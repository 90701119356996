// libraries
import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
// components
import Email from 'router/views/ResetPassword/ResetPasswordFlow/Email/Email';
import EmailChange from 'router/views/ResetPassword/ResetPasswordFlow/EmailChange/EmailChange';
import Verification from 'router/views/ResetPassword/ResetPasswordFlow/Verification/Verification';
import CreateNewPassword from 'router/views/ResetPassword/ResetPasswordFlow/CreateNewPassword/CreateNewPassword';
import UnlockAccount from 'router/views/ResetPassword/ResetPasswordFlow/UnlockAccount/UnlockAccount';
// helpers
import usePasswordResetForm, { PasswordRecoveryFormStep } from 'components/resetPassword/usePasswordResetForm';

const ResetPasswordFlow: React.FC = () => {
  const {
    currentStep,
    email,
    submitEmailForm,
    submitVerificationForm,
    submitEmailChangeForm,
    submitCreateNewPasswordForm,
    handleEmailChange,
    submitUnlockAccountForm,
    setCurrentStep,
  } = usePasswordResetForm();

  const { state: locationState = {} } = useLocation() || {};
  const { userEmail } = locationState;

  useLayoutEffect(() => {
    if (userEmail) {
      setCurrentStep(PasswordRecoveryFormStep.UNLOCK_ACCOUNT);
    }
  }, [userEmail]);

  if (currentStep === PasswordRecoveryFormStep.VERIFICATION) {
    return (
      <Verification
        email={email}
        handleEmailChange={handleEmailChange}
        submitVerificationForm={submitVerificationForm}
      />
    );
  }

  if (currentStep === PasswordRecoveryFormStep.UNLOCK_ACCOUNT) {
    return <UnlockAccount submitUnlockAccountForm={submitUnlockAccountForm} email={userEmail.email} />;
  }

  if (currentStep === PasswordRecoveryFormStep.CREATE_NEW_PASSWORD) {
    return <CreateNewPassword submitCreateNewPasswordForm={submitCreateNewPasswordForm} />;
  }

  if (currentStep === PasswordRecoveryFormStep.EMAIL_CHANGE) {
    return <EmailChange submitEmailChangeForm={submitEmailChangeForm} />;
  }

  return <Email submitEmailForm={submitEmailForm} />;
};

export default ResetPasswordFlow;
