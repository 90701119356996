import React from 'react';
import { isArray } from 'lodash';
import styles from './InputErrors.module.scss';
import { ErrorMessages } from '../Input';

const InputErrors: React.FC<ErrorMessages> = ({ errorMessages }: ErrorMessages) => {
  if (!errorMessages || !errorMessages.length) {
    return null;
  }

  let displayedErrors;

  // Error message = key in errors.json that is used by i18next
  if (isArray(errorMessages)) {
    displayedErrors = errorMessages.map((error: string) => <div key={error}>{error}</div>);
  } else {
    displayedErrors = <div>{errorMessages}</div>;
  }

  return <div className={styles['form-errors-wrapper']}>{displayedErrors}</div>;
};

export default InputErrors;
