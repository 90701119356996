// libraries
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route as RouteComponent, Link, Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// constants
import { TFA_PATHNAME } from 'router/configs/auth';
// components
import { RenderedRoute, Route } from 'router/Routes';
import DocumentHead from 'components/DocumentHead/DocumentHead';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
import { VerificationStatues } from 'shared/stores/AuthStore/AuthStore';
// helpers
import VerificationStatus from 'shared/constants/verificationStatus';
import { addStaticToast } from 'components/Toast/toastHelper';
import { getVerificationLink } from 'components/verification/verificationHelpers';

const VERIFICATION_TOAST_ID = 'VERIFICATION_TOAST';

const ProtectedRoute = ({ ...props }: Route): Nullable<RenderedRoute> => {
  const { t } = useTranslation('verification');
  const { path = '', name = '' } = props;
  const {
    myBusinessSnapshotStore: { myBusinessProfile, isVerifiedCompany },
    authStore: { isAuthLoading, sessionId, sessionTimedOut, mfaEnabled, verificationStatus },
    toastStore: { removeToastId },
  } = useStore();
  const { pathname } = useLocation();

  const isVerificationFlowRoute =
    isVerifiedCompany && path.includes('verification') && !path.includes('/verification/completed');

  useEffect(() => {
    if (
      myBusinessProfile &&
      !isVerifiedCompany &&
      myBusinessProfile?.verificationStatus !== VerificationStatus.PENDING &&
      !path.includes('verification')
    ) {
      addStaticToast({
        text: (
          <>
            {t('notificationMessage')}
            <Link
              to={{
                pathname: getVerificationLink(myBusinessProfile?.nextVerificationStep),
                state: { prevPath: pathname },
              }}
            >
              {t('notificationLink')}{' '}
            </Link>
          </>
        ),
        type: toast.TYPE.WARNING,
        toastId: VERIFICATION_TOAST_ID,
      });
    } else {
      removeToastId(VERIFICATION_TOAST_ID);
      toast.dismiss(VERIFICATION_TOAST_ID);
    }
  }, [isVerifiedCompany, path, myBusinessProfile?.verificationStatus, t, removeToastId]);

  if (isAuthLoading) {
    return <div />;
  }

  if (sessionId && mfaEnabled && verificationStatus !== VerificationStatues.VERIFIED) {
    return <Redirect to={TFA_PATHNAME} />;
  }

  if (!sessionId || verificationStatus !== VerificationStatues.VERIFIED) {
    if (sessionTimedOut) {
      return <Redirect to="/session-timeout" />;
    }

    return <Redirect to="/login" />;
  }

  if (!sessionTimedOut && isVerificationFlowRoute) {
    return <Redirect to="/" />;
  }

  return (
    <DocumentHead title={name}>
      <RouteComponent {...props} />
    </DocumentHead>
  );
};

export default observer(ProtectedRoute);
