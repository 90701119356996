import React from 'react';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// components
import Modal, { ModalType } from '../Modal/Modal';
import ShortList from './ShortList';
// styles
import styles from './ShortList.module.scss';

const ShortListModal = () => {
  return (
    <Modal
      id={ModalIds.shortList}
      type={ModalType.minimal}
      hasCloseButton
      modalClassName={styles['short-list__modal']}
      closeButtonClassName={styles['close-button']}
    >
      <ShortList />
    </Modal>
  );
};

export default ShortListModal;
