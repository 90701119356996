// libraries
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import DataGridLayout from 'components/list/layout/DataGridLayout';
import TextField from 'components/list/field/TextField';
import DueDateField from 'components/transactions/TransactionHistory/fields/DueDateField';
import CounterpartyField from 'components/transactions/TransactionHistory/fields/CounterpartyField';
import AmountField from 'components/transactions/TransactionHistory/fields/AmountField';
import SearchInput from 'components/list/search/SearchInput';
// styles
import styles from './TransactionList.module.scss';
// helpers
import scrollIfNeeded from 'shared/helpers/scroll';
import { useListContext } from 'components/list/ListContext';
import { FilterTabs } from '../transactionHistoryHelpers';

interface ITransactionListProps {
  openInvoiceDetails: (id: string) => void;
  openedInvoiceId?: Nullable<string>;
  closeInvoiceDetails?: () => void;
  activeTab: FilterTabs;
}

const TransactionList: React.FC<ITransactionListProps> = ({
  openInvoiceDetails,
  openedInvoiceId,
  closeInvoiceDetails,
  activeTab,
}: ITransactionListProps) => {
  const { t } = useTranslation('transactionHistory');
  const activeElementRef = useRef<HTMLElement>(null);
  const scrollContainerRef = useRef<SimpleBar>(null);
  const scrollElement = scrollContainerRef?.current?.getScrollElement();
  const { data, search } = useListContext();

  useEffect(() => {
    if (openedInvoiceId) {
      setTimeout(() => {
        scrollIfNeeded(activeElementRef?.current, scrollElement);
      }, 150);
    }
  }, [activeElementRef, scrollElement, data, openedInvoiceId, activeTab]);

  useEffect(() => {
    scrollElement?.scrollTo({ top: 0, behavior: 'smooth' });
    // Suppressed warnings because we should update data only if search was updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className={styles['transaction-history-table__wrapper']} id="my-element">
      <SearchInput
        placeholder="Search by name"
        className={styles['transaction-history-search__wrapper']}
        onSearchCallback={closeInvoiceDetails}
      />
      <DataGridLayout
        scrollbarRef={scrollContainerRef}
        gridTemplateColumns="2fr 1.2fr 116px 70px"
        rowKeySource="invoiceId"
        rowProps={invoiceData => {
          return {
            id: invoiceData.invoiceId === openedInvoiceId ? 'active-invoice' : '',
            ref: invoiceData.invoiceId === openedInvoiceId ? activeElementRef : null,
            onClick: () => openInvoiceDetails(invoiceData.invoiceId),
            className: classnames(styles['transaction-history-table__row'], {
              'data-grid-table__row--active': invoiceData.invoiceId === openedInvoiceId,
            }),
          };
        }}
        tableMaxHeight={306}
      >
        <CounterpartyField
          source="counterpartyDetails"
          sortSource="counterpartyDetails.dbaName"
          label={t('columnTitles.counterparty')}
          isSortable
          wrapperClassName={styles['transaction-history-table__counterparty-field']}
        />
        <TextField
          source="message"
          label={t('columnTitles.message')}
          wrapperClassName={styles['transaction-history-table__message-field']}
        />
        <DueDateField
          source="dueDate"
          label={t('columnTitles.dueDate')}
          isSortable
          wrapperClassName={styles['transaction-history-table__due-date-field']}
        />
        <AmountField
          source="totalAmount"
          label={t('columnTitles.amount')}
          isSortable
          wrapperClassName={styles['transaction-history-table__amount-field']}
        />
      </DataGridLayout>
    </div>
  );
};

export default TransactionList;
