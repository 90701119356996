// libraries
import { useCallback, useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// helpers
import { SelectedCompanyInfoProps, AddBillFormData, AddBillFormDataFromInvoice } from './types';
import VerificationStatus from 'shared/constants/verificationStatus';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import { useFormContext, FormData } from 'components/Form/FormContext';
import { getNewInvoice } from 'components/invoices/InvoiceHistory/mockData';
import useInvoiceItems from 'components/invoices/useInvoiceItems';
import { Invoice, InvoiceTypes } from 'components/invoices/types';
import useCompanies from 'components/company/useCompanies';
import { tryFormatToIso } from 'components/Form/inputs/DateInput/tryFormatToIso';
import { getMockData } from 'shared/helpers/simulateAsyncCall';

const MAX_SCROLL_LIST_SIZE = 7;

interface AddBillDataProps {
  onSearch: (query: string, isOpenSearch?: boolean) => void;
  onSubmitAddBillManuallyForm: (data: FormData[]) => void;
  onSubmitReview: (id: string) => void;
  vendorInputValue: string;
  isOpenSearch: boolean;
  companies: Partial<IBusinessSnapshot>[];
  isLoaded: boolean;
  isScrollableList: boolean;
  onSelectCompanyHandler: (companyName?: string, companyEmail?: string, companyLogo?: string) => void;
  isDisabledEmailInput: boolean;
  isNewVendor: boolean;
  isSubmitButtonDisabled: boolean;
}

const useAddBill = (
  setCompanyInfo: Dispatch<SetStateAction<SelectedCompanyInfoProps>> = () => {},
): AddBillDataProps => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [companies, setCompanies] = useState<Partial<IBusinessSnapshot>[]>([]);
  const [isScrollableList, setIsScrollableList] = useState(false);
  const [vendorInputValue, setVendorInputValue] = useState('');
  const [isDisabledEmailInput, setIsDisabledEmailInput] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isNewVendor, setIsNewVendor] = useState(false);
  const { setFieldValues = () => {}, getFieldValue = () => {}, validateField = () => {} } = useFormContext();
  const { addInvoiceItem, updateInvoiceItem } = useInvoiceItems();
  const { companiesData: storedCompaniesData } = useCompanies();
  const history = useHistory();
  const { state: locationState = {} } = useLocation() || {};

  const isSubmitButtonDisabled = useMemo(
    () =>
      !getFieldValue(AddBillFormData.vendor) ||
      (!getFieldValue(AddBillFormData.contactName) && isNewVendor) ||
      !getFieldValue(AddBillFormData.email) ||
      !getFieldValue(AddBillFormData.amount) ||
      !getFieldValue(AddBillFormData.invoiceNumber) ||
      !getFieldValue(AddBillFormData.dueDate),
    [getFieldValue],
  );

  const onSubmitAddBillManuallyForm = async (formData: FormData[]) => {
    // TODO: update after integration with BE
    let resultForm = {};

    formData.forEach(step => {
      resultForm = { ...resultForm, ...step };
    });

    // TODO: need update after integration. FE sents form data. BE returns new invoice data
    const newInvoice = await getMockData(
      getNewInvoice({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(resultForm as any),
        ...(getFieldValue(AddBillFormData.vendor) && { customer: getFieldValue(AddBillFormData.vendor) }),
        isManuallyAdded: true,
        invoiceType: InvoiceTypes.payable,
        invoiceId: locationState?.invoice?.invoiceId,
      }),
    );

    if (locationState) {
      updateInvoiceItem(newInvoice.invoiceId, newInvoice);
    } else {
      addInvoiceItem(newInvoice as Invoice);
    }

    return newInvoice.invoiceId;
  };

  const onSubmitReview = (id: string) => {
    history.push(`/accounting?type=payable&invoiceId=${id}`);
  };

  const closeVendorInputSearch = (companyName?: string, companiesData: Partial<IBusinessSnapshot>[] = companies) => {
    if (!companyName) {
      setIsDisabledEmailInput(false);
      return;
    }

    const findCompany = companiesData.find(company => company.dbaName?.toLowerCase() === companyName?.toLowerCase());

    if (findCompany) {
      if (findCompany.email) {
        setIsDisabledEmailInput(true);
      }

      setIsNewVendor(false);
      setFieldValues(AddBillFormData.email, findCompany.email);
      setFieldValues(AddBillFormData.vendor, findCompany.dbaName);
      setCompanyInfo({
        logo: findCompany.logoImage,
        isNewVendor: false,
        verificationStatus: findCompany.verificationStatus,
      });
      validateField(AddBillFormData.email, { [AddBillFormData.email]: findCompany.email });
      validateField(AddBillFormData.vendor, { [AddBillFormData.vendor]: findCompany.dbaName });
    } else {
      setIsNewVendor(true);
      setCompanyInfo({ logo: '', isNewVendor: true, verificationStatus: VerificationStatus.UNVERIFIED });
      setIsDisabledEmailInput(false);
    }

    setIsOpenSearch(false);
  };

  const filterCompanies = useCallback(
    async (queryString = '') => {
      setIsLoaded(true);

      if (!queryString) {
        setIsOpenSearch(false);
      }

      if (queryString) {
        const filteredCompanies = storedCompaniesData.filter(company =>
          company.dbaName.toLowerCase().includes(queryString.toLowerCase()),
        );
        setCompanies(filteredCompanies);
        setIsScrollableList(filteredCompanies.length >= MAX_SCROLL_LIST_SIZE);
        return filteredCompanies;
      }

      setCompanies(storedCompaniesData);
      setIsScrollableList(storedCompaniesData.length >= MAX_SCROLL_LIST_SIZE);
      return storedCompaniesData;
    },
    [storedCompaniesData],
  );

  const onSelectCompanyHandler = (
    companyName?: string,
    companyEmail?: string,
    companyLogo?: string,
    verificationStatus?: VerificationStatus,
  ) => {
    setFieldValues(AddBillFormData.vendor, companyName);
    setFieldValues(AddBillFormData.email, companyEmail);

    if (setCompanyInfo) {
      setCompanyInfo({ logo: companyLogo, isNewVendor: false, verificationStatus });
    }

    setIsOpenSearch(false);
  };

  const onSearch = useCallback(
    (query: string, isActiveSearch?: boolean) => {
      if (!isActiveSearch) {
        setIsOpenSearch(false);
        return;
      }

      setVendorInputValue(query);
      setIsOpenSearch(true);
      setFieldValues(AddBillFormData.email, '');
      setIsNewVendor(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!isOpenSearch) {
      closeVendorInputSearch(getFieldValue(AddBillFormData.vendor));
      return;
    }

    filterCompanies(vendorInputValue);
    // Suppressed warnings because we should filter data only if search was updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCompanies, vendorInputValue, isOpenSearch]);

  const setDataFromInvoice = (invoiceDetails: AddBillFormDataFromInvoice) => {
    const { customer, amount, invoiceNumber, dueDate, message = '' } = invoiceDetails;

    setFieldValues(AddBillFormData.vendor, customer);
    setFieldValues(AddBillFormData.amount, amount);
    setFieldValues(AddBillFormData.invoiceNumber, invoiceNumber);
    setFieldValues(AddBillFormData.dueDate, tryFormatToIso(dueDate));
    setFieldValues(AddBillFormData.message, message);
  };

  const loadData = async (invoiceDetails?: AddBillFormDataFromInvoice) => {
    const companyName = getFieldValue(AddBillFormData.vendor) || invoiceDetails?.customer;

    if (companyName) {
      const companyData = await filterCompanies(companyName);
      closeVendorInputSearch(companyName, companyData);
    }

    if (invoiceDetails) {
      setDataFromInvoice(invoiceDetails);
    }
  };

  useEffect(() => {
    if (locationState?.invoice) {
      loadData(locationState?.invoice);
      return;
    }

    loadData();
    // Suppressed warnings because we should load data only if data in store were updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedCompaniesData]);

  return {
    onSubmitAddBillManuallyForm,
    onSubmitReview,
    vendorInputValue,
    isOpenSearch,
    companies,
    isLoaded,
    isScrollableList,
    onSelectCompanyHandler,
    isDisabledEmailInput,
    isNewVendor,
    onSearch,
    isSubmitButtonDisabled,
  };
};

export default useAddBill;
