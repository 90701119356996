// libraries
import React from 'react';
// components
import Form from 'components/Form';
import CodeFormFields from 'components/settings/AccountTabForm/AuthentificationBlock/CodeForm/CodeFormFields';
// helpers
import useAccountTabForm from 'components/settings/AccountTabForm/useAccountTabForm';
import { ENTER_CODE_FORM_SCHEMA } from 'components/settings/AccountTabForm/validation-rules';

const CodeForm = () => {
  const { submitEnterCodeForm } = useAccountTabForm();

  return (
    <Form validationSchema={ENTER_CODE_FORM_SCHEMA} submitForm={submitEnterCodeForm}>
      <CodeFormFields />
    </Form>
  );
};

export default CodeForm;
