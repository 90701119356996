// libraries
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
// helpers
import { FormData } from 'components/Form/FormContext';
import { addErrorToast } from 'components/Toast/toastHelper';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// api
import IntegrationsApi, {
  ExportTransactionsToQuickBookData,
  QuickBookData as QuickBookRequestData,
} from '../api/IntegrationsApi';

type QuickBookData = {
  isFormSubmitted: boolean;
  isLoading: boolean;
  handleSubmitForm: (data: FormData) => Promise<void>;
  createQuickBookIntegration: (data: QuickBookRequestData) => Promise<void>;
};

const useQuickBook = (): QuickBookData => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const {
    integrationsStore: { setExportTransactionsFromDate, resetData, exportTransactionsFromDate, exportTransactionsData },
  } = useStore();

  const createQuickBookIntegration = useCallback(async () => {
    setIsFormSubmitted(false);
    setIsLoading(true);

    try {
      await IntegrationsApi.integrateQuickBook(exportTransactionsData as QuickBookRequestData);
      await IntegrationsApi.exportTransactionsToQuickBook({
        from: exportTransactionsFromDate || '',
      });

      resetData();
      setIsFormSubmitted(true);
    } catch (e) {
      const { error } = await e;
      // addErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, [exportTransactionsFromDate, exportTransactionsData, resetData]);

  const handleSubmitForm = useCallback(
    async (data?: FormData) => {
      setIsLoading(true);

      try {
        const { uri } = await IntegrationsApi.getQuickBookAuthorizeUri({
          state: pathname,
        });

        setExportTransactionsFromDate((data as ExportTransactionsToQuickBookData).from);

        window.location.href = uri;
      } catch (e) {
        const { error } = await e;
        // addErrorToast(error);
        setIsLoading(false);
      }
    },
    [pathname, setExportTransactionsFromDate],
  );

  useEffect(() => {
    if (exportTransactionsData && exportTransactionsFromDate) {
      createQuickBookIntegration();
    }
  }, [createQuickBookIntegration, exportTransactionsData, exportTransactionsFromDate]);

  return {
    handleSubmitForm,
    isFormSubmitted,
    isLoading,
    createQuickBookIntegration,
  };
};

export default useQuickBook;
