// libraries
import { makeAutoObservable } from 'mobx';
// constants
import { CLEAR_STORE } from '../../constants/funcNamesInStore';
import AccountIntegrationTypes from 'shared/constants/AccountIntegrationTypes';
// rootStore
import RootStore from 'shared/stores/RootStore/RootStore';

export type TAccount = {
  title: string;
  subtitle: string;
  type: AccountIntegrationTypes;
  isConnected: boolean;
};

export type TAccountIntegrationStore = {
  accounts: TAccount[];
};

// TODO: Delete after integration with BE
const initialValues = [
  {
    title: 'QuickBooks Online',
    subtitle: 'Automate your work and have more time for what matters most—no code required.',
    type: AccountIntegrationTypes.QUICKBOOK,
    isConnected: false,
  },
];

class AccountIntegrationsStore {
  accounts: TAccount[] = initialValues;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setAccounts = (accounts: TAccount[]) => {
    this.accounts = accounts;
  };

  connectAccount = (type: AccountIntegrationTypes) => {
    this.accounts = this.accounts.map(item => {
      if (item.type === type) {
        return {
          ...item,
          isConnected: true,
        };
      }

      return item;
    });
  };

  disconnectAccount = (type: AccountIntegrationTypes) => {
    this.accounts = this.accounts.map(item => {
      if (item.type === type) {
        return {
          ...item,
          isConnected: false,
        };
      }

      return item;
    });
  };

  setDataFromMessage = (data: TAccountIntegrationStore): void => {
    this.accounts = data.accounts;
  };

  [CLEAR_STORE] = (): void => {
    this.accounts = [];
  };
}

export default AccountIntegrationsStore;
