export default [
  {
    value: 'Information Technology and Services',
    label: 'Information Technology and Services',
  },
  {
    value: 'Hospital & Health Care',
    label: 'Hospital & Health Care',
  },
  {
    value: 'Construction',
    label: 'Construction',
  },
  {
    value: 'Education Management',
    label: 'Education Management',
  },
  {
    value: 'Retail',
    label: 'Retail',
  },
  {
    value: 'Financial Services',
    label: 'Financial Services',
  },
  {
    value: 'Accounting',
    label: 'Accounting',
  },
  {
    value: 'Computer Software',
    label: 'Computer Software',
  },
  {
    value: 'Automotive',
    label: 'Automotive',
  },
  {
    value: 'Higher Education',
    label: 'Higher Education',
  },
  {
    value: 'Marketing & Advertising',
    label: 'Marketing & Advertising',
  },
  {
    value: 'Government Administration',
    label: 'Government Administration',
  },
  {
    value: 'Banking',
    label: 'Banking',
  },
  {
    value: 'Health, Welness & Fitness',
    label: 'Health, Welness & Fitness',
  },
  {
    value: 'Real Estate',
    label: 'Real Estate',
  },
  {
    value: 'Telecommunications',
    label: 'Telecommunications',
  },
  {
    value: 'Oil & Energy',
    label: 'Oil & Energy',
  },
  {
    value: 'Food & Beverages',
    label: 'Food & Beverages',
  },
  {
    value: 'Hospitality',
    label: 'Hospitality',
  },
  {
    value: 'Mechanical or Industrial Engineering',
    label: 'Mechanical or Industrial Engineering',
  },
  {
    value: 'Electrical & Electronic Manufacturing',
    label: 'Electrical & Electronic Manufacturing',
  },
  {
    value: 'Primary/Secondary Education',
    label: 'Primary/Secondary Education',
  },
  {
    value: 'Insurance',
    label: 'Insurance',
  },
  {
    value: 'Internet',
    label: 'Internet',
  },
  {
    value: 'Human Resources',
    label: 'Human Resources',
  },
  {
    value: 'Medical Practice',
    label: 'Medical Practice',
  },
  {
    value: 'Transportation/Trucking/Railroad',
    label: 'Transportation/Trucking/Railroad',
  },
  {
    value: 'Consumer Services',
    label: 'Consumer Services',
  },
  {
    value: 'Management Consulting',
    label: 'Management Consulting',
  },
  {
    value: 'Civil Engineering',
    label: 'Civil Engineering',
  },
  {
    value: 'Design',
    label: 'Design',
  },
  {
    value: 'Research',
    label: 'Research',
  },
  {
    value: 'Restaurants',
    label: 'Restaurants',
  },
  {
    value: 'Logistics & Supply Chain',
    label: 'Logistics & Supply Chain',
  },
  {
    value: 'Architecture & Planning',
    label: 'Architecture & Planning',
  },
  {
    value: 'Law Practice',
    label: 'Law Practice',
  },
  {
    value: 'Apparel & Fashion',
    label: 'Apparel & Fashion',
  },
  {
    value: 'Consumer Goods',
    label: 'Consumer Goods',
  },
  {
    value: 'Facilities Services',
    label: 'Facilities Services',
  },
  {
    value: 'Pharmaceuticals',
    label: 'Pharmaceuticals',
  },
  {
    value: 'Food Production',
    label: 'Food Production',
  },
  {
    value: 'Non-profit Organization Management',
    label: 'Non-profit Organization Management',
  },
  {
    value: 'Entertainment',
    label: 'Entertainment',
  },
  {
    value: 'Machinery',
    label: 'Machinery',
  },
  {
    value: 'Chemicals',
    label: 'Chemicals',
  },
  {
    value: 'Arts & Crafts',
    label: 'Arts & Crafts',
  },
  {
    value: 'Wholesale',
    label: 'Wholesale',
  },
  {
    value: 'Utilities',
    label: 'Utilities',
  },
  {
    value: 'Legal Services',
    label: 'Legal Services',
  },
  {
    value: 'Farming',
    label: 'Farming',
  },
  {
    value: 'Mining & Metals',
    label: 'Mining & Metals',
  },
  {
    value: 'Airlines/Aviation',
    label: 'Airlines/Aviation',
  },
  {
    value: 'Leisure, Travel & Turism',
    label: 'Leisure, Travel & Turism',
  },
  {
    value: 'Building Materials',
    label: 'Building Materials',
  },
  {
    value: 'Music',
    label: 'Music',
  },
  {
    value: 'Enviromental Services',
    label: 'Enviromental Services',
  },
  {
    value: 'Professional Training & Coaching',
    label: 'Professional Training & Coaching',
  },
  {
    value: 'Medical Device',
    label: 'Medical Device',
  },
  {
    value: 'Individual & Family Services',
    label: 'Individual & Family Services',
  },
  {
    value: 'Cosmetics',
    label: 'Cosmetics',
  },
  {
    value: 'Mental Health Care',
    label: 'Mental Health Care',
  },
  {
    value: 'Aviation and Aerospace',
    label: 'Aviation and Aerospace',
  },
  {
    value: 'Staffing & Recruiting',
    label: 'Staffing & Recruiting',
  },
  {
    value: 'Industrial Automation',
    label: 'Industrial Automation',
  },
  {
    value: 'Graphic Design',
    label: 'Graphic Design',
  },
  {
    value: 'Security & Investigations',
    label: 'Security & Investigations',
  },
  {
    value: 'Import and Export',
    label: 'Import and Export',
  },
  {
    value: 'Public Relations and Communications',
    label: 'Public Relations and Communications',
  },
  {
    value: 'Textiles',
    label: 'Textiles',
  },
  {
    value: 'Military',
    label: 'Military',
  },
  {
    value: 'Broadcast Media',
    label: 'Broadcast Media',
  },
  {
    value: 'Biotechnology',
    label: 'Biotechnology',
  },
  {
    value: 'Media Production',
    label: 'Media Production',
  },
  {
    value: 'Business Supplies & Equipment',
    label: 'Business Supplies & Equipment',
  },
  {
    value: 'Computer Networking',
    label: 'Computer Networking',
  },
  {
    value: 'Writing & Editing',
    label: 'Writing & Editing',
  },
  {
    value: 'International Trade and Development',
    label: 'International Trade and Development',
  },
  {
    value: 'Consumer Elecronics',
    label: 'Consumer Elecronics',
  },
  {
    value: 'Events Services',
    label: 'Events Services',
  },
  {
    value: 'Photography',
    label: 'Photography',
  },
  {
    value: 'Renewables & Envirnoment',
    label: 'Renewables & Envirnoment',
  },
  {
    value: 'Computer Hardware',
    label: 'Computer Hardware',
  },
  {
    value: 'Civic and Social Organization',
    label: 'Civic and Social Organization',
  },
  {
    value: 'Furniture',
    label: 'Furniture',
  },
  {
    value: 'Defense & Space',
    label: 'Defense & Space',
  },
  {
    value: 'Computer & Network Security',
    label: 'Computer & Network Security',
  },
  {
    value: 'Printing',
    label: 'Printing',
  },
  {
    value: 'Fine Art',
    label: 'Fine Art',
  },
  {
    value: 'Religious Institutions',
    label: 'Religious Institutions',
  },
  {
    value: 'Investmend Management',
    label: 'Investmend Management',
  },
  {
    value: 'Law Enforcement',
    label: 'Law Enforcement',
  },
  {
    value: 'Publishing',
    label: 'Publishing',
  },
  {
    value: 'Information Services',
    label: 'Information Services',
  },
  {
    value: 'Maritime',
    label: 'Maritime',
  },
  {
    value: 'Outsourcing/Offshoring',
    label: 'Outsourcing/Offshoring',
  },
  {
    value: 'Warehousing',
    label: 'Warehousing',
  },
  {
    value: 'E-learning',
    label: 'E-learning',
  },
  {
    value: 'Executive Office',
    label: 'Executive Office',
  },
  {
    value: 'Government Relations',
    label: 'Government Relations',
  },
  {
    value: 'Animation',
    label: 'Animation',
  },
  {
    value: 'Semiconducs',
    label: 'Semiconducs',
  },
  {
    value: 'Supermarkets',
    label: 'Supermarkets',
  },
  {
    value: 'Program Development',
    label: 'Program Development',
  },
  {
    value: 'Public Safety',
    label: 'Public Safety',
  },
  {
    value: 'Plastics',
    label: 'Plastics',
  },
  {
    value: 'Alternative Medicine',
    label: 'Alternative Medicine',
  },
  {
    value: 'Performing Arts',
    label: 'Performing Arts',
  },
  {
    value: 'Online Media',
    label: 'Online Media',
  },
  {
    value: 'Motion Pictures & Film',
    label: 'Motion Pictures & Film',
  },
  {
    value: 'Commercial Real Estate',
    label: 'Commercial Real Estate',
  },
  {
    value: 'Judiciary',
    label: 'Judiciary',
  },
  {
    value: 'Packaging and Containers',
    label: 'Packaging and Containers',
  },
  {
    value: 'Luxury Goods & Jewelry',
    label: 'Luxury Goods & Jewelry',
  },
  {
    value: 'Veterinary',
    label: 'Veterinary',
  },
  {
    value: 'Computer Games',
    label: 'Computer Games',
  },
  {
    value: 'Investment Banking',
    label: 'Investment Banking',
  },
  {
    value: 'Market Research',
    label: 'Market Research',
  },
  {
    value: 'International Affairs',
    label: 'International Affairs',
  },
  {
    value: 'Wine & Spirits',
    label: 'Wine & Spirits',
  },
  {
    value: 'Newspapers',
    label: 'Newspapers',
  },
  {
    value: 'Translation & Localisation',
    label: 'Translation & Localisation',
  },
  {
    value: 'Recreational Facilities & Services',
    label: 'Recreational Facilities & Services',
  },
  {
    value: 'Sporting Goods',
    label: 'Sporting Goods',
  },
  {
    value: 'Paper & Forest Products',
    label: 'Paper & Forest Products',
  },
  {
    value: 'Capital Markets',
    label: 'Capital Markets',
  },
  {
    value: 'Public Policy',
    label: 'Public Policy',
  },
  {
    value: 'Package/Freight Delivery',
    label: 'Package/Freight Delivery',
  },
  {
    value: 'Libraries',
    label: 'Libraries',
  },
  {
    value: 'Wireless',
    label: 'Wireless',
  },
  {
    value: 'Gambling & Casinos',
    label: 'Gambling & Casinos',
  },
  {
    value: 'Venture Capital & Private Equity',
    label: 'Venture Capital & Private Equity',
  },
  {
    value: 'Glass, Ceramics & Concrete',
    label: 'Glass, Ceramics & Concrete',
  },
  {
    value: 'Philanthropy',
    label: 'Philanthropy',
  },
  {
    value: 'Ranching',
    label: 'Ranching',
  },
  {
    value: 'Dairy',
    label: 'Dairy',
  },
  {
    value: 'Museums and Institutions',
    label: 'Museums and Institutions',
  },
  {
    value: 'Shipbuilding',
    label: 'Shipbuilding',
  },
  {
    value: 'Think Thanks',
    label: 'Think Thanks',
  },
  {
    value: 'Political Organization',
    label: 'Political Organization',
  },
  {
    value: 'Fishery',
    label: 'Fishery',
  },
  {
    value: 'Fund-Raising',
    label: 'Fund-Raising',
  },
  {
    value: 'Tobacco',
    label: 'Tobacco',
  },
  {
    value: 'Railroad Manufacture',
    label: 'Railroad Manufacture',
  },
  {
    value: 'Alternative Dispute Resolution',
    label: 'Alternative Dispute Resolution',
  },
  {
    value: 'Nanotechnology',
    label: 'Nanotechnology',
  },
  {
    value: 'Legislative Office',
    label: 'Legislative Office',
  },
];
