import { makeAutoObservable } from 'mobx';
import RootStore from 'shared/stores/RootStore/RootStore';
import { IMessagePendingStore } from './types';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';

const initialValues = {
  messageType: null,
  endpoint: null,
  payload: null,
  sessionId: null,
};

class MessagePendingStore {
  pendingData: IMessagePendingStore = initialValues;

  isPendingMessageExist = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setDataFromMessage = (data: IMessagePendingStore): void => {
    this.pendingData = data;
    this.isPendingMessageExist = true;
  };

  [CLEAR_STORE] = (): void => {
    this.pendingData = initialValues;
    this.isPendingMessageExist = false;
  };
}

export default MessagePendingStore;
