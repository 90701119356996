// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { format } from 'date-fns';
// assets
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen.svg';
// components
import PaymentDatePicker from 'components/externalPayments/Calendar/Calendar';
import PaymentReviewField from './PaymentReviewField';
import FormFooter from 'components/MultiStep/Form/FormFooter/FormFooter';
// helpers
import { DateFormats, formatCurrency } from 'shared/helpers/format';
import { Invoice } from 'components/invoices/types';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
// styles
import styles from './PaymentReview.module.scss';

interface IPaymentReview {
  isConfirmed?: boolean;
  isScheduled?: boolean;
  invoiceDetails: Nullable<Invoice>;
  openPlaidModal?: () => void;
  unscheduleInvoice?: () => void;
  setPaymentDate: (date: Date) => void;
  paymentDate: Nullable<Date>;
  paymentAccount?: Nullable<Account>;
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}

const PaymentReview: React.FC<IPaymentReview> = ({
  invoiceDetails,
  paymentAccount,
  openPlaidModal,
  setPaymentDate,
  paymentDate,
  isConfirmed,
  children,
  isScheduled,
  className,
  unscheduleInvoice,
  isLoading = false,
}: IPaymentReview) => {
  const { t } = useTranslation('externalPaymentPage');
  const dueDate = invoiceDetails?.dueDate ? new Date(invoiceDetails?.dueDate) : new Date();

  return (
    <>
      <div className={classnames(styles['payment-review__wrapper'], className)}>
        <PaymentReviewField label={t('form.AmountField')} value={formatCurrency(invoiceDetails?.totalAmount, false)} />
        <PaymentReviewField label={t('form.DueDate')} value={format(dueDate, DateFormats.invoiceReviewDate)} />
        <PaymentReviewField label={t('form.Description')} value={invoiceDetails?.message} />
        <PaymentReviewField
          label={t('paymentMethod')}
          value={
            <>
              {`xxxx ${paymentAccount?.lastNumbers} `}
              {!isConfirmed && !isScheduled ? (
                <EditIcon width={9} height={9} className={styles['edit-pen']} onClick={openPlaidModal} />
              ) : null}
            </>
          }
        />
        <PaymentReviewField
          label={isScheduled ? t('paymentScheduledDate') : t('paymentDateLastStep')}
          value={
            <div className={styles['date-field-value__wrapper']}>
              {format(paymentDate || new Date(), DateFormats.invoiceReviewDate)}
              {!isConfirmed && !isScheduled && (
                <PaymentDatePicker
                  dueDate={dueDate}
                  selectedDate={paymentDate ? new Date(paymentDate) : new Date()}
                  handleChange={setPaymentDate}
                >
                  <EditIcon width={9} height={9} className={styles['edit-date-pen']} />
                </PaymentDatePicker>
              )}

              {!isConfirmed && isScheduled && (
                <EditIcon
                  width={9}
                  height={9}
                  className={classnames(styles['schedule-edit-pen'], { [styles['edit-pen--disabled']]: isLoading })}
                  onClick={unscheduleInvoice}
                />
              )}
            </div>
          }
        />
      </div>
      <FormFooter className={styles['payment-review-footer']}>{children}</FormFooter>
    </>
  );
};

export default PaymentReview;
