import React, { useEffect } from 'react';
// components
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
import RegistrationCompany from './RegistrationCompany';
import useMultiStep from 'components/MultiStep/useMultiStep';
import { ModalIds } from 'shared/constants/modalIds';
import Modal, { ModalType } from 'components/Modal/Modal';
import { useStore } from 'shared/stores/RootStore/useStore';

const RegistrationCompanyWrapper: React.FC = () => {
  const contextValue = useMultiStep(3, 1);

  const {
    modalStore: { openModal },
  } = useStore();

  useEffect(() => {
    openModal(ModalIds.registrationCompanyModal);
  }, [openModal]);

  return (
    <Modal id={ModalIds.registrationCompanyModal} type={ModalType.base} isUnclosed hasCloseButton={false}>
      <MultiStepProvider value={contextValue}>
        <RegistrationCompany />
      </MultiStepProvider>
    </Modal>
  );
};

export default RegistrationCompanyWrapper;
