import Login from '../../views/Login/Login';
import SignUp from 'router/views/SignUp';
import TwoFactorAuthWrapper from 'router/views/TwoFactorAuthWrapper/TwoFactorAuthWrapper';
import RegistrationCompanyWrapper from 'router/views/RegistrationCompany/RegistrationCompanyWrapper';
import resetPassRoutes from './reset-password';
import confirmEmailRoutes from './confirm-email';

export const TFA_PATHNAME = '/tfa';

export default [
  {
    path: '/login',
    exact: true,
    isProtected: false,
    component: Login,
    name: 'Login',
  },
  {
    path: TFA_PATHNAME,
    exact: true,
    isProtected: false,
    component: TwoFactorAuthWrapper,
    name: 'Two Factor Auth',
  },
  {
    path: '/sign-up',
    exact: true,
    isProtected: false,
    component: SignUp,
    name: 'Register',
  },
  {
    path: '/sign-up/company',
    exact: true,
    isProtected: false,
    component: RegistrationCompanyWrapper,
    name: 'Register',
  },
  ...resetPassRoutes,
  ...confirmEmailRoutes,
];
