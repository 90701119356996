// libraries
import React from 'react';
import classnames from 'classnames';
// components
import Input, { InputProps, InputTypes } from '../Input';
import InputErrors from '../InputErrors';
// styles
import styles from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<InputProps, 'children'> {
  children?: React.ReactNode;
  label?: string | React.ReactNode;
  fieldLabelClassName?: string;
  checkboxInputClassName?: string;
}

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { label, checked = false, children, fieldLabelClassName, checkboxInputClassName, ...inputProps } = props;

  return (
    <Input type={InputTypes.checkbox} checked={checked} {...inputProps}>
      {({ errorMessages, inputCommonProps: { className, disabled, ...inputCommonProps } = {} }) => {
        const controlClassName = classnames(styles['checkbox-control'], className, inputProps.className);

        return (
          <div className={styles['field-wrapper']}>
            <label className={classnames(styles['field-label'], fieldLabelClassName)}>
              <input {...inputCommonProps} className={classnames(styles['checkbox-input'], checkboxInputClassName)} />
              <div className={controlClassName} />
              {label}
            </label>
            {errorMessages ? <InputErrors errorMessages={errorMessages} /> : null}
            {children}
          </div>
        );
      }}
    </Input>
  );
};

export default Checkbox;
