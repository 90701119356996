// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// styles
import styles from './CompanyMarketingElement.module.scss';
// components
import LinkButton from 'components/Button/LinkButton';
// assets
import TitlePic from 'assets/images/title.png';
import MarketingBg from 'assets/images/marketing-guys.png';

const CompanyMarketingElement: React.FC = () => {
  const { t } = useTranslation('company');

  return (
    <div className={styles.marketing__element}>
      <img src={MarketingBg} alt={t('bgAlt')} className={styles['marketing-bg']} />
      <img src={TitlePic} alt={t('titleAlt')} className={styles.title} />
      <LinkButton url={t('signUpUrl')} className={styles.try__button}>
        {t('tryNow')}
      </LinkButton>
    </div>
  );
};

export default observer(CompanyMarketingElement);
