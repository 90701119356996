// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// assets
import { ReactComponent as DoneIcon } from 'assets/icons/done.svg';
// components
import Modal from 'components/Modal/Modal';
import AppLoading from 'components/AppLoading/AppLoading';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// styles
import styles from './SuccessMessage.module.scss';

type TSuccessMessageModalProps = {
  isLoading: boolean;
};

const SuccessMessageModal: React.FC<TSuccessMessageModalProps> = ({ isLoading }: TSuccessMessageModalProps) => {
  const { t } = useTranslation('externalPaymentPage');

  return (
    <Modal id={ModalIds.successPaymentReceiving} hasCloseButton={false}>
      <AppLoading isActive={isLoading} hideContent>
        <div className={styles.popup__content}>
          <DoneIcon className={styles.popup__icon} />
          <h1 className={styles.popup__title}>{t('receiving.successMessageTitle')}</h1>
          <p className={styles.popup__subtitle}>{t('receiving.successMessageSubtitle')}</p>
        </div>
      </AppLoading>
    </Modal>
  );
};

export default observer(SuccessMessageModal);
