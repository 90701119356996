import React from 'react';
import { useTranslation } from 'react-i18next';
// styles
import styles from './PayableReceivableContent.module.scss';
import { formatCurrency } from 'shared/helpers/format';

type PayableReceivableContentProps = {
  wrapperClassName?: string;
  textTitle: string;
  amount: number;
  aging: number;
  inCents?: boolean;
  handleClick: () => void;
};

const PayableReceivableContent: React.FC<PayableReceivableContentProps> = ({
  wrapperClassName = '',
  inCents = false,
  textTitle,
  amount,
  aging,
  handleClick,
}: PayableReceivableContentProps) => {
  const { t } = useTranslation('payableReceivable');

  return (
    <div
      className={`${styles['payable-receivable-content']} ${wrapperClassName}`}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
    >
      <span className={styles['payable-receivable__title']}>{textTitle}</span>
      <span className={styles['payable-receivable__amount']}>{formatCurrency(amount, inCents)}</span>
      <span className={styles['payable-receivable__aging']}>
        {t('aging')} {formatCurrency(aging, inCents)}
      </span>
    </div>
  );
};

export default PayableReceivableContent;
