import React from 'react';
// components
import PublicCompanyProfile from 'components/company/PublicCompanyProfile/PublicCompanyProfile';
import PublicLayout from 'shared/layouts/public';

const CompanyProfile: React.FC = () => {
  return (
    <PublicLayout hasControlsInHeader isNavigationVisible isPublicProfile customClassName="public-profile">
      <PublicCompanyProfile />
    </PublicLayout>
  );
};

export default CompanyProfile;
