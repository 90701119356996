// libraries
import { differenceInDays, set } from 'date-fns';
// helpers
import { InvoiceStates, Invoice } from 'components/invoices/types';

export enum FilterTabs {
  PAYABLE = 'payable',
  RECEIVABLE = 'receivable',
  COMPLETE = 'completed',
}

export enum HistoryDurations {
  quarter = '90days',
  twoMonth = '60days',
  month = '30days',
  all = 'all',
}

export const HistoryDurationsDaysDifferences = {
  [HistoryDurations.quarter]: 90,
  [HistoryDurations.twoMonth]: 60,
  [HistoryDurations.month]: 30,
};

export const completedStates = [InvoiceStates.markedPaid, InvoiceStates.paid, InvoiceStates.settled];
export const payableStates = [InvoiceStates.inTransit, InvoiceStates.pending, InvoiceStates.scheduled];
export const receivableStates = [InvoiceStates.inTransit, InvoiceStates.pending, InvoiceStates.rejected];

const timeOptions = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

export const filterInvoicesByPeriod = (
  invoices: Invoice[],
  isCompletedTab: boolean,
  date: number,
  maxDaysDifference: number,
): Invoice[] => {
  return invoices.filter(({ dueDate, payDate }) => {
    let daysDifference = 0;

    if (!isCompletedTab) {
      daysDifference = differenceInDays(set(dueDate, timeOptions), set(date, timeOptions));
      return daysDifference < maxDaysDifference;
    }

    if (payDate) {
      daysDifference = differenceInDays(set(date, timeOptions), set(payDate, timeOptions));
      return daysDifference <= maxDaysDifference;
    }

    return false;
  });
};
