// libraries
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import TransactionCard from 'components/transactions/TransactionCard/index';
import PayableInvoiceDetails from './PayableInvoiceDetails/PayableInvoiceDetails';
import ReceivableInvoiceDetails from './ReceivableInvoiceDetails/ReceivableInvoiceDetails';
import CompletedInvoiceDetails from './CompletedInvoiceDetails/CompletedInvoiceDetails';
import AppLoading from 'components/AppLoading/AppLoading';
import useMultiStep from 'components/MultiStep/useMultiStep';
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
import { AppLoadingProvider } from 'components/AppLoading/AppLoadingContext';
// helpers
import { FilterTabs } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
import { useListContext } from 'components/list/ListContext';
import { InvoiceTypes } from 'components/invoices/types';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
import { PlaidHelper } from 'components/paymentMethods/PaymentMethod/PaymentOptions/usePlaid';
// styles
import styles from './TransactionDetails.module.scss';

export interface IConnectedAccountsData extends PlaidHelper {
  accounts: Account[];
}

interface ITransactionDetails {
  openedInvoiceId: string;
  openInvoiceDetails: (id: string, tab?: FilterTabs) => void;
  closeInvoiceDetails: () => void;
  activeTab: FilterTabs;
  connectedAccountsData?: IConnectedAccountsData;
}
const TransactionDetails: React.FC<ITransactionDetails> = ({
  openedInvoiceId,
  closeInvoiceDetails,
  activeTab,
  openInvoiceDetails,
  connectedAccountsData,
}: ITransactionDetails) => {
  const { t } = useTranslation('transactionHistory');
  const { data = [] } = useListContext();
  const contextValue = useMultiStep(1);
  const invoiceDetails = openedInvoiceId && data.find(({ invoiceId }) => invoiceId === openedInvoiceId);
  const [isLoading, setIsLoading] = useState(false);

  const getInvoiceActions = useCallback(() => {
    if (activeTab === FilterTabs.PAYABLE) {
      return <PayableInvoiceDetails invoiceDetails={invoiceDetails} connectedAccountsData={connectedAccountsData} />;
    }

    const isReceivableInvoice = invoiceDetails.invoiceType === InvoiceTypes.receivable;
    const isReceivableTab = activeTab === FilterTabs.RECEIVABLE;

    if (isReceivableTab || (invoiceDetails?.isReceived && isReceivableInvoice)) {
      return <ReceivableInvoiceDetails invoiceDetails={invoiceDetails} openInvoiceDetails={openInvoiceDetails} />;
    }

    if (invoiceDetails.invoiceType !== InvoiceTypes.fundsTransfer) {
      return <CompletedInvoiceDetails invoiceDetails={invoiceDetails} />;
    }

    return null;
  }, [invoiceDetails, activeTab, openInvoiceDetails]);

  if (!invoiceDetails) {
    return null;
  }

  return (
    <AppLoadingProvider value={{ isLoading, setIsLoading }}>
      <MultiStepProvider value={contextValue}>
        <AppLoading
          isActive={isLoading}
          overlayClassName={styles['loading-overlay']}
          spinnerClassName={styles['loading-spinner']}
        >
          <TransactionCard
            closeCard={closeInvoiceDetails}
            data={invoiceDetails}
            mainBreadcrumbItemTitle={t(`dashboard.${activeTab}`)}
          >
            {getInvoiceActions()}
          </TransactionCard>
        </AppLoading>
      </MultiStepProvider>
    </AppLoadingProvider>
  );
};

export default TransactionDetails;
