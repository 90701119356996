// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
// components
import Modal, { ModalType } from 'components/Modal/Modal';
import WrapperTransferFunds from 'components/accounting/TransferFunds/WrapperTransferFunds';
import PaymentMethod from 'components/paymentMethods/PaymentMethod/PaymentMethod';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import InviteBusiness from 'components/InviteBusiness/InviteBusiness';
import BeneficiaryOwnersModal from 'components/beneficial/BeneficialOwnerModal/BeneficialOwnerModal';
import TwoFactorAuthForm from 'components/Auth/TwoFactorAuthForm/TwoFactorAuthForm';
import Integrations from 'components/Integrations/Integrations';
import CompanyProfileForm from '../../company/CompanyProfileForm/CompanyProfileForm';
import ChangePasswordModal from 'components/settings/AccountTabForm/ChangePasswordModal/ChangePasswordModal';
// RootStore
import ImageCropper from 'components/ImageCropper/ImageCropper';
// constants
import { ModalIds } from 'shared/constants/modalIds';

const GlobalModals: React.FC = () => {
  return (
    <>
      <Modal id={ModalIds.verifyPhone} type={ModalType.minimal}>
        <TwoFactorAuthForm isTitleExist isPhoneVerification />
      </Modal>

      <WrapperTransferFunds />

      <Modal id={ModalIds.paymentMethod}>
        <PaymentMethod />
      </Modal>

      <CompanyProfileForm />

      <ConfirmationModal />

      <InviteBusiness />

      <Integrations />

      <ImageCropper />

      <BeneficiaryOwnersModal modalId={ModalIds.beneficialOwner} />

      <ChangePasswordModal />
    </>
  );
};

export default observer(GlobalModals);
