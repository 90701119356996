import * as Yup from 'yup';
import { getPasswordSchema } from 'shared/helpers/validationSchemes';

export default Yup.object().shape({
  previousPassword: Yup.string().required('forms.password.required'),
  newPassword: getPasswordSchema('forms.newPassword.required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'forms.reEnterNewPassword.match')
    .required('forms.confirmNewPassword.required'),
});
