// libraries
import React from 'react';
// components
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
// helpers
import useFieldHelper from 'components/list/field/fieldHelper';
// types
import { IFieldProps } from 'components/list/field';
// styles
import styles from 'components/contacts/ContactsTable/columns/Business/index.module.scss';

const BusinessColumn = ({ source = '', label }: IFieldProps) => {
  const { getValue } = useFieldHelper({ source, label });
  const { logo, name, tag } = getValue() as any;

  return (
    <ShortEntityCard
      infoWrapperClassName={styles.business__container}
      messageClassName={styles.business__message}
      helpTextClassName={styles['business__help-text']}
      logo={<EntityLogo logoSrc={logo} />}
      message={name}
      helpText={tag}
    />
  );
};

export default BusinessColumn;
