// libraries
import React from 'react';
import classnames from 'classnames';
import { isString } from 'lodash';
// components
import SanitizedHtml from 'components/SanitizedHtml/SanitizedHtml';
// styles
import './ShortEntityCard.scss';

interface IShortEntityCardProps {
  className?: string;
  logo: React.ReactElement;
  message: string | React.ReactElement;
  messageClassName?: string;
  helpText: string;
  helpTextClassName?: string;
  onClickCallback?: Nullable<() => void>;
  additionalInfo?: React.ReactNode;
  infoWrapperClassName?: string;
}

const ShortEntityCard: React.FC<IShortEntityCardProps> = ({
  message,
  messageClassName,
  logo,
  className,
  helpText,
  helpTextClassName,
  onClickCallback,
  additionalInfo,
  infoWrapperClassName,
}: IShortEntityCardProps) => {
  return (
    <div
      className={classnames('entity-short-card', className, { 'is-clickable': !!onClickCallback })}
      onClick={onClickCallback || (() => {})}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
    >
      {logo}
      <div className={classnames('mr-auto entity-short-card__info-wrapper', infoWrapperClassName)}>
        {isString(message) ? (
          <SanitizedHtml
            html={message}
            wrapperTag="p"
            className={classnames('entity-short-card__info', messageClassName)}
          />
        ) : (
          <div className={classnames('entity-short-card__info', messageClassName)}>{message}</div>
        )}
        <p className={classnames('entity-short-card__help-text', helpTextClassName)}>{helpText}</p>
      </div>
      {additionalInfo}
    </div>
  );
};

export default ShortEntityCard;
