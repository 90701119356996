import Crud, { Entity } from 'shared/api/crud/Crud';
import { HttpMethod } from 'shared/api/AbstractRequest';
import { ResponsePromise } from 'shared/api/AbstractRequestManager';

class InviteBusinessApi extends Crud {
  constructor() {
    super('/invites');
  }

  checkEmail = (email: string): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}/check-email`;
    const params = { body: { email }, method: HttpMethod.POST };

    return this.getRequest(resourceUri, params).send();
  };

  sendCheckout = (data: Entity): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}/check-out`;
    const params = { body: { ...data }, method: HttpMethod.POST };

    return this.getRequest(resourceUri, params).send();
  };

  sendInvoice = (data: Entity): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}/send-invoice`;
    const params = { body: { ...data }, method: HttpMethod.POST };

    return this.getRequest(resourceUri, params).send();
  };
}

export default new InviteBusinessApi();
