import AbstractRequest, { RequestInitialParams } from '../AbstractRequest';
import PublicRequestManager from './PublicRequestManager';

class PublicRequest extends AbstractRequest {
  constructor(url: string, params?: RequestInitialParams) {
    const apiPrefix = '/api/v1';
    const protocolRegex = /^(https|http):\/\//;
    let newUrl = url;

    const isUri = !protocolRegex.test(url);

    if (isUri) {
      newUrl = `${apiPrefix}${url}`;
    }

    super(PublicRequestManager, newUrl, params);
  }
}

export default PublicRequest;
