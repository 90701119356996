// libraries
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
// helpers
import stall from 'shared/helpers/simulateAsyncCall';
import { unlockAccount } from 'components/LoginForm/mockData';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// constants
import { ModalIds } from 'shared/constants/modalIds';

export type EmailFormData = {
  email: string;
};

export type EmailChangeFormData = {
  email: string;
};

export type VerificationFormData = {
  token: string;
};

export type UnlockAccountFormData = {
  resetCode: string;
};

export type CreateNewPasswordFormData = {
  password: string;
  confirmPassword: string;
};

export enum PasswordRecoveryFormStep {
  EMAIL,
  VERIFICATION,
  EMAIL_CHANGE,
  CREATE_NEW_PASSWORD,
  UNLOCK_ACCOUNT,
}

interface PasswordRecoveryFormData {
  email: string;
  currentStep: PasswordRecoveryFormStep;
  submitEmailForm: (data: EmailFormData) => void;
  submitVerificationForm: (data: VerificationFormData) => void;
  submitEmailChangeForm: (data: EmailChangeFormData) => void;
  submitCreateNewPasswordForm: (data: CreateNewPasswordFormData) => void;
  handleEmailChange: () => void;
  submitUnlockAccountForm: (data: UnlockAccountFormData, userEmail: string) => void;
  setCurrentStep: Dispatch<SetStateAction<PasswordRecoveryFormStep>>;
}

const usePasswordResetForm = (): PasswordRecoveryFormData => {
  const [currentStep, setCurrentStep] = useState(PasswordRecoveryFormStep.EMAIL);
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const {
    modalStore: { openModal },
  } = useStore();

  const submitEmailForm = useCallback(async (data: EmailFormData) => {
    await stall();
    setCurrentStep(PasswordRecoveryFormStep.VERIFICATION);
    setEmail(data.email);
  }, []);

  const submitVerificationForm = useCallback(async (data: VerificationFormData) => {
    await stall();
    setCurrentStep(PasswordRecoveryFormStep.CREATE_NEW_PASSWORD);
    setToken(data.token);
  }, []);

  const submitEmailChangeForm = useCallback(async (data: EmailChangeFormData) => {
    await stall();
    setCurrentStep(PasswordRecoveryFormStep.VERIFICATION);
    setEmail(data.email);
  }, []);

  const submitUnlockAccountForm = useCallback(async (data: UnlockAccountFormData, userEmail: string) => {
    await stall(data);
    setCurrentStep(PasswordRecoveryFormStep.CREATE_NEW_PASSWORD);
    // TODO: Remove unlockAccount after integration
    unlockAccount(userEmail);
  }, []);

  const submitCreateNewPasswordForm = useCallback(async (data: CreateNewPasswordFormData) => {
    await stall();
    openModal(ModalIds.successPasswordChange);
  }, []);

  const handleEmailChange = useCallback(() => {
    setCurrentStep(PasswordRecoveryFormStep.EMAIL_CHANGE);
  }, []);

  return {
    currentStep,
    email,
    submitEmailForm,
    submitVerificationForm,
    submitEmailChangeForm,
    submitCreateNewPasswordForm,
    handleEmailChange,
    submitUnlockAccountForm,
    setCurrentStep,
  };
};

export default usePasswordResetForm;
