// libraries
import React from 'react';
// constants
import { SettingsPagesName } from 'router/configs/settings';
// assets
import { ReactComponent as UserIcon } from 'assets/icons/user-icon.svg';
import { ReactComponent as UserActiveIcon } from 'assets/icons/user-fill-blue-icon.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { ReactComponent as PaymentActiveIcon } from 'assets/icons/payment-fill-blue.svg';
import { ReactComponent as AccountingIcon } from 'assets/icons/accounting.svg';
import { ReactComponent as AccountingActiveIcon } from 'assets/icons/accounting-fill-blue.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import { ReactComponent as DocumentActiveIcon } from 'assets/icons/document-fill-blue.svg';

export default [
  {
    name: SettingsPagesName.ACCOUNT,
    path: '/settings',
    exact: true,
    icon: <UserIcon width="14" height="14" />,
    activeIcon: <UserActiveIcon width="14" height="14" />,
  },
  {
    name: SettingsPagesName.CONNECTED_ACCOUNTS,
    path: '/settings/payment-methods',
    exact: true,
    icon: <PaymentIcon width="14" height="14" />,
    activeIcon: <PaymentActiveIcon width="14" height="14" />,
  },
  {
    name: SettingsPagesName.INTEGRATIONS,
    path: '/settings/integrations',
    exact: true,
    icon: <AccountingIcon width="14" height="14" />,
    activeIcon: <AccountingActiveIcon width="14" height="14" />,
  },
  {
    name: SettingsPagesName.DOCUMENTS,
    path: '/settings/documents',
    exact: true,
    icon: <DocumentIcon width="14" height="14" />,
    activeIcon: <DocumentActiveIcon width="14" height="14" />,
  },
];
