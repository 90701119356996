// libraries
import React from 'react';
import { Link } from 'react-router-dom';
// styles
import commonStyles from 'components/EntryTitle/EntryTitle.module.scss';
import subtitleWithLinkStyles from 'components/EntryTitle/subtitles/SubtitleWithLink/SubtitleWithLink.module.scss';

interface ISubtitleWithLinkProps {
  subtitle: string;
  linkText: string;
  link: string;
}

const SubtitleWithLink: React.FC<ISubtitleWithLinkProps> = ({ subtitle, link, linkText }: ISubtitleWithLinkProps) => (
  <div className={commonStyles.subtitle}>
    <p className={commonStyles.subtitle__text}>{subtitle}</p>
    <Link className={subtitleWithLinkStyles.subtitle__link} to={link}>
      {linkText}
    </Link>
  </div>
);

export default SubtitleWithLink;
