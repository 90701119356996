// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// assets
import { ReactComponent as Icon } from 'assets/icons/empty-history-icon.svg';
// styles
import './EmptyInvoiceHistoryMessage.scss';

interface EmptyInvoiceHistoryMessageProps {
  title?: string;
  description?: string;
}

const EmptyInvoiceHistoryMessage: React.FC<EmptyInvoiceHistoryMessageProps> = ({
  title,
  description,
}: EmptyInvoiceHistoryMessageProps) => {
  const { t } = useTranslation('transactionHistory');

  return (
    <div className="empty-invoice-history__wrapper">
      <div className="empty-invoice-history__inner">
        <Icon className="empty-invoice-history__icon" />
        <div>
          <p className="empty-invoice-history__title">{title || t('emptyHistory.title')}</p>
          <p className="empty-invoice-history__description">{description || t('emptyHistory.description')}</p>
        </div>
      </div>
    </div>
  );
};

export default EmptyInvoiceHistoryMessage;
