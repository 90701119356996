// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EmailForm from 'components/resetPassword/EmailForm/EmailForm';
import EntryTitle from 'components/EntryTitle/EntryTitle';
// constants
import { EmailFormData } from 'components/resetPassword/usePasswordResetForm';
// styles
import styles from 'router/views/ResetPassword/index.module.scss';

interface IEmailProps {
  submitEmailForm: (data: EmailFormData) => void;
}

const Email: React.FC<IEmailProps> = ({ submitEmailForm }: IEmailProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <>
      <EntryTitle
        customClass={styles['entry-title__wrapper']}
        title={t('email.title')}
        subtitle={t('email.subtitle')}
      />
      <EmailForm submitEmailForm={submitEmailForm} />
    </>
  );
};

export default Email;
