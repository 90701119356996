// libraries
import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
// components
import Tabs from 'components/transactions/TransactionHistory/Tabs/Tabs';
import HistoryDurationFilter from 'components/transactions/TransactionHistory/filters/HistoryDurationFilter';
import TransactionsList from 'components/transactions/TransactionHistory/TransactionList/TransactionList';
import InvoiceDetails from '../TransactionDetails';
import List from 'components/list/List';
import TransactionsDashboard from 'components/transactions/TransactionsDashboard/TransactionsDashboard';
// helpers
import useTransactionHistory from 'components/transactions/TransactionHistory/useTransactionHistory';
import { ASC } from 'components/list/listHelper';
import useWindowSize, { DESKTOP_MIN_WIDTH } from 'shared/helpers/useWindowSize';
import useMyBusinessProfile from 'components/company/CompanyProfileForm/useMyBusinessProfile';
import usePlaid from 'components/paymentMethods/PaymentMethod/PaymentOptions/usePlaid';
import useConnectedAccounts from 'components/paymentMethods/useConnectedAccounts';
// styles
import styles from 'components/transactions/TransactionHistory/TransactionHistory.module.scss';

const TransactionHistory: React.FC = () => {
  const {
    activeTab,
    changeTab,
    data,
    shouldResetListParams,
    openInvoiceDetails,
    openedInvoiceId,
    closeInvoiceDetails,
  } = useTransactionHistory();
  const { width } = useWindowSize();
  const isMobile = useMemo(() => !!width && width < DESKTOP_MIN_WIDTH, [width]);
  const { reloadData: reloadMyBusinessProfile } = useMyBusinessProfile();
  const { accounts } = useConnectedAccounts();
  const connectedAccountsData = usePlaid({
    submitCallback: () => reloadMyBusinessProfile(),
  });

  return (
    <div className="transaction-history">
      <List
        data={data}
        defaultSortOrder={ASC}
        defaultSortField="dueDate"
        shouldResetParams={shouldResetListParams}
        idSource="invoiceId"
        searchSources={['counterpartyDetails.dbaName', 'counterpartyDetails.bankName']}
        onRemove={closeInvoiceDetails}
      >
        <div className={styles['transaction-history__controls']}>
          <Tabs activeTab={activeTab} changeTab={changeTab} />
          <HistoryDurationFilter activeTab={activeTab} closeInvoiceDetails={closeInvoiceDetails} allInvoices={data} />
        </div>
        <Row>
          <Col xs={10} md={6}>
            <TransactionsList
              openInvoiceDetails={openInvoiceDetails}
              openedInvoiceId={openedInvoiceId}
              closeInvoiceDetails={closeInvoiceDetails}
              activeTab={activeTab}
            />
          </Col>
          <Col xs={10} md={4}>
            {openedInvoiceId && (
              <InvoiceDetails
                openedInvoiceId={openedInvoiceId}
                closeInvoiceDetails={closeInvoiceDetails}
                activeTab={activeTab}
                openInvoiceDetails={openInvoiceDetails}
                connectedAccountsData={{ ...connectedAccountsData, accounts }}
              />
            )}
            <TransactionsDashboard activeTab={activeTab} isVisible={!openedInvoiceId || isMobile} />
          </Col>
        </Row>
      </List>
    </div>
  );
};

export default observer(TransactionHistory);
