// libraries
import React from 'react';
// styles
import styles from './AddBillPage.module.scss';

type AddBillPageProps = {
  children: React.ReactNode;
  wrapperClassName: string;
};

const AddBillPage: React.FC<AddBillPageProps> = ({ children, wrapperClassName }: AddBillPageProps) => {
  return (
    <div className={styles.page}>
      <div className={wrapperClassName}>{children}</div>
    </div>
  );
};

export default AddBillPage;
