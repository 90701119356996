import VerificationStatus from 'shared/constants/verificationStatus';

export type SelectedCompanyInfoProps = {
  logo: string | undefined;
  isNewVendor: boolean;
  verificationStatus?: VerificationStatus;
};

export enum AddBillFormStep {
  ADD_BILL_FORM = 0,
  ADD_BILL_FORM_CONFIRM = 1,
}

export enum AddBillFormData {
  vendor = 'vendor',
  contactName = 'contactName',
  email = 'email',
  amount = 'amount',
  invoiceNumber = 'invoiceNumber',
  dueDate = 'dueDate',
  message = 'message',
}

export type AddBillFormDataFromInvoice = {
  customer: string | undefined;
  amount: number;
  invoiceNumber: string;
  dueDate: number;
  message: string;
  invoiceId: string;
};
