import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { StoreProvider } from 'shared/stores/RootStore/storeContext';
import WebsocketContextProvider from 'shared/websocket/websocketContext';
import createStore from 'shared/stores/RootStore';

const rootStore = createStore();

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={rootStore}>
      <WebsocketContextProvider>
        <App />
      </WebsocketContextProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
