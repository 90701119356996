// libraries
import React from 'react';
// components
import TableHead, { ITableHeadProps } from 'components/list/layout/TableHead';
import TableBody, { ITableBodyProps } from 'components/list/layout/TableBody';
import Scrollbar from 'components/Scrollbar/Scrollbar';
// styles
import './DataGridLayout.scss';

interface IDataGridLayoutProps extends ITableHeadProps, ITableBodyProps {
  tableWrapperClassName?: string;
  gridTemplateColumns?: string;
}

/**
 * Layout to display list as table.
 * @param {(React.ReactElement & React.ReactNode) | (React.ReactElement[] & React.ReactNode)} children - fields
 * @param {React.ReactElement[]} buttons - action buttons for rows
 * @param {string} tableWrapperClassName - className for table wrapper
 * @param {string} baseGridTemplateColumns - column width. If not set, they will have the same width.
 * @param {string} rowKeySource - source in data for row key value
 * @param {(instance: FieldDataInstance) => Dictionary<any>} rowProps - property that each row will have. It function that accept data and return object like data => data.highlighted ? {className: 'highlighted'} : {}.
 * @param { number } tableMaxHeight - max height of container
 * @return {any}
 * @constructor
 */
const DataGridLayout: React.FC<IDataGridLayoutProps> = ({
  children,
  button,
  tableWrapperClassName = '',
  gridTemplateColumns: baseGridTemplateColumns,
  ...tableProps
}: IDataGridLayoutProps) => {
  const gridTemplateColumns = baseGridTemplateColumns || `repeat(${React.Children.count(children)}, 1fr)`;

  return (
    <div className={`table__wrapper ${tableWrapperClassName}`}>
      <Scrollbar>
        <div className="table__inner">
          <TableHead gridTemplateColumns={gridTemplateColumns} button={button}>
            {children}
          </TableHead>
          <TableBody {...tableProps} gridTemplateColumns={gridTemplateColumns} button={button}>
            {children}
          </TableBody>
        </div>
      </Scrollbar>
    </div>
  );
};

export default DataGridLayout;
