// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EntryTitle from 'components/EntryTitle/EntryTitle';
import BusinessInformationForm from 'components/verification/Business/BusinessDetailsForm/BusinessInformationForm';
// styles
import styles from '../Verification.module.scss';

const BusinessInformation: React.FC = () => {
  const { t } = useTranslation('verification');

  return (
    <div className={styles['form-wrapper']}>
      <EntryTitle
        // customClass={styles['verification-header']}
        title={t('businessDetails.title')}
        subtitle={t('businessDetails.subtitle')}
      />
      <BusinessInformationForm />
    </div>
  );
};

export default BusinessInformation;
