import { format, parseISO, differenceInMinutes, isThisYear } from 'date-fns';
import { Address } from 'components/User/UserProfile/useUserProfile';
import { USA_COUNTRY } from './countries';

export const MINUTES_IN_HOUR = 60;
export const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;
export const MINUTES_IN_WEEK = MINUTES_IN_DAY * 7;
export const CENTS_IN_DOLLAR = 100;

export enum CurrencySignDisplay {
  auto = 'auto',
  never = 'never',
  always = 'always',
  exceptZero = 'exceptZero',
}

const AMOUNT_LOCALE_OPTIONS = {
  style: 'currency',
  currency: 'USD',
};

export enum DateFormats {
  full = 'MMM dd, yyyy',
  short = 'LLL dd',
  documentsDate = 'MMMM yyyy',
  timestamp = 'MM.dd.yyyy_hh.mm.ss',
  shortISO = 'yyyy-MM-dd',
  fullDateTime = "dd MMM, yyyy 'at' HH:mm",
  invoiceDate = 'MMMM, do',
  fullInvoiceDate = 'MMMM do, yyyy',
  invoiceDatepicker = 'dd MMMM yyyy',
  externalPaymentDatePicker = 'MM/dd/yyyy',
  invoiceReviewDate = 'MMMM d, yyyy',
}

export const formatDate = (date?: Nullable<string | Date>, dateFormat?: string): string => {
  if (!date) {
    return '';
  }

  return format(typeof date === 'string' ? parseISO(date) : date, dateFormat || DateFormats.full);
};

export const formatAmountToLocal = (amount: number): string => amount.toLocaleString('en-US', AMOUNT_LOCALE_OPTIONS);

export const formatAmount = (amount: number, isSender: boolean): string => {
  const prefix = isSender ? '-' : '+';

  return `${prefix}${formatAmountToLocal(amount)}`;
};

export const formatStatusMessage = (completedAt: string, status: string): string => {
  if (completedAt) {
    const parsedDate = parseISO(completedAt);
    const timeDifference = differenceInMinutes(new Date(), parsedDate);

    if (timeDifference < MINUTES_IN_HOUR) {
      return `${timeDifference.toString()}m`;
    }

    if (timeDifference < MINUTES_IN_DAY) {
      return `${Math.round(timeDifference / MINUTES_IN_HOUR).toString()}h`;
    }

    if (isThisYear(parsedDate)) {
      return format(parsedDate, DateFormats.short);
    }

    return format(parsedDate, DateFormats.full);
  }

  return status;
};

export const convertCentsToDollars = (value: number | string): number => {
  return value ? +value / CENTS_IN_DOLLAR : +value;
};

export const convertDollarsToCents = (value: number | string): string => {
  return (+value * 100).toFixed(0);
};

export const formatCurrency: (
  value?: number,
  inCents?: boolean,
  signDisplay?: CurrencySignDisplay,
  currency?: string,
  locale?: string,
) => string = (value = 0, inCents = true, signDisplay, currency, locale) => {
  const formatter = new Intl.NumberFormat(locale || 'en-US', {
    style: 'currency',
    currency: currency || 'USD',
    signDisplay: signDisplay || CurrencySignDisplay.auto,
  } as Intl.NumberFormatOptions);

  const newValue = inCents ? convertCentsToDollars(value) : +value;

  return formatter.format(newValue || 0);
};

export const convertToUTC = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const normalizeUrlHref = (url: string): string => {
  const protocolRegex = /^(https|http):\/\//;
  let result = url;

  const isUri = protocolRegex.test(url);

  if (!isUri) {
    result = `//${url}`;
  }

  return result;
};

export const formatAddress = (address?: Partial<Address>): string => {
  const { line1, city, state, country, zip, region, postalCode } = address || {};

  if (line1 && city && state && country && zip && country === USA_COUNTRY) {
    return `${line1}, ${city}, ${state}, ${country}, ${zip}`;
  }

  if (line1 && city && region && country && postalCode && country !== USA_COUNTRY) {
    return `${line1}, ${city}, ${region}, ${country}, ${postalCode}`;
  }

  return '';
};
