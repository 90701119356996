import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';
import { Attachment } from 'components/transactions/AttachedDocuments/AttachedDocuments';

export type Document = {
  id: number;
  file: Attachment[];
  title: string;
  subtitle: string;
};

class DocumentStore {
  documents: Nullable<Document[]> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setDocuments = (data: Document[]): void => {
    this.documents = data;
  };

  setDataFromMessage = (data: Document[]): void => {
    this.documents = data;
  };

  [CLEAR_STORE] = (): void => {
    this.documents = [];
  };
}

export default DocumentStore;
