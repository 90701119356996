// libraries
import React from 'react';
// components
import PopupNotifications from './PopupNotifications/PopupNotifications';
import AddFundsPopup from './PopupNotifications/AddFundsPopup/AddFundsPopup';
import FirstLoginPopupNotification from './FirstLoginPopupNotification/FirstLoginPopupNotification';
import VerificationNotification from './VerificationNotification/VerificationNotification';
import usePopupNotification from './PopupNotifications/usePopupNotification';

const Notifications: React.FC = () => {
  usePopupNotification(true);

  return (
    <>
      <PopupNotifications />
      <AddFundsPopup />
      <FirstLoginPopupNotification />
      <VerificationNotification />
    </>
  );
};

export default Notifications;
