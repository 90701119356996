// libraries
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { includes } from 'lodash';
import { decode } from 'html-entities';
// components
import Button from 'components/Button';
import Modal, { ModalType } from 'components/Modal/Modal';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import { replaceTag } from 'shared/helpers/replaceTag';
import { PopupButtons } from '../inviteBusinessHelper';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// styles
import styles from './WarningPopup.module.scss';

type WarningPopupProps = {
  errorMessage: Nullable<string>;
  forceFormSubmission: () => void;
  handleCloseModals: () => void;
};

const WarningPopup: React.FC<WarningPopupProps> = ({
  errorMessage,
  forceFormSubmission,
  handleCloseModals,
}: WarningPopupProps) => {
  const {
    modalStore: { closeModal },
  } = useStore();
  let rightButton: Nullable<JSX.Element> = null;
  let leftButton: Nullable<JSX.Element> = null;

  const handleCloseWarning = useCallback(() => {
    closeModal(ModalIds.inviteWarningPopup);
  }, [closeModal]);

  const htmlParserOptions: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (!includes(PopupButtons, domNode.name)) {
        return replaceTag('a');
      }

      const buttonText = domNode.children[0].data;
      if (domNode.name === PopupButtons.sendInvite) {
        rightButton = <Button handleClick={forceFormSubmission}>{buttonText}</Button>;
      }

      if (domNode.name === PopupButtons.cancelInvite) {
        leftButton = (
          <Button handleClick={handleCloseWarning} outline>
            {buttonText}
          </Button>
        );
      }

      if (domNode.name === PopupButtons.sendInvoice) {
        rightButton = <Button handleClick={forceFormSubmission}>{buttonText}</Button>;
      }

      if (domNode.name === PopupButtons.discardInvoice) {
        leftButton = (
          <Button handleClick={handleCloseModals} outline>
            {buttonText}
          </Button>
        );
      }

      return <span className={styles['hidden-element']} />;
    },
  };

  if (!errorMessage) {
    return null;
  }

  return (
    <Modal id={ModalIds.inviteWarningPopup} type={ModalType.minimal} handleCloseModal={handleCloseWarning}>
      <p className={styles['warning-popup__text']}>{parse(decode(errorMessage), htmlParserOptions)}</p>
      <div className={styles['warning-popup__controls']}>
        {leftButton} {rightButton}
      </div>
    </Modal>
  );
};

export default observer(WarningPopup);
