// libraries
import React, { FormEvent, useRef, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// assets
import GoogleLogoPath from 'assets/images/google-logo.png';
// components
import { TextInput } from '../index';
import { InputElements } from '../Input';
import { TextInputProps } from '../TextInput/TextInput';
// context
import { useFormContext } from 'components/Form/FormContext';
// helpers
import { usePlaces } from 'shared/helpers/placesHelpers';
import useAutocompleteInput from './useAutocompleteInput';
import { DEFAULT_SEARCH_OPTIONS } from './autocompleteInputHelpers';
import useCloseByOutsideAction from 'shared/helpers/useCloseByOutsideAction';
// styles
import styles from './AutocompleteInput.module.scss';

interface AutocompleteInputProps extends TextInputProps {
  searchOptions?: {
    types: string[];
  };
  namePrefix?: string;
  errorLabel?: string;
  predifinedValue?: string | null;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  inputWrapperClassName = '',
  fieldWrapperClassName = '',
  fieldLabelClassName = '',
  onBlur,
  name,
  namePrefix,
  searchOptions = DEFAULT_SEARCH_OPTIONS,
  label = '',
  errorLabel = 'address',
  disabled,
  predifinedValue,
  additionalOnChangeCallBack,
  ...inputCommonProps
}: AutocompleteInputProps) => {
  const { onError } = usePlaces();
  const { setFieldValues = () => {} } = useFormContext();

  const {
    inputValue,
    handleSelect,
    handleBlur,
    handleChangeValue,
    isVisibleResults,
    handleCloseResults,
    handleFocus,
    setInputValue,
    setIsVisibleResults,
  } = useAutocompleteInput(
    onBlur as (event?: FormEvent<InputElements> | undefined) => void,
    name,
    namePrefix,
    additionalOnChangeCallBack,
  );

  useEffect(() => {
    if (predifinedValue) {
      setIsVisibleResults(true);
      setInputValue(predifinedValue);
      setFieldValues(name, predifinedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predifinedValue]);

  const { t } = useTranslation('forms');
  const resultsRef = useRef<Nullable<HTMLDivElement>>(null);
  useCloseByOutsideAction(handleCloseResults, resultsRef);

  const InputElement = ({ className: inputClassName }: TextInputProps) => {
    return (
      <PlacesAutocomplete
        value={inputValue}
        onChange={handleChangeValue}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        onError={(status: string, clearSuggestions: CallableFunction) => onError(status, clearSuggestions, errorLabel)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <div>
              <input
                name={name}
                {...inputCommonProps}
                {...getInputProps()}
                placeholder={inputCommonProps.placeholder || t('inputPlaceholder')}
                onBlur={handleBlur}
                className={inputClassName}
                autoComplete="off"
                disabled={disabled}
                onFocus={handleFocus}
              />
              {!!suggestions.length && isVisibleResults && (
                <div ref={resultsRef} className={styles.suggestion__list}>
                  {suggestions.map(suggestion => {
                    const className = classNames(styles.suggestion__item, {
                      [styles['suggestion__item--active']]: suggestion.active,
                    });

                    const suggestionProps = getSuggestionItemProps(suggestion, { className });

                    return (
                      <div {...suggestionProps} key={suggestion.description}>
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                  <div className={styles['google-logo']}>
                    <img className={styles['google-logo__img']} src={GoogleLogoPath} alt="google logo" />
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  };

  return (
    <TextInput
      name={name || 'address'}
      inputElement={InputElement}
      inputWrapperClassName={inputWrapperClassName}
      fieldWrapperClassName={fieldWrapperClassName}
      fieldLabelClassName={fieldLabelClassName}
      autoComplete="off"
      label={label}
    />
  );
};

export default AutocompleteInput;
