// libraries
import React, { useCallback, useState, useEffect, useLayoutEffect } from 'react';
import { isEmpty } from 'lodash';
import ReactCodeInput from 'react-verification-code-input';
import classnames from 'classnames';
// styles
import oneTimeCodeStyles from 'components/Form/inputs/OneTimeCodeInput/OneTimeCodeInput.module.scss';
import 'components/Form/inputs/TextInput/TextInput.module.scss';
// components
import { useFormContext } from 'components/Form/FormContext';
import TextInput, { TextInputProps } from 'components/Form/inputs/TextInput/TextInput';
// helpers
import { InputCommonProps } from 'components/Form/inputs/Input';

interface IOneTimeCodeInputProps extends TextInputProps {
  fieldHeight?: number;
  fieldWidth?: number;
  fieldsCount?: number;
  autoFocus?: boolean;
}

const OneTimeCodeInput: React.FC<IOneTimeCodeInputProps> = (props: IOneTimeCodeInputProps) => {
  const { setFieldValues, setFieldErrors, getErrorValue } = useFormContext();
  const ref = React.useRef<HTMLDivElement>(null);

  const { name, fieldWidth = 54, fieldHeight = 59, fieldsCount = 4, autoFocus = true, onChangeCallback } = props;

  const [code, setCode] = useState<Nullable<string>>(null);

  const resetError = useCallback(() => {
    if (setFieldErrors) {
      setFieldErrors(name, []);
    }
  }, [name]);

  useEffect(() => {
    if (code) {
      resetError();
    }
  }, [code]);

  useLayoutEffect(() => {
    if (ref.current) {
      const inputFields = ((ref.current.children[0]?.children[0]?.childNodes as unknown) as HTMLInputElement[]) || [];

      inputFields.forEach((item: HTMLInputElement, index: number) => {
        if (index !== 0) {
          // eslint-disable-next-line no-param-reassign
          item.disabled = true;
        }
      });
    }
  }, [ref]);

  const InputElement = ({ disabled, name: nameField }: InputCommonProps) => {
    const handleChange = useCallback(
      val => {
        setCode(val);

        if (ref.current) {
          const inputFields =
            ((ref.current.children[0]?.children[0]?.childNodes as unknown) as HTMLInputElement[]) || [];

          inputFields.forEach((item: HTMLInputElement, index: number) => {
            if (index === val.length) {
              // eslint-disable-next-line no-param-reassign
              item.disabled = false;
              item.focus();
            }
          });
        }

        if (onChangeCallback) {
          onChangeCallback(val);
        }

        if (setFieldValues) {
          setFieldValues(name, val);
        }
      },
      [name],
    );

    return (
      <div ref={ref}>
        <ReactCodeInput
          autoFocus={autoFocus}
          disabled={disabled}
          onChange={handleChange}
          fieldHeight={fieldHeight}
          fieldWidth={fieldWidth}
          fields={fieldsCount}
          className={classnames(oneTimeCodeStyles['one-time-code__container'], {
            [oneTimeCodeStyles['is-invalid']]: getErrorValue && getErrorValue(nameField)?.length,
          })}
        />
      </div>
    );
  };

  return <TextInput {...props} inputElement={InputElement} />;
};

export default OneTimeCodeInput;
