// libraries
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import { TextInput } from 'components/Form/inputs';
import Button from 'components/Button';
// helpers
import { useFormContext } from 'components/Form/FormContext';
import { AccountTabFormFields, AccountDataProps } from '../types';
import useAccountTabForm from 'components/settings/AccountTabForm/useAccountTabForm';
// styles
import styles from 'components/settings/AccountTabForm//AccountTabForm.module.scss';
import accountFieldsStyles from './AccountFields.module.scss';

type AccountFieldsDataProps = {
  data: AccountDataProps;
  handleOnValueChange: () => void;
};

const AccountFields: React.FC<AccountFieldsDataProps> = ({ data, handleOnValueChange }: AccountFieldsDataProps) => {
  const { t } = useTranslation('settingsPage');
  const { setFieldValues = () => {} } = useFormContext();
  const { openChangePasswordModal } = useAccountTabForm();
  const { firstName, lastName, email } = data;

  useEffect(() => {
    setFieldValues(AccountTabFormFields.firstName, firstName);
    setFieldValues(AccountTabFormFields.lastName, lastName);
    setFieldValues(AccountTabFormFields.email, email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TextInput
        name={AccountTabFormFields.firstName}
        label={t('accountBlock.fields.firstName.label')}
        placeholder={t('accountBlock.fields.firstName.placeholder')}
        fieldLabelClassName={styles['field-label']}
        fieldWrapperClassName={styles.field__wrapper}
        inputWrapperClassName={styles.input__wrapper}
        additionalOnChangeCallBack={handleOnValueChange}
      />
      <TextInput
        name={AccountTabFormFields.lastName}
        label={t('accountBlock.fields.lastName.label')}
        placeholder={t('accountBlock.fields.lastName.placeholder')}
        fieldLabelClassName={styles['field-label']}
        fieldWrapperClassName={styles.field__wrapper}
        inputWrapperClassName={styles.input__wrapper}
        additionalOnChangeCallBack={handleOnValueChange}
      />
      <TextInput
        name={AccountTabFormFields.email}
        label={t('accountBlock.fields.email.label')}
        placeholder={t('accountBlock.fields.email.placeholder')}
        fieldLabelClassName={styles['field-label']}
        fieldWrapperClassName={styles.field__wrapper}
        inputWrapperClassName={styles.input__wrapper}
        additionalOnChangeCallBack={handleOnValueChange}
      />
      <div className={classNames(styles.field__wrapper, accountFieldsStyles['password-field__wrapper'])}>
        <p className={classNames(styles['field-label'], accountFieldsStyles['password-field__label'])}>
          {t('accountBlock.fields.password.label')}
        </p>
        <div className={accountFieldsStyles['password-field']}>
          <span className={accountFieldsStyles['password-field__stars']}>
            {t('accountBlock.fields.password.stars')}
          </span>
          <Button className={accountFieldsStyles['password-field__link']} handleClick={openChangePasswordModal}>
            {t('accountBlock.fields.password.linkText')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AccountFields;
