import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// constants
import { RegistrationCompanySteps } from 'router/views/RegistrationCompany/RegistrationCompany';
// components
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// styles
import styles from './RegistrationCompnayButtons.module.scss';

type TRegistrationCompanyButtonsProps = {
  isButtonsDisabled: boolean;
  buttonWrapperClassName?: string;
};

const RegistrationCompanyButtons: React.FC<TRegistrationCompanyButtonsProps> = ({
  isButtonsDisabled,
  buttonWrapperClassName = '',
}: TRegistrationCompanyButtonsProps) => {
  const { t } = useTranslation('registrationCompanyModals');
  const { currentStep, goToNextStep } = useMultiStepContext();

  const submitText = useMemo(() => {
    return currentStep === RegistrationCompanySteps.ABOUT
      ? t('aboutModal.submitButtonText')
      : t('additionalModal.submitButtonText');
  }, [currentStep]);

  const handleCancelClick = useCallback(() => {
    if (goToNextStep && currentStep) {
      goToNextStep(currentStep, {});
    }
  }, [currentStep, goToNextStep]);

  return (
    <div className={styles['button__wrapper-line']}>
      <div className={classNames(styles.button__wrapper, buttonWrapperClassName)}>
        <Button btnLink className={styles.button__cancel} disabled={isButtonsDisabled} handleClick={handleCancelClick}>
          {t('skitButtonText')}
        </Button>
        <SubmitButton className={styles.button__submit} disabled={isButtonsDisabled}>
          {submitText}
        </SubmitButton>
      </div>
    </div>
  );
};

export default RegistrationCompanyButtons;
