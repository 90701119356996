// libraries
import React from 'react';
// components
import PublicLayout from 'shared/layouts/public';
import AppLoading from 'components/AppLoading/AppLoading';
import NotFound from 'router/views/NotFound/NotFound';
// helpers
import useRestrictedFileRoutes from 'components/RestrictedFiles/useRestrictedFileRoutes';

const RestrictedFiles: React.FC = () => {
  const { isLoading, fileUrl } = useRestrictedFileRoutes();

  if (!isLoading && fileUrl) {
    window.location.replace(fileUrl);
  }

  if (!isLoading && !fileUrl) {
    return <NotFound />;
  }

  return (
    <PublicLayout>
      <AppLoading />
    </PublicLayout>
  );
};

export default RestrictedFiles;
