// libraries
import React, { useContext } from 'react';

export interface IAppLoadingContextData {
  isLoading: boolean;
  setIsLoading: (id: boolean) => void;
}

const defaultContext = {
  isLoading: false,
  setIsLoading: () => {},
};

const AppLoadingContext = React.createContext<IAppLoadingContextData>(defaultContext);

export const AppLoadingProvider = AppLoadingContext.Provider;

export const useAppLoadingContext = () => useContext(AppLoadingContext);

export default AppLoadingContext;
