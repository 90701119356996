// libraries
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
// components
import Form from 'components/Form';
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import StripeForm, {
  StripeFormColorTheme,
  StripeFormRowsIndent,
} from 'components/paymentMethods/StripeForm/StripeForm';
import AddressForm, {
  AddressFormColorTheme,
  AddressFormRowsIndent,
} from 'components/paymentMethods/AddressForm/AddressForm';
// helpers
import { USA_COUNTRY } from 'shared/helpers/countries';
import ADDRESS_SCHEMA from 'components/Form/inputGroups/AddressField/validation-rules';
import useStripeForm from 'components/paymentMethods/StripeForm/useStripeForm';
import STRIPE_FORM_SCHEMA from 'components/paymentMethods/StripeForm/validation-rules';
import { PaymentMethodData, PaymentMethodParams } from 'components/paymentMethods/PaymentMethod/usePaymentMethod';
import { addErrorToast } from 'components/Toast/toastHelper';
// styles
import styles from './CreditCard.module.scss';

type CreditCardProps = Pick<PaymentMethodParams, 'addStripeCard'>;

const CreditCard: React.FC<CreditCardProps> = ({ addStripeCard }) => {
  const { t } = useTranslation(['connectedAccountsPage', 'forms']);

  const {
    fieldsState,
    onChange,
    toggleFocus,
    submitCardForm,
    submitAddressForm,
    isCardFormVisible,
    backToCardDetails,
  } = useStripeForm();

  const handleSubmitForm = useCallback(
    async data => {
      try {
        const { payment_method: paymentMethod } = submitAddressForm(data);
        await addStripeCard(paymentMethod as PaymentMethodData);
      } catch (e) {
        const { message } = await e;
        // addErrorToast(message);
      }
    },
    [submitAddressForm, addStripeCard],
  );

  const stripeFormStyles = classNames('', { [styles.form__hidden]: !isCardFormVisible });
  const addressFormStyles = classNames('', { [styles.form__hidden]: isCardFormVisible });

  return (
    <div className={styles.form}>
      <Form submitForm={submitCardForm} validationSchema={STRIPE_FORM_SCHEMA} className={stripeFormStyles}>
        <div className={styles.form__title}>{t('paymentMethods.creditCardTitle')}</div>
        <StripeForm
          onChange={onChange}
          fieldsState={fieldsState}
          toggleFocus={toggleFocus}
          rowsIndent={StripeFormRowsIndent.EXTENDED}
          colorTheme={StripeFormColorTheme.LIGHT}
          controlsClassName={styles.form__controls}
        />
      </Form>
      <Form
        submitForm={handleSubmitForm}
        validationSchema={ADDRESS_SCHEMA}
        data={{ country: USA_COUNTRY }}
        className={addressFormStyles}
      >
        <div className={styles.form__title}>{t('paymentMethods.addressTitle')}</div>
        <AddressForm
          menuPlacement="top"
          isUsaOnly
          rowsIndent={AddressFormRowsIndent.EXTENDED}
          colorTheme={AddressFormColorTheme.LIGHT}
        />
        <div className={styles.address__container}>
          <Button outline className={styles.address__btn} handleClick={backToCardDetails}>
            {t('forms:backButtonDefaultText')}
          </Button>
          <SubmitButton>{t('forms:addCardButtonDefaultText')}</SubmitButton>
        </div>
      </Form>
    </div>
  );
};

CreditCard.propTypes = {
  addStripeCard: func.isRequired,
};

export default CreditCard;
