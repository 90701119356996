// libraries
import React from 'react';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
// components
import BaseLayout from 'shared/layouts/basic';
import CompanySearch from 'components/company/CompanySearch/CompanySearch';
import CompanyDetails from 'components/company/CompanyDetails/CompanyDetails';

const SendInvoice: React.FC = () => {
  // TODO: remove after the integration
  const currentCompanyId = '2';
  const { id } = useParams<Dictionary<string>>();

  if (id === currentCompanyId) {
    return <Redirect to="/profile" />;
  }

  return (
    <>
      <BaseLayout>{id ? <CompanyDetails /> : <CompanySearch />}</BaseLayout>
    </>
  );
};

export default SendInvoice;
