// libraries
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// helpers
import { buildQueryString } from 'shared/helpers/api';
import { PaymentTabs } from '../Tabs';

type TPaymentHistoryHelper = {
  activeTab: PaymentTabs;
  changeTab: (tabName: PaymentTabs) => void;
};

type TPaymentHistoryParams = {
  signUpType: PaymentTabs;
  [key: string]: string | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUrlStorageParams = (location: any): Dictionary<string> => {
  const currentSearchParams = new URLSearchParams(location.search) || {};

  return { signUpType: currentSearchParams.get('signUpType') || PaymentTabs.SIGN_UP };
};

const useTabs = (): TPaymentHistoryHelper => {
  const [activeTab, setActiveTab] = useState(PaymentTabs.SIGN_UP);
  const history = useHistory();
  const location = useLocation();

  const [params] = useState(getUrlStorageParams(location));

  const updateParamsInUrl = ({ signUpType = activeTab }: TPaymentHistoryParams) => {
    const searchString = buildQueryString({
      signUpType,
    });

    if (location.search !== searchString) {
      history.replace({
        search: searchString,
      });
    }
  };

  const changeTab = (tabName: PaymentTabs) => {
    updateParamsInUrl({ signUpType: tabName });
    setActiveTab(tabName);
  };

  useEffect(() => {
    setActiveTab((params?.signUpType as PaymentTabs) || PaymentTabs.SIGN_UP);
  }, [params]);

  return {
    activeTab,
    changeTab,
  };
};

export default useTabs;
