// libraries
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
// helpers
import FieldData, { IData } from 'components/list/field/FieldData';

export type TFieldDataInstance = any;

interface IFieldContextData {
  data: IData;
  setData?: Dispatch<SetStateAction<TFieldDataInstance>>;
}

const FieldContext = createContext<IFieldContextData>({
  data: new FieldData({}),
  setData: () => null,
});

const FieldContextProvider = FieldContext.Provider;

const useFieldContext = (): IFieldContextData => useContext(FieldContext) || {};

export { FieldContext, useFieldContext, FieldContextProvider };
