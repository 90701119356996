// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// mock
import { mockBank, mockImg } from '../mockData';
// styles
import styles from './bank.module.scss';

type TTransferBankInfoProps = {
  isAddFundsPopup: boolean;
};

const TransferBankInfo: React.FC<TTransferBankInfoProps> = ({ isAddFundsPopup }: TTransferBankInfoProps) => {
  const { t } = useTranslation('accountingPage');

  const bankLabel = useMemo(() => {
    const labelPrefix = isAddFundsPopup ? t('transferFunds.From') : t('transferFunds.To');

    return `${labelPrefix} ${t('transferFunds.bank')}`;
  }, [isAddFundsPopup, t]);

  return (
    <div className={styles['bank-block']}>
      <span className={styles['bank-label']}>{bankLabel}:</span>

      <div className={styles.bank__wrapper}>
        <img src={mockImg} alt="bank img" width={54} height={54} />

        <div className={styles.bank__info}>
          <span className={styles['bank-title']}>{mockBank.name}</span>
          <span className={styles['bank-acct-number']}>{mockBank.act.replace(/^.{4}/, 'xxxx')}</span>
        </div>
      </div>
    </div>
  );
};

export default TransferBankInfo;
