// libraries
import React from 'react';
// assets
import { ReactComponent as UserIcon } from 'assets/icons/user-icon.svg';
import { ReactComponent as UserActiveIcon } from 'assets/icons/user-fill-blue-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
import { ReactComponent as TrashActiveIcon } from 'assets/icons/filled-trash-icon.svg';

export default [
  {
    name: 'Contacts',
    path: '/contacts',
    exact: true,
    icon: <UserIcon width="14" height="14" />,
    activeIcon: <UserActiveIcon width="14" height="14" />,
  },
  {
    name: 'Trash',
    path: '/contacts/trash',
    exact: true,
    icon: <TrashIcon width="14" height="14" />,
    activeIcon: <TrashActiveIcon width="14" height="14" />,
  },
];
