import { useState } from 'react';
// helpers
import { convertKeysToSnakeCase, convertKeysToCamelCase } from '../../../shared/helpers/api';
import RequestError, { CustomErrors } from '../../../shared/helpers/exceptions';
// api
import { Entity } from '../../../shared/api/crud/Crud';
import AuthApi from '../../Auth/api/AuthApi';

interface ConfirmEmailInterface {
  submitForm: (data: Entity) => Promise<void>;
  isSubmitted: boolean;
}

const useSubmitEmail = (): ConfirmEmailInterface => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submitForm = async (data: Entity) => {
    try {
      await AuthApi.submitEmail(convertKeysToSnakeCase(data));
      setIsSubmitted(true);
    } catch (e) {
      const { status, result } = await e;
      const { message = '', errors = {} } = await result;

      throw new RequestError(status, { errors: convertKeysToCamelCase(errors) as CustomErrors, message });
    }
  };

  return {
    submitForm,
    isSubmitted,
  };
};

export default useSubmitEmail;
