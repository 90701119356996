import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Textarea } from 'components/Form/inputs';
import RegistrationCompanyButtons from '../Buttons/RegistrationCompanyButtons';
import Form from 'components/Form';
// hooks
import useRegistrationCompanyForm from '../useRegistrationCompanyForm';
// helpers
import ABOUT_COMPANY_FORM_SCHEMA from './validation-rules';
// styles
import styles from './AboutCompanyForm.module.scss';

const MAX_DESCRIPTION_LENGTH = 600;

const AboutCompanyForm = () => {
  const { t } = useTranslation('registrationCompanyModals');
  const { isButtonsDisabled, submitForm } = useRegistrationCompanyForm();

  return (
    <Form
      // TODO Must be changed after integration
      formType="reg-company-about"
      submitForm={submitForm}
      canBeEmpty
      validationSchema={ABOUT_COMPANY_FORM_SCHEMA}
    >
      <span className={styles['form-title']}>{t('aboutModal.formTitle')}</span>
      <Textarea
        placeholder={t('aboutModal.textAreaPlaceholder')}
        className={styles['editable-content']}
        fieldWrapperClassName={styles['text-area__wrapper']}
        maxLength={MAX_DESCRIPTION_LENGTH}
        name="description"
      />

      <RegistrationCompanyButtons isButtonsDisabled={isButtonsDisabled} />
    </Form>
  );
};

export default AboutCompanyForm;
