// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Button from 'components/Button';
// helpers
import { FilterTabs } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
// styles
import styles from './Tab.module.scss';

type TabProps = {
  isActive?: boolean;
  isDisabled?: boolean;
  changeTab: (tagName: FilterTabs) => void;
  children: React.ReactNode;
};

const Tab: React.FC<TabProps> = ({ isActive, changeTab, children, isDisabled }: TabProps) => {
  const { t } = useTranslation('transactionHistory');
  const tabClassName = classNames(styles.tabs__item, { [styles['tabs__item--active']]: isActive });

  return (
    <Button className={tabClassName} handleClick={() => changeTab(children as FilterTabs)} disabled={isDisabled}>
      {t(`filters.${children}`)}
    </Button>
  );
};

export default Tab;
