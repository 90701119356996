// libraries
import React from 'react';
// components
import DropdownButtonItem from 'components/Button/DropdownButton/DropdownButtonItem';

interface ITransactionCardFooterActionItem {
  onClick: () => void;
  children?: React.ReactNode;
}

const TransactionCardFooterActionItem: React.FC<ITransactionCardFooterActionItem> = ({
  children,
  onClick,
}: ITransactionCardFooterActionItem) => {
  return <DropdownButtonItem onClick={onClick}>{children}</DropdownButtonItem>;
};

export default TransactionCardFooterActionItem;
