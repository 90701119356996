// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// assets
import { ReactComponent as Arrow } from 'assets/icons/long_bottom_up.svg';
// components
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
import { SelectedCompanyInfoProps } from '../types';
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
import Button from 'components/Button';
import ReviewField from './ReviewField/ReviewField';
import VerificationStar from 'components/company/CompanyInfo/InfoHeader/VerificationStar';
// helpers
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
import VerificationStatus from 'shared/constants/verificationStatus';
import { formatCurrency } from 'shared/helpers/format';
// styles
import styles from 'components/AddBill/AddBillFormReview/AddBillFormReview.module.scss';

type AddBillFormReviewParams = {
  vendor: string;
  amount: number;
  email: string;
  message?: string;
  invoiceNumber: string;
  invoiceId: string;
};

type AddBillFormReviewProps = {
  onSubmitReview: (id: string) => void;
  companyInfo: SelectedCompanyInfoProps;
};

const AddBillFormReview: React.FC<AddBillFormReviewProps> = ({
  onSubmitReview,
  companyInfo,
}: AddBillFormReviewProps) => {
  const { t } = useTranslation('addBillPage');
  const { formData = {} } = useMultiStepContext();
  const { logo, isNewVendor, verificationStatus } = companyInfo;

  const {
    amount = '',
    email = '',
    message = '',
    vendor = '',
    invoiceNumber = '',
    invoiceId,
  } = formData as AddBillFormReviewParams;

  const cardMessage = () => {
    return (
      <div className={styles.vendor}>
        <p className={styles['vendor-name']}>{vendor}</p>
        {isNewVendor ? (
          <p className={styles['new-vendor']}>{t('newVendor')}</p>
        ) : (
          <VerificationStar
            className={styles['verify-icon']}
            isVerified={verificationStatus === VerificationStatus.VERIFIED}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles['add-bill-review__container']}>
      <h2 className={styles.header}>{t('confirm.header')}</h2>
      <h3 className={styles['sub-header']}>{t('confirm.subHeader')}</h3>
      <div className={styles.content}>
        <ShortEntityCard
          className="billing-company-info"
          logo={
            <EntityLogo
              logoSrc={logo}
              className={styles['company-avatar']}
              icon={<Arrow width={9} height={9} className={styles['company-avatar__icon']} />}
            />
          }
          message={cardMessage()}
          helpText={email}
          helpTextClassName={styles['company-email']}
          // infoWrapperClassName={styles['company-info__wrapper']}
          additionalInfo={<div className={styles.amount}>{formatCurrency(amount || 0, false)}</div>}
        />
        <ReviewField title="Message" value={message} />
        <ReviewField title="Invoice Number" value={invoiceNumber} />
      </div>
      <div className={styles.button__container}>
        <Button className={styles['button-submit']} handleClick={() => onSubmitReview(invoiceId)}>
          {t('confirm.submitButton')}
        </Button>
      </div>
    </div>
  );
};

export default AddBillFormReview;
