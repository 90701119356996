import React from 'react';
// components
import Payable from './Payable';
import Receivable from './Receivable';
// styles
import styles from './PayableReceivableContainer.module.scss';

const PayableReceivableContainer: React.FC = () => {
  return (
    <div className={styles['payable-receivable-container']}>
      <Receivable />
      <Payable />
    </div>
  );
};

export default PayableReceivableContainer;
