// libraries
import React from 'react';
import Cropper from 'react-easy-crop';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/Button';
import Modal, { ModalType } from 'components/Modal/Modal';
// helpers
import useImageCropper from 'components/ImageCropper/useImageCropper';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './ImageCropper.module.scss';

const DEFAULT_ASPECT = 1;
const DEFAULT_ZOOM_SPEED = 0.1;
const DEFAULT_CROP_SIZE = { width: 300, height: 300 };

const ImageCropper: React.FC = () => {
  const { t } = useTranslation('forms');
  const { handleCloseCropper, crop, zoom, setCrop, setZoom, onCropComplete, showCroppedImage } = useImageCropper();
  const {
    imageCropperStore: { image },
  } = useStore();

  if (!image) {
    return null;
  }

  return (
    <Modal id={ModalIds.imageCropper} type={ModalType.base} handleCloseModal={handleCloseCropper}>
      <div className={styles.cropper}>
        <Cropper
          image={image.fullFilePath || ''}
          crop={crop}
          cropSize={DEFAULT_CROP_SIZE}
          zoom={zoom}
          zoomSpeed={DEFAULT_ZOOM_SPEED}
          aspect={DEFAULT_ASPECT}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          classes={{
            containerClassName: styles.cropper__wrapper,
          }}
        />
      </div>
      <div className={styles.cropper__btn}>
        <Button handleClick={showCroppedImage}>{t('saveButtonDefaultText')}</Button>
        <Button outline handleClick={handleCloseCropper}>
          {t('cancelButtonDefaultText')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(ImageCropper);
