// libraries
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// helpers
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import useCompanies from 'components/company/useCompanies';
import { useStore } from 'shared/stores/RootStore/useStore';

type CompanySearchParams = {
  companies: Partial<IBusinessSnapshot>[];
  onSearch: (query: string, isOpenSearch?: boolean) => void;
  isLoaded: boolean;
  isOpenSearch: boolean;
  searchQuery: string;
  isScrollableList: boolean;
  createInvoiceForNewBusiness: () => void;
};

const MAX_SCROLL_LIST_SIZE = 7;

const useCompanySearch = (): CompanySearchParams => {
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(true);
  const [companies, setCompanies] = useState<Partial<IBusinessSnapshot>[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isScrollableList, setIsScrollableList] = useState(false);
  const { companiesData: storedCompaniesData } = useCompanies();

  const {
    modalStore: { closeAllModals },
  } = useStore();

  const filterCompanies = useCallback(
    async (queryString = '') => {
      setIsLoaded(true);

      if (queryString) {
        const filteredCompanies = storedCompaniesData.filter(company =>
          company.dbaName.toLowerCase().includes(queryString.toLowerCase()),
        );
        setCompanies(filteredCompanies);
        setIsScrollableList(filteredCompanies.length >= MAX_SCROLL_LIST_SIZE);
        return filteredCompanies;
      }
      setCompanies(storedCompaniesData);
      setIsScrollableList(storedCompaniesData.length >= MAX_SCROLL_LIST_SIZE);
      return storedCompaniesData;
    },
    [storedCompaniesData],
  );

  const onSearch = useCallback(
    (query: string, isActiveSearch?: boolean) => {
      if (!isActiveSearch || !query) {
        setIsOpenSearch(false);
        return;
      }

      setSearchQuery(query);
      setIsOpenSearch(true);
      filterCompanies(query);
    },
    [filterCompanies],
  );

  const createInvoiceForNewBusiness = useCallback(() => {
    const companyInfo = {
      customer: searchQuery,
    };

    history.push('/create-invoice', {
      companyInfo,
      isExternalInvoice: true,
    });
  }, [history, searchQuery]);

  useEffect(() => {
    closeAllModals();
  }, []);

  return {
    companies,
    onSearch,
    isLoaded,
    isOpenSearch,
    searchQuery,
    isScrollableList,
    createInvoiceForNewBusiness,
  };
};

export default useCompanySearch;
