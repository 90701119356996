// libraries
import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// components
import { ExpandableNumberInput } from 'components/Form/inputs';
import CompletedModal from 'components/CompletedModal/CompletedModal';
import Form from 'components/Form';
import ModalTitleWithStep from '../../Modal/ModalTitleWithStep/ModalTitleWithStep';
import TransferCurrentBalance from './CurrentBalance/TransferCurrentBalance';
import TransferBankInfo from './bank/TransferBankInfo';
import TransferConfirmation from './Confirm/TransferConfirmation';
import TransferButtons from './Buttons/TransferButtons';
// constants
import { TransactionType } from 'shared/constants/transactions';
import fieldSizes from 'shared/constants/fieldSizes';
// context
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// helpers
import useTransferFunds, { StepsNumber } from './useTransferFunds';
import { ModalIds } from 'shared/constants/modalIds';
import TRANSFER_FUNDS_FORM_SCHEMA from './validation-rules';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// mock
import { mockBank } from './mockData';
// assets
import plusImage from 'assets/icons/plus.svg';
// assets
import { ReactComponent as DoneImage } from 'assets/icons/done.svg';
// styles
import styles from './TransferFunds.module.scss';

const TransferFunds: React.FC = () => {
  const { t } = useTranslation('accountingPage');
  const {
    transferFundsStore: { transferType },
    modalStore: { modalIds, isOpen },
    eventBalancesStore: { balancesData },
  } = useStore();
  const {
    isFormSubmitted,
    submitForm,
    isLoading,
    handleChangeAmount,
    amount,
    isSubmitDisabled,
    isAmountError,
    isAddFundsPopup,
  } = useTransferFunds();

  const { currentStep, goToStep } = useMultiStepContext();

  const titlePrefix = useMemo(() => (isAddFundsPopup ? t('transferFunds.to') : t('transferFunds.from')), [
    isAddFundsPopup,
    t,
  ]);

  const titlePopupText = useMemo(() => {
    if (currentStep === StepsNumber.FOUND) {
      return `${transferType} ${t('transferFunds.title')} ${titlePrefix} ${t('hopscotch')}`;
    }

    return `${t('transferFunds.transferConfirmTitle')} ${
      isAddFundsPopup ? t('transferFunds.add') : t('transferFunds.transfer')
    } ${titlePrefix} ${t('hopscotch')}`;
  }, [currentStep, t, transferType, titlePrefix]);

  const transactionType = isAddFundsPopup ? TransactionType.FUNDS : TransactionType.PAYOUT;

  useEffect(() => {
    if (isOpen(ModalIds.transferFunds) && goToStep) {
      goToStep(StepsNumber.FOUND);
    }
  }, [modalIds]);

  return isFormSubmitted ? (
    <CompletedModal
      wrapperClassName={styles['success-message-wrapper']}
      icon={DoneImage}
      iconClassName={styles['success-img']}
    >
      <span className={styles['transfer-success-message']}>{t('transferFunds.Done')}</span>
    </CompletedModal>
  ) : (
    <div className={styles['transfer-funds__wrapper']}>
      <>
        <ModalTitleWithStep
          titlePopupText={titlePopupText}
          image={plusImage}
          stepTitleClassName={styles['transfer-funds__step-title']}
        />
        <Form
          // TODO Must be changed after integration
          formType="transfer"
          submitForm={data => submitForm(data, transactionType)}
          validationSchema={TRANSFER_FUNDS_FORM_SCHEMA}
        >
          {currentStep === StepsNumber.FOUND && (
            <>
              <ExpandableNumberInput
                value={amount}
                integerLimit={fieldSizes.MONEY_INTEGER_LIMIT}
                onChangeCallback={handleChangeAmount}
                classNameWrapper={styles.amount__wrapper}
                classNamePrefix={styles['amount-prefix']}
                fieldWrapperClassName={styles['amount-input__wrapper']}
                inputClassName={styles['amount-input']}
                spanHelperClassName={styles['helper-amount-span']}
                prefix="$"
                name="amount"
              />

              <TransferCurrentBalance isAmountError={isAmountError} balance={balancesData.zuroBalance} />

              <TransferBankInfo isAddFundsPopup={isAddFundsPopup} />
            </>
          )}

          {currentStep === StepsNumber.CONFIRM && (
            <TransferConfirmation
              bankName={mockBank.name}
              bankAct={mockBank.act}
              isAddFundsPopup={isAddFundsPopup}
              amount={amount}
            />
          )}

          <TransferButtons
            isLoading={isLoading}
            isAmountError={isAmountError}
            isAddFundsPopup={isAddFundsPopup}
            isDisabledSubmit={isSubmitDisabled}
          />
        </Form>
      </>
    </div>
  );
};

export default observer(TransferFunds);
