// libraries
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import InvoicePage from 'components/invoices/InvoicePage/InvoicePage';
import InvoiceForm from 'components/invoices/InvoiceForm/InvoiceForm';
import InvoiceFormReview from 'components/invoices/InvoiceForm/InvoiceFormReview/InvoiceFormReview';
import InvoiceOwnerInfo from 'components/invoices/InvoiceOwnerInfo/InvoiceOwnerInfo';
import FormHeader from 'components/MultiStep/Form/FormHeader/FormHeader';
import useMultiStep from 'components/MultiStep/useMultiStep';
import Breadcrumbs from 'components/Breadcrumbs';
import InvoicePreparing from 'components/invoices/InvoiceForm/InvoicePreparing/InvoicePreparing';
// helpers
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import { Invoice } from 'components/invoices/types';
import useCreateInvoice, { InvoiceStep } from 'components/invoices/useCreateInvoice';
// styles
import styles from 'components/invoices/InvoicePage/InvoicePage.module.scss';

type TInternalInvoiceProps = {
  companyInfo: Partial<IBusinessSnapshot>;
  invoice?: Partial<Invoice> | null;
};

const InternalInvoice: React.FC<TInternalInvoiceProps> = ({ companyInfo, invoice = null }: TInternalInvoiceProps) => {
  const { t } = useTranslation('invoice');
  const history = useHistory();
  const contextValue = useMultiStep(InvoiceStep.INVOICE_FORM_REVIEW);
  const { currentStep, goToPreviousStep, isCurrentStep } = contextValue;

  const { handleSendInvoice, loadingBarProgress, isLoading } = useCreateInvoice(companyInfo);

  const handleReturn = useCallback(() => {
    if (currentStep && goToPreviousStep) {
      goToPreviousStep();

      return;
    }

    history.go(-1);
  }, [history, currentStep, goToPreviousStep]);

  if (isLoading) {
    return (
      <InvoicePage title={t('pageTitle')}>
        <div className={styles.page__content}>
          <InvoicePreparing loadingBarProgress={loadingBarProgress} />
        </div>
      </InvoicePage>
    );
  }

  return (
    <InvoicePage title={t('pageTitle')}>
      <MultiStepProvider value={contextValue}>
        <div className={styles['page__return-btn']}>
          <Breadcrumbs text={t('returnButton')} onClick={handleReturn} />
        </div>
        <div className={styles.page__content}>
          <FormHeader
            currentStep={currentStep || InvoiceStep.INVOICE_FORM}
            lastStep={InvoiceStep.INVOICE_FORM_REVIEW}
            title={isCurrentStep(InvoiceStep.INVOICE_FORM) ? t('invoiceFormTitle') : t('reviewInvoiceTitle')}
          />
          <div className={styles['page__invoice-owner']}>
            <InvoiceOwnerInfo
              logoSrc={companyInfo?.logoImage}
              companyName={companyInfo?.dbaName}
              companyEmail={companyInfo?.email}
            />
          </div>
          {isCurrentStep(InvoiceStep.INVOICE_FORM) && (
            <InvoiceForm handleReturn={handleReturn} currentStep={InvoiceStep.INVOICE_FORM} invoice={invoice} />
          )}
          {isCurrentStep(InvoiceStep.INVOICE_FORM_REVIEW) && (
            <InvoiceFormReview handleReturn={handleReturn} handleSubmitReview={handleSendInvoice} />
          )}
        </div>
      </MultiStepProvider>
    </InvoicePage>
  );
};

export default InternalInvoice;
