import { makeAutoObservable } from 'mobx';
import isEqual from 'lodash/isEqual';
import RootStore from 'shared/stores/RootStore/RootStore';
import { Invoice } from 'components/invoices/types';
import { Company } from 'components/company/CompanyAccount/useCompanyAccount';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';

export type TotalInfo = {
  subtotal: number;
  tax: number;
  taxRate: string | number;
  balanceDue: number;
};

export type Address = {
  line1: string;
  city: string;
  state: string;
  country: string;
  zip: string;
};

export interface CustomerData {
  companyName: string;
  billingInformation: Address;
  shippingInfoLikeBilling: boolean;
  shippingInformation: Nullable<Address>;
}

export interface CompanyData extends Company {
  logo: any;
  name?: Nullable<string>;
}

class InvoiceStore {
  invoiceItems: Invoice[] = [];

  totalInfo: Nullable<TotalInfo> = null;

  customerData: Nullable<CustomerData> = null;

  recipient: Partial<CompanyData> = {};

  sender: Partial<CompanyData> = {};

  invoicePdf: Nullable<File> = null;

  dueDate: Nullable<Date> = null;

  isLoading = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setInvoiceItems = (invoiceItems: Invoice[]): void => {
    if (isEqual(this.invoiceItems, invoiceItems)) return;

    this.invoiceItems = [...invoiceItems];
  };

  setTotalInfo = (totalInfo: Nullable<TotalInfo>): void => {
    if (isEqual(this.totalInfo, totalInfo)) return;

    this.totalInfo = totalInfo ? { ...totalInfo } : null;
  };

  setCustomerData = (data: Nullable<CustomerData>): void => {
    if (isEqual(this.customerData, data)) return;

    this.customerData = data;
  };

  setSenderData = (companyData: Partial<CompanyData>): void => {
    this.sender = companyData;
  };

  setInvoicePdf = (pdf: File): void => {
    this.invoicePdf = pdf;
  };

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  resetInvoiceData = (): void => {
    this.totalInfo = null;
    this.invoicePdf = null;
    this.isLoading = false;
    this.customerData = null;
  };

  resetData = (): void => {
    this.resetInvoiceData();
  };

  [CLEAR_STORE] = (): void => {
    this.invoiceItems = [];
  };
}

export default InvoiceStore;
