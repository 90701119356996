// libraries
import React from 'react';
// helpers
import { ListProvider, TListData, TListItem } from 'components/list/ListContext';
import { useListCrud, useListParams } from 'components/list/builder';
import { IListParamsProps } from 'components/list/builder/useListParams';

export interface IListProps extends IListParamsProps {
  data: TListData;
  children: React.ReactNode;
  idSource?: string;
  searchSources?: string[];
  onRemove?: (params: TListItem) => void;
}

const List: React.FC<IListProps> = ({
  data: initialData,
  children,
  defaultSortOrder,
  defaultSortField,
  shouldResetParams,
  idSource = 'id',
  searchSources = [],
  onRemove,
}: IListProps) => {
  const { params, sortBy, searchBy } = useListParams({
    defaultSortOrder,
    defaultSortField,
    shouldResetParams,
  });
  const listCrud = useListCrud({ params, initialData, idSource, searchSources, onRemove });

  const contextData = {
    ...params,
    sortBy,
    searchBy,
    ...listCrud,
  };

  return <ListProvider value={contextData}>{children}</ListProvider>;
};

export default List;
