import { create } from 'mobx-persist';
import AuthStore from 'shared/stores/AuthStore/AuthStore';
import ModalStore from 'shared/stores/ModalStore/ModalStore';
import FormStore from 'shared/stores/FormStore/FormStore';
import UserStore from 'shared/stores/UserStore/UserStore';
import TransferFundsStore from 'shared/stores/TransferFundsStore/TransferFundsStore';
import ShortListsStore from 'shared/stores/ShortListsStore/ShortListsStore';
import NotificationsStore from 'shared/stores/NotificationsStore/NotificationsStore';
import PaymentMethodStore from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
import TransactionStore from 'shared/stores/TransactionStore/TransactionStore';
import InvoiceStore from 'shared/stores/InvoiceStore/InvoiceStore';
import BeneficialOwnersStore from 'shared/stores/BeneficialOwnersStore/BeneficialOwnersStore';
import BusinessSnapshotStore from 'shared/stores/BusinessSnapshotStore/BusinessSnapshotStore';
import IntegrationsStore from 'shared/stores/IntegrationsStore/IntegrationsStore';
import ImageCropperStore from 'shared/stores/ImageCropperStore/ImageCropperStore';
import ExternalPaymentsStore from 'shared/stores/ExternalPaymentsStore/ExternalPaymentsStore';
import ToastStore from 'shared/stores/ToastStore/ToastStore';
import VerificationStore from 'shared/stores/VerificationStore/VerificationStore';
import LogoutStore from 'shared/stores/LogoutStore/LogoutStore';
import MyBusinessProfilesStore from 'shared/stores/MyBusinessProfilesStore/MyBusinessProfilesStore';
import ConnectionStore from 'shared/stores/ConnectionStore/ConnectionStore';
import SubscribeBusinessStore from 'shared/stores/SubscribeBusinessStore/SubscribeBusinessStore';
import MessagePendingStore from 'shared/stores/MessagePendingStore/MessagePendingStore';
import EventPayableStore from 'shared/stores/EventPayableStore/EventPayableStore';
import EventReceivableStore from 'shared/stores/EventReceivableStore/EventReceivableStore';
import EventBalancesStore from 'shared/stores/EventBalancesStore/EventBalancesStore';
import CompaniesStore from 'shared/stores/CompaniesStore/CompaniesStore';
import AccountIntegrationsStore from 'shared/stores/AccountIntegrationsStore/AccountIntegrationsStore';
import DocumentStore from 'shared/stores/DocumentStore/DocumentStore';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

class RootStore {
  authStore: AuthStore;

  modalStore: ModalStore;

  formStore: FormStore;

  userStore: UserStore;

  transferFundsStore: TransferFundsStore;

  notificationsStore: NotificationsStore;

  paymentMethods: PaymentMethodStore;

  transactionStore: TransactionStore;

  invoiceStore: InvoiceStore;

  beneficialOwnersStore: BeneficialOwnersStore;

  myBusinessSnapshotStore: BusinessSnapshotStore;

  imageCropperStore: ImageCropperStore;

  externalPaymentsStore: ExternalPaymentsStore;

  integrationsStore: IntegrationsStore;

  toastStore: ToastStore;

  verificationStore: VerificationStore;

  logoutStore: LogoutStore;

  connectionStore: ConnectionStore;

  messagePendingStore: MessagePendingStore;

  myBusinessProfilesStore: MyBusinessProfilesStore;

  subscribeBusinessStore: SubscribeBusinessStore;

  eventPayableStore: EventPayableStore;

  eventReceivableStore: EventReceivableStore;

  eventBalancesStore: EventBalancesStore;

  companiesStore: CompaniesStore;

  shortListsStore: ShortListsStore;

  accountIntegrationsStore: AccountIntegrationsStore;

  documentStore: DocumentStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.modalStore = new ModalStore(this);
    this.formStore = new FormStore(this);
    this.userStore = new UserStore(this);
    this.transferFundsStore = new TransferFundsStore(this);
    this.notificationsStore = new NotificationsStore(this);
    this.paymentMethods = new PaymentMethodStore(this);
    this.transactionStore = new TransactionStore(this);
    this.invoiceStore = new InvoiceStore(this);
    this.beneficialOwnersStore = new BeneficialOwnersStore(this);
    this.myBusinessSnapshotStore = new BusinessSnapshotStore(this);
    this.integrationsStore = new IntegrationsStore(this);
    this.imageCropperStore = new ImageCropperStore(this);
    this.externalPaymentsStore = new ExternalPaymentsStore(this);
    this.toastStore = new ToastStore(this);
    this.verificationStore = new VerificationStore(this);
    this.logoutStore = new LogoutStore(this);
    this.connectionStore = new ConnectionStore(this);
    this.messagePendingStore = new MessagePendingStore(this);
    this.myBusinessProfilesStore = new MyBusinessProfilesStore(this);
    this.subscribeBusinessStore = new SubscribeBusinessStore(this);
    this.eventPayableStore = new EventPayableStore(this);
    this.eventReceivableStore = new EventReceivableStore(this);
    this.eventBalancesStore = new EventBalancesStore(this);
    this.companiesStore = new CompaniesStore(this);
    this.accountIntegrationsStore = new AccountIntegrationsStore(this);
    this.documentStore = new DocumentStore(this);
    this.shortListsStore = new ShortListsStore(this);

    Promise.all([
      hydrate('zuro-auth', this.authStore),
      hydrate('zuro-user', this.userStore),
      hydrate('zuro-integrations', this.integrationsStore),
    ]).then(() => {
      this.authStore.setIsAuthLoading();
    });
  }
}

export const store = new RootStore();

export default RootStore;
