// libraries
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// constants
import AccountIntegrationTypes from 'shared/constants/AccountIntegrationTypes';
import { TAccount } from 'shared/stores/AccountIntegrationsStore/AccountIntegrationsStore';
import { ButtonColor } from 'components/Button';
import { ModalIds } from '../../../shared/constants/modalIds';
// store
import { useStore } from 'shared/stores/RootStore/useStore';
// assets
import confirmImage from 'assets/icons/confirm-icon.svg';
// styles
import styles from './AccountIntegrationsForm.module.scss';

type TypeAccountIntegration = {
  accounts: TAccount[];
  connectCallback: (type: AccountIntegrationTypes) => void;
  disconnectCallback: (type: AccountIntegrationTypes) => void;
  disconnectOpenModal: (type: AccountIntegrationTypes) => void;
};

const useAccountIntegration = (): TypeAccountIntegration => {
  const {
    accountIntegrationsStore: { accounts, connectAccount, disconnectAccount },
    modalStore: { openModal },
  } = useStore();
  const { t } = useTranslation('settingAccountIntegrations');

  const connectCallback = useCallback(
    (type: AccountIntegrationTypes) => {
      // TODO: Update after integration with BE
      connectAccount(type);
    },
    [connectAccount],
  );

  const disconnectCallback = useCallback(
    (type: AccountIntegrationTypes) => {
      // TODO: Update after integration with BE
      disconnectAccount(type);
    },
    [disconnectAccount],
  );

  const disconnectOpenModal = (type: AccountIntegrationTypes) => {
    openModal(ModalIds.confirmDisconnectAccount, {
      icon: confirmImage,
      title: t('confirmModal.title'),
      content: t('confirmModal.content'),
      classNameTitle: styles['confirm-disconnect-title'],
      classNameContent: styles['confirm-disconnect-content'],
      confirmButtonProps: {
        color: ButtonColor.danger,
      },
      cbConfirmActionFunction: () => {
        disconnectCallback(type);
      },
      cancelButtonText: t('confirmModal.cancelButtonText'),
      confirmButtonText: t('confirmModal.confirmButtonText'),
    });
  };

  return {
    accounts,
    connectCallback,
    disconnectCallback,
    disconnectOpenModal,
  };
};

export default useAccountIntegration;
