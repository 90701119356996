// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EntryTitle from 'components/EntryTitle/EntryTitle';
import BeneficiaryOwners from 'components/verification/Business/BeneficiaryOwners/BeneficiaryOwners';
// styles
import styles from '../Verification.module.scss';

const Owners: React.FC = () => {
  const { t } = useTranslation('verification');

  return (
    <div className={styles['form-wrapper']}>
      <EntryTitle
        customClass={styles['verification-header']}
        title={t('beneficiaryOwners.title')}
        subtitle={t('beneficiaryOwners.subtitle')}
      />
      <BeneficiaryOwners />
    </div>
  );
};

export default Owners;
