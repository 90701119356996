import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';

class CompaniesStore {
  companiesData: IBusinessSnapshot[] = [];

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setCompaniesData = (data: IBusinessSnapshot[]) => {
    this.companiesData = data;
  };

  [CLEAR_STORE] = (): void => {
    this.companiesData = [];
  };
}

export default CompaniesStore;
