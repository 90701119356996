// libraries
import { useCallback, useEffect, useState } from 'react';
// RootStore
import { Notification } from 'shared/stores/NotificationsStore/NotificationsStore';

import { getRandomMockItems } from './mockData';

const useInAppNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const getInAppNotifications = useCallback(() => {
    // TODO: Update after integration with BE
    setNotifications(getRandomMockItems() as Notification[]);
  }, []);

  useEffect(() => {
    getInAppNotifications();
  }, []);

  return {
    notifications,
  };
};

export default useInAppNotifications;
