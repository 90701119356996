import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// components
import PayableReceivableContent from '../PayableReceivableContent';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// constants
import { FilterTabs } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';

const Payable: React.FC = () => {
  const { t } = useTranslation('payableReceivable');
  const history = useHistory();

  const {
    eventPayableStore: { payableData },
  } = useStore();

  const handleClick = useCallback(() => {
    history.push(`/accounting?type=${FilterTabs.PAYABLE}`);
  }, [history]);

  return (
    <PayableReceivableContent
      textTitle={t('payableTitle')}
      amount={payableData.payableAmount}
      aging={payableData.pastDuePayableAmount}
      handleClick={handleClick}
    />
  );
};

export default observer(Payable);
