// libraries
import React, { useState, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import CustomInput from './CustomInput';
import TextInput from '../TextInput/TextInput';
import { InputCommonProps } from '../Input';
import { DatePickerInputProps, DESKTOP_MEDIA_QUERY } from '../DatePicker/DatePickerInput';
// helpers
import { tryFormatToIso } from './tryFormatToIso';
import { useFormContext } from 'components/Form/FormContext';
import { DateFormats } from 'shared/helpers/format';
// styles
import 'react-datepicker/dist/react-datepicker.css';
import '../DatePicker/DatePicker.scss';

const DEFAULT_MAX_DATE = new Date(9999, 12, 31);

const DateInput: React.FC<DatePickerInputProps> = ({
  onChangeCallback,
  popperPlacement,
  placeholder,
  customFormat,
  ...props
}: DatePickerInputProps) => {
  const { t } = useTranslation('forms');
  const { name, minDate, maxDate = DEFAULT_MAX_DATE, defaultValue, calendarContainer } = props;
  const [date, setDate] = useState<Nullable<Date | [Date, Date]>>(defaultValue || null);
  const { setFieldValues, validateField = () => {} } = useFormContext();
  const isDesktop = useCallback(() => window.matchMedia(DESKTOP_MEDIA_QUERY).matches, []);

  useEffect(() => {
    if (validateField && date !== defaultValue) {
      validateField(name);
    }
  }, [date, defaultValue, name]);

  useEffect(() => {
    if (defaultValue && setFieldValues) {
      setFieldValues(name, tryFormatToIso(defaultValue));
    }
  }, [defaultValue, name, setFieldValues]);

  const InputElement = ({ onChange, value, className, ...inputCommonProps }: InputCommonProps) => {
    const handleChange = useCallback((newDate: Date) => {
      setDate(newDate);
      if (setFieldValues) {
        setFieldValues(name, tryFormatToIso(newDate));
      }

      if (onChangeCallback) {
        onChangeCallback(tryFormatToIso(newDate));
      }
    }, []);

    if (value && !date) {
      const newDate = parse(value as string, DateFormats.shortISO, new Date());
      setDate(newDate);
    }

    if (!value && date) {
      setDate(defaultValue || null);
    }

    return (
      <DatePicker
        {...inputCommonProps}
        autoComplete="off"
        className={classNames('datepicker', className)}
        minDate={minDate}
        maxDate={maxDate}
        selected={date as Date}
        onChange={handleChange}
        customInput={React.createElement(CustomInput)}
        popperContainer={calendarContainer}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
        popperPlacement={isDesktop() ? popperPlacement : 'auto'}
        placeholderText={placeholder || t('dateInputPlaceholder')}
      />
    );
  };

  return <TextInput {...props} inputElement={InputElement} />;
};

export default DateInput;
