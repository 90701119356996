// libraries
import React, { useState, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { parse, formatISO } from 'date-fns';
import classnames from 'classnames';
// components
import TextInput, { TextInputProps } from '../TextInput/TextInput';
import { InputCommonProps } from '../Input';
// helpers
import { useFormContext } from 'components/Form/FormContext';
import useDatePicker from './datePickerHelper';
import { DateFormats } from 'shared/helpers/format';
// styles
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

export interface DatePickerInputProps extends Omit<TextInputProps, 'onChangeCallback'> {
  minDate?: Date;
  maxDate?: Date;
  defaultValue?: Date;
  calendarContainer?: ComponentWrapper;
  popperPlacement?: string;
  hasStaticInput?: boolean;
  onChangeCallback?: (date: string) => void;
  customFormat?: (date: Date) => string;
}

export const DESKTOP_MEDIA_QUERY = '(min-width: 992px)';

const DatePickerInput: React.FC<DatePickerInputProps> = ({
  onChangeCallback,
  popperPlacement,
  placeholder,
  customFormat,
  ...props
}: DatePickerInputProps) => {
  const { name, minDate, maxDate, defaultValue, calendarContainer } = props;
  const [date, setDate] = useState<Nullable<Date | [Date, Date]>>(defaultValue || null);
  const { setFieldValues, validateForm } = useFormContext();
  const isDesktop = useCallback(() => window.matchMedia(DESKTOP_MEDIA_QUERY).matches, []);

  useEffect(() => {
    if (validateForm && date !== defaultValue) {
      validateForm();
    }
  }, [validateForm, date, defaultValue]);

  useEffect(() => {
    if (defaultValue && setFieldValues) {
      setFieldValues(name, formatISO(defaultValue, { representation: 'date' }));
    }
  }, [defaultValue, name, setFieldValues]);

  const InputElement = ({ onBlur, onFocus, onChange, value, className, ...inputCommonProps }: InputCommonProps) => {
    const { isCalendarOpen, closeCalendar, handleCloseOnScroll, getCustomInput } = useDatePicker();
    const customInput = getCustomInput((date || value) as Nullable<Date>, placeholder, customFormat);

    const handleChange = useCallback(
      (newDate: Date) => {
        setDate(newDate);
        closeCalendar();

        if (setFieldValues) {
          setFieldValues(name, formatISO(newDate, { representation: 'date' }));
        }

        if (onChangeCallback) {
          onChangeCallback(formatISO(newDate, { representation: 'date' }));
        }
      },
      [closeCalendar],
    );

    if (value && !date) {
      setDate(parse(value as string, DateFormats.shortISO, new Date()));
    }

    if (!value && date) {
      setDate(defaultValue || null);
    }

    return (
      <DatePicker
        {...inputCommonProps}
        className={classnames('datepicker', className)}
        minDate={minDate}
        maxDate={maxDate}
        selected={date as Date}
        onChange={handleChange}
        customInput={customInput}
        open={isCalendarOpen}
        onClickOutside={closeCalendar}
        closeOnScroll={handleCloseOnScroll}
        popperContainer={calendarContainer}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
        popperPlacement={isDesktop() ? popperPlacement : 'auto'}
      />
    );
  };

  return <TextInput {...props} inputElement={InputElement} />;
};

export default DatePickerInput;
