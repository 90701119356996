// libraries
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import useRestrictedFiles from 'components/RestrictedFiles/useRestrictedFiles';
import { Document as DocumentProps } from 'shared/stores/DocumentStore/DocumentStore';

type DocumentParams = {
  downloadDocument: () => void;
  isLoading: boolean;
  fileUrl: string;
};

const useDocument = ({ title, file }: DocumentProps): DocumentParams => {
  const { t } = useTranslation('documentsPage');
  const fileUrl = useMemo(() => (file.length ? file[0].url : ''), [file]);
  const { downloadFile, isLoading } = useRestrictedFiles();

  const downloadDocument = useCallback(async () => {
    try {
      // TODO: Update after BE integration
      // const { url, fileName: downloadedFileName } = await downloadFile(getInnerUrlPathname(fileUrl));
      //
      // const fileExtension = getFileExtension(downloadedFileName);
      // const formattedFileName = `${title}.${fileExtension}`;
      //
      // downloadFromUrl(url, formattedFileName);
    } catch (e) {
      const { message } = await e;
      // addErrorToast(message);
    }
  }, [fileUrl, downloadFile, title]);

  return {
    downloadDocument,
    isLoading,
    fileUrl,
  };
};

export default useDocument;
