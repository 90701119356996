// libraries
import { useCallback, useEffect, useState } from 'react';
import { browserName, browserVersion, osName } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
// types
import {
  SessionHook,
  SessionConnectHandler,
  SessionMessageHandler,
  SessionDisconnectHandler,
  SessionErrorHandler,
} from './types';
import { Entity } from 'shared/api/crud/Crud';
// constants
import config from '../../config';
import Endpoints from 'shared/constants/endpoints';

const useSession = (
  onOpen: SessionConnectHandler,
  onMessage: SessionMessageHandler,
  onClose: SessionDisconnectHandler,
  onError: SessionErrorHandler,
): SessionHook => {
  const [session, setSession] = useState((null as unknown) as WebSocket);

  const handleOpen = () => {
    if (!session) return;

    session.addEventListener('open', onOpen);

    // eslint-disable-next-line
    return () => {
      session.removeEventListener('open', onOpen);
    };
  };

  const handleMessage = () => {
    if (!session) return;

    session.addEventListener('message', onMessage);

    // eslint-disable-next-line
    return () => {
      session.removeEventListener('message', onMessage);
    };
  };

  const handleError = () => {
    if (!session) return;

    session.addEventListener('error', onError);

    // eslint-disable-next-line
    return () => {
      session.removeEventListener('error', onError);
    };
  };

  const handleClose = () => {
    if (!session) return;

    session.addEventListener('close', onClose);

    // eslint-disable-next-line
    return () => {
      session.removeEventListener('close', onClose);
    };
  };

  useEffect(handleOpen, [session, onOpen]);
  useEffect(handleMessage, [session, onMessage]);
  useEffect(handleError, [session, onError]);
  useEffect(handleClose, [session, onClose]);

  const connect = useCallback(() => {
    const ws = new WebSocket('wss://dev.zurohoq.com');
    setSession(ws);
  }, []);

  const send = (
    messageType: Nullable<string>,
    endpoint: Nullable<string>,
    payload: Nullable<Entity>,
    sessionId?: Nullable<string>,
  ) => {
    session.send(
      JSON.stringify({
        messageType,
        payload: JSON.stringify({
          issuedTime: Date.now(),
          traceId: uuidv4(),
          endPoint: endpoint,
          ...(endpoint !== (Endpoints.LOGIN && Endpoints.SIGN_UP) && { sessionId }),
          endpointRequest: JSON.stringify(payload),
          systemInfo: {
            osName,
            browserName,
            browserVersion,
          },
        }),
      }),
    );
  };

  const close = useCallback(() => {
    if (session.readyState === session.OPEN) session.close(1001);
  }, [session]);

  return [connect, send, close];
};

export default useSession;
