// libraries
import React from 'react';
// components
import BaseLayout from 'shared/layouts/basic';
import AddBill from 'components/AddBill/AddBill';

const AddExternalBill: React.FC = () => {
  return (
    <BaseLayout>
      <AddBill />
    </BaseLayout>
  );
};

export default AddExternalBill;
