// libraries
import React, { PropsWithChildren, useEffect } from 'react';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip as BaseTooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
// styles
import './Tooltip.scss';

type TooltipProps = PropsWithChildren<{
  message?: string | string[];
  id: string;
  className?: string;
  onToggle?: () => void;
}>;

const TOOLTIP_DELAY = 150;

const UpdatingPopover = React.forwardRef(
  ({ popper, children, id, className, ...props }: OverlayInjectedProps, ref: React.Ref<HTMLDivElement>) => {
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);

    return (
      <BaseTooltip {...props} ref={ref} id={id} className={className}>
        {children}
      </BaseTooltip>
    );
  },
);

const Tooltip: React.FC<TooltipProps> = ({ children = null, message = '', id, className, onToggle }: TooltipProps) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <UpdatingPopover id={id} className={classnames('tooltip', className)}>
          {message}
        </UpdatingPopover>
      }
      delay={{ show: TOOLTIP_DELAY, hide: TOOLTIP_DELAY }}
      onToggle={onToggle}
    >
      <div>{children}</div>
    </OverlayTrigger>
  );
};

export default Tooltip;
