import Crud from 'shared/api/crud/Crud';
import { ResponsePromise } from 'shared/api/AbstractRequestManager';
import { HttpMethod } from 'shared/api/AbstractRequest';
import { buildQueryString } from 'shared/helpers/api';

export type QuickBookData = {
  code: string;
  companyId: string;
};

type QuickBookAuthorizeUriResponse = Promise<{
  uri: string;
}>;

export type ExportTransactionsToQuickBookData = {
  from: string;
};

type QuickBookAuthorizeUriParams = {
  state: string;
};

class IntegrationsApi extends Crud {
  constructor() {
    super('/integrations');
  }

  getQuickBookAuthorizeUri = (data: QuickBookAuthorizeUriParams): QuickBookAuthorizeUriResponse => {
    const resourceUri = `${this.resourceUrl}/quickbooks/authorize-uri`;
    const params = { body: { ...data }, method: HttpMethod.POST };

    return this.getRequest(resourceUri, params).send() as QuickBookAuthorizeUriResponse;
  };

  integrateQuickBook = (data: QuickBookData): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}/quickbooks/create`;
    const params = { body: { ...data }, method: HttpMethod.POST };

    return this.getRequest(resourceUri, params).send();
  };

  exportTransactionsToQuickBook = (params?: ExportTransactionsToQuickBookData): QuickBookAuthorizeUriResponse => {
    const queryString = params ? buildQueryString(params) : '';
    const listUri = `${this.resourceUrl}/quickbooks/export${queryString}`;

    return this.getRequest(listUri).send() as QuickBookAuthorizeUriResponse;
  };
}

export default new IntegrationsApi();
