/* eslint-disable import/prefer-default-export */

// libraries
import React from 'react';
import classnames from 'classnames';
// components
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
import Tooltip from 'components/Tooltip/Tooltip';
// assets
import { ReactComponent as ReceivableArrow } from 'assets/icons/long_top_down.svg';
import { ReactComponent as PayableArrow } from 'assets/icons/long_bottom_up.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock-icon.svg';

export const getInvoiceCompanyLogo = (
  isPayableInvoice: boolean,
  logoImageAsBase64?: string,
  isScheduledInvoice?: boolean,
  className?: string,
  tooltipMessage?: string,
  isConfirmStep?: boolean,
) => {
  const Icon = isPayableInvoice ? PayableArrow : ReceivableArrow;
  let IconElement = <Icon width={9} height={9} />;

  if (isScheduledInvoice || isConfirmStep) {
    IconElement = <ClockIcon width={8} height={8} className="schedule-invoice-icon" />;
  }

  if (tooltipMessage) {
    IconElement = (
      <Tooltip message={tooltipMessage} id="invoice-tooltip" className="invoice-tooltip">
        {IconElement}
      </Tooltip>
    );
  }

  return (
    <EntityLogo
      logoSrc={logoImageAsBase64}
      className={classnames(className, { 'has-opacity': isScheduledInvoice || isConfirmStep })}
      icon={IconElement}
    />
  );
};
