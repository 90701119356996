// libraries
import React, { memo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import SignUPButton from 'components/Button/LinkButton';
import { ButtonColor } from 'components/Button';
import Link from 'components/Link/Link';
import LogoBig, { LogoColor } from 'components/LogoBig/LogoBig';
// styles
import styles from './Header.module.scss';

export interface IHeader {
  handleLogoClick?: () => void;
  isNavigationVisible?: boolean;
}

const Header: React.FC<IHeader> = ({ isNavigationVisible = false, handleLogoClick = () => {} }: IHeader) => {
  const { t } = useTranslation('navigation');

  return (
    <header className={styles.header}>
      <div className="layout__container mx-auto">
        <div className={classnames(styles.header__wrapper, 'layout__container-wrapper')}>
          <div className={styles['logo-wrapper']}>
            <LogoBig
              classNameLogo={styles['logo-img']}
              handleLogoClick={handleLogoClick}
              color={LogoColor.DARK}
              href="/"
            />
            <span className={styles['logo-subtitle']}>&#10072; {t(`publicTitleText`)}</span>
          </div>
          {isNavigationVisible && (
            <div className={styles['navigation-wrapper']}>
              <Link linkWrapperClassName={styles['links-wrapper']} linkClassName={styles['item-link']} to="/login">
                {t(`main.log in`)}
              </Link>
              <SignUPButton color={ButtonColor.primary} url="/sign-up" className={styles['join-zuro-btn']}>
                {t(`main.sign up`)}
              </SignUPButton>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default memo(Header);
