// libraries
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// images
import { ReactComponent as BackArrow } from 'assets/icons/arrow-back.svg';
// styles
import styles from './Breadcrumbs.module.scss';

type TBreadcrumbsProps = {
  text?: Nullable<string>;
  onClick?: () => void;
};

const Breadcrumbs = ({ text = null, onClick }: TBreadcrumbsProps) => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();

      return;
    }

    history.go(-1);
  }, [history, onClick]);

  return (
    <button className={styles.breadcrumbs} onClick={handleClick}>
      <BackArrow className={styles['back-arrow']} />
      {text && <span className={styles.text}>{text}</span>}
    </button>
  );
};

export default Breadcrumbs;
