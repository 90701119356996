import SessionTimeout from '../views/SessionTimeout/SessionTimeout';
import NotFound from 'router/views/NotFound/NotFound';

export default [
  {
    path: '/session-timeout',
    exact: true,
    isProtected: false,
    component: SessionTimeout,
    name: 'Session Timed Out',
  },
  {
    path: '*',
    exact: true,
    isProtected: false,
    component: NotFound,
    name: '404',
  },
];
