// libraries
import React, { CSSProperties } from 'react';
import SimpleBar from 'simplebar-react';
// styles
import 'simplebar-react/dist/simplebar.min.css';
import './Scrollbar.scss';

// @ts-ignore
export type ScrollBarRef = any;

interface ICustomScrollbarProps {
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
  scrollbarRef?: ScrollBarRef;
}

const Scrollbar = ({ style, children, className, scrollbarRef }: ICustomScrollbarProps) => {
  return (
    <SimpleBar style={{ ...style }} className={className} ref={scrollbarRef}>
      {children}
    </SimpleBar>
  );
};

export default Scrollbar;
