// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import { PasswordInput } from 'components/Form/inputs';
// helpers
import areFieldsFullfilled from 'shared/helpers/inputs';
import { useFormContext } from 'components/Form/FormContext';
// styles
import styles from './ChangePasswordModal.module.scss';

const currentPageField = ['previousPassword', 'newPassword', 'confirmNewPassword'];

type ChangePasswordModalFieldsProps = {
  handleCloseModal: () => void;
};

const ChangePasswordModalFields: React.FC<ChangePasswordModalFieldsProps> = ({
  handleCloseModal,
}: ChangePasswordModalFieldsProps) => {
  const { t } = useTranslation(['settingsPage', 'forms']);
  const { data } = useFormContext();
  const isButtonDisabled = !areFieldsFullfilled(data, currentPageField);

  return (
    <>
      <PasswordInput
        name="previousPassword"
        className={styles.input}
        label={t('accountBlock.changePasswordModal.fields.previousPassword.label')}
        placeholder={t('accountBlock.changePasswordModal.fields.previousPassword.placeholder')}
        fieldWrapperClassName={styles.field__wrapper}
        autoComplete="off"
      />
      <PasswordInput
        name="newPassword"
        className={styles.input}
        label={t('accountBlock.changePasswordModal.fields.newPassword.label')}
        placeholder={t('accountBlock.changePasswordModal.fields.newPassword.placeholder')}
        fieldWrapperClassName={styles.field__wrapper}
        autoComplete="off"
      />
      <PasswordInput
        name="confirmNewPassword"
        className={styles.input}
        label={t('accountBlock.changePasswordModal.fields.confirmNewPassword.label')}
        placeholder={t('accountBlock.changePasswordModal.fields.confirmNewPassword.placeholder')}
        fieldWrapperClassName={classNames(styles.field__wrapper, styles['confirm-password__field'])}
        autoComplete="off"
      />
      <div className={styles.button__wrapper}>
        <Button outline handleClick={handleCloseModal}>
          {t('forms:cancelButtonDefaultText')}
        </Button>
        <SubmitButton className={styles['save-button']} disabled={isButtonDisabled}>
          {t('forms:saveButtonDefaultText')}
        </SubmitButton>
      </div>
    </>
  );
};

export default ChangePasswordModalFields;
