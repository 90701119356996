// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import DatePickerInput from 'components/Form/inputs/DatePicker/DatePickerInput';
import AutocompleteInput from 'components/Form/inputs/AutocompleteInput/AutocompleteInput';
import Checkbox from '../../Form/inputs/Checkbox';
import { TextInput, MaskedInput } from 'components/Form/inputs';
import EntryButton from 'components/EntryButton/EntryButton';
// helpers
import areFieldsFullfilled from 'shared/helpers/inputs';
import { MAX_BIRTH_DATE, MIN_BIRTH_DATE } from 'components/verification/verificationHelpers';
import useVerification from 'components/verification/useVerification';
// constants
import { SSN_MASK } from 'shared/constants';
import { useFormContext } from 'components/Form/FormContext';
// styles
import formStyles from 'components/verification/Business/Business.module.scss';
import styles from './OwnersForm.module.scss';

const currentPageFields = ['firstName', 'lastName', 'birthday', 'ssn', 'address'];

type OwnerFieldsProps = {
  fieldWrapperClassName?: string;
  isModalComponent?: boolean;
};

const OwnerFields: React.FC<OwnerFieldsProps> = ({ fieldWrapperClassName, isModalComponent }: OwnerFieldsProps) => {
  const { t } = useTranslation(['verification', 'forms']);
  const { stepData } = useVerification();
  const { data } = useFormContext();

  const isButtonDisabled = !areFieldsFullfilled(data, currentPageFields);

  const { businessAddress } = stepData || {};

  const predifinedValue = data?.sameAddress && businessAddress ? businessAddress : null;

  const nameContainerClassName = classnames(styles['name-container'], {
    [styles['modal-form__name-container']]: isModalComponent,
  });

  const firstNameFieldClassName = classnames({
    [styles['modal-form__firstname-input']]: isModalComponent,
  });

  return (
    <>
      <div className={formStyles['fields-container']}>
        <div className={nameContainerClassName}>
          <TextInput
            name="firstName"
            label={t('verification:beneficiaryOwners.ownerForm.fields.firstName.label')}
            placeholder={t('verification:beneficiaryOwners.ownerForm.fields.firstName.placeholder')}
            fieldWrapperClassName={firstNameFieldClassName}
          />

          <TextInput
            name="lastName"
            label={t('verification:beneficiaryOwners.ownerForm.fields.lastName.label')}
            placeholder={t('verification:beneficiaryOwners.ownerForm.fields.lastName.placeholder')}
          />
        </div>

        <DatePickerInput
          name="birthday"
          label={t('verification:beneficiaryOwners.ownerForm.fields.birthday.label')}
          fieldWrapperClassName={classnames(formStyles.form__input, fieldWrapperClassName)}
          minDate={MIN_BIRTH_DATE}
          maxDate={MAX_BIRTH_DATE}
        />

        <MaskedInput
          name="ssn"
          mask={SSN_MASK}
          label={t('verification:beneficiaryOwners.ownerForm.fields.ssn.label')}
          placeholder={t('verification:beneficiaryOwners.ownerForm.fields.ssn.placeholder')}
          fieldWrapperClassName={fieldWrapperClassName}
        />

        <AutocompleteInput
          name="address"
          label={t('verification:beneficiaryOwners.ownerForm.fields.address.label')}
          namePrefix=""
          errorLabel={t('address.homeAddress')}
          placeholder={t('verification:beneficiaryOwners.ownerForm.fields.address.placeholder')}
          disabled={!!data?.sameAddress}
          predifinedValue={predifinedValue}
          fieldWrapperClassName={classnames(styles['address-input'], fieldWrapperClassName)}
        />

        <Checkbox name="sameAddress" label={t('forms:sameAsBusinessAddress')} />
      </div>
      <div className={formStyles.button__wrapper}>
        <EntryButton
          disabled={isButtonDisabled}
          entryButtonClassName={classnames(formStyles.button, formStyles['save-button'])}
        >
          {t('forms:saveButtonDefaultText')}
        </EntryButton>
      </div>
    </>
  );
};

export default observer(OwnerFields);
