// libraries
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
// helpers
import { useListContext } from 'components/list/ListContext';
// components
import SearchInputField from './SearchInputField';
import Form from 'components/Form';
// styles
import styles from './SearchInput.module.scss';

interface ISearchInputProps {
  label?: string | React.ReactElement;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  onSearchCallback?: (query: string) => void;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  label,
  placeholder,
  className,
  inputClassName,
  onSearchCallback = () => {},
}: ISearchInputProps) => {
  const { t } = useTranslation('forms');
  const { search = '', searchBy = () => {} } = useListContext();

  const submitForm = useCallback(
    data => {
      onSearchCallback(data.search);
      searchBy(data.search);
    },
    [searchBy, onSearchCallback],
  );

  return (
    <Form data={{ search }} className={classnames(styles['list-search__wrapper'], className)} submitForm={submitForm}>
      <SearchInputField
        label={label}
        placeholder={placeholder || t('searchPlaceholder')}
        inputClassName={inputClassName}
      />
    </Form>
  );
};

export default SearchInput;
