import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';
import { ISubscribeBusinessStore } from './types';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';

class SubscribeBusinessStore {
  requestSuccessful = false;

  isFetching = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setDataFromMessage = (data: ISubscribeBusinessStore): void => {
    this.requestSuccessful = data.requestSuccessful;
    this.isFetching = data.isFetching;
  };

  [CLEAR_STORE] = (): void => {
    this.requestSuccessful = false;
    this.isFetching = false;
  };
}

export default SubscribeBusinessStore;
