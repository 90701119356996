// libraries
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
// components
import Button from 'components/Button/index';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import { DatePicker, NumberInput, TextInput } from 'components/Form/inputs/index';
import AppLoading from 'components/AppLoading/AppLoading';
import AddressField, { AddressFieldType } from 'components/Form/inputGroups/AddressField/AddressField';
// helpers
import { usePlaces } from 'shared/helpers/placesHelpers';
import { useStore } from 'shared/stores/RootStore/useStore';
import { ModalIds } from 'shared/constants/modalIds';
// styles
import styles from './InvoiceDetailsForm.module.scss';

type InvoiceDetailsFormProps = {
  handleCancelClick: () => void;
};

const InvoiceDetailsForm: React.FC<InvoiceDetailsFormProps> = ({ handleCancelClick }: InvoiceDetailsFormProps) => {
  const { isPlacesLoaded } = usePlaces();
  const { t } = useTranslation(['inviteBusiness', 'forms']);
  const minDueDate = useMemo(() => new Date(), []);
  const {
    invoiceStore: { dueDate: prePopulatedDueDate },
    modalStore: { openModal },
  } = useStore();

  const handleCreateInvoiceClick = useCallback(() => {
    openModal(ModalIds.createInvoice);
  }, [openModal]);

  if (!isPlacesLoaded) {
    return (
      <div className={styles.loading__wrapper}>
        <AppLoading />
      </div>
    );
  }

  return (
    <>
      <div className={styles.fields__wrapper}>
        <TextInput
          label={t('invoiceDetailsForm.legalName')}
          name="legalName"
          fieldWrapperClassName={styles['form-input__wrapper']}
        />
        <TextInput
          label={t('invoiceDetailsForm.subject')}
          name="subject"
          fieldWrapperClassName={styles['form-input__wrapper']}
        />
      </div>
      <div className={classNames(styles.fields__wrapper)}>
        <NumberInput
          label={t('invoiceDetailsForm.amount')}
          name="amount"
          fieldWrapperClassName={styles['form-input__wrapper']}
          prefix="$"
        />
        <DatePicker
          label={t('invoiceDetailsForm.dueDate')}
          minDate={minDueDate}
          name="dueDate"
          defaultValue={prePopulatedDueDate || minDueDate}
        />
      </div>

      <div className={styles.documents__wrapper}>
        <p className={classNames(styles.field__label, styles['documents-label'])}>
          {t('invoiceDetailsForm.documents')}
        </p>
      </div>

      <div className={styles['address-field']}>
        <AddressField
          header={t('invoiceDetailsForm.billingAddress')}
          name="address"
          menuPlacement="top"
          fieldType={AddressFieldType.EXTENDED}
        />
      </div>
      <div className={styles['buttons-wrapper']}>
        <Button handleClick={handleCancelClick} className={styles.button} outline>
          {t('invoiceDetailsForm.backButton')}
        </Button>
        <SubmitButton className={classNames(styles['submit-button'], styles.button)}>
          {t('invoiceDetailsForm.submitButton')}
        </SubmitButton>
      </div>
    </>
  );
};

export default observer(InvoiceDetailsForm);
