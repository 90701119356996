import React from 'react';
// components
import ConfirmEmailInfo from 'components/confirmEmail/ConfirmEmailInfo';
import PublicLayout from 'shared/layouts/public/index';

const ConfirmEmail: React.FC = () => {
  return (
    <PublicLayout>
      <ConfirmEmailInfo />
    </PublicLayout>
  );
};

export default ConfirmEmail;
