// libs
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
// helpers
import useImageCrop from 'components/Form/inputs/ImageCropInput/useImageCrop';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { FileUploadInputProps } from 'components/Form/inputs/FileUpload/FileUpload';
// components
import Input, { InputTypes } from 'components/Form/inputs/Input';
import InputErrors from 'components/Form/inputs/InputErrors';
// context
import { useFormContext } from 'components/Form/FormContext';
// styles
import styles from 'components/Form/inputs/ImageCropInput/ImageCropInput.module.scss';

export interface ImageCropInputProps extends FileUploadInputProps {
  isFilesListShown?: boolean;
  isAutoSubmitAllowed?: boolean;
}

const ImageCropInput: React.FC<ImageCropInputProps> = (props: ImageCropInputProps) => {
  const { children, fieldWrapperClassName = '', onChangeCallback, ...inputProps } = props;
  const {
    label,
    name,
    controlElement,
    isRemoveDisabled = false,
    isControlsHidden = false,
    isFilesListShown = false,
    isAutoSubmitAllowed = false,
  } = props;
  const { addFiles, files, removeFile, error, allowedFileTypes, fieldName } = useImageCrop(props);
  const { t } = useTranslation('forms');
  const { isSubmitEnabled = false, onSubmitForm } = useFormContext();

  useEffect(() => {
    if (isSubmitEnabled && isAutoSubmitAllowed && onSubmitForm) {
      onSubmitForm();
    }
  }, [isSubmitEnabled, isAutoSubmitAllowed]);

  useEffect(() => {
    if (onChangeCallback) {
      onChangeCallback(files);
    }
  }, [files, onChangeCallback]);

  return (
    <Input {...inputProps} name={fieldName} type={InputTypes.file} onChangeCallback={addFiles}>
      {({ isRequired, errorMessages, inputCommonProps: { className, disabled, value, ...inputCommonProps } }) => {
        const inputClassName = classnames(styles['image-crop__control'], className, {
          [styles['crop-image--disabled']]: disabled,
        });

        return (
          <div className={classnames(styles['field-wrapper'], fieldWrapperClassName)}>
            <label
              htmlFor={name}
              className={classnames(styles['image-crop__label'], {
                [styles['image-crop__label--required']]: isRequired && label,
              })}
            >
              {label}
            </label>
            <input
              {...inputCommonProps}
              id={name}
              accept={allowedFileTypes.join(',')}
              className={styles['image-crop__input']}
              disabled={disabled}
            />

            <div>
              {isFilesListShown &&
                files.map(({ file, key }) => {
                  return (
                    <div key={key} className={styles.file__wrapper}>
                      <span className={styles.file__name}>{file.name}</span>
                      {!isRemoveDisabled && (
                        <RemoveIcon
                          className={classnames(styles['remove-button'], {
                            [styles['image-crop--disabled']]: disabled,
                          })}
                          role="button"
                          aria-label="remove-button"
                          tabIndex={0}
                          onClick={() => !disabled && removeFile(key)}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
            {!isControlsHidden && (
              <label htmlFor={name} className={inputClassName}>
                {controlElement || t('selectButtonDefaultText')}
              </label>
            )}
            {!error && errorMessages && errorMessages.length && <InputErrors errorMessages={errorMessages} />}
            {error && <InputErrors errorMessages={[error]} />}
            {children}
          </div>
        );
      }}
    </Input>
  );
};

export default observer(ImageCropInput);
