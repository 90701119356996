// libraries
import React from 'react';
import { toast, Flip, ToastOptions, TypeOptions } from 'react-toastify';
import i18next from 'i18next';
// components
import ToastMessage from './ToastMessage/ToastMessage';
// RootStore
import { store } from 'shared/stores/RootStore/RootStore';

export const TOAST_DEFAULT_OPTIONS = {
  position: toast.POSITION.TOP_CENTER,
  type: toast.TYPE.INFO,
  autoClose: false,
  pauseOnHover: true,
  hideProgressBar: true,
  draggable: false,
  transition: Flip,
};

interface CustomToastOptions extends ToastOptions {
  title?: string;
}

const addToast = (text: string | React.ReactElement, params: CustomToastOptions) => {
  const { title = '', toastId = '', ...toastParams } = params;
  const {
    toastStore: { addToastId, removeToastId, isOpenToast },
  } = store;

  const options = {
    ...TOAST_DEFAULT_OPTIONS,
    ...toastParams,
    toastId,
    onOpen() {
      addToastId(toastId as string);
    },
    onClose() {
      removeToastId(toastId as string);
    },
  };

  if (isOpenToast(toastId as string)) {
    return null;
  }

  // @ts-ignore
  return toast(<ToastMessage title={title} message={text} type={options.type} />, options);
};

export const addSuccessToast = (text = '', title = i18next.t('toasts:successTitle')): void => {
  addToast(text, {
    type: toast.TYPE.SUCCESS,
    title,
    toastId: text,
  });
};

export const addWarningToast = (text: string, title = i18next.t('toasts:warningTitle')): void => {
  addToast(text, {
    type: toast.TYPE.WARNING,
    title,
    toastId: text,
  });
};

export const addInfoToast = (text: string, title = ''): void => {
  addToast(text, {
    type: toast.TYPE.INFO,
    title,
    toastId: text,
  });
};

export const addErrorToast = (text?: string, title = i18next.t('toasts:errorTitle')): void => {
  const {
    authStore: { sessionTimedOut },
  } = store;

  if (sessionTimedOut) return;

  const message: string = text || i18next.t('errors:defaultError');

  addToast(message, {
    type: toast.TYPE.ERROR,
    title,
    toastId: message,
  });
};

export const addStaticToast = ({
  text,
  type,
  title,
  toastId,
}: {
  text: string | React.ReactElement;
  type: TypeOptions;
  title?: string;
  toastId?: string;
}): void => {
  const {
    authStore: { sessionTimedOut },
  } = store;

  if (sessionTimedOut) return;

  addToast(text, {
    type,
    title,
    toastId: toastId || String(text),
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    closeButton: false,
  });
};
