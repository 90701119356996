// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// assets
import marketingImage from 'assets/images/marketing-image.svg';
// styles
import styles from './MarketingComponent.module.scss';
// components
import Button from 'components/Button';

const MarketingComponent: React.FC = () => {
  const { t } = useTranslation('homePage');

  return (
    <div className={styles.marketing}>
      <img src={marketingImage} alt="Ad" className={styles['marketing-image']} />
      <div className={styles['marketing__text-block']}>
        <p className={styles['marketing-title']}>{t('marketingMessage')}</p>
        <Button className={styles['marketing-button']} btnLink>
          {t('marketingLink')}
        </Button>
      </div>
    </div>
  );
};

export default observer(MarketingComponent);
