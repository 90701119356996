import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import signUpPage from './translations/en/signUpPage.json';
import loginPage from './translations/en/loginPage.json';
import passwordReset from './translations/en/passwordReset.json';
import forms from './translations/en/forms.json';
import errors from './translations/en/errors.json';
import navigation from './translations/en/navigation.json';
import personalProfileSettings from './translations/en/personalProfileSettings.json';
import companyProfileSettings from './translations/en/companyProfileSettings.json';
import states from './translations/en/states.json';
import connectedAccountsPage from './translations/en/connectedAccountsPage.json';
import registrationCompanyModals from './translations/en/registrationCompanyModals.json';
import homePage from './translations/en/homePage.json';
import companyAccountPage from './translations/en/companyAccountPage.json';
import relationshipToCompany from './translations/en/relationshipToCompany.json';
import accountingPage from './translations/en/accountingPage.json';
import confirmEmail from './translations/en/confirmEmail.json';
import submitEmail from './translations/en/submitEmail.json';
import emailConfirmed from './translations/en/emailConfirmed.json';
import sessionTimeoutPage from './translations/en/sessionTimeoutPage.json';
import notifications from './translations/en/notifications.json';
import confirmationModal from './translations/en/confirmationModal.json';
import invoiceDetails from './translations/en/invoiceDetails.json';
import transactionHistory from './translations/en/transactionHistory.json';
import twoFactorAuth from './translations/en/twoFactorAuth.json';
import companySearch from './translations/en/companySearch.json';
import sendInvoicePage from './translations/en/sendInvoicePage.json';
import payments from './translations/en/payments.json';
import company from './translations/en/company.json';
import calendar from './translations/en/calendar.json';
import balance from './translations/en/balance.json';
import toasts from './translations/en/toasts.json';
import transactionContextMenu from './translations/en/transactionContextMenu.json';
import exportTransactions from './translations/en/exportTransactions.json';
import inviteBusiness from './translations/en/inviteBusiness.json';
import documentsPage from './translations/en/documentsPage.json';
import errorPages from 'translations/en/errorPages.json';
import externalClients from './translations/en/externalClients.json';
import integrations from './translations/en/integrations.json';
import verification from './translations/en/verification.json';
import modalSteps from './translations/en/modalSteps.json';
import beneficiaryOwners from './translations/en/beneficiaryOwners.json';
import payableReceivable from './translations/en/payableReceivable.json';
import invoice from './translations/en/invoice.json';
import externalPaymentPage from './translations/en/externalPaymentPage.json';
import addBillPage from './translations/en/addBillPage.json';
import settingsPage from './translations/en/settingsPage.json';
import paymentMethodsSettings from './translations/en/paymentMethodsSettings.json';
import settingAccountIntegrations from './translations/en/SettingAccountIntegrations.json';
import shortLists from './translations/en/shortLists.json';
import contactPages from 'translations/en/contactPages.json';

const resources = {
  en: {
    signUpPage,
    registrationCompanyModals,
    forms,
    modalSteps,
    errors,
    navigation,
    personalProfileSettings,
    companyProfileSettings,
    states,
    connectedAccountsPage,
    homePage,
    loginPage,
    companyAccountPage,
    relationshipToCompany,
    accountingPage,
    passwordReset,
    payableReceivable,
    confirmEmail,
    submitEmail,
    emailConfirmed,
    sessionTimeoutPage,
    notifications,
    confirmationModal,
    sendInvoicePage,
    invoiceDetails,
    transactionHistory,
    twoFactorAuth,
    companySearch,
    payments,
    company,
    calendar,
    balance,
    toasts,
    transactionContextMenu,
    inviteBusiness,
    documentsPage,
    errorPages,
    exportTransactions,
    externalClients,
    integrations,
    verification,
    beneficiaryOwners,
    invoice,
    externalPaymentPage,
    addBillPage,
    shortLists,
    settingsPage,
    paymentMethodsSettings,
    settingAccountIntegrations,
    contactPages,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
