// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import PaymentLayout from 'shared/layouts/payment';
import FormHeader from 'components/MultiStep/Form/FormHeader/FormHeader';
import PaymentForm from './PaymentForm/PaymentForm';
import InvoiceOwnerInfo from 'components/invoices/InvoiceOwnerInfo/InvoiceOwnerInfo';
import PaymentReview from './PaymentReview/PaymentReview';
import Button from 'components/Button';
import ConfirmationPage from './ConfirmationPage/ConfirmationPage';
// helpers
import useAccountPayable, {
  AccountPayableStep,
  IPayment,
} from 'components/externalPayments/AccountPayable/Payment/useAccountPayable';
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
// styles
import styles from 'components/externalPayments/AccountReceivable/AccountReceivable.module.scss';
import paymentFormStyles from './Payment.module.scss';

const Payment: React.FC<IPayment> = (props: IPayment) => {
  const { t } = useTranslation('externalPaymentPage');
  const { invoiceDetails, setInvoiceDetails } = props;
  const {
    currentStep,
    isCurrentStep,
    isPlaidAvailable,
    openPlaidModal,
    contextValue,
    paymentAccount,
    getPaid,
    payDate,
    setPayDate,
    isSending,
    openReviewPage,
  } = useAccountPayable(props);
  const isFormShown = useMemo(() => isCurrentStep(AccountPayableStep.PAYMENT_METHOD), [isCurrentStep]);

  if (isCurrentStep(AccountPayableStep.COMPLETED_PAYMENT)) {
    return <ConfirmationPage invoiceDetails={invoiceDetails} setInvoiceDetails={setInvoiceDetails} />;
  }

  return (
    <PaymentLayout companyName={invoiceDetails?.counterpartyDetails?.dbaName}>
      <div className={styles.page}>
        <div className={styles.page__wrapper}>
          <h1 className={classnames(styles.page__title, paymentFormStyles.page__title)}>{t('title')}</h1>
          <MultiStepProvider value={contextValue}>
            <div
              className={classnames(paymentFormStyles.page__content, {
                [paymentFormStyles['completed-page']]: !isFormShown,
              })}
            >
              <FormHeader
                currentStep={currentStep || AccountPayableStep.PAYMENT_METHOD}
                lastStep={AccountPayableStep.REVIEW_INFORMATION}
                title={isFormShown ? t('chooseMethodAndDate') : t('confirmMessage')}
              />
              <div className={styles['page__invoice-owner']}>
                <InvoiceOwnerInfo
                  logoSrc={invoiceDetails?.counterpartyDetails?.logoImage}
                  companyName={invoiceDetails?.counterpartyDetails?.dbaName}
                  companyEmail={invoiceDetails?.counterpartyDetails?.email}
                  invoiceNumber={invoiceDetails?.invoiceNumber}
                />
              </div>
              {isFormShown ? (
                <PaymentForm
                  openPlaidModal={openPlaidModal}
                  invoiceDetails={invoiceDetails}
                  isPlaidAvailable={isPlaidAvailable}
                  paymentAccount={paymentAccount}
                  paymentDate={payDate}
                  setPaymentDate={setPayDate}
                  submitDisabled={!paymentAccount || !payDate || isSending}
                  submitCallback={openReviewPage}
                />
              ) : (
                <PaymentReview
                  paymentDate={payDate}
                  paymentAccount={paymentAccount}
                  setPaymentDate={setPayDate}
                  invoiceDetails={invoiceDetails}
                  openPlaidModal={openPlaidModal}
                >
                  <Button
                    className={paymentFormStyles['payment-confirm-button']}
                    disabled={isSending}
                    handleClick={getPaid}
                  >
                    {t('button.completePayment')}
                  </Button>
                </PaymentReview>
              )}
            </div>
          </MultiStepProvider>
        </div>
      </div>
    </PaymentLayout>
  );
};

export default Payment;
