// libraries
import { ChangeEvent, useCallback, useState, useMemo } from 'react';
// components
import { InputElements } from 'components/Form/inputs/Input';
import stall from 'shared/helpers/simulateAsyncCall';
// constants
import { TransactionType } from 'shared/constants/transactions';
import { ModalIds } from 'shared/constants/modalIds';
import { TransferType } from 'shared/stores/TransferFundsStore/TransferFundsStore';
// helpers
import { formatCurrency } from 'shared/helpers/format';
// context
import { FormData } from 'components/Form/FormContext';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
import { useMultiStepContext } from '../../MultiStep/MultiStepContext';

const INTERVAL_BEFORE_CLOSE_MODAL = 2000;

export enum StepsNumber {
  FOUND = 1,
  CONFIRM = 2,
}

type TransferFoundsData = {
  isFormSubmitted: boolean;
  submitForm: (data: FormData, transactionType: TransactionType) => void;
  isLoading: boolean;
  handleChangeAmount: (e: ChangeEvent<InputElements>) => void;
  amount: string;
  isSubmitDisabled: boolean;
  isAddFundsPopup: boolean;
  isAmountError: boolean;
};

const useTransferFunds = (): TransferFoundsData => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [amount, setAmount] = useState<string>('');
  const { currentStep, goToStep } = useMultiStepContext();

  const {
    transferFundsStore: { transferType },
    modalStore: { closeModal },
    eventBalancesStore: { balancesData },
  } = useStore();

  const isAddFundsPopup = useMemo(() => transferType === TransferType.add, [transferType]);

  const amountNumber = useMemo(() => +(amount as string).replace(/[^\d.]/g, ''), [amount]);

  const isAmountError = useMemo(() => amountNumber > balancesData.zuroBalance && !isAddFundsPopup, [
    amountNumber,
    balancesData.zuroBalance,
    isAddFundsPopup,
  ]);

  const isSubmitDisabled = useMemo(
    () => isLoading || isAmountError || !amount || !amountNumber || amount[0] === '.' || amount.slice(-1) === '.',
    [amount, amountNumber, isAmountError, isLoading],
  );

  const submitForm = useCallback(
    async (data: FormData, transactionType: TransactionType) => {
      if (isSubmitDisabled) {
        return;
      }

      if (currentStep === StepsNumber.FOUND && goToStep) {
        goToStep(StepsNumber.CONFIRM);
        return;
      }

      // TODO Must be changed after integration
      setIsFormSubmitted(false);
      setIsLoading(true);

      await stall(data, 1000);

      setIsFormSubmitted(true);
      setTimeout(() => {
        closeModal(ModalIds.transferFunds);
      }, INTERVAL_BEFORE_CLOSE_MODAL);
    },
    [isSubmitDisabled, currentStep, goToStep, closeModal],
  );

  const handleChangeAmount = useCallback((e: ChangeEvent<InputElements>) => {
    const formatAmount = formatCurrency(+e.target.value, false).slice(1);

    setAmount(formatAmount);
  }, []);

  return {
    amount,
    isSubmitDisabled,
    isFormSubmitted,
    submitForm,
    isLoading,
    handleChangeAmount,
    isAddFundsPopup,
    isAmountError,
  };
};

export default useTransferFunds;
