import AbstractRequest from './AbstractRequest';

export const apiPrefix = '/api/v1';

export enum HttpStatus {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FAILED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
}

export type ResponsePromise = Promise<unknown>;

export interface RequestManager {
  sendRequest: <T extends AbstractRequest>(request: T) => ResponsePromise;
  handleError: (error: Response) => ResponsePromise;
}

abstract class AbstractRequestManager implements RequestManager {
  sendRequest: <T extends AbstractRequest>(request: T) => ResponsePromise = async request => {
    return fetch(request.url, request.fetchParams)
      .then(this.handleResponse)
      .catch(error => Promise.reject(error));
  };

  handleResponse = (response: Response): ResponsePromise => {
    const contentType = response.headers.get('content-type');

    if (response.ok || response.status === HttpStatus.OK) {
      if (contentType === 'text/plain; charset=utf-8') {
        return Promise.resolve(response.text());
      }

      if (contentType && contentType.startsWith('application/json')) {
        return Promise.resolve(response.json());
      }

      return Promise.resolve(response);
    }

    return this.handleError(response);
  };

  abstract handleError = (error: Response): Promise<Error> => Promise.reject(error);
}

export default AbstractRequestManager;
