// libraries
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import { TextInput, NumberInput, DateInput, Textarea } from 'components/Form/inputs';
import SearchBar from 'components/company/SearchBar/SearchBar';
import CompanyCard from 'components/company/SearchBar/CompanyCard/CompanyCard';
import TwoColumnRow from 'components/MultiStep/Form/TwoColumnRow/TwoColumnRow';
import FormFieldLabel from 'components/MultiStep/Form/FormFieldLabel/FormFieldLabel';
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import { useFormContext } from '../../../Form/FormContext';
// helpers
import useAddBill from '../../useAddBill';
import useCloseByOutsideAction from 'shared/helpers/useCloseByOutsideAction';
import { AddBillFormData, SelectedCompanyInfoProps } from '../../types';
// constants
import fieldSizes from 'shared/constants/fieldSizes';
// styles
import styles from './FormFields.module.scss';

const TEXT_AREA_MAX_LENGTH = 240;

type FormFieldsProps = {
  setIsNewVendorFormSchema?: Dispatch<SetStateAction<boolean>>;
  setCompanyInfo?: Dispatch<SetStateAction<SelectedCompanyInfoProps>>;
  handleReturn: () => void;
};

const FormFields: React.FC<FormFieldsProps> = ({
  setIsNewVendorFormSchema = () => {},
  setCompanyInfo,
  handleReturn,
}: FormFieldsProps) => {
  const { t } = useTranslation('addBillPage');
  const {
    isScrollableList,
    companies,
    isLoaded,
    onSelectCompanyHandler,
    isDisabledEmailInput,
    isNewVendor,
    onSearch,
    isOpenSearch,
    vendorInputValue,
    isSubmitButtonDisabled,
  } = useAddBill(setCompanyInfo);
  const { getFieldValue = () => {} } = useFormContext();

  const emailInputClassName = classNames(styles.input, {
    [styles['email-input--disabled']]: isDisabledEmailInput,
  });

  const hasNotResults = !companies.length && isLoaded;

  const companyListContainerClassName = classNames(styles['company-list__container'], {
    [styles['company-list__container--active']]: isOpenSearch,
    [styles['scroll--hidden']]: !isScrollableList,
    [styles['company-list__container--hidden']]: hasNotResults,
  });

  const scrollBarClassName = classNames(styles.scrollbar, {
    [styles['scrollbar--hidden']]: hasNotResults,
  });

  const newVendorClassName = classNames(styles['new-vendor'], {
    [styles['new-vendor--hidden']]: !isNewVendor,
  });

  const vendorInputClassName = classNames(styles.input, styles['vendor-input'], {
    [styles['vendor-input__new-vendor']]: isNewVendor,
  });

  useEffect(() => {
    setIsNewVendorFormSchema(isNewVendor);
  }, [isNewVendor, setIsNewVendorFormSchema]);

  return (
    <>
      <div className={styles['inputs-container']}>
        <TwoColumnRow
          column1={
            <FormFieldLabel className={styles['add-bill-form__label']}>{t('form.fields.vendor.label')}</FormFieldLabel>
          }
          column2={
            <SearchBar
              name="vendor"
              onSearch={onSearch}
              companiesLength={companies.length}
              isLoaded={isLoaded}
              companyListContainerClassName={companyListContainerClassName}
              inputValue={vendorInputValue}
              newBusinessWrapperClassName={styles['new-business__wrapper']}
              closeOnClick={useCloseByOutsideAction}
              className={vendorInputClassName}
              searchInputContainerClassName={styles['search-input__container']}
              scrollBarClassName={scrollBarClassName}
              additionalInputText={<p className={newVendorClassName}>{t('newVendor')}</p>}
              iconClassName={styles['vendor-input__icon']}
              companyNameValue={getFieldValue(AddBillFormData.vendor)}
            >
              {companies.map(company => (
                <CompanyCard
                  key={company.id}
                  onClickCallback={() => onSelectCompanyHandler(company.dbaName, company.email, company.logoImage)}
                  company={company}
                  value={vendorInputValue}
                />
              ))}
            </SearchBar>
          }
          className={styles['add-bill-form__field']}
        />
        {isNewVendor && (
          <TwoColumnRow
            column1={
              <FormFieldLabel className={styles['add-bill-form__label']}>
                {t('form.fields.contactName.label')}
              </FormFieldLabel>
            }
            column2={
              <TextInput
                name="contactName"
                fieldWrapperClassName={styles.field__wrapper}
                inputWrapperClassName={styles.input__wrapper}
                className={styles.input}
                errorInputClassName={styles['input--invalid']}
                autoComplete="off"
              />
            }
            className={styles['add-bill-form__field']}
          />
        )}
        <TwoColumnRow
          column1={
            <FormFieldLabel className={styles['add-bill-form__label']}>{t('form.fields.email.label')}</FormFieldLabel>
          }
          column2={
            <TextInput
              name="email"
              fieldWrapperClassName={styles.field__wrapper}
              inputWrapperClassName={styles.input__wrapper}
              className={emailInputClassName}
              errorInputClassName={styles['input--invalid']}
              value={getFieldValue('email')}
              disabled={isDisabledEmailInput}
            />
          }
          className={styles['add-bill-form__field']}
        />
        <TwoColumnRow
          column1={
            <FormFieldLabel className={styles['add-bill-form__label']}>{t('form.fields.amount.label')}</FormFieldLabel>
          }
          column2={
            <NumberInput
              name="amount"
              fieldWrapperClassName={styles.field__wrapper}
              prefix="$"
              placeholder={t('form.fields.amount.placeholder')}
              inputWrapperClassName={styles.input__wrapper}
              className={styles.input}
              errorInputClassName={styles['input--invalid']}
              value={getFieldValue(AddBillFormData.amount)}
              integerLimit={fieldSizes.MONEY_INTEGER_LIMIT}
            />
          }
          className={styles['add-bill-form__field']}
        />
        <TwoColumnRow
          column1={
            <FormFieldLabel className={styles['add-bill-form__label']}>{t('form.fields.invoice.label')}</FormFieldLabel>
          }
          column2={
            <TextInput
              name="invoiceNumber"
              fieldWrapperClassName={styles.field__wrapper}
              inputWrapperClassName={styles.input__wrapper}
              className={styles.input}
              errorInputClassName={styles['input--invalid']}
              autoComplete="off"
            />
          }
          className={styles['add-bill-form__field']}
        />
        <TwoColumnRow
          column1={
            <FormFieldLabel className={styles['add-bill-form__label']}>{t('form.fields.dueDate.label')}</FormFieldLabel>
          }
          column2={
            <DateInput
              name="dueDate"
              fieldWrapperClassName={styles.field__wrapper}
              inputWrapperClassName={styles.input__wrapper}
              className={classNames(styles.input, styles['date-input'])}
              errorInputClassName={styles['input--invalid']}
              popperPlacement="right"
              minDate={new Date()}
            />
          }
          className={styles['add-bill-form__field']}
        />
        <TwoColumnRow
          column1={
            <FormFieldLabel
              className={classNames(styles['add-bill-form__label'], styles['add-bill-form__textarea-label'])}
            >
              {t('form.fields.message.label')}
            </FormFieldLabel>
          }
          column2={
            <Textarea
              name="message"
              shouldCounterBeShown
              fieldWrapperClassName={styles.field__wrapper}
              placeholder={t('form.fields.message.placeholder')}
              inputWrapperClassName={classNames(styles.input__wrapper, styles.textarea__wrapper)}
              maxLength={TEXT_AREA_MAX_LENGTH}
              className={classNames(styles.input, styles.textarea)}
              counterClassName={styles['textarea-counter']}
              errorInputClassName={styles['input--invalid']}
            />
          }
          className={classNames(styles['add-bill-form__field'], styles['add-bill-form__textarea-field'])}
        />
      </div>
      <div className={styles.buttons__container}>
        <Button className={styles['button-cancel']} outline handleClick={handleReturn}>
          {t('form.cancelButton')}
        </Button>
        <SubmitButton className={styles['button-submit']} disabled={isSubmitButtonDisabled}>
          {t('form.submitButton')}
        </SubmitButton>
      </div>
    </>
  );
};

export default FormFields;
