// libraries
import React from 'react';
// components
import BaseLayout from 'shared/layouts/basic';
import Scrollbar from 'components/Scrollbar/Scrollbar';
import InAppNotification from 'components/notifications/InApp/InAppNotification/InAppNotification';
// hooks
import useInAppNotifications from 'components/notifications/InApp/useInAppNotifications';
// styles
import styles from './Notifications.module.scss';

const Notifications = () => {
  const { notifications } = useInAppNotifications();

  return (
    <BaseLayout>
      <div className={styles['notifications-container']}>
        <h1 className={styles['notifications-title']}>Notifications</h1>
        <Scrollbar className={styles.scrollbar}>
          {notifications.map(({ notificationId, ...rest }) => (
            <InAppNotification
              key={notificationId}
              notificationId={notificationId}
              logoClassName={styles.logo}
              shortCardClassName={styles['short-card']}
              messageClassName={styles.notifications__message}
              helpTextClassName={styles['notifications__help-text']}
              {...rest}
            />
          ))}
        </Scrollbar>
      </div>
    </BaseLayout>
  );
};

export default Notifications;
