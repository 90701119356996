// libraries
import { useCallback, useState } from 'react';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

type BalanceMenuParams = {
  isOpen: boolean;
  toggleMenu: () => void;
  handleCloseMenu: () => void;
  addFunds: () => void;
  withdrawFunds: () => void;
};

const useBalanceMenu = (): BalanceMenuParams => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    modalStore: { openModal },
    transferFundsStore: { setTransferToZuro, setTransferToBank },
  } = useStore();

  const toggleMenu = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openFundsModal = useCallback(() => {
    handleCloseMenu();
    openModal(ModalIds.transferFunds);
  }, [handleCloseMenu, openModal]);

  const addFunds = useCallback(() => {
    setTransferToZuro();
    openFundsModal();
  }, [openFundsModal, setTransferToZuro]);

  const withdrawFunds = useCallback(() => {
    setTransferToBank();
    openFundsModal();
  }, [openFundsModal, setTransferToBank]);

  return {
    toggleMenu,
    handleCloseMenu,
    isOpen,
    addFunds,
    withdrawFunds,
  };
};

export default useBalanceMenu;
