// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { sortBy } from 'lodash';
// components
import { Select, TextInput } from 'components/Form/inputs';
import { InputFieldSize } from 'components/Form/inputs/TextInput/TextInput';
import Modal from 'components/Modal/Modal';
import Form from 'components/Form';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import Button from 'components/Button';
import ChangeAvatarInput from 'components/Form/inputs/ChangeAvatarInput/ChangeAvatarInput';
import CompanyProfileFormField from 'components/company/CompanyProfileForm/CompanyProfileFormField/CompanyProfileFormField';
// constants
import BUSINESS_TYPES from 'shared/constants/businessTypes';
// helpers
import useMyBusinessProfile from './useMyBusinessProfile';
import stall from 'shared/helpers/simulateAsyncCall';
// styles
import styles from './CompanyProfileForm.module.scss';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// assets
import { ReactComponent as EditPen } from 'assets/icons/edit-pen.svg';
// store
import { useStore } from 'shared/stores/RootStore/useStore';
// schema
import { COMPANY_PROFILE_FORM_SCHEMA } from './validation-rules';

const CompanyProfileForm: React.FC = () => {
  const { t } = useTranslation('companyProfileSettings');
  const {
    modalStore: { closeAllModals },
  } = useStore();
  const { myBusinessProfile, updateData } = useMyBusinessProfile();

  const titleBlock = (
    <div className={styles.form__title}>
      <EditPen className={styles['form-title__icon']} />
      <span className={styles['form-title__text']}>{t('editInformation')}</span>
    </div>
  );
  const logoChangeWrapper = styles['field-change-logo__wrapper'];
  const inputClassName = styles.field__input;
  const fieldWrapperClassName = styles.input__wrapper;

  const closeModal = useCallback(() => {
    closeAllModals();
  }, [closeAllModals]);

  const submitForm = useCallback(async data => {
    await stall();
    updateData(data);
    closeModal();
  }, []);

  return (
    <Modal id={ModalIds.editProfileForm} modalClassName={styles['edit-form__modal']}>
      <Form
        data={{
          logoImage: myBusinessProfile?.logoImage,
          dbaName: myBusinessProfile?.dbaName,
          handle: myBusinessProfile?.handle,
          industry: myBusinessProfile?.industry,
          website: myBusinessProfile?.website,
          registeredSince: myBusinessProfile?.registeredSince,
        }}
        className={styles.form__wrapper}
        submitForm={submitForm}
        validationSchema={COMPANY_PROFILE_FORM_SCHEMA}
      >
        <div className={styles.layout}>
          {titleBlock}
          <div className={styles.layout__content}>
            <CompanyProfileFormField wrapperClassName={logoChangeWrapper} title={t('formFields.companyLogo')}>
              <ChangeAvatarInput userAvatar={myBusinessProfile?.logoImage} />
            </CompanyProfileFormField>
            <CompanyProfileFormField title={t('formFields.companyName')}>
              <TextInput
                name="dbaName"
                inputSize={InputFieldSize.M}
                className={inputClassName}
                fieldWrapperClassName={fieldWrapperClassName}
                placeholder={t('placeholders.companyName')}
              />
            </CompanyProfileFormField>
            <CompanyProfileFormField title={t('formFields.username')}>
              <TextInput
                name="handle"
                inputSize={InputFieldSize.M}
                className={inputClassName}
                fieldWrapperClassName={fieldWrapperClassName}
              />
            </CompanyProfileFormField>
            <CompanyProfileFormField title={t('formFields.industry')}>
              <Select
                name="industry"
                options={sortBy(BUSINESS_TYPES, ['value'])}
                isSearchable
                isClearable
                isFormField
                fieldWrapperClassName={fieldWrapperClassName}
                placeholder={t('placeholders.industry')}
                listSize="254px"
              />
            </CompanyProfileFormField>
            <CompanyProfileFormField title={t('formFields.website')}>
              <TextInput
                name="website"
                inputSize={InputFieldSize.M}
                className={inputClassName}
                fieldWrapperClassName={fieldWrapperClassName}
              />
            </CompanyProfileFormField>
            <CompanyProfileFormField title={t('formFields.yearFounded')}>
              <TextInput
                name="registeredSince"
                inputSize={InputFieldSize.M}
                className={inputClassName}
                fieldWrapperClassName={fieldWrapperClassName}
                placeholder={t('placeholders.yearFounded')}
              />
            </CompanyProfileFormField>
          </div>
          <hr className={styles.horizontal__separator} />
          <div className={styles.button__panel}>
            <Button outline className={styles.cancel__button} handleClick={closeModal}>
              {t('cancel')}
            </Button>
            <SubmitButton className={styles.save__button}>{t('updateProfile')}</SubmitButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default observer(CompanyProfileForm);
