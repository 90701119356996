// libraries
import React, { useMemo } from 'react';
import { format, isToday } from 'date-fns';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
// assets
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as DoneIcon } from 'assets/icons/doneLogo.svg';
import { ReactComponent as EditPen } from 'assets/icons/edit-pen.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendarPayment.svg';
// components
import PaymentDatePicker from 'components/externalPayments/Calendar/Calendar';
// styles
import styles from 'components/externalPayments/ExternalFormAction/ExternalFormAction.module.scss';

import { DateFormats } from 'shared/helpers/format';

type TChooseDate = {
  dueDate: Date;
  selectedDate?: Nullable<Date>;
  handleChange: (date: Date) => void;
};

const ChooseDate: React.FC<TChooseDate> = ({ dueDate, selectedDate, handleChange }: TChooseDate) => {
  const { t } = useTranslation('externalPaymentPage');

  const formattedDate = useMemo(() => {
    if (!selectedDate) {
      return '';
    }

    if (isToday(selectedDate)) {
      return t('selectedDateIsToday');
    }

    return format(selectedDate, DateFormats.invoiceReviewDate);
  }, [selectedDate, t]);

  if (selectedDate) {
    return (
      <div className={classnames(styles.field, styles['date-action'])}>
        <div>
          <div className={styles.field__label}>
            <div className={styles['field__label-text']}>{t('chooseDate')}</div>
            <DoneIcon className={styles['icon-warning']} />
          </div>
          <div className={styles.field__value}>{formattedDate}</div>
        </div>
        <div className={classnames('external-payment-datepicker', styles.field__button)}>
          <PaymentDatePicker selectedDate={selectedDate} dueDate={dueDate} handleChange={handleChange}>
            <div>
              <EditPen className={styles['icon-edit-pen']} />
              {t('dateAdded')}
            </div>
          </PaymentDatePicker>
        </div>
      </div>
    );
  }

  return (
    <div className={classnames(styles.button, styles['date-action'])}>
      <div>
        <div className={styles.button__info}>
          <p className={styles['button__info-message']}>{t('chooseDate')}</p>
          <WarningIcon className={styles['icon-warning']} />
        </div>
        <p className={styles['button__help-text']}>{t('noDate')}</p>
      </div>
      <div className={classnames('external-payment-datepicker', styles['button__additional-info'])}>
        <PaymentDatePicker dueDate={dueDate} handleChange={handleChange}>
          <div>
            <CalendarIcon className={styles['add-icon']} />
            {t('addDate')}
          </div>
        </PaymentDatePicker>
      </div>
    </div>
  );
};

export default ChooseDate;
