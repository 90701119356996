// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/Button';
import FormFooter from 'components/MultiStep/Form/FormFooter/FormFooter';
import TwoColumnRow from 'components/MultiStep/Form/TwoColumnRow/TwoColumnRow';
import FormFieldLabel from 'components/MultiStep/Form/FormFieldLabel/FormFieldLabel';
// helpers
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
import { TInvoiceFormProps } from 'components/invoices/InvoiceForm/InvoiceForm';
import { formatDate, DateFormats, formatCurrency } from 'shared/helpers/format';
import { FormData } from 'components/Form/FormContext';
// styles
import styles from './InvoiceFormReview.module.scss';

type TInvoiceReviewParams = {
  amount: number;
  dueDate: string;
  description?: string;
};

type TInvoiceReviewProps = Omit<TInvoiceFormProps, 'currentStep'> & {
  handleSubmitReview: (steps: FormData[]) => void;
};

const InvoiceFormReview: React.FC<TInvoiceReviewProps> = ({
  handleReturn,
  handleSubmitReview,
}: TInvoiceReviewProps) => {
  const { t } = useTranslation('invoice');
  const { formData = {}, steps } = useMultiStepContext();

  const { amount = '', dueDate = '', description = '' } = formData as TInvoiceReviewParams;

  return (
    <div>
      <div className={styles['invoice-review']}>
        <TwoColumnRow
          column1={<FormFieldLabel>{t('fields.amount.label')}</FormFieldLabel>}
          column2={<div className={styles['invoice-review__field-value']}>{formatCurrency(amount || 0, false)}</div>}
          className={styles['invoice-review__field']}
        />
        <TwoColumnRow
          column1={<FormFieldLabel>{t('fields.dueDate.label')}</FormFieldLabel>}
          column2={
            <div className={styles['invoice-review__field-value']}>
              {formatDate(dueDate, DateFormats.invoiceReviewDate)}
            </div>
          }
          className={styles['invoice-review__field']}
        />
        <TwoColumnRow
          column1={
            <FormFieldLabel
              helpText={`(${t('fields.description.helpText')})`}
              className={styles['invoice-review__field-label--description']}
            >
              {t('fields.description.label')}
            </FormFieldLabel>
          }
          column2={<div className={styles['invoice-review__field-value--description']}>{description}</div>}
          className={styles['invoice-review__field']}
        />
      </div>
      <FormFooter>
        <Button outline handleClick={handleReturn} className={styles['invoice-review__button']}>
          {t('editInfoButton')}
        </Button>
        <Button
          className={styles['invoice-review__button']}
          handleClick={() => handleSubmitReview(steps as FormData[])}
        >
          {t('sendInvoiceButton')}
        </Button>
      </FormFooter>
    </div>
  );
};

export default InvoiceFormReview;
