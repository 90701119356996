// libraries
import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import EmailColumn from 'components/contacts/ContactsTable/columns/Email';
import ContactNameColumn from 'components/contacts/ContactsTable/columns/ContactName';
import BusinessColumn from 'components/contacts/ContactsTable/columns/Business';
import SearchInput from 'components/list/search/SearchInput';
import DataGridLayout from 'components/list/layout/DataGridLayout';
import List from 'components/list/List';
import Button from 'components/Button';
// constants
import ContactPages from 'shared/constants/contactPages';
// helpers
import { ASC } from 'components/list/listHelper';
// types
import { Contact } from 'components/contacts/types';
// styles
import 'components/contacts/ContactsTable/index.scss';

interface IContactsTable {
  contacts: Contact[];
  page: string;
}

const ContactsTable: React.FC<IContactsTable> = ({ contacts, page }: IContactsTable) => {
  const { t } = useTranslation('contactPages');

  const scrollContainerRef = useRef<SimpleBar>(null);

  const history = useHistory();

  const [contactsAmount, setContactsAmount] = useState(contacts.length);

  const handleAddContactClick = useCallback(() => {
    history.push('/add-contact');
  }, []);

  const isContactListPage = page === ContactPages.CONTACT_LIST;

  return (
    <List
      data={contacts}
      defaultSortOrder={ASC}
      defaultSortField="businessDetails.name"
      searchSources={['businessDetails.name', 'businessDetails.tag', 'email', 'contactName']}
      idSource="contactId"
    >
      <div className="contacts-table__wrapper">
        <p className="contacts-amount">
          {t('contactsAmount')} ({contactsAmount})
        </p>
        <div className="contacts-table__header">
          <SearchInput
            placeholder="Search"
            className="contacts-search__wrapper"
            inputClassName="contacts-search__input"
          />
          {isContactListPage && (
            <Button handleClick={handleAddContactClick} className="add-contact">
              {t('buttons.addContact')}
            </Button>
          )}
        </div>
        <DataGridLayout
          scrollbarRef={scrollContainerRef}
          gridTemplateColumns="1fr 1fr 1fr"
          rowKeySource="invoiceId"
          rowProps={() => ({
            className: 'contacts-table__row',
          })}
          tableMaxHeight={456}
        >
          <BusinessColumn
            source="businessDetails"
            sortSource="businessDetails.name"
            isSortable
            label={t('table.columns.business')}
          />
          <EmailColumn isSortable source="email" label={t('table.columns.email')} />
          <ContactNameColumn
            // @ts-ignore
            scrollContainerRef={scrollContainerRef}
            setContactsAmount={setContactsAmount}
            page={page}
            isSortable
            source="contactName"
            label={t('table.columns.contactName')}
          />
        </DataGridLayout>
      </div>
    </List>
  );
};

export default ContactsTable;
