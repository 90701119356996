// libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
// assets
import { ReactComponent as BellIcon } from 'assets/icons/bell-icon.svg';
// hooks
import useInAppNotifications from 'components/notifications/InApp/useInAppNotifications';
// styles
import styles from './NotificationButton.module.scss';

interface NotificationButtonProps {
  path: string;
  exact?: boolean;
}

const NotificationButton: React.FC<NotificationButtonProps> = ({ path, exact }: NotificationButtonProps) => {
  // TODO: need to get this value from BE
  const { notifications } = useInAppNotifications();
  const numberOfNotifications = notifications.length;

  return (
    <NavLink className={styles['notification-button']} to={path} exact={exact}>
      <BellIcon width="12" height="12" className={styles['notification-button__icon']} />
      {!!numberOfNotifications && (
        <div className={styles['notification-button__counter']}>
          <span className={styles['notification-button__counter-number']}>{numberOfNotifications}</span>
        </div>
      )}
    </NavLink>
  );
};

export default NotificationButton;
