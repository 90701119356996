// libraries
import React from 'react';
// components
import FormTitle, { TFormTitleProps } from '../FormTitle/FormTitle';
import StepCounter, { TStepCounterProps } from 'components/MultiStep/StepCounter/StepCounter';
// styles
import styles from './FormHeader.module.scss';

type TFormHeaderProps = TStepCounterProps & TFormTitleProps;

const FormHeader: React.FC<TFormHeaderProps> = ({ currentStep, lastStep, icon, title }: TFormHeaderProps) => (
  <div className={styles.header}>
    <FormTitle title={title} icon={icon} />
    <StepCounter currentStep={currentStep} lastStep={lastStep} />
  </div>
);

export default FormHeader;
