// libraries
import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
// assets
import { ReactComponent as AddBillIcon } from 'assets/icons/paper-add.svg';
// components
import Form from 'components/Form';
import FormFields from './FormFields/FormFields';
import { newVendorAddBillFormSchema, addBillFormSchema } from './validation-rules';
// helpers
import { FormData } from 'components/Form/FormContext';
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
import useAddBillForm from '../useAddBill';
import { SelectedCompanyInfoProps } from '../types';
// styles
import styles from './AddBillForm.module.scss';

export type AddBillFormProps = {
  handleReturn: () => void;
  currentStep: number;
  setCompanyInfo: Dispatch<SetStateAction<SelectedCompanyInfoProps>>;
};

const AddBillForm: React.FC<AddBillFormProps> = ({ handleReturn, currentStep, setCompanyInfo }: AddBillFormProps) => {
  const { t } = useTranslation('addBillPage');
  const [isNewVendorFormSchema, setIsNewVendorFormSchema] = useState(false);
  const { onSubmitAddBillManuallyForm } = useAddBillForm(setCompanyInfo);
  const { goToNextStep, formData } = useMultiStepContext();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      const invoiceId = await onSubmitAddBillManuallyForm([data]);
      if (goToNextStep) {
        goToNextStep(currentStep, { ...data, invoiceId });
      }
    },
    [goToNextStep, currentStep, onSubmitAddBillManuallyForm],
  );

  return (
    <Form
      submitForm={handleSubmit}
      className={styles['billing-form']}
      validationSchema={isNewVendorFormSchema ? newVendorAddBillFormSchema : addBillFormSchema}
      data={formData}
    >
      <div className={styles['billing-form__header']}>
        <AddBillIcon className={styles.icon} />
        <h3 className={styles.title}>{t('manuallyForm.title')}</h3>
      </div>
      <FormFields
        setIsNewVendorFormSchema={setIsNewVendorFormSchema}
        setCompanyInfo={setCompanyInfo}
        handleReturn={handleReturn}
      />
    </Form>
  );
};

export default AddBillForm;
