import BusinessInformation from 'router/views/Verification/Business/BusinessInformation';
import BusinessTax from 'router/views/Verification/Business/BusinessTax';
import Owners from 'router/views/Verification/Business/Owners';

export const commonPage = '/verification';

export enum VerificationPagesName {
  BUSINESS_DETAILS = 'Verification',
  BUSINESS_TAX = 'Tax',
  OWNERS = 'Business Owners',
}

export default [
  {
    path: commonPage,
    component: BusinessInformation,
    name: VerificationPagesName.BUSINESS_DETAILS,
    exact: true,
  },
  {
    path: `${commonPage}/tax`,
    component: BusinessTax,
    name: VerificationPagesName.BUSINESS_TAX,
    exact: true,
  },
  {
    path: `${commonPage}/beneficial-owners`,
    component: Owners,
    name: VerificationPagesName.OWNERS,
  },
];
