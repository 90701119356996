// libraries
import React from 'react';
// helpers
import useFieldHelper from 'components/list/field/fieldHelper';
// types
import { IFieldProps } from 'components/list/field';

const EmailColumn = ({ source = '', label }: IFieldProps) => {
  const { getValue } = useFieldHelper({ source, label });

  return <>{getValue()}</>;
};

export default EmailColumn;
