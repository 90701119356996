import { isObject, isArray } from 'lodash';
import { RequestManager, ResponsePromise } from './AbstractRequestManager';
import { normalizeUrl } from '../helpers/api';

export enum HttpMethod {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum ContentType {
  JSON = 'application/json',
  MULTIPART = '', // need to leave it blank to let browser set its own content type
}

type HeadersMap = Dictionary<string | number>;

export type RequestInitialParams = {
  method?: HttpMethod;
  headers?: HeadersMap;
  contentType?: ContentType | string;
  body?: Dictionary<unknown> | string | BodyInit;
};

class AbstractRequest {
  private requestManager: RequestManager;

  url: string;

  fetchParams: Partial<RequestInit>;

  constructor(
    requestManager: RequestManager,
    url: string,
    { method = HttpMethod.GET, headers = {}, body, contentType = ContentType.JSON }: RequestInitialParams = {},
  ) {
    this.requestManager = requestManager;
    this.url = normalizeUrl(url);

    this.fetchParams = {
      method,
      headers: this.mapHeaders({ 'Content-Type': contentType, ...headers }),
    };

    if (body && method !== HttpMethod.GET) {
      this.fetchParams.body =
        contentType === ContentType.JSON
          ? JSON.stringify(this.trimBody(body as Dictionary<unknown>))
          : (body as BodyInit);
    }

    // TODO add AbortController support
  }

  private trimBody = (body: Dictionary<unknown>): Dictionary<unknown> => {
    const result: Dictionary<unknown> = {};

    Object.entries(body).forEach(([key, value]) => {
      if (isObject(value) && !isArray(value)) {
        result[key] = this.trimBody(value as Dictionary<unknown>);
        return;
      }

      if (typeof body[key] !== 'string' && !(body[key] instanceof String)) {
        result[key] = value;
        return;
      }

      const stringValue = (body[key] as string).trim();

      if (stringValue) {
        result[key] = stringValue;
      }
    });

    return result;
  };

  private mapHeaders = (headersMap: HeadersMap): Headers => {
    const headers = headersMap;
    const requestHeaders = new Headers();

    Object.entries(headers).forEach(([key, value]) => {
      if (value === undefined) return;

      requestHeaders.append(key, `${value}`);
    });

    return requestHeaders;
  };

  public send(): ResponsePromise {
    return this.requestManager.sendRequest(this);
  }
}

export default AbstractRequest;
