// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import BaseLayout from 'shared/layouts/basic';
import ContactsAddEdit from 'components/contacts/ContactsAddEdit';
// hooks
import useContacts from 'components/contacts/useContacts';

const AddContact = () => {
  const { t } = useTranslation('contactPages');

  const { submitAddContact } = useContacts();

  return (
    <BaseLayout>
      <ContactsAddEdit submitForm={submitAddContact} title={t('pageTitles.addContact')} />
    </BaseLayout>
  );
};

export default AddContact;
