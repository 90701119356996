// libraries
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
// assets
import darkLogoImg from 'assets/images/logo.svg';
// styles
import styles from './LogoBig.module.scss';

export enum LogoColor {
  DARK = 'dark',
}

const unProtectedRotes = ['login', 'sign-up'];

type LogoBigProps = Partial<{
  color: LogoColor;
  subtitleText: string;
  handleLogoClick?: () => void;
  href: string;
  classNameLogo: string;
}>;

const images = {
  dark: darkLogoImg,
};

const LogoBig: React.FC<LogoBigProps> = ({
  classNameLogo = '',
  color = LogoColor.DARK,
  handleLogoClick = () => {},
  subtitleText = '',
  href = '',
}) => {
  const { url } = useRouteMatch();

  const logoView = useMemo(() => {
    const isLogoLink = unProtectedRotes.every(route => !url.includes(route));
    const logoPath = images[color];

    const logo = <img src={logoPath} alt="Hopscotch logo" width="107" height="30" className={styles.logo__img} />;

    if (isLogoLink) {
      return (
        <Link onClick={handleLogoClick} to="/">
          {logo}
        </Link>
      );
    }

    return logo;
  }, [handleLogoClick, color, url]);

  if (!href) {
    return <span className={styles.logo}>{logoView}</span>;
  }

  return (
    <a className={classnames(styles.logo, classNameLogo)} href={href}>
      {logoView}
    </a>
  );
};

export default LogoBig;
