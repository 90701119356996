// libraries
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
// helpers
import useFieldHelper from 'components/list/field/fieldHelper';
import { IFieldProps } from 'components/list/field';
import { useFieldContext } from 'components/list/field/FieldContext';
import { InvoiceTypes, IInvoiceBankDetails, InvoiceStates, FundsTransferTypes } from 'components/invoices/types';
import { getInvoiceCompanyLogo } from 'components/invoices/invoiceHelpers';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
// components
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
// styles
import './CounterpartyField.scss';

const CounterpartyField: React.FC<IFieldProps> = ({ source = '', label }: IFieldProps) => {
  const { t } = useTranslation('transactionHistory');
  const { data } = useFieldContext();
  const { getValue } = useFieldHelper<Partial<IBusinessSnapshot & IInvoiceBankDetails>>({ source, label });
  const invoiceType = data.getValueBySource('invoiceType');
  const counterpartyDetails = getValue();
  const transferType = data.getValueBySource('transferType');
  const invoiceState = data.getValueBySource('invoiceState');
  const isTransferFunds = useMemo(() => invoiceType === InvoiceTypes.fundsTransfer, [invoiceType]);
  const isPayableInvoice = useMemo(
    () => (isTransferFunds ? transferType === FundsTransferTypes.transfer : invoiceType === InvoiceTypes.payable),
    [invoiceType, transferType, isTransferFunds],
  );
  const isScheduledInvoice = useMemo(() => invoiceState === InvoiceStates.scheduled, [invoiceState]);

  return (
    <ShortEntityCard
      className={classnames('transaction-history-company-card', {
        'transaction-history-transfer-card': isTransferFunds,
      })}
      message={
        <span>
          {isTransferFunds ? (
            <>
              {isPayableInvoice ? t('to') : t('from')}&nbsp;<b>{counterpartyDetails.bankName}</b>
            </>
          ) : (
            counterpartyDetails.dbaName
          )}
        </span>
      }
      helpText={(isTransferFunds ? counterpartyDetails.cardNumber : counterpartyDetails.industry) || ''}
      logo={getInvoiceCompanyLogo(
        isPayableInvoice,
        counterpartyDetails.logoImage,
        isScheduledInvoice,
        'counterparty-field__logo',
        isPayableInvoice && isScheduledInvoice ? 'Scheduled Payment' : '',
      )}
    />
  );
};

export default CounterpartyField;
