export type AccountDataProps = {
  firstName: string;
  lastName: string;
  email: string;
};

export type BusinessInformationDataProps = {
  legalName: string;
  address: string;
  taxId: string;
  idNumber: string;
};

export enum AccountTabFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  legalName = 'legalName',
  address = 'address',
  taxId = 'taxId',
  idNumber = 'idNumber',
}
