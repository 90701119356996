import { createContext, useContext } from 'react';
import { FormData } from 'components/Form/FormContext';

export interface MultiStepContextProps {
  initialStep?: number;
  lastStep?: number;
}

export interface MultiStepContextData extends MultiStepContextProps {
  currentStep: number;
  steps: FormData[];
  goToNextStep: (stepNumber: number, data: FormData) => void;
  goToStep: (stepNumber: number) => void;
  goToPreviousStep: () => void;
  formData: FormData;
  isCurrentStep: (step: number) => boolean;
}

const MultiStepContext = createContext<Partial<MultiStepContextData>>({});

export const MultiStepProvider = MultiStepContext.Provider;

export const useMultiStepContext: () => Partial<MultiStepContextData> = () =>
  useContext<Partial<MultiStepContextData>>(MultiStepContext);

export default MultiStepContext;
