enum UserRoles {
  AccountAdmin = 'AccountAdmin',
  Admin = 'Admin',
  User = 'User',
  PlatformAdmin = 'PlatformAdmin',
  Supervisor = 'Supervisor',
  Moderator = 'Moderator',
}

export const ADMIN_ROLES = [UserRoles.PlatformAdmin, UserRoles.Supervisor, UserRoles.Moderator];

export default UserRoles;
