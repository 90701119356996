// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import BusinessTaxForm from 'components/verification/Business/BusinessTaxForm/BusinessTaxForm';
import EntryTitle from 'components/EntryTitle/EntryTitle';
// styles
import styles from '../Verification.module.scss';

const BusinessTax: React.FC = () => {
  const { t } = useTranslation('verification');

  return (
    <div className={styles['form-wrapper']}>
      <EntryTitle
        customClass={styles['verification-header']}
        title={t('businessDetails.title')}
        subtitle={t('businessDetails.subtitle')}
      />
      <BusinessTaxForm />
    </div>
  );
};

export default BusinessTax;
