// libraries
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// components
import Modal, { ModalType } from 'components/Modal/Modal';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// styles
import styles from './FirstLoginPopupNotification.module.scss';
import btnStyles from 'components/Button/Button.module.scss';

const FirstLoginPopupNotification: React.FC = () => {
  const { t } = useTranslation('notifications');
  const {
    userStore: { companyName },
    modalStore: { closeModal },
    authStore: { setIsFirstLogin },
  } = useStore();

  const handleCloseModal = useCallback(() => {
    setIsFirstLogin(false);
    closeModal(ModalIds.firstLoginPopupNotification);
  }, [closeModal, setIsFirstLogin]);

  return (
    <Modal id={ModalIds.firstLoginPopupNotification} type={ModalType.base} handleCloseModal={handleCloseModal}>
      <div className={styles.popup__wrapper}>
        <div className={styles.popup__title}>{t('firstLoginPopup.title')}</div>
        <div className={styles.popup__body}>
          {t('firstLoginPopup.text.part1')}
          <b className={styles.popup__company}>{companyName}</b>
          {t('firstLoginPopup.text.part2')}
        </div>
        <Link
          to="/verification/business"
          className={classNames(btnStyles.btn, styles.popup__button)}
          onClick={handleCloseModal}
        >
          {t('firstLoginPopup.continueButton')}
        </Link>
      </div>
    </Modal>
  );
};

export default observer(FirstLoginPopupNotification);
