// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import PASSWORD_RECOVERY_FORM_SCHEMA from './validation-rules';
// components
import ResetPasswordFormType from 'components/resetPassword/ResetPasswordFormType/ResetPasswordFormType';
// types
import { EmailFormData } from 'components/resetPassword/usePasswordResetForm';
// styles
import styles from 'components/resetPassword/EmailForm/EmailForm.module.scss';

interface IEmailFormProps {
  submitEmailForm: (data: EmailFormData) => void;
}

const EmailForm: React.FC<IEmailFormProps> = ({ submitEmailForm }: IEmailFormProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <div className={styles['email-form__container']}>
      <ResetPasswordFormType
        onSubmit={data => submitEmailForm(data as EmailFormData)}
        validationSchema={PASSWORD_RECOVERY_FORM_SCHEMA}
        labelName={t('email.fields.email.label')}
        inputPlaceholder={t('email.fields.email.placeholder')}
        inputName="email"
        buttonTitle={t('email.submitButton')}
      />
    </div>
  );
};

export default EmailForm;
