// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Route as RouteComponent } from 'react-router-dom';
// components
import { RenderedRoute, Route } from 'router/Routes';
import DocumentHead from 'components/DocumentHead/DocumentHead';

const PublicRoute = ({ ...props }: Route): Nullable<RenderedRoute> => {
  const { name = '' } = props;

  return (
    <DocumentHead title={name}>
      <RouteComponent {...props} />
    </DocumentHead>
  );
};

export default observer(PublicRoute);
