import { snakeCase, camelCase } from 'change-case';
import { isObject, lowerCase, capitalize } from 'lodash';
import { Entity, ListParams } from '../api/crud/Crud';
import config from '../../config';
import { apiPrefix } from 'shared/api/AbstractRequestManager';

export const buildQueryString = (params: ListParams): string => {
  let queryString = '';

  Object.entries(params).forEach(([param, value]) => {
    if (value || value === 0) {
      if (!queryString) {
        queryString += '?';
      } else {
        queryString += '&';
      }

      if (Array.isArray(value)) {
        value.forEach((el, index) => {
          queryString += `${encodeURIComponent(param)}=${encodeURIComponent(el)}`;

          if (value.length - 1 > index) {
            queryString += '&';
          }
        });
        return;
      }

      queryString += `${encodeURIComponent(param)}=${encodeURIComponent(value)}`;
    }
  });

  return queryString;
};

export const normalizeUrl = (url: string): string => {
  const protocolRegex = /^(https|http):\/\//;
  let result = url;

  const isUri = !protocolRegex.test(url);

  if (isUri) {
    result = `${config.apiUrl}${url}`;
  }

  return result;
};

const convertKeys = (obj: Entity, convertFn: (str: string) => string): Entity => {
  const result: Entity = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (isObject(value)) {
      result[convertFn(key)] = convertKeys(value as Entity, convertFn);
    } else {
      result[convertFn(key)] = value;
    }

    return result;
  });

  return result;
};

export const convertKeysToSnakeCase = (data: Entity): Entity => convertKeys(data, snakeCase);

export const convertKeysToCamelCase = (data: Entity): Entity => convertKeys(data, camelCase);

export const getUrlPathname = (urlString: string): string => {
  try {
    const url = new URL(urlString);

    return url.pathname;
  } catch {
    return '';
  }
};

export const getInnerUrlPathname = (url: string): string => getUrlPathname(url).replace(apiPrefix, '');

export const splitStringAndConvertToUppercase = (message: string): string => capitalize(lowerCase(message));
