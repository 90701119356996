// libraries
import React from 'react';
// styles
import styles from './Lock.module.scss';

type LockProps = {
  handleAnimationEnd: () => void;
};

const Lock: React.FC<LockProps> = ({ handleAnimationEnd }: LockProps) => (
  <div className={styles.lock__wrapper}>
    <svg
      className={styles.lock__head}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 59 73"
      onAnimationEnd={handleAnimationEnd}
    >
      <path d="M17.7218 16.9322V24.5811H14.1017V16.9322C13.9413 12.8369 15.4415 8.85019 18.2627 5.87468C22.441 1.29154 28.965 -0.337742 34.8105 1.74207C40.6561 3.82189 44.6804 9.20422 45.0182 15.3942H41.3565C41.0328 12.3731 39.5395 9.59816 37.1955 7.66218C32.3918 3.57461 25.2167 3.99626 20.9258 8.61829C18.8042 10.8604 17.653 13.8477 17.7218 16.9322Z" />
    </svg>
    <svg className={styles.lock__body} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 73">
      <path d="M49.2609 24.5826H17.7202H14.1001H9.81419C7.40242 24.6302 5.10402 25.6143 3.40619 27.3262C1.69901 29.0503 0.754785 31.3861 0.784732 33.811V62.9099C0.755122 64.1214 0.981997 65.3255 1.4505 66.4433C1.88278 67.5559 2.54979 68.5626 3.40619 69.3947C5.06869 71.1578 7.38969 72.1516 9.81419 72.1385H49.2609C54.2677 72.0249 58.2539 67.9129 58.2075 62.9099V33.811C58.213 31.4274 57.2699 29.1393 55.5856 27.4509C53.9312 25.7092 51.6624 24.6802 49.2609 24.5826ZM36.7777 57.8384C36.2674 58.577 35.581 59.177 34.7804 59.5843C33.9951 60.0315 33.1045 60.2611 32.2006 60.2494H26.7912C24.9298 60.2576 23.1889 59.3304 22.1583 57.7817C21.1276 56.2331 20.9453 54.2706 21.6732 52.559L23.3792 48.402C22.7659 47.3261 22.45 46.1066 22.4638 44.8686C22.4638 40.9427 25.6494 37.7602 29.5791 37.7602C33.5089 37.7602 36.6945 40.9427 36.6945 44.8686C36.6613 46.1137 36.3484 47.3355 35.7791 48.4436L37.4851 52.6006C37.8357 53.4699 37.9644 54.4128 37.8596 55.3442C37.7754 56.2857 37.4454 57.1887 36.9026 57.9631L36.7777 57.8384Z" />
      <path d="M33.7242 55.1702C33.5552 55.3757 33.3401 55.5459 33.0933 55.6694C32.8479 55.7916 32.5737 55.8575 32.2942 55.8614H26.7849C26.5047 55.8624 26.2291 55.7962 25.9858 55.6694C25.739 55.5459 25.5239 55.3757 25.355 55.1702C25.1954 54.9375 25.0948 54.6751 25.0606 54.4023C24.9798 54.1386 24.9798 53.8597 25.0606 53.596L27.626 47.8364L26.911 47.0301C26.4204 46.4758 26.1532 45.7845 26.154 45.0718C26.156 44.6641 26.2416 44.2604 26.4064 43.8815C26.5737 43.5106 26.8163 43.1719 27.1213 42.8832C27.4263 42.61 27.7824 42.3889 28.1727 42.2304C28.5866 42.0763 29.0295 41.998 29.4765 42C29.9097 41.999 30.3387 42.0774 30.7382 42.2304C31.1413 42.3885 31.5113 42.6094 31.8316 42.8832C32.1224 43.1821 32.3633 43.5185 32.5466 43.8815C32.698 44.2627 32.7693 44.6665 32.7569 45.0718C32.7776 45.7692 32.5239 46.4503 32.0419 46.9917L31.2428 47.798L33.8503 53.596C33.9311 53.8597 33.9311 54.1386 33.8503 54.4023C33.8685 54.6635 33.8255 54.9253 33.7242 55.1702Z" />
    </svg>
  </div>
);

export default Lock;
