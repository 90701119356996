// libraries
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// components
import { ReactComponent as SuccessIcon } from 'assets/icons/verification-success.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/verification-pending.svg';
// helpers
import { addSuccessToast, addWarningToast } from 'components/Toast/toastHelper';
import { useStore } from 'shared/stores/RootStore/useStore';
import VerificationStatuses from 'shared/constants/verificationStatus';
import { ModalIds } from 'shared/constants/modalIds';
// styles
import styles from './VerificationStatus.module.scss';

const timerDelay = 3000;

const getData = (t: (key: string) => string) => ({
  [VerificationStatuses.PENDING]: {
    icon: PendingIcon,
    title: t('status.pending'),
    showToast: () => addWarningToast(t('toastPending'), t('toastPendingTitle')),
  },
  [VerificationStatuses.VERIFIED]: {
    icon: SuccessIcon,
    title: t('status.success'),
    showToast: () => addSuccessToast(t('toastSuccess'), t('toastSuccessTitle')),
  },
});

type VerificationStatusProps = {
  prevPath: string;
};

const VerificationStatus: React.FC<VerificationStatusProps> = ({ prevPath }: VerificationStatusProps) => {
  const { t } = useTranslation('verification');
  const history = useHistory();
  const data: Dictionary<any> = getData(t);
  const {
    myBusinessSnapshotStore: { updateVerificationStatus },
    modalStore: { closeModal, modalData, isOpen },
  } = useStore();
  const verificationStatus = modalData?.cbConfirmActionFunction && modalData?.cbConfirmActionFunction();
  const isModalOpened = isOpen(ModalIds.verificationStatus);

  useEffect(() => {
    if (!verificationStatus || !isModalOpened) return;

    setTimeout(() => {
      closeModal(ModalIds.verificationStatus);
      updateVerificationStatus(verificationStatus);

      history.push(prevPath);
      data[verificationStatus].showToast();
    }, timerDelay);
  }, [history, closeModal, data, verificationStatus, isModalOpened, updateVerificationStatus]);

  if (!verificationStatus || !isModalOpened) {
    return null;
  }

  const IconComponent = data[verificationStatus].icon;
  const { title } = data[verificationStatus] || {};

  return (
    <div className={styles['content-block']}>
      <IconComponent />
      <div className={styles.title}>{title}</div>
      <div className={styles.farewell}>{t('farewell')}</div>
    </div>
  );
};

export default observer(VerificationStatus);
