import ExternalPayment from 'router/views/ExternalPayment';
import ExternalReceiving from 'router/views/ExternalReceiving/ExternalReceiving';

export default [
  {
    path: `/execute-payment/:token`,
    exact: false,
    isProtected: false,
    component: ExternalPayment,
    name: 'Account Payable',
  },
  {
    path: `/receive-payment/:token`,
    exact: false,
    isProtected: false,
    component: ExternalReceiving,
    name: 'Account Receivable',
  },
];
