// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import Select, { components, OptionTypeBase, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { isEqual } from 'lodash';
// api
import { AccountType } from 'components/paymentMethods/api/PaymentMethodApi';
// components
import { InputCommonProps } from '../Input';
import TextInput, { TextInputProps } from '../TextInput/TextInput';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import { getOptions, BankAccountsOptionType } from 'components/Form/inputs/BankAccountsInput/bankAccountsHelpers';
import useConnectedAccounts from 'components/paymentMethods/useConnectedAccounts';
import { useFormContext } from 'components/Form/FormContext';
import { PaymentMethodType } from 'components/transactions/types';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import '../Select/Select.scss';
import styles from './BankAccountsInput.module.scss';
import { customStyles, SelectSize } from '../Select/customStyles';

export const selectSizes: SelectSize = {
  sm: '125px',
  m: '205px',
};

export interface BankAccountsInputProps extends TextInputProps {
  label?: string;
  newAccountModalId?: ModalIds;
  onSelectCallback?: (option: Nullable<BankAccountsOptionType>) => void;
  hideCreditCards?: boolean;
  listSize?: string;
}

const BankAccountsInput: React.FC<BankAccountsInputProps> = ({
  value: initialValue,
  name,
  newAccountModalId = ModalIds.paymentMethod,
  className,
  onSelectCallback,
  hideCreditCards = false,
  listSize = selectSizes.m,
  ...props
}: BankAccountsInputProps) => {
  const { accounts = [], setData, isLoading } = useConnectedAccounts({ preloadData: false });
  const { data = {}, setFieldErrors, setFieldValues } = useFormContext();
  const {
    modalStore: { isOpen, modalIds, openModal },
  } = useStore();
  const { t } = useTranslation(['connectedAccountsPage', 'forms']);
  const [initialAccounts, setInitialAccounts] = useState<Account[]>(accounts);
  const [selectedOption, setSelectedOption] = useState<BankAccountsOptionType | boolean>(false);
  const [lastSelectedOption, setLastSelectedOption] = useState<BankAccountsOptionType | boolean>(false);
  const [isAddAccountButtonClicked, setIsAddAccountButtonClicked] = useState<boolean>(false);
  const isNewAccountAdded = useMemo(() => {
    if (!isEqual(accounts, initialAccounts) && !isOpen(newAccountModalId) && isAddAccountButtonClicked) {
      return true;
    }

    if (!selectedOption) {
      setSelectedOption(lastSelectedOption);
    }

    return false;
  }, [
    accounts,
    initialAccounts,
    isOpen,
    newAccountModalId,
    isAddAccountButtonClicked,
    lastSelectedOption,
    selectedOption,
  ]);

  useEffect(() => {
    if (!isAddAccountButtonClicked) {
      setInitialAccounts(accounts);
    }
  }, [accounts, isAddAccountButtonClicked]);

  useEffect(() => {
    if (onSelectCallback) {
      onSelectCallback(typeof selectedOption === 'boolean' ? null : selectedOption);
    }
  }, [selectedOption, onSelectCallback]);

  const resetError = useCallback(() => {
    if (setFieldErrors) {
      setFieldErrors(name, []);
    }
  }, [name, setFieldErrors]);

  useEffect(() => {
    if (data && data[name]) {
      resetError();
    }
  }, [data, name, resetError]);

  useEffect(() => {
    if (modalIds && !isOpen(newAccountModalId)) {
      setData();
    }
  }, [isOpen, setData, modalIds, newAccountModalId]);

  useEffect(() => {
    if (!isNewAccountAdded || !setFieldValues) {
      return;
    }

    const newOptions = getOptions(accounts, hideCreditCards);
    const lastAddedAccount = newOptions[newOptions.length - 1];

    if (!lastAddedAccount.fee && lastAddedAccount.type === PaymentMethodType.CC) {
      return;
    }

    setSelectedOption(lastAddedAccount);
    setFieldValues(name, (lastAddedAccount.value || '').toString());
    setIsAddAccountButtonClicked(false);
  }, [accounts, setFieldValues, name, hideCreditCards, isNewAccountAdded]);

  const options: BankAccountsOptionType[] = getOptions(accounts, hideCreditCards);

  options.push({ value: null, label: t('paymentMethods.addInsideSelect') });

  const handleNewAccountButtonClick = useCallback(() => {
    setLastSelectedOption(selectedOption);
    setSelectedOption(false);
    openModal(newAccountModalId);
    setIsAddAccountButtonClicked(true);
  }, [openModal, newAccountModalId, selectedOption]);

  const getAccountData = useCallback((brand, lastNumbers, type) => {
    if (type === AccountType.CREDIT_CARD) {
      return (
        <>
          <p className={styles['accounts-item__name']}>**** **** **** {lastNumbers}</p>
          <p className={classnames(styles['accounts-item__number'], styles['accounts-item__number--card'])}>{brand}</p>
        </>
      );
    }

    return (
      <>
        <p className={styles['accounts-item__name']}>{brand}</p>
        <p className={styles['accounts-item__number']}>****{lastNumbers}</p>
      </>
    );
  }, []);

  const formatOption = ({ value, label: optionLabel, lastNumbers, type }: BankAccountsOptionType) => {
    if (value !== null) {
      return <div className={styles.option__wrapper}>{getAccountData(optionLabel, lastNumbers, type)}</div>;
    }

    return (
      <button type="button" className={styles.list__button} onClick={handleNewAccountButtonClick}>
        {t('paymentMethods.addInsideSelect')}
      </button>
    );
  };

  const input = ({ data: selectData, ...valueProps }: SingleValueProps<BankAccountsOptionType>) => {
    return (
      <components.SingleValue {...valueProps} data={selectData}>
        {selectData.label}
      </components.SingleValue>
    );
  };

  const InputElement = ({ onBlur, onFocus, disabled, ...inputCommonProps }: InputCommonProps) => {
    const handleChange = (option: ValueType<OptionTypeBase, false>) => {
      resetError();

      if (option && !option.value) {
        setSelectedOption(false);
        return;
      }

      setSelectedOption(option as BankAccountsOptionType);

      if (setFieldValues) {
        setFieldValues(inputCommonProps.name, option?.value as string);
      }
    };

    return (
      <Select
        {...inputCommonProps}
        placeholder={t('forms:selectPlaceholder')}
        name={name}
        id={name}
        value={selectedOption as ValueType<BankAccountsOptionType, false>}
        formatOptionLabel={formatOption}
        options={options}
        components={{ SingleValue: input }}
        onChange={handleChange}
        classNamePrefix="select"
        className={classnames('select', 'bank-accounts', className, inputCommonProps.className)}
        styles={customStyles({ listSize })}
        isDisabled={disabled || isLoading}
        isSearchable={false}
        menuPortalTarget={document.body}
      />
    );
  };

  return <TextInput {...props} name={name} inputElement={InputElement} className={styles.select} />;
};

export default observer(BankAccountsInput);
