// libraries
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
// context
import { FormData } from 'components/Form/FormContext';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import { addErrorToast } from 'components/Toast/toastHelper';
import { prepareSubmittedAddress } from 'components/Form/inputGroups/AddressField/addressFieldHelpers';
import { VERIFICATION_FLOW, VerificationStep } from 'components/verification/verificationHelpers';
import stall, { getMockData } from 'shared/helpers/simulateAsyncCall';
import VerificationStatuses from 'shared/constants/verificationStatus';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

type VerificationDataProps = {
  businessAddress: string;
  redirectUrl: string;
  verificationStatus: 'pending' | 'success';
};

type VerificationParams = {
  stepData: Nullable<Dictionary<string>>;
  isLoading: boolean;
  submitAddressForm: (data: FormData) => void;
  submitBusinessInformation: (data: FormData) => void;
  submitTaxInformation: (data: FormData) => void;
  verifyCompany: () => void;
};

// TODO: need remove after integration
const mockVerificationStatus = Math.round(Math.random()) ? 'pending' : 'success';

const useVerification = (): VerificationParams => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [stepData, setStepData] = useState<Nullable<Dictionary<string>>>(null);

  const getVerificationData = useCallback(async () => {
    setIsLoading(true);

    try {
      // TODO this request should return initial data for current step
      const response = await getMockData({
        businessAddress: 'DSW Drive, 4321',
        redirectUrl: '/',
        verificationStatus: mockVerificationStatus,
      });
      setStepData(response as VerificationDataProps);
    } catch (e) {
      const { message } = await e;

      addErrorToast(message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getVerificationData();
  }, [getVerificationData]);

  const {
    modalStore: { openModal },
    myBusinessSnapshotStore: { updateNextVerificationStep },
  } = useStore();

  const submitBusinessInformation = async (data: FormData) => {
    try {
      const address = prepareSubmittedAddress(data);
      const requestBody = { ...data, bizAddress: address };
      await stall(requestBody);
      updateNextVerificationStep(VerificationStep.TAX);
      history.push(VERIFICATION_FLOW.tax);
    } catch (e) {
      const { message } = await e;

      addErrorToast(message);
    }
  };

  const submitTaxInformation = async (data: FormData) => {
    try {
      await stall(data);
      history.push(VERIFICATION_FLOW.beneficialOwners);
      updateNextVerificationStep(VerificationStep.BENEFICIAL_OWNERS);
    } catch (e) {
      const { message } = await e;

      addErrorToast(message);
    }
  };

  const submitAddressForm = useCallback(async (data: FormData) => {
    try {
      const address = prepareSubmittedAddress(data);
      await stall(address);
    } catch (e) {
      const { message } = await e;

      addErrorToast(message);
    }
  }, []);

  const verifyCompany = useCallback(async () => {
    setIsLoading(true);
    // TODO: need update after integration
    const { verificationStatus } = await getMockData({ verificationStatus: mockVerificationStatus });
    openModal(ModalIds.verificationStatus, {
      cbConfirmActionFunction: () =>
        verificationStatus === 'success' ? VerificationStatuses.VERIFIED : VerificationStatuses.PENDING,
    });
    setIsLoading(false);
  }, [openModal]);

  return {
    stepData,
    isLoading,
    submitAddressForm,
    submitTaxInformation,
    submitBusinessInformation,
    verifyCompany,
  };
};
export default useVerification;
