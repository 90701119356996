// libraries
import React from 'react';
import classNames from 'classnames';
// styles
import styles from './FormFieldLabel.module.scss';

type TFormFieldLabelProps = {
  children: React.ReactNode;
  helpText?: string;
  className?: string;
};

const FormFieldLabel: React.FC<TFormFieldLabelProps> = ({
  children,
  helpText,
  className = '',
}: TFormFieldLabelProps) => {
  return (
    <div className={classNames(styles.label, className)}>
      <div className={styles.label__text}>{children}</div>
      {helpText && <div className={styles.label__description}>{helpText}</div>}
    </div>
  );
};

export default FormFieldLabel;
