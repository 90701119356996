// libraries
import React from 'react';
// components
import AccountTabForm from 'components/settings/AccountTabForm/AccountTabForm';
import SettingFormWrapper from 'components/settings/SettingFormWrapper/SettingFormWrapper';

const Account: React.FC = () => {
  return (
    <SettingFormWrapper>
      <AccountTabForm />
    </SettingFormWrapper>
  );
};

export default Account;
