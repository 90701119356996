// libraries
import React from 'react';
import i18next from 'i18next';
// styles
import styles from './StepCounter.module.scss';

export type TStepCounterProps = {
  currentStep: number;
  lastStep: number;
};

const StepCounter: React.FC<TStepCounterProps> = ({ currentStep, lastStep }: TStepCounterProps) => {
  return (
    <div className={styles.counter}>
      {i18next.t('forms:stepCounter', { currentStep: currentStep + 1, lastStep: lastStep + 1 })}
    </div>
  );
};

export default StepCounter;
