// libraries
import React, { useMemo } from 'react';
// components
import EditPayableInvoice from './EditPayableInvoice';
import UnschedulePayableInvoice from './UnschedulePayableInvoice';
import DownloadInvoice from 'components/transactions/TransactionDetails/shared/DownloadInvoice';
import RejectPayableInvoice from './RejectPayableInvoice';
import DeletePayableInvoice from './DeletePayableInvoice';
import TransactionCardFooterActions from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooterActions/TransactionCardFooterActions';
import DropdownButtonDivider from 'components/Button/DropdownButton/DropdownButtonDivider';
// helpers
import { Invoice, InvoiceStates } from 'components/invoices/types';

interface IPayableInvoiceFooterActions {
  invoiceDetails: Invoice;
}

const PayableInvoiceFooterActions: React.FC<IPayableInvoiceFooterActions> = ({
  invoiceDetails,
}: IPayableInvoiceFooterActions) => {
  const { invoiceState, isManuallyAdded } = invoiceDetails;
  const isScheduled = useMemo(() => invoiceState === InvoiceStates.scheduled, [invoiceState]);

  return (
    <TransactionCardFooterActions>
      {isManuallyAdded && !isScheduled && <EditPayableInvoice invoiceDetails={invoiceDetails} />}
      {isScheduled && <UnschedulePayableInvoice invoiceDetails={invoiceDetails} />}
      <DownloadInvoice invoiceId={invoiceDetails.invoiceId} />
      <DropdownButtonDivider />
      {isManuallyAdded ? (
        <DeletePayableInvoice invoiceId={invoiceDetails.invoiceId} />
      ) : (
        <RejectPayableInvoice invoiceId={invoiceDetails.invoiceId} />
      )}
    </TransactionCardFooterActions>
  );
};

export default PayableInvoiceFooterActions;
