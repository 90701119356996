// libraries
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
// helpers
import RequestError, { CustomErrors } from 'shared/helpers/exceptions';
import { addErrorToast } from 'components/Toast/toastHelper';
import stall, { getMockData } from 'shared/helpers/simulateAsyncCall';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { VERIFICATION_FLOW } from 'components/verification/verificationHelpers';
import { ReactComponent as FilledTrashIcon } from 'assets/icons/filled-trash-icon.svg';
import { ButtonColor } from 'components/Button';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

export type BeneficialOwnerFormData = {
  firstName: string;
  lastName: string;
  ssn: string;
  birthday: string;
  address: string;
};

export type BeneficialOwner = {
  id: number;
  firstName: string;
  lastName: string;
  ssn: string;
  birthday: string;
  address: string;
};

type BeneficialOwnersParams = {
  submitForm: (data: BeneficialOwnerFormData) => void;
  openConfirmationModal: (id: number, fullName: string) => void;
  openBeneficialOwnerModal: (id?: number) => void;
  getBeneficialOwners: () => void;
  isLoading: boolean;
};

export const mockData = {
  total_items: 1,
  rows: [
    {
      id: 7,
      firstName: 'John',
      lastName: 'Snow',
      ssn: '121314159',
      birthday: '1980-11-05',
      address: 'homeless',
    },
  ],
};

const redirections: Record<string, string> = {
  '/verification/beneficial-owners/form': VERIFICATION_FLOW.beneficialOwners,
  '/verification/beneficial-owners/:id/form': VERIFICATION_FLOW.beneficialOwners,
};

const useBeneficialOwners = (owner?: Nullable<BeneficialOwner>): BeneficialOwnersParams => {
  const { t } = useTranslation('beneficiaryOwners');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { path = '' } = useRouteMatch();

  const {
    beneficialOwnersStore: {
      setBeneficialOwners,
      setCurrentBeneficialOwner,
      beneficialOwnersList,
      currentBeneficialOwner,
      clearCurrentOwner,
    },
    modalStore: { openModal, closeAllModals },
  } = useStore();

  const getBeneficialOwners = useCallback(async () => {
    setIsLoading(true);
    try {
      // TODO change to a correct API route
      const ownersData = await getMockData(mockData);
      const { total_items: totalItems = 0, rows = [] } = ownersData as List<BeneficialOwner>;

      setBeneficialOwners(totalItems, rows);
    } catch (e) {
      const { message = '' } = await e;
      addErrorToast(message);
    } finally {
      setIsLoading(false);
    }
  }, [setBeneficialOwners]);

  const handleCompleteAction = useCallback(async () => {
    try {
      await getBeneficialOwners();

      const needRedirection = !!redirections[path];

      if (needRedirection) {
        history.push(redirections[path]);
      }
    } catch (e) {
      const { message } = await e;
      addErrorToast(message);
    }
  }, [getBeneficialOwners, history, path]);

  const createBeneficialOwner = useCallback(
    async (data: BeneficialOwnerFormData) => {
      try {
        await stall(data);
        await handleCompleteAction();
      } catch (e) {
        const { status, message = '', errors = {} } = await e;
        throw new RequestError(status, { errors: errors as CustomErrors, message });
      } finally {
        closeAllModals();
        clearCurrentOwner();
      }
    },
    [handleCompleteAction, closeAllModals, clearCurrentOwner],
  );

  const updateBeneficialOwner = useCallback(
    async (id: number, data: BeneficialOwnerFormData) => {
      try {
        await stall({ id, data });
        await handleCompleteAction();
      } catch (e) {
        const { status, message = '', errors = {} } = await e;
        throw new RequestError(status, { errors: errors as CustomErrors, message });
      } finally {
        closeAllModals();
        clearCurrentOwner();
      }
    },
    [handleCompleteAction, closeAllModals, clearCurrentOwner],
  );

  const deleteBeneficialOwner = useCallback(
    async (id: number) => {
      try {
        // TODO change to delele owner route
        await stall(id);
        await handleCompleteAction();
      } catch (e) {
        const { message = '' } = await e;
        addErrorToast(message);
      }
    },
    [handleCompleteAction],
  );

  const submitForm = async (data: BeneficialOwnerFormData) => {
    // Data is sended as string, if you need parsed data - extract required fields from data object
    const { address, ssn, birthday, firstName, lastName } = data;
    const requestBody = {
      address,
      ssn,
      birthday,
      firstName,
      lastName,
    };
    const { id = '' } = owner || {};

    if (Boolean(id) || !currentBeneficialOwner?.id) {
      await createBeneficialOwner(requestBody);
      return;
    }

    await updateBeneficialOwner(id || currentBeneficialOwner?.id, requestBody);
  };

  const openConfirmationModal = (id: number, fullName: string) => {
    openModal(ModalIds.confirmationModal, {
      icon: <FilledTrashIcon width={36} height={36} />,
      title: `${t('messages.confirmDeletion')} ${fullName}?`,
      cbConfirmActionFunction: () => deleteBeneficialOwner(id),
      cancelButtonText: t('deleteCancelBtn'),
      confirmButtonText: t('deleteConfirmBtn'),
      confirmButtonProps: { color: ButtonColor.danger },
    });
  };

  const openBeneficialOwnerModal = (id?: number) => {
    openModal(ModalIds.beneficialOwner);
    const currentOwner = beneficialOwnersList?.find(beneficialOwner => Number(beneficialOwner?.id) === Number(id));

    if (currentOwner) {
      setCurrentBeneficialOwner(currentOwner);
    }
  };

  return { submitForm, getBeneficialOwners, openConfirmationModal, isLoading, openBeneficialOwnerModal };
};

export default useBeneficialOwners;
