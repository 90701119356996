// libraries
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import { Checkbox } from 'components/Form/inputs';
// styles
import styles from 'components/settings/AccountTabForm/AccountTabForm.module.scss';
import checkboxStyles from 'components/settings/AccountTabForm/AuthentificationBlock/CheckboxField/CheckboxField.module.scss';

type CheckBoxFieldProps = {
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  isChecked: boolean;
};

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({ isChecked, setIsChecked }: CheckBoxFieldProps) => {
  const { t } = useTranslation('settingsPage');

  const handleCheckboxClick = useCallback(() => {
    setIsChecked(prevValue => !prevValue);
  }, [setIsChecked]);

  return (
    <div className={classNames(styles.field__wrapper, checkboxStyles['tfa-checkbox-field__wrapper'])}>
      <div className={checkboxStyles['tfa-checkbox__wrapper']}>
        <label htmlFor="tfa" className={checkboxStyles['custom-label']}>
          {t('authentificationBlock.fields.tfa.label')}
        </label>
        <Checkbox
          name="tfa"
          className={checkboxStyles['tfa-checkbox__track']}
          fieldLabelClassName={checkboxStyles['tfa-checkbox__label']}
          checkboxInputClassName={checkboxStyles['tfa-checkbox__input']}
          checked={isChecked}
          additionalOnChangeCallBack={handleCheckboxClick}
        />
      </div>
      <span className={checkboxStyles['custom-label__tip']}>{t('authentificationBlock.fields.tfa.text')}</span>
    </div>
  );
};

export default CheckBoxField;
