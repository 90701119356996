// libraries
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// helpers
import stall, { getMockData } from 'shared/helpers/simulateAsyncCall';
import { SignUpFormStep } from 'components/signUpForms/useSignUpForm';
import { PaymentTabs } from 'components/externalPayments/Tabs/Tabs';
import { getNewInvoice } from 'components/invoices/InvoiceHistory/mockData';
import { Invoice, InvoiceStates } from 'components/invoices/types';

export type EmailFormData = {
  email: string;
};

export type VerificationFormData = {
  verificationCode: string;
};

export type EmailChangeFormData = {
  email: string;
};

export enum ExternalPaymentSteps {
  EMAIL,
  VERIFICATION,
  EMAIL_CHANGE,
  PAYMENT_FORM,
}

interface ExternalPaymentFormsData {
  currentStep: ExternalPaymentSteps;
  handleEmailChange: () => void;
  email: string;
  submitEmailForm: (data: EmailFormData) => void;
  submitVerificationForm: (data: VerificationFormData) => void;
  submitEmailChangeForm: (data: EmailChangeFormData) => void;
  isLoading: boolean;
  invoiceDetails: Nullable<Invoice>;
  setInvoiceDetails: (data: Invoice) => void;
}

const useExternalPaymentForms = (): ExternalPaymentFormsData => {
  const { token } = useParams<Dictionary<string>>();
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(ExternalPaymentSteps.EMAIL);
  const [email, setEmail] = useState<string>('');
  const [invoiceDetails, setInvoiceDetails] = useState<Nullable<Invoice>>(null);
  const { search } = useLocation();
  const history = useHistory();

  const checkToken = useCallback(async () => {
    setIsLoading(true);

    try {
      // TODO: Replace after integration with BE - Get invoice details by token
      const invoice: Invoice = await getMockData(
        // @ts-ignore
        getNewInvoice({
          dueDate: '12/25/2021',
          amount: Math.floor(Math.random() * 10000),
          description: 'Description',
          token,
        }),
      );

      setInvoiceDetails(invoice);

      if (invoice.invoiceState !== InvoiceStates.pending) {
        setCurrentStep(ExternalPaymentSteps.PAYMENT_FORM);
      }
    } catch (e) {
      history.push('/not-found');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  const submitEmailForm = useCallback(
    async (data: EmailFormData) => {
      await stall(data);

      if (search === `?signUpType=${PaymentTabs.GUEST}`) {
        setCurrentStep(ExternalPaymentSteps.VERIFICATION);
      } else {
        history.push('/sign-up', {
          data: {
            email: data.email,
          },
          currentStep: SignUpFormStep.EMAIL,
        });
      }

      setEmail(data.email);
    },
    [search, history],
  );

  const submitVerificationForm = async (data: VerificationFormData) => {
    await stall(data);
    setCurrentStep(ExternalPaymentSteps.PAYMENT_FORM);
  };

  const submitEmailChangeForm = async (data: EmailChangeFormData) => {
    await stall(data);
    setCurrentStep(ExternalPaymentSteps.VERIFICATION);
    setEmail(data.email);
  };

  const handleEmailChange = useCallback(() => {
    setCurrentStep(ExternalPaymentSteps.EMAIL_CHANGE);
  }, []);

  return {
    currentStep,
    handleEmailChange,
    email,
    submitEmailForm,
    submitVerificationForm,
    submitEmailChangeForm,
    isLoading,
    invoiceDetails,
    setInvoiceDetails,
  };
};

export default useExternalPaymentForms;
