// libraries
import React, { memo } from 'react';
import { Container } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite';
// components
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Notifications from 'components/notifications/Notifications';
import GlobalModals from 'components/Modal/GlobalModals/GlobalModals';
import Toast from 'components/Toast';
// constants
import STRIPE_OPTIONS from 'shared/constants/stripe';
import config from 'config';
// styles
import styles from './styles.module.scss';

const BaseLayout: ComponentWrapper = ({ children = null }) => {
  const stripePromise = loadStripe(`${config.apiKeys.stripe}`);

  return (
    <Elements stripe={stripePromise} options={STRIPE_OPTIONS}>
      <Notifications />
      <div className={styles['layout--basic']}>
        <Header />
        <Container className="layout__container p-0 toasts-wrapper">
          <div className="layout__container-wrapper">
            <Toast />
          </div>
        </Container>
        <main className={styles.layout__main}>
          <Container className="layout__container p-0">
            <div className="layout__container-wrapper">{children}</div>
          </Container>
        </main>
        <Footer />
      </div>
      <GlobalModals />
    </Elements>
  );
};

export default memo(observer(BaseLayout));
