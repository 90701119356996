import { CropArea } from 'components/ImageCropper/useImageCropper';

const canvas = document.createElement('canvas');

const DEFAULT_CROP_SIZE = 300;

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Nullable<CropArea>,
  extension: string,
): Promise<any> => {
  const image = await createImage(imageSrc);

  if (!pixelCrop) return null;

  const { x, y, width, height } = pixelCrop;

  canvas.width = DEFAULT_CROP_SIZE;
  canvas.height = DEFAULT_CROP_SIZE;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  ctx.drawImage(image, x, y, width, height, 0, 0, canvas.width, canvas.height);

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve({
        url: canvas.toDataURL(extension),
        file: file as File,
      });
    }, extension);
  });
};

export const getImageForDatabase = (file: File, imageName: string, imageType: string): File => {
  return new File([file], imageName, {
    type: imageType,
  });
};

export const getFilePath = (file: File): Promise<Nullable<string | ArrayBuffer>> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};
