// libraries
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isToday, parseISO } from 'date-fns';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
// constants
import { PayableInvoiceSteps } from 'components/transactions/TransactionDetails/PayableInvoiceDetails/constants';
// components
import TransactionCardFooter from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooter';
import DatePickerInput from 'components/Form/inputs/DatePicker/DatePickerInput';
import TransactionCardField from 'components/transactions/TransactionCard/TransactionCardField';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import RejectPayableInvoice from 'components/transactions/TransactionDetails/PayableInvoiceDetails/PayableInvoiceFooterActions/PayableInvoiceFooterActions';
// helpers
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
import { Invoice } from 'components/invoices/types';
import { formatDate, DateFormats } from 'shared/helpers/format';
import { useFormContext } from 'components/Form/FormContext';
// assets
import { ReactComponent as EditPenIcon } from 'assets/icons/edit-pen.svg';
// styles
import styles from './PayableInvoiceForm.module.scss';
import stylesStatic from './PayableInvoiceStaticInfo.module.scss';

interface IPayableInvoiceFormContent {
  invoiceDetails: Invoice;
  forceSubmit?: boolean;
  setForceSubmit: (value: boolean) => void;
  hasConnectedAccounts?: boolean;
  isDisabled?: boolean;
  isVerifiedCompany?: boolean;
}
const PayableInvoiceFormContent: React.FC<IPayableInvoiceFormContent> = ({
  invoiceDetails,
  forceSubmit,
  setForceSubmit,
  hasConnectedAccounts,
  isDisabled,
  isVerifiedCompany,
}: IPayableInvoiceFormContent) => {
  const { t } = useTranslation('transactionHistory');
  const { resetForm = () => {}, data } = useFormContext();
  const { currentStep, goToPreviousStep } = useMultiStepContext();

  const isConfirmStep = useMemo(() => currentStep === PayableInvoiceSteps.CONFIRM_STEP, [currentStep]);

  useEffect(() => {
    resetForm();
    if (goToPreviousStep && isConfirmStep) {
      goToPreviousStep();
    }
    // Suppressed warnings because we should reset data only if invoice was updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetails]);

  const formatDatepickerDate = useCallback(
    (date: Date) => {
      setForceSubmit(false);

      return !date || isToday(date) ? t('nowLabel') : formatDate(date, DateFormats.invoiceDatepicker);
    },
    [setForceSubmit, t],
  );

  const unscheduleInvoice = useCallback(async () => {
    if (goToPreviousStep) {
      goToPreviousStep();
    }
  }, []);

  const formattedPayDate = useMemo(() => {
    let payDate = data?.payDate;

    if (forceSubmit) {
      payDate = Date.now();
    }

    if (isToday(parseISO(payDate)) || isToday(payDate)) {
      return `Now (${formatDate(payDate, DateFormats.full)})`;
    }

    return formatDate(payDate, DateFormats.full);
  }, [data, forceSubmit]);

  return (
    <>
      {(hasConnectedAccounts || isVerifiedCompany) &&
        (isConfirmStep ? (
          <TransactionCardField
            label={t('invoiceActions.paymentDate')}
            value={
              <div className={stylesStatic['schedule-field__wrapper']}>
                <span>{formattedPayDate}</span>
                <button className={stylesStatic['edit-button']} onClick={unscheduleInvoice}>
                  <EditPenIcon width={10} height={10} />
                </button>
              </div>
            }
          />
        ) : (
          <TransactionCardField
            label={t('payableInvoice.dateLabel')}
            value={
              <DatePickerInput
                className={classnames({ 'datepicker--active': forceSubmit })}
                name="payDate"
                minDate={new Date()}
                popperPlacement="left"
                placeholder={t('nowLabel')}
                customFormat={formatDatepickerDate}
              />
            }
            className={styles['datepicker-field']}
          />
        ))}
      <TransactionCardFooter
        helpText={
          hasConnectedAccounts || isVerifiedCompany ? (
            <>
              {t('payableInvoice.helpText.part1')}
              {/* TODO: Change the span element to link when the url is specified */}
              <span className={styles.link__button}>{t('payableInvoice.helpText.link')}</span>
              {t('payableInvoice.helpText.part2')}
            </>
          ) : null
        }
      >
        <SubmitButton className={styles['invoice-pay-btn']} disabled={isDisabled}>
          {t('payInvoiceBtn')}
        </SubmitButton>
        {!isConfirmStep && <RejectPayableInvoice invoiceDetails={invoiceDetails} />}
        {forceSubmit && !isConfirmStep && (
          <span className={styles['payment-prompt']}>{t('payableInvoice.payInvoiceConfirmation')}</span>
        )}
      </TransactionCardFooter>
    </>
  );
};

export default observer(PayableInvoiceFormContent);
