import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { arrayOf, func, node, oneOfType, string } from 'prop-types';

type PageHeadProps = PropsWithChildren<{
  title?: string;
}>;

const TITLE_POSTFIX = '- Hopscotch';

const DocumentHead: React.FC<PageHeadProps> = ({ children = null, title = '' }) => {
  return (
    <>
      <Helmet>
        <title>{title ? `${title} ${TITLE_POSTFIX}` : 'Hopscotch'}</title>
      </Helmet>
      {children}
    </>
  );
};

DocumentHead.propTypes = {
  children: oneOfType([node, arrayOf(node), func]),
  title: string,
};

export default DocumentHead;
