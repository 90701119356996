// libraries
import React, { PropsWithChildren, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
// constants
import { TFA_PATHNAME } from 'router/configs/auth';
// components
import HeaderWithoutControls from './HeaderWithoutControls/HeaderWithoutControls';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import BaseFooter from '../basic/Footer/Footer';
// constants
import { VerificationStatues } from 'shared/stores/AuthStore/AuthStore';
import config from 'config';
// helpers
import useAuth from 'components/Auth/authHelper';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './styles.module.scss';

type PublicLayoutProps = PropsWithChildren<{
  checkToken?: boolean;
  hasControlsInHeader?: boolean;
  isNavigationVisible?: boolean;
  isPublicProfile?: boolean;
  children: React.ReactNode;
  customClassName?: string;
  handleLogoClick?: () => void;
}>;

const PublicLayout: React.FC<PublicLayoutProps> = ({
  children = null,
  hasControlsInHeader = false,
  isNavigationVisible = false,
  isPublicProfile = false,
  checkToken = false,
  customClassName = '',
  handleLogoClick = () => {},
}: PublicLayoutProps) => {
  const {
    authStore: { isAuthLoading, sessionId, verificationStatus, mfaEnabled },
  } = useStore();
  const { isAdminLoggedIn } = useAuth();

  if (checkToken) {
    if (isAuthLoading) return null;

    if (sessionId && mfaEnabled && verificationStatus !== VerificationStatues.VERIFIED) {
      return <Redirect to={TFA_PATHNAME} />;
    }

    if (sessionId && verificationStatus === VerificationStatues.VERIFIED) {
      return <Redirect to="/" />;
    }

    if (isAdminLoggedIn()) {
      window.location.href = `${config.adminPlatformUrl}`;
      return null;
    }
  }

  const containerStyles = classNames(
    hasControlsInHeader ? styles['layout--public-full'] : styles['layout--public'],
    customClassName && styles[customClassName],
  );

  return (
    <div className={containerStyles}>
      {hasControlsInHeader ? (
        <Header isNavigationVisible={isNavigationVisible} />
      ) : (
        <HeaderWithoutControls handleLogoClick={handleLogoClick} />
      )}
      <main className={styles.layout__main}>
        <Container className="layout__container p-0">
          <div className="layout__container-wrapper">{children}</div>
        </Container>
      </main>
      {isPublicProfile ? <BaseFooter /> : <Footer />}
    </div>
  );
};

export default observer(PublicLayout);
