import csc from 'country-state-city';

export const USA_COUNTRY = 'USA';
export const USA_COUNTRY_CODE = 'US';

export const COUNTRIES = csc.getAllCountries().map(country => {
  return {
    value: country.name === 'United States' ? USA_COUNTRY : country.name,
    label: country.name === 'United States' ? USA_COUNTRY : country.name,
    params: country.sortname,
  };
});

export const COUNTRIES_STRIPE = csc.getAllCountries().map(country => {
  return {
    value: country.sortname,
    label: country.name,
  };
});
