// libraries
import React from 'react';
// components
import PublicLayout from 'shared/layouts/public';
import ConfirmationView from 'components/verification/ConfirmationPage';

const ConfirmationPage: React.FC = () => (
  <PublicLayout>
    <ConfirmationView />
  </PublicLayout>
);

export default ConfirmationPage;
