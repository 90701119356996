// libraries
import React from 'react';
import classNames from 'classnames';
// styles
import styles from 'components/EntryButton/EntryButton.module.scss';
// components
import SubmitButton from 'components/Form/buttons/SubmitButton';

interface IEntryButton {
  entryButtonClassName?: string;
  children: string;
  disabled?: boolean;
}

const EntryButton: React.FC<IEntryButton> = ({ children, entryButtonClassName, disabled = false }: IEntryButton) => (
  <SubmitButton disabled={disabled} className={classNames(styles.button, entryButtonClassName)}>
    {children}
  </SubmitButton>
);

export default EntryButton;
