// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/Button';
// helpers
import { Document as DocumentType } from 'shared/stores/DocumentStore/DocumentStore';
import useDocument from 'components/company/CompanyDocuments/Document/useDocument';
// assets
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
// styles
import styles from './Document.module.scss';

type DocumentProps = {
  document: DocumentType;
};

const Document: React.FC<DocumentProps> = ({ document }: DocumentProps) => {
  const { t } = useTranslation('documentsPage');
  const { downloadDocument, isLoading } = useDocument(document);

  return (
    <div className={styles.document__wrapper}>
      <div>
        <p className={styles['document-title']}>{document.title}</p>
        <span className={styles['document-subtitle']}>{document.subtitle}</span>
      </div>

      <Button handleClick={downloadDocument} disabled={isLoading} className={styles['document-download-btn']} btnLink>
        <DownloadIcon width={15} height={12} />
        {t('downloadBtn')}
      </Button>
    </div>
  );
};

export default observer(Document);
