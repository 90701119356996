// libraries
import { useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
// constants
import MessageTypes from 'shared/constants/messageTypes';
import Endpoints from 'shared/constants/endpoints';
import TopicAliasEvents from 'shared/constants/topicAliasEvents';
// helpers
import { useWebsocketContext } from 'shared/websocket/websocketContext';
import { useStore } from 'shared/stores/RootStore/useStore';

const useSubscribe = (): void => {
  const { sendMessage } = useWebsocketContext();
  const {
    authStore: { sessionId },
    myBusinessProfilesStore: { myBusinessProfilesData, getFirstMyBusinessProfiles },
  } = useStore();

  const firstBusinessProfile = useMemo(() => toJS(getFirstMyBusinessProfiles()), [
    getFirstMyBusinessProfiles,
    myBusinessProfilesData,
  ]);

  useEffect(() => {
    if (sessionId) {
      sendMessage(MessageTypes.REQUEST, Endpoints.GET_MY_BUSINESS_PROFILES, {}, sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (firstBusinessProfile?.bizId && sessionId) {
      sendMessage(
        MessageTypes.REQUEST,
        Endpoints.SUBSCRIBE,
        {
          topicAlias: TopicAliasEvents.entityBusiness,
          id: firstBusinessProfile?.bizId,
        },
        sessionId,
      );
    }
  }, [firstBusinessProfile?.bizId, sessionId]);
};

export default useSubscribe;
