import businessInfoRoutes from './business-information';
import bankDetailsRoutes from './bank-details';
import NotFound from 'router/views/NotFound/NotFound';

export default [
  ...businessInfoRoutes,
  ...bankDetailsRoutes,
  {
    path: '*',
    exact: true,
    isProtected: false,
    component: NotFound,
    name: '404',
  },
];
