import React from 'react';
import shortid from 'shortid';
// styles
import styles from './HighlightText.module.scss';

type HighlightTextProps = {
  value: string;
  children?: string;
  id?: string;
};

const HighlightText: React.FC<HighlightTextProps> = ({ value = '', children = '', id }: HighlightTextProps) => {
  if (value) {
    const pattern = new RegExp(`(${value})`, 'i');
    const parts = String(children).split(pattern);
    return (
      <span>
        {parts.map(part =>
          pattern.test(part) ? (
            <span className={styles.highlight} key={shortid.generate()}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </span>
    );
  }

  return <span>{children}</span>;
};

export default HighlightText;
