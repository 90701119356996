// libraries
import { useEffect } from 'react';
// helpers
import { tryFormatToIso } from 'components/Form/inputs/DateInput/tryFormatToIso';
import { useFormContext } from 'components/Form/FormContext';
import { Invoice, InvoiceFormData } from '../types';

const useInvoiceLoadData = (invoice?: Partial<Invoice> | null) => {
  const { setFieldValues = () => {} } = useFormContext();

  const setDataFromInvoice = (invoiceDetails: Partial<Invoice>) => {
    const { totalAmount, dueDate = 0, message = '' } = invoiceDetails;

    setFieldValues(InvoiceFormData.amount, totalAmount);
    setFieldValues(InvoiceFormData.dueDate, tryFormatToIso(dueDate));
    setFieldValues(InvoiceFormData.description, message);
  };

  const loadData = async (invoiceDetails: Partial<Invoice>) => {
    setDataFromInvoice(invoiceDetails);
  };

  useEffect(() => {
    if (invoice) {
      loadData(invoice);
    }
    // Suppressed warnings because we should load data only if data in store were updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);
};

export default useInvoiceLoadData;
