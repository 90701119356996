import React, { Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import Input, { InputCommonProps, InputProps } from '../Input';
import InputErrors from '../InputErrors';
import styles from './TextInput.module.scss';

export enum InputFieldSize {
  XS = 'xs',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export interface TextInputProps extends Omit<InputProps, 'children'> {
  inputElement?: (props: InputCommonProps) => React.ReactNode;
  inputWrapperClassName?: string;
  fieldWrapperClassName?: string;
  fieldLabelClassName?: string;
  children?: React.ReactNode;
  label?: string;
  inputSize?: InputFieldSize;
  additionalOnChangeCallBack?: Dispatch<SetStateAction<string>>;
  onClick?: () => void;
}

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const {
    label,
    children,
    inputElement,
    inputWrapperClassName = '',
    fieldWrapperClassName = '',
    fieldLabelClassName = '',
    inputSize,
    onClick,
    ...inputProps
  } = props;

  return (
    <Input {...inputProps}>
      {({ isRequired, errorMessages, inputCommonProps }) => {
        const inputClassName = classnames(styles['field-input'], inputCommonProps.className);

        return (
          <div
            className={classnames(
              styles['field-wrapper'],
              {
                [styles['field-wrapper--disabled']]: inputCommonProps.disabled,
                [styles[`field-wrapper--${inputSize}`]]: inputSize,
              },
              fieldWrapperClassName,
            )}
          >
            {label && (
              <label
                htmlFor={inputCommonProps.name}
                className={classnames(styles['field-label'], fieldLabelClassName, {
                  [styles['field-label--required']]: isRequired,
                })}
              >
                {label}
              </label>
            )}
            <div className={inputWrapperClassName}>
              {inputElement ? (
                inputElement({ ...inputCommonProps, className: inputClassName })
              ) : (
                <input
                  {...inputCommonProps}
                  className={inputClassName}
                  onClick={onClick}
                  value={inputProps.value || inputCommonProps.value}
                />
              )}
              {errorMessages ? <InputErrors errorMessages={errorMessages} /> : null}
              {children}
            </div>
          </div>
        );
      }}
    </Input>
  );
};

export default TextInput;
