import BankDetails from 'router/views/Verification/BankDetails/BankDetails';

export enum VerificationPagesName {
  BANK_DETAILS = 'Bank Details',
}

export default [
  {
    path: '/verification/bank-accounts',
    component: BankDetails,
    name: VerificationPagesName.BANK_DETAILS,
    exact: true,
  },
];
