// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// validations
import CODE_VERIFICATION_FORM_SCHEMA from './validation-rules';
// components
import ResetPasswordFormType from 'components/resetPassword/ResetPasswordFormType/ResetPasswordFormType';
// types
import { VerificationFormData } from 'components/resetPassword/usePasswordResetForm';

interface IVerificationFormProps {
  submitVerificationForm: (data: VerificationFormData) => void;
}

const VerificationForm: React.FC<IVerificationFormProps> = ({ submitVerificationForm }: IVerificationFormProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <ResetPasswordFormType
      onSubmit={data => submitVerificationForm(data as VerificationFormData)}
      validationSchema={CODE_VERIFICATION_FORM_SCHEMA}
      labelName={t('verification.fields.verificationCode.label')}
      inputPlaceholder={t('verification.fields.verificationCode.placeholder')}
      inputName="verificationCode"
      buttonTitle={t('verification.submitButton')}
    />
  );
};

export default VerificationForm;
