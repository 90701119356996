// libraries
import React from 'react';
import { ObjectSchema } from 'yup';
// components
import Form from 'components/Form';
import ResetPasswordFields from './ResetPasswordFields';
// helpers
import { FormData } from 'components/Form/FormContext';
// styles
import styles from 'components/resetPassword/ResetPasswordFormType/ResetPasswordFormType.module.scss';

interface IPasswordResetProps {
  onSubmit: (data: FormData) => void;
  labelName: string;
  validationSchema: ObjectSchema;
  inputName: string;
  inputPlaceholder: string;
  buttonClassName?: string;
  buttonTitle: string;
}

const ResetPasswordFormType: React.FC<IPasswordResetProps> = ({
  onSubmit,
  labelName = '',
  inputName = '',
  inputPlaceholder = '',
  validationSchema,
  buttonTitle = '',
  buttonClassName = '',
}: IPasswordResetProps) => {
  return (
    <Form submitForm={onSubmit} validationSchema={validationSchema} className={styles.recovery__form}>
      <ResetPasswordFields
        labelName={labelName}
        inputName={inputName}
        inputPlaceholder={inputPlaceholder}
        buttonClassName={buttonClassName}
        buttonTitle={buttonTitle}
      />
    </Form>
  );
};

export default ResetPasswordFormType;
