// libraries
import React from 'react';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

interface DropdownButtonItemProps {
  href?: string;
  children: React.ReactNode;
  onClick?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemElement?: React.FC<any>;
  disabled?: boolean;
  prevPathEnable?: boolean;
}

const DropdownButtonItem: React.FC<DropdownButtonItemProps> = ({
  onClick,
  href,
  children,
  itemElement,
  disabled = false,
  prevPathEnable = false,
}: DropdownButtonItemProps) => {
  const { pathname } = useLocation();

  return (
    <Dropdown.Item
      className={classNames('dropdown-button__menu-item', {
        'dropdown-button__menu-item--disabled': disabled,
      })}
      href={href}
      to={prevPathEnable ? { pathname: href, state: { prevPath: pathname } } : href}
      onClick={onClick}
      as={href ? NavLink : itemElement}
    >
      {children}
    </Dropdown.Item>
  );
};

export default DropdownButtonItem;
