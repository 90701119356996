// libraries
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// components
import Modal from 'components/Modal/Modal';
import Form from 'components/Form';
import ChangePasswordModalFields from './ChangePasswordModalFields';
// helpers
import CHANGE_PASSWORD_FORM_SCHEMA from './validation-rules';
import useAccountTabForm from '../useAccountTabForm';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { CreateNewPasswordFormData } from 'components/resetPassword/usePasswordResetForm';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './ChangePasswordModal.module.scss';

const ChangePasswordModal: React.FC = () => {
  const { t } = useTranslation('settingsPage');
  const {
    modalStore: { closeModal },
  } = useStore();

  const { submitChangePasswordModal } = useAccountTabForm();

  const handleCloseModal = useCallback(() => {
    closeModal(ModalIds.changePasswordModal);
  }, [closeModal]);

  return (
    <Modal
      id={ModalIds.changePasswordModal}
      handleCloseModal={handleCloseModal}
      closeButtonClassName={styles['close-button']}
    >
      <Form
        className={styles.form}
        validationSchema={CHANGE_PASSWORD_FORM_SCHEMA}
        submitForm={data => submitChangePasswordModal(data as CreateNewPasswordFormData)}
      >
        <div className={styles.form__wrapper}>
          <div className={styles.title}>{t('accountBlock.changePasswordModal.title')}</div>
          <ChangePasswordModalFields handleCloseModal={handleCloseModal} />
        </div>
      </Form>
    </Modal>
  );
};

export default observer(ChangePasswordModal);
