// libraries
import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
// api
import { NewBankAccount } from 'components/paymentMethods/api/PaymentMethodApi';
// constants
import { getRandomMockItem } from './mockData';
// helpers
import { addErrorToast } from 'components/Toast/toastHelper';
import { getMockData } from 'shared/helpers/simulateAsyncCall';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';

interface PlaidLinkProps {
  linkToken: string;
}

type PlaidHelperProps = {
  submitCallback?: (account: Account) => void;
};

export type PlaidHelper = {
  openPlaidModal: () => void;
  isPlaidAvailable: boolean;
};

enum PlaidEvents {
  open = 'OPEN',
  exit = 'EXIT',
  error = 'ERROR',
  handoff = 'HANDOFF',
}

const usePlaid = ({ submitCallback }: PlaidHelperProps = {}): PlaidHelper => {
  const [plaidLink, setPlaidLink] = useState<Nullable<string>>(null);

  const {
    paymentMethods: { addAccount, modalId, setLoading },
    modalStore: { closeModal },
  } = useStore();

  // Plaid link must be generated on BE side and send to FE
  // https://plaid.com/docs/link/
  const getPlaidLink = useCallback(async () => {
    const mockPlaidLink = localStorage.getItem('plaid_link') || '';

    const mockResponse: PlaidLinkProps = { linkToken: mockPlaidLink };
    try {
      const { linkToken } = await getMockData(mockResponse);
      setPlaidLink(linkToken as string);
    } catch (e) {
      const { message } = await e;
      addErrorToast(message);
    }
  }, []);

  useEffect(() => {
    getPlaidLink();
  }, [getPlaidLink]);

  const connectBankAccount = useCallback(
    async (token, metadata) => {
      try {
        // TODO: Need to get account data from BE (getNewAccount(data))
        const data: NewBankAccount = {
          publicToken: token,
          accountId: metadata.account_id,
        };

        const account = await getMockData(getRandomMockItem());
        closeModal(modalId);
        addAccount(account as Account);

        if (submitCallback) {
          submitCallback(account);
        }
      } catch (e) {
        const { message } = await e;
        addErrorToast(message);
      } finally {
        setLoading(false);
      }
    },
    [addAccount, closeModal, modalId, setLoading],
  );

  const config = {
    token: plaidLink || '',
    onSuccess: connectBankAccount,
    onEvent: (eventName: PlaidEvents) => {
      if (eventName === PlaidEvents.open) {
        setLoading(true);
      }

      if (eventName === PlaidEvents.exit || eventName === PlaidEvents.error) {
        setLoading(false);
      }
    },
  };

  const { open, ready } = usePlaidLink(config);

  const openPlaidModal = () => open();
  const isPlaidAvailable = ready && Boolean(plaidLink);

  return {
    openPlaidModal,
    isPlaidAvailable,
  };
};

export default usePlaid;
