// libraries
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
// api
import { AccountType } from 'components/paymentMethods/api/PaymentMethodApi';
// components
import Form from 'components/Form';
import Button from 'components/Button';
import { RadioInput } from 'components/Form/inputs';
import SubmitButton from 'components/Form/buttons/SubmitButton';
// helpers
import PAYMENT_METHOD_SCHEMA from './validation-rules';
import { PaymentMethodParams } from 'components/paymentMethods/PaymentMethod/usePaymentMethod';
import usePaymentOptions from 'components/paymentMethods/PaymentMethod/PaymentOptions/usePaymentOptions';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './PaymentOptions.module.scss';

type PaymentOptionsProps = Pick<PaymentMethodParams, 'handleCloseModal'>;

const PaymentOptions: React.FC<PaymentOptionsProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation(['connectedAccountsPage', 'forms']);
  const { pathname } = useLocation();
  const { formData, submitForm, isPlaidAvailable } = usePaymentOptions();
  const {
    transferFundsStore: { transferType },
    paymentMethods: { setLoading },
  } = useStore();

  useEffect(() => {
    setLoading(!isPlaidAvailable);
  }, [isPlaidAvailable, setLoading]);

  const shouldShowCreditCard = false;

  return (
    <Form
      submitForm={submitForm}
      className={styles['payment-options']}
      data={formData}
      validationSchema={PAYMENT_METHOD_SCHEMA}
    >
      <h3 className={styles['payment-options__title']}>{t('paymentMethods.title')}</h3>
      {shouldShowCreditCard && (
        <RadioInput
          name="type"
          label={t('paymentMethods.types.creditCard')}
          value={AccountType.CREDIT_CARD}
          fieldWrapperClassName={styles['payment-options__radio']}
        />
      )}
      <RadioInput name="type" label={t('paymentMethods.types.bankAccount')} value={AccountType.BANK} />
      <div className={styles['payment-options__buttons']}>
        <Button handleClick={handleCloseModal} outline>
          {t('forms:cancelButtonDefaultText')}
        </Button>
        <SubmitButton>{t('forms:continueButtonDefaultText')}</SubmitButton>
      </div>
    </Form>
  );
};

PaymentOptions.propTypes = {
  handleCloseModal: func.isRequired,
};

export default observer(PaymentOptions);
