import React from 'react';
import DOMPurify from 'dompurify';

interface SanitizedHtmlProps {
  html: string;
  className?: string;
  wrapperTag?: React.ElementType;
}

/**
 * This component sanitizes HTML and returns a tag with sanitized data.
 * It was created to prevent XSS attacks and it locates dangerouslySetInnerHTML in one place.
 * @this  {SanitizedHtml}
 * @param {SanitizedHtmlProps}
 * html - html that we need to sanitize;
 * wrapperTag - a tag what we need to return (div by default);
 * props - props to be passed to tag;
 * @return a tag with sanitized html
 */
const SanitizedHtml = ({ html, wrapperTag: Tag = 'div', ...props }: SanitizedHtmlProps) => {
  const cleanData = DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
  return (
    <Tag
      {...props}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: cleanData,
      }}
    />
  );
};

export default SanitizedHtml;
