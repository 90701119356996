import React, { useEffect, useMemo, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CollapseItemData = any;

const getNumber = (value: string): number => +value.replace(/[^0-9]/g, '');

interface CollapseData {
  parentBlockRef: React.MutableRefObject<Nullable<HTMLDivElement>>;
  itemsRefs: React.MutableRefObject<Nullable<HTMLDivElement>>[];
  numberOfItems: number;
  displayedData: CollapseItemData[];
  isCollapsed: boolean;
}

const useCollapse = (maxItemNumber: number, data: CollapseItemData[] = []): CollapseData => {
  const numberOfItems = data.length;
  const [visibleItems, setVisibleItems] = useState<React.MutableRefObject<Nullable<HTMLDivElement>>[]>([]);
  const displayedData = data.slice(0, maxItemNumber);
  const isCollapsed = useMemo(
    () => !!visibleItems.length && (numberOfItems >= maxItemNumber || visibleItems.length < numberOfItems),
    [visibleItems.length, numberOfItems, maxItemNumber],
  );

  const parentBlockRef = useRef<Nullable<HTMLDivElement>>(null);

  const itemsRefs = useMemo<React.MutableRefObject<Nullable<HTMLDivElement>>[]>(
    () =>
      Array(maxItemNumber)
        .fill(null)
        .map(() => React.createRef()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, maxItemNumber],
  );

  const getPositioning = ({ x, width, y, height }: DOMRect) => ({
    startX: x,
    endX: x + width,
    startY: y,
    endY: y + height,
  });

  const isVisibleDueToOverflow = (parentEl: Nullable<HTMLElement>, el: Nullable<HTMLElement>) => {
    if (!parentEl || !el) {
      return false;
    }

    const elPositioning = getPositioning(el.getBoundingClientRect());
    const parentElPositioning = getPositioning(parentEl.getBoundingClientRect());
    const parentElStyles = window.getComputedStyle(parentEl);

    const isElInsideParentRectX =
      elPositioning.startX >= parentElPositioning.startX && elPositioning.endX <= parentElPositioning.endX;
    const isElInsideParentRectY =
      elPositioning.startY >= parentElPositioning.startY &&
      elPositioning.endY <= parentElPositioning.endY - getNumber(parentElStyles.paddingBottom);

    return isElInsideParentRectX && isElInsideParentRectY;
  };

  useEffect(() => {
    const newVisibleItems = itemsRefs.filter(item => isVisibleDueToOverflow(parentBlockRef?.current, item?.current));
    setVisibleItems(newVisibleItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentBlockRef, itemsRefs[0]]);

  return {
    parentBlockRef,
    itemsRefs,
    numberOfItems,
    displayedData,
    isCollapsed,
  };
};

export default useCollapse;
