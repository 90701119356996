// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { formatCurrency } from 'shared/helpers/format';
// styles
import styles from './CurrentBalance.module.scss';

type TCurrentBalanceProps = {
  isAmountError: boolean;
  balance: number;
};

const TransferCurrentBalance: React.FC<TCurrentBalanceProps> = ({ isAmountError, balance }: TCurrentBalanceProps) => {
  const { t } = useTranslation('accountingPage');

  return (
    <div className={styles['transfer-balance__wrapper']}>
      <span
        className={classNames(styles['transfer-balance'], {
          [styles['text-error']]: isAmountError,
        })}
      >
        {t('accountingBalance.currentHopscotchBalance')}: {formatCurrency(balance, false)}
      </span>
    </div>
  );
};

export default TransferCurrentBalance;
