// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Routes from 'router/Routes';
import BaseLayout from 'shared/layouts/basic';
import SettingsLayout from 'components/settings/SettingsLayout/SettingsLayout';
import SideNav from 'components/SideNav';
// constants
import menuMap from 'components/settings/SettingsNav/menuMap';
// helpers
import settingsRoutes from 'router/configs/settings';

const Settings: React.FC = () => {
  const { t } = useTranslation('settingsPage');

  return (
    <BaseLayout>
      <SettingsLayout titleBlock={t('titlePage')}>
        <SideNav menuMap={menuMap} />
        <Routes routes={settingsRoutes} />
      </SettingsLayout>
    </BaseLayout>
  );
};

export default Settings;
