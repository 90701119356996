// libraries
import React, { useContext } from 'react';

export type SortOrder = 'ASC' | 'DESC' | string;

export type SortParam = {
  field?: string;
  order?: SortOrder;
};

export interface IListParams {
  sort?: SortParam;
  search?: string;
  [key: string]: string | SortParam | undefined;
}

export interface IDefaultListParams {
  initialParams?: IListParams;
  defaultSortOrder?: SortOrder;
  defaultSortField?: string;
  page?: number;
}

export type TListItem = any;
export type TListData = TListItem[];

export interface IListContextData {
  data?: TListData;
  dataWithoutSearch?: TListData;
  updateAllListData?: (data: TListData) => void;
  deleteFromList?: (id: string) => void;
  sort?: SortParam;
  sortBy?: (key?: string, order?: SortOrder) => void;
  searchBy?: (search: string) => void;
  search?: string;
  addToList?: (entity: TListItem) => void;
  updateInList?: (entityId: string, entity: TListItem) => void;
}

const defaultContext = {};

const ListContext = React.createContext<IListContextData>(defaultContext);

export const ListProvider = ListContext.Provider;

export const useListContext = () => useContext(ListContext);

export default ListContext;
