// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Select from 'components/Form/inputs/Select/Select';
// helpers
import {
  FilterTabs,
  HistoryDurations,
  HistoryDurationsDaysDifferences,
  filterInvoicesByPeriod,
} from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
import { useListContext } from 'components/list/ListContext';
import { Invoice } from 'components/invoices/types';
// styles
import styles from 'components/transactions/TransactionHistory/filters/HistoryDurationFilter.module.scss';

interface IHistoryDurationFilter {
  activeTab: FilterTabs;
  allInvoices: Invoice[];
  closeInvoiceDetails: () => void;
}

const HistoryDurationFilter: React.FC<IHistoryDurationFilter> = ({
  activeTab,
  allInvoices,
  closeInvoiceDetails,
}: IHistoryDurationFilter) => {
  const [selectedValue, setSelectedValue] = useState(HistoryDurations.all);
  const isCompletedTab = useMemo(() => activeTab === FilterTabs.COMPLETE, [activeTab]);
  const { t } = useTranslation('transactionHistory');
  const { updateAllListData = () => {} } = useListContext();
  const now = Date.now();

  useEffect(() => {
    setSelectedValue(HistoryDurations.all);
  }, [activeTab]);

  const filterInvoices = useCallback(
    (activeValue: HistoryDurations) => {
      if (activeValue === HistoryDurations.all) {
        updateAllListData(allInvoices);
        return;
      }

      const data = filterInvoicesByPeriod(
        allInvoices,
        isCompletedTab,
        now,
        HistoryDurationsDaysDifferences[activeValue] - 1,
      );
      updateAllListData(data);
    },
    [allInvoices, updateAllListData, now, isCompletedTab],
  );

  const changeHistoryFilter = useCallback(
    (newValue: string) => {
      closeInvoiceDetails();
      setSelectedValue(newValue as HistoryDurations);
      filterInvoices(newValue as HistoryDurations);
    },
    [closeInvoiceDetails, filterInvoices],
  );

  return (
    <div className={styles['history-duration-filter']}>
      {t('filterDuration')}
      <Select
        name="historyDuration"
        options={Object.values(HistoryDurations).map(value => ({
          value,
          label: t(isCompletedTab ? `completedFilterDurationValues.${value}` : `filterDurationValues.${value}`),
        }))}
        defaultValue={selectedValue}
        fieldWrapperClassName={styles['history-duration-filter__input']}
        onChangeCallback={changeHistoryFilter}
      />
    </div>
  );
};

export default HistoryDurationFilter;
