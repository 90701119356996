// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// components
import TransactionCardFooterActionItem from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooterActions/TransactionCardFooterActionItem';
// assets
import { ReactComponent as EditPenIcon } from 'assets/icons/edit-pen.svg';
// helpers
import { Invoice } from 'components/invoices/types';
import { AddBillFormDataFromInvoice } from 'components/AddBill/types';

interface IEditPayableInvoice {
  invoiceDetails: Invoice;
}

const EditPayableInvoice: React.FC<IEditPayableInvoice> = ({ invoiceDetails }: IEditPayableInvoice) => {
  const { t } = useTranslation('transactionHistory');
  const history = useHistory();

  const editInvoice = useCallback(() => {
    const invoice: AddBillFormDataFromInvoice = {
      customer: invoiceDetails.counterpartyDetails?.dbaName,
      amount: invoiceDetails.totalAmount,
      invoiceNumber: invoiceDetails.invoiceNumber,
      dueDate: invoiceDetails.dueDate,
      message: invoiceDetails.message,
      invoiceId: invoiceDetails.invoiceId,
    };

    history.push('/billing', {
      invoice,
    });
  }, [invoiceDetails, history]);

  return (
    <TransactionCardFooterActionItem onClick={editInvoice}>
      <EditPenIcon width={9} height={9} />
      {t('invoiceActions.edit')}
    </TransactionCardFooterActionItem>
  );
};

export default EditPayableInvoice;
