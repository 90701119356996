import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';
import { Profiles, IMyBusinessProfilesStore } from './types';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';

class MyBusinessProfilesStore {
  myBusinessId: Nullable<string> = null;

  myBusinessProfilesData: Profiles = [];

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  getFirstMyBusinessProfiles = (): IMyBusinessProfilesStore => {
    // @ts-ignore
    return { ...this.myBusinessProfilesData }['0'];
  };

  setDataFromMessage = (data: Profiles): void => {
    this.myBusinessProfilesData = data;
    this.myBusinessId = data[0] ? data[0].bizId : null;
  };

  [CLEAR_STORE] = (): void => {
    this.myBusinessProfilesData = [];
  };
}

export default MyBusinessProfilesStore;
