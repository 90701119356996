// libraries
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { ButtonColor } from 'components/Button';
// styles
import styles from 'components/Modal/ConfirmationModal/ConfirmationModal.module.scss';

const useUnsavedChangesModal = (isDataChanged = false) => {
  const { t } = useTranslation('confirmationModal');
  const history = useHistory();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');
  const {
    modalStore: { openModal },
  } = useStore();

  const handleConfirmClick = useCallback(async () => {
    history.block(() => {});
    history.push(currentPath);
  }, [currentPath, history]);

  const handleCancelClick = useCallback(async () => {
    setCurrentPath('');
  }, []);

  const openConfirmationModal = useCallback(() => {
    openModal(ModalIds.confirmationModal, {
      title: t('unsavedChanges.title'),
      cbConfirmActionFunction: handleConfirmClick,
      cbCancelActionFunction: handleCancelClick,
      cancelButtonText: t('unsavedChanges.cancelButton'),
      confirmButtonText: t('unsavedChanges.confirmButton'),
      classNameTitle: styles['unsaved-changes-modal__title'],
      confirmButtonProps: { color: ButtonColor.danger },
    });
  }, [handleConfirmClick, openModal, t, handleCancelClick]);

  useEffect(() => {
    if (isDataChanged) {
      history.block((prompt: any) => {
        setCurrentPath(prompt.pathname);
        return prompt.pathname;
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, isDataChanged, openConfirmationModal]);

  useEffect(() => {
    if (isDataChanged && currentPath && currentPath !== location.pathname) {
      openConfirmationModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);
};

export default useUnsavedChangesModal;
