import { makeAutoObservable } from 'mobx';
import RootStore from 'shared/stores/RootStore/RootStore';

export interface ExternalTransactionInfo {
  // TODO need update in task with external payment
  [key: string]: any;
}

class ExternalPaymentsStore {
  transactionInfo: Nullable<ExternalTransactionInfo> = null;

  isPaymentLoading = false;

  isPaymentComplete = false;

  suggestRegistration = false;

  firstName: Nullable<string> = null;

  lastName: Nullable<string> = null;

  password: Nullable<string> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setTransactionInfo = (transactionInfo: Nullable<ExternalTransactionInfo>): void => {
    this.transactionInfo = transactionInfo;
  };

  setIsPaymentLoading = (loadingState: boolean): void => {
    this.isPaymentLoading = loadingState;
  };

  setName = (firstName: Nullable<string>, lastName?: Nullable<string>): void => {
    this.firstName = firstName;
    this.lastName = lastName || null;
  };

  setPassword = (password: Nullable<string>): void => {
    this.password = password;
  };

  setIsPaymentComplete = (isPaymentComplete: boolean): void => {
    this.isPaymentComplete = isPaymentComplete;
  };

  setSuggestRegistration = (suggestRegistration: boolean): void => {
    this.suggestRegistration = suggestRegistration;
  };

  clearExternalPaymentsStore = (): void => {
    this.password = null;
    this.transactionInfo = null;
    this.firstName = null;
    this.lastName = null;
    this.isPaymentComplete = false;
    this.isPaymentLoading = false;
    this.suggestRegistration = false;
  };
}

export default ExternalPaymentsStore;
