const documents = [
  {
    id: 1,
    title: 'August Summary 2021',
    file: [
      {
        url: 'url',
      },
    ],
    subtitle: 'Aug 1st - Aug 31st, 2021.',
  },
  {
    id: 2,
    title: 'July Summary 2021',
    file: [
      {
        url: 'url',
      },
    ],
    subtitle: 'July 1st - July 31st, 2021.',
  },
  {
    id: 3,
    title: 'June Summary 2021',
    file: [
      {
        url: 'url',
      },
    ],
    subtitle: 'June 1st - June 31st, 2021.',
  },
  {
    id: 4,
    title: 'May Summary 2021',
    file: [
      {
        url: 'url',
      },
    ],
    subtitle: 'May 1st - May 31st, 2021.',
  },
  {
    id: 5,
    title: 'April Summary 2021',
    file: [
      {
        url: 'url',
      },
    ],
    subtitle: 'April 1st - April 31st, 2021.',
  },
];
const getDocuments = () => {
  return documents;
};

export default getDocuments;
