import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './CompanyProfileFormField.module.scss';

type TCompanyProfileFormFieldProps = PropsWithChildren<{
  title?: string;
  wrapperClassName?: string;
}>;

const CompanyProfileFormField: React.FC<TCompanyProfileFormFieldProps> = ({
  title = '',
  wrapperClassName = '',
  children = null,
}) => {
  const defaultWrapperClassName = styles['field__content-wrapper'];

  return (
    <div className={wrapperClassName || defaultWrapperClassName}>
      <div className={styles['field__content-title']}>
        {title && <span className={styles['field__content-header']}>{title}</span>}
      </div>
      {children}
    </div>
  );
};

export default observer(CompanyProfileFormField);
