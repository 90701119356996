// libraries
import React from 'react';
// assets
import { ReactComponent as EditPen } from 'assets/icons/edit-pen.svg';
// styles
import styles from './EditButton.module.scss';

type EditButtonProps = {
  handleOnClick: () => void;
};

const EditButton: React.FC<EditButtonProps> = ({ handleOnClick }: EditButtonProps) => {
  return (
    <div className={styles['button-container']}>
      <button onClick={handleOnClick} className={styles['edit-button']}>
        <EditPen />
      </button>
    </div>
  );
};

export default EditButton;
