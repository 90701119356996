// libraries
import React from 'react';
// components
import SettingFormWrapper from 'components/settings/SettingFormWrapper/SettingFormWrapper';
import CompanyDocuments from 'components/company/CompanyDocuments/CompanyDocuments';

const Documents: React.FC = () => {
  return (
    <SettingFormWrapper>
      <CompanyDocuments />
    </SettingFormWrapper>
  );
};

export default Documents;
