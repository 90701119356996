// helpers
import { useFieldContext } from 'components/list/field/FieldContext';
import { IData } from 'components/list/field/FieldData';
import { isNotNullValue } from 'components/list/listHelper';
import { IFieldProps } from 'components/list/field';

interface IFieldHelperData<ValueType> {
  getValue: () => ValueType;
  getLabel: () => string;
  getData: () => IData | any;
}

const useFieldHelper: <ValueType = string>(props?: IFieldProps<ValueType>) => IFieldHelperData<ValueType> = (
  props = { source: '', label: '' },
) => {
  const data = useFieldContext()?.data;

  const getValue = () => {
    const { value, source = '' } = props;

    const getValueFromContext = () => {
      if (!data) {
        return undefined;
      }

      const showContextValue = data.getValueBySource(source);

      if (isNotNullValue(showContextValue)) {
        return showContextValue;
      }

      return undefined;
    };

    return isNotNullValue(value) ? value : getValueFromContext();
  };

  const getFieldLabel = () => props.label;

  const getData = () => data && data.getData();

  return {
    getValue,
    getLabel: getFieldLabel,
    getData,
  };
};
export default useFieldHelper;
