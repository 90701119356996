import { makeAutoObservable } from 'mobx';
import { persist } from 'mobx-persist';
import RootStore from '../RootStore/RootStore';
import { IAuthStore } from './types';

export enum VerificationStatues {
  VERIFIED = 'Verified',
  FAILED = 'Failed',
}

class AuthStore {
  @persist sessionId: Nullable<string> = null;

  failureReason: Nullable<string> = null;

  isFetching = false;

  isAuthLoading = true;

  isFirstLogin = false;

  sessionTimedOut = false;

  mfaEnabled = false;

  verificationStatus: Nullable<VerificationStatues> = null;

  userId: Nullable<string> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setIsAuthLoading = (value = false): void => {
    this.isAuthLoading = value;
  };

  setSessionTimedOut = (value = true): void => {
    this.sessionTimedOut = value;
  };

  setSessionId = (sessionId: Nullable<string>): void => {
    this.sessionId = sessionId;
  };

  setVerificationStatus = (verificationStatus: VerificationStatues): void => {
    this.verificationStatus = verificationStatus;
  };

  removeSessionId = (): void => {
    this.sessionId = null;
    this.verificationStatus = null;
    this.userId = null;
  };

  setIsFirstLogin = (value = true): void => {
    this.isFirstLogin = value;
  };

  setDataFromMessage = (data: IAuthStore): void => {
    this.sessionId = data.sessionId;
    this.failureReason = data.failureReason;
    this.isFetching = data.isFetching;
    this.mfaEnabled = data.mfaEnabled;
    this.userId = data.userId;

    if (data.mfaEnabled) {
      return;
    }

    this.verificationStatus = VerificationStatues.VERIFIED;
  };

  removeFailureReason = (): void => {
    this.failureReason = null;
  };
}

export default AuthStore;
