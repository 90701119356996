// libraries
import { makeAutoObservable } from 'mobx';
// helpers
import RootStore from 'shared/stores/RootStore/RootStore';
import { Notification } from 'shared/stores/NotificationsStore/NotificationsStore';

class VerificationStore {
  verificationPopup: Nullable<Notification> = null;

  verificationLink: Nullable<string> = null;

  businessVerificationErrors: Dictionary<string> = {};

  personalVerificationErrors: Dictionary<string> = {};

  shouldShowLoginPopup = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setVerificationPopup = (data: Nullable<Notification>): void => {
    this.verificationPopup = data;
  };

  setVerificationLink = (link: Nullable<string>): void => {
    this.verificationLink = link;
  };

  setBusinessVerificationErrors = (data: Dictionary<string>): void => {
    this.businessVerificationErrors = data;
  };

  setPersonalVerificationErrors = (data: Dictionary<string>): void => {
    this.personalVerificationErrors = data;
  };

  setShouldShowLoginPopup = (value: boolean): void => {
    this.shouldShowLoginPopup = value;
  };

  resetVerificationData = (): void => {
    this.verificationPopup = null;
    this.verificationLink = null;
    this.shouldShowLoginPopup = false;
  };
}

export default VerificationStore;
