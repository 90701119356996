import { makeAutoObservable } from 'mobx';
import RootStore from 'shared/stores/RootStore/RootStore';
import { ModalIds } from 'shared/constants/modalIds';

export enum AccountType {
  CREDIT_CARD = 'cc',
  DEBIT_CARD = 'dc',
  BANK = 'ach',
}

export interface Fee {
  fixFeeAmount?: number;
  feePercentage: string;
  maxFeeAmount?: number;
  feePercentagePayouts?: string;
}

export type Account = {
  id: number;
  brand: string;
  lastNumbers: string;
  isFundingAccount: boolean;
  isDepositAccount: boolean;
  type?: AccountType;
  logo?: string;
};

class PaymentMethodStore {
  accounts: Account[] = [];

  accountToFunds: Nullable<Account> = null;

  modalId: ModalIds = ModalIds.paymentMethod;

  isLoading = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setAccounts = (accounts: Account[]): void => {
    this.accounts = [...accounts];
  };

  addAccount = (account: Account): void => {
    this.accounts = [...this.accounts, account];
  };

  addAccountToFunds = (account: Account): void => {
    this.accountToFunds = account;
  };

  removeAccountToFunds = (): void => {
    this.accountToFunds = null;
  };

  removeAccount = (id: number): void => {
    this.accounts = this.accounts.filter(account => account.id !== id);
  };

  setModalId = (modalId: ModalIds): void => {
    this.modalId = modalId;
  };

  setLoading = (loading: boolean): void => {
    this.isLoading = loading;
  };
}

export default PaymentMethodStore;
