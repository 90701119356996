import React from 'react';

type TCompletedModalProps = {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconClassName: string;
  wrapperClassName: string;
  children: React.ReactNode;
};

const CompletedModal: React.FC<TCompletedModalProps> = ({
  icon: Icon,
  iconClassName,
  wrapperClassName,
  children,
}: TCompletedModalProps) => {
  return (
    <div className={wrapperClassName}>
      <Icon width={60} height={60} className={iconClassName} />
      {children}
    </div>
  );
};

export default CompletedModal;
