import React from 'react';
import styles from './ImageUploadButton.module.scss';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

type ImageUploadButtonProps = {
  title: string;
};

const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({ title = '' }) => {
  return (
    <button className={styles['upload-button']} disabled>
      <UploadIcon />
      <span className={styles['upload-button__text']}>{title}</span>
    </button>
  );
};

export default ImageUploadButton;
