import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';
import { IEventPayableStore } from './types';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';

const initialValues = {
  // receivableAmount: 0,
  // pastDueReceivableAmount: 0,
  // nextWeekReceivableeAmount: 0,
  // businessEventId: '',

  // TODO: need remove after integration with BE
  receivableAmount: 15000,
  pastDueReceivableAmount: 0,
  nextWeekReceivableeAmount: 0,
  businessEventId: '',
};

class EventReceivableStore {
  receivableData = initialValues;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setDataFromMessage = (data: IEventPayableStore): void => {
    this.receivableData = {
      receivableAmount: data.receivableAmount,
      pastDueReceivableAmount: data.pastDueReceivableAmount,
      nextWeekReceivableeAmount: data.nextWeekReceivableeAmount,
      businessEventId: data.businessEventId,
    };
  };

  [CLEAR_STORE] = (): void => {
    this.receivableData = initialValues;
  };
}

export default EventReceivableStore;
