// libraries
import { differenceInDays } from 'date-fns';
// helpers
import { InvoiceStates } from 'components/invoices/types';
import { completedStates } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';

export enum InvoiceStatuses {
  scheduled = 'scheduled',
  comingUp = 'coming-up',
  overdue = 'overdue',
  completed = 'completed',
}

const PERIOD_FOR_COMING_UP = 6;

export const getInvoiceStatus = (
  invoiceState: InvoiceStates,
  dueDate: number,
  isPayableInvoice: boolean,
): Nullable<InvoiceStatuses> => {
  const now = Date.now();

  if (invoiceState === InvoiceStates.scheduled && isPayableInvoice) {
    return InvoiceStatuses.scheduled;
  }

  if (completedStates.includes(invoiceState)) {
    return InvoiceStatuses.completed;
  }

  const daysDifference = differenceInDays(dueDate, now);

  if (daysDifference < 0) {
    return InvoiceStatuses.overdue;
  }

  if (daysDifference < PERIOD_FOR_COMING_UP) {
    return InvoiceStatuses.comingUp;
  }

  return null;
};
