// libraries
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import Tooltip from 'components/Tooltip/Tooltip';
// assets
import { ReactComponent as Contacts } from 'assets/icons/contacts.svg';
// styles
import styles from './ShortlistButton.module.scss';

enum ShortlistMessages {
  addedToShortlistTooltip = 'addedToShortlistTooltip',
  removeFromShortlistTooltip = 'removeFromShortlistTooltip',
  removedFromShortlistTooltip = 'removedFromShortlistTooltip',
  addToShortlistTooltip = 'addToShortlistTooltip',
}

const ShortlistButton: React.FC = () => {
  const { t } = useTranslation('company');
  // TODO: need update initial state after integration
  const [isAddedToShortList, setIsAddedToShortList] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const tooltipMessage = useMemo(() => {
    if (isAddedToShortList) {
      if (isButtonClicked) {
        return ShortlistMessages.addedToShortlistTooltip;
      }

      return ShortlistMessages.removeFromShortlistTooltip;
    }

    if (isButtonClicked) {
      return ShortlistMessages.removedFromShortlistTooltip;
    }

    return ShortlistMessages.addToShortlistTooltip;
  }, [isAddedToShortList, isButtonClicked]);

  const changeButtonState = useCallback(() => {
    setIsAddedToShortList(prevValue => !prevValue);
    setIsButtonClicked(true);
  }, []);

  const onToggle = useCallback(() => {
    setIsButtonClicked(false);
  }, []);

  return (
    <div className={styles['button-container']}>
      <Tooltip
        message={t(tooltipMessage)}
        id="short-list-button"
        onToggle={onToggle}
        className={classnames('short-list-tooltip', {
          'small-short-list-tooltip': tooltipMessage !== ShortlistMessages.addToShortlistTooltip,
        })}
      >
        <button
          className={classnames(styles['shortlist-button'], {
            [styles.active]: isAddedToShortList,
            [styles['can-remove']]: isAddedToShortList && tooltipMessage !== ShortlistMessages.addedToShortlistTooltip,
          })}
          onClick={changeButtonState}
        >
          <Contacts />
        </button>
      </Tooltip>
    </div>
  );
};

export default ShortlistButton;
