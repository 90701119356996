import React, { useState, useMemo, useCallback } from 'react';
// api
import { Entity } from 'shared/api/crud/Crud';
// constants
import { VerificationStatues } from 'shared/stores/AuthStore/AuthStore';
import { InputElements } from 'components/Form/inputs/Input';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

interface SignFormData {
  email: Nullable<string>;
  isDisabledBtn: boolean;
  token: string;
  changeTokenCallback: (event: React.ChangeEvent<InputElements>) => void;
  verifyTfa: (data: Entity) => Promise<void>;
  sendNewCode: () => Promise<void>;
}

export enum TfaTypes {
  phone = 'phone',
  tfa = '2fa',
}

const useTwoFactorAuthForm = (): SignFormData => {
  const {
    authStore: { setVerificationStatus },
    userStore: { email },
  } = useStore();

  const [token, setToken] = useState('');

  const isDisabledBtn = useMemo(() => token.length !== 4, [token.length]);

  const changeTokenCallback = useCallback(newToken => {
    setToken(newToken);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const verifyTfa = async (data: Entity) => {
    // TODO: Update after BE integration
    setVerificationStatus(VerificationStatues.VERIFIED);
  };

  const sendNewCode = async () => {
    // TODO: Update after BE integration
  };

  return {
    token,
    isDisabledBtn,
    changeTokenCallback,
    email,
    verifyTfa,
    sendNewCode,
  };
};

export default useTwoFactorAuthForm;
