import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// api
import AuthApi from 'components/Auth/api/AuthApi';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import { useSearchParams } from 'shared/helpers/url';
import useAuth from 'components/Auth/authHelper';
import { addErrorToast } from 'components/Toast/toastHelper';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
import { UserStoreData } from 'shared/stores/UserStore/UserStore';

export enum ConfirmEmailStatus {
  confirmed = 'confirmed',
  expired = 'expired',
}

type ConfirmEmailHelperData = {
  isConfirmationLoading: boolean;
  tokenStatus: Nullable<ConfirmEmailStatus>;
  email: Nullable<string>;
};

type ConfirmEmailData = {
  status: string;
  email: string;
  token: string;
  user: UserStoreData;
};

const useConfirmEmail = (): ConfirmEmailHelperData => {
  const { t } = useTranslation('confirmEmail');
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(true);
  const [tokenStatus, setTokenStatus] = useState<Nullable<ConfirmEmailStatus>>(null);
  const [email, setEmail] = useState<Nullable<string>>(null);
  const { getSearchParameter } = useSearchParams();
  const { setAuthData, login } = useAuth();

  const confirmationToken = getSearchParameter('token');

  const {
    modalStore: { openModal },
    authStore: { setIsFirstLogin },
  } = useStore();

  const verifyToken = useCallback(async () => {
    try {
      const { user, token, email: userEmail = null, status } = (await AuthApi.confirmEmail({
        token: confirmationToken,
      })) as Partial<ConfirmEmailData>;

      if (user && token) {
        setAuthData(token, user);
        login();
        setIsFirstLogin(true);
        openModal(ModalIds.firstLoginPopupNotification);
        return;
      }

      if (status === ConfirmEmailStatus.confirmed) {
        setTokenStatus(ConfirmEmailStatus.confirmed);
        setEmail(userEmail);
        return;
      }

      if (status === ConfirmEmailStatus.expired) {
        // addErrorToast(t('linkExpired'));
        setTokenStatus(ConfirmEmailStatus.expired);
        setEmail(userEmail);
      }
    } catch (e) {
      const { error } = await e;

      // addErrorToast(error);
    } finally {
      setIsConfirmationLoading(false);
    }
  }, [confirmationToken, login, t, openModal, setIsFirstLogin, setAuthData]);

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isConfirmationLoading,
    tokenStatus,
    email,
  };
};

export default useConfirmEmail;
