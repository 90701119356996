// libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Textarea } from 'components/Form/inputs';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import Button from 'components/Button';
import Form from 'components/Form';
import { FormData } from '../../Form/FormContext';
// styles
import styles from './CompanyDescription.module.scss';
// helpers
import { DESCRIPTION_VALIDATION_SCHEMA } from 'components/company/CompanyProfileForm/validation-rules';

type CompanyDescriptionProps = {
  handleCancelClick: () => void;
  handleSubmitClick: (data: FormData) => void;
  initialValue?: string;
};

const MAX_DESCRIPTION_LENGTH = 600;

const EditCompanyDescriptionForm: React.FC<CompanyDescriptionProps> = ({
  initialValue = '',
  handleCancelClick,
  handleSubmitClick,
}: CompanyDescriptionProps) => {
  const { t } = useTranslation('company');

  return (
    <Form
      data={{
        description: initialValue,
      }}
      submitForm={handleSubmitClick}
      validationSchema={DESCRIPTION_VALIDATION_SCHEMA}
    >
      <Textarea
        className={styles['editable-content']}
        maxLength={MAX_DESCRIPTION_LENGTH}
        name="description"
        placeholder=""
      />

      <div className={styles['edit-panel']}>
        <Button className={styles['cancel-button']} handleClick={handleCancelClick}>
          {t('cancel')}
        </Button>
        <SubmitButton className={styles['save-button']}>{t('saveChanges')}</SubmitButton>
      </div>
    </Form>
  );
};

export default EditCompanyDescriptionForm;
