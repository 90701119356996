enum Endpoints {
  SIGN_UP = 'SignUp',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  GENERATE_RESET_TOKEN = 'GenerateResetToken',
  CHANGE_PASSWORD = 'ChangePassword',
  KEEP_SESSION_ALIVE = 'KeepSessionAlive',
  PING = 'Ping',
  GET_MY_BUSINESS_PROFILES = 'GetMyBusinessProfiles',
  GET_USER_PROFILE = 'GetUserProfile',
  UPDATE_USER_PROFILE = 'UpdateUserProfile',
  UPDATE_USER_PREFERENCES = 'UpdateUserPreferences',
  STATIC_DATA_REQUEST = 'StaticDataRequest',
  COMPLETE_SIGN_UP_PROCESS = 'CompleteSignUpProcess',
  VERIFY_EMAIL_TOKEN = 'VerifyEmailToken',
  GET_BUSINESS_PROFILE = 'GetBusinessProfile',
  GET_BUSINESS_SNAPSHOT = 'GetBusinessSnapshot',
  SUBSCRIBE = 'Subscribe',
  GET_NOTIFICATION = 'Notification',
  ACCOUNTS_RECEIVABLE = 'AccountsReceivable',
  ACCOUNTS_PAYABLE = 'AccountsPayable',
  BALANCES = 'Balances',
}

export default Endpoints;
