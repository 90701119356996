// libraries
import React from 'react';
// assets
import { ReactComponent as HomeIcon } from 'assets/icons/home-icon.svg';
import { ReactComponent as HomeActiveIcon } from 'assets/icons/home-fill-icon.svg';
import { ReactComponent as ClientsIcon } from 'assets/icons/clients-icon.svg';
import { ReactComponent as ClientsActiveIcon } from 'assets/icons/clients-fill-icon.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-icon.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/setting-icon.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/chart-icon.svg';

export default [
  {
    name: 'Homepage',
    path: '/',
    exact: true,
    icon: <HomeIcon width="11" height="11" />,
    activeIcon: <HomeActiveIcon width="11" height="11" />,
  },
  {
    name: 'Contact List',
    path: '/contacts',
    exact: false,
    icon: <ClientsIcon width="10" height="10" />,
    activeIcon: <ClientsActiveIcon width="10" height="10" />,
  },
];

export const dropDownMenu = [
  {
    name: 'View Profile',
    path: '/profile',
    icon: <UserIcon width="9" height="9" />,
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: <SettingIcon width="10" height="10" />,
    prevPathEnable: true,
  },
  {
    name: 'Accounting',
    path: '/accounting',
    icon: <ChartIcon width="10" height="10" />,
  },
];
