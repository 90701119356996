// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Form from 'components/Form';
import EntryButton from 'components/EntryButton/EntryButton';
import OneTimeCodeInput from 'components/Form/inputs/OneTimeCodeInput/OneTimeCodeInput';
// styles
import styles from 'components/signUpForms/VerificationForm/VerificationForm.module.scss';
// helpers
import { VerificationFormData } from 'components/signUpForms/useSignUpForm';
import VERIFICATION_FORM_SCHEMA from 'components/signUpForms/VerificationForm/validation-rules';
// constants
import Endpoints from 'shared/constants/endpoints';

interface IVerificationFormProps {
  submitVerificationForm: (data: VerificationFormData) => void;
  verificationInputLabel?: string;
}

const VerificationForm: React.FC<IVerificationFormProps> = ({
  submitVerificationForm,
  verificationInputLabel,
}: IVerificationFormProps) => {
  const { t } = useTranslation('signUpPage');

  return (
    <Form
      formType={Endpoints.SIGN_UP}
      submitForm={data => submitVerificationForm(data as VerificationFormData)}
      validationSchema={VERIFICATION_FORM_SCHEMA}
      className="public-form-wrapper"
      autoComplete="off"
    >
      <div className={styles['fields-container']}>
        <OneTimeCodeInput
          label={verificationInputLabel || t('verificationForm.fields.verificationCode.label')}
          name="verificationCode"
          fieldLabelClassName={styles.form__label}
        />
      </div>
      <div className={styles.button__wrapper}>
        <EntryButton entryButtonClassName={styles.button}>{t('verificationForm.submitButton')}</EntryButton>
      </div>
    </Form>
  );
};

export default VerificationForm;
