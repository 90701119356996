// libraries
import React from 'react';
import classnames from 'classnames';
// styles
import styles from './TransactionCardField.module.scss';

interface ITransactionCardField {
  label: string;
  value: React.ReactNode;
  className?: string;
}

const TransactionCardField: React.FC<ITransactionCardField> = ({ label, value, className }: ITransactionCardField) => {
  return value ? (
    <div className={classnames(className, styles['transaction-card-field__wrapper'])}>
      <p className={styles['transaction-card-field__label']}>{label}</p>
      <p className={styles['transaction-card-field__value']}>{value}</p>
    </div>
  ) : null;
};

export default TransactionCardField;
