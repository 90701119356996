import { useCallback, useEffect } from 'react';
import { Invoice } from './types';
import { useStore } from 'shared/stores/RootStore/useStore';
import { getRandomMockItems } from 'components/invoices/InvoiceHistory/mockData';

export interface IInvoiceItemsData {
  invoiceItems: Nullable<Invoice[]>;
  updateInvoiceItem: (id: string, data: Partial<Invoice>) => void;
  deleteInvoiceItem: (id: string) => void;
  addInvoiceItem: (newInvoice: Partial<Invoice>) => void;
}

const useInvoiceItems = (): IInvoiceItemsData => {
  const {
    invoiceStore: { invoiceItems, setInvoiceItems },
  } = useStore();

  useEffect(() => {
    // TODO: need remove mock data after integration and add getting data from BE if store data is empty
    // @ts-ignore
    if (!invoiceItems.length) setInvoiceItems(getRandomMockItems());
  }, []);

  const updateInvoiceItem = useCallback(
    (invoiceId: string, data: Partial<Invoice> = {}) => {
      setInvoiceItems(
        invoiceItems.map(invoiceItem => {
          if (String(invoiceItem.invoiceId) === String(invoiceId)) {
            return {
              ...invoiceItem,
              ...data,
            };
          }

          return invoiceItem;
        }),
      );
    },
    [setInvoiceItems, invoiceItems],
  );

  const deleteInvoiceItem = useCallback(
    (invoiceId: string) => {
      setInvoiceItems(invoiceItems.filter(invoiceItem => !(String(invoiceItem.invoiceId) === String(invoiceId))));
    },
    [setInvoiceItems, invoiceItems],
  );

  const addInvoiceItem = useCallback(
    (newInvoice: Nullable<Partial<Invoice>>) => {
      if (!newInvoice) {
        return;
      }

      setInvoiceItems([...invoiceItems, newInvoice as Invoice]);
    },
    [setInvoiceItems, invoiceItems],
  );

  return {
    invoiceItems,
    updateInvoiceItem,
    deleteInvoiceItem,
    addInvoiceItem,
  };
};

export default useInvoiceItems;
