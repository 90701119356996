// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { TextInput } from 'components/Form/inputs';
import Form from 'components/Form';
import EntryButton from 'components/EntryButton/EntryButton';
// styles
import styles from 'components/signUpForms/EmailForm/EmailForm.module.scss';
// helpers
import { EmailFormData } from 'components/signUpForms/useSignUpForm';
import EMAIL_FORM_SCHEMA from 'components/signUpForms/EmailForm/validation-rules';

interface IEmailFormProps {
  submitEmailForm: (data: EmailFormData) => void;
}

const EmailForm: React.FC<IEmailFormProps> = ({ submitEmailForm }: IEmailFormProps) => {
  const { t } = useTranslation('signUpPage');

  return (
    <Form
      submitForm={data => submitEmailForm(data as EmailFormData)}
      validationSchema={EMAIL_FORM_SCHEMA}
      className="public-form-wrapper"
    >
      <div className={styles['fields-container']}>
        <TextInput
          name="email"
          label={t('emailForm.fields.email.label')}
          placeholder={t('emailForm.fields.email.placeholder')}
        />
      </div>
      <EntryButton>{t('emailForm.submitButton')}</EntryButton>
    </Form>
  );
};

export default EmailForm;
