// libraries
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// context
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// assets
import { ReactComponent as Pencil } from 'assets/icons/edit-pen.svg';
// styles
import styles from './Confirm.module.scss';

type TConfirmProps = {
  isAddFundsPopup: boolean;
  bankName?: string;
  bankAct?: string;
  amount: string;
};

const TransferConfirmation: React.FC<TConfirmProps> = ({
  isAddFundsPopup,
  amount,
  bankName = '',
  bankAct = '',
}: TConfirmProps) => {
  const { t } = useTranslation('accountingPage');

  const { goToPreviousStep } = useMultiStepContext();

  return (
    <>
      <div
        className={classNames(styles['info-confirm__wrapper'], {
          [styles['add-confirm-margin']]: isAddFundsPopup,
        })}
      >
        <div className={styles['confirm-item__wrapper']}>
          <span className={styles['confirm-item__name']}>{t('transferFunds.Amount')}:</span>
          <div className={styles['confirm-item__value']}>
            <span className={styles['confirm-item-amount__value']}>${amount}</span>
            <Pencil className={styles['confirm-amount-edit']} onClick={goToPreviousStep} />
          </div>
        </div>
        <div className={styles['confirm-item__wrapper']}>
          <span className={styles['confirm-item__name']}>
            {t('transferFunds.From')} {t('transferFunds.bank')}:
          </span>
          <div className={styles['confirm-item__value']}>
            <div className={styles['confirm-item-bank__value']}>{bankName}</div>
            <div className={styles['confirm-item-act__value']}>{bankAct.replace(/^.{4}/, 'xxxx')}</div>
          </div>
        </div>
      </div>

      {!isAddFundsPopup && <div className={styles['confirm-additional__text']}>{t('additionalText')}</div>}
    </>
  );
};

export default TransferConfirmation;
