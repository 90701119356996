// libraries
import React from 'react';
import classnames from 'classnames';
import { format, differenceInDays } from 'date-fns';
// helpers
import useFieldHelper from 'components/list/field/fieldHelper';
import { IFieldProps } from 'components/list/field';
import { DateFormats } from 'shared/helpers/format';
import { useFieldContext } from 'components/list/field/FieldContext';
import { InvoiceStates } from 'components/invoices/types';
import { completedStates } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
// styles
import './DueDateField.scss';

enum DueDateStatuses {
  completed = 'completed',
  closeToDate = 'closeToDate',
  overdue = 'overdue',
  default = 'default',
}

const PERIOD_FOR_CLOSE_TO_DATE = 6;

const DueDateField: React.FC<IFieldProps> = ({ source = '', label }: IFieldProps) => {
  const { getValue } = useFieldHelper({ source, label });
  const { data } = useFieldContext();
  const invoiceState = data.getValueBySource('invoiceState') as InvoiceStates;
  const now = Date.now();
  const invoiceDueDate = +getValue();
  let dueDateStatus = DueDateStatuses.default;

  if (completedStates.includes(invoiceState)) {
    dueDateStatus = DueDateStatuses.completed;
  } else {
    const daysDifference = differenceInDays(invoiceDueDate, now);

    if (daysDifference < 0) {
      dueDateStatus = DueDateStatuses.overdue;
    } else if (daysDifference < PERIOD_FOR_CLOSE_TO_DATE) {
      dueDateStatus = DueDateStatuses.closeToDate;
    }
  }

  return (
    <span key={invoiceDueDate} className={classnames('due-date__wrapper', dueDateStatus)}>
      {invoiceDueDate ? format(invoiceDueDate, DateFormats.invoiceDate) : ''}
    </span>
  );
};

export default DueDateField;
