import { makeAutoObservable } from 'mobx';
import RootStore from 'shared/stores/RootStore/RootStore';

export enum TransferType {
  add = 'Add',
  withdraw = 'Transfer',
}

class TransferFundsStore {
  transferType = TransferType.add;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setTransferToZuro = (): void => {
    this.transferType = TransferType.add;
  };

  setTransferToBank = (): void => {
    this.transferType = TransferType.withdraw;
  };
}

export default TransferFundsStore;
