// libraries
import React from 'react';
import classNames from 'classnames';
// styles
import styles from './FormFooter.module.scss';

type TFormFooterProps = {
  children?: React.ReactNode;
  className?: string;
};

const FormFooter: React.FC<TFormFooterProps> = ({ children, className = '' }: TFormFooterProps) => (
  <div className={classNames(styles.footer, className)}>{children}</div>
);

export default FormFooter;
