// libraries
import React from 'react';
import classNames from 'classnames';
// assets
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
// styles
import styles from './CloseButton.module.scss';

type CloseButtonProps = {
  handleClick: () => void;
  className?: string;
};

const CloseButton: React.FC<CloseButtonProps> = ({ handleClick, className = '' }: CloseButtonProps) => (
  <button className={classNames(className, styles['close-button'])} onClick={handleClick} aria-label="Close">
    <CloseIcon />
  </button>
);

export default CloseButton;
