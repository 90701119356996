// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
// components
import Breadcrumbs from 'components/Breadcrumbs';
import ProfileTopSection from 'components/ProfileTopSection/ProfileTopSection';
import CompanyDescription from 'components/company/CompanyDescription/CompanyDescription';
import InvoiceHistory from 'components/invoices/InvoiceHistory/InvoiceHistory';
import EmptyInvoiceHistoryMessage from 'components/invoices/InvoiceHistory/EmptyInvoiceHistoryMessage';
// helpers
import useCompanyDetails from './useCompanyDetails';
// styles
import styles from './CompanyDetails.module.scss';

const CompanyDetails: React.FC = () => {
  const { t } = useTranslation('company');
  const { id = '' } = useParams<Dictionary<string>>();
  const { companyProfile, error, invoices } = useCompanyDetails(id);

  if (error) {
    return <div className={classNames(styles.company__wrapper, styles.company__error)}>{t('companyNotFound')}</div>;
  }

  return (
    <>
      <Row className={styles['company-details-layout__wrapper']}>
        <Breadcrumbs />
        <Col xs={10} md={10}>
          <ProfileTopSection companyProfile={companyProfile} hasCreateInvoiceButton hasShortlistButton />
        </Col>
      </Row>
      <Row>
        <Col xs={10} md={6}>
          <CompanyDescription companyProfile={companyProfile} />
        </Col>
        <Col xs={10} md={4}>
          <InvoiceHistory
            title={`${t('transactionHistoryTitle')} ${companyProfile?.dbaName}`}
            data={invoices}
            emptyMessage={<EmptyInvoiceHistoryMessage description={t('transactionHistoryEmptyMessage')} />}
            className="company__history"
          />
        </Col>
      </Row>
    </>
  );
};

export default observer(CompanyDetails);
