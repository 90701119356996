// libraries
import { makeAutoObservable } from 'mobx';
// helpers
import { BankAccountsOptionType } from 'components/Form/inputs/BankAccountsInput/bankAccountsHelpers';
import { FilterTabs } from '../../../components/transactions/TransactionHistory/transactionHistoryHelpers';
// RootStore
import RootStore from 'shared/stores/RootStore/RootStore';

class TransactionStore {
  isLoading = false;

  transactionUpdated = false;

  transactionType: FilterTabs = FilterTabs.PAYABLE;

  isLoadingTransactionView = false;

  isAttachmentsVisible = true;

  paymentMethod: Nullable<Partial<BankAccountsOptionType>> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setIsLoading = (loadingState: boolean): void => {
    this.isLoading = loadingState;
  };

  clearTransactionDetails = (): void => {
    this.isAttachmentsVisible = true;
    this.paymentMethod = null;
  };

  setTransactionUpdated = (updatingStatus: boolean): void => {
    this.transactionUpdated = updatingStatus;
  };

  setPaymentMethod = (value: Nullable<Partial<BankAccountsOptionType>>): void => {
    this.paymentMethod = value;
  };
}

export default TransactionStore;
