// libraries
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { ReactComponent as FilledTrashIcon } from 'assets/icons/filled-trash-icon.svg';
// helpers
import { addErrorToast, addSuccessToast } from 'components/Toast/toastHelper';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
import { ButtonColor } from '../Button';
import stall, { getMockData } from 'shared/helpers/simulateAsyncCall';

type ConnectedAccounts = {
  isLoading: boolean;
  accounts: Account[];
  setData: () => void;
  deleteAccount: (id: number) => void;
  showConfirmationModal: (accountData: Account) => void;
  showPaymentMethodModal: () => void;
};

type ConnectedAccountsParams = {
  preloadData?: boolean;
};

const useConnectedAccounts = (props?: ConnectedAccountsParams): ConnectedAccounts => {
  const { t } = useTranslation('connectedAccountsPage');
  const { preloadData = true } = props || {};
  const [isLoading, setIsLoading] = useState(true);
  const {
    paymentMethods: { accounts, setAccounts, removeAccount },
    modalStore: { openModal },
  } = useStore();

  const setData = useCallback(async () => {
    if (accounts.length) {
      setIsLoading(false);
      return;
    }

    try {
      const accountsData: Account[] = await getMockData([]);
      setAccounts(accountsData);
    } catch (e) {
      const { error } = await e;
      addErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, [setAccounts, accounts.length]);

  useEffect(() => {
    if (preloadData) {
      setData();
    }
  }, [setData, preloadData]);

  const deleteAccount = useCallback(
    async (id: number, showMessageAfterDelete = false) => {
      try {
        await stall(id);
        removeAccount(id);

        if (showMessageAfterDelete) {
          addSuccessToast(t('deletionSuccessMessage'));
        }
      } catch (error) {
        const { message } = await error;
        addErrorToast(message);
      }
    },
    [removeAccount, t],
  );

  const showPaymentMethodModal = useCallback(() => {
    openModal(ModalIds.paymentMethod);
  }, [openModal]);

  const showConfirmationModal = ({ id, brand }: Account, showMessageAfterDelete = false) => {
    openModal(ModalIds.confirmationModal, {
      icon: <FilledTrashIcon width={36} height={36} />,
      title: `${t('deleteAccountConfirmation')} ${brand}?`,
      cbConfirmActionFunction: () => deleteAccount(id, showMessageAfterDelete),
      cancelButtonText: t('deleteCancelBtn'),
      confirmButtonText: t('deleteConfirmBtn'),
      confirmButtonProps: { color: ButtonColor.danger },
    });
  };

  return {
    isLoading,
    accounts,
    setData,
    deleteAccount,
    showConfirmationModal,
    showPaymentMethodModal,
  };
};

export default useConnectedAccounts;
