import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// components
import PayableReceivableContent from '../PayableReceivableContent';
import Button from 'components/Button';
// mock data
import { getMockCompanies } from 'components/company/CompanySearch/mockData';
// store
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from 'components/transactions/TransactionsDashboard/PayableReceivableContent/PayableReceivableContent.module.scss';

const PayableDashboard: React.FC = () => {
  const { t } = useTranslation('transactionHistory');
  // TODO: need update after integration with BE
  const vendors = getMockCompanies().slice(3, 9) as any;
  const history = useHistory();

  const {
    eventPayableStore: { payableData },
  } = useStore();

  return (
    <PayableReceivableContent
      title={t('dashboard.payableTitle')}
      pastDueAmount={payableData.pastDuePayableAmount}
      nextWeekAmount={payableData.nextWeekPayableAmount}
      companies={vendors}
      companiesTitle={t('dashboard.payableCompaniesTitle')}
      getCompanyLink={data => (
        <Button
          className={styles['payable-receivable__companies-link']}
          btnLink
          handleClick={() => {
            history.push(`/companies/${data.id}`);
          }}
        >
          {t('dashboard.profile')}
        </Button>
      )}
    />
  );
};

export default observer(PayableDashboard);
