import React from 'react';
import classnames from 'classnames';
import Input, { InputProps, InputTypes } from '../Input';
import InputErrors from '../InputErrors';
import styles from './RadioInput.module.scss';

export interface RadioInputProps extends Omit<InputProps, 'children'> {
  fieldWrapperClassName?: string;
  children?: React.ReactNode;
  label?: string | React.ReactNode;
}

const RadioInput: React.FC<RadioInputProps> = (props: RadioInputProps) => {
  const { name, label, value: defaultValue = '', children, fieldWrapperClassName = '', ...inputProps } = props;

  return (
    <Input {...inputProps} type={InputTypes.radio} name={name}>
      {({ errorMessages, inputCommonProps: { value, disabled, ...inputCommonProps } }) => (
        <div
          className={classnames(
            styles['field-wrapper'],
            { [styles['field-wrapper--disabled']]: disabled },
            fieldWrapperClassName,
          )}
        >
          <label className={styles['field-label']}>
            <input
              {...inputCommonProps}
              className={styles['radio-input']}
              type="radio"
              value={defaultValue}
              disabled={disabled}
              checked={String(value) === String(defaultValue)}
            />
            <div className={styles['radio-control']} />
            {label}
          </label>
          {errorMessages ? <InputErrors errorMessages={errorMessages} /> : null}
          {children}
        </div>
      )}
    </Input>
  );
};

export default RadioInput;
