// libraries
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
// constants
import { Invoice, InvoiceStates } from 'components/invoices/types';
// helpers
import useMultiStep from 'components/MultiStep/useMultiStep';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
import usePlaid, { PlaidHelper } from 'components/paymentMethods/PaymentMethod/PaymentOptions/usePlaid';
import { getMockData } from 'shared/helpers/simulateAsyncCall';
import { addErrorToast } from 'components/Toast/toastHelper';
import { MultiStepContextData } from 'components/MultiStep/MultiStepContext';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';

export enum AccountPayableStep {
  PAYMENT_METHOD = 0,
  REVIEW_INFORMATION = 1,
  COMPLETED_PAYMENT = 2,
}

type AccountReceivableParams = PlaidHelper & {
  currentStep: number;
  isCurrentStep: (step: number) => boolean;
  contextValue: MultiStepContextData;
  paymentAccount: Nullable<Account>;
  isSending: boolean;
  getPaid: () => void;
  openReviewPage: () => void;
  payDate: Nullable<Date>;
  setPayDate: (date: Date) => void;
};

export interface IPayment {
  invoiceDetails: Nullable<Invoice>;
  setInvoiceDetails: (data: Invoice) => void;
}

const useAccountPayable = ({ invoiceDetails }: IPayment): AccountReceivableParams => {
  const history = useHistory();
  const { token } = useParams<Dictionary<string>>();
  const contextValue = useMultiStep();
  const { currentStep, goToNextStep, isCurrentStep, goToStep } = contextValue;
  const [isSending, setIsSending] = useState(false);
  const [paymentAccount, setPaymentAccount] = useState<Nullable<Account>>(null);
  const [payDate, setPayDate] = useState<Nullable<Date>>(null);

  const addPaymentMethod = useCallback((account: Account) => {
    setPaymentAccount(account);
  }, []);

  const { openPlaidModal, isPlaidAvailable } = usePlaid({
    submitCallback: addPaymentMethod,
  });

  const getPaid = useCallback(async () => {
    setIsSending(true);
    try {
      // TODO: Update after integration with BE
      const companyData: IBusinessSnapshot = await getMockData({ token, paymentAccount, payDate, id: 3 });

      history.push(`/companies/${companyData.id}/public-profile`);
    } catch (e) {
      const { message } = await e;
      addErrorToast(message);
    } finally {
      setIsSending(false);
    }
  }, [history, paymentAccount, payDate, token]);

  const openReviewPage = useCallback(() => {
    goToNextStep(currentStep, {});
  }, [goToNextStep, currentStep]);

  useEffect(() => {
    if (invoiceDetails && invoiceDetails?.invoiceState !== InvoiceStates.pending) {
      goToStep(AccountPayableStep.COMPLETED_PAYMENT);
      return;
    }

    if (invoiceDetails?.payDate && invoiceDetails?.paymentAccount) {
      goToStep(AccountPayableStep.REVIEW_INFORMATION);
      setPaymentAccount(invoiceDetails?.paymentAccount || null);
      setPayDate((invoiceDetails?.payDate as Date) || null);
      return;
    }

    goToStep(AccountPayableStep.PAYMENT_METHOD);

    // Suppressed warnings because we should update data only if invoice details was updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetails]);

  return {
    contextValue,
    currentStep,
    isCurrentStep,
    openPlaidModal,
    isPlaidAvailable,
    paymentAccount,
    isSending,
    getPaid,
    payDate,
    setPayDate,
    openReviewPage,
  };
};

export default useAccountPayable;
