import React, { PropsWithChildren } from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
// components
import Form from '../../Form';
import { TextInput } from '../../Form/inputs';
import SubmitButton from '../../Form/buttons/SubmitButton';
// helpers
import useSubmitEmail from './useSubmitEmail';
import FORM_SCHEMA from './validation-rules';
// styles
import styles from './SubmitEmailForm.module.scss';

const SubmitEmailForm: React.FC<PropsWithChildren<{ email: string }>> = ({ email }) => {
  const { t } = useTranslation('submitEmail');
  const { submitForm, isSubmitted } = useSubmitEmail();

  if (isSubmitted) return <p>{t('submittedMessage')}</p>;

  return (
    <Form submitForm={submitForm} validationSchema={FORM_SCHEMA} data={{ email }}>
      <Row>
        <Col xs={12} className={styles['form-column']}>
          <TextInput
            label={t('formFields.email')}
            type="email"
            name="email"
            inputWrapperClassName={styles.input}
            fieldWrapperClassName={styles['input-wrapper']}
          />
          <SubmitButton className={styles.button}>{t('submitButton')}</SubmitButton>
        </Col>
      </Row>
    </Form>
  );
};

SubmitEmailForm.propTypes = {
  email: string.isRequired,
};

export default SubmitEmailForm;
