// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
// components
import CompletedDashboardItem from './CompletedDashboardItem';
// helpers
import { useListContext } from 'components/list/ListContext';
import { InvoiceTypes, FundsTransferTypes } from 'components/invoices/types';

const CompletedDashboard = () => {
  const { t } = useTranslation('transactionHistory');
  const { dataWithoutSearch = [] } = useListContext();

  const { payableAmount, receivableAmount } = useMemo(
    () =>
      dataWithoutSearch.reduce(
        (result, item) => {
          const transferType = get(item, 'transferType');
          const invoiceType = get(item, 'invoiceType');
          const isTransferFunds = invoiceType === InvoiceTypes.fundsTransfer;
          const isPayableInvoice = isTransferFunds
            ? transferType === FundsTransferTypes.transfer
            : invoiceType === InvoiceTypes.payable;
          const amount = +get(item, 'totalAmount', 0);

          return {
            payableAmount: result.payableAmount + (isPayableInvoice ? amount : 0),
            receivableAmount: result.receivableAmount + (isPayableInvoice ? 0 : amount),
          };
        },
        {
          payableAmount: 0,
          receivableAmount: 0,
        },
      ),
    [dataWithoutSearch],
  );

  return (
    <>
      <CompletedDashboardItem
        title={t('dashboard.completedReceivableTitle')}
        amount={receivableAmount}
        subtitle={t('dashboard.completedReceivableSubtitle')}
      />
      <CompletedDashboardItem
        title={t('dashboard.completedPayableTitle')}
        amount={payableAmount}
        subtitle={t('dashboard.completedPayableSubtitle')}
      />
      <CompletedDashboardItem
        title={t('dashboard.completedFlowTitle')}
        amount={receivableAmount - payableAmount}
        subtitle={t('dashboard.completedFlowSubtitle')}
      />
    </>
  );
};

export default CompletedDashboard;
