import { makeAutoObservable } from 'mobx';
import RootStore from 'shared/stores/RootStore/RootStore';
import { BeneficialOwner } from 'components/beneficial/useBeneficialOwners';

class BeneficialOwnersStore {
  beneficialOwnersCount = 0;

  currentBeneficialOwner: Nullable<BeneficialOwner> = null;

  beneficialOwnersList: BeneficialOwner[] = [];

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setBeneficialOwners = (ownersCount: number, ownersList: BeneficialOwner[]): void => {
    this.beneficialOwnersCount = ownersCount;
    this.beneficialOwnersList = ownersList;
  };

  setCurrentBeneficialOwner = (owner: Nullable<BeneficialOwner>): void => {
    if (!owner) {
      return;
    }

    this.currentBeneficialOwner = owner;
  };

  clearCurrentOwner = (): void => {
    this.currentBeneficialOwner = null;
  };
}

export default BeneficialOwnersStore;
