// libraries
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// helpers
import stall from 'shared/helpers/simulateAsyncCall';

export type EmailFormData = {
  email: string;
};

export type RegisterFormData = {
  password: string;
  legalName: string;
  firstName: string;
  lastName: string;
};

export type VerificationFormData = {
  verificationCode: string;
};

export type EmailChangeFormData = {
  email: string;
};

export enum SignUpFormStep {
  EMAIL,
  REGISTER,
  VERIFICATION,
  EMAIL_CHANGE,
}

interface SignFormData {
  currentStep: SignUpFormStep;
  handleEmailChange: () => void;
  email: string;
  submitEmailForm: (data: EmailFormData) => void;
  submitRegisterForm: (data: RegisterFormData) => Promise<void>;
  submitVerificationForm: (data: VerificationFormData) => void;
  submitEmailChangeForm: (data: EmailChangeFormData) => void;
}

const useSignUpForm = (): SignFormData => {
  const [currentStep, setCurrentStep] = useState(SignUpFormStep.EMAIL);
  const [email, setEmail] = useState<string>('');
  const history = useHistory();
  const { state = {} } = useLocation();
  const { data: stateData = {}, currentStep: stateCurrentStep = SignUpFormStep } = state || {};

  const submitEmailForm = useCallback(async (data: EmailFormData) => {
    await stall(data);
    setCurrentStep(SignUpFormStep.REGISTER);
    setEmail(data.email);
  }, []);

  const submitRegisterForm = useCallback(async (data: RegisterFormData) => {
    await stall(data);
    setCurrentStep(SignUpFormStep.VERIFICATION);
  }, []);

  const submitVerificationForm = useCallback(
    async (data: VerificationFormData) => {
      await stall(data);
      history.push('/sign-up/company');
    },
    [history],
  );

  const submitEmailChangeForm = useCallback(async (data: EmailChangeFormData) => {
    await stall(data);
    setCurrentStep(SignUpFormStep.VERIFICATION);
    setEmail(data.email);
  }, []);

  useEffect(() => {
    if (stateCurrentStep === SignUpFormStep.EMAIL) {
      setCurrentStep(SignUpFormStep.REGISTER);
      setEmail(stateData?.email);
    }
    // Suppressed warnings because we should update data only after first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailChange = useCallback(() => {
    setCurrentStep(SignUpFormStep.EMAIL_CHANGE);
  }, []);

  return {
    currentStep,
    handleEmailChange,
    email,
    submitEmailForm,
    submitRegisterForm,
    submitVerificationForm,
    submitEmailChangeForm,
  };
};

export default useSignUpForm;
