// libraries
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
// components
import AppLoading from 'components/AppLoading/AppLoading';
import BaseLayout from 'shared/layouts/basic';
import CompanyInfo from 'components/company/CompanyInfo/CompanyInfo';
import PayableReceivableContainer from 'components/PayableReceivable/PayableReceivableContainer';
import Balance from 'components/Balance/Balance';
import MarketingComponent from 'components/MarketingComponent';
import InAppNotifications from 'components/notifications/InApp/InAppNotifications';
import InvoiceHistory from 'components/invoices/InvoiceHistory/InvoiceHistory';
import EmptyInvoiceHistoryMessage from 'components/invoices/InvoiceHistory/EmptyInvoiceHistoryMessage';
// helpers
import useHome from 'router/views/Home/useHome';
import useMyBusinessProfile from 'components/company/CompanyProfileForm/useMyBusinessProfile';
// styles
import styles from './Home.module.scss';

const Home: React.FC = () => {
  const { myBusinessProfile } = useMyBusinessProfile();
  const { invoices } = useHome();
  // TODO: Need update after integration with BE
  const isLoaded = true;

  const getLayout = () => {
    return (
      <>
        <Row className={styles['finance-block__wrapper']}>
          <Col xs={10} lg={10}>
            <CompanyInfo company={myBusinessProfile} />
          </Col>
          <Col xs={10} md={6}>
            <Balance />
          </Col>
          <Col xs={10} md={4}>
            <PayableReceivableContainer />
          </Col>
        </Row>
        <Row>
          <Col xs={10} md={6}>
            <InAppNotifications />
          </Col>
          <Col xs={10} md={4}>
            <InvoiceHistory data={invoices} emptyMessage={<EmptyInvoiceHistoryMessage />} />
            <MarketingComponent />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <BaseLayout>
      <AppLoading isActive={!isLoaded}>{getLayout()}</AppLoading>
    </BaseLayout>
  );
};

export default observer(Home);
