import React, { useCallback, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
// components
import PublicLayout from 'shared/layouts/public/index';
import TwoFactorAuthForm from 'components/Auth/TwoFactorAuthForm/TwoFactorAuthForm';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import { VerificationStatues } from 'shared/stores/AuthStore/AuthStore';
import { TFA_PATHNAME } from '../../configs/auth';

const TwoFactorAuthWrapper: React.FC = () => {
  const {
    authStore: { removeSessionId, sessionId, verificationStatus },
  } = useStore();

  const history = useHistory();
  const { pathname } = useLocation();

  const clickLogoHandler = useCallback(() => {
    removeSessionId();
  }, [removeSessionId]);

  useEffect(() => {
    if (sessionId && verificationStatus === VerificationStatues.VERIFIED && pathname === TFA_PATHNAME) {
      history.push('/');
    }
  }, [sessionId, verificationStatus]);

  if (!sessionId) {
    return <Redirect to="/login" />;
  }

  return (
    <PublicLayout handleLogoClick={clickLogoHandler}>
      <TwoFactorAuthForm />
    </PublicLayout>
  );
};

export default observer(TwoFactorAuthWrapper);
