// libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config';
// helpers
import { addErrorToast } from '../../components/Toast/toastHelper';

export const loadPlaces = (callback: CallableFunction): void => {
  const isScriptExisting = document.getElementById('placesAutocomplete');

  if (isScriptExisting && callback) {
    callback();
    return;
  }

  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${config.apiKeys.googlePlaces}&libraries=places&language=en`;
  script.id = 'placesAutocomplete';
  document.body.appendChild(script);
  script.onload = () => {
    if (callback) callback();
  };
};

export const getPlacesPostCodeById = async (placeId: string): Promise<Nullable<string>> =>
  new Promise((resolve, reject) => {
    if (!placeId) reject(new Error('placeId not provided'));

    try {
      new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
        {
          placeId,
          fields: ['address_components'],
        },
        details => {
          let postcode = null;
          details?.address_components?.forEach(entry => {
            if (entry.types?.[0] === 'postal_code') {
              postcode = entry.long_name;
            }
          });
          return resolve(postcode);
        },
      );
    } catch (e) {
      reject(e);
    }
  });

type PlacesHelpers = {
  isPlacesLoaded: boolean;
  onError: (status: string, clearSuggestions: CallableFunction, name: string) => void;
};

export const usePlaces = (): PlacesHelpers => {
  const [isPlacesLoaded, setIsPlacesLoaded] = useState(false);
  const { t } = useTranslation(['companyProfileSettings', 'states', 'errors']);

  useEffect(() => {
    loadPlaces(() => {
      setIsPlacesLoaded(true);
    });
  }, []);

  const onError = (status: string, clearSuggestions: CallableFunction, label: string) => {
    let errorMessage = t(`errors:googleApi.${status}`);

    if (status === 'ZERO_RESULTS') {
      errorMessage = `${t(`errors:googleApi.${status}`)} '${t(`errors:${label}`)}' ${t(
        `errors:googleApi.${status}_SECOND_PART`,
      )}`;
    }

    // addErrorToast(errorMessage, t('toasts:errorTitle'));
    clearSuggestions();
  };

  return { isPlacesLoaded, onError };
};
