// libraries
import React from 'react';
// components
import DropdownButtonItem from 'components/Button/DropdownButton/DropdownButtonItem';

type TItemActionMenu = {
  onClick: (() => void) | undefined;
  logo: string;
  text: string;
  disabled?: boolean;
};

const ItemActionMenu: React.FC<TItemActionMenu> = ({ onClick, logo, text, disabled = false }: TItemActionMenu) => {
  return (
    <DropdownButtonItem onClick={onClick} disabled={disabled}>
      <img src={logo} alt="Icon" />
      {text}
    </DropdownButtonItem>
  );
};

export default ItemActionMenu;
