// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import TwoColumnRow from 'components/MultiStep/Form/TwoColumnRow/TwoColumnRow';
import FormFieldLabel from 'components/MultiStep/Form/FormFieldLabel/FormFieldLabel';
// helpers
import { DateFormats, formatAmountToLocal, formatDate } from 'shared/helpers/format';
// styles
import styles from './InvoiceDetails.module.scss';

type TInvoiceDetails = {
  amount?: number;
  dueDate?: number;
  description?: string;
  fieldWrapperClassName?: string;
  className?: string;
};

const InvoiceDetails: React.FC<TInvoiceDetails> = ({
  fieldWrapperClassName,
  className,
  amount,
  dueDate,
  description = '',
}: TInvoiceDetails) => {
  const { t } = useTranslation('invoice');
  return (
    <div className={classnames(styles['invoice-details'], className)}>
      <TwoColumnRow
        column1={<FormFieldLabel>{t('fields.amount.label')}</FormFieldLabel>}
        column2={
          <div className={styles['invoice-details__field-value']}>{amount && `${formatAmountToLocal(amount)}`}</div>
        }
        className={classnames(styles['invoice-details__field'], fieldWrapperClassName)}
      />
      <TwoColumnRow
        column1={<FormFieldLabel>{t('fields.dueDate.label')}</FormFieldLabel>}
        column2={
          <div className={styles['invoice-details__field-value']}>
            {dueDate && formatDate(new Date(dueDate), DateFormats.invoiceReviewDate)}
          </div>
        }
        className={classnames(styles['invoice-details__field'], fieldWrapperClassName)}
      />
      <TwoColumnRow
        column1={
          <FormFieldLabel
            helpText={`(${t('fields.description.helpText')})`}
            className={styles['invoice-details__field-label--description']}
          >
            {t('fields.description.label')}
          </FormFieldLabel>
        }
        column2={<div className={styles['invoice-details__field-value--description']}>{description}</div>}
        className={classnames(styles['invoice-details__field'], fieldWrapperClassName)}
      />
    </div>
  );
};

export default InvoiceDetails;
