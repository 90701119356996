// libraries
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
// components
import { addErrorToast } from 'components/Toast/toastHelper';
// helpers
import stall from 'shared/helpers/simulateAsyncCall';
// mock
import { addContact, editContact } from 'components/contacts/mockData';

type ParamsProps = {
  id?: string;
};

type RouterMatchProps = {
  params: ParamsProps;
};

const useContacts = () => {
  const history = useHistory();
  const { params = {} }: RouterMatchProps = useRouteMatch();

  const submitAddContact = useCallback(async contact => {
    try {
      await stall();

      addContact(contact);

      history.push('/contacts');
    } catch (e) {
      const { message } = await e;
      addErrorToast(message);
    }
  }, []);

  const submitEditContact = useCallback(async contact => {
    try {
      await stall();

      editContact(params.id, contact);

      history.push('/contacts');
    } catch (e) {
      const { message } = await e;
      addErrorToast(message);
    }
  }, []);

  return {
    submitAddContact,
    submitEditContact,
  };
};

export default useContacts;
