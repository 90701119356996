// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import get from 'lodash/get';
import { NavLink } from 'react-router-dom';
// helpers
import useCollapse, { CollapseItemData } from 'components/Collapse/useCollapse';
// styles
import './Collapse.scss';

interface CollapseProps {
  maxItemNumber: number;
  className?: string;
  mapItemFunc: (data: CollapseItemData) => React.ReactElement;
  data: CollapseItemData[];
  linkForViewAllBtn: string;
  title: string;
  idSource: string;
  emptyMessage?: React.ReactElement;
}

/**
 * This component renders collapsible block
 * @param {string} title - title of block
 * @param {CollapseItemData[]} data - items data
 * @param {number} maxItemNumber - the number of items displayed without the "view all" button
 * @param {(data: CollapseItemData) => React.ReactElement} mapItemFunc - function that return collapse items
 * @param {string} linkForViewAllBtn - url for redirect by click 'View all' button
 * @param {string} className
 * @param {string} idSource - key of id field in data
 * @return {any}
 * @constructor
 */
const Collapse: React.FC<CollapseProps> = ({
  title,
  data,
  maxItemNumber,
  mapItemFunc,
  linkForViewAllBtn,
  className,
  idSource,
  emptyMessage,
}: CollapseProps) => {
  const { t } = useTranslation('navigation');
  const { displayedData, numberOfItems, isCollapsed, parentBlockRef, itemsRefs } = useCollapse(maxItemNumber, data);

  return (
    <div ref={parentBlockRef} className={classnames('collapse__wrapper', className)}>
      <div className="collapse__title">{title}</div>
      {displayedData.length
        ? displayedData.map((itemData, index) => (
            <div key={`collapse-item-${get(itemData, idSource, index)}`} ref={itemsRefs[index]}>
              {mapItemFunc(itemData)}
            </div>
          ))
        : emptyMessage}

      {isCollapsed && (
        <div className="collapse-button__wrapper">
          <NavLink to={linkForViewAllBtn}>
            {t('viewAll')} ({numberOfItems})
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Collapse;
