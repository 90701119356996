// libraries
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';
import intersection from 'lodash/intersection';
// constants
import { ADMIN_ROLES } from 'shared/constants/userRoles';
import config from 'config';
// helpers
import { getCurrentDomain } from 'shared/helpers/url';
import usePopupNotification from '../notifications/PopupNotifications/usePopupNotification';
// RootStore
import { UserStoreData } from '../../shared/stores/UserStore/UserStore';
import { useStore } from '../../shared/stores/RootStore/useStore';

enum StartPage {
  HOME = '/',
  ACCOUNTING = '/accounting',
}

type AuthData = {
  setAuthData: (sessionId: string, user: UserStoreData, isRememberDeviceCheckboxShown?: boolean) => void;
  login: (sessionId?: string, userRoles?: string[], isVerifiedAccount?: boolean) => void;
  isAdminLoggedIn: () => boolean;
  isAdminReferrer: () => boolean;
};

const COOKIE_TOKEN_KEY = 'zuro-token';

const checkIsPlatformAdmin = (roles: string[]) => !!intersection(roles, ADMIN_ROLES).length;

const useAuth = (): AuthData => {
  const { t } = useTranslation('errors');
  const history = useHistory();
  const {
    authStore: { setSessionId, setSessionTimedOut, sessionId: storeSessionId },
    userStore: {
      setUser,
      role: storeUserRole,
      isVerifiedCompany: storeVerificationStatus,
      lastVisitedPage,
      setLastVisitedPage,
    },
    notificationsStore: { setPopupsLoaded },
  } = useStore();
  const { showNotifications } = usePopupNotification();

  const adminLogin = (sessionId: string) => {
    const adminUrl: string = config.adminPlatformUrl as string;
    const adminDomain: Nullable<string> = getCurrentDomain() || '';

    if (!adminDomain) {
      throw new Error(t('defaultError'));
    }

    cookie.set(COOKIE_TOKEN_KEY, sessionId, { domain: adminDomain });
    window.location.href = adminUrl;
  };

  const setAuthData = (sessionId: string, user: UserStoreData) => {
    setSessionId(sessionId);
    setUser(user);
  };

  const login = (
    sessionId = storeSessionId,
    userRoles = storeUserRole,
    isVerifiedAccount = storeVerificationStatus,
  ) => {
    const isPlatformAdmin = checkIsPlatformAdmin(userRoles);
    setSessionTimedOut(false);

    if (!isPlatformAdmin) {
      const verifiedAccountStartPage = lastVisitedPage || StartPage.ACCOUNTING;
      const startPage = isVerifiedAccount ? verifiedAccountStartPage : StartPage.HOME;

      setPopupsLoaded(false);
      history.push(startPage);
      showNotifications();
      setLastVisitedPage(null);
      return;
    }

    setPopupsLoaded(true);
    adminLogin(sessionId || '');
  };

  const isAdminLoggedIn = () => {
    return !!cookie.get(COOKIE_TOKEN_KEY);
  };

  const isAdminReferrer = () => {
    return document.referrer.startsWith(config.adminPlatformUrl as string);
  };

  return {
    setAuthData,
    login,
    isAdminLoggedIn,
    isAdminReferrer,
  };
};

export default useAuth;
