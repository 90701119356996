import { makeAutoObservable } from 'mobx';
import { persist } from 'mobx-persist';
import isEqual from 'lodash/isEqual';
import RootStore from 'shared/stores/RootStore/RootStore';
import { QuickBookData } from '../../../components/Integrations/api/IntegrationsApi';

class IntegrationsStore {
  @persist exportTransactionsFromDate: Nullable<string> = null;

  exportTransactionsData: Nullable<QuickBookData> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setExportTransactionsData = (data: Nullable<QuickBookData>): void => {
    if (isEqual(data, this.exportTransactionsData)) {
      return;
    }

    this.exportTransactionsData = data;
  };

  setExportTransactionsFromDate = (date: Nullable<string>): void => {
    this.exportTransactionsFromDate = date;
  };

  resetData = (): void => {
    this.exportTransactionsFromDate = null;
    this.exportTransactionsData = null;
  };
}

export default IntegrationsStore;
