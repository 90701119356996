// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EntryTitle from 'components/EntryTitle/EntryTitle';
import EmailForm from 'components/signUpForms/EmailForm/EmailForm';
import Tabs from 'components/externalPayments/Tabs/Tabs';
// helpers
import { EmailFormData } from '../useExternalPaymentForms';
import useTabs from 'components/externalPayments/Tabs/Tab/useTabs';
// constants
import styles from './Email.module.scss';

interface IEmailProps {
  submitEmailForm: (data: EmailFormData) => void;
}

const Email: React.FC<IEmailProps> = ({ submitEmailForm }: IEmailProps) => {
  const { t } = useTranslation('externalPaymentPage');
  const { activeTab, changeTab } = useTabs();

  return (
    <div className={styles['left-column']}>
      <EntryTitle
        link="/login"
        linkText={t('emailForm.linkText')}
        subtitle={t('emailForm.subtitle')}
        title={t('emailForm.title')}
      />
      <div className={styles.tabs}>
        <Tabs activeTab={activeTab} changeTab={changeTab} />
      </div>
      <EmailForm submitEmailForm={submitEmailForm} />
    </div>
  );
};

export default Email;
