// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { TextInput } from 'components/Form/inputs';
import EntryButton from 'components/EntryButton/EntryButton';
import AutocompleteInput from 'components/Form/inputs/AutocompleteInput/AutocompleteInput';
// constants
import { useFormContext } from 'components/Form/FormContext';
// helpers
import areFieldsFullfilled from 'shared/helpers/inputs';
// styles
import styles from 'components/verification/Business/Business.module.scss';

const currentPageFields = ['legalName', 'bizAddress'];

const BusinessDetailsFields: React.FC = () => {
  const { t } = useTranslation(['verification', 'forms']);
  const { data } = useFormContext();

  const isButtonDisabled = useMemo(() => !areFieldsFullfilled(data, currentPageFields), [data]);

  return (
    <>
      <div className={styles['fields-container']}>
        <TextInput
          name="legalName"
          label={t('verification:businessDetails.businessDetailsForm.fields.legalName.label')}
          placeholder={t('verification:businessDetails.businessDetailsForm.fields.legalName.placeholder')}
        />
        <AutocompleteInput
          name="bizAddress"
          label={t('verification:businessDetails.businessDetailsForm.fields.bizAddress.label')}
          namePrefix=""
          errorLabel={t('address.businessAddress')}
          placeholder={t('verification:businessDetails.businessDetailsForm.fields.bizAddress.placeholder')}
        />
      </div>

      <EntryButton disabled={isButtonDisabled} entryButtonClassName={styles.button}>
        {t('forms:continueButtonDefaultText')}
      </EntryButton>
    </>
  );
};

export default BusinessDetailsFields;
