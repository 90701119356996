// libraries
import React from 'react';
import classNames from 'classnames';
// components
import SubtitleWithLink from 'components/EntryTitle/subtitles/SubtitleWithLink/SubtitleWithLink';
import Subtitle from 'components/EntryTitle/subtitles/Subtitle/Subtitle';
// styles
import styles from 'components/EntryTitle/EntryTitle.module.scss';

interface IEntryTitleProps {
  title: string;
  subtitle: string;
  linkText?: string;
  link?: string;
  email?: string;
  customClass?: string;
  subtitleEnding?: string;
}

const EntryTitle: React.FC<IEntryTitleProps> = ({
  link = '',
  linkText = '',
  subtitle,
  title,
  email = '',
  customClass = '',
  subtitleEnding = '',
}: IEntryTitleProps) => (
  <div className={classNames(styles['entry-title__container'], customClass)}>
    <h1 className={styles.title}>{title}</h1>
    {link ? (
      <SubtitleWithLink subtitle={subtitle} linkText={linkText} link={link} />
    ) : (
      <Subtitle subtitle={subtitle} email={email} subtitleEnding={subtitleEnding} />
    )}
  </div>
);

export default EntryTitle;
