// libraries
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
// components
import TwoColumnRow from 'components/MultiStep/Form/TwoColumnRow/TwoColumnRow';
import FormFieldLabel from 'components/MultiStep/Form/FormFieldLabel/FormFieldLabel';
import { TextInput } from 'components/Form/inputs';
import CompanyCard from 'components/company/SearchBar/CompanyCard/CompanyCard';
import SearchBar from 'components/company/SearchBar/SearchBar';
// helpers
import useCloseByOutsideAction from 'shared/helpers/useCloseByOutsideAction';
// hooks
import useSearchBar from 'components/contacts/ContactsForm/ContactFormFields/useSearchBar';
// context
import { useFormContext } from 'components/Form/FormContext';
// styles
import styles from 'components/contacts/ContactsForm/ContactFormFields/index.module.scss';

const ContactFormFields = () => {
  const { t } = useTranslation('contactPages');
  const { state: { contact = {} } = {} } = useLocation();
  const { setFieldValues = () => {}, getFieldValue = () => {} } = useFormContext();

  const isContactExist = !isEmpty(contact);

  const {
    businessValue,
    foundCompanies,
    handleSelect,
    handleSearch,
    isSearchOpen,
    isListScrollable,
    isNewVendor,
    isDisabledInput,
  } = useSearchBar(contact);

  const amountOfFoundCompanies = foundCompanies.length;
  const isCompaniesFound = !amountOfFoundCompanies;
  const businessNameFieldValue = getFieldValue('businessName');

  const isDisabledInputClassName = classNames(styles.input, {
    [styles['input--disabled']]: isDisabledInput,
  });

  const companyListContainerClassName = classNames(styles['company-list__container'], {
    [styles['company-list__container--active']]: isSearchOpen,
    [styles['scroll--hidden']]: !isListScrollable,
    [styles['company-list__container--hidden']]: isCompaniesFound,
  });

  const scrollBarClassName = classNames(styles.scrollbar, {
    [styles['scrollbar--hidden']]: isCompaniesFound,
  });

  const newVendorClassName = classNames(styles['new-vendor'], {
    [styles['new-vendor--hidden']]: !isNewVendor,
  });

  const businessNameClassName = classNames(styles['business-input'], {
    [styles['business-input__new-vendor']]: isNewVendor,
  });

  useEffect(() => {
    if (!isContactExist) return;

    setFieldValues('businessName', contact.businessDetails.name);
    setFieldValues('contactName', contact.contactName);
    setFieldValues('contactEmail', contact.email);
    setFieldValues('tag', contact.businessDetails.tag);
    setFieldValues('logo', contact.businessDetails.logo);
    setFieldValues('isExistingBusiness', contact.isExistingBusiness);
  }, []);

  return (
    <div className={styles['fields-container']}>
      <TwoColumnRow
        column1={<FormFieldLabel>{t('form.fields.businessName.label')}</FormFieldLabel>}
        column2={
          <SearchBar
            name="businessName"
            isLoaded
            onSearch={handleSearch}
            closeOnClick={useCloseByOutsideAction}
            inputValue={businessValue}
            companyNameValue={businessNameFieldValue}
            companiesLength={amountOfFoundCompanies}
            additionalInputText={<p className={newVendorClassName}>{t('form.fields.businessName.additionalText')}</p>}
            companyListContainerClassName={companyListContainerClassName}
            className={businessNameClassName}
            scrollBarClassName={scrollBarClassName}
            newBusinessWrapperClassName={styles['new-business__wrapper']}
            iconClassName={styles['business-input__icon']}
          >
            {foundCompanies.map(company => (
              <CompanyCard
                key={company.id}
                onClickCallback={() =>
                  handleSelect(company.dbaName, company.email, company.contactName, company.logoImage, company.handle)
                }
                company={company}
                value={businessValue}
              />
            ))}
          </SearchBar>
        }
      />
      <TwoColumnRow
        column1={<FormFieldLabel>{t('form.fields.contactName.label')}</FormFieldLabel>}
        column2={
          <TextInput
            disabled={isDisabledInput}
            name="contactName"
            placeholder={t('form.fields.contactName.placeholder')}
            className={classNames(styles['contact-form__input'], isDisabledInputClassName)}
          />
        }
      />
      <TwoColumnRow
        column1={<FormFieldLabel>{t('form.fields.contactEmail.label')}</FormFieldLabel>}
        column2={
          <TextInput
            disabled={isDisabledInput}
            name="contactEmail"
            placeholder={t('form.fields.contactEmail.placeholder')}
            className={classNames(styles['contact-form__input'], isDisabledInputClassName)}
          />
        }
      />
    </div>
  );
};

export default ContactFormFields;
