// api
import Crud, { ListParams } from 'shared/api/crud/Crud';
import { ResponsePromise } from 'shared/api/AbstractRequestManager';
import { HttpMethod } from 'shared/api/AbstractRequest';
import PublicRequest from 'shared/api/public/PublicRequest';
// helpers
import { buildQueryString } from 'shared/helpers/api';
// RootStore
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';

export enum AccountType {
  CREDIT_CARD = 'cc',
  DEBIT_CARD = 'dc',
  BANK = 'ach',
}

export type NewCreditCard = Partial<{
  token: string;
}>;

export type NewBankAccount = Partial<{
  publicToken: string;
  accountId: string;
}>;

export type PlaidLinkData = {
  linkToken: string;
};

type NewAccount = NewCreditCard | NewBankAccount;

class PaymentMethodApi extends Crud {
  constructor() {
    super('/payment-methods');
  }

  readonly plaidLinkUrl = '/plaid/link';

  readonly publicPlaidLinkUrl = '/public/plaid/link';

  connectAccount = (type: AccountType, data: NewAccount): Promise<Account> => {
    const resourceUri = `${this.resourceUrl}/${type}`;
    const params = { body: { ...data }, method: HttpMethod.POST };

    return this.getRequest(resourceUri, params).send() as Promise<Account>;
  };

  getPlaidLink = (): Promise<PlaidLinkData> => {
    const resourceUri = `${this.resourceUrl}${this.plaidLinkUrl}`;
    const params = { method: HttpMethod.GET };
    return this.getRequest(resourceUri, params).send() as Promise<PlaidLinkData>;
  };

  getPublicPlaidLink = (params?: ListParams): ResponsePromise => {
    const queryString = params ? buildQueryString(params) : '';
    const plaidUri = `${this.resourceUrl}${this.publicPlaidLinkUrl}${queryString}`;

    return new PublicRequest(plaidUri, { method: HttpMethod.GET }).send();
  };
}

export default new PaymentMethodApi();
