// libraries
import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
// components
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
// helpers
import { getTimeSince } from 'shared/helpers/date';
import { NotificationTypes, Notification } from 'shared/stores/NotificationsStore/NotificationsStore';

const InAppNotification: React.FC<Notification> = ({
  iconInBase64,
  type,
  message,
  notificationTime,
  notificationId,
  markedRead,
  shortCardClassName = '',
  logoClassName = '',
  ...rest
}: Notification) => {
  const history = useHistory();

  const getOnClickCallback = (notificationType: NotificationTypes) => {
    if (notificationType === NotificationTypes.invoiceReceived || notificationType === NotificationTypes.invoiceSent) {
      return () => {
        history.push('/accounting');
      };
    }

    if (notificationType === NotificationTypes.officialCommunication) {
      return null;
    }

    return () => {
      history.push('/');
    };
  };

  return (
    <ShortEntityCard
      className={classNames(markedRead ? '' : 'active', shortCardClassName)}
      key={notificationId}
      onClickCallback={getOnClickCallback(type)}
      // TODO: make element when will be integration with BE
      message={message}
      helpText={getTimeSince(notificationTime)}
      logo={<EntityLogo logoSrc={iconInBase64} className="in-app-notifications__logo" imageClassName={logoClassName} />}
      {...rest}
    />
  );
};

export default InAppNotification;
