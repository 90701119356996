// libraries
import React from 'react';
// styles
import styles from './ProfileTopSection.module.scss';
// components
import InfoHeader, { IInfoHeaderProps } from 'components/company/CompanyInfo/InfoHeader/InfoHeader';
// assets
import { ReactComponent as EditPen } from 'assets/icons/edit-pen.svg';

interface ITopSectionProps extends IInfoHeaderProps {
  isEditable?: boolean;
  hasCreateInvoiceButton?: boolean;
  hasBillButton?: boolean;
}

const ProfileTopSection: React.FC<ITopSectionProps> = ({ isEditable = false, companyProfile = null, ...props }) => {
  return (
    <div className={styles['top-section-container']}>
      <div className={styles['banner-poster']}>
        {companyProfile?.bannerImage && (
          <img src={companyProfile?.bannerImage} alt="Banner" className={styles['poster-image']} />
        )}
        {isEditable && (
          // TODO: add eventHandler when image upload and cropping are possible
          <button className={styles['edit-banner-button']} disabled>
            <EditPen className={styles['edit-banner-button__icon']} />
          </button>
        )}
      </div>
      <InfoHeader {...props} companyProfile={companyProfile} isEntityProfile isEditable={isEditable} />
    </div>
  );
};

export default ProfileTopSection;
