import { useEffect } from 'react';
// store
import { useStore } from '../stores/RootStore/useStore';

const useDisableScroll = () => {
  const {
    modalStore: { modalIds },
  } = useStore();

  useEffect(() => {
    const html = document.querySelector('html');

    if (!html) {
      return;
    }

    if (modalIds.length) {
      html.style.overflowY = 'hidden';
    } else {
      html.style.overflowY = 'auto';
    }
  }, [modalIds.length]);
};

export default useDisableScroll;
