import React from 'react';
import { Switch, RouteProps } from 'react-router-dom';
// helpers
import { mapRoute } from './helpers';

export interface Route extends RouteProps {
  name: string;
  isProtected?: boolean;
}

export type RoutesConfig = {
  routes: Route[];
};

export type RoutesRenderer = JSX.Element;
export type RenderedRoute = JSX.Element;

const Routes = ({ routes }: RoutesConfig): RoutesRenderer => {
  return <Switch>{routes.map(mapRoute)}</Switch>;
};

export default Routes;
