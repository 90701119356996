const EXTENSION_REGEXP = /^.*?\.([a-zA-Z0-9]+)$/;
const FILENAME_REGEXP = /filename="(?<filename>.*)"/;

const downloadFromUrl = (url: string, fileName: string): void => {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.download = fileName || '';

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const getFileExtension = (fileName: string): string => fileName.replace(EXTENSION_REGEXP, '$1');

export const getContentDispositionFileName = (response: Response): string => {
  const contentDispositionHeader = response.headers.get('Content-Disposition') || '';
  const fileName = FILENAME_REGEXP.exec(contentDispositionHeader)?.groups?.filename || '';

  return fileName;
};

export default downloadFromUrl;

/**
 * This function checks if the background color of the image matches the color from the function parameters
 * @param {string} imageInBase64
 * @param {string} color
 * @param {(isDesiredColor: boolean) => void} callback
 */
export const checkBackgroundColor = (
  imageInBase64: string,
  color: string,
  callback: (isDesiredColor: boolean) => void,
): void => {
  const image = new Image();
  image.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context?.drawImage(image, 0, 0);

    const imageData = context?.getImageData(0, 0, canvas.width, canvas.height) || {};

    /**
     * Check pixel color by coordinates
     * @param {number} x
     * @param {number} y
     * @return {boolean}
     */
    const isDesiredColor = (x: number, y: number) => {
      const index = (y * imageData.width + x) * 4;
      const red = imageData.data[index];
      const green = imageData.data[index + 1];
      const blue = imageData.data[index + 2];

      return `${red}, ${green}, ${blue}` === color;
    };

    callback(isDesiredColor(0, 0));
  };
  image.src = imageInBase64;
};
