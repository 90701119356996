// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import LoadingBar from 'components/AppLoading/LoadingBar/LoadingBar';
// helpers
import { TLoadingBarParams } from 'components/AppLoading/LoadingBar/useLoadingBar';
// styles
import styles from './InvoicePreparing.module.scss';

type TInvoicePreparing = Pick<TLoadingBarParams, 'loadingBarProgress'>;

const InvoicePreparing: React.FC<TInvoicePreparing> = ({ loadingBarProgress }: TInvoicePreparing) => {
  const { t } = useTranslation('invoice');

  return (
    <div className={styles['invoice-preparing']}>
      <div className={styles['invoice-preparing__text']}>{t('preparingInvoice')}</div>
      <LoadingBar loadingProgress={loadingBarProgress} />
    </div>
  );
};

export default InvoicePreparing;
