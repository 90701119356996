// libraries
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
// components
import BaseLayout from 'shared/layouts/basic';
import InternalInvoice from 'components/invoices/InternalInvoice/InternalInvoice';
import ExternalInvoice from 'components/invoices/ExternalInvoice/ExternalInvoice';

const CreateInvoice: React.FC = () => {
  const { state: { invoice = null, companyInfo = null, isExternalInvoice = false } = {} } = useLocation();

  if (!companyInfo) {
    return <Redirect to="/companies" />;
  }

  if (isExternalInvoice) {
    return (
      <BaseLayout>
        <ExternalInvoice customer={companyInfo.customer} />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <InternalInvoice companyInfo={companyInfo} invoice={invoice} />
    </BaseLayout>
  );
};

export default CreateInvoice;
