// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import VerificationForm from 'components/resetPassword/VerificationForm/VerificationForm';
import EntryTitle from 'components/EntryTitle/EntryTitle';
// types
import { VerificationFormData } from 'components/resetPassword/usePasswordResetForm';
// styles
import styles from 'router/views/ResetPassword/ResetPasswordFlow/Verification/Verification.module.scss';
import resetPasswordStyles from 'router/views/ResetPassword/index.module.scss';

interface IVerificationProps {
  email: string;
  handleEmailChange: () => void;
  submitVerificationForm: (data: VerificationFormData) => void;
}

const Verification: React.FC<IVerificationProps> = ({
  email,
  handleEmailChange,
  submitVerificationForm,
}: IVerificationProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <>
      <EntryTitle
        title={t('verification.title')}
        subtitle={t('verification.subtitle')}
        email={email}
        customClass={resetPasswordStyles['entry-title__wrapper']}
      />
      <div className={styles['verification-form__container']}>
        <VerificationForm submitVerificationForm={submitVerificationForm} />
        <p className={styles.link__wrapper}>
          <span className={styles.link__text}>{t('verification.wrongEmailText')}</span>
          <span>&nbsp;</span>
          <button className={styles.link__button} onClick={handleEmailChange}>
            {t('verification.wrongEmailLink')}
          </button>
        </p>
      </div>
    </>
  );
};

export default Verification;
