// libraries
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
// assets
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as GroupClientsIcon } from 'assets/icons/clients-group.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
// components
import CompanyQuickActionItem from './CompanyQuickActionItem/CompanyQuickActionItem';
import SearchBar from 'components/company/SearchBar/SearchBar';
import CompanyCard from '../SearchBar/CompanyCard/CompanyCard';
import ShortListModal from '../../ShortLists/ShortListModal';
// helpers
import useCompanySearch from 'components/company/CompanySearch/useCompanySearch';
import useCloseByOutsideAction from 'shared/helpers/useCloseByOutsideAction';
import { ShortListType } from 'shared/stores/ShortListsStore/ShortListsStore';
// styles
import styles from './CompanySearch.module.scss';

interface QuickActionsItem {
  icon: React.ReactElement;
  title: string;
  clientsNumber: number;
  modalType: ShortListType;
}

const CompanySearch: React.FC = () => {
  const { t } = useTranslation('companySearch');
  const {
    companies,
    isLoaded,
    onSearch,
    isOpenSearch,
    searchQuery,
    isScrollableList,
    createInvoiceForNewBusiness,
  } = useCompanySearch();
  const history = useHistory();

  // TODO: Need update after integration with BE
  const quickActionsItems: QuickActionsItem[] = [
    {
      icon: <SaveIcon width={24} height={24} />,
      title: t('quickActionsItems.topClients'),
      clientsNumber: 7,
      modalType: ShortListType.topClients,
    },
    {
      icon: <GroupClientsIcon width={35} height={35} />,
      title: t('quickActionsItems.shortlist'),
      clientsNumber: 8,
      modalType: ShortListType.shortList,
    },
    {
      icon: <ClockIcon width={28} height={28} />,
      title: t('quickActionsItems.recentPayments'),
      clientsNumber: 10,
      modalType: ShortListType.recentPayments,
    },
  ];

  const companyListContainerClassName = classNames(styles['company-list__container'], {
    [styles['company-list__container--active']]: isOpenSearch,
    [styles['scroll--hidden']]: !isScrollableList,
  });

  const companyActionsWrapperClassName = classNames(styles['company-actions__wrapper'], {
    [styles['company-actions__wrapper--blur']]: isOpenSearch,
  });

  const onCompanyClickHandler = (id?: string) => {
    history.push(`/companies/${id}`);
  };

  return (
    <>
      <div className={styles['company-search']}>
        <div className={styles['company-search__container']}>
          <div className={styles['company-search__wrapper']}>
            <h1 className={styles['company-search__title']}>{t('pageTitle')}</h1>
            <SearchBar
              name="search"
              onSearch={onSearch}
              companiesLength={companies.length}
              isLoaded={isLoaded}
              companyListContainerClassName={companyListContainerClassName}
              companyListTitle={t('companyList.title')}
              inputValue={searchQuery}
              newBusinessWrapperClassName={styles['new-business__wrapper']}
              closeOnESC={useCloseByOutsideAction}
              className={styles['search-input']}
              searchInputContainerClassName={styles['search-input__container']}
              addNewBusinessOnClickHandler={createInvoiceForNewBusiness}
            >
              {companies.map(company => (
                <CompanyCard
                  key={company.id}
                  onClickCallback={() => onCompanyClickHandler(company.id)}
                  company={company}
                  value={searchQuery}
                  additionalInfo={t('companyList.visitProfile')}
                />
              ))}
            </SearchBar>
          </div>
          <div className={styles['company-actions']}>
            <h3 className={styles['company-actions__title']}>{t('quickActionsItems.title')}</h3>
            <div className={companyActionsWrapperClassName}>
              {quickActionsItems.map(elem => (
                <CompanyQuickActionItem
                  key={elem.title}
                  icon={elem.icon}
                  title={elem.title}
                  modalType={elem.modalType}
                  clientsNumber={elem.clientsNumber}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ShortListModal />
    </>
  );
};

export default observer(CompanySearch);
