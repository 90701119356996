// libraries
import { useCallback, useEffect, useState } from 'react';
// helpers
import { Document } from 'shared/stores/DocumentStore/DocumentStore';
import { useStore } from 'shared/stores/RootStore/useStore';
// mock
import getDocuments from './mockDataDocuments';

type CompanyDocumentsHelper = {
  isLoading: boolean;
  documents: Nullable<Document[]>;
};

const useCompanyDocuments = (): CompanyDocumentsHelper => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    documentStore: { documents, setDocuments },
  } = useStore();

  const loadPageData = useCallback(async () => {
    setIsLoading(true);

    // TODO: Update after BE integration
    const data = getDocuments();

    setDocuments(data);
    // // const { rows = [], total_items: totalItems = 0 } = (await submitRequest(PAGE_SIZE, page)) as List<Document>;
    //
    // if (totalItems <= PAGE_SIZE * page) {
    //   setHasMoreItems(false);
    // }
    //
    // setDocuments(prevState => [...prevState, ...rows]);
    // setPageCount(Math.ceil(totalItems / PAGE_SIZE));
    //
    setIsLoading(false);
  }, [setDocuments]);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  return {
    isLoading,
    documents,
  };
};

export default useCompanyDocuments;
