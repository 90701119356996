// libraries
import React from 'react';
import classNames from 'classnames';
// styles
import styles from './SettingsBlockTitle.module.scss';

type SettingsBlockTitleProps = {
  titleBlock: string;
  className?: string;
};

const SettingsBlockTitle: React.FC<SettingsBlockTitleProps> = ({
  titleBlock = '',
  className,
}: SettingsBlockTitleProps) => {
  return <div className={classNames(styles['settings-form__title-block'], className)}>{titleBlock}</div>;
};

export default SettingsBlockTitle;
