// libraries
import { computed, makeAutoObservable } from 'mobx';
// helpers
import { IBusinessSnapshot } from './types';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';
import VerificationStatus from 'shared/constants/verificationStatus';
import { VerificationStep } from 'components/verification/verificationHelpers';
// RootStore
import RootStore from 'shared/stores/RootStore/RootStore';

class BusinessSnapshotStore {
  myBusinessProfile: Nullable<IBusinessSnapshot> = null;

  autoDownloadDocumentIds: number[] = [];

  shouldUpdateDocuments = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  addDocumentToAutoDownload = (id: number): void => {
    this.autoDownloadDocumentIds.push(id);
  };

  removeDocumentFromAutoDownload = (id: number): void => {
    this.autoDownloadDocumentIds = this.autoDownloadDocumentIds.filter(documentId => documentId !== id);
  };

  updateDocuments = (status: boolean): void => {
    this.shouldUpdateDocuments = status;
  };

  updateVerificationStatus = (status: VerificationStatus): void => {
    // @ts-ignore
    this.myBusinessProfile = {
      ...this.myBusinessProfile,
      verificationStatus: status,
    };
  };

  updateNextVerificationStep = (nextVerificationStep: VerificationStep): void => {
    // @ts-ignore
    this.myBusinessProfile = {
      ...this.myBusinessProfile,
      nextVerificationStep,
    };
  };

  @computed get isVerifiedCompany(): boolean {
    const verificationStatus = VerificationStatus.VERIFIED.toUpperCase();

    return this.myBusinessProfile?.verificationStatus?.toUpperCase() === verificationStatus;
  }

  setDataFromMessage = (data: IBusinessSnapshot): void => {
    this.myBusinessProfile = {
      ...this.myBusinessProfile,
      ...data,
    };
  };

  [CLEAR_STORE] = (): void => {
    this.myBusinessProfile = null;
  };
}

export default BusinessSnapshotStore;
