// libraries
import { makeAutoObservable } from 'mobx';
// helpers
import { ImageForCrop } from 'components/ImageCropper/useImageCropper';
// RootStore
import RootStore from 'shared/stores/RootStore/RootStore';

class ImageCropperStore {
  image: Nullable<ImageForCrop> = null;

  croppedImage: Nullable<File> = null;

  isFileSavedInForm = false;

  imageForPreview: Nullable<string> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setImage = (image: Nullable<ImageForCrop>): void => {
    this.image = image;
  };

  setCroppedImage = (image: Nullable<File>): void => {
    this.croppedImage = image;
  };

  handleSavingInForm = (): void => {
    this.isFileSavedInForm = true;
    this.image = null;
    this.croppedImage = null;
  };

  setImageForPreview = (image: Nullable<string>): void => {
    this.imageForPreview = image;
  };

  resetCropperStore = (): void => {
    this.isFileSavedInForm = false;
    this.image = null;
    this.croppedImage = null;
    this.imageForPreview = null;
  };
}

export default ImageCropperStore;
