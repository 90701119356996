import i18n from 'i18next';
import AbstractRequestManager, { HttpStatus } from '../AbstractRequestManager';
import { store } from 'shared/stores/RootStore/RootStore';

class ProtectedRequestManager extends AbstractRequestManager {
  handleError = (error: Response) => {
    const { pathname } = window.location;

    if (error.status === HttpStatus.UNAUTHORIZED) {
      const {
        authStore: { removeSessionId, setSessionTimedOut },
        userStore: { removeUser, setLastVisitedPage },
        modalStore: { closeAllModals },
        notificationsStore: { resetNotificationData },
        verificationStore: { resetVerificationData },
        transactionStore: { clearTransactionDetails },
      } = store;

      setLastVisitedPage(pathname);
      setSessionTimedOut();
      removeUser();
      removeSessionId();
      closeAllModals();
      resetNotificationData();
      resetVerificationData();
      clearTransactionDetails();

      return Promise.reject(new Error(i18n.t('errors:sessionExpired')));
    }

    return Promise.reject(error.json());
  };
}

export default new ProtectedRequestManager();
