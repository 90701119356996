// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Form from 'components/Form';
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import FormFooter from 'components/MultiStep/Form/FormFooter/FormFooter';
import { TextInput } from 'components/Form/inputs';
import TwoColumnRow from 'components/MultiStep/Form/TwoColumnRow/TwoColumnRow';
import FormFieldLabel from 'components/MultiStep/Form/FormFieldLabel/FormFieldLabel';
// constants
import CUSTOMER_FORM_SCHEMA from 'components/invoices/ExternalInvoice/NewCustomerForm/validation-rules';
// helpers
import { FormData } from 'components/Form/FormContext';
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
import { ExternalInvoiceStep } from 'components/invoices/useCreateInvoice';
// styles
import styles from 'components/invoices/ExternalInvoice/NewCustomerForm/NewCustomerForm.module.scss';

export type TNewCustomerFormProps = {
  handleReturn: () => void;
  customerName?: string;
};

const NewCustomerForm: React.FC<TNewCustomerFormProps> = ({
  handleReturn,
  customerName = '',
}: TNewCustomerFormProps) => {
  const { t } = useTranslation(['invoice', 'forms']);
  const { goToNextStep, steps = [] } = useMultiStepContext();

  const handleSubmit = useCallback(
    (data: FormData) => {
      if (goToNextStep) {
        goToNextStep(ExternalInvoiceStep.NEW_CUSTOMER, data);
      }
    },
    [goToNextStep],
  );

  const initialData = steps[ExternalInvoiceStep.NEW_CUSTOMER] || {
    customer: customerName || '',
  };

  return (
    <Form submitForm={handleSubmit} validationSchema={CUSTOMER_FORM_SCHEMA} data={initialData}>
      <div className={styles['customer-form__body']}>
        <TwoColumnRow
          column1={<FormFieldLabel>{t('fields.customer.label')}</FormFieldLabel>}
          column2={
            <TextInput
              name="customer"
              placeholder={t('fields.customer.placeholder')}
              className={styles['customer-form__input']}
            />
          }
          className={styles['customer-form__field']}
        />
        <TwoColumnRow
          column1={<FormFieldLabel>{t('fields.contactName.label')}</FormFieldLabel>}
          column2={
            <TextInput
              name="contactName"
              placeholder={t('fields.contactName.placeholder')}
              className={styles['customer-form__input']}
            />
          }
          className={styles['customer-form__field']}
        />
        <TwoColumnRow
          column1={<FormFieldLabel>{t('fields.contactEmail.label')}</FormFieldLabel>}
          column2={
            <TextInput
              name="contactEmail"
              placeholder="example@example.com"
              className={styles['customer-form__input']}
            />
          }
          className={styles['customer-form__field']}
        />
      </div>
      <FormFooter>
        <Button outline handleClick={handleReturn} className={styles['customer-form__button']}>
          {t('forms:backButtonDefaultText')}
        </Button>
        <SubmitButton className={styles['customer-form__button']}>{t('forms:continueButtonDefaultText')}</SubmitButton>
      </FormFooter>
    </Form>
  );
};

export default NewCustomerForm;
