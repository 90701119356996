// libraries
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import AddBillPage from './AddBillPage/AddBillPage';
import AddBillForm from './AddBillForm/AddBillForm';
import AddBillFormReview from './AddBillFormReview/AddBillFormReview';
import Breadcrumbs from 'components/Breadcrumbs';
import useMultiStep from 'components/MultiStep/useMultiStep';
// helpers
import useAddBill from './useAddBill';
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
import { SelectedCompanyInfoProps, AddBillFormStep } from './types';
import VerificationStatus from 'shared/constants/verificationStatus';
// styles
import styles from './AddBill.module.scss';

const AddBill: React.FC = () => {
  const { t } = useTranslation('addBillPage');
  const history = useHistory();

  const [companyInfo, setCompanyInfo] = useState<SelectedCompanyInfoProps>({
    logo: '',
    isNewVendor: false,
    verificationStatus: VerificationStatus.UNVERIFIED,
  });
  const { onSubmitReview } = useAddBill();
  const contextValue = useMultiStep(AddBillFormStep.ADD_BILL_FORM_CONFIRM);
  const { currentStep, goToPreviousStep, isCurrentStep } = contextValue;

  const handleReturn = () => {
    if (currentStep && goToPreviousStep) {
      goToPreviousStep();

      return;
    }

    history.go(-1);
  };

  const wrapperClassName = classNames(styles['add-bill-page__wrapper'], {
    [styles['add-bill-review-page__wrapper']]: isCurrentStep(AddBillFormStep.ADD_BILL_FORM_CONFIRM),
  });

  return (
    <AddBillPage wrapperClassName={wrapperClassName}>
      <MultiStepProvider value={contextValue}>
        <div className={styles['add-bill__return-btn']}>
          <Breadcrumbs text={t('returnButton')} onClick={handleReturn} />
        </div>
        {isCurrentStep(AddBillFormStep.ADD_BILL_FORM) && (
          <AddBillForm
            handleReturn={handleReturn}
            currentStep={AddBillFormStep.ADD_BILL_FORM}
            setCompanyInfo={setCompanyInfo}
          />
        )}
        {isCurrentStep(AddBillFormStep.ADD_BILL_FORM_CONFIRM) && (
          <AddBillFormReview onSubmitReview={onSubmitReview} companyInfo={companyInfo} />
        )}
      </MultiStepProvider>
    </AddBillPage>
  );
};

export default observer(AddBill);
