// libraries
import React, { Dispatch, SetStateAction } from 'react';
import SimpleBar from 'simplebar-react';
// components
import ContactActionButton from 'components/contacts/ContactsTable/ContactActionButton';
// helpers
import useFieldHelper from 'components/list/field/fieldHelper';
// types
import { IFieldProps } from 'components/list/field';
// styles
import styles from './index.module.scss';

interface IContactNameColumn extends IFieldProps {
  scrollContainerRef: React.MutableRefObject<SimpleBar>;
  setContactsAmount: Dispatch<SetStateAction<number>>;
  page: string;
}

const ContactNameColumn = ({ source = '', label, scrollContainerRef, setContactsAmount, page }: IContactNameColumn) => {
  const { getData } = useFieldHelper({ source, label });

  const contact = getData();

  return (
    <div className={styles['contact-name__container']}>
      <p className={styles['contact-name']}>{contact.contactName}</p>
      <ContactActionButton
        setContactsAmount={setContactsAmount}
        scrollContainerRef={scrollContainerRef}
        page={page}
        contact={contact}
      />
    </div>
  );
};

export default ContactNameColumn;
