// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
// assets
import { ReactComponent as RocketIcon } from 'assets/icons/rocket-icon.svg';
import { ReactComponent as AddBillIcon } from 'assets/icons/paper-add.svg';
// components
import Avatar, { AvatarType } from 'components/Avatar/Avatar';
import LinkButton from 'components/Button/LinkButton';
import Button, { ButtonColor } from 'components/Button';
import ShortlistButton from 'components/ShortlistButton/ShortlistButton';
import VerificationStar from 'components/company/CompanyInfo/InfoHeader/VerificationStar';
import EditButton from 'components/EditButton/EditButton';
// styles
import styles from './InfoHeader.module.scss';
// helpers
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import VerificationStatus from 'shared/constants/verificationStatus';
import { normalizeUrlHref } from 'shared/helpers/format';
// store
import { useStore } from 'shared/stores/RootStore/useStore';
// constants
import { ModalIds } from 'shared/constants/modalIds';

export interface IInfoHeaderProps {
  companyProfile: Nullable<IBusinessSnapshot>;
  isEntityProfile?: boolean;
  hasShortlistButton?: boolean;
  isEditable?: boolean;
  hasCreateInvoiceButton?: boolean;
  hasBillButton?: boolean;
}

const InfoHeader: React.FC<IInfoHeaderProps> = ({
  companyProfile = null,
  hasCreateInvoiceButton,
  isEntityProfile,
  hasShortlistButton,
  isEditable,
  hasBillButton,
}: IInfoHeaderProps) => {
  const { t } = useTranslation('company');
  const history = useHistory();
  const {
    modalStore: { openModal },
  } = useStore();

  const handleEditButtonClick = useCallback(() => {
    openModal(ModalIds.editProfileForm, null);
  }, [openModal]);

  const createInvoice = useCallback(() => {
    history.push('/create-invoice', {
      companyInfo: companyProfile,
    });
  }, [companyProfile, history]);

  return (
    <div className={isEntityProfile ? styles['header__internal-profile'] : styles.header}>
      <div
        className={
          isEntityProfile ? styles['header__general-company-info-column'] : styles['header__general-company-info-row']
        }
      >
        <Avatar avatarUrl={companyProfile?.logoImage} type={AvatarType.big} className={styles.header__logo} />
        <div className={styles.header__container}>
          <span className={styles.header__title}>
            {companyProfile?.dbaName}
            <VerificationStar
              isVerified={companyProfile?.verificationStatus === VerificationStatus.VERIFIED}
              className={styles.verification_star}
            />
            {isEditable && (
              <div className={styles['header__button-container']}>
                <EditButton handleOnClick={handleEditButtonClick} />
              </div>
            )}
            {hasShortlistButton && (
              <div className={styles['header__button-container']}>
                <ShortlistButton />
              </div>
            )}
          </span>
          <p className={styles.header__handle}>{companyProfile?.handle}</p>
          <p className={styles.header__location}>
            {companyProfile?.industry || ''}
            {companyProfile?.locationCity
              ? `${companyProfile?.industry ? ' • ' : ''} ${companyProfile?.locationCity}`
              : ''}
            {companyProfile?.registeredSince
              ? `${companyProfile?.industry || companyProfile?.locationCity ? ' • ' : ''} ${t('registeredSince')} ${
                  companyProfile?.registeredSince
                }`
              : ''}
          </p>
          {isEntityProfile && companyProfile?.website && (
            <a
              href={`${normalizeUrlHref(companyProfile?.website) || ''}`}
              className={styles.header__link}
              target="_blank"
              rel="noreferrer"
            >
              {companyProfile?.website}
            </a>
          )}
        </div>
      </div>
      {!isEditable && hasCreateInvoiceButton && (
        <Button
          color={ButtonColor.primary}
          icon={<RocketIcon className={styles['create-invoice-btn-icon']} />}
          className={classNames(styles['link-btn'], styles['create-invoice-link-btn'])}
          handleClick={createInvoice}
        >
          {t('createInvoiceButton')}
        </Button>
      )}
      {hasBillButton && (
        <LinkButton
          color={ButtonColor.primary}
          url="/billing"
          icon={<AddBillIcon className={styles['add-bill-btn-icon']} />}
          className={classNames(styles['link-btn'], styles['add-bill-link-btn'])}
        >
          {t('addBillButton')}
        </LinkButton>
      )}
    </div>
  );
};

export default InfoHeader;
