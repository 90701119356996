// api
import PublicRequest from 'shared/api/public/PublicRequest';
import ProtectedRequest from 'shared/api/protected/ProtectedRequest';
import { HttpMethod } from 'shared/api/AbstractRequest';
import { ResponsePromise } from 'shared/api/AbstractRequestManager';
import { Entity } from 'shared/api/crud/Crud';
// helpers
import { TfaTypes } from '../TwoFactorAuthForm/twoFactoAuthFormHelper';

class AuthApi {
  readonly confirmEmailUrl = '/confirm-email/verify';

  readonly submitEmailUrl = '/confirm-email/send';

  readonly verifyTfaUrl = '/verification';

  confirmEmail = (data: Entity): ResponsePromise => {
    const params = { body: { ...data }, method: HttpMethod.POST };

    return new PublicRequest(this.confirmEmailUrl, params).send();
  };

  submitEmail = (data: Entity): ResponsePromise => {
    const params = { body: { ...data }, method: HttpMethod.POST };

    return new PublicRequest(this.submitEmailUrl, params).send();
  };

  verifyTfaCode = (data: Entity, type: TfaTypes): ResponsePromise => {
    const params = { body: { ...data }, method: HttpMethod.PUT };

    return new ProtectedRequest(`${this.verifyTfaUrl}/${type}`, params).send();
  };

  sendNewTfaCode = (type: TfaTypes): ResponsePromise => {
    const params = { body: {}, method: HttpMethod.POST };

    return new ProtectedRequest(`${this.verifyTfaUrl}/${type}`, params).send();
  };
}

export default new AuthApi();
