import { USA_COUNTRY } from 'shared/helpers/countries';
import { FormData, FormFieldValue } from 'components/Form/FormContext';

export type SubmittedAddress = {
  address: FormFieldValue;
};

export const prepareSubmittedAddress = (data: FormData): SubmittedAddress => {
  const isUSA = data.country === USA_COUNTRY;

  const newAddress: FormData = {
    line1: data.line1 as string,
    city: data.city as string,
    country: data.country as string,
  };

  if (isUSA) {
    newAddress.zip = data.zip as string;
    newAddress.state = data.state as string;
  } else {
    newAddress.postalCode = data.postalCode as string;
    newAddress.region = data.region as string;
  }

  return { address: newAddress as FormFieldValue };
};
