// libraries
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
// components
import CompanyDescription from 'components/company/CompanyDescription/CompanyDescription';
import BaseLayout from 'shared/layouts/basic';
import Breadcrumbs from 'components/Breadcrumbs';
import ProfileTopSection from 'components/ProfileTopSection/ProfileTopSection';
import EmptyInvoiceHistoryMessage from 'components/invoices/InvoiceHistory/EmptyInvoiceHistoryMessage';
import InvoiceHistory from 'components/invoices/InvoiceHistory/InvoiceHistory';
// helpers
import useHome from 'router/views/Home/useHome';
// assets
import useCompanyDetails from 'components/company/CompanyDetails/useCompanyDetails';

const EditProfile: React.FC = () => {
  const { companyProfile } = useCompanyDetails();
  const { invoices } = useHome();

  return (
    <BaseLayout>
      <Row>
        <Breadcrumbs />
        <Col xs={10} md={10}>
          <ProfileTopSection companyProfile={companyProfile} isEditable hasBillButton />
        </Col>
        <Col xs={10} md={6}>
          <CompanyDescription companyProfile={companyProfile} isEditable />
        </Col>
        <Col xs={10} md={4}>
          <InvoiceHistory data={invoices} emptyMessage={<EmptyInvoiceHistoryMessage />} className="company__history" />
        </Col>
      </Row>
    </BaseLayout>
  );
};

export default observer(EditProfile);
