import { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// api
import { Entity } from 'shared/api/crud/Crud';
import { splitStringAndConvertToUppercase } from 'shared/helpers/api';
// RootStore
import { UserStoreData } from 'shared/stores/UserStore/UserStore';
import { useStore, useSelectFromStoreByEndpoint } from 'shared/stores/RootStore/useStore';
import { useWebsocketContext } from 'shared/websocket/websocketContext';
// constants
import Endpoints from 'shared/constants/endpoints';
import MessageTypes from 'shared/constants/messageTypes';
import { checkUser } from './mockData';
import stall from 'shared/helpers/simulateAsyncCall';
import { IAuthStore } from 'shared/stores/AuthStore/types';

export type LoginData = {
  token: string;
  user: UserStoreData;
  loginVerified: boolean;
  isRememberEnabled: boolean;
};

interface SignFormData {
  submitForm: (data: Entity) => Promise<void>;
  apiError: string;
  setApiError: Dispatch<SetStateAction<string>>;
  isFetching: boolean;
  attemptsRemaining: number;
  onChangeHandler: () => void;
}

const useLoginForm = (): SignFormData => {
  // @ts-ignore
  const { sessionId, failureReason, isFetching, removeFailureReason } = useSelectFromStoreByEndpoint(Endpoints.LOGIN);
  const { sendMessage } = useWebsocketContext();
  const history = useHistory();

  const {
    authStore: { setDataFromMessage },
    userStore: { setUserEmail },
  } = useStore();

  const [apiError, setApiError] = useState('');
  const [attemptsRemaining, setAttemptsRemaining] = useState<number>(0);

  useEffect(() => {
    if (!isFetching) {
      setApiError(splitStringAndConvertToUppercase(failureReason));
    }
  }, [failureReason, isFetching]);

  const submitForm = async ({ email, password }: Entity) => {
    setApiError('');

    // TODO: temporary measure. Need remove setDataFromMessage, checkUser and uncomment sendMessage after integration
    // @ts-ignore
    // sendMessage(MessageTypes.REQUEST, Endpoints.LOGIN, {
    //   authenticationType: 'Default',
    //   id: email,
    //   password,
    //   ...(sessionId && { sessionId }),
    // });
    const userData: IAuthStore = checkUser({ email, password });
    setDataFromMessage(userData);
    // @ts-ignore
    setUserEmail(email);
    const { failureReason: errorMessage, noOfAttemptsRemaining } = userData;
    setAttemptsRemaining(noOfAttemptsRemaining);

    if (noOfAttemptsRemaining === 0) {
      setAttemptsRemaining(0);
      setApiError('');
      const userEmail = {
        email,
      };

      await stall(1000);

      history.push('/password-reset', { userEmail });
      removeFailureReason();
      return;
    }

    if (errorMessage && noOfAttemptsRemaining !== 0) {
      setApiError(errorMessage);
    }
  };

  const onChangeHandler = useCallback(() => {
    setAttemptsRemaining(5);
  }, []);

  return {
    submitForm,
    apiError,
    setApiError,
    isFetching,
    attemptsRemaining,
    onChangeHandler,
  };
};

export default useLoginForm;
