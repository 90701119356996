import React, { memo } from 'react';
import { Container } from 'react-bootstrap';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation('homePage');

  return (
    <footer className={styles.footer}>
      <Container className="layout__container p-0">
        <div className="layout__container-wrapper">{`${t('footer')} Hopscotch`}</div>
      </Container>
    </footer>
  );
};

export default memo(Footer);
