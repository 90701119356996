// libraries
import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
// styles
import styles from './AppLoading.module.scss';

type AppLoadingProps = PropsWithChildren<{
  isActive?: boolean;
  className?: string;
  wrapperClassName?: string;
  overlayClassName?: string;
  spinnerClassName?: string;
  hideContent?: boolean;
}>;

const AppLoading: React.FC<AppLoadingProps> = ({
  children = null,
  isActive = false,
  className = '',
  wrapperClassName = '',
  overlayClassName = '',
  spinnerClassName = '',
  hideContent = false,
}) => {
  const spinner = (
    <div className={classNames(styles.spinner, className)} role="status">
      {[...Array(9)].map((val, index) => (
        <div
          /* eslint-disable-next-line react/no-array-index-key */
          key={`sk-circle-${index}`}
          className={classNames(styles.spinner__item, styles[`spinner__item--${index}`])}
        />
      ))}
    </div>
  );

  if (!children) {
    return (
      <div className={classNames('d-flex justify-content-center', styles.spinner__wrapper, wrapperClassName)}>
        {spinner}
      </div>
    );
  }

  return (
    <div>
      {children}
      <div
        className={classNames(
          styles['app-loading__overlay'],
          {
            [styles['app-loading__overlay--filled']]: hideContent,
            [styles['app-loading__overlay--hidden']]: !isActive,
          },
          overlayClassName,
        )}
      />
      <div
        className={classNames(
          styles['app-loading__spinner-wrapper'],
          {
            [styles['app-loading__spinner-wrapper--hidden']]: !isActive,
          },
          spinnerClassName,
        )}
      >
        {spinner}
      </div>
    </div>
  );
};

export default AppLoading;
