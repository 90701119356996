// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// context
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// styles
import styles from './ModalTitleWithStep.module.scss';

type TModalTitleWithStepProps = {
  titlePopupText: string;
  image: string;
  width?: number;
  height?: number;
  lastStep?: number;
  stepTitleClassName?: string;
};

const ModalTitleWithStep: React.FC<TModalTitleWithStepProps> = ({
  titlePopupText,
  image,
  width = 20,
  height = 16,
  lastStep = 2,
  stepTitleClassName,
}: TModalTitleWithStepProps) => {
  const { currentStep } = useMultiStepContext();
  const { t } = useTranslation('modalSteps');

  return (
    <div className={styles.title__wrapper}>
      <div className={styles['title__info-wrapper']}>
        <img src={image} alt="plus" width={width} height={height} />
        <span className={styles.title}>{titlePopupText}</span>

        <span className={classNames(styles['title-steps'], stepTitleClassName)}>
          {t('Step')} {currentStep && currentStep} of {lastStep}
        </span>
      </div>
    </div>
  );
};

export default ModalTitleWithStep;
