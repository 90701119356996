// libraries
import { ConfirmCardPaymentData } from '@stripe/stripe-js';
// helpers
import { COUNTRIES_STRIPE, USA_COUNTRY, USA_COUNTRY_CODE } from 'shared/helpers/countries';
import { AddressFormData, CardFormData } from 'components/paymentMethods/StripeForm/useStripeForm';

export type BillingDetails = {
  name: string;
  address: {
    country: string;
    city: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  };
};

type StripeCardData = AddressFormData & CardFormData;

export const getCountryCode = (country: string): string => {
  if (country === USA_COUNTRY) {
    return USA_COUNTRY_CODE;
  }

  const countryCode = COUNTRIES_STRIPE.find(el => country === el.label)?.value;

  return countryCode || USA_COUNTRY_CODE;
};

export const prepareCardPaymentData = ({
  cardData,
  country = '',
  postalCode = '',
  lastName = '',
  firstName = '',
  state = '',
  city = '',
  line1 = '',
  region = '',
  zip = '',
}: StripeCardData): ConfirmCardPaymentData => {
  const name = `${firstName} ${lastName}`.trim().toUpperCase() || '';
  const isUsa = country === USA_COUNTRY;

  return {
    payment_method: {
      card: cardData,
      billing_details: {
        name,
        address: {
          country: getCountryCode(country),
          city,
          line1,
          line2: '',
          postal_code: isUsa ? zip : postalCode,
          state: isUsa ? state : region,
        },
      },
    },
  };
};
