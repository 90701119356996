// libraries
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import Button, { ButtonColor } from 'components/Button';
// styles
import styles from './ReceivableInvoiceDetails.module.scss';

interface IReceivableInvoiceDetails {
  handleClick: () => void;
}
const MarkNotReceivedButton: React.FC<IReceivableInvoiceDetails> = ({ handleClick }: IReceivableInvoiceDetails) => {
  const { t } = useTranslation('transactionHistory');
  const [isMouseInside, setIsMouseInside] = useState(false);

  const mouseEnter = () => {
    setIsMouseInside(true);
  };

  const mouseLeave = () => {
    setIsMouseInside(false);
  };

  return (
    <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <Button
        handleClick={handleClick}
        color={ButtonColor.danger}
        outline
        className={classnames(styles['mark-not-received-btn'], {
          [styles['not-active']]: !isMouseInside,
        })}
      >
        {isMouseInside ? t('markNotReceived') : t('markedReceived')}
      </Button>
    </div>
  );
};

export default MarkNotReceivedButton;
