// libraries
import { makeAutoObservable } from 'mobx';
// rootStore
import RootStore from 'shared/stores/RootStore/RootStore';
// constants
import { ButtonProps } from 'components/Button';

export type ModalData = {
  icon?: React.ReactElement | string;
  title?: string;
  classNameTitle?: string;
  content?: string;
  classNameContent?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonProps?: ButtonProps;
  cbConfirmActionFunction: CallableFunction;
  cbCancelActionFunction?: CallableFunction;
};

class ModalStore {
  modalIds: string[] = [];

  userOpenModal = false;

  modalData: Nullable<ModalData> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  openModal = (id: string, modalData?: Nullable<ModalData>): void => {
    this.modalIds.push(id);
    this.userOpenModal = true;

    if (modalData) {
      this.modalData = modalData;
    }
  };

  closeModal = (id: string): void => {
    this.modalIds = this.modalIds.filter(modalId => modalId !== id);
    this.userOpenModal = false;
  };

  closeAllModals = (): void => {
    this.modalIds = [];
    this.userOpenModal = false;
  };

  isOpen = (id: string): boolean => {
    return this.modalIds.includes(id);
  };
}

export default ModalStore;
