// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { TextInput } from 'components/Form/inputs';
import Form from 'components/Form';
import EntryButton from 'components/EntryButton/EntryButton';
// styles
import styles from 'components/signUpForms/EmailChangeForm/EmailChangeForm.module.scss';
// helpers
import { EmailChangeFormData } from 'components/signUpForms/useSignUpForm';
import EMAIL_CHANGE_FORM_SCHEMA from 'components/signUpForms/EmailChangeForm/validation-rules';

interface IEmailChangeFormProps {
  submitEmailChangeForm: (data: EmailChangeFormData) => void;
}

const EmailChangeForm: React.FC<IEmailChangeFormProps> = ({ submitEmailChangeForm }: IEmailChangeFormProps) => {
  const { t } = useTranslation('signUpPage');

  return (
    <Form
      submitForm={data => submitEmailChangeForm(data as EmailChangeFormData)}
      validationSchema={EMAIL_CHANGE_FORM_SCHEMA}
      className="public-form-wrapper"
    >
      <div className={styles['fields-container']}>
        <TextInput
          name="email"
          label={t('emailForm.fields.email.label')}
          placeholder={t('emailForm.fields.email.placeholder')}
        />
      </div>
      <EntryButton>{t('emailForm.submitButton')}</EntryButton>
    </Form>
  );
};

export default EmailChangeForm;
