// libraries
import React, { useEffect, useState } from 'react';
import ReactSelect, { OptionTypeBase, ValueType, MenuPlacement } from 'react-select';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import { InputCommonProps } from '../Input';
import TextInput, { TextInputProps } from '../TextInput/TextInput';
// helpers
import { useFormContext } from '../../FormContext';
// styles
import { customStyles, SelectSize } from './customStyles';
import './Select.scss';

export const selectSizes: SelectSize = {
  sm: '225px',
  m: '265px',
  xl: '300px',
};

export interface SelectProps extends Omit<TextInputProps, 'onChangeCallback'> {
  options: Dictionary<string>[];
  isSearchable?: boolean;
  isFormField?: boolean;
  isClearable?: boolean;
  menuPlacement?: MenuPlacement;
  listSize?: string;
  defaultValue?: string;
  onChangeCallback?: (value: string) => void;
}

const Select: React.FC<SelectProps> = ({ onChangeCallback = () => {}, ...props }: SelectProps) => {
  const { t } = useTranslation('forms');
  const {
    className,
    options,
    isSearchable = false,
    isFormField = false,
    isClearable = false,
    menuPlacement = 'auto',
    placeholder,
    listSize = selectSizes.xl,
    defaultValue,
  } = props;
  const { setFieldValues = () => {}, validateForm, isDirty } = useFormContext();
  let initialValue: Nullable<OptionTypeBase> = null;

  const [selectedOption, setSelectedOption] = useState<Nullable<OptionTypeBase>>(null);
  const [isValueUpdated, setIsValueUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (validateForm && isValueUpdated) {
      validateForm();
    }
  }, [selectedOption, validateForm, isValueUpdated]);

  const InputElement = ({ onBlur, onFocus, ...inputCommonProps }: InputCommonProps) => {
    const selectValue = inputCommonProps.value || defaultValue;

    const handleChange = (option: ValueType<OptionTypeBase, false>) => {
      setSelectedOption(option as Dictionary<string>);
      setIsValueUpdated(true);

      setFieldValues(inputCommonProps.name, option?.value as string);

      onChangeCallback(option?.value || '');
    };

    if (selectValue !== selectedOption?.value && (!isValueUpdated || !isDirty)) {
      initialValue = options[options.findIndex(x => x.value === selectValue)];
      setSelectedOption(initialValue);
    }

    useEffect(() => {
      if (defaultValue) {
        setIsValueUpdated(true);
        setFieldValues(inputCommonProps.name, defaultValue);
      }
    }, [inputCommonProps.name]);

    return (
      <ReactSelect
        {...inputCommonProps}
        value={selectedOption}
        options={options}
        onChange={handleChange}
        styles={customStyles({ listSize })}
        defaultValue={initialValue}
        isSearchable={isSearchable}
        className={classNames('select', className, inputCommonProps.className, { 'select__form-field': isFormField })}
        classNamePrefix={isFormField ? 'select__form-field' : 'select'}
        menuPlacement={menuPlacement}
        placeholder={placeholder || t('selectPlaceholder')}
        hideSelectedOptions
        menuPortalTarget={document.body}
        isClearable={isClearable}
      />
    );
  };

  return <TextInput {...props} inputElement={InputElement} />;
};

export default Select;
