// TODO: delete after implementing requests
async function stall(data?: any, stallTime = 3000) {
  await new Promise(resolve => setTimeout(resolve, stallTime));
}

export default stall;

export const getMockData = (data = {}, delay = 2000): Promise<any> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(data);
    }, delay);
  });
};
