// libraries
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
// components
import PopupNotification from './PopupNotification/PopupNotification';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

const PopupNotifications: React.FC = () => {
  const {
    notificationsStore: { popups },
    modalStore: { modalIds },
  } = useStore();
  const [activePopupId, setActivePopupId] = useState<string>('');

  useEffect(() => {
    setActivePopupId(modalIds[0]);
  }, [modalIds, modalIds.length]);

  return (
    <>
      {popups.map(data => {
        if (`${ModalIds.notificationPopup}-${data.nvmap?.id}` === activePopupId) {
          return <PopupNotification key={data.nvmap?.id} data={data} />;
        }

        return null;
      })}
    </>
  );
};

export default observer(PopupNotifications);
