// libraries
import { get } from 'lodash';

export interface IData {
  getData: () => Dictionary<any>;
  getValueBySource: (source: string) => any;
  isNotEmpty: () => boolean;
}

class FieldData implements IData {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
    Object.entries(this.data).forEach(([key, value]) => {
      // @ts-ignore
      this[key] = value;
    });
  }

  getData() {
    return this.data;
  }

  getValueBySource(source: string) {
    return get(this.data, source);
  }

  isNotEmpty() {
    return Object.keys(this.data).length > 0;
  }
}

export default FieldData;
