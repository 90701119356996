// libraries
import React from 'react';
// helpers
import { formatCurrency } from 'shared/helpers/format';
// styles
import styles from './CompletedDashboard.module.scss';

interface ICompletedDashboardItem {
  title: string;
  amount: number;
  subtitle: string;
}

const CompletedDashboardItem: React.FC<ICompletedDashboardItem> = ({
  title,
  amount,
  subtitle,
}: ICompletedDashboardItem) => {
  const isNegativeAmount = amount < 0;

  return (
    <div className={styles['completed-dashboard-item']}>
      <div className={styles['completed-dashboard-item__title']}>{title}</div>
      <div className={styles['completed-dashboard-item__amount-wrapper']}>
        {isNegativeAmount && <span className={styles['completed-dashboard-item__amount-minus']}>-</span>}
        <span className={styles['completed-dashboard-item__amount']}>
          {formatCurrency(isNegativeAmount ? -amount : amount, false)}
        </span>
      </div>
      <div className={styles['completed-dashboard-item__subtitle']}>{subtitle}</div>
    </div>
  );
};

export default CompletedDashboardItem;
