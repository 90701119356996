// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
// components
import BusinessDetailsFields from './BusinessDetailsFields';
import Form from 'components/Form';
import AppLoading from 'components/AppLoading/AppLoading';
// helpers
import BUSINESS_NAME_SCHEMA from './validation-rules';
import useVerification from 'components/verification/useVerification';

const BusinessInformationForm: React.FC = () => {
  const { isLoading, stepData, submitBusinessInformation } = useVerification();

  if (isLoading) {
    return <AppLoading isActive={isLoading} />;
  }

  return (
    <Form
      submitForm={submitBusinessInformation}
      validationSchema={BUSINESS_NAME_SCHEMA}
      className="public-form-wrapper"
      autoComplete="off"
      data={stepData}
    >
      <BusinessDetailsFields />
    </Form>
  );
};

export default observer(BusinessInformationForm);
