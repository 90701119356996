// libraries
import React from 'react';
// components
import Form from 'components/Form';
import PhoneFormFields from 'components/settings/AccountTabForm/AuthentificationBlock/PhoneForm/PhoneFormFields';
// helpers
import useAccountTabForm from 'components/settings/AccountTabForm/useAccountTabForm';
import { SEND_CODE_TO_PHONE_FORM_SCHEMA } from 'components/settings/AccountTabForm/validation-rules';

const PhoneForm = () => {
  const { submitSendCodeToPhoneForm } = useAccountTabForm();

  return (
    <Form validationSchema={SEND_CODE_TO_PHONE_FORM_SCHEMA} submitForm={submitSendCodeToPhoneForm}>
      <PhoneFormFields />
    </Form>
  );
};

export default PhoneForm;
