import ContactList from 'router/views/Contacts/ContactList';
import TrashContacts from 'router/views/Contacts/TrashContacts';
import NotFound from 'router/views/NotFound/NotFound';

export enum ContactsPageNames {
  CONTACTS = 'Contact List',
  TRASH = 'Trash Contacts',
}

export default [
  {
    path: '/contacts',
    component: ContactList,
    name: ContactsPageNames.CONTACTS,
    exact: true,
  },
  {
    path: '/contacts/trash',
    component: TrashContacts,
    name: ContactsPageNames.TRASH,
  },
  {
    path: '*',
    exact: true,
    isProtected: false,
    component: NotFound,
    name: '404',
  },
];
