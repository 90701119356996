// libraries
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import Breadcrumbs from 'components/Breadcrumbs';
import InvoiceForm from 'components/invoices/InvoiceForm/InvoiceForm';
import NewCustomerForm from 'components/invoices/ExternalInvoice/NewCustomerForm/NewCustomerForm';
import InvoiceOwnerInfo from 'components/invoices/InvoiceOwnerInfo/InvoiceOwnerInfo';
import InvoicePage from 'components/invoices/InvoicePage/InvoicePage';
import FormHeader from 'components/MultiStep/Form/FormHeader/FormHeader';
import InvoiceFormReview from 'components/invoices/InvoiceForm/InvoiceFormReview/InvoiceFormReview';
import InvoicePreparing from 'components/invoices/InvoiceForm/InvoicePreparing/InvoicePreparing';
// helpers
import useMultiStep from 'components/MultiStep/useMultiStep';
import useCreateInvoice, { ExternalInvoiceStep } from 'components/invoices/useCreateInvoice';
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
// styles
import styles from 'components/invoices/InvoicePage/InvoicePage.module.scss';

type TExternalInvoiceProps = {
  customer: string;
};

const ExternalInvoice: React.FC<TExternalInvoiceProps> = ({ customer }: TExternalInvoiceProps) => {
  const { t } = useTranslation('invoice');
  const history = useHistory();
  const contextValue = useMultiStep(ExternalInvoiceStep.INVOICE_FORM_REVIEW);
  const { currentStep, goToPreviousStep, isCurrentStep, steps } = contextValue;
  const formTitle = useMemo(() => {
    if (isCurrentStep(ExternalInvoiceStep.NEW_CUSTOMER)) {
      return t('addNewCustomerTitle');
    }

    if (isCurrentStep(ExternalInvoiceStep.INVOICE_FORM)) {
      return t('invoiceFormTitle');
    }

    return t('reviewInvoiceTitle');
  }, [isCurrentStep, t]);

  const { handleSendInvoice, loadingBarProgress, isLoading } = useCreateInvoice(null);

  const handleReturn = useCallback(() => {
    if (currentStep && goToPreviousStep) {
      goToPreviousStep();

      return;
    }

    history.go(-1);
  }, [history, currentStep, goToPreviousStep]);

  if (isLoading) {
    return (
      <InvoicePage title={t('pageTitle')}>
        <div className={styles.page__content}>
          <InvoicePreparing loadingBarProgress={loadingBarProgress} />
        </div>
      </InvoicePage>
    );
  }

  return (
    <InvoicePage title={t('pageTitle')}>
      <MultiStepProvider value={contextValue}>
        <div className={styles['page__return-btn']}>
          <Breadcrumbs text={t('returnButton')} onClick={handleReturn} />
        </div>
        <div className={styles.page__content}>
          <FormHeader currentStep={currentStep} lastStep={ExternalInvoiceStep.INVOICE_FORM_REVIEW} title={formTitle} />
          {!isCurrentStep(ExternalInvoiceStep.NEW_CUSTOMER) && (
            <div className={styles['page__invoice-owner']}>
              <InvoiceOwnerInfo
                companyName={steps[ExternalInvoiceStep.NEW_CUSTOMER]?.customer}
                companyEmail={steps[ExternalInvoiceStep.NEW_CUSTOMER]?.contactEmail}
                withNewVendorBadge
              />
            </div>
          )}
          {isCurrentStep(ExternalInvoiceStep.NEW_CUSTOMER) && (
            <NewCustomerForm handleReturn={handleReturn} customerName={customer} />
          )}
          {isCurrentStep(ExternalInvoiceStep.INVOICE_FORM) && (
            <InvoiceForm handleReturn={handleReturn} currentStep={ExternalInvoiceStep.INVOICE_FORM} />
          )}
          {isCurrentStep(ExternalInvoiceStep.INVOICE_FORM_REVIEW) && (
            <InvoiceFormReview handleReturn={handleReturn} handleSubmitReview={handleSendInvoice} />
          )}
        </div>
      </MultiStepProvider>
    </InvoicePage>
  );
};

export default ExternalInvoice;
