// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import CHANGE_EMAIL_FORM_SCHEMA from './validation-rules';
// components
import ResetPasswordFormType from 'components/resetPassword/ResetPasswordFormType/ResetPasswordFormType';
// styles
import styles from 'components/resetPassword/EmailChangeForm/EmailChangeForm.module.scss';
// types
import { EmailChangeFormData } from 'components/resetPassword/usePasswordResetForm';

interface IEmailChangeProps {
  submitEmailChangeForm: (data: EmailChangeFormData) => void;
}

const EmailChangeForm: React.FC<IEmailChangeProps> = ({ submitEmailChangeForm }: IEmailChangeProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <div className={styles['email-form__container']}>
      <ResetPasswordFormType
        onSubmit={data => submitEmailChangeForm(data as EmailChangeFormData)}
        validationSchema={CHANGE_EMAIL_FORM_SCHEMA}
        labelName={t('emailChange.fields.emailChange.label')}
        inputPlaceholder={t('emailChange.fields.emailChange.placeholder')}
        inputName="email"
        buttonTitle={t('email.submitButton')}
      />
    </div>
  );
};

export default EmailChangeForm;
