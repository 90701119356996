// libraries
import React from 'react';
// styles
import styles from './LoadingBar.module.scss';

type TLoadingBar = {
  loadingProgress: number;
};

const LoadingBar: React.FC<TLoadingBar> = ({ loadingProgress }: TLoadingBar) => {
  return (
    <div className={styles['loading-bar']}>
      <div className={styles['loading-bar__filler']} style={{ width: `${loadingProgress}%` }} />
    </div>
  );
};

export default LoadingBar;
