// libraries
import React, { PropsWithChildren } from 'react';
// components
import SettingsBlockTitle from 'components/settings/SettingsBlockTitle/SettingsBlockTitle';

type SettingsBlockWrapperProps = PropsWithChildren<{
  titleBlock: string;
  className?: string;
  titleClassName?: string;
}>;

const SettingsBlockWrapper: React.FC<SettingsBlockWrapperProps> = ({
  titleBlock = '',
  children,
  className,
  titleClassName = '',
}: SettingsBlockWrapperProps) => {
  return (
    <div className={className}>
      <SettingsBlockTitle titleBlock={titleBlock} className={titleClassName} />
      <div>{children}</div>
    </div>
  );
};

export default SettingsBlockWrapper;
