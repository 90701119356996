// libraries
import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatISO, sub } from 'date-fns';
// components
import Form from 'components/Form';
import { DatePicker } from 'components/Form/inputs';
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import AppLoading from 'components/AppLoading/AppLoading';
// helpers
import QUICKBOOKS_VALIDATION_SCHEMA from './validation-rules';
import useQuickBook from './useQuickBook';
// styles
import styles from './AddQuickBooks.module.scss';
import modalStyles from '../Integrations.module.scss';
import { useStore } from '../../../shared/stores/RootStore/useStore';

type QuickBooksProps = {
  handleBackButtonClick: () => void;
  handleCloseModal: () => void;
};

const AddQuickBooks: React.FC<QuickBooksProps> = ({ handleBackButtonClick, handleCloseModal }: QuickBooksProps) => {
  const { t } = useTranslation('integrations');
  const { handleSubmitForm, isFormSubmitted, isLoading } = useQuickBook();
  const {
    integrationsStore: { exportTransactionsFromDate },
  } = useStore();
  const initialFromDate = useMemo(
    () => exportTransactionsFromDate || formatISO(sub(new Date(), { months: 3 }), { representation: 'date' }),
    [exportTransactionsFromDate],
  );
  const maxFromDate = new Date();

  if (isFormSubmitted) {
    return (
      <>
        <h2 className={modalStyles.title}>{t('quickBooksIntegration.successTitle')}</h2>
        <p className={classNames(styles.subtitle, styles['subtitle--success'])}>
          {t('quickBooksIntegration.successSubtitle')}
        </p>

        <div className={modalStyles.buttons__wrapper}>
          <Button className={modalStyles['cancel-button']} handleClick={handleCloseModal}>
            {t('closeButton')}
          </Button>
        </div>
      </>
    );
  }

  return (
    <AppLoading isActive={isLoading} hideContent>
      <h2 className={modalStyles.title}>{t('quickBooksIntegration.title')}</h2>
      <p className={styles.subtitle}>{t('quickBooksIntegration.subtitle')}</p>
      <Form
        validationSchema={QUICKBOOKS_VALIDATION_SCHEMA}
        submitForm={handleSubmitForm}
        data={{ from: initialFromDate }}
      >
        <DatePicker
          name="from"
          label={t('quickBooksIntegration.fromDate')}
          className={styles.datepicker__wrapper}
          placeholder={t('quickBooksIntegration.datepickerPlaceholder')}
          maxDate={maxFromDate}
        />
        <div className={modalStyles.buttons__wrapper}>
          <Button outline className={modalStyles['cancel-button']} handleClick={handleBackButtonClick}>
            {t('backButton')}
          </Button>
          <SubmitButton>{t('continueButton')}</SubmitButton>
        </div>
      </Form>
    </AppLoading>
  );
};

export default observer(AddQuickBooks);
