// libraries
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
// helpers
import { format } from 'date-fns';
import { DateFormats, formatAmount } from 'shared/helpers/format';
import { completedStates, FilterTabs } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
import { Invoice, InvoiceTypes } from 'components/invoices/types';
// assets
import { ReactComponent as ReceivableArrow } from 'assets/icons/long_top_down.svg';
import { ReactComponent as PayableArrow } from 'assets/icons/long_bottom_up.svg';

const InvoiceHistoryItem: React.FC<Invoice> = ({
  invoiceDate,
  invoiceId,
  invoiceType,
  totalAmount,
  invoiceState,
  counterpartyDetails: { dbaName, logoImage },
}: Invoice) => {
  const { t } = useTranslation('transactionHistory');
  const history = useHistory();
  const isPayableInvoice = useMemo(() => invoiceType === InvoiceTypes.payable, [invoiceType]);
  const Icon = useMemo(() => (isPayableInvoice ? PayableArrow : ReceivableArrow), [isPayableInvoice]);

  const Message = useMemo(
    () => (
      <span>
        {isPayableInvoice ? `${t('to')}` : `${t('from')}`}
        &nbsp;
        <b>{dbaName}</b>
      </span>
    ),
    [dbaName, isPayableInvoice, t],
  );

  const CompanyLogo = useMemo(
    () => (
      <EntityLogo logoSrc={logoImage} className="in-app-notifications__logo" icon={<Icon width={9} height={9} />} />
    ),
    [logoImage, Icon],
  );

  const InvoiceAmount = useMemo(
    () => (
      <span className={classnames('invoice-history__amount', { 'receivable-invoice': !isPayableInvoice })}>
        {formatAmount(totalAmount, isPayableInvoice)}
      </span>
    ),
    [totalAmount, isPayableInvoice],
  );

  const accountingLink = useMemo(() => {
    let accountingTab = isPayableInvoice ? FilterTabs.PAYABLE : FilterTabs.RECEIVABLE;

    if (completedStates.includes(invoiceState)) {
      accountingTab = FilterTabs.COMPLETE;
    }
    return `/accounting?invoiceId=${invoiceId}&type=${accountingTab}`;
  }, [isPayableInvoice, invoiceState, invoiceId]);

  return (
    <ShortEntityCard
      className="invoice_card"
      key={invoiceId}
      onClickCallback={() => {
        history.push(accountingLink);
      }}
      message={Message}
      helpText={format(invoiceDate, DateFormats.full)}
      logo={CompanyLogo}
      additionalInfo={InvoiceAmount}
    />
  );
};

export default InvoiceHistoryItem;
