// libs
import { useEffect, useState, useCallback, useMemo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
// components
import { InputElements } from 'components/Form/inputs/Input';
// constants
import { FilePath, FileName, FileTypes, BYTES_IN_MB } from 'shared/constants/fileupload';
// context
import { useFormContext } from 'components/Form/FormContext';

export type FileUploadProps = {
  name: string;
  fileTypes?: FileTypes[];
  label?: string;
  controlName?: string;
  maxSizeMb?: number;
  maxFiles?: number;
  required?: boolean;
  filesData?: Nullable<File[]>;
  path?: FilePath;
  fileName: FileName;
};

export type FileItem = {
  file: File;
  key: string;
  path: FilePath;
  name: FileName;
};

export type FileUploadData = {
  addFiles?: (e: ChangeEvent<InputElements>) => void;
  files: FileItem[];
  removeFile: (key: string) => void;
  error: Nullable<string>;
  allowedFileTypes: string[];
  fieldName: string;
  validateSingle: (file: File) => boolean;
  handleSetFiles: (newFiles: FileItem[]) => void;
};

const getBytesFromMb = (value: number): number => value * BYTES_IN_MB;

const useFileUpload = (props?: FileUploadProps, addFileCallback?: (file: File) => Promise<void>): FileUploadData => {
  const { name, fileTypes, maxSizeMb, maxFiles, path = FilePath.default, filesData = [], fileName = FileName.default } =
    props || {};
  const [files, setFiles] = useState<FileItem[]>([]);
  const [error, setError] = useState<Nullable<string>>(null);
  const { setFieldValues, validateForm } = useFormContext();
  const { t } = useTranslation('errors');
  const fieldName = useMemo(() => `fileupload.${name}`, [name]);

  const allowedFileTypes: string[] = useMemo(() => {
    const types: string[] = [];

    if (!fileTypes) {
      return types;
    }

    Object.entries(FileTypes).forEach(([key, value]) => {
      if (fileTypes.includes(value)) {
        types.push(`.${key}`);
      }
    });

    return types;
  }, [fileTypes]);

  const handleSetFiles = useCallback((newFiles: FileItem[]) => {
    setFiles(newFiles);
  }, []);

  const validate = useCallback(
    (data: FileList) => {
      if (!maxFiles) {
        return true;
      }

      setError(null);

      if (data.length > maxFiles - files.length) {
        setError(t('forms.files.max'));

        return false;
      }

      return true;
    },
    [files.length, maxFiles, t],
  );

  const validateSingle = useCallback(
    (file: File) => {
      if (fileTypes) {
        if (!fileTypes.includes(file.type as FileTypes)) {
          setError(`${t('forms.files.extension')} ${allowedFileTypes.join(', ')}`);
          return false;
        }
      }

      if (maxSizeMb && file.size > getBytesFromMb(maxSizeMb)) {
        setError(`${t('forms.files.size')} ${maxSizeMb}Mb`);
        return false;
      }

      setError(null);
      return true;
    },
    [allowedFileTypes, fileTypes, maxSizeMb, t],
  );

  const addFile = useCallback(
    (file: File) => {
      const key = shortid.generate();
      const isFileValid = validateSingle(file);

      if (!isFileValid) return;

      if (addFileCallback) {
        addFileCallback(file);
        return;
      }

      setFiles(prevPreviews => [...prevPreviews, { file, key, path, name: fileName }]);
    },
    [path, validateSingle, setFiles, addFileCallback, fileName],
  );

  const addFiles = useCallback(
    (e: ChangeEvent<InputElements>) => {
      const input = e.target as HTMLInputElement;

      if (!input.files || !validate(input.files)) {
        e.preventDefault();
        return;
      }

      [].forEach.call(input.files, file => {
        addFile(file);
      });

      input.value = '';
    },
    [addFile, validate],
  );

  const removeFile = (key: string) => {
    setFiles(prevPreviews => prevPreviews.filter(item => item.key !== key));
  };

  useEffect(() => {
    if (!filesData || !filesData.length) {
      return;
    }

    filesData.forEach(file => {
      addFile(file);
    });
  }, [filesData, addFile]);

  useEffect(() => {
    if (!setFieldValues) return;

    setFieldValues(fieldName, files, false);
  }, [files, setFieldValues, fieldName]);

  useEffect(() => {
    if (!validateForm) return;

    validateForm();
  }, [validateForm, files]);

  return {
    addFiles,
    files,
    removeFile,
    error,
    allowedFileTypes,
    fieldName,
    validateSingle,
    handleSetFiles,
  };
};

export default useFileUpload;
