// libraries
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// components
import OwnerForm from 'components/beneficial/OwnerForm/OwnerForm';
import SavedOwners from 'components/beneficial/SavedOwners/SavedOwners';
import AppLoading from 'components/AppLoading/AppLoading';
// helpers
import useBeneficialOwners from 'components/beneficial/useBeneficialOwners';

const BeneficiaryOwners: React.FC = () => {
  const { url } = useRouteMatch();
  const { isLoading, getBeneficialOwners } = useBeneficialOwners();

  useEffect(() => {
    getBeneficialOwners();
  }, [getBeneficialOwners]);

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <Switch>
      <Route exact path={url}>
        <div className="public-form-wrapper">
          <SavedOwners />
        </div>
      </Route>

      <Route path={`${url}/form`}>
        <div className="public-form-wrapper">
          <OwnerForm />
        </div>
      </Route>

      <Route path={`${url}/:id/form`}>
        <div className="public-form-wrapper">
          <OwnerForm />
        </div>
      </Route>
    </Switch>
  );
};

export default observer(BeneficiaryOwners);
