// libraries
import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
// context
import { FormData } from 'components/Form/FormContext';
import { MultiStepContextData } from 'components/MultiStep/MultiStepContext';

const useMultiStep = (lastStep?: number, startStep = 0): MultiStepContextData => {
  const [currentStep, setCurrentStep] = useState(startStep);
  const [steps, setSteps] = useState<FormData[]>([]);
  const [formData, setFormData] = useState<FormData>({});

  const goToNextStep = useCallback(
    (stepNumber: number, data: FormData) => {
      const dataToSave = !data || isEmpty(data) ? {} : data;
      const isNewStep = !steps.length || steps.length - stepNumber === 0;

      if (lastStep && stepNumber === lastStep) {
        setSteps(prevState => [...prevState, dataToSave]);
        return;
      }

      if (isNewStep) {
        setSteps(prevState => [...prevState, dataToSave]);
        setCurrentStep(stepNumber + 1);
        return;
      }

      setSteps(prevState => [...prevState.slice(0, stepNumber), dataToSave, ...prevState.slice(stepNumber + 1)]);
      setCurrentStep(stepNumber + 1);
    },
    [steps, lastStep],
  );

  const goToPreviousStep = useCallback(() => {
    setCurrentStep(prevState => prevState - 1);
  }, []);

  const goToStep = useCallback(
    (step: number) => {
      setCurrentStep(step);
      setFormData(steps[step]);
    },
    [steps],
  );

  useEffect(() => {
    setFormData(steps[currentStep - 1]);
  }, [currentStep, steps]);

  const isCurrentStep = useCallback(step => currentStep === step, [currentStep]);

  return {
    currentStep,
    lastStep,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    steps,
    formData,
    isCurrentStep,
  };
};

export default useMultiStep;
