import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
// components
import AppLoading from '../../AppLoading/AppLoading';
import EmailConfirmedInfo from '../EmailConfirmedInfo';
import SubmitEmailForm from '../SubmitEmailForm';
// helpers
import useConfirmEmail, { ConfirmEmailStatus } from './useConfirmEmail';
// styles
import styles from './ConfirmEmail.module.scss';

const emailConfirmationResult = (email: string) => ({
  [ConfirmEmailStatus.confirmed]: <EmailConfirmedInfo email={email} />,
  [ConfirmEmailStatus.expired]: <SubmitEmailForm email={email} />,
});

const ConfirmEmailInfo: React.FC = () => {
  const { t } = useTranslation('confirmEmail');
  const { isConfirmationLoading, email, tokenStatus } = useConfirmEmail();

  if (isConfirmationLoading) return <AppLoading />;

  if (tokenStatus && email) {
    return (
      <Container className={styles.container}>
        <h1>{t('title')}</h1>
        {emailConfirmationResult(email)[tokenStatus]}
      </Container>
    );
  }

  return null;
};

export default ConfirmEmailInfo;
