// libraries
import React from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { bool, func, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import { TextInput } from 'components/Form/inputs';
// context
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// helpers
import { StripeFormParams, StripeInputId } from 'components/paymentMethods/StripeForm/useStripeForm';
import STRIPE_FIELD_OPTIONS from 'components/paymentMethods/StripeForm/stripeFieldHelpers';
// styles
import styles from 'components/paymentMethods/StripeForm/StripeForm.module.scss';

export enum StripeFormColorTheme {
  DEFAULT = 'default',
  LIGHT = 'light',
}

export enum StripeFormRowsIndent {
  DEFAULT = 'default',
  EXTENDED = 'extended',
}

interface StripeFormProps extends Pick<StripeFormParams, 'fieldsState' | 'onChange' | 'toggleFocus'> {
  colorTheme?: StripeFormColorTheme;
  rowsIndent?: StripeFormRowsIndent;
  controlsClassName?: string;
}

const StripeForm: React.FC<StripeFormProps> = ({
  fieldsState,
  onChange,
  toggleFocus,
  colorTheme = StripeFormColorTheme.DEFAULT,
  rowsIndent = StripeFormRowsIndent.DEFAULT,
  controlsClassName = '',
}) => {
  const stripe = useStripe();
  const { t } = useTranslation(['connectedAccountsPage', 'forms']);
  const { goToPreviousStep } = useMultiStepContext();

  const {
    cardNumber: { hasFocus: hasCardNumberFocus, error: cardNumberError },
    cardExpiry: { hasFocus: hasCardExpiryFocus, error: cardExpiryError },
    cardCvc: { hasFocus: hasCardCvcFocus, error: cardCvcError },
  } = fieldsState;

  const cardNumberStyle = classNames(styles['stripe-input'], {
    [styles['stripe-input--focus']]: hasCardNumberFocus,
    [styles['stripe-input--error']]: !!cardNumberError,
  });
  const expirationStyle = classNames(styles['stripe-input'], {
    [styles['stripe-input--focus']]: hasCardExpiryFocus,
    [styles['stripe-input--error']]: !!cardExpiryError,
  });
  const cvvStyle = classNames(styles['stripe-input'], {
    [styles['stripe-input--focus']]: hasCardCvcFocus,
    [styles['stripe-input--error']]: !!cardCvcError,
  });

  const formClassName = classNames({
    [styles['stripe-form__light-theme']]: colorTheme === StripeFormColorTheme.LIGHT,
  });

  const fieldRowClassName = classNames(styles['stripe-form__row'], {
    [styles['stripe-form__row-indent--default']]: rowsIndent === StripeFormRowsIndent.DEFAULT,
    [styles['stripe-form__row-indent--extended']]: rowsIndent === StripeFormRowsIndent.EXTENDED,
  });

  const controlsStyles = classNames(styles['stripe-form__controls'], controlsClassName);

  return (
    <div className={formClassName}>
      <>
        <div className={fieldRowClassName}>
          <TextInput
            name="firstName"
            label={t('stripeForm.labels.firstName')}
            fieldWrapperClassName={styles['stripe-form__field']}
            inputWrapperClassName={styles['stripe-form__name-input']}
          />
          <TextInput
            name="lastName"
            label={t('stripeForm.labels.lastName')}
            fieldWrapperClassName={styles['stripe-form__field']}
            inputWrapperClassName={styles['stripe-form__name-input']}
          />
        </div>
        <div className={fieldRowClassName}>
          <div className={styles['stripe-form__field']}>
            <div className={styles['stripe-form__label']}>{t('stripeForm.labels.cardNumber')}</div>
            <CardNumberElement
              options={STRIPE_FIELD_OPTIONS as StripeCardElementOptions}
              className={cardNumberStyle}
              onFocus={() => toggleFocus(StripeInputId.CARD_NUMBER)}
              onBlur={() => toggleFocus(StripeInputId.CARD_NUMBER)}
              onChange={onChange}
            />
            <div className={styles['stripe-input__errors']}>{fieldsState[StripeInputId.CARD_NUMBER].error}</div>
          </div>
        </div>
        <div className={fieldRowClassName}>
          <div className={styles['stripe-form__field']}>
            <div className={styles['stripe-form__label']}>{t('stripeForm.labels.expiration')}</div>
            <CardExpiryElement
              options={STRIPE_FIELD_OPTIONS as StripeCardElementOptions}
              className={expirationStyle}
              onFocus={() => toggleFocus(StripeInputId.CARD_EXPIRY)}
              onBlur={() => toggleFocus(StripeInputId.CARD_EXPIRY)}
              onChange={onChange}
            />
            <div className={styles['stripe-input__errors']}>{fieldsState[StripeInputId.CARD_EXPIRY].error}</div>
          </div>
          <div className={styles['stripe-form__field']}>
            <div className={styles['stripe-form__label']}>{t('stripeForm.labels.cvv')}</div>
            <CardCvcElement
              options={STRIPE_FIELD_OPTIONS as StripeCardElementOptions}
              className={cvvStyle}
              onFocus={() => toggleFocus(StripeInputId.CARD_CVC)}
              onBlur={() => toggleFocus(StripeInputId.CARD_CVC)}
              onChange={onChange}
            />
            <div className={styles['stripe-input__errors']}>{fieldsState[StripeInputId.CARD_CVC].error}</div>
          </div>
        </div>
      </>
      <div className={controlsStyles}>
        <Button className={styles['stripe-form__btn']} outline handleClick={goToPreviousStep}>
          {t('forms:backButtonDefaultText')}
        </Button>
        <SubmitButton disabled={!stripe}>{t('forms:continueButtonDefaultText')}</SubmitButton>
      </div>
    </div>
  );
};

StripeForm.propTypes = {
  fieldsState: shape({
    cardNumber: shape({
      hasFocus: bool.isRequired,
      error: string.isRequired,
      isComplete: bool.isRequired,
      isEmpty: bool.isRequired,
    }).isRequired,
    cardExpiry: shape({
      hasFocus: bool.isRequired,
      error: string.isRequired,
      isComplete: bool.isRequired,
      isEmpty: bool.isRequired,
    }).isRequired,
    cardCvc: shape({
      hasFocus: bool.isRequired,
      error: string.isRequired,
      isComplete: bool.isRequired,
      isEmpty: bool.isRequired,
    }).isRequired,
  }).isRequired,
  onChange: func.isRequired,
  toggleFocus: func.isRequired,
  controlsClassName: string,
  colorTheme: oneOf([StripeFormColorTheme.DEFAULT, StripeFormColorTheme.LIGHT]),
  rowsIndent: oneOf([StripeFormRowsIndent.DEFAULT, StripeFormRowsIndent.EXTENDED]),
};

export default StripeForm;
