// libraries
import React from 'react';
// styles
import styles from 'shared/layouts/public/TwoBlockLayout/TwoBlockLayout.module.scss';

interface ITwoBlockProps {
  children: React.ReactNode;
  imageAlt?: string;
  imageSrc?: string;
}

const TwoBlockLayout: React.FC<ITwoBlockProps> = ({ imageAlt, imageSrc, children }: ITwoBlockProps) => (
  <>
    <div className={styles['two-block__container']}>
      <div className={styles['left-block']}>{children}</div>
      <img src={imageSrc} alt={imageAlt} className={styles.image} />
    </div>
  </>
);

export default TwoBlockLayout;
