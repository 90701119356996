// libraries
import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
// components
import CalendarHeader from './CalendarHeader/CalendarHeader';
// helpers
import useWindowSize, { DESKTOP_MIN_WIDTH } from 'shared/helpers/useWindowSize';
// styles
import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.scss';

interface ICalendarProps {
  dueDate: Date;
  children: React.ReactElement;
  handleChange: (value: Date) => void;
  selectedDate?: Date;
}

const PaymentDatePicker: React.FC<ICalendarProps> = ({
  selectedDate,
  handleChange,
  dueDate,
  children,
}: ICalendarProps) => {
  const { width } = useWindowSize();
  const isMobile = useMemo(() => !!width && width < DESKTOP_MIN_WIDTH, [width]);

  return (
    <DatePicker
      autoComplete="off"
      className="external-payment-datepicker"
      onChange={handleChange}
      minDate={new Date()}
      highlightDates={[dueDate]}
      selected={selectedDate}
      disabledKeyboardNavigation
      formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
      popperPlacement={isMobile ? 'top-start' : 'right'}
      customInput={children}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
        <CalendarHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
      )}
    />
  );
};

export default PaymentDatePicker;
