// libraries
import { useCallback, useState } from 'react';
// api
import RestrictedFilesApi from 'components/RestrictedFiles/api/RestrictedFilesApi';
// helpers
import { getContentDispositionFileName } from 'shared/helpers/files';

export type RestrictedFile = {
  url: string;
  description: string;
  type: string;
  fileName: string;
};

type RestrictedFilesParams = {
  downloadFile: (filePath: string, description?: string) => Promise<RestrictedFile>;
  isLoading: boolean;
};

const useRestrictedFiles = (): RestrictedFilesParams => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = useCallback(async (filePath: string, description?: string) => {
    setIsLoading(true);

    try {
      const response = (await RestrictedFilesApi.getFile(filePath)) as Response;
      const fileName = getContentDispositionFileName(response);
      const blob = await response.blob();

      return {
        fileName,
        url: URL.createObjectURL(blob) || '',
        description: description || '',
        type: blob.type || '',
      };
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    downloadFile,
    isLoading,
  };
};

export default useRestrictedFiles;
