// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
// styles
import styles from './CompanyDescription.module.scss';
// assets
import { ReactComponent as EditPen } from 'assets/icons/edit-pen.svg';
// helpers
import useCompanyDescription from './useCompanyDescription';
// components
import EditCompanyDescriptionForm from './EditCompanyDescriptionForm';
import Invitation from './Invitation';
// constants
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';

type CompanyDescriptionProps = {
  companyProfile: Nullable<IBusinessSnapshot>;
  isEditable?: boolean;
};

const CompanyDescription: React.FC<CompanyDescriptionProps> = ({
  companyProfile = null,
  isEditable = false,
}: CompanyDescriptionProps) => {
  const { t } = useTranslation('company');

  const description = companyProfile?.description || '';
  const id = companyProfile?.id;
  const isGhosty = companyProfile?.isGhosty;

  const { showEditForm, isEditFormShown, hideEditForm, handleChange, companyDescription } = useCompanyDescription({
    description,
    id,
  });

  const isEmptyDescription = companyDescription?.length === 0;

  const wrapperClassName = classNames(
    styles.company__description,
    { [styles.ghosty__description]: isGhosty },
    { [styles.empty__description]: isEmptyDescription && !isGhosty },
  );

  const invitationPhrase = isEmptyDescription ? t('ghostyEmptyDescriptionTitle') : t('ghostyTitle');

  return (
    <div className={wrapperClassName}>
      <div className={classNames(styles.subtitle__container, { [styles.ghosty__subtitle]: isGhosty })}>
        <h2 className={styles.company__subtitle}>{t('about')}</h2>
        {isEditable && (
          <button className={styles['edit-description__button']} onClick={showEditForm} disabled={isEditFormShown}>
            <EditPen className={classNames({ [styles['edit-description-icon__active']]: isEditFormShown })} />
          </button>
        )}
      </div>
      {isEditFormShown ? (
        <EditCompanyDescriptionForm
          handleCancelClick={hideEditForm}
          handleSubmitClick={handleChange}
          initialValue={companyDescription}
        />
      ) : (
        <div
          className={classNames(styles.description__wrapper, {
            [styles['empty-description__wrapper']]: isEmptyDescription,
          })}
        >
          <div className={classNames(styles.company__about, { [styles.ghosty__about]: isGhosty })}>
            {companyDescription}
          </div>
          {isGhosty && (
            <Invitation
              wrapperClassName={classNames({ [styles['empty-description-invitation__wrapper']]: isEmptyDescription })}
              invitationPhrase={invitationPhrase}
              subtitle={`${companyProfile?.dbaName} ${t('ghostySubtitle')}`}
              buttonTitle={t('inviteButton')}
            />
          )}
          {isEditable && isEmptyDescription && (
            <Invitation
              wrapperClassName={styles['edit-description-invitation__wrapper']}
              invitationPhrase={invitationPhrase}
              subtitle={t('emptyDescSubtitle')}
              buttonTitle={t('emptyDescButton')}
              buttonClickHandler={showEditForm}
              hasEditButton
            />
          )}
        </div>
      )}
    </div>
  );
};

export default observer(CompanyDescription);
