// libraries
import React, { useCallback } from 'react';
import { number, shape, string, func } from 'prop-types';
import classNames from 'classnames';
// assets
import { ReactComponent as UserIcon } from 'assets/icons/beneficial-owner.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-icon.svg';
// components
import Button from 'components/Button';
// helpers
import { getOwnerDetails } from 'components/beneficial/beneficialOwnersHelpers';
import useBeneficialOwners, { BeneficialOwner as OwnerType } from '../useBeneficialOwners';
// styles
import styles from './BeneficialOwner.module.scss';

type BeneficialOwnerProps = {
  owner: OwnerType;
  editHandler: () => void;
};

const BeneficialOwner: React.FC<BeneficialOwnerProps> = ({ owner, editHandler }) => {
  const { firstName, lastName, id } = owner || {};
  const labelName = `${firstName} ${lastName}`;

  const { openConfirmationModal } = useBeneficialOwners();

  const { headerLabel } = getOwnerDetails(owner);

  const handleConfirmModal = useCallback(() => {
    openConfirmationModal(id, headerLabel);
  }, [id, headerLabel, openConfirmationModal]);

  return (
    <div className={styles['beneficial-owner__container']}>
      <div className={styles['icon-container']}>
        <UserIcon />
      </div>
      <div className={styles.name}>{labelName}</div>
      <div className={styles.actions}>
        <Button
          className={classNames(styles['edit-icon'], styles.icon)}
          handleClick={editHandler}
          icon={<EditIcon />}
        />
        <Button
          className={classNames(styles['delete-icon'], styles.icon)}
          handleClick={handleConfirmModal}
          icon={<DeleteIcon />}
        />
      </div>
    </div>
  );
};

BeneficialOwner.propTypes = {
  owner: shape({
    id: number.isRequired,
    firstName: string.isRequired,
    lastName: string.isRequired,
    ssn: string.isRequired,
    birthday: string.isRequired,
    address: string.isRequired,
  }).isRequired,
  editHandler: func.isRequired,
};

export default BeneficialOwner;
