// libraries
import React from 'react';
import classNames from 'classnames';
// styles
import styles from './Button.module.scss';

export enum ButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export enum ButtonColor {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
}

export interface ButtonProps {
  handleClick?: () => void;
  className?: string;
  type?: ButtonType;
  disabled?: boolean;
  btnLink?: boolean;
  btnDangerLink?: boolean;
  outline?: boolean;
  color?: ButtonColor;
  children?: React.ReactNode;
  icon?: React.ReactElement;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  handleClick = () => {},
  type = ButtonType.button,
  disabled = false,
  outline,
  btnLink = false,
  btnDangerLink = false,
  color = ButtonColor.secondary,
  icon,
}: ButtonProps) => {
  const buttonClass = classNames(
    styles.btn,
    {
      [styles['btn--primary']]: color === ButtonColor.primary,
      [styles['btn--danger']]: color === ButtonColor.danger,
      [styles['btn--link']]: btnLink,
      [styles['btn--danger-link']]: btnDangerLink,
      [styles['btn--outline']]: outline,
      [styles['btn--disabled']]: disabled,
    },
    className,
  );

  const linkIcon = icon && <span className={styles.btn__icon}>{icon}</span>;

  return (
    <button
      className={buttonClass}
      type={type}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        (e.target as HTMLButtonElement).blur();
        handleClick();
      }}
      disabled={disabled}
    >
      {children}
      {linkIcon}
    </button>
  );
};

export default Button;
