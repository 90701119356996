// libraries
import * as Yup from 'yup';
// helpers
import {
  getLatinNameSchema,
  getLatinNameWithNumberSchema,
  getLatinNameSchemaWithNumberAndApostrophe,
  getZipCodeNameSchema,
  getPostalCodeSchema,
} from 'shared/helpers/validationSchemes';
import { USA_COUNTRY } from 'shared/helpers/countries';

export const addressFieldsRules = {
  country: Yup.string().required('forms.address.country.required'),
  line1: getLatinNameSchemaWithNumberAndApostrophe().required('forms.address.line1.required'),
  city: getLatinNameSchema().required('forms.address.city.required'),
  state: Yup.string().when('country', {
    is: USA_COUNTRY,
    then: (schema: Yup.ObjectSchema) => schema.required('forms.address.state.required'),
    otherwise: (schema: Yup.ObjectSchema) => schema.notRequired(),
  }),
  zip: Yup.string().when('country', {
    is: USA_COUNTRY,
    then: () => getZipCodeNameSchema().required('forms.address.zip.required'),
    otherwise: (schema: Yup.ObjectSchema) => schema.notRequired(),
  }),
  region: Yup.string().when('country', {
    is: USA_COUNTRY,
    then: (schema: Yup.ObjectSchema) => schema.notRequired(),
    otherwise: () => getLatinNameWithNumberSchema().required('forms.address.region.required'),
  }),
  postalCode: Yup.string().when('country', {
    is: USA_COUNTRY,
    then: (schema: Yup.ObjectSchema) => schema.notRequired(),
    otherwise: () => getPostalCodeSchema().required('forms.address.postalCode.required'),
  }),
};

export default Yup.object().shape(addressFieldsRules);
