// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Form from 'components/Form';
import { PasswordInput, TextInput } from 'components/Form/inputs';
import EntryButton from 'components/EntryButton/EntryButton';
// styles
import styles from 'components/signUpForms/RegisterForm/RegisterForm.module.scss';
// helpers
import { RegisterFormData } from 'components/signUpForms/useSignUpForm';
import REGISTER_FORM_SCHEMA from 'components/signUpForms/RegisterForm/validation-rules';
// constants
import Endpoints from 'shared/constants/endpoints';

interface IRegisterFormProps {
  submitRegisterForm: (data: RegisterFormData) => Promise<void>;
}

const RegisterForm: React.FC<IRegisterFormProps> = ({ submitRegisterForm }: IRegisterFormProps) => {
  const { t } = useTranslation('signUpPage');

  return (
    <Form
      formType={Endpoints.SIGN_UP}
      submitForm={data => submitRegisterForm(data as RegisterFormData)}
      validationSchema={REGISTER_FORM_SCHEMA}
      className="public-form-wrapper"
      autoComplete="off"
    >
      <div className={styles['fields-container']}>
        <div className={styles['form__two-column']}>
          <TextInput
            name="firstName"
            label={t('registerForm.fields.firstName.label')}
            fieldLabelClassName={styles.form__label}
            placeholder={t('registerForm.fields.firstName.placeholder')}
          />
          <TextInput
            name="lastName"
            label={t('registerForm.fields.lastName.label')}
            fieldLabelClassName={styles.form__label}
            placeholder={t('registerForm.fields.lastName.placeholder')}
          />
        </div>
        <TextInput
          name="legalName"
          label={t('registerForm.fields.businessName.label')}
          fieldLabelClassName={styles.form__label}
          autoComplete="organization"
          placeholder={t('registerForm.fields.businessName.placeholder')}
        />
        <PasswordInput
          name="password"
          label={t('registerForm.fields.password.label')}
          fieldLabelClassName={styles.form__label}
          autoComplete="new-password"
          placeholder={t('registerForm.fields.password.placeholder')}
        />
      </div>
      <EntryButton>{t('registerForm.submitButton')}</EntryButton>
    </Form>
  );
};

export default RegisterForm;
