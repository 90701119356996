// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// components
import Form from 'components/Form';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import Button from 'components/Button';
import FormFooter from 'components/MultiStep/Form/FormFooter/FormFooter';
import ContactFormFields from 'components/contacts/ContactsForm/ContactFormFields';
// helpers
import CONTACT_FORM_SCHEMA from 'components/contacts/ContactsForm/validation-rules';
// types
import { FormData } from 'components/Form/FormContext';
// styles
import styles from 'components/contacts/ContactsForm/index.module.scss';

interface IContactsForm {
  submitForm: (data: FormData) => void;
}

const ContactsForm: React.FC<IContactsForm> = ({ submitForm }: IContactsForm) => {
  const { t } = useTranslation('contactPages');
  const history = useHistory();

  const handleCancel = useCallback(() => {
    history.push('/contacts');
  }, []);

  return (
    <Form submitForm={submitForm} validationSchema={CONTACT_FORM_SCHEMA}>
      <ContactFormFields />
      <FormFooter>
        <Button outline handleClick={handleCancel} className={styles['customer-form__button']}>
          {t('form.buttons.cancel')}
        </Button>
        <SubmitButton className={styles['customer-form__button']}>{t('form.buttons.continue')}</SubmitButton>
      </FormFooter>
    </Form>
  );
};

export default ContactsForm;
