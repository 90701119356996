import * as Yup from 'yup';
import { getLatinNameSchema, getUsernameSchema } from 'shared/helpers/validationSchemes';

export const COMPANY_PROFILE_FORM_SCHEMA = Yup.object().shape({
  dbaName: getLatinNameSchema().required('forms.companyName.required'),
  handle: getUsernameSchema().required('forms.username.required'),
  registeredSince: Yup.string().matches(new RegExp('^\\s*$|^\\s*\\d{4}\\s*$'), 'forms.yearFounded.length'),
});

export const DESCRIPTION_VALIDATION_SCHEMA = Yup.object().shape({
  description: Yup.string().max(600, 'forms.companyDescription.length'),
});

export const LOCATION_VALIDATION_SCHEMA = Yup.object().shape({
  city: getLatinNameSchema().required('forms.city.required'),
  state: getLatinNameSchema().required('forms.state.required'),
});
