import RestrictedFiles from 'components/RestrictedFiles/RestrictedFiles';

export default [
  {
    path: '/companies/invoices/:hash',
    exact: true,
    isProtected: true,
    component: RestrictedFiles,
    name: 'Invoice',
  },
  {
    path: '/companies/receipts/:hash',
    exact: true,
    isProtected: true,
    component: RestrictedFiles,
    name: 'Receipt',
  },
  {
    path: '/companies/reports/:hash',
    exact: true,
    isProtected: true,
    component: RestrictedFiles,
    name: 'Report',
  },
];
