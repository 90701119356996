// libraries
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// styles
import styles from './Link.module.scss';

type LinkProps = PropsWithChildren<{
  to: string;
  linkClassName?: string;
  linkWrapperClassName?: string;
}>;

const StyledLink: React.FC<LinkProps> = ({
  children = null,
  to = '',
  linkClassName = '',
  linkWrapperClassName = '',
}: LinkProps) => {
  const resultLinkClassName = classNames(styles.link, linkClassName);

  return (
    <div className={linkWrapperClassName}>
      <Link to={to} className={resultLinkClassName}>
        {children}
      </Link>
    </div>
  );
};

export default StyledLink;
