// libraries
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
// components
import { ButtonProps, ButtonColor } from 'components/Button';
// styles
import styles from './Button.module.scss';

export interface LinkButtonProps extends ButtonProps {
  url: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  className,
  disabled = false,
  outline = false,
  url,
  icon,
  color,
  handleClick: handleClickCustom,
}: LinkButtonProps) => {
  const buttonClass = classNames(
    styles.btn,
    {
      [styles['btn--primary']]: color === ButtonColor.primary,
      [styles['btn--outline']]: outline,
      [styles['btn--disabled']]: disabled,
    },
    className,
  );
  const linkIcon = icon && <span className={styles.btn__icon}>{icon}</span>;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (handleClickCustom) {
        handleClickCustom();
        return;
      }
      (e.target as HTMLElement).blur();
    },
    [handleClickCustom],
  );

  return disabled ? (
    <span className={buttonClass}>
      {children} {linkIcon}
    </span>
  ) : (
    <NavLink to={url} className={buttonClass} onClick={handleClick}>
      {children}
      {linkIcon}
    </NavLink>
  );
};

export default LinkButton;
