// libraries
import React from 'react';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// components
import Modal, { ModalType } from 'components/Modal/Modal';
import TransferFunds from './TransferFunds';
// context
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
// hooks
import useMultiStep from 'components/MultiStep/useMultiStep';

const WrapperTransferFunds = () => {
  const contextValue = useMultiStep(2, 1);

  return (
    <Modal id={ModalIds.transferFunds} type={ModalType.base} hasCloseButton={false}>
      <MultiStepProvider value={contextValue}>
        <TransferFunds />
      </MultiStepProvider>
    </Modal>
  );
};

export default WrapperTransferFunds;
