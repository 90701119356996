import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';

class ConnectionStore {
  isConnectionOpen = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setIsConnectionOpen = (isOpen: boolean): void => {
    this.isConnectionOpen = isOpen;
  };
}

export default ConnectionStore;
