// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuPlacement } from 'react-select';
import classNames from 'classnames';
// components
import { AutocompleteInput, Select, TextInput } from 'components/Form/inputs';
// context
import { useFormContext } from 'components/Form/FormContext';
// helpers
import { STATES } from 'shared/constants/states';
import { COUNTRIES, USA_COUNTRY } from 'shared/helpers/countries';
// styles
import styles from 'components/paymentMethods/AddressForm/AddressForm.module.scss';

export enum AddressFormColorTheme {
  DEFAULT = 'default',
  LIGHT = 'light',
}

export enum AddressFormRowsIndent {
  DEFAULT = 'default',
  EXTENDED = 'extended',
}

type AddressFormProps = {
  isUsaOnly?: boolean;
  menuPlacement?: MenuPlacement;
  rowsIndent?: AddressFormRowsIndent;
  colorTheme?: AddressFormColorTheme;
};

const AddressForm: React.FC<AddressFormProps> = ({
  menuPlacement = 'auto',
  isUsaOnly = false,
  rowsIndent = AddressFormRowsIndent.DEFAULT,
  colorTheme = AddressFormColorTheme.DEFAULT,
}) => {
  const { t } = useTranslation('forms');
  const { data } = useFormContext();

  const isUSA = data?.country === USA_COUNTRY;

  const formClassName = classNames({
    [styles['address-form--light-theme']]: colorTheme === AddressFormColorTheme.LIGHT,
  });

  const fieldRowClassName = classNames(styles['address-form__row'], {
    [styles['address-form__row-intent--default']]: rowsIndent === AddressFormRowsIndent.DEFAULT,
    [styles['address-form__row-intent--extended']]: rowsIndent === AddressFormRowsIndent.EXTENDED,
  });

  return (
    <div className={formClassName}>
      <div className={fieldRowClassName}>
        <AutocompleteInput name="address" label="Enter your address" />
      </div>

      <div className={fieldRowClassName}>
        <TextInput name="line1" label="Street Address" />
      </div>

      <div className={fieldRowClassName}>
        <TextInput name="city" label={t('address.city')} />
      </div>

      {isUSA ? (
        <div className={fieldRowClassName}>
          <div className={styles['address-form__field']}>
            <Select
              name="state"
              options={STATES}
              menuPlacement={menuPlacement}
              label={t('address.state')}
              isRequired
              fieldWrapperClassName={styles['address-form__input']}
            />
          </div>
          <div className={styles['address-form__field']}>
            <TextInput
              name="zip"
              label={t('address.zip')}
              isRequired
              fieldWrapperClassName={styles['address-form__input']}
            />
          </div>
        </div>
      ) : (
        <div className={fieldRowClassName}>
          <div className={styles['address-form__field']}>
            <TextInput
              name="region"
              label={t('address.region')}
              isRequired
              fieldWrapperClassName={styles['address-form__input']}
            />
          </div>
          <div className={styles['address-form__field']}>
            <TextInput
              name="postalCode"
              label={t('address.postalCode')}
              isRequired
              fieldWrapperClassName={styles['address-form__input']}
            />
          </div>
        </div>
      )}

      {!isUsaOnly && (
        <div className={fieldRowClassName}>
          <Select
            name="country"
            disabled={isUsaOnly}
            options={COUNTRIES}
            menuPlacement={menuPlacement}
            label={t('address.country')}
          />
        </div>
      )}
    </div>
  );
};

export default AddressForm;
