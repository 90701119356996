// libraries
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import CheckboxField from 'components/settings/AccountTabForm/AuthentificationBlock/CheckboxField/CheckboxField';
import PhoneForm from 'components/settings/AccountTabForm/AuthentificationBlock/PhoneForm/PhoneForm';
import CodeForm from 'components/settings/AccountTabForm/AuthentificationBlock/CodeForm/CodeForm';
import Button from 'components/Button';
// helpers
import useAccountTabForm from 'components/settings/AccountTabForm/useAccountTabForm';
// styles
import authentificationBlockStyles from './AuthentificationBlock.module.scss';

const AuthentificationBlock = () => {
  const { t } = useTranslation('settingsPage');
  const [isChecked, setIsChecked] = useState(false);
  const { openConfirmationModal } = useAccountTabForm();

  const tfaFieldsWrapperClassName = classNames({
    [authentificationBlockStyles['tfa-fields__wrapper--disabled']]: !isChecked,
  });

  const closeAccountWrapperClassName = classNames(authentificationBlockStyles['close-account__wrapper'], {
    [authentificationBlockStyles['close-account__wrapper--top']]: !isChecked,
  });

  return (
    <>
      <CheckboxField setIsChecked={setIsChecked} isChecked={isChecked} />
      <div className={tfaFieldsWrapperClassName}>
        <PhoneForm />
        <CodeForm />
      </div>
      <div className={closeAccountWrapperClassName}>
        <Button
          className={classNames(
            authentificationBlockStyles['button-link'],
            authentificationBlockStyles['close-account'],
          )}
          handleClick={openConfirmationModal}
        >
          {t('authentificationBlock.closeAccount.link')}
        </Button>
        <span className={authentificationBlockStyles.tip}>{t('authentificationBlock.closeAccount.tip')}</span>
      </div>
    </>
  );
};

export default AuthentificationBlock;
