// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import Modal, { ModalType } from 'components/Modal/Modal';
import Form from 'components/Form';
import { RadioInput } from 'components/Form/inputs';
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import AddQuickBooks from './AddQuickBooks/AddQuickBooks';
import DeleteIntegration from './DeleteIntegration/DeleteIntegration';
import AppLoading from 'components/AppLoading/AppLoading';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import useIntegrations, { IntegrationTypes } from './useIntegrations';
import INTEGRATIONS_VALIDATION_SCHEMA from './validation-rules';
// styles
import styles from './Integrations.module.scss';

const Integrations: React.FC = () => {
  const { t } = useTranslation('integrations');
  const {
    handleCloseModal,
    handleBackButtonClick,
    changeModalContent,
    type,
    formData,
    isIntegrationConnected,
    isLoading,
    integrationsList,
  } = useIntegrations();

  return (
    <>
      <Modal id={ModalIds.integrations} type={ModalType.full} handleCloseModal={handleCloseModal}>
        <div className={classNames(styles.modal)}>
          {isIntegrationConnected && !isLoading && (
            <DeleteIntegration integrations={integrationsList} handleCancelClick={handleCloseModal} />
          )}

          {type === IntegrationTypes.quickBooks && (
            <AddQuickBooks handleBackButtonClick={handleBackButtonClick} handleCloseModal={handleCloseModal} />
          )}

          {!type && !isIntegrationConnected && (
            <AppLoading isActive={isLoading} hideContent>
              <h2 className={styles.title}>{t('title')}</h2>
              <p className={styles.subtitle}>{t('noIntegrationModal.subtitle')}</p>
              <Form validationSchema={INTEGRATIONS_VALIDATION_SCHEMA} submitForm={changeModalContent} data={formData}>
                {Object.entries(IntegrationTypes).map(([key, value]) => (
                  <RadioInput key={key} name="type" label={t(`type.${value}`)} value={value} />
                ))}
                <div className={styles.buttons__wrapper}>
                  <Button outline className={styles['cancel-button']} handleClick={handleCloseModal}>
                    {t('cancelButton')}
                  </Button>
                  <SubmitButton>{t('continueButton')}</SubmitButton>
                </div>
              </Form>
            </AppLoading>
          )}
        </div>
      </Modal>
    </>
  );
};

export default observer(Integrations);
