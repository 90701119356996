// libraries
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// components
import SettingsBlockWrapper from 'components/settings/SettingsBlockWrapper/SettingsBlockWrapper';
import AccountFields from 'components/settings/AccountTabForm/AccountFields/AccountFields';
import BusinessInformationFields from 'components/settings/AccountTabForm/BusinessInformationFields/BusinessInformationFields';
import AuthentificationBlock from 'components/settings/AccountTabForm/AuthentificationBlock/AuthentificationBlock';
import Form from 'components/Form';
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import AppLoading from 'components/AppLoading/AppLoading';
// helpers
import useBeneficialOwners from 'components/beneficial/useBeneficialOwners';
import useUnsavedChangesModal from 'shared/helpers/useUnsavedChangesModal';
import { usePlaces } from 'shared/helpers/placesHelpers';
import useAccountTabForm from 'components/settings/AccountTabForm/useAccountTabForm';
import { ACCOUNT_TAB_FORM_SCHEMA } from 'components/settings/AccountTabForm/validation-rules';
// styles
import styles from './AccountTabForm.module.scss';

const AccountTabForm = () => {
  const { isPlacesLoaded } = usePlaces();
  const {
    accountData,
    businessLegalDetails,
    submitForm,
    isDataLoaded,
    handleOnValueChange,
    isDataChanged,
    handleCancelClick,
  } = useAccountTabForm();
  const { isLoading, getBeneficialOwners } = useBeneficialOwners();
  useUnsavedChangesModal(isDataChanged);
  const { t } = useTranslation('settingsPage');

  const settingsLoaded = isPlacesLoaded && isDataLoaded && !isLoading;

  useEffect(() => {
    getBeneficialOwners();
  }, [getBeneficialOwners]);

  if (!settingsLoaded) {
    return <AppLoading />;
  }

  return (
    <div className={styles['account-tab__container']}>
      <Form submitForm={submitForm} validationSchema={ACCOUNT_TAB_FORM_SCHEMA} canBeEmpty>
        <SettingsBlockWrapper titleBlock={t('accountBlock.title')} className={styles['account-block__wrapper']}>
          <AccountFields data={accountData} handleOnValueChange={handleOnValueChange} />
        </SettingsBlockWrapper>
        <SettingsBlockWrapper
          titleBlock={t('businessInformationBlock.title')}
          className={styles['business-information-block__wrapper']}
        >
          <BusinessInformationFields data={businessLegalDetails} handleOnValueChange={handleOnValueChange} />
        </SettingsBlockWrapper>
        <div className={styles.buttons__container}>
          <Button className={styles['button-cancel']} outline handleClick={handleCancelClick}>
            {t('cancelButton')}
          </Button>
          <SubmitButton className={styles['button-submit']} disabled={!isDataChanged}>
            {t('saveButton')}
          </SubmitButton>
        </div>
      </Form>
      <SettingsBlockWrapper
        titleBlock={t('authentificationBlock.title')}
        titleClassName={styles['authentification-block__title']}
      >
        <AuthentificationBlock />
      </SettingsBlockWrapper>
    </div>
  );
};

export default observer(AccountTabForm);
