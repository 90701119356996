// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// components
import Document from './Document/Document';
import SettingBlockWrapper from 'components/settings/SettingsBlockWrapper/SettingsBlockWrapper';
// helpers
import useCompanyDocuments from './useCompanyDocuments';
// styles
import styles from './CompanyDocuments.module.scss';

const CompanyDocuments: React.FC = () => {
  const { t } = useTranslation('documentsPage');
  const { documents } = useCompanyDocuments();

  return (
    <SettingBlockWrapper titleBlock={t('pageTitle')} titleClassName={styles.title}>
      <div className={styles.documents__wrapper}>
        {documents?.map(item => {
          return <Document key={item.id} document={item} />;
        })}
      </div>
    </SettingBlockWrapper>
  );
};

export default observer(CompanyDocuments);
