import React from 'react';
import { observer } from 'mobx-react-lite';
import Button from 'components/Button';
import { ReactComponent as InviteIcon } from 'assets/icons/door.svg';
import styles from './InviteButton.module.scss';

type TInviteButtonProps = {
  title: string;
  isEditButton?: boolean;
  buttonClickHandler?: () => void;
};

const InviteButton: React.FC<TInviteButtonProps> = ({
  title = '',
  isEditButton = false,
  buttonClickHandler,
}: TInviteButtonProps) => {
  const buttonClassName = isEditButton ? styles['edit-description__button'] : styles.invite__button;

  return (
    <Button outline className={buttonClassName} handleClick={buttonClickHandler}>
      {title}
      {!isEditButton && <InviteIcon />}
    </Button>
  );
};

export default observer(InviteButton);
