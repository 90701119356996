// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// constants
import fieldSizes from 'shared/constants/fieldSizes';
// components
import TwoColumnRow from 'components/MultiStep/Form/TwoColumnRow/TwoColumnRow';
import FormFieldLabel from 'components/MultiStep/Form/FormFieldLabel/FormFieldLabel';
import { DateInput, NumberInput, Textarea } from 'components/Form/inputs';
// helpers
import { Invoice, InvoiceFormData } from '../../types';
import useInvoiceLoadData from '../useInvoiceLoadData';
// styles
import styles from '../InvoiceForm.module.scss';

export type TInvoiceFormProps = {
  invoice?: Partial<Invoice> | null;
};

const FormFieldsInvoice: React.FC<TInvoiceFormProps> = ({ invoice }: TInvoiceFormProps) => {
  const { t } = useTranslation(['invoice', 'forms']);
  useInvoiceLoadData(invoice);

  return (
    <div className={styles['invoice-form__body']}>
      <TwoColumnRow
        column1={<FormFieldLabel>{t('fields.amount.label')}</FormFieldLabel>}
        column2={
          <NumberInput
            name={InvoiceFormData.amount}
            prefix="$"
            placeholder="$0.00"
            className={styles['invoice-form__input']}
          />
        }
        className={styles['invoice-form__field']}
      />
      <TwoColumnRow
        column1={<FormFieldLabel>{t('fields.dueDate.label')}</FormFieldLabel>}
        column2={
          <DateInput name={InvoiceFormData.dueDate} minDate={new Date()} className={styles['invoice-form__input']} />
        }
        className={styles['invoice-form__field']}
      />
      <TwoColumnRow
        column1={
          <FormFieldLabel
            helpText={`(${t('fields.description.helpText')})`}
            className={styles['invoice-form__textarea-label']}
          >
            {t('fields.description.label')}
          </FormFieldLabel>
        }
        column2={
          <Textarea
            name={InvoiceFormData.description}
            shouldCounterBeShown={false}
            placeholder={t('fields.description.label')}
            className={styles['invoice-form__textarea']}
            maxLength={fieldSizes.INVOICE_TEXT_AREA_LIMIT}
          />
        }
        className={styles['invoice-form__field']}
      />
    </div>
  );
};

export default FormFieldsInvoice;
