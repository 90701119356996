import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// components
import PayableReceivableContent from '../PayableReceivableContent';
import Button from 'components/Button';
// mock data
import { getMockCompanies } from 'components/company/CompanySearch/mockData';
// store
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from 'components/transactions/TransactionsDashboard/PayableReceivableContent/PayableReceivableContent.module.scss';

const ReceivableDashboard: React.FC = () => {
  const { t } = useTranslation('transactionHistory');
  // TODO: need update after integration with BE
  const vendors = getMockCompanies().slice(7, 13) as any;
  const history = useHistory();

  const {
    eventReceivableStore: { receivableData },
  } = useStore();

  return (
    <PayableReceivableContent
      title={t('dashboard.receivableTitle')}
      pastDueAmount={receivableData.pastDueReceivableAmount}
      nextWeekAmount={receivableData.nextWeekReceivableeAmount}
      companies={vendors}
      companiesTitle={t('dashboard.payableCompaniesTitle')}
      getCompanyLink={data => (
        <Button
          btnLink
          className={styles['payable-receivable__companies-link']}
          handleClick={() => {
            history.push('/create-invoice', {
              companyInfo: data,
            });
          }}
        >
          {t('dashboard.invoice')}
        </Button>
      )}
    />
  );
};

export default observer(ReceivableDashboard);
