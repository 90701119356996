import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
// helpers
import LOGIN_FORM_SCHEMA from './validation-rules';
import useLoginForm from './useLoginForm';
// styles
import styles from './LoginForm.module.scss';
// constants
import Endpoints from 'shared/constants/endpoints';
// components
import EntryButton from 'components/EntryButton/EntryButton';
import Form from '../Form';
import { TextInput, PasswordInput } from '../Form/inputs';

const ATTEMPTS_TO_LOGIN = 5;

const LoginForm: React.FC = () => {
  const { t } = useTranslation('loginPage');
  const { submitForm, apiError, setApiError, isFetching, attemptsRemaining, onChangeHandler } = useLoginForm();

  const shouldShowError = !isFetching && !!apiError;
  const shouldShowAttemptsError = Boolean(attemptsRemaining) && attemptsRemaining !== ATTEMPTS_TO_LOGIN;

  return (
    <Form
      formType={Endpoints.LOGIN}
      submitForm={submitForm}
      validationSchema={LOGIN_FORM_SCHEMA}
      className="public-form-wrapper"
    >
      <Row>
        <Col xs={12} className={styles['form-column']}>
          <TextInput
            additionalOnChangeCallBack={setApiError}
            shouldShowError={shouldShowError}
            label={t('formFields.email')}
            name="email"
            placeholder={t('formFields.emailPlaceholder')}
            onChangeCallback={onChangeHandler}
          />
          <PasswordInput
            additionalOnChangeCallBack={setApiError}
            shouldShowError={shouldShowError}
            fieldWrapperClassName={styles['password-field']}
            label={t('formFields.password')}
            name="password"
            placeholder={t('formFields.passwordPlaceholder')}
            passwordRecoveryLink={t('passwordRecoveryLink')}
            autoComplete="off"
          />
          <div className={styles['field__content-error']}>{apiError}</div>
          <EntryButton>{t('submitButton')}</EntryButton>
          {shouldShowAttemptsError && (
            <div className={classNames(styles['field__content-error'], styles['attempts-error'])}>
              {t('attemptsError.youHave')}
              <span>{attemptsRemaining}</span>
              {t('attemptsError.attempts')}
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default observer(LoginForm);
