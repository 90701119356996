// libraries
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// helpers
import {
  FilterTabs,
  payableStates,
  receivableStates,
  completedStates,
} from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
import { Invoice, InvoiceStates, InvoiceTypes } from 'components/invoices/types';
import { buildQueryString } from 'shared/helpers/api';
import useInvoiceItems from 'components/invoices/useInvoiceItems';

type TransactionHistoryHelper = {
  activeTab: FilterTabs;
  changeTab: (tabName: FilterTabs) => void;
  data: Invoice[];
  shouldResetListParams: boolean;
  openInvoiceDetails: (id: string, tab?: FilterTabs) => void;
  openedInvoiceId: Nullable<string>;
  closeInvoiceDetails: () => void;
};

type TransactionHistoryParams = {
  invoiceType: FilterTabs;
  invoiceId?: string;
  [key: string]: string | undefined;
};

const getUrlStorageParams = (location: any): TransactionHistoryParams => {
  const currentSearchParams = new URLSearchParams(location.search) || {};
  const allParamsInUrl: Dictionary<string> = {};

  currentSearchParams.forEach((value, key) => {
    if (key === 'type') {
      allParamsInUrl.invoiceType = value;
    } else {
      allParamsInUrl[key] = value;
    }
  });

  return { ...allParamsInUrl, invoiceType: (allParamsInUrl.invoiceType as FilterTabs) || FilterTabs.PAYABLE };
};

const useTransactionHistory = (): TransactionHistoryHelper => {
  const [shouldResetListParams, setShouldResetListParams] = useState(false);
  const [activeTab, setActiveTab] = useState(FilterTabs.PAYABLE);
  const [openedInvoiceId, setOpenedInvoiceId] = useState<Nullable<string>>(null);
  const [data, setData] = useState<Invoice[]>([]);
  const { invoiceItems } = useInvoiceItems();

  const history = useHistory();
  const location = useLocation();

  const [params] = useState(getUrlStorageParams(location));

  const updateParamsInUrl = ({ invoiceType = FilterTabs.PAYABLE, invoiceId }: TransactionHistoryParams) => {
    const urlStorageParams = getUrlStorageParams(location);
    const searchString = buildQueryString({
      ...urlStorageParams,
      invoiceType: undefined,
      type: invoiceType,
      invoiceId,
    });

    if (location.search !== searchString) {
      history.replace({
        search: searchString,
      });
    }
  };

  const changeTab = (tabName: FilterTabs) => {
    updateParamsInUrl({ invoiceType: tabName });
    setActiveTab(tabName);
    setShouldResetListParams(true);
    setOpenedInvoiceId(null);
  };

  const openInvoiceDetails = (id: string, invoiceType?: FilterTabs) => {
    updateParamsInUrl({ invoiceType: invoiceType || activeTab, invoiceId: id });
    setOpenedInvoiceId(id);

    if (invoiceType) {
      setActiveTab(invoiceType);
      setShouldResetListParams(true);
    }
  };

  const closeInvoiceDetails = () => {
    updateParamsInUrl({ invoiceType: activeTab });
    setOpenedInvoiceId(null);
  };

  const getTabData = () => {
    let filteredData: Invoice[] = [];

    switch (activeTab) {
      case FilterTabs.PAYABLE:
        // TODO: remove ignore after integration with BE
        // @ts-ignore
        filteredData = invoiceItems.filter(
          ({ invoiceType, invoiceState = InvoiceStates.new }) =>
            invoiceType === InvoiceTypes.payable && payableStates.includes(invoiceState),
        );
        break;

      case FilterTabs.RECEIVABLE:
        // TODO: remove ignore after integration with BE
        // @ts-ignore
        filteredData = invoiceItems.filter(
          ({ invoiceType, invoiceState = InvoiceStates.new }) =>
            invoiceType === InvoiceTypes.receivable && receivableStates.includes(invoiceState),
        );
        break;

      case FilterTabs.COMPLETE:
        // TODO: update after integration with BE
        // @ts-ignore
        filteredData = invoiceItems.filter(({ invoiceState = InvoiceStates.new }) =>
          completedStates.includes(invoiceState),
        );
        break;

      default:
        filteredData = [];
    }

    setData(filteredData);
    setShouldResetListParams(false);
  };

  useEffect(() => {
    setActiveTab(params.invoiceType);
    setOpenedInvoiceId(params.invoiceId || null);
    setData([]);
  }, [params]);

  useEffect(() => {
    getTabData();
    // Suppressed warnings because we should update data only if params were updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, invoiceItems]);

  return {
    activeTab,
    changeTab,
    data,
    shouldResetListParams,
    openInvoiceDetails,
    closeInvoiceDetails,
    openedInvoiceId,
  };
};

export default useTransactionHistory;
