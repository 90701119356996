// libraries
import React from 'react';
import classNames from 'classnames';
// styles
import styles from './TwoColumnRow.module.scss';

type TTwoColumnRowProps = {
  column1?: React.ReactNode;
  column2?: React.ReactNode;
  className?: string;
};

const TwoColumnRow: React.FC<TTwoColumnRowProps> = ({ column1, column2, className = '' }: TTwoColumnRowProps) => {
  return (
    <div className={classNames(styles['two-column'], className)}>
      <div>{column1}</div>
      <div>{column2}</div>
    </div>
  );
};

export default TwoColumnRow;
