// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EntryTitle from 'components/EntryTitle/EntryTitle';
import RegisterForm from 'components/signUpForms/RegisterForm/RegisterForm';
// constants
import { RegisterFormData } from 'components/signUpForms/useSignUpForm';

interface IRegisterProps {
  submitRegisterForm: (data: RegisterFormData) => Promise<void>;
}

const Register: React.FC<IRegisterProps> = ({ submitRegisterForm }: IRegisterProps) => {
  const { t } = useTranslation('signUpPage');

  return (
    <>
      <EntryTitle
        link="/login"
        linkText={t('registerForm.linkText')}
        subtitle={t('registerForm.subtitle')}
        title={t('registerForm.title')}
      />
      <RegisterForm submitRegisterForm={submitRegisterForm} />
    </>
  );
};

export default Register;
