// libraries
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// components
import AddButton from 'components/Button/AddButton';
import ConnectedAccountsList from './ConnectedAccountsList/ConnectedAccountsList';
import SettingBlockWrapper from 'components/settings/SettingsBlockWrapper/SettingsBlockWrapper';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import usePlaid from 'components/paymentMethods/PaymentMethod/PaymentOptions/usePlaid';
// styles
import styles from './PaymentMethodsForm.module.scss';
// mocks
import mockedAccountsData from './mockAccountsData';

const PaymentMethodsForm: React.FC = () => {
  const { t } = useTranslation('paymentMethodsSettings');
  const { openPlaidModal } = usePlaid();

  const {
    paymentMethods: { setAccounts },
  } = useStore();

  // TODO delete after integration with BE
  useEffect(() => {
    setAccounts(mockedAccountsData);
  }, []);

  return (
    <SettingBlockWrapper titleBlock={t('titleSetting')} titleClassName={styles.title}>
      <div className={styles['payment-setting__wrapper']}>
        <div className={styles.info__wrapper}>
          <b className={styles.subtitle}>{t('subTitleInfo')}</b>
          <span>
            <p className={styles['text-info']}>&bull; {t('infoText.fundingText')}</p>
            <p className={styles['text-info']}>&bull; {t('infoText.depositText')}</p>
          </span>
        </div>

        <ConnectedAccountsList />

        <AddButton label={t('addAccount')} handleClick={() => openPlaidModal()} />
      </div>
    </SettingBlockWrapper>
  );
};

export default observer(PaymentMethodsForm);
