// libraries
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import PaymentLayout from 'shared/layouts/payment';
import PaymentReview from '../PaymentReview/PaymentReview';
import Button from 'components/Button';
import InvoiceOwnerInfo from 'components/invoices/InvoiceOwnerInfo/InvoiceOwnerInfo';
// helpers
import { Invoice, InvoiceStates } from 'components/invoices/types';
import stall from 'shared/helpers/simulateAsyncCall';
// styles
import styles from 'components/externalPayments/AccountReceivable/AccountReceivable.module.scss';
import paymentFormStyles from '../Payment.module.scss';

interface IConfirmationPage {
  invoiceDetails: Nullable<Invoice>;
  setInvoiceDetails: (data: Invoice) => void;
}

const ConfirmationPage: React.FC<IConfirmationPage> = ({ invoiceDetails, setInvoiceDetails }: IConfirmationPage) => {
  const { t } = useTranslation('externalPaymentPage');
  const [isLoading, setIsLoading] = useState(false);
  const isScheduled = invoiceDetails?.invoiceState === InvoiceStates.scheduled;

  const unscheduleInvoice = useCallback(async () => {
    setIsLoading(true);
    await stall();

    setInvoiceDetails({ ...invoiceDetails, invoiceState: InvoiceStates.pending } as Invoice);
    setIsLoading(false);
  }, [invoiceDetails, setInvoiceDetails]);

  const updatePayDate = useCallback(
    async (date: Date) => {
      setIsLoading(true);
      await stall(date);

      setInvoiceDetails({ ...invoiceDetails, payDate: date } as Invoice);
      setIsLoading(false);
    },
    [setInvoiceDetails, invoiceDetails],
  );

  const downloadInvoice = useCallback(async () => {
    setIsLoading(true);
    await stall(invoiceDetails?.invoiceId);

    setIsLoading(false);
  }, [invoiceDetails]);

  return (
    <PaymentLayout companyName={invoiceDetails?.counterpartyDetails?.dbaName}>
      <div className={styles.page}>
        <div className={styles.page__wrapper}>
          <h1 className={classnames(styles.page__title, paymentFormStyles.page__title)}>{t('titleConfirmed')}</h1>
          <div className={classnames(paymentFormStyles.page__content, paymentFormStyles['confirmation-page'])}>
            {isScheduled ? (
              <div className={paymentFormStyles['scheduled-label']}>{t('scheduled')}</div>
            ) : (
              <div className={paymentFormStyles['completed-label']}>{t('completed')}</div>
            )}
            <div className={styles['page__invoice-owner']}>
              <InvoiceOwnerInfo
                logoSrc={invoiceDetails?.counterpartyDetails?.logoImage}
                companyName={invoiceDetails?.counterpartyDetails?.dbaName}
                companyEmail={invoiceDetails?.counterpartyDetails?.email}
                invoiceNumber={invoiceDetails?.invoiceNumber}
              />
            </div>
            <PaymentReview
              paymentDate={invoiceDetails?.payDate as Nullable<Date>}
              paymentAccount={invoiceDetails?.paymentAccount}
              setPaymentDate={updatePayDate}
              invoiceDetails={invoiceDetails}
              isConfirmed={!isScheduled}
              isScheduled={isScheduled}
              isLoading={isLoading}
              className={paymentFormStyles['confirmation-page__wrapper']}
              unscheduleInvoice={unscheduleInvoice}
            >
              {isScheduled ? (
                <>
                  <Button
                    className={paymentFormStyles['download-button']}
                    disabled={isLoading}
                    outline
                    handleClick={downloadInvoice}
                  >
                    {t('button.download')}
                  </Button>
                  <Button
                    className={paymentFormStyles['unschedule-button']}
                    disabled={isLoading}
                    handleClick={unscheduleInvoice}
                  >
                    {t('button.unschedule')}
                  </Button>
                </>
              ) : (
                <Button
                  className={paymentFormStyles['download-invoice-button']}
                  disabled={isLoading}
                  outline
                  handleClick={downloadInvoice}
                >
                  {t('button.downloadInvoice')}
                </Button>
              )}
            </PaymentReview>
          </div>
        </div>
      </div>
    </PaymentLayout>
  );
};

export default ConfirmationPage;
