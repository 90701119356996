// libraries
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// assets
import { ReactComponent as FilledTrashIcon } from 'assets/icons/filled-trash-icon.svg';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { ButtonColor } from 'components/Button';
import { CreateNewPasswordFormData } from 'components/resetPassword/usePasswordResetForm';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import useLogout from 'shared/hooks/useLogout';
import stall, { getMockData } from 'shared/helpers/simulateAsyncCall';
import { FormData } from 'components/Form/FormContext';
import { addErrorToast, addSuccessToast } from 'components/Toast/toastHelper';
import { AccountDataProps, BusinessInformationDataProps } from 'components/settings/AccountTabForm/types';
import { getMockUserProfile, updateMockUserProfile } from 'components/LoginForm/mockData';
import {
  getMockBusinessLegalDetails,
  updateMockBusinessLegalDetails,
} from 'components/settings/AccountTabForm/mockData';
// styles
import styles from 'components/settings/AccountTabForm/AuthentificationBlock/AuthentificationBlock.module.scss';

const accountDataInitialValues = { firstName: '', lastName: '', email: '' };

const businessInformationDataInitialValues = {
  legalName: '',
  address: '',
  taxId: '',
  idNumber: '',
};

const useAccountTabForm = () => {
  const { t } = useTranslation('settingsPage');
  const [accountData, setAccountData] = useState<AccountDataProps>(accountDataInitialValues);
  const [businessLegalDetails, setBusinessLegalDetails] = useState<BusinessInformationDataProps>(
    businessInformationDataInitialValues,
  );
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [prevPath, setPrevPath] = useState('/');
  const { state: locationState = {} } = useLocation();
  const history = useHistory();
  // TODO: Update after integration with BE
  const { logout } = useLogout();

  useEffect(() => {
    if (locationState) {
      setPrevPath(locationState.prevPath);
    }
  }, [locationState]);

  const {
    authStore: { userId },
    modalStore: { openModal, closeModal },
  } = useStore();

  const loadData = useCallback(async () => {
    setIsDataLoaded(false);
    try {
      const [userAccountData, businessLegalDetailsData] = await Promise.all([
        getMockData(getMockUserProfile(userId)),
        getMockData(getMockBusinessLegalDetails()),
      ]);

      setAccountData(userAccountData);
      setBusinessLegalDetails(businessLegalDetailsData);
    } catch (e) {
      const { message = '' } = await e;
    } finally {
      setIsDataLoaded(true);
    }
  }, [userId]);

  const submitForm = async (data: FormData) => {
    try {
      await stall(data);
      addSuccessToast(t('successToast.confirmationMessage'), t('successToast.confirmationTitle'));
      updateMockBusinessLegalDetails(data);
      updateMockUserProfile(userId, data);
      setIsDataChanged(false);
    } catch (e) {
      const { message = '' } = await e;
      addErrorToast(message);
    }
  };

  const handleCancelClick = () => {
    history.push(prevPath);
  };

  const handleOnValueChange = useCallback(() => {
    setIsDataChanged(true);
  }, []);

  const openChangePasswordModal = useCallback(() => {
    openModal(ModalIds.changePasswordModal);
  }, [openModal]);

  const submitChangePasswordModal = async (data: CreateNewPasswordFormData) => {
    try {
      await stall(data);
      addSuccessToast(
        t('accountBlock.changePasswordModal.successToast.confirmationMessage'),
        t('accountBlock.changePasswordModal.successToast.confirmationTitle'),
      );
      closeModal(ModalIds.changePasswordModal);
    } catch (e) {
      const { message = '' } = await e;
    }
  };
  const submitSendCodeToPhoneForm = useCallback(async (data: FormData) => {
    try {
      await stall(data);
    } catch (e) {
      const { message = '' } = await e;
    }
  }, []);

  const submitEnterCodeForm = useCallback(async (data: FormData) => {
    try {
      await stall(data);
    } catch (e) {
      const { message = '' } = await e;
    }
  }, []);

  const openConfirmationModal = useCallback(() => {
    openModal(ModalIds.confirmationModal, {
      icon: <FilledTrashIcon width={36} height={36} />,
      title: t('authentificationBlock.closeAccount.modal.title'),
      content: t('authentificationBlock.closeAccount.modal.content'),
      classNameTitle: styles['confirm-modal__title'],
      classNameContent: styles['confirm-modal__content'],
      cbConfirmActionFunction: () => {
        logout();
        history.push('/sign-up');
      },
      cancelButtonText: t('authentificationBlock.closeAccount.modal.cancelButton'),
      confirmButtonText: t('authentificationBlock.closeAccount.modal.deleteButton'),
      confirmButtonProps: { color: ButtonColor.danger },
    });
  }, [logout, openModal, t, history]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {
    accountData,
    businessLegalDetails,
    submitForm,
    isDataLoaded,
    handleOnValueChange,
    isDataChanged,
    handleCancelClick,
    openChangePasswordModal,
    submitChangePasswordModal,
    submitSendCodeToPhoneForm,
    submitEnterCodeForm,
    openConfirmationModal,
  };
};

export default useAccountTabForm;
