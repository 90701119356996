import React from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
// components
import Form from 'components/Form';
import { Select, TextInput } from 'components/Form/inputs';
import RegistrationCompanyButtons from '../Buttons/RegistrationCompanyButtons';
// constants
import { STATES } from 'shared/constants/states';
import BUSINESS_TYPES from 'shared/constants/businessTypes';
// hooks
import useRegistrationCompanyForm from '../useRegistrationCompanyForm';
// helpers
import ADDITIONAL_INFORMATION_FORM_SCHEMA from './validation-rules';
// styles
import styles from './AdditionalInformationForm.module.scss';

const AdditionalInformationForm = () => {
  const { t } = useTranslation('registrationCompanyModals');
  const { isButtonsDisabled, submitForm } = useRegistrationCompanyForm();

  return (
    <Form
      // TODO Must be changed after integration
      formType="reg-company-additional"
      submitForm={submitForm}
      canBeEmpty
      validationSchema={ADDITIONAL_INFORMATION_FORM_SCHEMA}
    >
      <div className={styles['fields-wrapper']}>
        <TextInput
          fieldWrapperClassName={styles['field-wrapper']}
          fieldLabelClassName={styles['input-label']}
          placeholder={t('additionalModal.websitePlaceholder')}
          name="website"
          className={styles.field__input}
          label={t('additionalModal.websiteLabel')}
          inputWrapperClassName={styles['input-wrapper']}
        />

        <TextInput
          inputWrapperClassName={styles['input-wrapper']}
          fieldWrapperClassName={styles['field-wrapper']}
          fieldLabelClassName={styles['input-label']}
          className={styles.field__input}
          placeholder={t('additionalModal.cityPlaceholder')}
          name="city"
          label={t('additionalModal.cityLabel')}
        />

        <Select
          placeholder={t('additionalModal.statePlaceholder')}
          name="state"
          isFormField
          options={STATES}
          menuPlacement="auto"
          listSize="200px"
          isClearable
          label={t('additionalModal.stateLabel')}
          inputWrapperClassName={styles['input-wrapper']}
          fieldLabelClassName={styles['input-label']}
          fieldWrapperClassName={styles['field-wrapper']}
        />

        <TextInput
          inputWrapperClassName={styles['input-wrapper']}
          fieldLabelClassName={styles['input-label']}
          fieldWrapperClassName={styles['field-wrapper']}
          name="year"
          className={styles.field__input}
          placeholder={t('additionalModal.yearPlaceholder')}
          label={t('additionalModal.yearLabel')}
        />

        <Select
          placeholder={t('additionalModal.industryPlaceholder')}
          name="industry"
          isFormField
          isClearable
          options={sortBy(BUSINESS_TYPES, ['value'])}
          menuPlacement="auto"
          listSize="150px"
          label={t('additionalModal.industryLabel')}
          inputWrapperClassName={styles['input-wrapper']}
          fieldLabelClassName={styles['input-label']}
          fieldWrapperClassName={styles['field-wrapper']}
        />
      </div>
      <RegistrationCompanyButtons
        buttonWrapperClassName={styles.buttons__wrapper}
        isButtonsDisabled={isButtonsDisabled}
      />
    </Form>
  );
};

export default AdditionalInformationForm;
