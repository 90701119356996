// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// components
import TransactionCardFooterActionItem from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooterActions/TransactionCardFooterActionItem';
// assets
import { ReactComponent as ClockIcon } from 'assets/icons/clock-icon.svg';
// helpers
import stall from 'shared/helpers/simulateAsyncCall';
import { Invoice, InvoiceStates } from 'components/invoices/types';
import { useListContext } from 'components/list/ListContext';
import { useAppLoadingContext } from 'components/AppLoading/AppLoadingContext';
import useInvoiceItems from 'components/invoices/useInvoiceItems';

interface IUnschedulePayableInvoice {
  invoiceDetails: Invoice;
}

const UnschedulePayableInvoice: React.FC<IUnschedulePayableInvoice> = ({
  invoiceDetails,
}: IUnschedulePayableInvoice) => {
  const { t } = useTranslation('transactionHistory');
  const { updateInList = () => {} } = useListContext();
  const { updateInvoiceItem } = useInvoiceItems();
  const { setIsLoading } = useAppLoadingContext();

  const unscheduleInvoice = useCallback(async () => {
    setIsLoading(true);

    // TODO: add request to BE
    await stall();

    updateInList(invoiceDetails.invoiceId, { ...invoiceDetails, invoiceState: InvoiceStates.pending, payDate: null });
    updateInvoiceItem(invoiceDetails.invoiceId, { invoiceState: InvoiceStates.pending, payDate: null });
    setIsLoading(false);
  }, [invoiceDetails, updateInList, setIsLoading, updateInvoiceItem]);

  return (
    <TransactionCardFooterActionItem onClick={unscheduleInvoice}>
      <ClockIcon width={9} height={9} />
      {t('invoiceActions.unschedule')}
    </TransactionCardFooterActionItem>
  );
};

export default UnschedulePayableInvoice;
