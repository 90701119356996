// libraries
import React, { memo, PropsWithChildren } from 'react';
import { Container } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
// components
import Header from 'shared/layouts/public/Header/Header';
import Footer from './Footer/Footer';
import GlobalModals from 'components/Modal/GlobalModals/GlobalModals';
// styles
import styles from './styles.module.scss';
import publicStyles from '../public/styles.module.scss';

type BaseLayoutProps = PropsWithChildren<{
  children?: React.ReactNode;
  companyName?: string;
  hideFooter?: boolean;
}>;

const PaymentLayout: React.FC<BaseLayoutProps> = ({ children = null, companyName = '', hideFooter = false }) => (
  <>
    <div className={publicStyles['layout--public-full']}>
      <Header isNavigationVisible />
      <main className={styles.layout__main}>
        <Container className="layout__container p-0">
          <div className={styles['layout__container-wrapper']}>{children}</div>
        </Container>
      </main>
      {!hideFooter && <Footer companyName={companyName} />}
    </div>
    <GlobalModals />
  </>
);

export default memo(observer(PaymentLayout));
