import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';

export enum InvoiceTypes {
  payable = 'Payable',
  receivable = 'Receivable',
  fundsTransfer = 'FundsTransfer',
}

export enum FundsTransferTypes {
  add = 'AddFunds',
  transfer = 'TransferFunds',
}

export enum InvoiceStates {
  invalid = 'Invalid',
  new = 'New',
  inTransit = 'InTransit',
  draft = 'Draft',
  cancelled = 'Cancelled',
  scheduled = 'Scheduled',
  pending = 'Pending',
  rejected = 'Rejected',
  markedPaid = 'MarkedPaid',
  paid = 'Paid',
  settled = 'Settled',
}

export interface IInvoiceBankDetails {
  bankName: string;
  cardNumber: string;
  logoImage: string;
}

export enum InvoiceFormData {
  amount = 'amount',
  dueDate = 'dueDate',
  description = 'description',
}

export interface Invoice {
  lastModifiedTimestamp: number;
  primaryKey: string;
  bizId: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceVersion: number;
  invoiceType: InvoiceTypes;
  transferType?: FundsTransferTypes;
  invoiceState: InvoiceStates;
  invoiceDate: number;
  dueDate: number;
  description: string;
  totalAmount: number;
  balanceDue: number;
  pastDue: boolean;
  counterpartyDetails: Partial<IBusinessSnapshot & IInvoiceBankDetails>;
  counterpartyMessage: string;
  systemMessage: string;
  message: string;
  payDate?: Nullable<number | Date>;
  isManuallyAdded?: boolean;
  isReceived?: boolean;
  paymentAccount?: Account;
}
