import { Entity } from 'shared/api/crud/Crud';
import { HttpMethod } from 'shared/api/AbstractRequest';
import PublicRequest from 'shared/api/public/PublicRequest';
import ProtectedRequest from 'shared/api/protected/ProtectedRequest';
import { ResponsePromise } from 'shared/api/AbstractRequestManager';

class FileUploadApi {
  readonly resourceUrl = '/file/upload-link';

  getUploadLinkRequest(data: Entity) {
    const resourceUri = `${this.resourceUrl}`;

    const params = { body: { ...data }, method: HttpMethod.POST };

    return new ProtectedRequest(resourceUri, params).send();
  }
}

export const uploadFileRequest = (s3link: string, data: BodyInit, type: string): ResponsePromise => {
  const params = { body: data, method: HttpMethod.PUT, contentType: type };

  return new PublicRequest(s3link, params).send();
};

export default new FileUploadApi();
