import * as Yup from 'yup';
import {
  getLatinNameSchema,
  getFirstAndLastNameSchema,
  getLatinNameSchemaWithNumberAndApostrophe,
  getTaxIdNameSchema,
  getIdNumberSchema,
  getPhoneSchema,
} from 'shared/helpers/validationSchemes';

const isExcludeEmptyString = true;

export const ACCOUNT_TAB_FORM_SCHEMA = Yup.object().shape({
  firstName: getFirstAndLastNameSchema().required('forms.firstName.required'),
  lastName: getFirstAndLastNameSchema().required('forms.lastName.required'),
  email: Yup.string().email('forms.email.invalid').required('forms.email.required'),
  legalName: getLatinNameSchema(isExcludeEmptyString).notRequired(),
  address: getLatinNameSchemaWithNumberAndApostrophe(isExcludeEmptyString).notRequired(),
  taxId: getTaxIdNameSchema(isExcludeEmptyString).notRequired(),
  idNumber: getIdNumberSchema(isExcludeEmptyString).notRequired(),
});

export const SEND_CODE_TO_PHONE_FORM_SCHEMA = Yup.object().shape({
  phone: getPhoneSchema().required('forms.phone.required'),
});

export const ENTER_CODE_FORM_SCHEMA = Yup.object().shape({
  code: Yup.string().required('forms.resetCode.required'),
});
