// libraries
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
// components
import Lock from './Lock/Lock';
import AppLoading from 'components/AppLoading/AppLoading';
// helpers
import { addSuccessToast } from 'components/Toast/toastHelper';
// styles
import styles from './ConfirmationPage.module.scss';

const ConfirmationPage: React.FC = () => {
  const { t } = useTranslation('verification');
  const history = useHistory();

  const handleAnimationEnd = useCallback(() => {
    history.push('/');
    addSuccessToast(t('confirmationMessage'), t('confirmationTitle'));
  }, [history, t]);

  return (
    <div className={styles.wrapper}>
      <Lock handleAnimationEnd={handleAnimationEnd} />
      <h1 className={styles.title}>You’re all set!</h1>
      <p className={styles.subtitle}>You’re account has been verified.</p>
      <AppLoading className={styles.loader} />
    </div>
  );
};

export default ConfirmationPage;
