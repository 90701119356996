// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { TextInput } from 'components/Form/inputs';
import SubmitButton from 'components/Form/buttons/SubmitButton';
// helpers
import { useFormContext } from 'components/Form/FormContext';
import areFieldsFullfilled from 'shared/helpers/inputs';
// styles
import styles from 'components/settings/AccountTabForm/AccountTabForm.module.scss';
import authentificationBlockStyles from 'components/settings/AccountTabForm/AuthentificationBlock/AuthentificationBlock.module.scss';

const currentPageField = ['code'];

const CodeFormFields = () => {
  const { t } = useTranslation('settingsPage');
  const { data } = useFormContext();

  const isButtonDisabled = !areFieldsFullfilled(data, currentPageField);

  return (
    <div className={authentificationBlockStyles['code-input__wrapper']}>
      <TextInput
        name="code"
        label={t('authentificationBlock.fields.code.label')}
        placeholder={t('authentificationBlock.fields.code.placeholder')}
        fieldWrapperClassName={styles.field__wrapper}
        inputWrapperClassName={styles.input__wrapper}
        fieldLabelClassName={styles['field-label']}
      />
      <SubmitButton className={authentificationBlockStyles['submit-code']} disabled={isButtonDisabled}>
        {t('authentificationBlock.fields.code.button')}
      </SubmitButton>
    </div>
  );
};

export default CodeFormFields;
