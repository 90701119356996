// libraries
import React from 'react';
// components
import TransactionCardNavigation from 'components/transactions/TransactionCard/TransactionCardNavigation';
import InvoiceCard from './InvoiceCard';
import TransferFundsCard from './TransferFundsCard';
// helpers
import { InvoiceTypes, Invoice } from 'components/invoices/types';
// styles
import styles from './TransactionCard.module.scss';

interface ITransactionCard {
  closeCard: () => void;
  data: Invoice;
  children?: React.ReactNode;
  mainBreadcrumbItemTitle: string;
}

const TransactionCard: React.FC<ITransactionCard> = ({
  closeCard,
  data,
  children,
  mainBreadcrumbItemTitle,
}: ITransactionCard) => {
  const { invoiceType } = data || {};

  return (
    <div className={styles['transaction-card__wrapper']}>
      <TransactionCardNavigation closeCard={closeCard} mainBreadcrumbItemTitle={mainBreadcrumbItemTitle} />
      {invoiceType === InvoiceTypes.fundsTransfer ? (
        <TransferFundsCard data={data}>{children}</TransferFundsCard>
      ) : (
        <InvoiceCard data={data}>{children}</InvoiceCard>
      )}
    </div>
  );
};

export default TransactionCard;
