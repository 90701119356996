// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import UNLOCK_ACCOUNT_FORM_SCHEMA from './validation-rules';
// components
import ResetPasswordFormType from 'components/resetPassword/ResetPasswordFormType/ResetPasswordFormType';
// types
import { UnlockAccountFormData } from 'components/resetPassword/usePasswordResetForm';
// styles
import styles from 'components/resetPassword/UnlockAccountForm/UnlockAccountForm.module.scss';

interface IUnlockAccountProps {
  submitUnlockAccountForm: (data: UnlockAccountFormData, userEmail: string) => void;
  email: string;
}

const UnlockAccount: React.FC<IUnlockAccountProps> = ({ submitUnlockAccountForm, email }: IUnlockAccountProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <div className={styles['unlock-account-form__container']}>
      <ResetPasswordFormType
        onSubmit={data => submitUnlockAccountForm(data as UnlockAccountFormData, email)}
        validationSchema={UNLOCK_ACCOUNT_FORM_SCHEMA}
        labelName={t('unlockAccount.fields.resetCode.label')}
        inputPlaceholder={t('unlockAccount.fields.resetCode.placeholder')}
        inputName="resetCode"
        buttonTitle={t('unlockAccount.submitButton')}
        buttonClassName={styles['submit-button']}
      />
    </div>
  );
};

export default UnlockAccount;
