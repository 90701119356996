import React from 'react';
import { RouteComponentProps } from 'react-router';
import ProtectedRoute from '../components/Auth/ProtectedRoute/ProtectedRoute';
import PublicRoute from '../components/Auth/PublicRoute/PublicRoute';
import { Route, RenderedRoute } from './Routes';

// eslint-disable-next-line import/prefer-default-export
export const mapRoute = ({ isProtected = true, ...route }: Route): Nullable<RenderedRoute> => {
  if (!route.component) return null;

  const ComponentToRender = route.component || '';

  const routeProps = {
    ...route,
    key: route.path as string,
    // eslint-disable-next-line react/display-name
    render: (props: RouteComponentProps) => <ComponentToRender {...props} />,
  };

  return isProtected ? <ProtectedRoute {...routeProps} /> : <PublicRoute {...routeProps} />;
};
