// libraries
import { useCallback, useState } from 'react';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { ModalData } from 'shared/stores/ModalStore/ModalStore';
// helpers
import { addErrorToast } from 'components/Toast/toastHelper';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

type ConfirmationModalParams = {
  handleCancelAction: () => void;
  handleConfirmAction: () => void;
  isLoading: boolean;
  modalData: Nullable<ModalData>;
};

const useConfirmationModal = (customId = ''): ConfirmationModalParams => {
  const {
    modalStore: { closeModal, modalData },
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelAction = useCallback(() => {
    closeModal(customId || ModalIds.confirmationModal);

    if (modalData?.cbCancelActionFunction) {
      modalData?.cbCancelActionFunction();
    }
  }, [closeModal, modalData]);

  const handleConfirmAction = useCallback(async () => {
    if (!modalData?.cbConfirmActionFunction) {
      handleCancelAction();
    }

    setIsLoading(true);

    try {
      await modalData?.cbConfirmActionFunction();
    } catch (e) {
      const { message } = await e;
      addErrorToast(message);
    } finally {
      setIsLoading(false);
      closeModal(customId || ModalIds.confirmationModal);
    }
  }, [handleCancelAction, modalData, closeModal]);

  return {
    handleCancelAction,
    handleConfirmAction,
    isLoading,
    modalData,
  };
};

export default useConfirmationModal;
