import { useEffect, useState } from 'react';
import { FormData } from 'components/Form/FormContext';
import stall from 'shared/helpers/simulateAsyncCall';
import useMyBusinessProfile from '../CompanyProfileForm/useMyBusinessProfile';

const useCompanyDescription = ({ description, id }: { description: string; id?: string }) => {
  const { myBusinessProfile, updateData } = useMyBusinessProfile();
  const [companyDescription, setDescription] = useState(description);
  const [isEditFormShown, setIsEditFormShown] = useState(false);

  useEffect(() => {
    setDescription(description.trim());
  }, [description]);

  const showEditForm = () => {
    setIsEditFormShown(true);
  };

  const hideEditForm = () => {
    setIsEditFormShown(false);
  };

  const handleChange = async (data: FormData) => {
    // TODO: Remove after BE integration
    await stall(id || myBusinessProfile?.id);
    const newDescription = data.description?.trim().toString();
    setDescription(newDescription || '');

    if (!id || id === myBusinessProfile?.id) {
      updateData({ description: newDescription });
    }

    hideEditForm();
  };

  return {
    companyDescription,
    isEditFormShown,
    showEditForm,
    handleChange,
    hideEditForm,
  };
};

export default useCompanyDescription;
