import React from 'react';
// components
import PayableDashboard from './PayableDashboard';
import ReceivableDashboard from './ReceivableDashboard';
import CompletedDashboard from './CompletedDashboard/CompletedDashboard';
// helpers
import { FilterTabs } from '../TransactionHistory/transactionHistoryHelpers';

interface ITransactionsDashboard {
  activeTab: FilterTabs;
  isVisible?: boolean;
}

const TransactionsDashboard: React.FC<ITransactionsDashboard> = ({
  activeTab,
  isVisible = true,
}: ITransactionsDashboard) => {
  if (!isVisible) {
    return null;
  }

  if (activeTab === FilterTabs.RECEIVABLE) {
    return <ReceivableDashboard />;
  }

  if (activeTab === FilterTabs.PAYABLE) {
    return <PayableDashboard />;
  }

  return <CompletedDashboard />;
};

export default TransactionsDashboard;
