// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// assets
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
// components
import { TextInput } from 'components/Form/inputs';
import { TextInputProps } from 'components/Form/inputs/TextInput/TextInput';
// styles
import styles from './CompanySearchInput.module.scss';

export type CompanySearchInputProps = TextInputProps & {
  onSearch: (query: string) => void;
  additionalInputText?: React.ReactElement;
  searchInputContainerClassName?: string;
  iconClassName?: string;
  inputValue: string;
};

const CompanySearchInput: React.FC<CompanySearchInputProps> = ({
  onSearch,
  additionalInputText,
  searchInputContainerClassName,
  iconClassName,
  inputValue,
  ...props
}: CompanySearchInputProps) => {
  const { t } = useTranslation('companySearch');
  const { name, className, value, children, onChangeCallback } = props;

  const inputClassName = classNames(styles.input, className);

  return (
    <div className={styles['search-input__wrapper']}>
      <TextInput
        name={name}
        fieldWrapperClassName={styles.field__wrapper}
        placeholder={t('searchInput.placeholder')}
        fieldLabelClassName={styles['input-label']}
        inputWrapperClassName={styles.input__wrapper}
        className={inputClassName}
        errorInputClassName={styles['input--invalid']}
        autoComplete="off"
        onChangeCallback={onChangeCallback}
        value={value || inputValue}
      >
        <SearchIcon className={classNames(styles['search-input__icon'], iconClassName)} />
        {additionalInputText}
      </TextInput>
      {children}
    </div>
  );
};

export default CompanySearchInput;
