// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Button, { ButtonProps, ButtonType } from '../../Button';
// helpers
import { useFormContext } from '../FormContext';

const SubmitButton: React.FC<Partial<ButtonProps>> = (props: Partial<ButtonProps>) => {
  const { isSubmitEnabled = false } = useFormContext();
  const { t } = useTranslation('forms');
  const { className = '', children = t('submitButtonDefaultText'), disabled = false } = props;

  return (
    <Button {...props} className={className} disabled={disabled || !isSubmitEnabled} type={ButtonType.submit}>
      {children}
    </Button>
  );
};

export default SubmitButton;
