// libraries
import React from 'react';
import Tab from './Tab/Tab';

export enum PaymentTabs {
  SIGN_UP = 'signUp',
  GUEST = 'guest',
}
export type TTabsProps = {
  activeTab: PaymentTabs;
  changeTab: (tabName: PaymentTabs) => void;
};

const Tabs: React.FC<TTabsProps> = ({ activeTab, changeTab }: TTabsProps) => {
  return (
    <div>
      {Object.values(PaymentTabs).map(tabName => (
        <Tab isActive={activeTab === tabName} changeTab={changeTab} key={tabName}>
          {tabName}
        </Tab>
      ))}
    </div>
  );
};

export default Tabs;
