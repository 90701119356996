// libraries
import React from 'react';
// components
import Tab from 'components/transactions/TransactionHistory/Tabs/Tab/Tab';
// helpers
import { FilterTabs } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
// styles
import styles from './Tabs.module.scss';

export type TabsProps = {
  activeTab: FilterTabs;
  disabledTab?: FilterTabs;
  changeTab: (tabName: FilterTabs) => void;
};

const Tabs: React.FC<TabsProps> = ({ activeTab, changeTab, disabledTab }: TabsProps) => {
  return (
    <div className={styles.tabs}>
      {Object.values(FilterTabs).map(tabName => (
        <Tab isActive={activeTab === tabName} isDisabled={disabledTab === tabName} changeTab={changeTab} key={tabName}>
          {tabName}
        </Tab>
      ))}
    </div>
  );
};

export default Tabs;
