// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import AppLoading from 'components/AppLoading/AppLoading';
import Scrollbar from 'components/Scrollbar/Scrollbar';
import AddNewBusiness from './AddNewBusiness/AddNewBusiness';
// styles
import styles from './CompanyList.module.scss';

type CompanyListProps = {
  companiesLength: number;
  isLoaded: boolean;
  children: React.ReactNode;
  companyListContainerClassName: string;
  newBusinessWrapperClassName?: string;
  companyListTitle?: string;
  value: string;
  scrollBarClassName?: string;
  addNewBusinessOnClickHandler?: () => void;
};

const CompanyList: React.FC<CompanyListProps> = ({
  isLoaded,
  children,
  companiesLength,
  companyListContainerClassName,
  newBusinessWrapperClassName,
  companyListTitle,
  value,
  scrollBarClassName,
  addNewBusinessOnClickHandler,
}: CompanyListProps) => {
  const { t } = useTranslation('companySearch');

  const hasNotResults = useMemo(() => !companiesLength && isLoaded, [companiesLength, isLoaded]);

  const loaderWrapper = classNames(styles.loader__wrapper, {
    [styles['loader__wrapper--active']]: !isLoaded,
    [styles['loader__wrapper--empty']]: hasNotResults,
  });

  return (
    <div className={companyListContainerClassName}>
      {companyListTitle && <h3 className={styles['company-list__title']}>{companyListTitle}</h3>}
      <Scrollbar className={classNames(styles.scrollbar, scrollBarClassName)}>
        <div className={loaderWrapper}>
          {!isLoaded && <AppLoading key="loading" />}
          {hasNotResults ? (
            <div className={styles['company-list__empty']}>{t('companyList.hasNotResults')}</div>
          ) : (
            <div className={styles['company-list']}>{children}</div>
          )}
        </div>
      </Scrollbar>
      <AddNewBusiness
        newBusinessWrapperClassName={newBusinessWrapperClassName}
        value={value}
        onClickHandler={addNewBusinessOnClickHandler}
      />
    </div>
  );
};

export default CompanyList;
