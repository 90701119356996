// helpers
import { BeneficialOwner } from 'components/beneficial/useBeneficialOwners';

export const MAX_OWNERS_COUNT = 4;

type OwnerDetailsParams = {
  headerLabel: string;
  formData: {
    firstName: string;
    lastName: string;
  };
};

export const getOwnerDetails = (owner?: Nullable<BeneficialOwner>): OwnerDetailsParams => {
  const { firstName = '', lastName = '' } = owner || {};
  const headerLabel = owner ? `${firstName} ${lastName}` : '';

  const formData = {
    firstName: firstName || '',
    lastName: lastName || '',
  };

  return {
    formData,
    headerLabel,
  };
};
