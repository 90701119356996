// libraries
import React, { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
// components
import DropdownButton from 'components/Button/DropdownButton/DropdownButton';
import DropdownButtonItem from 'components/Button/DropdownButton/DropdownButtonItem';
// assets
import { ReactComponent as DotsIcon } from 'assets/icons/action-dots-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
import { ReactComponent as EditPenIcon } from 'assets/icons/edit-pen.svg';
// helpers
import { useListContext } from 'components/list/ListContext';
// mock
import { deleteContact, undeleteContact, getMockContacts, getMockTrashContacts } from 'components/contacts/mockData';
// types
import { Contact } from 'components/contacts/types';
// constants
import ContactPages from 'shared/constants/contactPages';
// styles
import 'components/contacts/ContactsTable/ContactActionButton/index.scss';

const popperConfig = {
  strategy: 'fixed',
};

const toggle = (
  <div className="contact-action__button">
    <DotsIcon />
  </div>
);

interface IContactActionButton {
  contact: Contact;
  scrollContainerRef: React.MutableRefObject<SimpleBar>;
  setContactsAmount: Dispatch<SetStateAction<number>>;
  page: string;
}

const ContactActionButton: React.FC<IContactActionButton> = ({
  contact,
  scrollContainerRef,
  setContactsAmount,
  page,
}: IContactActionButton) => {
  const { t } = useTranslation('contactPages');
  const history = useHistory();
  const { deleteFromList = () => {} } = useListContext();

  const toggleButtonRef = useRef<HTMLDivElement>(null);

  const isContactListPage = page === ContactPages.CONTACT_LIST;

  const scrollElement = scrollContainerRef?.current?.getScrollElement();

  // behavior to close dropdown when scrolling a table or page
  const handleScroll = useCallback(e => {
    if (
      document.activeElement &&
      document.activeElement instanceof HTMLElement &&
      document.activeElement.isEqualNode(toggleButtonRef.current)
    ) {
      document.activeElement.click();
      document.activeElement.blur();
    }
  }, []);

  scrollElement?.addEventListener('scroll', handleScroll);
  window.addEventListener('scroll', handleScroll);

  const handleEditClick = useCallback(() => {
    history.push(`/edit-contact/${contact.contactId}`, {
      contact,
    });
  }, [contact]);

  const handleDelete = useCallback(() => {
    deleteFromList(contact.contactId);
    // temporary function to simulate deleting contacts
    deleteContact(contact.contactId);
    // temporary functionality, the updated amount should change after the response from the server
    setContactsAmount(getMockContacts().length);
  }, [contact.contactId]);

  const handleUndelete = useCallback(() => {
    deleteFromList(contact.contactId);
    // temporary function to simulate undeleting contacts
    undeleteContact(contact.contactId);
    // temporary functionality, the updated amount should change after the response from the server
    setContactsAmount(getMockTrashContacts().length);
  }, [contact.contactId]);

  return (
    <DropdownButton
      toggleButtonRef={toggleButtonRef}
      className="contact-action__wrapper"
      // @ts-ignore
      popperConfig={popperConfig}
      toggle={toggle}
      alignRight
    >
      {isContactListPage && (
        <DropdownButtonItem onClick={handleEditClick}>
          <EditPenIcon width={13} height={13} />
          {t('dropdownActions.edit')}
        </DropdownButtonItem>
      )}
      <DropdownButtonItem onClick={isContactListPage ? handleDelete : handleUndelete}>
        <TrashIcon className="icon" width={13} height={13} />
        {isContactListPage ? t('dropdownActions.delete') : t('dropdownActions.undelete')}
      </DropdownButtonItem>
    </DropdownButton>
  );
};

export default ContactActionButton;
