// libraries
import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Form from 'components/Form';
import OwnerFields from './OwnerFields';
// helpers
import { VERIFICATION_FLOW } from 'components/verification/verificationHelpers';
import { MAX_OWNERS_COUNT } from 'components/beneficial/beneficialOwnersHelpers';
import useBeneficialOwners, { BeneficialOwnerFormData } from 'components/beneficial/useBeneficialOwners';
// constants
import BENEFICIARY_OWNER_SCHEMA from './validation-rules';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './OwnersForm.module.scss';

const initialValues = {
  firstName: '',
  lastName: '',
  address: '',
  birthday: '',
  ssn: '',
};

type ParamsProps = {
  id?: string;
};

type RouterMatchProps = {
  params: ParamsProps;
};

type OwnerFormProps = {
  isModalComponent?: boolean;
  fieldWrapperClassName?: string;
};

const OwnerForm: React.FC<OwnerFormProps> = ({ isModalComponent = false, fieldWrapperClassName = '' }) => {
  const { t } = useTranslation('beneficiaryOwners');
  const history = useHistory();
  const { params = {} }: RouterMatchProps = useRouteMatch();
  const { id } = params;
  const { submitForm } = useBeneficialOwners();

  const {
    beneficialOwnersStore: { beneficialOwnersCount, beneficialOwnersList, currentBeneficialOwner },
  } = useStore();

  useEffect(() => {
    if (beneficialOwnersCount >= MAX_OWNERS_COUNT && !isModalComponent) {
      return history.push(VERIFICATION_FLOW.beneficialOwners);
    }
    return () => {};
  }, [beneficialOwnersCount, history, isModalComponent]);

  const currentOwner = beneficialOwnersList?.find(owner => Number(owner?.id) === Number(id)) || currentBeneficialOwner;

  const modalFormClassName = classNames({
    [styles['modal-form']]: isModalComponent,
  });
  const modalFormContentClassName = classNames({
    [styles['modal-form__content']]: isModalComponent,
  });

  const modalFormTitle = useMemo(
    () => (currentOwner ? `${t('settingsEditOwnerTitle')}0${currentOwner?.id}` : t('settingsEditOwnerTitle')),
    [currentOwner, t],
  );

  return (
    <div className={modalFormClassName}>
      {isModalComponent && <div className={styles['modal-form__title']}>{modalFormTitle}</div>}
      <div className={modalFormContentClassName}>
        <Form
          submitForm={data => submitForm(data as BeneficialOwnerFormData)}
          validationSchema={BENEFICIARY_OWNER_SCHEMA}
          autoComplete="off"
          data={currentOwner || initialValues}
        >
          <OwnerFields fieldWrapperClassName={fieldWrapperClassName} isModalComponent={isModalComponent} />
        </Form>
      </div>
    </div>
  );
};

export default observer(OwnerForm);
