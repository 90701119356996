// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// components
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import { TextInput, Select } from 'components/Form/inputs/index';
// helpers
import { InviteReasons } from '../useInviteBusiness';
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './InviteBusinessForm.module.scss';

const InviteBusinessForm: React.FC = () => {
  const { t } = useTranslation('inviteBusiness');
  const {
    modalStore: { closeAllModals },
  } = useStore();

  const reasonSelectOption = useMemo(
    () =>
      Object.values(InviteReasons).map(value => ({
        value,
        label: t(`inviteBusinessForm.inviteReasons.${value}`),
      })),
    [t],
  );

  return (
    <>
      <TextInput label={t('inviteBusinessForm.email')} name="email" />
      <Select name="reason" label={t('inviteBusinessForm.reason')} options={reasonSelectOption} />
      <div>
        <div className={styles['buttons-wrapper']}>
          <Button className={styles.button} outline handleClick={closeAllModals}>
            {t('inviteBusinessForm.cancelButton')}
          </Button>
          <SubmitButton className={styles.button}>{t('inviteBusinessForm.submitButton')}</SubmitButton>
        </div>
      </div>
    </>
  );
};

export default observer(InviteBusinessForm);
