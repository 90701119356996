// libraries
import { useCallback, useEffect } from 'react';
// helpers
import { ModalIds } from 'shared/constants/modalIds';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

type VerificationNotificationHelper = {
  openVerificationPopup: (isLogin?: boolean) => void;
};

const useVerificationNotification = (): VerificationNotificationHelper => {
  const {
    verificationStore: { verificationPopup, verificationLink, setShouldShowLoginPopup, shouldShowLoginPopup },
    authStore: { isFirstLogin },
    modalStore: { openModal },
  } = useStore();

  const openVerificationPopup = useCallback(
    (isLogin = false) => {
      if (isLogin) {
        setShouldShowLoginPopup(true);
      }

      if (isFirstLogin) {
        setShouldShowLoginPopup(false);
        return;
      }

      if (!verificationPopup || !verificationLink) {
        return;
      }

      openModal(ModalIds.verifyAccount);
      setShouldShowLoginPopup(false);
    },
    [isFirstLogin, verificationPopup, verificationLink, openModal, setShouldShowLoginPopup],
  );

  useEffect(() => {
    if (verificationLink && shouldShowLoginPopup) {
      openVerificationPopup();
    }
  }, [verificationLink, shouldShowLoginPopup, openVerificationPopup]);

  return {
    openVerificationPopup,
  };
};

export default useVerificationNotification;
