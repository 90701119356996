// libraries
import React from 'react';
import classNames from 'classnames';
// components
import { TextInput } from 'components/Form/inputs';
import SubmitButton from 'components/Form/buttons/SubmitButton';
// helpers
import { InputFieldSize } from 'components/Form/inputs/TextInput/TextInput';
import areFieldsFullfilled from 'shared/helpers/inputs';
import { useFormContext } from 'components/Form/FormContext';
// styles
import styles from 'components/resetPassword/ResetPasswordFormType/ResetPasswordFormType.module.scss';

const inputNames = ['resetCode', 'verificationCode', 'email'];

interface IResetPasswordFieldsProps {
  labelName: string;
  inputName: string;
  inputPlaceholder: string;
  buttonTitle: string;
  buttonClassName?: string;
}

const ResetPasswordFields: React.FC<IResetPasswordFieldsProps> = ({
  labelName = '',
  inputName = '',
  inputPlaceholder = '',
  buttonTitle = '',
  buttonClassName = '',
}: IResetPasswordFieldsProps) => {
  const { data } = useFormContext();

  const currentPageField = inputNames.filter(input => input === inputName);
  const isButtonDisabled = !areFieldsFullfilled(data, currentPageField);

  return (
    <>
      <TextInput
        label={labelName}
        name={inputName}
        placeholder={inputPlaceholder}
        inputSize={InputFieldSize.L}
        fieldWrapperClassName={styles.field__wrapper}
        inputWrapperClassName={styles.input__wrapper}
      />
      <SubmitButton className={classNames(styles.button, buttonClassName)} disabled={isButtonDisabled}>
        {buttonTitle}
      </SubmitButton>
    </>
  );
};

export default ResetPasswordFields;
