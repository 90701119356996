export const BYTES_IN_MB = 1000000;

export enum FilePath {
  default = '',
  invoice = 'company/invoices',
  logo = 'company/logo',
}

export enum FileName {
  default = 'file',
  invoice = 'Invoice',
  companyAvatar = 'Company Avatar',
}

export enum FileTypes {
  jpg = 'image/jpeg',
  png = 'image/png',
  pdf = 'application/pdf',
}

export const fileRestrictions = {
  fileTypes: [FileTypes.jpg, FileTypes.png, FileTypes.pdf],
  maxSizeMb: 10,
  maxFiles: 10,
};
