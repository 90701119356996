// libraries
import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// helpers
import { FormData } from 'components/Form/FormContext';
import { addErrorToast, addSuccessToast } from 'components/Toast/toastHelper';
import useLoadingBar, { TLoadingBarParams } from 'components/AppLoading/LoadingBar/useLoadingBar';
import { getNewInvoice } from 'components/invoices/InvoiceHistory/mockData';
import useInvoiceItems from 'components/invoices/useInvoiceItems';
import { getMockData } from 'shared/helpers/simulateAsyncCall';
import { Invoice } from 'components/invoices/types';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';

export enum InvoiceStep {
  INVOICE_FORM = 0,
  INVOICE_FORM_REVIEW = 1,
}

export enum ExternalInvoiceStep {
  NEW_CUSTOMER = 0,
  INVOICE_FORM = 1,
  INVOICE_FORM_REVIEW = 2,
}

type TCreateInvoiceParams = Pick<TLoadingBarParams, 'loadingBarProgress'> & {
  handleSendInvoice: (steps: FormData[]) => void;
  isLoading: boolean;
};

const useCreateInvoice = (companyInfo: Nullable<Partial<IBusinessSnapshot>>): TCreateInvoiceParams => {
  const history = useHistory();
  const { t } = useTranslation('invoice');
  const [isLoading, setIsLoading] = useState(false);

  const { addInvoiceItem, updateInvoiceItem } = useInvoiceItems();
  const { completeProgress, loadingBarProgress = 0 } = useLoadingBar();
  const { state: locationInvoiceState } = useLocation() || {};

  const handleSendInvoice = useCallback(
    async (data: FormData[]) => {
      // TODO: update after integration with BE
      setIsLoading(true);

      try {
        let resultForm = {};

        data.forEach(step => {
          resultForm = { ...resultForm, ...step, invoiceId: locationInvoiceState?.invoice?.invoiceId };
        });

        // TODO: replace stall with api after integration with BE

        // ENDPOINT can be mapped by company info:
        // 1) Internal Invoice - has companyInfo with registered field
        // 2) External Invoice for existed company - has companyInfo without registered field
        // 3) External Invoice for new company - companyInfo === null

        // await request and loading animation
        const [newInvoice] = await Promise.all([
          getMockData(
            getNewInvoice({ ...(resultForm as any), ...(companyInfo?.dbaName && { customer: companyInfo?.dbaName }) }),
          ),
          completeProgress(),
        ]);

        if (locationInvoiceState) {
          updateInvoiceItem(newInvoice.invoiceId, newInvoice);
        } else {
          addInvoiceItem(newInvoice as Invoice);
        }

        history.push(`/accounting?type=receivable&invoiceId=${newInvoice.invoiceId}`);
        addSuccessToast(`${t('toast.successMessage')}`, `${t('toast.successTitle')}`);
      } catch (e) {
        const error = await e;
        addErrorToast(error);
      } finally {
        setIsLoading(false);
      }
    },
    [completeProgress, addInvoiceItem, history, t],
  );

  return {
    handleSendInvoice,
    isLoading,
    loadingBarProgress,
  };
};

export default useCreateInvoice;
