import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';
import { IEventPayableStore } from './types';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';

const initialValues = {
  // payableAmount: 0,
  // pastDuePayableAmount: 0,
  // nextWeekPayableAmount: 0,
  // businessEventId: '',

  // TODO: need remove after integration with BE
  payableAmount: 10853.85,
  pastDuePayableAmount: 7000,
  nextWeekPayableAmount: 999.99,
  businessEventId: '',
};

class EventPayableStore {
  payableData = initialValues;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setDataFromMessage = (data: IEventPayableStore): void => {
    this.payableData = {
      payableAmount: data.payableAmount,
      pastDuePayableAmount: data.pastDuePayableAmount,
      nextWeekPayableAmount: data.nextWeekPayableAmount,
      businessEventId: data.businessEventId,
    };
  };

  [CLEAR_STORE] = (): void => {
    this.payableData = initialValues;
  };
}

export default EventPayableStore;
