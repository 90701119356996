// libraries
import { makeAutoObservable } from 'mobx';
import isEqual from 'lodash/isEqual';
// helpers
import RootStore from 'shared/stores/RootStore/RootStore';

export enum NotificationTypes {
  officialCommunication = 'OfficialCommunication',
  reminder = 'Reminder',
  alert = 'Alert',
  invoiceReceived = 'InvoiceReceived',
  invoiceSent = 'InvoiceSent',
  paymentReceived = 'PaymentReceived',
  paymentMade = 'PaymentMade',
  advertisement = 'Advertisement',
}

export type Notification = {
  userId: string;
  notificationId: string;
  notificationTime: number;
  type: NotificationTypes;
  iconInBase64?: string;
  message: string;
  nvmap: Dictionary<string>;
  expiryTime?: number;
  markedRead: boolean;
  markReadAfterTime?: number;
  messageClassName?: string;
  helpTextClassName?: string;
  shortCardClassName?: string;
  logoClassName?: string;
};

class NotificationsStore {
  banners: Notification[] = [];

  popups: Notification[] = [];

  isBannersLoaded = false;

  isPopupsLoaded = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  updateBannersList = (bannersList: Notification[]): void => {
    if (isEqual(this.banners, bannersList)) {
      return;
    }

    this.banners = [...bannersList];
  };

  updatePopupList = (popups: Notification[]): void => {
    if (isEqual(this.popups, popups)) {
      return;
    }

    this.popups = [...popups];
  };

  setBannersLoaded = (value: boolean): void => {
    this.isBannersLoaded = value;
  };

  setPopupsLoaded = (value: boolean): void => {
    this.isPopupsLoaded = value;
  };

  resetNotificationData = (): void => {
    this.banners = [];
    this.popups = [];
    this.isBannersLoaded = false;
    this.isPopupsLoaded = false;
  };
}

export default NotificationsStore;
