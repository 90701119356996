import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Input from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextInput, { TextInputProps } from './TextInput/TextInput';
import { InputCommonProps } from './Input';

export type NumberInputProps = TextInputProps & {
  guide?: boolean;
  prefix?: string;
  allowDecimal?: boolean;
  includeThousandsSeparator?: boolean;
  integerLimit?: number;
};

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
  const {
    guide = false,
    value = '',
    prefix = '',
    allowDecimal = true,
    includeThousandsSeparator = true,
    integerLimit,
  } = props;
  const [inputValue, setInputValue] = useState(value);

  const numberMask = useMemo(
    () =>
      createNumberMask({
        prefix,
        allowDecimal,
        integerLimit,
        includeThousandsSeparator,
      }),
    [prefix, allowDecimal, integerLimit],
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const InputElement = ({ onChange, value: defaultValue = '', ...inputCommonProps }: InputCommonProps) => {
    const onChangeCallback = useCallback(
      event => {
        const newValue = event.target.value;

        setInputValue(newValue);

        if (onChange) {
          onChange({
            ...event,
            target: {
              ...event.target,
              value: +(newValue as string).replace(/[^\d.]/g, ''),
            },
          });
        }
      },
      [onChange],
    );

    return (
      <Input
        {...inputCommonProps}
        mask={numberMask}
        guide={guide}
        value={inputValue || defaultValue}
        onChange={onChangeCallback}
      />
    );
  };

  return <TextInput {...props} inputElement={InputElement} />;
};

export default NumberInput;
