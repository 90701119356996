// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import TransactionCardFooter from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooter';
import ReceivableInvoiceFooterActions from 'components/transactions/TransactionDetails/ReceivableInvoiceDetails/ReceivableInvoiceFooterActions/ReceivableInvoiceFooterActions';
import Button, { ButtonColor } from 'components/Button';
import MarkNotReceivedButton from './MarkNotReceivedButton';
// helpers
import { Invoice, InvoiceStates } from 'components/invoices/types';
import stall from 'shared/helpers/simulateAsyncCall';
import { useAppLoadingContext } from 'components/AppLoading/AppLoadingContext';
import { FilterTabs } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
import useInvoiceItems from 'components/invoices/useInvoiceItems';
// styles
import styles from './ReceivableInvoiceDetails.module.scss';

interface IReceivableInvoiceDetails {
  invoiceDetails: Invoice;
  openInvoiceDetails: (id: string, tab?: FilterTabs) => void;
}
const ReceivableInvoiceDetails: React.FC<IReceivableInvoiceDetails> = ({
  invoiceDetails,
  openInvoiceDetails,
}: IReceivableInvoiceDetails) => {
  const { t } = useTranslation('transactionHistory');
  const { invoiceId, isReceived } = invoiceDetails;
  const { setIsLoading } = useAppLoadingContext();
  const { updateInvoiceItem } = useInvoiceItems();

  const handleUpdateInvoice = async () => {
    setIsLoading(true);
    await stall();

    updateInvoiceItem(invoiceId, {
      isReceived: !isReceived,
      invoiceState: isReceived ? InvoiceStates.pending : InvoiceStates.markedPaid,
      payDate: isReceived ? null : Date.now(),
    });
    openInvoiceDetails(invoiceId, isReceived ? FilterTabs.RECEIVABLE : FilterTabs.COMPLETE);
    setIsLoading(false);
  };

  return (
    <TransactionCardFooter>
      {isReceived ? (
        <MarkNotReceivedButton handleClick={handleUpdateInvoice} />
      ) : (
        <Button handleClick={handleUpdateInvoice} color={ButtonColor.secondary} className={styles['mark-received-btn']}>
          {t('markReceived')}
        </Button>
      )}

      <ReceivableInvoiceFooterActions invoiceDetails={invoiceDetails} />
    </TransactionCardFooter>
  );
};

export default ReceivableInvoiceDetails;
