// libraries
import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
// constants
import { DISABLED_ROUTES } from 'shared/constants/baseRoutes';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// helpers
import useVerificationNotification from 'components/notifications/VerificationNotification/useVerificationNotification';

type NavParams = {
  handleClick: (e: MouseEvent, path: string) => void;
  isVerifiedCompany: Nullable<boolean>;
};

const useNav = (): NavParams => {
  const history = useHistory();
  const {
    userStore: { isVerifiedCompany },
  } = useStore();
  const { openVerificationPopup } = useVerificationNotification();

  const handleClick = (e: MouseEvent, path: string) => {
    if (isVerifiedCompany) {
      history.push(path);

      return;
    }

    const isDisabledPath = DISABLED_ROUTES.some(val => path.includes(val));

    if (isDisabledPath) {
      e.preventDefault();
      openVerificationPopup();
    }
  };

  return { handleClick, isVerifiedCompany };
};

export default useNav;
