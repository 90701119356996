// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import CreateNewPasswordForm from 'components/resetPassword/CreateNewPasswordForm/CreateNewPasswordForm';
import Modal from 'components/Modal/Modal';
import PasswordSuccess from 'components/resetPassword/PasswordSuccess/PasswordSuccess';
import EntryTitle from 'components/EntryTitle/EntryTitle';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// types
import { CreateNewPasswordFormData } from 'components/resetPassword/usePasswordResetForm';
// styles
import styles from 'router/views/ResetPassword/index.module.scss';
import createNewPasswordStyles from 'router/views/ResetPassword/ResetPasswordFlow/CreateNewPassword/CreateNewPassword.module.scss';
// assets
import { ReactComponent as Lock } from 'assets/icons/lock.svg';

interface ICreateNewPasswordProps {
  submitCreateNewPasswordForm: (data: CreateNewPasswordFormData) => void;
}

const CreateNewPassword: React.FC<ICreateNewPasswordProps> = ({
  submitCreateNewPasswordForm,
}: ICreateNewPasswordProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <>
      <Lock className={createNewPasswordStyles.lock} />
      <EntryTitle
        title={t('createNewPassword.title')}
        subtitle={t('createNewPassword.subtitle')}
        customClass={styles['entry-title__wrapper']}
      />
      <CreateNewPasswordForm submitCreateNewPasswordForm={submitCreateNewPasswordForm} />
      <Modal hasCloseButton={false} id={ModalIds.successPasswordChange}>
        <PasswordSuccess />
      </Modal>
    </>
  );
};

export default CreateNewPassword;
