import * as Yup from 'yup';
import { formatISO } from 'date-fns';
import { getLatinNameSchema } from 'shared/helpers/validationSchemes';
import fieldSizes from 'shared/constants/fieldSizes';

const MAX_LENGTH = 240;

const billFormSchema = {
  vendor: Yup.string().required('forms.vendor.required'),
  email: Yup.string().email('forms.email.invalid').required('forms.email.required'),
  amount: Yup.number()
    .required('forms.amount.required')
    .moreThan(0, 'forms.amount.enterValidAmount')
    .lessThan(fieldSizes.MONEY_LIMIT, 'forms.amount.enterValidAmount'),
  invoiceNumber: Yup.string().required('forms.invoiceNumber.required'),
  dueDate: Yup.date()
    .typeError('forms.dueDate.required')
    .min(formatISO(new Date(), { representation: 'date' }), 'forms.dueDate.minDate')
    .required('forms.dueDate.required'),
  message: Yup.string().max(MAX_LENGTH, 'forms.maxLength'),
};

export const newVendorAddBillFormSchema = Yup.object().shape({
  ...billFormSchema,
  contactName: getLatinNameSchema().required('forms.contactName.required'),
});

export const addBillFormSchema = Yup.object().shape(billFormSchema);
