import React, { ChangeEvent, Dispatch, FocusEvent, FormEvent, ReactElement, SetStateAction, useEffect } from 'react';
import classNames from 'classnames';
import useInputHelper from './inputHelper';
import styles from './TextInput/TextInput.module.scss';

export enum InputTypes {
  text = 'text',
  password = 'password',
  checkbox = 'checkbox',
  file = 'file',
  radio = 'radio',
}

export type InputElements = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

export interface InputCommonProps {
  id?: string;
  name: string;
  onFocus?: (event: FocusEvent<InputElements>) => void;
  onBlur?: (event?: FormEvent<InputElements>) => void;
  onChange?: (event: ChangeEvent<InputElements>) => void | null;
  value?: string | string[];
  checked?: boolean;
  className?: string;
  type?: string;
  disabled?: boolean;
  autoComplete?: string;
  placeholder?: string;
  style?: Dictionary<string>;
}

export type ErrorMessages = Dictionary<Nullable<string[] | void>>;

export interface InputProps extends InputCommonProps {
  errorMessages?: ErrorMessages;
  showError?: boolean;
  errorInputClassName?: string;
  shouldShowError?: boolean;
  isRequired?: boolean | (() => boolean);
  errorMessageMapper?: () => string;
  onChangeCallback?: (event: ChangeEvent<InputElements>) => void;
  onFocusCallback?: (event: FocusEvent<InputElements>) => void;
  onBlurCallback?: () => void;
  additionalOnChangeCallBack?: Dispatch<SetStateAction<string>>;
  children: (data: {
    errorMessages?: string[] | null;
    inputCommonProps: InputCommonProps;
    isRequired?: boolean;
  }) => ReactElement;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    autoComplete = 'on',
    type,
    children,
    className = '',
    name,
    placeholder = '',
    showError,
    shouldShowError,
    style = {},
    errorInputClassName,
    checked,
  } = props;

  const {
    getOnChangeCallback,
    getValidationCallback,
    getValue,
    getErrors,
    isDisabled: disabled,
    removeErrors,
    isRequired,
    setTouched,
    touched,
  } = useInputHelper(props);

  const inputValue = String(getValue() || '');
  const errorMessages = touched || showError || inputValue ? getErrors() : null;
  const hasError = errorMessages && errorMessages.length > 0 && (touched || showError || inputValue);
  const invalidClass =
    hasError || shouldShowError ? classNames(styles['field-input--invalid'], errorInputClassName) : '';
  const checkedValue = checked || !!inputValue;
  const validateForm = getValidationCallback();

  useEffect(() => {
    if ((type === InputTypes.radio || type === InputTypes.password) && validateForm) {
      validateForm();
    }
  }, [inputValue, validateForm, type]);

  const inputCommonProps: InputCommonProps = {
    id: name,
    name,
    style,
    value: inputValue,
    checked: checkedValue,
    onChange: getOnChangeCallback(),
    onBlur: validateForm,
    onFocus: () => {
      setTouched();
      removeErrors();
    },
    className: classNames(invalidClass, className),
    type: type || InputTypes.text,
    disabled,
    autoComplete,
    placeholder: placeholder || '',
  };

  return children({
    errorMessages,
    inputCommonProps,
    isRequired,
  });
};

export default Input;
