// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
// components
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
// helpers
import { formatCurrency } from 'shared/helpers/format';
import { useListContext } from 'components/list/ListContext';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
// styles
import styles from './PayableReceivableContent.module.scss';

type PayableReceivableContentProps = {
  title: string;
  pastDueAmount: number;
  nextWeekAmount: number;
  companies?: Partial<IBusinessSnapshot>[];
  companiesTitle?: string;
  getCompanyLink?: (company: Partial<IBusinessSnapshot>) => React.ReactNode;
};

const MAX_COMPANIES_COUNT = 6;

const PayableReceivableContent: React.FC<PayableReceivableContentProps> = ({
  title,
  pastDueAmount,
  nextWeekAmount,
  companies = [],
  companiesTitle = '',
  getCompanyLink = () => null,
}: PayableReceivableContentProps) => {
  const { t } = useTranslation('transactionHistory');
  const { dataWithoutSearch = [] } = useListContext();

  const countOfItems = useMemo(() => dataWithoutSearch.length, [dataWithoutSearch.length]);
  const amount = useMemo(() => dataWithoutSearch.reduce((result, item) => result + +get(item, 'totalAmount', 0), 0), [
    dataWithoutSearch,
  ]);

  return (
    <div className={styles['payable-receivable-content']}>
      <div className={styles['payable-receivable__title']}>{title}</div>
      <div className={styles['payable-receivable__amount-wrapper']}>
        <div className={styles['payable-receivable__amount']}>{formatCurrency(amount, false)}</div>
        <div className={styles['payable-receivable__count-of-items']}>{`${countOfItems} ${
          countOfItems === 1 ? t('dashboard.item') : t('dashboard.items')
        }`}</div>
      </div>
      <div className={styles['payable-receivable__aging-wrapper']}>
        <span className={styles['payable-receivable__aging']}>
          {t('dashboard.pastDueAmount')} {formatCurrency(pastDueAmount, false)}
        </span>
        <span className={styles['payable-receivable__aging']}>
          {t('dashboard.nextWeekAmount')} {formatCurrency(nextWeekAmount, false)}
        </span>
      </div>
      {!!companies.length && (
        <div className={styles['payable-receivable__companies-list']}>
          <div className={styles['payable-receivable__companies-title']}>{companiesTitle}</div>
          {companies.slice(0, MAX_COMPANIES_COUNT).map(company => (
            <ShortEntityCard
              className="invoice_vendor"
              key={company.dbaName}
              message={company.dbaName || ''}
              helpText={company.industry || ''}
              logo={<EntityLogo logoSrc={company.logoImage} />}
              additionalInfo={getCompanyLink(company)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PayableReceivableContent;
