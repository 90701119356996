import { HttpMethod } from 'shared/api/AbstractRequest';
import { ResponsePromise } from 'shared/api/AbstractRequestManager';
import ProtectedRequest from 'shared/api/protected/ProtectedRequest';

class RestrictedFilesApi {
  getFile = (filePath: string): ResponsePromise => {
    return new ProtectedRequest(filePath, { method: HttpMethod.GET }).send();
  };
}

export default new RestrictedFilesApi();
