// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EntryTitle from 'components/EntryTitle/EntryTitle';
import EmailChangeForm from 'components/signUpForms/EmailChangeForm/EmailChangeForm';
// constants
import { EmailChangeFormData } from 'components/signUpForms/useSignUpForm';

interface IEmailChangeProps {
  submitEmailChangeForm: (data: EmailChangeFormData) => void;
}

const EmailChange: React.FC<IEmailChangeProps> = ({ submitEmailChangeForm }: IEmailChangeProps) => {
  const { t } = useTranslation('signUpPage');

  return (
    <>
      <EntryTitle subtitle={t('emailChangeForm.subtitle')} title={t('emailChangeForm.title')} />
      <EmailChangeForm submitEmailChangeForm={submitEmailChangeForm} />
    </>
  );
};

export default EmailChange;
