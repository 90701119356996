import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
// hooks
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// store
import { useStore } from 'shared/stores/RootStore/useStore';
// components
import CompletedModal from 'components/CompletedModal/CompletedModal';
import AboutCompanyForm from 'components/RegistrationCompanyForms/AboutCompanyForm/AboutCompanyForm';
import AdditionalInformationForm from 'components/RegistrationCompanyForms/AdditionalInformationForm/AdditionalInformationForm';
import ModalTitleWithStep from 'components/Modal/ModalTitleWithStep/ModalTitleWithStep';
import LinkButton from 'components/Button/LinkButton';
import { ButtonColor } from 'components/Button';
// assets
import pencilImage from 'assets/icons/full-pencil.svg';
import { ReactComponent as DoneImage } from 'assets/icons/done-blue.svg';
// styles
import styles from './RegistrationCompany.module.scss';

export enum RegistrationCompanySteps {
  ABOUT = 1,
  ADDITION = 2,
  DONE = 3,
}

const RegistrationCompany: React.FC = () => {
  const { t } = useTranslation('registrationCompanyModals');
  const { currentStep } = useMultiStepContext();
  const {
    modalStore: { closeAllModals },
  } = useStore();

  const modalTitle = useMemo(
    () => (currentStep === RegistrationCompanySteps.ABOUT ? t('modalTitleAbout') : t('modalTitleAdditional')),
    [currentStep, t],
  );

  const handleClickLinkButton = useCallback(() => {
    closeAllModals();
  }, [closeAllModals]);

  return currentStep === RegistrationCompanySteps.DONE ? (
    <CompletedModal
      wrapperClassName={styles['registration-company-success__wrapper']}
      icon={DoneImage}
      iconClassName={styles['success-img']}
    >
      <span className={styles['success-title']}>{t('successModal.titleText')}</span>
      <span className={styles['success-additional']}>{t('successModal.additionalText')}</span>
      <LinkButton
        color={ButtonColor.primary}
        url="/"
        className={styles['success-link']}
        handleClick={handleClickLinkButton}
      >
        {t('successModal.linkText')}
      </LinkButton>
    </CompletedModal>
  ) : (
    <div
      className={classNames(styles['registration-company-modal__wrapper'], {
        [styles['additional-modal__wrapper']]: currentStep === RegistrationCompanySteps.ADDITION,
      })}
    >
      <>
        <ModalTitleWithStep titlePopupText={modalTitle} image={pencilImage} width={11} height={11} />

        {currentStep === RegistrationCompanySteps.ABOUT && <AboutCompanyForm />}

        {currentStep === RegistrationCompanySteps.ADDITION && <AdditionalInformationForm />}
      </>
    </div>
  );
};

export default observer(RegistrationCompany);
