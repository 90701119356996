/* eslint-disable */
// libraries
import { useCallback } from 'react';
// types
import RootStore from 'shared/stores/RootStore/RootStore';
// constants
import EndpointsToStores from 'shared/constants/EndpointsToStores';

export const useConnection = (store: RootStore) =>
  useCallback(() => {
    console.log('Connection established');
    store.connectionStore.setIsConnectionOpen(true);
  }, [store]);

export const useMessage = (store: RootStore) =>
  useCallback(
    (event: MessageEvent<any>) => {
      const { payload } = JSON.parse(event.data);
      const parsedPayload = JSON.parse(payload);

      let storeName;
      let payloadResponse;

      if (parsedPayload.endpoint) {
        storeName = EndpointsToStores[parsedPayload.endpoint as keyof typeof EndpointsToStores];
        payloadResponse = parsedPayload?.endpointResponse && JSON.parse(parsedPayload.endpointResponse);
      }

      if (parsedPayload.eventType) {
        storeName = EndpointsToStores[parsedPayload.eventType as keyof typeof EndpointsToStores];
        payloadResponse = JSON.parse(parsedPayload.payload);
      }

      store[
        storeName as keyof RootStore
        // @ts-ignore
      ]?.setDataFromMessage({
        ...payloadResponse,
        isFetching: false,
      });
    },
    [store],
  );

export const useError = () =>
  useCallback(() => {
    console.log('Error');
  }, []);

export const useDisconnection = (store: RootStore) =>
  useCallback(() => {
    console.log('Connection interrupted');
    store.connectionStore.setIsConnectionOpen(false);
  }, [store]);
