// libraries
import { useCallback, useEffect, useState } from 'react';
// constants
import config from 'config';

const DEFAULT_PROGRESS = 0;
const MAX_PROGRESS = 100;

const DEFAULT_LOADING_BAR_TIME = 1500;

const LOADING_TIME = Number(config?.loadingBarTime || DEFAULT_LOADING_BAR_TIME);
const LOADING_BAR_UPDATE_TIME = 200;
const LOADING_BAR_STEP = MAX_PROGRESS / (LOADING_TIME / LOADING_BAR_UPDATE_TIME);

export type TLoadingBarParams = {
  completeProgress: () => void;
  loadingBarProgress: number;
};

const useLoadingBar = (): TLoadingBarParams => {
  const [isLaunched, setIsLaunched] = useState(false);
  const [progress, setProgress] = useState(DEFAULT_PROGRESS);

  const completeProgress = useCallback(async () => {
    setProgress(DEFAULT_PROGRESS);
    setIsLaunched(true);

    await new Promise(resolve => {
      setTimeout(resolve, LOADING_TIME);
    });
  }, []);

  useEffect(() => {
    if (!isLaunched) {
      return () => {};
    }

    const interval = setInterval(() => {
      setProgress(prevState => {
        const nextProgressValue = prevState + LOADING_BAR_STEP;

        if (nextProgressValue >= MAX_PROGRESS) {
          setIsLaunched(false);
          clearInterval(interval);

          return MAX_PROGRESS;
        }

        return nextProgressValue;
      });
    }, LOADING_BAR_UPDATE_TIME);

    return () => {
      clearInterval(interval);
    };
  }, [isLaunched]);

  return {
    loadingBarProgress: progress,
    completeProgress,
  };
};

export default useLoadingBar;
