// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/Button';
import FormFooter from 'components/MultiStep/Form/FormFooter/FormFooter';
import InvoiceDetails from 'components/externalPayments/AccountReceivable/InvoiceDetails/InvoiceDetails';
import ExternalFormAction from 'components/externalPayments/ExternalFormAction/ExternalFormAction';
import ChooseDate from 'components/externalPayments/AccountPayable/Payment/PaymentForm/ChooseDate/ChooseDate';
// helpers
import { Invoice } from 'components/invoices/types';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
// assets
import { ReactComponent as PaymentMethodIcon } from 'assets/icons/paymentMethod.svg';
// styles
import paymentFormStyles from '../Payment.module.scss';

interface IPaymentForm {
  invoiceDetails: Nullable<Invoice>;
  isPlaidAvailable: boolean;
  openPlaidModal: () => void;
  setPaymentDate: (date: Date) => void;
  paymentDate: Nullable<Date>;
  paymentAccount: Nullable<Account>;
  submitCallback: () => void;
  submitDisabled?: boolean;
}

const PaymentForm: React.FC<IPaymentForm> = ({
  invoiceDetails,
  isPlaidAvailable,
  openPlaidModal,
  setPaymentDate,
  paymentDate,
  paymentAccount,
  submitCallback,
  submitDisabled,
}: IPaymentForm) => {
  const { t } = useTranslation('externalPaymentPage');

  return (
    <>
      <InvoiceDetails
        dueDate={invoiceDetails?.dueDate}
        amount={invoiceDetails?.totalAmount}
        description={invoiceDetails?.message}
        fieldWrapperClassName={paymentFormStyles.field_wrapper}
        className={paymentFormStyles['invoice-details__wrapper']}
      />
      <ExternalFormAction
        isActive={!!paymentAccount?.lastNumbers}
        handleClick={openPlaidModal}
        disabled={!isPlaidAvailable}
        activeValue={`xxxx ${paymentAccount?.lastNumbers}`}
        inactiveValue={t('noBankAccount')}
        title={t('paymentMethod')}
        actionTitle={t('addMethod')}
        activeActionTitle={t('methodAdded')}
        actionIcon={PaymentMethodIcon}
        className={paymentFormStyles['payment-action']}
      />
      <ChooseDate
        handleChange={setPaymentDate}
        selectedDate={paymentDate}
        dueDate={invoiceDetails?.dueDate ? new Date(invoiceDetails?.dueDate) : new Date()}
      />
      <FormFooter>
        <Button
          className={paymentFormStyles['payment-form-submit-button']}
          disabled={submitDisabled}
          handleClick={submitCallback}
        >
          {t('form.button')}
        </Button>
      </FormFooter>
    </>
  );
};

export default PaymentForm;
