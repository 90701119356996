// libraries
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
// components
import AddAmount from './AddAmount/AddAmount';
import Modal from 'components/Modal/Modal';
import PaymentMethod from 'components/paymentMethods/PaymentMethod/PaymentMethod';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
// styles
import styles from './AddFundsPopup.module.scss';

const AddFundsPopup: React.FC = () => {
  const {
    modalStore: { closeModal, openModal },
    paymentMethods: { accountToFunds, removeAccountToFunds },
  } = useStore();
  const [cardData, setCardData] = useState<Partial<Account>>({});

  const handlePaymentMethodsModalClose = useCallback(() => {
    removeAccountToFunds();
    closeModal(ModalIds.paymentMethodFromNotification);
  }, [closeModal, removeAccountToFunds]);

  const handleAddFundsModalClose = useCallback(() => {
    removeAccountToFunds();
    closeModal(ModalIds.addAmountPopup);
  }, [closeModal, removeAccountToFunds]);

  useEffect(() => {
    if (!accountToFunds) {
      return;
    }

    setCardData(accountToFunds);
    handlePaymentMethodsModalClose();
    openModal(ModalIds.addAmountPopup);
  }, [accountToFunds, handlePaymentMethodsModalClose, openModal]);

  return (
    <>
      <Modal id={ModalIds.addAmountPopup} handleCloseModal={handleAddFundsModalClose}>
        <div className={styles.modal__wrapper}>
          <AddAmount paymentMethodId={cardData.id} onSubmit={handleAddFundsModalClose} />
        </div>
      </Modal>

      <Modal id={ModalIds.paymentMethodFromNotification} handleCloseModal={handlePaymentMethodsModalClose}>
        <div className={styles.modal__wrapper}>
          <PaymentMethod />
        </div>
      </Modal>
    </>
  );
};

export default observer(AddFundsPopup);
