// libraries
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isToday } from 'date-fns';
// components
import TransactionCardFooter from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooter';
import TransactionCardField from 'components/transactions/TransactionCard/TransactionCardField';
import PayableInvoiceFooterActions from 'components/transactions/TransactionDetails/PayableInvoiceDetails/PayableInvoiceFooterActions/PayableInvoiceFooterActions';
// helpers
import { Invoice, InvoiceStates } from 'components/invoices/types';
import { DateFormats } from 'shared/helpers/format';
import { useListContext } from 'components/list/ListContext';
import stall from 'shared/helpers/simulateAsyncCall';
import { useAppLoadingContext } from 'components/AppLoading/AppLoadingContext';
import useInvoiceItems from 'components/invoices/useInvoiceItems';
// assets
import { ReactComponent as ClockIcon } from 'assets/icons/clock-icon.svg';
import { ReactComponent as EditPenIcon } from 'assets/icons/edit-pen.svg';
// styles
import styles from './PayableInvoiceStaticInfo.module.scss';

interface IPayableInvoiceStaticInfo {
  invoiceDetails: Invoice;
}

const PayableInvoiceStaticInfo: React.FC<IPayableInvoiceStaticInfo> = ({
  invoiceDetails,
}: IPayableInvoiceStaticInfo) => {
  const { t } = useTranslation('transactionHistory');
  const { payDate } = invoiceDetails;
  const { updateInList = () => {} } = useListContext();
  const { updateInvoiceItem } = useInvoiceItems();
  const { setIsLoading } = useAppLoadingContext();

  const unscheduleInvoice = useCallback(async () => {
    setIsLoading(true);

    // TODO: add request to BE
    await stall();

    updateInList(invoiceDetails.invoiceId, { ...invoiceDetails, invoiceState: InvoiceStates.pending, payDate: null });
    updateInvoiceItem(invoiceDetails.invoiceId, { invoiceState: InvoiceStates.pending, payDate: null });
    setIsLoading(false);
  }, [invoiceDetails, updateInList, setIsLoading, updateInvoiceItem]);

  const formattedPayDate = useMemo(() => {
    if (!payDate) {
      return '';
    }

    if (isToday(payDate)) {
      return t('payableInvoice.immediately');
    }

    return format(payDate, DateFormats.full);
  }, [payDate, t]);

  return (
    <>
      {payDate && (
        <TransactionCardField
          label={t('invoiceActions.scheduledDate')}
          value={
            <div className={styles['schedule-field__wrapper']}>
              <span>
                {t('payableInvoice.paymentScheduled')}
                <b>{formattedPayDate}</b>
              </span>
              <button className={styles['edit-button']} onClick={unscheduleInvoice}>
                <EditPenIcon width={10} height={10} />
              </button>
            </div>
          }
        />
      )}
      <TransactionCardFooter className={styles['scheduled-invoice-footer__wrapper']}>
        <div className={styles['schedule-message__wrapper']}>
          <ClockIcon width={14} height={14} className={styles['clock-icon']} />
          <span>
            {t('payableInvoice.invoiceScheduled')}
            <b>{formattedPayDate}</b>
          </span>
        </div>

        <PayableInvoiceFooterActions invoiceDetails={invoiceDetails} />
      </TransactionCardFooter>
    </>
  );
};

export default PayableInvoiceStaticInfo;
