// libraries
import React, { useMemo } from 'react';
// components
import ContactsTable from 'components/contacts/ContactsTable';
// mock
import { getMockTrashContacts } from 'components/contacts/mockData';
// constants
import ContactPages from 'shared/constants/contactPages';

const TrashContacts = () => {
  const trashContacts = useMemo(() => getMockTrashContacts(), []);

  return <ContactsTable page={ContactPages.TRASH_CONTACTS} contacts={trashContacts} />;
};

export default TrashContacts;
