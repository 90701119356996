import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import TextInput, { TextInputProps } from '../TextInput/TextInput';
import Button from '../../../Button';
import styles from './PasswordInput.module.scss';
import StyledLink from '../../../Link/Link';

interface PasswordInputProps extends TextInputProps {
  buttonClassName?: string;
  passwordRecoveryLink?: string;
  additionalOnChangeCallBack?: Dispatch<SetStateAction<string>>;
}

const PasswordInput: React.FC<PasswordInputProps> = (props: PasswordInputProps) => {
  const { t } = useTranslation('forms');
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const { inputWrapperClassName = '', buttonClassName = '', passwordRecoveryLink = '', ...rest } = props;

  return (
    <TextInput
      {...rest}
      inputWrapperClassName={classNames(styles['password-input-wrapper'], inputWrapperClassName)}
      type={isPasswordShown ? 'text' : 'password'}
    >
      <StyledLink linkClassName={styles['password-reset-link']} to="/password-reset">
        {passwordRecoveryLink}
      </StyledLink>
      <Button
        className={classNames(styles['password-switch'], buttonClassName)}
        btnLink
        handleClick={() => {
          setIsPasswordShown(!isPasswordShown);
        }}
      >
        {isPasswordShown ? t('passwordHideButton') : t('passwordShowButton')}
      </Button>
    </TextInput>
  );
};

export default PasswordInput;
