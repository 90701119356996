// libraries
import React from 'react';
// components
import SettingFormWrapper from 'components/settings/SettingFormWrapper/SettingFormWrapper';
import AccountIntegrationsForm from 'components/settings/AccountIntegrationsForm/AccountIntegrationsForm';

const SettingsAccountIntegrations: React.FC = () => {
  return (
    <SettingFormWrapper>
      <AccountIntegrationsForm />
    </SettingFormWrapper>
  );
};

export default SettingsAccountIntegrations;
