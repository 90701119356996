// libraries
import React, { memo } from 'react';
// components
import LogoBig, { LogoColor } from 'components/LogoBig/LogoBig';
// styles
import styles from './Header.module.scss';

type HeaderProps = {
  handleLogoClick?: () => void;
  isWhiteLogo?: boolean;
};

const HeaderWithoutControls: React.FC<HeaderProps> = ({ handleLogoClick = () => {} }) => (
  <header className={styles.header}>
    <div className="layout__container mx-auto">
      <div className="layout__container-wrapper">
        <LogoBig color={LogoColor.DARK} handleLogoClick={handleLogoClick} />
      </div>
    </div>
  </header>
);

export default memo(HeaderWithoutControls);
