import { Account, Fee } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
import { AccountType } from 'components/paymentMethods/api/PaymentMethodApi';

export type BankAccountsOptionType = {
  value: Nullable<number>;
  label: string;
  lastNumbers?: string;
  type?: string;
  fee?: Fee;
};

export const getOptions = (accounts: Account[], hideCreditCards?: boolean): BankAccountsOptionType[] => {
  let connectedAccounts = accounts;

  if (hideCreditCards) {
    connectedAccounts = connectedAccounts.filter(({ type }) => type !== AccountType.CREDIT_CARD);
  }

  return connectedAccounts.map(({ brand, lastNumbers, id, type }) => {
    return {
      value: id,
      label: brand,
      lastNumbers,
      type,
    };
  });
};

export default {};
