import { isEmpty, pickBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
// hooks
import { useMultiStepContext } from '../MultiStep/MultiStepContext';
// helpers
import { FormData } from 'components/Form/FormContext';
import stall from 'shared/helpers/simulateAsyncCall';

type TRegistrationCompanyFormData = {
  submitForm: (data: FormData) => void;
  isButtonsDisabled: boolean;
  isFormSubmitted: boolean;
};

const useRegistrationCompanyForm = (): TRegistrationCompanyFormData => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { currentStep, goToNextStep } = useMultiStepContext();

  const isButtonsDisabled = useMemo(() => isLoading, [isLoading]);

  const submitForm = useCallback(
    async (data: FormData) => {
      const requestData = pickBy(data);

      if (goToNextStep && currentStep && isEmpty(requestData)) {
        goToNextStep(currentStep, {});
        return;
      }

      if (isButtonsDisabled) {
        return;
      }

      // TODO Must be changed after integration
      setIsFormSubmitted(false);
      setIsLoading(true);

      await stall(requestData, 1000);

      setIsFormSubmitted(true);
      setIsLoading(false);

      if (goToNextStep && currentStep) {
        goToNextStep(currentStep, {});
      }
    },
    [goToNextStep, currentStep, isButtonsDisabled],
  );

  return {
    isButtonsDisabled,
    isFormSubmitted,
    submitForm,
  };
};

export default useRegistrationCompanyForm;
