// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import Link from 'components/Link/Link';
// assets
import { ReactComponent as AddIcon } from 'assets/icons/add-icon.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/export-icon.svg';
// styles
import styles from './AccountingTools.module.scss';

const AccountingTools: React.FC = () => {
  const { t } = useTranslation('accountingPage');

  return (
    <div className={styles['accounting-tools__wrapper']}>
      <h3 className={styles['accounting-tools__title']}>Tools</h3>
      <Link
        linkWrapperClassName={styles['accounting-tool__wrapper']}
        linkClassName={styles['accounting-tool__link']}
        to="/accounting"
      >
        <ExportIcon
          className={classnames(styles['accounting-tool__icon'], styles['accounting-tool__icon--export'])}
          width={13}
          height={14}
        />
        {t('tools.export')}
      </Link>
      <Link
        linkWrapperClassName={styles['accounting-tool__wrapper']}
        linkClassName={styles['accounting-tool__link']}
        to="/billing"
      >
        <AddIcon className={styles['accounting-tool__icon']} width={17} height={17} />
        {t('tools.addBill')}
      </Link>
    </div>
  );
};

export default AccountingTools;
