import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import { ShortListType } from 'shared/stores/ShortListsStore/ShortListsStore';
import { ModalIds } from 'shared/constants/modalIds';
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './CompanyQuickActionItem.module.scss';

type CompanyQuickActionItemProps = {
  icon: React.ReactElement;
  title: string;
  clientsNumber: number;
  modalType: ShortListType;
};

const CompanyQuickActionItem: React.FC<CompanyQuickActionItemProps> = ({
  icon,
  title,
  clientsNumber = 0,
  modalType,
}: CompanyQuickActionItemProps) => {
  const {
    modalStore: { openModal },
    shortListsStore: { setShortList },
  } = useStore();
  const { t } = useTranslation('companySearch');

  const handleClick = useCallback(() => {
    setShortList(modalType);
    openModal(ModalIds.shortList);
  }, [setShortList, modalType, openModal]);

  return (
    <button className={styles['action-item']} onClick={handleClick}>
      <div className={styles['action-item__icon_wrapper']}>{icon}</div>
      <h5 className={styles['action-item__title']}>{title}</h5>
      <span className={styles['action-item__clients']}>
        {`${clientsNumber} ${t('quickActionsItems.numberOfClients')}`}
      </span>
    </button>
  );
};

export default CompanyQuickActionItem;
