import { store } from 'shared/stores/RootStore/RootStore';
import AbstractRequest, { RequestInitialParams } from '../AbstractRequest';
import ProtectedRequestManager from './ProtectedRequestManager';

class ProtectedRequest extends AbstractRequest {
  constructor(url: string, params?: RequestInitialParams) {
    const {
      authStore: { sessionId },
    } = store;

    const defaultHeaders = {
      Authorization: `Bearer ${sessionId}`,
      'zuro-context': 'platform',
    };
    const apiPrefix = '/api/v1';

    super(ProtectedRequestManager, `${apiPrefix}${url}`, {
      ...params,
      headers: {
        ...(params?.headers || {}),
        ...defaultHeaders,
      },
    });
  }
}

export default ProtectedRequest;
