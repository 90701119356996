// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Form from 'components/Form';
import { PasswordInput } from 'components/Form/inputs';
import SubmitButton from 'components/Form/buttons/SubmitButton';
// helpers
import PASSWORD_RESET_FORM_SCHEMA from './validation-rules';
import { InputFieldSize } from 'components/Form/inputs/TextInput/TextInput';
// types
import { CreateNewPasswordFormData } from 'components/resetPassword/usePasswordResetForm';
// styles
import styles from 'components/resetPassword/CreateNewPasswordForm/CreateNewPasswordForm.module.scss';

interface ICreateNewPasswordFormProps {
  submitCreateNewPasswordForm: (data: CreateNewPasswordFormData) => void;
}

const CreateNewPasswordForm: React.FC<ICreateNewPasswordFormProps> = ({
  submitCreateNewPasswordForm,
}: ICreateNewPasswordFormProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <div className={styles.form__wrapper}>
      <Form
        className={styles.form__container}
        submitForm={data => submitCreateNewPasswordForm(data as CreateNewPasswordFormData)}
        validationSchema={PASSWORD_RESET_FORM_SCHEMA}
      >
        <PasswordInput
          label={t('createNewPassword.fields.password.label')}
          placeholder={t('createNewPassword.fields.password.placeholder')}
          name="password"
          inputSize={InputFieldSize.L}
          fieldWrapperClassName={styles.field__wrapper}
          inputWrapperClassName={styles.input__wrapper}
        />
        <PasswordInput
          label={t('createNewPassword.fields.confirmPassword.label')}
          placeholder={t('createNewPassword.fields.confirmPassword.placeholder')}
          name="confirmPassword"
          inputSize={InputFieldSize.L}
          fieldWrapperClassName={styles['last-field__wrapper']}
          inputWrapperClassName={styles.input__wrapper}
        />
        <SubmitButton className={styles.button}>{t('createNewPassword.submitButton')}</SubmitButton>
      </Form>
    </div>
  );
};

export default CreateNewPasswordForm;
