import AbstractRequestManager from '../AbstractRequestManager';

class PublicRequestManager extends AbstractRequestManager {
  handleError = (error: Response) => {
    const errorData = {
      status: error.status,
      result: error.json(),
    };

    return Promise.reject(errorData);
  };
}

export default new PublicRequestManager();
