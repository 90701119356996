// libraries
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { useTranslation } from 'react-i18next';
// components
import Button from '../../../Button';
import Form from '../../../Form';
import Checkbox from '../../../Form/inputs/Checkbox';
import Modal, { ModalType } from '../../../Modal/Modal';
// helpers
import { useStore } from '../../../../shared/stores/RootStore/useStore';
import { replaceTag } from '../../../../shared/helpers/replaceTag';
import usePopupNotification from '../usePopupNotification';
import { InputElements } from '../../../Form/inputs/Input';
import { Notification } from '../../../../shared/stores/NotificationsStore/NotificationsStore';
// constants
import { ModalIds } from '../../../../shared/constants/modalIds';
// styles
import styles from './PopupNotification.module.scss';

enum PopupButtons {
  addFunds = 'add-funds-btn',
}

export interface PopupNotificationProps {
  data: Notification;
}

const PopupNotification: React.FC<PopupNotificationProps> = ({
  data: { nvmap: { id, title, text } = {} },
}: PopupNotificationProps) => {
  const { t } = useTranslation('notifications');
  const { updateNotificationsDate } = usePopupNotification();
  const {
    modalStore: { closeModal, openModal },
    transferFundsStore: { setTransferToZuro },
    userStore: { isVerifiedCompany },
  } = useStore();
  const [showAgain, setShowAgain] = useState<boolean>(true);
  const popupId = useMemo(() => `${ModalIds.notificationPopup}-${id}`, [id]);
  let buttons: Nullable<JSX.Element> = null;

  const handleModalClose = useCallback(() => {
    if (!showAgain) {
      updateNotificationsDate(+id);
    }

    closeModal(popupId);
  }, [closeModal, showAgain, updateNotificationsDate, popupId, id]);

  const handleCheckboxChange = useCallback((event: ChangeEvent<InputElements>) => {
    setShowAgain(!(event.target as HTMLInputElement).checked);
  }, []);

  const handleAddFundsButtonClick = useCallback(() => {
    setTransferToZuro();
    openModal(ModalIds.paymentMethodFromNotification);
    handleModalClose();
  }, [openModal, setTransferToZuro, handleModalClose]);

  const htmlParserOptions: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (domNode.name === PopupButtons.addFunds) {
        buttons = (
          <Button className={styles.popup__button} handleClick={handleAddFundsButtonClick}>
            {t('popups.addFundsButton')}
          </Button>
        );

        return null;
      }

      return replaceTag('a');
    },
  };

  if (!isVerifiedCompany) {
    closeModal(popupId);
    return null;
  }

  return (
    <Modal id={popupId} type={ModalType.base} handleCloseModal={handleModalClose}>
      <div className={styles.popup__title}>{title}</div>
      <div className={styles.popup__body}>{parse(text, htmlParserOptions)}</div>
      <div className={styles.popup__buttons}>
        {buttons}
        <Form>
          <Checkbox name="notificationStatus" label={t('popups.checkbox')} onChangeCallback={handleCheckboxChange} />
        </Form>
      </div>
    </Modal>
  );
};

export default observer(PopupNotification);
