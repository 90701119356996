import { ChangeEvent, useEffect, useState } from 'react';
import { get, find } from 'lodash';
import { useFormContext } from '../FormContext';
import { InputProps, InputTypes, InputElements } from './Input';

export enum ValidationSchema {
  required = 'required',
  exclusive = '_exclusive',
}

interface InputData {
  touched: boolean;
  getValue: () => string | string[] | boolean | void;
  isRequired: boolean;
  getOnChangeCallback: () => (event: ChangeEvent<InputElements>) => void | null;
  getValidationCallback: () => (() => void) | undefined;
  removeErrors: () => void;
  getErrors: () => string[];
  isDisabled: boolean;
  setTouched: () => void;
}

const useInputHelper = (inputProps: InputProps): InputData => {
  const {
    onChangeCallback: contextOnChangeCallback,
    setFieldErrors,
    getFieldValue,
    getErrorValue,
    validateForm,
    isFormValidated,
    isSubmitEnabled = true,
    validationSchema,
  } = useFormContext();
  const { name: inputName, type, isRequired = false } = inputProps;
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (isFormValidated) {
      setTouched(true);
    }
  }, [isFormValidated]);

  const getValueBySchema = (schemaField: string) => {
    if (!validationSchema) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let variableSchema: Dictionary<any> = validationSchema;

    inputName.split('.').forEach((p: string) => {
      if (variableSchema) {
        variableSchema = get(find(variableSchema, p), p);
      }
    });

    return (
      variableSchema &&
      variableSchema[ValidationSchema.exclusive] &&
      variableSchema[ValidationSchema.exclusive][schemaField]
    );
  };

  const getValue = () => {
    let inputValue;

    if (getFieldValue) {
      inputValue = getFieldValue(inputName);
    }

    return typeof inputValue === 'object' ? '' : inputValue;
  };

  const getErrors = () => (getErrorValue ? getErrorValue(inputName) : []);

  const getValidationCallback = () => validateForm;

  const removeErrors = () => {
    if (inputName && setFieldErrors) {
      setFieldErrors(inputName, []);
    }
  };

  const getOnChangeCallback = () => {
    const { onChangeCallback: inputOnChangeCallback, additionalOnChangeCallBack } = inputProps;

    return (event: ChangeEvent<InputElements>) => {
      const inputValue = type === InputTypes.checkbox ? (event.target as HTMLInputElement).checked : event.target.value;

      if (contextOnChangeCallback && type !== InputTypes.file) {
        contextOnChangeCallback(inputName, inputValue);
      }

      if (inputOnChangeCallback) {
        inputOnChangeCallback(event);
      }

      if (additionalOnChangeCallBack) {
        additionalOnChangeCallBack('');
      }
    };
  };

  return {
    touched,
    getValue,
    isRequired: getValueBySchema(ValidationSchema.required) || isRequired,
    getOnChangeCallback,
    getValidationCallback,
    removeErrors,
    getErrors,
    isDisabled: inputProps.disabled || !isSubmitEnabled,
    setTouched: () => setTouched(true),
  };
};

export default useInputHelper;
