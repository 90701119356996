import { useContext } from 'react';
import camelCase from 'lodash/camelCase';
import RootStore from './RootStore';
import { StoreContext } from './storeContext';
import EndpointsToStores from 'shared/constants/EndpointsToStores';

export const useStore = (): RootStore => {
  return useContext(StoreContext);
};

export const useSelectFromStoreByEndpoint = (selector: string) => {
  // @ts-ignore
  return useContext(StoreContext)[camelCase(EndpointsToStores[selector]) as keyof RootStore];
};
