import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';
import { IEventBalancesStore } from './types';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';

const initialValues = {
  // TODO: need to set 0 after integration with BE
  zuroBalance: 10835.99,
};

class EventBalancesStore {
  balancesData: IEventBalancesStore = initialValues;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setDataFromMessage = (data: IEventBalancesStore): void => {
    this.balancesData = {
      ...this.balancesData,
      zuroBalance: data.zuroBalance,
    };
  };

  [CLEAR_STORE] = (): void => {
    this.balancesData = initialValues;
  };
}

export default EventBalancesStore;
