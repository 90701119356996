import { CSSProperties } from 'react';
import { StylesConfig } from 'react-select';

export type SelectSize = {
  sm?: string;
  m?: string;
  l?: string;
  xl?: string;
};

type SelectStylesParams = {
  listSize?: string;
};

type StylesGenerator = (params?: SelectStylesParams) => StylesConfig;

export const customStyles: StylesGenerator = params => ({
  dropdownIndicator: (): CSSProperties => ({
    display: 'none',
  }),
  indicatorSeparator: (): CSSProperties => ({
    display: 'none',
  }),
  menuList: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    maxHeight: params?.listSize || '295px',
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
});
