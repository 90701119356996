// libraries
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import { FormData } from 'components/Form/FormContext';
import { addErrorToast } from '../Toast/toastHelper';
import { ModalIds } from 'shared/constants/modalIds';
import { useSearchParams } from 'shared/helpers/url';
// api
import IntegrationsApi from './api/IntegrationsApi';

export enum IntegrationTypes {
  quickBooks = 'books',
}

type IntegrationsFormData = {
  type: string;
};

export type IntegrationsList = {
  id: string;
  type: IntegrationTypes;
};

type IntegrationsData = {
  handleBackButtonClick: () => void;
  handleCloseModal: () => void;
  changeModalContent: (data: FormData) => void;
  formData: Nullable<IntegrationsFormData>;
  type: Nullable<IntegrationTypes>;
  isLoading: boolean;
  isIntegrationConnected: boolean;
  integrationsList: IntegrationsList[];
};

const PAGE_SIZE = 50;

const useIntegrations = (): IntegrationsData => {
  const {
    integrationsStore: { exportTransactionsFromDate, setExportTransactionsData, exportTransactionsData, resetData },
    modalStore: { closeAllModals, isOpen, modalIds, openModal },
    myBusinessSnapshotStore: { updateDocuments },
  } = useStore();
  const [formData, setFormData] = useState<Nullable<IntegrationsFormData>>(null);
  const [type, setType] = useState<Nullable<IntegrationTypes>>(null);
  const [isIntegrationConnected, setIsIntegrationConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [integrationsList, setIntegrationsList] = useState<IntegrationsList[]>([]);
  const [isParamRestored, setIsParamRestored] = useState<boolean>(false);
  const { getSearchParameter } = useSearchParams();
  const history = useHistory();

  const changeModalContent = useCallback(data => {
    setFormData({ ...data });
    setType(data.type);
  }, []);

  const handleCloseModal = useCallback(() => {
    if (type === IntegrationTypes.quickBooks) {
      updateDocuments(true);
    }

    closeAllModals();
    setFormData(null);
    setType(null);
    setIsParamRestored(false);
    setIsIntegrationConnected(false);
    setIsLoading(true);
    resetData();
  }, [closeAllModals, resetData, updateDocuments, type]);

  const handleBackButtonClick = useCallback(() => {
    setType(null);
    setIsLoading(false);
    resetData();
  }, [resetData]);

  const checkIntegrations = useCallback(async () => {
    setIsLoading(true);

    try {
      const { rows } = (await IntegrationsApi.getList({
        pageSize: PAGE_SIZE,
        page: 1,
        'filter[type]': IntegrationTypes.quickBooks,
      })) as List<IntegrationsList>;

      setIsIntegrationConnected(!!rows.length);
      setIntegrationsList(rows);
    } catch (e) {
      const { error } = await e;
      // addErrorToast(error);
      handleCloseModal();
    } finally {
      setIsLoading(false);
    }
  }, [handleCloseModal]);

  useEffect(() => {
    if (isParamRestored) {
      return;
    }

    const code = getSearchParameter('code');
    const companyId = getSearchParameter('realmId');
    const pageHref = getSearchParameter('state');

    if (((!code || !companyId) && !exportTransactionsData) || !exportTransactionsFromDate) {
      setIsParamRestored(true);
      return;
    }

    if (code && companyId) {
      setExportTransactionsData({
        code,
        companyId,
      });
    }

    if (pageHref) {
      history.push(pageHref);
    }

    setType(IntegrationTypes.quickBooks);
    setFormData({ type: IntegrationTypes.quickBooks });
    openModal(ModalIds.integrations);
    setIsParamRestored(true);
  }, [
    openModal,
    setExportTransactionsData,
    exportTransactionsFromDate,
    getSearchParameter,
    history,
    exportTransactionsData,
    isParamRestored,
  ]);

  useEffect(() => {
    if (isOpen(ModalIds.integrations) && isParamRestored && !exportTransactionsData && !type) {
      checkIntegrations();
    }
  }, [checkIntegrations, isOpen, modalIds.length, isParamRestored, exportTransactionsData, type]);

  return {
    formData,
    handleBackButtonClick,
    handleCloseModal,
    type,
    changeModalContent,
    isIntegrationConnected,
    isLoading,
    integrationsList,
  };
};

export default useIntegrations;
