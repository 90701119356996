// libraries
import React, { useMemo } from 'react';
// components
import PayableInvoiceForm from './PayableInvoiceForm';
import PayableInvoiceStaticInfo from './PayableInvoiceStaticInfo';
// helpers
import { Invoice, InvoiceStates } from 'components/invoices/types';
import { IConnectedAccountsData } from 'components/transactions/TransactionDetails';

interface IPayableInvoiceForm {
  invoiceDetails: Invoice;
  connectedAccountsData?: IConnectedAccountsData;
}
const PayableInvoiceDetails: React.FC<IPayableInvoiceForm> = ({
  invoiceDetails,
  connectedAccountsData,
}: IPayableInvoiceForm) => {
  const { invoiceState } = invoiceDetails;
  const isScheduled = useMemo(() => invoiceState === InvoiceStates.scheduled, [invoiceState]);

  return isScheduled ? (
    <PayableInvoiceStaticInfo invoiceDetails={invoiceDetails} />
  ) : (
    <PayableInvoiceForm invoiceDetails={invoiceDetails} connectedAccountsData={connectedAccountsData} />
  );
};

export default PayableInvoiceDetails;
