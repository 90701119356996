// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
// assets
import { ReactComponent as PaymentMethodIcon } from 'assets/icons/paymentMethod.svg';
// components
import ExternalFormAction from 'components/externalPayments/ExternalFormAction/ExternalFormAction';
import PaymentLayout from 'shared/layouts/payment';
import Button from 'components/Button';
import AppLoading from 'components/AppLoading/AppLoading';
import FormHeader from 'components/MultiStep/Form/FormHeader/FormHeader';
import FormFooter from 'components/MultiStep/Form/FormFooter/FormFooter';
import InvoiceOwnerInfo from 'components/invoices/InvoiceOwnerInfo/InvoiceOwnerInfo';
import InvoiceDetails from 'components/externalPayments/AccountReceivable/InvoiceDetails/InvoiceDetails';
import SuccessMessage from 'components/externalPayments/AccountReceivable/SuccessMessage/SuccessMessage';
// helpers
import useAccountReceivable, {
  AccountReceivableStep,
} from 'components/externalPayments/AccountReceivable/useAccountReceivable';
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
// styles
import styles from 'components/externalPayments/AccountReceivable/AccountReceivable.module.scss';

const AccountReceivable = () => {
  const { t } = useTranslation('externalPaymentPage');
  const {
    currentStep,
    isCurrentStep,
    isPlaidAvailable,
    openPlaidModal,
    contextValue,
    paymentAccount,
    invoiceDetails,
    isLoading,
    isSending,
    getPaid,
  } = useAccountReceivable();

  if (isLoading) {
    return (
      <PaymentLayout hideFooter>
        <AppLoading />
      </PaymentLayout>
    );
  }

  if (!invoiceDetails && !isLoading) {
    return <Redirect to="/not-found" />;
  }

  return (
    <PaymentLayout companyName={invoiceDetails?.counterpartyDetails?.dbaName}>
      <div className={styles.page}>
        <div className={styles.page__wrapper}>
          <h1 className={styles.page__title}>{t('receiving.pageTitle')}</h1>
          <p className={styles.page__subtitle}>{t('receiving.pageSubTitle')}</p>
          <MultiStepProvider value={contextValue}>
            <div className={styles.page__content}>
              <FormHeader
                currentStep={currentStep || AccountReceivableStep.PAYMENT_METHOD}
                lastStep={AccountReceivableStep.REVIEW_INFORMATION}
                title={
                  isCurrentStep(AccountReceivableStep.PAYMENT_METHOD)
                    ? t('receiving.completeInformation')
                    : t('receiving.reviewInformation')
                }
              />
              <div className={styles['page__invoice-owner']}>
                <InvoiceOwnerInfo
                  logoSrc={invoiceDetails?.counterpartyDetails?.logoImage}
                  companyName={invoiceDetails?.counterpartyDetails?.dbaName}
                  companyEmail={invoiceDetails?.counterpartyDetails?.email}
                  invoiceNumber={invoiceDetails?.invoiceNumber}
                />
              </div>
              <InvoiceDetails
                dueDate={invoiceDetails?.dueDate}
                amount={invoiceDetails?.totalAmount}
                description={invoiceDetails?.message}
              />
              <ExternalFormAction
                isActive={!!paymentAccount?.lastNumbers}
                handleClick={openPlaidModal}
                disabled={!isPlaidAvailable}
                activeValue={`xxxx ${paymentAccount?.lastNumbers}`}
                inactiveValue={t('noAccount')}
                title={t('paidTitle')}
                activeTitle={t('paymentMethod')}
                actionTitle={t('addMethod')}
                activeActionTitle={t('methodAdded')}
                actionIcon={PaymentMethodIcon}
              />
              <FormFooter>
                <Button className={styles['page__submit-button']} disabled={!paymentAccount} handleClick={getPaid}>
                  {t('receiving.getPaid')}
                </Button>
              </FormFooter>
            </div>
          </MultiStepProvider>
        </div>
        <SuccessMessage isLoading={isSending} />
      </div>
    </PaymentLayout>
  );
};

export default AccountReceivable;
