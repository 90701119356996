// libraries
import React, { MutableRefObject, useRef } from 'react';
// components
import CompanySearchInput, {
  CompanySearchInputProps,
} from 'components/company/SearchBar/CompanySearchInput/CompanySearchInput';
import CompanyList from './CompanyList/CompanyList';
import useCompanySearch from './useSearchBar';

type SearchBarProps = CompanySearchInputProps & {
  companiesLength: number;
  isLoaded: boolean;
  companyListContainerClassName: string;
  companyListTitle?: string;
  newBusinessWrapperClassName: string;
  value?: string;
  closeOnClick?: (func: () => void, element: MutableRefObject<Nullable<HTMLDivElement>>) => void;
  closeOnESC?: (func: () => void) => void;
  scrollBarClassName?: string;
  companyNameValue?: string;
  addNewBusinessOnClickHandler?: () => void;
};

const SearchBar: React.FC<SearchBarProps> = ({
  companiesLength,
  isLoaded,
  companyListContainerClassName,
  companyListTitle,
  newBusinessWrapperClassName,
  value,
  closeOnClick = () => {},
  closeOnESC = () => {},
  scrollBarClassName,
  companyNameValue,
  addNewBusinessOnClickHandler,
  ...props
}: SearchBarProps) => {
  const {
    name,
    className,
    iconClassName,
    additionalInputText,
    searchInputContainerClassName,
    onSearch,
    children,
  } = props;
  const { inputValue, handleChangeInput, clearSearch, closeSearch } = useCompanySearch(onSearch);
  const searchInputRef = useRef<Nullable<HTMLDivElement>>(null);

  const closeSearchWithClear = () => {
    closeSearch();
    clearSearch();
  };

  const closeSearchWithOutClear = () => {
    if (isLoaded || !inputValue) {
      closeSearch();
    }
  };

  closeOnESC(closeSearchWithClear);
  closeOnClick(closeSearchWithOutClear, searchInputRef);

  return (
    <div ref={searchInputRef} className={searchInputContainerClassName}>
      <CompanySearchInput
        onSearch={onSearch}
        name={name}
        inputValue={inputValue}
        searchInputContainerClassName={searchInputContainerClassName}
        className={className}
        additionalInputText={additionalInputText}
        iconClassName={iconClassName}
        onChangeCallback={handleChangeInput}
        value={companyNameValue}
      >
        <CompanyList
          companiesLength={companiesLength}
          companyListContainerClassName={companyListContainerClassName}
          newBusinessWrapperClassName={newBusinessWrapperClassName}
          value={inputValue}
          isLoaded={isLoaded}
          companyListTitle={companyListTitle}
          scrollBarClassName={scrollBarClassName}
          addNewBusinessOnClickHandler={addNewBusinessOnClickHandler || closeSearchWithOutClear}
        >
          {children}
        </CompanyList>
      </CompanySearchInput>
    </div>
  );
};

export default SearchBar;
