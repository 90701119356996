import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
// helpers
import { formatDate } from 'shared/helpers/format';
// assets
import { ReactComponent as Calendar } from 'assets/icons/datepicker.svg';
// components
import Button from 'components/Button';
// styles
import styles from './DatePicker.module.scss';

type DatePickerData = {
  isCalendarOpen: boolean;
  closeCalendar: () => void;
  handleCloseOnScroll: () => boolean;
  getCustomInput: (
    date?: Nullable<Date>,
    placeholder?: string,
    customFormat?: (date: Date) => string,
  ) => React.ReactNode;
};

const useDatePicker = (): DatePickerData => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const closeCalendar = useCallback(() => {
    setIsCalendarOpen(false);
  }, []);

  const handleCloseOnScroll = useCallback(() => {
    closeCalendar();
    return true;
  }, [closeCalendar]);

  const handleChangeButtonClick = useCallback(() => {
    setIsCalendarOpen(!isCalendarOpen);
  }, [isCalendarOpen]);

  const getCustomInput = useCallback(
    (date, placeholder, customFormat = () => {}) => {
      return (
        <Button
          className={classNames(styles.datepicker, {
            [styles['datepicker--empty']]: !date,
          })}
          btnLink
          handleClick={handleChangeButtonClick}
          area-label="datepicker-input"
        >
          {date ? customFormat(date) || formatDate(date as Date) : placeholder || ''}
          <Calendar className={styles['datepicker__control-icon']} />
        </Button>
      );
    },
    [handleChangeButtonClick],
  );

  return {
    isCalendarOpen,
    closeCalendar,
    handleCloseOnScroll,
    getCustomInput,
  };
};

export default useDatePicker;
