// libraries
import React, { useState } from 'react';
import classnames from 'classnames';
// helpers
import { checkBackgroundColor } from 'shared/helpers/files';
// assets
import DefaultEntityLogo from 'assets/images/default-entity-logo.svg';
// styles
import './EntityLogo.scss';

interface EntityLogoProps {
  className?: string;
  imageClassName?: string;
  logoSrc?: string;
  icon?: Nullable<React.ReactElement>;
}

const WHITE_COLOR = '255, 255, 255';

const EntityLogo: React.FC<EntityLogoProps> = ({
  logoSrc = '',
  className,
  imageClassName = '',
  icon,
}: EntityLogoProps) => {
  const [hasBorder, setHasBorder] = useState(false);

  if (logoSrc) {
    checkBackgroundColor(logoSrc, WHITE_COLOR, setHasBorder);
  }

  return (
    <div className={classnames('entity-logo__wrapper', className)}>
      <img
        src={logoSrc || DefaultEntityLogo}
        alt="logo"
        className={classnames('entity-logo', imageClassName, { 'has-border': logoSrc && hasBorder })}
      />
      {icon && <span className="entity-logo__icon">{icon}</span>}
    </div>
  );
};

export default EntityLogo;
