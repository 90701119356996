// libraries
import React from 'react';
// styles
import subtitleStyles from 'components/EntryTitle/subtitles/Subtitle/Subtitle.module.scss';

interface ISubtitleProps {
  subtitle: string;
  email: string;
  subtitleEnding?: string;
}

const Subtitle: React.FC<ISubtitleProps> = ({ subtitle, email, subtitleEnding }: ISubtitleProps) => (
  <div className={subtitleStyles.subtitle}>
    <p className={subtitleStyles.subtitle__text}>{subtitle}</p>
    {email && <p className={subtitleStyles.subtitle__email}>{email}</p>}
    {subtitleEnding && <p className={subtitleStyles.subtitle__text}>{subtitleEnding}</p>}
  </div>
);

export default Subtitle;
