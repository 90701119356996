// libraries
import React from 'react';
import classnames from 'classnames';
// helpers
import { useListContext } from 'components/list/ListContext';
// assets
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down-icon.svg';
// styles
import styles from './TableHead.module.scss';

interface ISortButtonProps {
  source?: string;
  label?: string;
}

const SortButton = ({ source = '', label = '' }: ISortButtonProps) => {
  const { sort = {}, sortBy = () => {} } = useListContext();
  const isActive = sort.field === source;
  const { order = '' } = sort;

  return (
    <div className={styles['table-header__cell']}>
      <button
        className={classnames(
          styles['table-header__cell'],
          styles['table-header__sortable-button'],
          styles['table-header__sortable-cell'],
          {
            [styles['table-header__sortable-cell--active']]: isActive,
          },
        )}
        type="button"
        onClick={() => {
          sortBy(source);
        }}
      >
        {label}
        <ArrowIcon
          className={classnames(
            styles['table-header__sort-arrow'],
            styles[`table-header__sort-arrow--${isActive ? order.toLowerCase() : 'desc'}`],
          )}
          width={14}
          height={15}
        />
      </button>
    </div>
  );
};

export default SortButton;
