// libraries
import React from 'react';
// components
import PaymentMethodsForm from 'components/settings/PaymentMethodsForm/PaymentMethodsForm';
import SettingFormWrapper from 'components/settings/SettingFormWrapper/SettingFormWrapper';

const SettingsPaymentMethods: React.FC = () => {
  return (
    <SettingFormWrapper>
      <PaymentMethodsForm />
    </SettingFormWrapper>
  );
};

export default SettingsPaymentMethods;
