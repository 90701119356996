export enum ModalIds {
  addAmountPopup = 'addAmountPopup',
  transferFunds = 'transferFunds',
  paymentMethod = 'paymentMethod',
  registrationCompanyModal = 'registrationCompanyModal',
  confirmationModal = 'confirmationModal',
  notificationPopup = 'notificationPopup',
  paymentMethodFromNotification = 'paymentMethodFromNotifications',
  paymentMethodFromTransactionDetails = 'paymentMethodFromTransactionDetails',
  transactionDetails = 'transactionDetails',
  verifyPhone = 'verifyPhone',
  createInvoice = 'createInvoice',
  beneficialOwner = 'beneficialOwner',
  deleteBeneficialOwner = 'deleteBeneficialOwner',
  firstLoginPopupNotification = 'firstLoginPopupNotification',
  inviteBusiness = 'inviteBusiness',
  inviteWarningPopup = 'inviteWarningPopup',
  integrations = 'integrations',
  exportTransactions = 'exportTransactions',
  imageCropper = 'imageCropper',
  externalPayments = 'externalPayments',
  verifyAccount = 'verifyAccount',
  verificationStatus = 'verificationStatus',
  editProfileForm = 'editProfile',
  successPaymentReceiving = 'successPaymentReceiving',
  successPasswordChange = 'successPasswordChange',
  confirmDisconnectAccount = 'confirmDisconnectAccount',
  confirmDeletePaymentMethod = 'confirmDeletePaymentMethod',
  changePasswordModal = 'changePasswordModal',
  shortList = 'shortList',
}

export default ModalIds;
