// libraries
import React, { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import Modal, { ModalType } from 'components/Modal/Modal';
import InviteBusinessForm from './InviteBusinessForm/InviteBusinessForm';
import InvoiceDetailsForm from './InvoiceDetailsForm/InvoiceDetailsForm';
import Form from 'components/Form';
import WarningPopup from './WarningPopup/WarningPopup';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { USA_COUNTRY } from 'shared/helpers/countries';
// helpers
import useInviteBusiness, { ModalContent } from './useInviteBusiness';
import INVITE_BUSINESS_FORM_SCHEMA from './InviteBusinessForm/validation-rules';
import INVOICE_DETAILS_FORM_SCHEMA from './InvoiceDetailsForm/validation-rules';
// styles
import styles from './InviteBusiness.module.scss';

const InviteBusiness: React.FC = () => {
  const { t } = useTranslation('inviteBusiness');
  const {
    isFormSubmitted,
    sendInvoice,
    handleBackButtonClick,
    displayedModalContent,
    mainFormData,
    submitInviteBusinessForm,
    errorMessage,
    forceFormSubmission,
    handleCloseModal,
  } = useInviteBusiness();
  const isMainForm = useMemo(() => displayedModalContent === ModalContent.mainForm, [displayedModalContent]);

  const generateModalContent = useCallback(() => {
    if (isFormSubmitted) {
      return <p className={styles['invoice-success-message']}>{t('inviteSent')}</p>;
    }

    if (isMainForm) {
      return (
        <div className={styles['invite-business__inner']}>
          <h2 className={styles.title}>{t('inviteBusinessForm.title')}</h2>
          <Form
            submitForm={submitInviteBusinessForm}
            validationSchema={INVITE_BUSINESS_FORM_SCHEMA}
            data={mainFormData}
          >
            <InviteBusinessForm />
          </Form>
        </div>
      );
    }

    if (displayedModalContent === ModalContent.invoiceDetails) {
      return (
        <>
          <h2 className={styles.title__invoice}>{t('invoiceDetailsForm.title')}</h2>
          <Form
            submitForm={sendInvoice}
            validationSchema={INVOICE_DETAILS_FORM_SCHEMA}
            data={{ address: { country: USA_COUNTRY } }}
          >
            <InvoiceDetailsForm handleCancelClick={handleBackButtonClick} />
          </Form>
        </>
      );
    }

    return null;
  }, [
    displayedModalContent,
    handleBackButtonClick,
    isFormSubmitted,
    isMainForm,
    mainFormData,
    sendInvoice,
    submitInviteBusinessForm,
    t,
  ]);

  return (
    <>
      <Modal id={ModalIds.inviteBusiness} type={ModalType.full} handleCloseModal={handleCloseModal}>
        <div
          className={classNames({
            [styles['invite-business__wrapper']]: isMainForm,
            [styles['invoice-details__wrapper']]: !isMainForm,
            [styles['success-message-wrapper']]: isFormSubmitted,
          })}
        >
          {generateModalContent()}
        </div>
      </Modal>
      <WarningPopup
        errorMessage={errorMessage}
        forceFormSubmission={forceFormSubmission}
        handleCloseModals={handleCloseModal}
      />
    </>
  );
};

export default observer(InviteBusiness);
