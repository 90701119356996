import * as Yup from 'yup';
import {
  getLatinNameSchema,
  getSSNchema,
  getLatinNameSchemaWithNumberAndApostrophe,
} from 'shared/helpers/validationSchemes';

export default Yup.object().shape({
  firstName: getLatinNameSchema().required('forms.firstName.required'),
  lastName: getLatinNameSchema().required('forms.lastName.required'),
  address: getLatinNameSchemaWithNumberAndApostrophe().required('forms.homeAddress.required'),
  ssn: getSSNchema().required('forms.ssn.required'),
});
