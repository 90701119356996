// libraries
import React from 'react';
// helpers
import useFieldHelper from 'components/list/field/fieldHelper';
import { IFieldProps } from 'components/list/field';

export const EMPTY_VALUE_INDICATOR = '-';

type TextFieldValue = React.ReactNode;

const TextField = ({ source = '', label }: IFieldProps<TextFieldValue>) => {
  const { getValue } = useFieldHelper({ source, label });

  return <>{getValue() || EMPTY_VALUE_INDICATOR}</>;
};

export default TextField;
