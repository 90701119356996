// libraries
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { MaskedInput, Select } from 'components/Form/inputs';
// constants
import { SSN_MASK, EIN_MASK } from 'shared/constants';

const TAX_TYPES = [
  {
    value: 'EIN',
    label: 'EIN',
  },
  {
    value: 'SSN',
    label: 'SSN',
  },
  {
    value: 'TIN',
    label: 'TIN',
  },
];

const EIN_TAX_TYPE = 'EIN';

type TaxIdFieldsProps = {
  defaultTaxIdValue?: string;
  taxIdFieldLabelClassName?: string;
  idNumberFieldLabelClassName?: string;
  taxIdFieldWrapperClassName?: string;
  idNumberFieldWrapperClassName?: string;
  taxIdInputWrapperClassName?: string;
  idNumberInputWrapperClassName?: string;
  onChangeCallback?: () => void;
  isIdNumberFieldDisabled?: boolean;
};

const TaxIdFields: React.FC<TaxIdFieldsProps> = ({
  defaultTaxIdValue = '',
  taxIdFieldLabelClassName = '',
  idNumberFieldLabelClassName = '',
  taxIdFieldWrapperClassName = '',
  idNumberFieldWrapperClassName = '',
  taxIdInputWrapperClassName = '',
  idNumberInputWrapperClassName = '',
  isIdNumberFieldDisabled = true,
  onChangeCallback = () => {},
}: TaxIdFieldsProps) => {
  const { t } = useTranslation('forms');
  const [taxIdValue, setTaxIdValue] = useState('');
  const [idNumberMask, setIdNumberMask] = useState('');
  const [isIdNumberInputDisabled, setIsIdNumberInputDisabled] = useState(true);

  const handleOnTaxIdValueChange = useCallback(
    event => {
      setTaxIdValue(event);
      setIsIdNumberInputDisabled(false);
      onChangeCallback();
    },
    [onChangeCallback],
  );

  useEffect(() => {
    if (defaultTaxIdValue) {
      setTaxIdValue(defaultTaxIdValue);
      setIsIdNumberInputDisabled(false);
    }
  }, [defaultTaxIdValue]);

  useEffect(() => {
    if (taxIdValue === EIN_TAX_TYPE) {
      setIdNumberMask(EIN_MASK);
      return;
    }

    setIdNumberMask(SSN_MASK);
  }, [taxIdValue]);

  return (
    <>
      <Select
        name="taxId"
        options={TAX_TYPES}
        label={t('taxIdFields.fields.taxId.label')}
        placeholder={t('taxIdFields.fields.taxId.placeholder')}
        isSearchable
        isFormField
        onChangeCallback={handleOnTaxIdValueChange}
        defaultValue={taxIdValue}
        fieldLabelClassName={taxIdFieldLabelClassName}
        fieldWrapperClassName={taxIdFieldWrapperClassName}
        inputWrapperClassName={taxIdInputWrapperClassName}
      />
      <MaskedInput
        name="idNumber"
        mask={idNumberMask}
        label={t('taxIdFields.fields.idNumber.label')}
        placeholder={t('taxIdFields.fields.idNumber.placeholder')}
        fieldLabelClassName={idNumberFieldLabelClassName}
        fieldWrapperClassName={idNumberFieldWrapperClassName}
        inputWrapperClassName={idNumberInputWrapperClassName}
        additionalOnChangeCallBack={onChangeCallback}
        disabled={isIdNumberInputDisabled && isIdNumberFieldDisabled}
      />
    </>
  );
};

export default TaxIdFields;
