import { makeAutoObservable } from 'mobx';
import RootStore from 'shared/stores/RootStore/RootStore';

class ToastStore {
  toastIdsList: string[] = [];

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  addToastId = (toastId: string): void => {
    this.toastIdsList = [...this.toastIdsList, toastId];
  };

  removeToastId = (toastId: string): void => {
    this.toastIdsList = this.toastIdsList.filter(id => id !== toastId);
  };

  isOpenToast = (toastId: string): boolean => {
    return this.toastIdsList.includes(toastId);
  };
}

export default ToastStore;
