import ResetPassword from 'router/views/ResetPassword';

export default [
  {
    path: '/password-reset',
    exact: true,
    isProtected: false,
    component: ResetPassword,
    name: 'Reset Password',
  },
];
