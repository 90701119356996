import sub from 'date-fns/sub';

export enum VerificationStep {
  BUSINESS_INFORMATION = 'business',
  TAX = 'tax',
  BENEFICIAL_OWNERS = 'beneficial-owners',
  BANK_ACCOUNTS = 'bank-accounts',
}

const VERIFICATION_PATH = '/verification';

export const VERIFICATION_FLOW = {
  businessInformation: VERIFICATION_PATH,
  tax: `${VERIFICATION_PATH}/tax`,
  beneficialOwners: `${VERIFICATION_PATH}/beneficial-owners`,
  beneficialOwnersForm: (id?: number): string =>
    id ? `${VERIFICATION_PATH}/beneficial-owners/${id}/form` : `${VERIFICATION_PATH}/beneficial-owners/form`,
  linkAccount: (): string => `${VERIFICATION_PATH}/bank-accounts`,
};

const AGE_LIMIT = 13;

export const MIN_BIRTH_DATE = new Date(1900, 0, 1);
export const MAX_BIRTH_DATE = sub(new Date(), { years: AGE_LIMIT });

export const getVerificationLink = (nextStep?: VerificationStep): string => {
  if (!nextStep || nextStep === VerificationStep.BUSINESS_INFORMATION) {
    return VERIFICATION_PATH;
  }

  return `${VERIFICATION_PATH}/${nextStep}`;
};
