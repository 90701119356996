// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// assets
import users from 'assets/icons/users.svg';
// components
import Link from 'components/Link/Link';
import InfoHeader from 'components/company/CompanyInfo/InfoHeader/InfoHeader';
// helpers
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
// mock
import { getMockContacts } from 'components/contacts/mockData';
// styles
import styles from './CompanyInfo.module.scss';

type CompanyInfoProps = {
  company: Nullable<IBusinessSnapshot>;
};

const CompanyInfo: React.FC<CompanyInfoProps> = ({ company }: CompanyInfoProps) => {
  const { t } = useTranslation('company');

  // TODO: change during integration
  const contactsAmount = getMockContacts().length;

  return (
    <div className={styles['company-info']}>
      <InfoHeader companyProfile={company} />

      <Link
        linkWrapperClassName={styles['company-addition-info']}
        linkClassName={styles['client-link__wrapper']}
        to="/contacts"
      >
        <img src={users} alt="users" className={styles['client-img']} />
        <span>
          {t('clientListButton')} ({contactsAmount})
        </span>
      </Link>
    </div>
  );
};

export default observer(CompanyInfo);
