import ConfirmEmail from '../../views/ConfirmEmail/ConfirmEmail';

export default [
  {
    path: '/confirm-email',
    exact: true,
    isProtected: false,
    component: ConfirmEmail,
    name: 'Confirm Email',
  },
];
