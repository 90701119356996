import { useCallback, useEffect, useState } from 'react';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import { Invoice, InvoiceStates } from 'components/invoices/types';
import { completedStates } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
import { getMockProfile } from './mockData';
import useMyBusinessProfile from '../CompanyProfileForm/useMyBusinessProfile';
import useInvoiceItems from 'components/invoices/useInvoiceItems';

export interface UserDetailsData {
  error: Nullable<string>;
  invoices: Invoice[];
  companyProfile: Nullable<IBusinessSnapshot>;
}

const useCompanyDetails = (id?: string): UserDetailsData => {
  const [error] = useState<Nullable<string>>(null);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [companyProfile, setCompanyProfile] = useState<Nullable<IBusinessSnapshot>>(null);
  const { myBusinessProfile } = useMyBusinessProfile();
  const { invoiceItems } = useInvoiceItems();

  const getFilteredInvoiceHistory = (companyName = '') => {
    setInvoices(
      (invoiceItems || []).filter(
        ({ counterpartyDetails, invoiceState = InvoiceStates.new }: Invoice) =>
          counterpartyDetails?.dbaName === companyName && completedStates.includes(invoiceState),
      ),
    );
  };

  const getCompanyData = useCallback(async () => {
    if (!id || (myBusinessProfile && id === myBusinessProfile.id)) {
      setCompanyProfile(myBusinessProfile);
    } else {
      // TODO: remove mock code after the integration. Add setError(true) if company not found
      setCompanyProfile(getMockProfile(id) as IBusinessSnapshot);
    }
  }, [myBusinessProfile, id]);

  useEffect(() => {
    getCompanyData();

    getFilteredInvoiceHistory(companyProfile?.dbaName ?? '');
  }, [getCompanyData, companyProfile?.dbaName]);

  return {
    error,
    invoices,
    companyProfile,
  };
};

export default useCompanyDetails;
