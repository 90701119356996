// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EntryButton from 'components/EntryButton/EntryButton';
import TaxIdFields from 'components/Form/inputGroups/TaxIdFields/TaxIdFields';
// helpers
import areFieldsFullfilled from 'shared/helpers/inputs';
// constants
import { useFormContext } from 'components/Form/FormContext';
// styles
import styles from 'components/verification/Business/Business.module.scss';

const currentPageFields = ['taxId', 'idNumber'];

const TaxFields: React.FC = () => {
  const { t } = useTranslation('forms');
  const { data } = useFormContext();

  const isButtonDisabled = !areFieldsFullfilled(data, currentPageFields);

  return (
    <>
      <div className={styles['fields-container']}>
        <TaxIdFields isIdNumberFieldDisabled={false} />
      </div>
      <EntryButton disabled={isButtonDisabled} entryButtonClassName={styles.button}>
        {t('continueButtonDefaultText')}
      </EntryButton>
    </>
  );
};

export default TaxFields;
