// libraries
import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import Form from 'components/Form';
import { RadioInput } from 'components/Form/inputs';
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import AppLoading from 'components/AppLoading/AppLoading';
// helpers
import DELETE_INTEGRATION_VALIDATION_SCHEMA from './validation-rules';
import useDeleteIntegration from './useDeleteIntegration';
import { IntegrationsList } from '../useIntegrations';
// styles
import styles from './DeleteIntegration.module.scss';
import modalStyles from '../Integrations.module.scss';

type DeleteIntegrationProps = {
  handleCancelClick: () => void;
  integrations: IntegrationsList[];
};

const DeleteIntegration: React.FC<DeleteIntegrationProps> = ({
  handleCancelClick,
  integrations,
}: DeleteIntegrationProps) => {
  const { t } = useTranslation('integrations');
  const { deleteIntegration, isFormSubmitted, isLoading } = useDeleteIntegration();
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState<boolean>(false);

  const showDeleteButton = useCallback(() => {
    setIsDeleteButtonShown(true);
  }, []);

  if (isFormSubmitted) {
    return (
      <>
        <h2 className={modalStyles.title}>{t('deleteIntegration.successTitle')}</h2>
        <p className={classNames(styles.subtitle, styles['subtitle--success'])}>
          {t('deleteIntegration.successSubtitle')}
        </p>

        <div className={modalStyles.buttons__wrapper}>
          <Button className={modalStyles['cancel-button']} handleClick={handleCancelClick}>
            {t('closeButton')}
          </Button>
        </div>
      </>
    );
  }

  return (
    <AppLoading isActive={isLoading} hideContent>
      <h2 className={modalStyles.title}>{t('title')}</h2>
      <p className={modalStyles.subtitle}>{t('deleteIntegration.subtitle')}</p>
      <Form validationSchema={DELETE_INTEGRATION_VALIDATION_SCHEMA} submitForm={deleteIntegration}>
        {integrations.map(({ id, type }) => (
          <RadioInput
            key={id}
            name="integrationId"
            label={t(`type.${type}`)}
            value={id}
            onChangeCallback={showDeleteButton}
          />
        ))}
        <div className={modalStyles.buttons__wrapper}>
          <Button className={modalStyles['cancel-button']} handleClick={handleCancelClick}>
            {t('closeButton')}
          </Button>
          {isDeleteButtonShown && <SubmitButton outline>{t('deleteButton')}</SubmitButton>}
        </div>
      </Form>
    </AppLoading>
  );
};

export default observer(DeleteIntegration);
