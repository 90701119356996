// libraries
import React from 'react';
import classnames from 'classnames';
// components
import Input from '../Input';
import InputErrors from '../InputErrors';
import { TextInputProps } from '../TextInput/TextInput';
// styles
import textareaStyles from './Textarea.module.scss';
import styles from '../TextInput/TextInput.module.scss';

export interface TextareaProps extends TextInputProps {
  maxLength?: number;
  shouldCounterBeShown?: boolean;
  counterClassName?: string;
}

const Textarea: React.FC<TextareaProps> = (props: TextareaProps) => {
  const {
    label,
    children,
    inputElement,
    inputWrapperClassName = '',
    fieldWrapperClassName = '',
    fieldLabelClassName = '',
    maxLength = 250,
    shouldCounterBeShown = true,
    inputSize,
    counterClassName = '',
    ...inputProps
  } = props;

  return (
    <Input {...inputProps}>
      {({ isRequired, errorMessages, inputCommonProps }) => {
        const inputClassName = classnames(
          styles['field-input'],
          textareaStyles['field-input'],
          textareaStyles['field-textarea'],
          { [textareaStyles.invalid]: !!errorMessages?.length },
          inputCommonProps.className,
        );
        const fieldClassName = classnames(
          styles['field-wrapper'],
          {
            [styles[`field-wrapper--${inputSize}`]]: inputSize,
          },
          textareaStyles['field-wrapper'],
          { [styles['field-wrapper--disabled']]: inputCommonProps.disabled },
          fieldWrapperClassName,
        );
        const letterCounterClassName = classnames(textareaStyles['letters-counter'], counterClassName);

        const valueLength = inputCommonProps.value?.length || 0;
        const numberOfLetters = `${valueLength}/${maxLength}`;

        return (
          <div className={fieldClassName}>
            {label && (
              <label
                htmlFor={inputCommonProps.name}
                className={classnames(styles['field-label'], fieldLabelClassName, {
                  [styles['field-label--required']]: isRequired,
                })}
              >
                {label}
              </label>
            )}
            <div className={inputWrapperClassName}>
              <div className={textareaStyles.textarea__container}>
                <textarea wrap="soft" maxLength={maxLength} {...inputCommonProps} className={inputClassName}>
                  {children}
                </textarea>
                {shouldCounterBeShown && <span className={letterCounterClassName}>{numberOfLetters}</span>}
              </div>
              {errorMessages ? <InputErrors errorMessages={errorMessages} /> : null}
            </div>
          </div>
        );
      }}
    </Input>
  );
};

export default Textarea;
