// libraries
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser';
// components
import Modal, { ModalType } from 'components/Modal/Modal';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import { replaceTag } from 'shared/helpers/replaceTag';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// styles
import styles from './VerificationNotification.module.scss';

const VerificationNotification: React.FC = () => {
  const {
    modalStore: { closeModal },
    verificationStore: { verificationPopup, verificationLink },
  } = useStore();
  const { title = '', text = '' } = verificationPopup?.nvmap || {};

  const handleModalClose = useCallback(() => {
    closeModal(ModalIds.verifyAccount);
  }, [closeModal]);

  return (
    <Modal id={ModalIds.verifyAccount} type={ModalType.base} handleCloseModal={handleModalClose}>
      <div className={styles.popup__wrapper}>
        <div className={styles.popup__title}>{title}</div>
        <div className={styles.popup__body}>{parse(text, replaceTag('a', verificationLink, handleModalClose))}</div>
      </div>
    </Modal>
  );
};

export default observer(VerificationNotification);
