// libraries
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { Invoice } from 'components/invoices/types';
// helpers
import useMultiStep from 'components/MultiStep/useMultiStep';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
import usePlaid, { PlaidHelper } from 'components/paymentMethods/PaymentMethod/PaymentOptions/usePlaid';
import { getMockData } from 'shared/helpers/simulateAsyncCall';
import { getNewInvoice } from 'components/invoices/InvoiceHistory/mockData';
import { addErrorToast } from 'components/Toast/toastHelper';
import { MultiStepContextData } from 'components/MultiStep/MultiStepContext';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
// store
import { useStore } from 'shared/stores/RootStore/useStore';

const redirectDelay = 3000;

export type PaymentToken = {
  token: string;
};

export enum AccountReceivableStep {
  PAYMENT_METHOD = 0,
  REVIEW_INFORMATION = 1,
}

type AccountReceivableParams = PlaidHelper & {
  currentStep: number;
  isCurrentStep: (step: number) => boolean;
  contextValue: MultiStepContextData;
  paymentAccount: Nullable<Account>;
  invoiceDetails: Nullable<Invoice>;
  isLoading: boolean;
  isSending: boolean;
  getPaid: () => void;
};

const useAccountReceivable = (): AccountReceivableParams => {
  const history = useHistory();
  const { token } = useParams<PaymentToken>();
  const contextValue = useMultiStep();
  const { currentStep, goToNextStep, isCurrentStep } = contextValue;

  const {
    modalStore: { openModal, closeModal },
  } = useStore();

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState<Nullable<Invoice>>(null);
  const [paymentAccount, setPaymentAccount] = useState<Nullable<Account>>(null);

  const getInvoiceDetails = useCallback(async () => {
    setIsLoading(true);

    try {
      // TODO: Replace after integration with BE - Get invoice details by token
      const invoice = await getMockData(
        // @ts-ignore
        getNewInvoice({
          dueDate: new Date(),
          amount: Math.floor(Math.random() * 10000),
          description: 'Description',
          token,
        }),
      );

      setInvoiceDetails(invoice);
    } catch (e) {
      const { message } = await e;
      addErrorToast(message);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getInvoiceDetails();
  }, [getInvoiceDetails]);

  const addPaymentMethod = useCallback(
    (account: Account) => {
      setPaymentAccount(account);
      goToNextStep(AccountReceivableStep.PAYMENT_METHOD, {});
    },
    [goToNextStep],
  );

  const changePaymentMethod = useCallback((account: Account) => {
    setPaymentAccount(account);
  }, []);

  const { openPlaidModal, isPlaidAvailable } = usePlaid({
    submitCallback: isCurrentStep(AccountReceivableStep.PAYMENT_METHOD) ? addPaymentMethod : changePaymentMethod,
  });

  const getPaid = useCallback(async () => {
    setIsSending(true);
    openModal(ModalIds.successPaymentReceiving);
    try {
      // TODO: Replace after integration with BE
      const paymentData = {
        token,
        paymentAccount,
      };
      const companyData: IBusinessSnapshot = await getMockData({ ...paymentData, id: 3 });

      setTimeout(() => {
        closeModal(ModalIds.successPaymentReceiving);
        history.push(`/companies/${companyData.id}/public-profile`);
      }, redirectDelay);
    } catch (e) {
      const { message } = await e;
      addErrorToast(message);
    } finally {
      setIsSending(false);
    }
  }, [openModal, history, paymentAccount, token, closeModal]);

  return {
    contextValue,
    currentStep,
    isCurrentStep,
    openPlaidModal,
    isPlaidAvailable,
    paymentAccount,
    invoiceDetails,
    isLoading,
    isSending,
    getPaid,
  };
};

export default useAccountReceivable;
