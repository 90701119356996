// libraries
import React from 'react';
import classNames from 'classnames';
// assets
import { ReactComponent as BankIcon } from 'assets/icons/bank-account.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-icon.svg';
// helpers
import useConnectedAccounts from 'components/paymentMethods/useConnectedAccounts';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
// components
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
// styles
import styles from './BankAccount.module.scss';

type BankAccountProps = {
  bank: Account;
};

const BankAccount: React.FC<BankAccountProps> = ({ bank }: BankAccountProps) => {
  const { brand, lastNumbers, logo } = bank || {};

  const { showConfirmationModal } = useConnectedAccounts();

  const formatAccountNumber = (currentAccountNumber: string) => {
    if (!currentAccountNumber) return '';

    return `xxxx${currentAccountNumber}`;
  };

  return (
    <div className={styles['bank-account__container']}>
      <div className={styles['icon-container']}>
        {logo ? <EntityLogo logoSrc={logo} className={styles['bank-logo']} /> : <BankIcon />}
      </div>
      <div className={styles.name}>
        <p className={styles.bank}>{brand}</p>
        <p>{formatAccountNumber(lastNumbers)}</p>
      </div>
      <div className={styles.actions}>
        <button className={classNames(styles['delete-icon'], styles.icon)} onClick={() => showConfirmationModal(bank)}>
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
};

export default BankAccount;
