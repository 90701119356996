// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import { MaskedInput } from 'components/Form/inputs';
import SubmitButton from 'components/Form/buttons/SubmitButton';
// helpers
import { PHONE_MASK } from 'shared/constants';
// styles
import styles from 'components/settings/AccountTabForm/AccountTabForm.module.scss';
import authentificationBlockStyles from 'components/settings/AccountTabForm/AuthentificationBlock/AuthentificationBlock.module.scss';

const PhoneFormFields = () => {
  const { t } = useTranslation('settingsPage');

  return (
    <MaskedInput
      name="phone"
      mask={PHONE_MASK}
      label={t('authentificationBlock.fields.mobileNumber.label')}
      placeholder={t('authentificationBlock.fields.mobileNumber.placeholder')}
      fieldWrapperClassName={classNames(styles.field__wrapper, authentificationBlockStyles['phone-field__wrapper'])}
      inputWrapperClassName={styles.input__wrapper}
      fieldLabelClassName={styles['field-label']}
    >
      <SubmitButton
        className={classNames(authentificationBlockStyles['button-link'], authentificationBlockStyles['send-code'])}
      >
        {t('authentificationBlock.fields.mobileNumber.sendCode')}
      </SubmitButton>
    </MaskedInput>
  );
};

export default PhoneFormFields;
