// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// styles
import styles from './BalanceMenu.module.scss';
import Button from 'components/Button';
// hooks
import useBalanceMenu from './useBalanceMenu';

const BalanceMenu: React.FC = () => {
  const { t } = useTranslation('balance');

  const { addFunds, withdrawFunds } = useBalanceMenu();

  return (
    <div className={styles['balance-menu']}>
      <div className={styles['balance-menu__list']}>
        <Button handleClick={addFunds} className={styles['balance-menu__button-primary']}>
          {t('addFunds')}
        </Button>
        <Button handleClick={withdrawFunds} className={styles['balance-menu__button-secondary']} outline>
          {t('withdrawFunds')}
        </Button>
      </div>
    </div>
  );
};

export default BalanceMenu;
