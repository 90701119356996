// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// constants
import { dropDownMenu } from '../menuMap';
// components
import DropdownButton from 'components/Button/DropdownButton/DropdownButton';
import DropdownButtonItem from 'components/Button/DropdownButton/DropdownButtonItem';
import DropdownButtonDivider from 'components/Button/DropdownButton/DropdownButtonDivider';
// helpers
import useLogout from 'shared/hooks/useLogout';
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import './CompanyButton.scss';
// assets
import DefaultUserAvatar from 'assets/images/user-logo-placeholder.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/logout-icon.svg';

const CompanyButton: React.FC = () => {
  const { t } = useTranslation('navigation');
  const { logout } = useLogout();
  const {
    myBusinessSnapshotStore: { myBusinessProfile },
  } = useStore();

  // TODO: get src and company name from BE. Add alt to company name
  const toggle = (
    <div className="company-logo">
      <img src={myBusinessProfile?.logoImage || DefaultUserAvatar} alt="Company Logo" />
    </div>
  );

  return (
    <DropdownButton hasArrow toggle={toggle} className="company-dropdown-btn__wrapper">
      {dropDownMenu.map(({ name, path, icon, prevPathEnable }) => (
        <DropdownButtonItem href={path} key={path} prevPathEnable={prevPathEnable}>
          <span className="dropdown-menu__icon">{icon}</span>
          {t(`main.${name}`)}
        </DropdownButtonItem>
      ))}
      <DropdownButtonDivider />
      <DropdownButtonItem onClick={logout}>
        <span className="dropdown-menu__icon logout-icon">
          <LogOutIcon width={12} height={12} />
        </span>
        {t('main.Log Out')}
      </DropdownButtonItem>
    </DropdownButton>
  );
};

export default observer(CompanyButton);
