// libraries
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import ContactsForm from 'components/contacts/ContactsForm';
import FormTitle from 'components/MultiStep/Form/FormTitle/FormTitle';
import Breadcrumbs from 'components/Breadcrumbs';
// types
import { FormData } from 'components/Form/FormContext';
// styles
import styles from './index.module.scss';

interface IContactsAddEdit {
  title: string;
  submitForm: (data: FormData) => void;
}

const ContactsAddEdit: React.FC<IContactsAddEdit> = ({ title, submitForm }: IContactsAddEdit) => {
  const { t } = useTranslation('contactPages');
  const history = useHistory();

  const handleReturn = useCallback(() => {
    history.push('/contacts');
  }, []);

  return (
    <div className={styles['contacts-page']}>
      <div className={styles['contacts-page__wrapper']}>
        <div className={styles['contacts-page__return-btn']}>
          <Breadcrumbs text={t('returnButton')} onClick={handleReturn} />
        </div>
        <div className={styles['contacts-page__content']}>
          <div className={styles['contacts-form__title']}>
            <FormTitle title={title} />
          </div>
          <ContactsForm submitForm={submitForm} />
        </div>
      </div>
    </div>
  );
};

export default ContactsAddEdit;
