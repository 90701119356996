// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// constants
import { PayableInvoiceSteps } from 'components/transactions/TransactionDetails/PayableInvoiceDetails/constants';
// components
import TransferDateField from 'components/transactions/TransactionCard/TransferDateField';
import VerificationStar from 'components/company/CompanyInfo/InfoHeader/VerificationStar';
import TransactionCardField from 'components/transactions/TransactionCard/TransactionCardField';
import InvoiceStatus from 'components/transactions/TransactionCard/InvoiceStatus/InvoiceStatus';
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
// helpers
import { formatAmountToLocal } from 'shared/helpers/format';
import { InvoiceTypes, Invoice, InvoiceStates } from 'components/invoices/types';
import { getInvoiceCompanyLogo } from 'components/invoices/invoiceHelpers';
import VerificationStatus from 'shared/constants/verificationStatus';
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// styles
import styles from './TransactionCard.module.scss';

interface IInvoiceCard {
  data: Invoice;
  children?: React.ReactNode;
}

const MAX_MESSAGE_LEGTH = 190;

const InvoiceCard: React.FC<IInvoiceCard> = ({ data, children }: IInvoiceCard) => {
  const { t } = useTranslation('transactionHistory');
  const { currentStep } = useMultiStepContext();
  const { invoiceType, totalAmount, counterpartyDetails, message = '', invoiceNumber, invoiceState, payDate } =
    data || {};

  const tooltipMessage = '';
  const isConfirmStep = useMemo(() => currentStep === PayableInvoiceSteps.CONFIRM_STEP, [currentStep]);
  const isPayableInvoice = useMemo(() => invoiceType === InvoiceTypes.payable, [invoiceType]);
  const isScheduledInvoice = useMemo(() => invoiceState === InvoiceStates.scheduled, [invoiceState]);
  const isPlaidInvoice = useMemo(
    () => invoiceState === InvoiceStates.paid || invoiceState === InvoiceStates.markedPaid,
    [invoiceState],
  );

  return (
    <>
      <ShortEntityCard
        className="invoice-details-card"
        helpText={counterpartyDetails.handle ? `@${counterpartyDetails.handle}` : ''}
        message={
          <div className={styles['transaction-card__entity-info']}>
            <span className={styles['transaction-card__entity-name']}>{counterpartyDetails.dbaName}</span>
            <VerificationStar
              size={20}
              isVerified={counterpartyDetails.verificationStatus === VerificationStatus.VERIFIED}
              className={styles['transaction-card__entity-verification-label']}
            />
          </div>
        }
        logo={getInvoiceCompanyLogo(
          isPayableInvoice,
          counterpartyDetails.logoImage,
          isScheduledInvoice,
          'transaction-card__logo',
          tooltipMessage,
          isConfirmStep,
        )}
        additionalInfo={
          <div className={styles['transaction-card-header']}>
            <p className={styles['transaction-card-header__amount']}>
              {`${isPayableInvoice ? '-' : '+'}${formatAmountToLocal(totalAmount)}`}
            </p>
            <InvoiceStatus data={data} />
          </div>
        }
      />
      <TransactionCardField label={t('invoiceDetails.message')} value={message.slice(0, MAX_MESSAGE_LEGTH)} />
      <div className={styles['transaction-card__row-wrapper']}>
        <TransactionCardField label={t('invoiceDetails.invoiceNumber')} value={invoiceNumber} />
        {payDate && isPlaidInvoice && <TransferDateField value={payDate} />}
      </div>
      {children}
    </>
  );
};

export default InvoiceCard;
