// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// components
import VerificationForm from 'components/signUpForms/VerificationForm/VerificationForm';
import EntryTitle from 'components/EntryTitle/EntryTitle';
// helpers
import { VerificationFormData } from 'components/signUpForms/useSignUpForm';
// styles
import styles from 'router/views/SignUp/RegistrationFlow/Verification/Verification.module.scss';

interface IVerificationProps {
  email: string;
  submitVerificationForm: (data: VerificationFormData) => void;
  handleEmailChange: () => void;
  verificationInputLabel?: string;
}

const Verification: React.FC<IVerificationProps> = ({
  email,
  handleEmailChange,
  submitVerificationForm,
  verificationInputLabel,
}: IVerificationProps) => {
  const { t } = useTranslation('signUpPage');

  const handleCodeResend = useCallback(() => {
    // TODO: clean up after integration; stub for testing
    console.log('Resend code');
  }, []);

  return (
    <>
      <EntryTitle title={t('verificationForm.title')} subtitle={t('verificationForm.subtitle')} email={`${email}.`} />
      <VerificationForm
        submitVerificationForm={submitVerificationForm}
        verificationInputLabel={verificationInputLabel}
      />
      <div className={styles.notes__container}>
        <p className={styles.notes}>{t('verificationForm.notes.noCode')}</p>
        <p className={styles.notes}>
          {t('verificationForm.notes.stillNoCode')}&nbsp;
          <button onClick={handleEmailChange} className={styles.actions}>
            {t('verificationForm.actions.changeEmail')}
          </button>
          &nbsp;
          {t('verificationForm.notes.or')}&nbsp;
          <button onClick={handleCodeResend} className={styles.actions}>
            {t('verificationForm.actions.resendCode')}
          </button>
        </p>
      </div>
    </>
  );
};

export default Verification;
