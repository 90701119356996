let mockBusinessLegalDetails = {
  legalName: 'Hooli HQ',
  address: '10th Avenue, New York, NY',
  taxId: '',
  idNumber: '',
};

export const getMockBusinessLegalDetails = () => {
  return mockBusinessLegalDetails;
};

export const updateMockBusinessLegalDetails = data => {
  const { legalName, address, taxId, idNumber } = data;
  const updateData = { legalName, address, taxId, idNumber };
  mockBusinessLegalDetails = { ...mockBusinessLegalDetails, ...updateData };
};
