// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Collapse from 'components/Collapse/Collapse';
import InvoiceHistoryItem from 'components/invoices/InvoiceHistory/InvoiceHistoryItem/InvoiceHistoryItem';
// helpers
import { Invoice } from 'components/invoices/types';
import { FilterTabs } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
// styles
import './InvoiceHistory.scss';

interface InvoiceHistoryProps {
  title?: string;
  data: Invoice[];
  maxItemNumber?: number;
  emptyMessage: React.ReactElement;
  className?: string;
}

const InvoiceHistory: React.FC<InvoiceHistoryProps> = ({
  data,
  title,
  maxItemNumber = 5,
  emptyMessage,
  className,
}: InvoiceHistoryProps) => {
  const { t } = useTranslation('transactionHistory');

  return (
    <Collapse
      idSource="invoiceId"
      className={classNames('invoice-history', className)}
      title={title || t('homepageHistory')}
      linkForViewAllBtn={`/accounting?type=${FilterTabs.COMPLETE}`}
      data={data}
      maxItemNumber={maxItemNumber}
      mapItemFunc={invoiceProps => <InvoiceHistoryItem {...invoiceProps} />}
      emptyMessage={emptyMessage}
    />
  );
};

export default InvoiceHistory;
