import { makeAutoObservable } from 'mobx';
import RootStore from 'shared/stores/RootStore/RootStore';
import { CLEAR_STORE } from '../../constants/funcNamesInStore';

export enum ShortListType {
  topClients = 'topClients',
  shortList = 'shortList',
  recentPayments = 'recentPayments',
}

class ShortListsStore {
  shortListType: ShortListType = ShortListType.topClients;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setShortList = (type: ShortListType): void => {
    this.shortListType = type;
  };
}

export default ShortListsStore;
