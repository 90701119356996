import React, { useMemo } from 'react';
import useFieldHelper from 'components/list/field/fieldHelper';
import { IFieldProps } from 'components/list/field';
import { useFieldContext } from 'components/list/field/FieldContext';
import { formatAmount } from 'shared/helpers/format';
import { InvoiceTypes, FundsTransferTypes } from 'components/invoices/types';

const AmountField = ({ source = '', label }: IFieldProps) => {
  const { data } = useFieldContext();
  const { getValue } = useFieldHelper({ source, label });
  const invoiceType = data.getValueBySource('invoiceType');
  const transferType = data.getValueBySource('transferType');
  const isTransferFunds = useMemo(() => invoiceType === InvoiceTypes.fundsTransfer, [invoiceType]);
  const isPayableInvoice = useMemo(
    () => (isTransferFunds ? transferType === FundsTransferTypes.transfer : invoiceType === InvoiceTypes.payable),
    [invoiceType, transferType, isTransferFunds],
  );

  return <>{formatAmount(+getValue(), isPayableInvoice)}</>;
};

export default AmountField;
