// libraries
import React from 'react';
// components
import SortButton from 'components/list/layout/TableHead/SortButton';
// styles
import styles from './TableHead.module.scss';

export interface ITableHeadProps {
  children: React.ReactElement | React.ReactElement[];
  button?: React.ReactElement | React.ReactElement[];
  gridTemplateColumns?: string;
}

const TableHead: React.FC<ITableHeadProps> = ({ children, button = [], gridTemplateColumns }: ITableHeadProps) => {
  return (
    <div className="data-grid-table__header" style={{ gridTemplateColumns }}>
      {React.Children.map(children, child => {
        const { source, label, isSortable, sortSource } = child.props;

        const content = isSortable ? (
          <SortButton source={sortSource || source} label={label} />
        ) : (
          <span className={styles['table-header__cell']}>{label}</span>
        );

        return (
          <div className={styles['table-header__wrapper']} key={label || source}>
            {content}
          </div>
        );
      })}
      {React.Children.map(button, () => (
        <div className="th-buttons" />
      ))}
    </div>
  );
};

export default TableHead;
