// libraries
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import { TextInput } from 'components/Form/inputs';
import TaxIdFields from 'components/Form/inputGroups/TaxIdFields/TaxIdFields';
import AutocompleteInput from 'components/Form/inputs/AutocompleteInput/AutocompleteInput';
import BeneficiaryOwnersField from 'components/settings/AccountTabForm/BusinessInformationFields/BeneficiaryOwnersField/BeneficiaryOwnersField';
// helpers
import { useFormContext } from 'components/Form/FormContext';
import { AccountTabFormFields, BusinessInformationDataProps } from 'components/settings/AccountTabForm/types';
// styles
import styles from 'components/settings/AccountTabForm/AccountTabForm.module.scss';
import businessInformationFieldsStyles from './BusinessInformationFields.module.scss';

type BusinessInformationFieldsProps = {
  data: BusinessInformationDataProps;
  handleOnValueChange: () => void;
};

const BusinessInformationFields: React.FC<BusinessInformationFieldsProps> = ({
  data,
  handleOnValueChange,
}: BusinessInformationFieldsProps) => {
  const { t } = useTranslation('settingsPage');
  const { setFieldValues = () => {} } = useFormContext();
  const { legalName, address, taxId, idNumber } = data;
  const fieldWrapperClassName = classNames(businessInformationFieldsStyles.field__wrapper, styles.field__wrapper);
  const idNumberFieldWrapperClassName = classNames(
    fieldWrapperClassName,
    businessInformationFieldsStyles['id-number-field__wrapper'],
  );

  useEffect(() => {
    setFieldValues(AccountTabFormFields.legalName, legalName);
    setFieldValues(AccountTabFormFields.idNumber, idNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TextInput
        name={AccountTabFormFields.legalName}
        label={t('businessInformationBlock.fields.legalName.label')}
        placeholder={t('businessInformationBlock.fields.legalName.placeholder')}
        fieldLabelClassName={styles['field-label']}
        fieldWrapperClassName={fieldWrapperClassName}
        inputWrapperClassName={styles.input__wrapper}
        additionalOnChangeCallBack={handleOnValueChange}
      />
      <AutocompleteInput
        name={AccountTabFormFields.address}
        label={t('businessInformationBlock.fields.address.label')}
        placeholder={t('businessInformationBlock.fields.address.placeholder')}
        namePrefix=""
        errorLabel={t('businessInformationBlock.fields.address.label')}
        fieldLabelClassName={styles['field-label']}
        fieldWrapperClassName={fieldWrapperClassName}
        inputWrapperClassName={styles.input__wrapper}
        predifinedValue={address}
        additionalOnChangeCallBack={handleOnValueChange}
      />
      <TaxIdFields
        taxIdFieldLabelClassName={styles['field-label']}
        taxIdFieldWrapperClassName={fieldWrapperClassName}
        taxIdInputWrapperClassName={styles.input__wrapper}
        defaultTaxIdValue={taxId}
        idNumberFieldLabelClassName={styles['field-label']}
        idNumberFieldWrapperClassName={idNumberFieldWrapperClassName}
        idNumberInputWrapperClassName={styles.input__wrapper}
        onChangeCallback={handleOnValueChange}
      />
      <BeneficiaryOwnersField />
    </div>
  );
};

export default BusinessInformationFields;
