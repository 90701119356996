// libraries
import React, { useMemo } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import { useTranslation } from 'react-i18next';
// assets
import { ReactComponent as ErrorIcon } from 'assets/icons/error-toast-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-toast-icon.svg';
import { ReactComponent as WarnIcon } from 'assets/icons/warn-toast-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-toast-icon.svg';
// styles
import './ToastMessage.scss';

type ToastMessageProps = {
  title?: string;
  message: string | React.ReactElement;
  type: TypeOptions;
};

const ToastMessage: React.FC<ToastMessageProps> = ({ title, message, type }: ToastMessageProps) => {
  const { t } = useTranslation('notifications');

  const Icon = useMemo(() => {
    if (type === toast.TYPE.ERROR) {
      return ErrorIcon;
    }

    if (type === toast.TYPE.WARNING) {
      return WarnIcon;
    }

    if (type === toast.TYPE.SUCCESS) {
      return SuccessIcon;
    }

    return InfoIcon;
  }, [type]);

  return (
    <>
      <Icon className="toast__icon" />
      <p className="toast__title">{title || t(`toast.${type}`)}</p>
      <p className="toast__message">{message}</p>
    </>
  );
};

export default ToastMessage;
