import React from 'react';
// styles
import styles from './SettingFormWrapper.module.scss';

type SettingFormWrapperProps = {
  children: React.ReactNode;
};

const SettingFormWrapper: React.FC<SettingFormWrapperProps> = ({ children }: SettingFormWrapperProps) => {
  return (
    <div className={styles['settings-form__wrapper']}>
      <div className={styles['settings-form__content']}>{children}</div>
    </div>
  );
};

export default SettingFormWrapper;
