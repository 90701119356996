// libs
import { useEffect, useCallback } from 'react';
import shortid from 'shortid';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import { FileName, FilePath } from 'shared/constants/fileupload';
// helpers
import { getFilePath } from 'components/ImageCropper/cropperHelpers';
import useFileUpload, { FileUploadData, FileUploadProps } from '../FileUpload/useFileUpload';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

const useImageCrop = (props?: FileUploadProps): FileUploadData => {
  const { path = FilePath.default, fileName = FileName.default } = props || {};

  const {
    imageCropperStore: { setImage, croppedImage, handleSavingInForm },
    modalStore: { openModal, closeModal },
  } = useStore();

  const addCustomFile = useCallback(
    async (file: File) => {
      try {
        const filePath = await getFilePath(file);

        setImage({
          file,
          fullFilePath: filePath as string,
        });

        openModal(ModalIds.imageCropper);
      } catch (e) {
        throw new Error(e.message);
      }
    },
    [openModal, setImage],
  );

  const {
    addFiles,
    validateSingle,
    fieldName,
    allowedFileTypes,
    error,
    files,
    removeFile,
    handleSetFiles,
  } = useFileUpload(props, addCustomFile);

  const setImageToForm = useCallback(
    newImage => {
      const key = shortid.generate();

      const isFileValid = validateSingle(newImage);

      if (!isFileValid) return;

      const newFile = {
        file: newImage,
        key,
        path,
        name: fileName,
      };
      handleSetFiles([newFile]);

      closeModal(ModalIds.imageCropper);
      handleSavingInForm();
    },
    [path, closeModal, handleSavingInForm, validateSingle, handleSetFiles, fileName],
  );

  useEffect(() => {
    if (!croppedImage) return;

    setImageToForm(croppedImage);
  }, [croppedImage, setImageToForm]);

  return {
    addFiles,
    files,
    removeFile,
    error,
    allowedFileTypes,
    fieldName,
    handleSetFiles,
    validateSingle,
  };
};

export default useImageCrop;
