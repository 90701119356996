export enum TransactionTransferType {
  RECEIVABLE = 'receivable',
  PAYABLE = 'payable',
  COMPLETE = 'complete',
}

export enum TransactionType {
  FUNDS = 'funds',
  PAYOUT = 'payout',
  PAYMENT = 'payment',
  REFUND = 'refund',
}

export enum TransactionStatus {
  INITIATED = 'initiated',
  PENDING = 'pending',
  PROCESSED_BY_BANK = 'processed_by_bank',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
  PROCESSING_ERROR = 'processing_error',
  MANUAL_COMPLETED = 'manual_completed',
  HOLD = 'hold',
}

export const FAILED_TRANSACTION_STATUSES = [
  TransactionStatus.REJECTED,
  TransactionStatus.FAILED,
  TransactionStatus.CANCELED,
];
