// libraries
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
// helpers
import { addErrorToast } from 'components/Toast/toastHelper';
import useRestrictedFiles, { RestrictedFile } from 'components/RestrictedFiles/useRestrictedFiles';

type RestrictedFileRoutesParams = {
  downloadFileFromExternalLink: (filePath: string) => void;
  fileUrl: Nullable<string>;
  isLoading: boolean;
};

const useRestrictedFileRoutes = (): RestrictedFileRoutesParams => {
  const { pathname } = useLocation();
  const [fileUrl, setFileUrl] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { downloadFile } = useRestrictedFiles();

  const downloadFileFromExternalLink = useCallback(
    async (filePath: string) => {
      setIsLoading(true);

      try {
        const file = (await downloadFile(filePath)) as RestrictedFile;

        setFileUrl(file.url);
      } catch (e) {
        const { message } = await e;
        // addErrorToast(message);
      } finally {
        setIsLoading(false);
      }
    },
    [downloadFile],
  );

  useEffect(() => {
    downloadFileFromExternalLink(pathname);
  }, [pathname, downloadFileFromExternalLink]);

  return {
    downloadFileFromExternalLink,
    fileUrl,
    isLoading,
  };
};

export default useRestrictedFileRoutes;
