// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Button from 'components/Button';
// helpers
import { PaymentTabs } from '../Tabs';
// styles
import styles from './Tab.module.scss';

type TTabProps = {
  isActive?: boolean;
  changeTab: (tagName: PaymentTabs) => void;
  children: PaymentTabs;
};

const Tab: React.FC<TTabProps> = ({ isActive, changeTab, children }: TTabProps) => {
  const { t } = useTranslation('externalPaymentPage');
  const tabClassName = classNames(styles.tabs__item, { [styles['tabs__item--active']]: isActive });

  const onClick = useCallback(() => {
    changeTab(children);
  }, [changeTab, children]);

  return (
    <Button className={tabClassName} handleClick={onClick}>
      {t(`tabs.${children}`)}
    </Button>
  );
};

export default Tab;
