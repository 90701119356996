// libraries
import { useCallback, useState } from 'react';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import { getCroppedImg, getImageForDatabase } from 'components/ImageCropper/cropperHelpers';
import { addErrorToast } from 'components/Toast/toastHelper';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

export type ImageForCrop = {
  file: File;
  fullFilePath: string;
};

export type CropArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Crop = {
  x: number;
  y: number;
};

type ImageCropperParams = {
  handleCloseCropper: () => void;
  crop: Crop;
  setCrop: (crop: Crop) => void;
  onCropComplete: (croppedArea: CropArea, areaPixels: CropArea) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  image: Nullable<ImageForCrop>;
  showCroppedImage: () => void;
};

const INITIAL_ZOOM = 1;
const INITIAL_CROP = { x: 0, y: 0 };

const useImageCropper = (): ImageCropperParams => {
  const [crop, setCrop] = useState<Crop>(INITIAL_CROP);
  const [zoom, setZoom] = useState<number>(INITIAL_ZOOM);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Nullable<CropArea>>(null);

  const {
    modalStore: { closeModal },
    imageCropperStore: { setImage, image, setCroppedImage, setImageForPreview },
  } = useStore();

  const handleCloseCropper = useCallback(() => {
    closeModal(ModalIds.imageCropper);
    setImage(null);
    setCroppedImage(null);
  }, [closeModal, setImage, setCroppedImage]);

  const onCropComplete = useCallback((croppedArea: CropArea, areaPixels: CropArea) => {
    setCroppedAreaPixels(areaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (!image) {
      return;
    }

    try {
      const newCroppedImage = await getCroppedImg(image.fullFilePath, croppedAreaPixels, image.file.type);
      const imageForForm = getImageForDatabase(newCroppedImage.file, image.file.name, image.file.type);

      setImageForPreview(newCroppedImage.url);
      setCroppedImage(imageForForm);
      closeModal(ModalIds.imageCropper);
      setCrop(INITIAL_CROP);
      setZoom(INITIAL_ZOOM);
    } catch (e) {
      const { message } = await e;
      // addErrorToast(message);
    }
  }, [image, croppedAreaPixels, closeModal, setCroppedImage, setImageForPreview]);

  return {
    handleCloseCropper,
    crop,
    setCrop,
    onCropComplete,
    zoom,
    setZoom,
    image,
    showCroppedImage,
  };
};

export default useImageCropper;
