// libraries
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import SubmitButton from 'components/Form/buttons/SubmitButton';
import { NumberInput } from 'components/Form/inputs';
import Form from 'components/Form';
// constants
import { TransactionType } from 'shared/constants/transactions';
// helpers
import useTransferFunds from 'components/accounting/TransferFunds/useTransferFunds';
import TRANSFER_FUNDS_FORM_SCHEMA from 'components/accounting/TransferFunds/validation-rules';
// constants
import styles from './AddAmount.module.scss';

type AddAmountProps = {
  paymentMethodId?: number;
  onSubmit: () => void;
};

const AddAmount: React.FC<AddAmountProps> = ({ paymentMethodId, onSubmit }: AddAmountProps) => {
  const { t } = useTranslation('notifications');
  const { isFormSubmitted, submitForm } = useTransferFunds();

  useEffect(() => {
    if (isFormSubmitted) {
      onSubmit();
    }
  }, [isFormSubmitted, onSubmit]);

  return (
    <div className={classNames(styles.wrapper)}>
      <h2 className={styles.title}>{t('popups.addAmountPopupTitle')}</h2>
      <Form
        data={{ paymentMethodId: String(paymentMethodId) }}
        submitForm={data => submitForm(data, TransactionType.FUNDS)}
        validationSchema={TRANSFER_FUNDS_FORM_SCHEMA}
      >
        <NumberInput
          name="amount"
          className={styles['amount-input']}
          fieldWrapperClassName={styles['amount-input__wrapper']}
          prefix="$"
        />
        <SubmitButton className={styles.button}>{t('popups.addAmountButton')}</SubmitButton>
      </Form>
    </div>
  );
};

export default observer(AddAmount);
