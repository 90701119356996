import { useEffect } from 'react';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import { useStore } from 'shared/stores/RootStore/useStore';
import { getMockCompanies } from './CompanySearch/mockData';

export interface CompaniesItemsData {
  companiesData: IBusinessSnapshot[];
}

const useCompanyItems = (): CompaniesItemsData => {
  const {
    companiesStore: { companiesData, setCompaniesData },
  } = useStore();

  useEffect(() => {
    // TODO: need remove mock data after integration and add getting data from BE if store data is empty
    // @ts-ignore
    if (!companiesData.length) setCompaniesData(getMockCompanies());
  }, []);

  return { companiesData };
};

export default useCompanyItems;
