// libraries
import React, { useMemo } from 'react';
// components
import HighlightText from 'components/company/SearchBar/CompanyCard/HighlightText/HighlightText';
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
// helpers
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
// styles
import styles from './CompanyCard.module.scss';

type CompanyCardProps = {
  company: Partial<IBusinessSnapshot>;
  onClickCallback: () => void;
  value: string;
  additionalInfo?: React.ReactNode;
};

const CompanyCard: React.FC<CompanyCardProps> = ({
  company,
  onClickCallback,
  value,
  additionalInfo,
}: CompanyCardProps) => {
  const { id, logoImage, dbaName, industry = '', locationCity = '' } = company;

  const AdditionalInfo = useMemo(() => <div className={styles.company__link}>{additionalInfo}</div>, [additionalInfo]);

  const helpText = () => {
    if (!industry || !locationCity) {
      return industry || locationCity;
    }

    return `${industry} • ${locationCity}`;
  };

  return (
    <ShortEntityCard
      key={id}
      className={styles.company}
      logo={<EntityLogo logoSrc={logoImage} className={styles.company__avatar} />}
      message={
        <HighlightText value={value} id={id}>
          {dbaName}
        </HighlightText>
      }
      messageClassName={styles.company__name}
      helpText={helpText()}
      helpTextClassName={styles.company__additional}
      additionalInfo={AdditionalInfo}
      infoWrapperClassName={styles['company-info__wrapper']}
      onClickCallback={onClickCallback}
    />
  );
};

export default CompanyCard;
