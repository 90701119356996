import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import PublicLayout from 'shared/layouts/public/index';
import TwoBlocksLayout from 'shared/layouts/public/TwoBlockLayout/TwoBlockLayout';
import LoginForm from 'components/LoginForm/index';
import EntryTitle from 'components/EntryTitle/EntryTitle';
// assets
import layoutImage from 'assets/images/layout-image.jpg';

const Login: React.FC = () => {
  const { t } = useTranslation('loginPage');

  return (
    <PublicLayout checkToken>
      <TwoBlocksLayout imageAlt="Login" imageSrc={layoutImage}>
        <EntryTitle link="/sign-up" linkText={t('signUpLink')} subtitle={t('signUpText')} title={t('title')} />
        <LoginForm />
      </TwoBlocksLayout>
    </PublicLayout>
  );
};

export default Login;
