// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// components
import PaymentActionMenu from '../PaymentActionMenu/PaymentActionMenu';
import AccountDetails from '../AccountDetails/AccountDetails';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
// helpers
import useConnectedAccounts from './useSettingsConnectedAccount';
// styles
import styles from './SettingsPaymentMethods.module.scss';

const ConnectedAccountsList: React.FC = () => {
  const { accounts, paymentMethodDeleteConfirm, setDepositCallback, setFundingCallback } = useConnectedAccounts();

  return (
    <div>
      {accounts.map(item => (
        <div key={item.id} className={styles['payment-method__item']}>
          <AccountDetails account={item} />

          <PaymentActionMenu
            account={item}
            deleteAccountCallback={paymentMethodDeleteConfirm}
            setFundingCallback={setFundingCallback}
            setDepositCallback={setDepositCallback}
          />
        </div>
      ))}

      <ConfirmationModal customId={ModalIds.confirmDeletePaymentMethod} />
    </div>
  );
};

export default observer(ConnectedAccountsList);
