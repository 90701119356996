// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// components
import HeaderWithoutControls from 'shared/layouts/public/HeaderWithoutControls/HeaderWithoutControls';
import Footer from 'shared/layouts/public/Footer/Footer';
// styles
import styles from './NotFound.module.scss';
import buttonStyles from 'components/Button/Button.module.scss';

const NotFound: React.FC = () => {
  const { t } = useTranslation('errorPages');

  return (
    <div className={styles['not-found']}>
      <HeaderWithoutControls />
      <main className={styles['not-found__main']}>
        <Container className={styles['not-found__wrapper']}>
          <div className={styles['not-found__title']}>{t('notFound.title')}</div>
          <div className={styles['not-found__subtitle']}>{t('notFound.subtitle')}</div>
          <Link className={classNames(buttonStyles.btn, buttonStyles['btn--outline'])} to="/">
            {t('notFound.homeLink')}
          </Link>
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default NotFound;
