// libraries
import React, { memo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import { ReactComponent as LogoMobile } from 'assets/icons/logo-mobile.svg';
import { ReactComponent as LogoDesktop } from 'assets/icons/logo-desktop.svg';
import MainNav from 'components/MainNav/MainNav';
import SendInvoiceButton from 'components/Button/LinkButton';
import { ButtonColor } from 'components/Button';
// helpers
import useWindowSize from 'shared/helpers/useWindowSize';
// assets
import { ReactComponent as RocketIcon } from 'assets/icons/rocket-icon.svg';
// styles
import styles from './Header.module.scss';

const mobileSize = 576;

const Header: React.FC = () => {
  const { t } = useTranslation('navigation');
  const { width } = useWindowSize();

  const isMobile = width && width < mobileSize;
  const LogoIcon = isMobile ? LogoMobile : LogoDesktop;

  return (
    <header className={styles.header}>
      <div className="layout__container mx-auto">
        <div className={classnames(styles.header__wrapper, 'layout__container-wrapper')}>
          <Link to="/">
            <LogoIcon className={styles.header__logo} />
          </Link>
          <MainNav />
          <SendInvoiceButton
            color={ButtonColor.primary}
            url="/companies"
            icon={<RocketIcon />}
            className={styles['send-invoice-btn']}
          >
            {t(`main.Send Invoice`)}
          </SendInvoiceButton>
        </div>
      </div>
    </header>
  );
};

export default memo(Header);
