// libraries
import React from 'react';
// components
import { ReactComponent as AddIcon } from 'assets/icons/add-owner.svg';
import Button from 'components/Button';
// styles
import styles from './AddButton.module.scss';

type AddButtonProps = {
  handleClick: () => void;
  label: string;
};

const AddButton: React.FC<AddButtonProps> = ({ label, handleClick }: AddButtonProps) => (
  <Button className={styles['add-button']} handleClick={handleClick}>
    <span className={styles['add-icon-wrapper']}>
      <AddIcon className={styles['add-icon']} />
    </span>

    <span>{label}</span>
  </Button>
);

export default AddButton;
