import * as Yup from 'yup';
import {
  getLatinNameSchema,
  getLatinNameSchemaWithNumberAndApostrophe,
  getZipCodeNameSchema,
  getLatinNameWithNumberSchema,
  getPostalCodeSchema,
  getPhoneSchema,
} from 'shared/helpers/validationSchemes';
import { formatISO } from 'date-fns';
import { USA_COUNTRY } from 'shared/helpers/countries';
import { fileRestrictions } from 'shared/constants/fileupload';

const ADDRESS = Yup.object().shape({
  country: Yup.string().required('forms.address.country.required'),
  line1: getLatinNameSchemaWithNumberAndApostrophe().required('forms.address.line1.required'),
  line2: getLatinNameSchemaWithNumberAndApostrophe(),
  city: getLatinNameSchema().required('forms.address.city.required'),
  state: Yup.string().when('country', {
    is: USA_COUNTRY,
    then: (schema: Yup.ObjectSchema) => schema.required('forms.address.state.required'),
    otherwise: (schema: Yup.ObjectSchema) => schema.notRequired(),
  }),
  zip: Yup.string().when('country', {
    is: USA_COUNTRY,
    then: () => getZipCodeNameSchema().required('forms.address.zip.required'),
    otherwise: (schema: Yup.ObjectSchema) => schema.notRequired(),
  }),
  region: Yup.string().when('country', {
    is: USA_COUNTRY,
    then: (schema: Yup.ObjectSchema) => schema.notRequired(),
    otherwise: () => getLatinNameWithNumberSchema().required('forms.address.region.required'),
  }),
  postalCode: Yup.string().when('country', {
    is: USA_COUNTRY,
    then: (schema: Yup.ObjectSchema) => schema.notRequired(),
    otherwise: () => getPostalCodeSchema().required('forms.address.postalCode.required'),
  }),
  phone: getPhoneSchema().required('forms.phone.required'),
});

export default Yup.object().shape({
  legalName: getLatinNameSchema().required('forms.legalName.required'),
  subject: getLatinNameSchema().required('forms.subject.required'),
  amount: Yup.number().required('forms.amount.required').moreThan(0, 'forms.amount.enterValidAmount'),
  dueDate: Yup.date()
    .min(formatISO(new Date(), { representation: 'date' }), 'forms.dueDate.minDate')
    .required('forms.dueDate.required'),
  fileupload: Yup.object().shape({
    files: Yup.array().max(fileRestrictions.maxFiles, 'forms.files.max'),
  }),
  address: ADDRESS,
});
