import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// components
import PayableReceivableContent from '../PayableReceivableContent';
// styles
import styles from './Receivable.module.scss';
// store
import { useStore } from 'shared/stores/RootStore/useStore';
// constants
import { FilterTabs } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';

const Receivable: React.FC = () => {
  const { t } = useTranslation('payableReceivable');
  const history = useHistory();

  const {
    eventReceivableStore: { receivableData },
  } = useStore();

  const handleClick = useCallback(() => {
    history.push(`/accounting?type=${FilterTabs.RECEIVABLE}`);
  }, [history]);

  return (
    <PayableReceivableContent
      wrapperClassName={styles['receivable-container']}
      textTitle={t('receivableTitle')}
      amount={receivableData.receivableAmount}
      aging={receivableData.pastDueReceivableAmount}
      handleClick={handleClick}
    />
  );
};

export default observer(Receivable);
