// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Verification from 'router/views/SignUp/RegistrationFlow/Verification/Verification';
import EmailChange from 'router/views/SignUp/RegistrationFlow/EmailChange/EmailChange';
import Email from './Email/Email';
import Payment from './Payment/Payment';
import TwoBlockLayout from 'shared/layouts/public/TwoBlockLayout/TwoBlockLayout';
import PublicLayout from 'shared/layouts/public';
import AppLoading from 'components/AppLoading/AppLoading';
// assets
import layoutImage from 'assets/images/layout-image.jpg';
// helpers
import useExternalPaymentForms, { ExternalPaymentSteps } from './useExternalPaymentForms';

const AccountPayable: React.FC = () => {
  const {
    currentStep,
    handleEmailChange,
    email,
    submitEmailForm,
    submitVerificationForm,
    submitEmailChangeForm,
    isLoading,
    invoiceDetails,
    setInvoiceDetails,
  } = useExternalPaymentForms();
  const { t } = useTranslation('externalPaymentPage');

  const getPublicStepForm = () => {
    let form = null;

    if (currentStep === ExternalPaymentSteps.EMAIL_CHANGE) {
      form = <EmailChange submitEmailChangeForm={submitEmailChangeForm} />;
    }

    if (currentStep === ExternalPaymentSteps.VERIFICATION) {
      form = (
        <Verification
          email={email}
          handleEmailChange={handleEmailChange}
          submitVerificationForm={submitVerificationForm}
          verificationInputLabel={t('verificationFieldLabel')}
        />
      );
    }

    return form || <Email submitEmailForm={submitEmailForm} />;
  };

  if (currentStep === ExternalPaymentSteps.PAYMENT_FORM) {
    return <Payment invoiceDetails={invoiceDetails} setInvoiceDetails={setInvoiceDetails} />;
  }

  return (
    <PublicLayout>
      {isLoading ? (
        <AppLoading />
      ) : (
        <TwoBlockLayout imageAlt="Sign-up" imageSrc={layoutImage}>
          {getPublicStepForm()}
        </TwoBlockLayout>
      )}
    </PublicLayout>
  );
};

export default AccountPayable;
