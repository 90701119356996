// libraries
import React, { useMemo } from 'react';
// components
import ContactsTable from 'components/contacts/ContactsTable';
// mock
import { getMockContacts } from 'components/contacts/mockData';
// constants
import ContactPages from 'shared/constants/contactPages';

const ContactList = () => {
  const contacts = useMemo(() => getMockContacts(), []);

  return <ContactsTable page={ContactPages.CONTACT_LIST} contacts={contacts} />;
};

export default ContactList;
