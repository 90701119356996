import React, { PropsWithChildren } from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const EmailConfirmedInfo: React.FC<PropsWithChildren<{ email: string }>> = ({ email }) => {
  const { t } = useTranslation('emailConfirmed');

  return (
    <p>
      {email}
      <br />
      {t('message')} <Link to="/login">{t('link')}</Link>
    </p>
  );
};

EmailConfirmedInfo.propTypes = {
  email: string.isRequired,
};

export default EmailConfirmedInfo;
