// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// components
import AccountIntegrationCard from './AccountIntegrationCard/AccountIntegrationCard';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import SettingBlockWrapper from 'components/settings/SettingsBlockWrapper/SettingsBlockWrapper';
// assets
import quickBooks from 'assets/images/quickBooks.svg';
// helpers
import useAccountIntegration from './useAccountIntegration';

const AccountIntegrationsForm: React.FC = () => {
  const { t } = useTranslation('settingAccountIntegrations');
  const { accounts, connectCallback, disconnectOpenModal } = useAccountIntegration();

  return (
    <SettingBlockWrapper titleBlock={t('titleSetting')}>
      {accounts.map(item => (
        <AccountIntegrationCard
          key={item.type}
          account={item}
          connectCallback={connectCallback}
          disconnectCallback={disconnectOpenModal}
          img={quickBooks}
        />
      ))}

      <ConfirmationModal customId={ModalIds.confirmDisconnectAccount} />
    </SettingBlockWrapper>
  );
};

export default observer(AccountIntegrationsForm);
