// libraries
import React from 'react';
import classnames from 'classnames';
// assets
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as DoneIcon } from 'assets/icons/doneLogo.svg';
import { ReactComponent as EditPen } from 'assets/icons/edit-pen.svg';
// styles
import styles from 'components/externalPayments/ExternalFormAction/ExternalFormAction.module.scss';

type TExternalFormAction = {
  isActive?: boolean;
  handleClick: () => void;
  disabled: boolean;
  activeValue?: string;
  inactiveValue: string;
  title: string;
  activeTitle?: string;
  activeActionTitle: string;
  actionTitle: string;
  actionIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  className?: string;
};

const ExternalFormAction: React.FC<TExternalFormAction> = ({
  isActive,
  handleClick,
  disabled,
  activeValue,
  inactiveValue,
  title,
  activeTitle,
  actionTitle,
  activeActionTitle,
  actionIcon: ActionIcon,
  className,
}: TExternalFormAction) => {
  if (isActive) {
    return (
      <div className={classnames(styles.field, className)}>
        <div>
          <div className={styles.field__label}>
            <div className={styles['field__label-text']}>{activeTitle || title}</div>
            <DoneIcon className={styles['icon-warning']} />
          </div>
          {activeValue && <div className={styles.field__value}>{activeValue}</div>}
        </div>
        <button className={styles.field__button} onClick={handleClick} disabled={disabled}>
          <EditPen className={styles['icon-edit-pen']} />
          {activeActionTitle}
        </button>
      </div>
    );
  }

  return (
    <div className={classnames(styles.button, className)}>
      <div>
        <div className={styles.button__info}>
          <p className={styles['button__info-message']}>{title}</p>
          <WarningIcon className={styles['icon-warning']} />
        </div>
        <p className={styles['button__help-text']}>{inactiveValue}</p>
      </div>
      <button className={styles['button__additional-info']} onClick={handleClick} disabled={disabled}>
        <ActionIcon className={styles['add-icon']} />
        {actionTitle}
      </button>
    </div>
  );
};

export default ExternalFormAction;
