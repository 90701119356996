// libraries
import { useCallback, useState } from 'react';
// helpers
import { FormData } from 'components/Form/FormContext';
import { addErrorToast } from '../../Toast/toastHelper';
// api
import IntegrationsApi from '../api/IntegrationsApi';

type DeleteIntegrationFormData = {
  integrationId: string;
};

type DeleteIntegrationData = {
  isFormSubmitted: boolean;
  isLoading: boolean;
  deleteIntegration: (data: FormData) => Promise<void>;
};

const useDeleteIntegration = (): DeleteIntegrationData => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteIntegration = useCallback(async (data?: FormData) => {
    setIsFormSubmitted(false);
    setIsLoading(true);
    try {
      await IntegrationsApi.delete((data as DeleteIntegrationFormData).integrationId);
      setIsFormSubmitted(true);
    } catch (e) {
      const { error } = await e;
      // addErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    deleteIntegration,
    isFormSubmitted,
    isLoading,
  };
};

export default useDeleteIntegration;
