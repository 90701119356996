import React, { PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Container } from 'react-bootstrap';
import HeaderWithoutControls from '../public/HeaderWithoutControls/HeaderWithoutControls';
import Footer from '../public/Footer/Footer';
import { useStore } from '../../stores/RootStore/useStore';
import publicStyles from '../public/styles.module.scss';
import styles from './styles.module.scss';
import config from '../../../config';
import useAuth from '../../../components/Auth/authHelper';

type ServiceMessageLayoutProps = PropsWithChildren<{
  checkToken?: boolean;
}>;

const ServiceMessageLayout: React.FC<ServiceMessageLayoutProps> = ({ children = null, checkToken = false }) => {
  const {
    authStore: { isAuthLoading, sessionId },
  } = useStore();
  const { isAdminLoggedIn } = useAuth();

  if (checkToken) {
    if (isAuthLoading) return null;

    if (sessionId) return <Redirect to="/" />;

    if (isAdminLoggedIn()) {
      window.location.href = `${config.adminPlatformUrl}`;
      return null;
    }
  }

  return (
    <div className={publicStyles['layout--public']}>
      <HeaderWithoutControls />
      <main className={styles.layout__main}>
        <Container className={styles.container}>{children}</Container>
      </main>
      <Footer />
    </div>
  );
};

export default observer(ServiceMessageLayout);
