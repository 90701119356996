import ProtectedRequest from '../protected/ProtectedRequest';
import PublicRequest from '../public/PublicRequest';
import AbstractRequest, { HttpMethod, RequestInitialParams } from '../AbstractRequest';
import { buildQueryString } from '../../helpers/api';
import { ResponsePromise } from '../AbstractRequestManager';

export type Entity = Dictionary<unknown>;
export type EntityId = string | number;

// Define list params interface when list is being implemented
export type ListParams = Dictionary<string | string[] | number | boolean | undefined>;

class Crud {
  readonly resourceUrl: string;

  public readonly isProtected: boolean;

  constructor(resourceUrl: string, isProtected = true) {
    this.resourceUrl = resourceUrl;
    this.isProtected = isProtected;
  }

  protected getRequest = (url: string, params?: RequestInitialParams): AbstractRequest => {
    if (this.isProtected) {
      return new ProtectedRequest(url, params);
    }

    return new PublicRequest(url, params);
  };

  getList = (params?: ListParams): ResponsePromise => {
    const queryString = params ? buildQueryString(params) : '';
    const listUri = `${this.resourceUrl}${queryString}`;

    return this.getRequest(listUri).send();
  };

  create = (data: Entity): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}`;
    const params = { body: { ...data }, method: HttpMethod.POST };

    return this.getRequest(resourceUri, params).send();
  };

  getSingle = (id: EntityId): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}/${id}`;

    return this.getRequest(resourceUri).send();
  };

  update = (id: EntityId, data: Entity): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}/${id}`;
    const params = { body: { ...data }, method: HttpMethod.PUT };

    return this.getRequest(resourceUri, params).send();
  };

  delete = (id: EntityId): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}/${id}`;
    const params = { method: HttpMethod.DELETE };

    return this.getRequest(resourceUri, params).send();
  };
}

export default Crud;
