// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// components
import EntryTitle from 'components/EntryTitle/EntryTitle';
import BankInformation from 'components/verification/Bank/BankInformation';
// styles
import styles from '../Verification.module.scss';

const BankDetails: React.FC = () => {
  const { t } = useTranslation('verification');

  return (
    <div className={styles['form-wrapper']}>
      <EntryTitle
        customClass={classnames(styles['verification-header'], styles['accounts-header'])}
        title={t('linkedAccounts.title')}
        subtitle={t('linkedAccounts.subtitle')}
      />
      <BankInformation />
    </div>
  );
};

export default BankDetails;
