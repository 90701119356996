// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
// styles
import styles from './CompanyDescription.module.scss';
// components
import InviteButton from 'components/InviteButton/InviteButton';
// assets
import { ReactComponent as Cosmonaut } from 'assets/images/cosmonaut.svg';

type TInvitationProps = {
  wrapperClassName?: string;
  invitationPhrase?: string;
  subtitle?: string;
  buttonTitle?: string;
  hasEditButton?: boolean;
  buttonClickHandler?: () => void;
};

const Invitation: React.FC<TInvitationProps> = ({
  invitationPhrase = '',
  wrapperClassName = '',
  subtitle = '',
  buttonTitle = '',
  hasEditButton = false,
  buttonClickHandler,
}: TInvitationProps) => {
  return (
    <div className={classNames(styles.invitation__wrapper, wrapperClassName)}>
      <Cosmonaut className={styles.invitation__image} />
      <div className={styles.invitation__right}>
        <div className={styles['invitation__text-block']}>
          <span className={styles.invitation__title}>{invitationPhrase}</span>
          <span>{subtitle}</span>
        </div>
        <InviteButton title={buttonTitle} isEditButton={hasEditButton} buttonClickHandler={buttonClickHandler} />
      </div>
    </div>
  );
};

export default observer(Invitation);
