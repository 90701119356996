// libraries
import React, { PropsWithChildren, useCallback, useEffect, useState, useMemo } from 'react';
import { Row } from 'react-bootstrap';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
// components
import LogoBig, { LogoColor } from '../LogoBig/LogoBig';
import CloseButton from './CloseButton/CloseButton';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import useCloseByOutsideAction from 'shared/helpers/useCloseByOutsideAction';
// RootStore
import { useStore } from '../../shared/stores/RootStore/useStore';
// styles
import styles from './Modal.module.scss';

export enum ModalType {
  full = 'full',
  minimal = 'minimal',
  base = 'base',
  confirmation = 'confirmation',
}

type ModalProps = PropsWithChildren<{
  id: string;
  type?: ModalType;
  isUnclosed?: boolean;
  handleCloseModal?: () => void;
  logoColor?: LogoColor;
  subtitleText?: string;
  shouldLogoBeRendered?: boolean;
  isInvoiceDetailsModal?: boolean;
  hasCloseButton?: boolean;
  modalClassName?: string;
  closeButtonClassName?: string;
}>;

const Modal: React.FC<ModalProps> = ({
  type = ModalType.full,
  children,
  handleCloseModal: handleCloseModalCallback,
  id,
  logoColor,
  subtitleText,
  isUnclosed = false,
  shouldLogoBeRendered = true,
  isInvoiceDetailsModal = false,
  hasCloseButton = true,
  modalClassName = '',
  closeButtonClassName = '',
}: ModalProps) => {
  const {
    modalStore: { closeAllModals, modalIds, isOpen, userOpenModal },
    formStore: { setIsFormDirty },
    authStore: { isFirstLogin },
  } = useStore();

  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const [isActiveModal, setActiveModal] = useState<boolean>(false);
  const [isFirstModal, setIsFirstModal] = useState<boolean>(false);
  const isConfirmationModal = useMemo(() => id === ModalIds.confirmationModal || id === ModalIds.inviteWarningPopup, [
    id,
  ]);

  const isAnimated = useMemo(() => isFirstModal && userOpenModal && modalIds.length === 1, [
    isFirstModal,
    userOpenModal,
    modalIds.length,
  ]);

  useEffect(() => {
    if (userOpenModal && modalIds.length === 1) {
      setIsFormDirty(false);
    }
  }, [userOpenModal, setIsFormDirty, modalIds.length]);

  const handleCloseModal = useCallback(() => {
    if (handleCloseModalCallback) {
      handleCloseModalCallback();
      return;
    }

    if (!isUnclosed) {
      setIsFormDirty(false);
      closeAllModals();
    }
  }, [handleCloseModalCallback, isUnclosed, setIsFormDirty, closeAllModals]);

  useCloseByOutsideAction(handleCloseModal);

  useEffect(() => {
    const modalCount = modalIds.length;
    let activeModalId = modalIds[modalCount - 1];

    for (let i = modalCount - 1; i >= 0; i -= 1) {
      if (modalIds[i] !== ModalIds.confirmationModal && modalIds[i] !== ModalIds.inviteWarningPopup) {
        activeModalId = modalIds[i];
        break;
      }
    }

    setModalOpened(isOpen(id));

    if (isFirstLogin) {
      setActiveModal(id !== ModalIds.firstLoginPopupNotification);
    } else {
      setActiveModal((modalCount && activeModalId !== id) as boolean);
    }
    setIsFirstModal(modalIds[0] === id);
  }, [modalIds, modalIds.length, isOpen, id, isFirstLogin]);

  const modalBodyClass = classNames(
    styles.modal__body,
    modalClassName,
    { [styles['modal__body--full']]: type === ModalType.full },
    { [styles['modal__body--minimal']]: type === ModalType.minimal },
    { [styles['modal__body--base']]: type === ModalType.base },
    { [styles['modal__body--confirmation']]: type === ModalType.confirmation },
  );

  const getModalBody = () => {
    if (type === ModalType.full) {
      return (
        <Row className={classNames('m-0', modalBodyClass)}>
          {shouldLogoBeRendered && (
            <div className={styles.modal__logo}>
              {logoColor && <LogoBig color={logoColor} subtitleText={subtitleText} />}
            </div>
          )}
          <div className={styles.modal__container}>{children}</div>
          {hasCloseButton && (
            <div>
              <CloseButton
                handleClick={handleCloseModal}
                className={classNames(closeButtonClassName, {
                  [styles['modal__form-close-button']]: !isConfirmationModal,
                })}
              />
            </div>
          )}
        </Row>
      );
    }

    return (
      <Row className={classNames('m-0', modalBodyClass)}>
        <div>{children}</div>
        {hasCloseButton && (
          <div>
            <CloseButton className={closeButtonClassName} handleClick={handleCloseModal} />
          </div>
        )}
      </Row>
    );
  };

  if (!isModalOpened) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(styles.modal__overlay, {
          [styles['modal--hidden']]: isActiveModal && !isConfirmationModal,
          [styles['modal--animated']]: isAnimated,
        })}
        onClick={handleCloseModal}
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
      />
      <div
        className={classNames(styles.modal, {
          [styles['modal-transaction-details']]: isInvoiceDetailsModal && !isConfirmationModal,
          [styles['modal--hidden']]: isActiveModal && !isConfirmationModal,
          [styles['modal--animated']]: isAnimated,
        })}
      >
        {getModalBody()}
      </div>
    </>
  );
};

export default observer(Modal);
