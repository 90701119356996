// libraries
import { useCallback, useEffect, useState } from 'react';
// helpers
import { Invoice, InvoiceStates, InvoiceTypes } from 'components/invoices/types';
import { completedStates } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';
import useInvoiceItems from 'components/invoices/useInvoiceItems';

export interface UseHomeInterface {
  invoices: Invoice[];
}

const useHome = (): UseHomeInterface => {
  const { invoiceItems } = useInvoiceItems();
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const getInvoiceHistory = useCallback(() => {
    setInvoices(
      (invoiceItems || []).filter(
        ({ invoiceState = InvoiceStates.new, invoiceType }: Invoice) =>
          completedStates.includes(invoiceState) && invoiceType !== InvoiceTypes.fundsTransfer,
      ),
    );
  }, [invoiceItems]);

  useEffect(() => {
    getInvoiceHistory();
  }, [getInvoiceHistory]);

  return {
    invoices,
  };
};

export default useHome;
