// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// styles
import styles from './AddNewBusiness.module.scss';

type AddNewBusinessProps = {
  newBusinessWrapperClassName?: string;
  value: string;
  onClickHandler?: () => void;
};

const AddNewBusiness: React.FC<AddNewBusinessProps> = ({
  newBusinessWrapperClassName,
  value,
  onClickHandler,
}: AddNewBusinessProps) => {
  const { t } = useTranslation('companySearch');

  return (
    <div
      className={classNames(styles['new-business__wrapper'], newBusinessWrapperClassName)}
      onClick={onClickHandler}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
    >
      <span className={styles['new-business__plus']} />
      <div className={styles['new-business__title']}>
        <span>{t('searchInput.add')}</span>
        <span className={styles['new-business__name']}>{value}</span>
        <span>{t('searchInput.newBusiness')}</span>
      </div>
    </div>
  );
};

export default AddNewBusiness;
