// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EmailChangeForm from 'components/resetPassword/EmailChangeForm/EmailChangeForm';
import EntryTitle from 'components/EntryTitle/EntryTitle';
// types
import { EmailChangeFormData } from 'components/resetPassword/usePasswordResetForm';
// styles
import styles from 'router/views/ResetPassword/index.module.scss';

interface IEmailChangeProps {
  submitEmailChangeForm: (data: EmailChangeFormData) => void;
}

const EmailChange: React.FC<IEmailChangeProps> = ({ submitEmailChangeForm }: IEmailChangeProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <>
      <EntryTitle
        title={t('emailChange.title')}
        subtitle={t('emailChange.subtitle')}
        customClass={styles['entry-title__wrapper']}
      />
      <EmailChangeForm submitEmailChangeForm={submitEmailChangeForm} />
    </>
  );
};

export default EmailChange;
