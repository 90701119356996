enum EndpointsToStores {
  Login = 'authStore',
  Logout = 'logoutStore',
  GetMyBusinessProfiles = 'myBusinessProfilesStore',
  Subscribe = 'subscribeBusinessStore',
  AccountsPayable = 'eventPayableStore',
  AccountsReceivable = 'eventReceivableStore',
  Balances = 'eventBalancesStore',
  GetBusinessSnapshot = 'myBusinessSnapshotStore',
}

export default EndpointsToStores;
