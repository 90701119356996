// libraries
import React from 'react';
import classNames from 'classnames';
// assets
import DefaultAvatar from 'assets/images/default-entity-logo.svg';
// styles
import styles from './Avatar.module.scss';

export enum AvatarType {
  small = 'small',
  big = 'big',
  medium = 'mediumS',
}

export type AvatarProps = Partial<{
  type: AvatarType;
  avatarUrl?: string;
  className: string;
  alt: string;
}>;

const Avatar: React.FC<AvatarProps> = ({ avatarUrl = '', type = AvatarType.small, alt, className }: AvatarProps) => {
  const imageClassName = classNames(
    styles.avatar__img,
    {
      [styles['avatar__img--big']]: type === AvatarType.big,
      [styles['avatar__img--medium-s']]: type === AvatarType.medium,
    },
    className,
  );

  return <img className={imageClassName} src={avatarUrl || DefaultAvatar} alt={alt || 'avatar'} />;
};

export default Avatar;
