import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.scss';

interface FooterProps {
  customClass?: string;
}

const Footer: React.FC<FooterProps> = ({ customClass = '' }: FooterProps) => {
  const { t } = useTranslation('loginPage');

  return (
    <footer className={classnames(styles.footer, { [customClass]: customClass })}>
      <Container className="layout__container p-0">
        <div className={classnames(styles.footer__wrapper, 'layout__container-wrapper')}>
          <Link to="/" className={styles.footer__link}>
            {t('privacyPolicyLink')}
          </Link>
          and
          <Link to="/" className={styles.footer__link}>
            {t('termsOfServiceLink')}
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default memo(Footer);
