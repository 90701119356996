// libraries
import React, { memo } from 'react';
import { Container } from 'react-bootstrap';
import i18next from 'i18next';
// styles
import styles from './Footer.module.scss';

type TFooter = {
  companyName?: string;
};

const Footer: React.FC<TFooter> = ({ companyName = '' }: TFooter) => (
  <footer className={styles.footer}>
    <Container className="layout__container p-0">
      <div className="layout__container-wrapper">{i18next.t('externalPaymentPage:footer', { companyName })}</div>
    </Container>
  </footer>
);

export default memo(Footer);
