import * as Yup from 'yup';

const getMaxLengthSchema = (length: number, message: string) => Yup.string().max(length, message);

const getNameOnCardSchema = () =>
  getMaxLengthSchema(60, 'forms.stripeForm.name.maxLength').matches(/^[a-zA-Z0-9\s-]+$/i, {
    message: 'forms.stripeForm.name.format',
  });

export default Yup.object().shape({
  firstName: getNameOnCardSchema().required('forms.firstName.required'),
  lastName: getNameOnCardSchema().required('forms.lastName.required'),
});
