// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
// components
import { ReactComponent as CirclePlusIcon } from 'assets/icons/plus-in-circle.svg';
import Button from 'components/Button';
import AddButton from 'components/Button/AddButton';
import AppLoading from 'components/AppLoading/AppLoading';
import BankAccount from 'components/verification/Bank/BankAccount';
import Scrollbar from 'components/Scrollbar/Scrollbar';
// helpers
import usePlaid from 'components/paymentMethods/PaymentMethod/PaymentOptions/usePlaid';
import useVerification from '../useVerification';
import useConnectedAccounts from 'components/paymentMethods/useConnectedAccounts';
// styles
import formStyles from 'components/verification/Business/Business.module.scss';
import styles from './BankInformation.module.scss';

const BankInformation: React.FC = () => {
  const { t } = useTranslation(['verification', 'forms']);

  const { openPlaidModal } = usePlaid();
  const { accounts, isLoading } = useConnectedAccounts();
  const { verifyCompany, isLoading: isVerificationLoading } = useVerification();

  const isContinueDisabled = !accounts.length || isVerificationLoading;

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <div className="public-form-wrapper">
      <div className={styles['linked-accounts-header']}>
        <span>
          <CirclePlusIcon />
        </span>
        <span className={styles.text}>{t('linkedAccounts.linkedAccounts')}</span>
      </div>
      {!!accounts.length && (
        <Scrollbar className={styles['accounts-container']}>
          {accounts.map(bank => (
            <BankAccount key={bank.id} bank={bank} />
          ))}
        </Scrollbar>
      )}

      <AddButton label={t('linkedAccounts.addAccount')} handleClick={() => openPlaidModal()} />

      <Button
        handleClick={verifyCompany}
        disabled={isContinueDisabled}
        className={classnames(formStyles.button, styles['continue-button'])}
      >
        {t('forms:continueButtonDefaultText')}
      </Button>
    </div>
  );
};

export default observer(BankInformation);
