// libraries
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { useListContext } from 'components/list/ListContext';
import { useFormContext } from 'components/Form/FormContext';
// components
import TextInput from 'components/Form/inputs/TextInput/TextInput';
import SubmitButton from 'components/Form/buttons/SubmitButton';
// styles
import styles from './SearchInput.module.scss';

interface ISearchInputFieldProps {
  label?: string | React.ReactElement;
  placeholder?: string;
  inputClassName?: string;
}

const SearchInputField: React.FC<ISearchInputFieldProps> = ({
  label,
  placeholder,
  inputClassName,
}: ISearchInputFieldProps) => {
  const { t } = useTranslation('forms');
  const { search = '' } = useListContext();

  const { resetForm = () => {} } = useFormContext();

  useEffect(() => {
    if (!search) {
      resetForm();
    }
  }, [search]);

  return (
    <>
      <TextInput
        label={label as string}
        placeholder={placeholder || t('searchPlaceholder')}
        name="search"
        fieldWrapperClassName={styles['list-search__input-wrapper']}
        className={classNames(styles['list-search__input'], inputClassName)}
      />
      <SubmitButton btnLink className={styles['list-search__button']}>
        {t('searchBtn')}
      </SubmitButton>
    </>
  );
};

export default SearchInputField;
