// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import UnlockAccountForm from 'components/resetPassword/UnlockAccountForm/UnlockAccountForm';
import EntryTitle from 'components/EntryTitle/EntryTitle';
// constants
import { UnlockAccountFormData } from 'components/resetPassword/usePasswordResetForm';
// styles
import unlockAccountStyles from './UnlockAccount.module.scss';
import styles from 'router/views/ResetPassword/index.module.scss';

interface IUnlockAccountProps {
  submitUnlockAccountForm: (data: UnlockAccountFormData, userEmail: string) => void;
  email: string;
}

const UnlockAccount: React.FC<IUnlockAccountProps> = ({ submitUnlockAccountForm, email }: IUnlockAccountProps) => {
  const { t } = useTranslation('passwordReset');

  return (
    <div className={unlockAccountStyles['unlock-account__form']}>
      <EntryTitle
        customClass={classNames(styles['entry-title__wrapper'], unlockAccountStyles['entry-title__wrapper'])}
        title={t('unlockAccount.title')}
        subtitle={t('unlockAccount.subTitle.checkEmail')}
        email={email}
        subtitleEnding={t('unlockAccount.subTitle.unlock')}
      />
      <UnlockAccountForm submitUnlockAccountForm={submitUnlockAccountForm} email={email} />
    </div>
  );
};

export default UnlockAccount;
