// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toJS } from 'mobx';
// components
import TransactionCardFooterActionItem from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooterActions/TransactionCardFooterActionItem';
// assets
import { ReactComponent as EditPenIcon } from 'assets/icons/edit-pen.svg';
// helpers
import { Invoice } from 'components/invoices/types';
import useCompanyDetails from 'components/company/CompanyDetails/useCompanyDetails';

interface IEditReceivableInvoice {
  invoiceDetails: Invoice;
}

const EditReceivableInvoice: React.FC<IEditReceivableInvoice> = ({ invoiceDetails }: IEditReceivableInvoice) => {
  const { t } = useTranslation('transactionHistory');
  const { companyProfile } = useCompanyDetails(invoiceDetails.counterpartyDetails?.id);
  const history = useHistory();

  const editInvoice = useCallback(() => {
    history.push('/create-invoice', {
      invoice: toJS(invoiceDetails),
      companyInfo: toJS(companyProfile),
    });
  }, [companyProfile, invoiceDetails]);

  return (
    <TransactionCardFooterActionItem onClick={editInvoice}>
      <EditPenIcon width={9} height={9} />
      {t('invoiceActions.edit')}
    </TransactionCardFooterActionItem>
  );
};

export default EditReceivableInvoice;
