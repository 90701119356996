// libraries
import { useCallback, useState, useEffect } from 'react';
// hooks
import useCompanies from 'components/company/useCompanies';
import { useFormContext } from 'components/Form/FormContext';
// helpers
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import { Contact } from 'components/contacts/types';

const LIST_SIZE_MAX = 7;

interface AddBillDataProps {
  businessValue: string;
  foundCompanies: Partial<IBusinessSnapshot>[];
  handleSelect: (
    companyName?: string,
    companyEmail?: string,
    contactName?: string,
    companyLogo?: string,
    companyTag?: string,
  ) => void;
  handleSearch: (query: string, isSearchOpen?: boolean) => void;
  isSearchOpen: boolean;
  isListScrollable: boolean;
  isNewVendor: boolean;
  isDisabledInput: boolean;
}

const useSearchBar = (contact: Contact): AddBillDataProps => {
  // TODO: change this logic during integration

  const [foundCompanies, setFoundCompanies] = useState<Partial<IBusinessSnapshot>[]>([]);
  const [isListScrollable, setIsScrollableList] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(contact.isExistingBusiness);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isNewVendor, setIsNewVendor] = useState(false);
  const [businessValue, setBusinessValue] = useState('');

  const { setFieldValues = () => {}, getFieldValue = () => {}, validateField = () => {} } = useFormContext();
  const { companiesData: storedCompanies } = useCompanies();

  const closeSearch = useCallback(
    (companyName: string) => {
      const selectedCompany = foundCompanies.find(
        company => company.dbaName?.toLowerCase() === companyName?.toLowerCase(),
      );

      if (selectedCompany) {
        if (selectedCompany.email && selectedCompany.contactName) {
          setIsDisabledInput(true);
        }

        setIsNewVendor(false);
        setFieldValues('businessName', selectedCompany.dbaName);
        setFieldValues('contactName', selectedCompany.contactName);
        setFieldValues('contactEmail', selectedCompany.email);
        setFieldValues('logo', selectedCompany.logoImage);
        setFieldValues('tag', selectedCompany.handle);
        setFieldValues('isExistingBusiness', true);

        // eslint-disable-next-line
        validateField('businessName', { ['businessName']: selectedCompany.dbaName });
        // eslint-disable-next-line
        validateField('contactName', { ['contactName']: selectedCompany.contactName });
        // eslint-disable-next-line
        validateField('contactEmail', { ['contactEmail']: selectedCompany.email });
      }

      if (!selectedCompany && companyName) {
        setIsNewVendor(true);
        setIsDisabledInput(false);
      }

      setIsSearchOpen(false);
    },
    [foundCompanies],
  );

  const filterCompanies = useCallback(
    async (queryString = '') => {
      if (!queryString) {
        setIsSearchOpen(false);
      }

      if (queryString) {
        const filteredCompanies = storedCompanies.filter(company =>
          company.dbaName.toLowerCase().includes(queryString.toLowerCase()),
        );

        setFoundCompanies(filteredCompanies);
        setIsScrollableList(filteredCompanies.length >= LIST_SIZE_MAX);

        return filteredCompanies;
      }

      setFoundCompanies(storedCompanies);
      setIsScrollableList(storedCompanies.length >= LIST_SIZE_MAX);

      return storedCompanies;
    },
    [storedCompanies],
  );

  const handleSelect = useCallback(
    (companyName?: string, companyEmail?: string, contactName?: string, companyLogo?: string, companyTag?: string) => {
      setFieldValues('businessName', companyName);
      setFieldValues('contactName', contactName);
      setFieldValues('contactEmail', companyEmail);
      setFieldValues('logo', companyLogo);
      setFieldValues('tag', companyTag);
      setFieldValues('isExistingBusiness', true);

      setIsSearchOpen(false);
    },
    [],
  );

  const handleSearch = useCallback((query: string, isActiveSearch?: boolean) => {
    if (!isActiveSearch) {
      setIsSearchOpen(false);

      return;
    }

    setBusinessValue(query);
    setIsSearchOpen(true);

    setFieldValues('tag', '');
    setFieldValues('isExistingBusiness', false);

    setIsNewVendor(false);
  }, []);

  useEffect(() => {
    if (!isSearchOpen) {
      closeSearch(getFieldValue('businessName'));

      return;
    }

    filterCompanies(businessValue);
  }, [businessValue, isSearchOpen]);

  return {
    businessValue,
    foundCompanies,
    handleSelect,
    handleSearch,
    isSearchOpen,
    isListScrollable,
    isNewVendor,
    isDisabledInput,
  };
};

export default useSearchBar;
