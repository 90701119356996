import Settings from '../views/Settings';
import Verification from '../views/Verification';
import Accounting from '../views/Accounting/Accounting';
import SendInvoice from '../views/SendInvoice/SendInvoice';
import ConfirmationPage from 'router/views/Verification/ConfirmationPage';
import CompanyProfile from '../views/CompanyProfile/public';
import EditProfile from 'router/views/EditProfile/EditProfile';
import CreateInvoice from '../views/CreateInvoice/CreateInvoice';
import AddExternalBill from 'router/views/AddExternalBill/AddExternalBill';
import Notifications from 'router/views/Notifications/Notifications';
import Contacts from 'router/views/Contacts';
import AddContact from 'router/views/Contacts/AddContact';
import EditContact from 'router/views/Contacts/EditContact';

export const VERIFICATION_CONFIRMATION_PATH = '/verification/completed';

export enum MainPageName {
  COMPANY_PUBLIC_PROFILE = 'Company public profile',
  SEND_INVOICE = 'Send Invoice',
  CREATE_INVOICE = 'Create Invoice',
  ACCOUNTING = 'Accounting',
  SETTINGS = 'Settings',
  CONTACTS = 'Contacts',
  VERIFICATION = 'Verification',
  CONFIRMATION = 'Completed verification',
  EDIT_PROFILE = 'Edit Profile',
  ADD_BILL = 'Add bill',
  NOTIFICATIONS = 'Notifications',
  ADD_CONTACT = 'Add Contact',
  EDIT_CONTACT = 'Edit Contact',
}

export default [
  {
    path: '/companies',
    component: SendInvoice,
    name: MainPageName.SEND_INVOICE,
    exact: true,
  },
  {
    path: '/companies/:id',
    component: SendInvoice,
    name: MainPageName.SEND_INVOICE,
    exact: true,
  },
  {
    path: '/profile',
    component: EditProfile,
    name: MainPageName.EDIT_PROFILE,
    exact: true,
  },
  {
    path: '/companies/:id/public-profile',
    component: CompanyProfile,
    isProtected: false,
    name: MainPageName.COMPANY_PUBLIC_PROFILE,
    exact: true,
  },
  {
    path: '/accounting',
    component: Accounting,
    name: MainPageName.ACCOUNTING,
    exact: true,
  },
  {
    path: '/billing',
    component: AddExternalBill,
    name: MainPageName.ADD_BILL,
    exact: true,
  },
  {
    path: '/settings',
    component: Settings,
    name: MainPageName.SETTINGS,
  },
  {
    path: '/contacts',
    component: Contacts,
    name: MainPageName.CONTACTS,
  },
  {
    path: '/add-contact',
    component: AddContact,
    name: MainPageName.ADD_CONTACT,
    exact: true,
  },
  {
    path: '/edit-contact/:id',
    component: EditContact,
    name: MainPageName.EDIT_CONTACT,
    exact: true,
  },
  {
    path: VERIFICATION_CONFIRMATION_PATH,
    component: ConfirmationPage,
    name: 'Completed verification',
    exact: true,
  },
  {
    path: '/verification',
    component: Verification,
    name: MainPageName.VERIFICATION,
  },
  {
    path: '/create-invoice',
    component: CreateInvoice,
    name: MainPageName.CREATE_INVOICE,
  },
  {
    path: '/notifications',
    component: Notifications,
    name: MainPageName.NOTIFICATIONS,
  },
];
