// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
// components
import Button from 'components/Button';
import AddButton from 'components/Button/AddButton';
import BeneficialOwner from 'components/beneficial/BeneficialOwner/BeneficialOwner';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// constants
import { VERIFICATION_FLOW, VerificationStep } from 'components/verification/verificationHelpers';
// helpers
import useBeneficialOwners, { BeneficialOwner as BeneficialOwnerType } from 'components/beneficial/useBeneficialOwners';
import { MAX_OWNERS_COUNT } from 'components/beneficial/beneficialOwnersHelpers';
// styles
import formStyles from 'components/verification/Business/Business.module.scss';
import styles from './SavedOwners.module.scss';

type SavedOwnersProps = {
  showContinueButton?: boolean;
  isRedirectRequired?: boolean;
};

const SavedOwners: React.FC<SavedOwnersProps> = ({
  showContinueButton = true,
  isRedirectRequired = true,
}: SavedOwnersProps) => {
  const { t } = useTranslation(['verification', 'forms']);

  const { openBeneficialOwnerModal } = useBeneficialOwners();
  const history = useHistory();
  const {
    beneficialOwnersStore: { beneficialOwnersCount, beneficialOwnersList },
    myBusinessSnapshotStore: { updateNextVerificationStep },
  } = useStore();

  const isAddVisible = beneficialOwnersCount < MAX_OWNERS_COUNT;

  const handleRedirect = (id = 0) => {
    if (isRedirectRequired) {
      history.push(VERIFICATION_FLOW.beneficialOwnersForm(id));
    } else {
      openBeneficialOwnerModal(id);
    }
  };

  const handleAddOwner = () => {
    handleRedirect();
  };

  const handleContinue = () => {
    updateNextVerificationStep(VerificationStep.BANK_ACCOUNTS);
    history.push(VERIFICATION_FLOW.linkAccount());
  };

  const handleEditOwner = (id: number) => {
    handleRedirect(id);
  };

  return (
    <>
      <div className={formStyles.form__fields}>
        {beneficialOwnersList.map((owner: BeneficialOwnerType) => (
          <BeneficialOwner key={owner.id} owner={owner} editHandler={() => handleEditOwner(owner.id)} />
        ))}
      </div>

      {isAddVisible && <AddButton label={t('beneficiaryOwners.addOwner')} handleClick={handleAddOwner} />}

      {showContinueButton && (
        <Button
          handleClick={handleContinue}
          disabled={!beneficialOwnersCount}
          className={classnames(formStyles.button, styles['continue-button'])}
        >
          {t('forms:continueButtonDefaultText')}
        </Button>
      )}
    </>
  );
};

export default observer(SavedOwners);
