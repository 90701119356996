// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/Button';
// assets
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
// styles
import styles from './TransactionCard.module.scss';

interface ITransactionCardNavigation {
  closeCard: () => void;
  mainBreadcrumbItemTitle: string;
}

const TransactionCardNavigation: React.FC<ITransactionCardNavigation> = ({
  closeCard,
  mainBreadcrumbItemTitle,
}: ITransactionCardNavigation) => {
  const { t } = useTranslation('transactionHistory');

  return (
    <div className={styles['transaction-card-header__wrapper']}>
      <div className={styles['transaction-card-header__breadcrumbs']}>
        <Button btnLink handleClick={closeCard} className={styles['transaction-card-header__breadcrumbs-item']}>
          {mainBreadcrumbItemTitle}
        </Button>
        <span className={styles['transaction-card-header__breadcrumbs-item']}>{t('invoiceDetailsTitle')}</span>
      </div>
      <Button handleClick={closeCard} btnLink className={styles['transaction-card-header__close-btn']}>
        <CloseIcon width={12} height={12} fill="#1d191F" />
      </Button>
    </div>
  );
};

export default TransactionCardNavigation;
