// libraries
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// components
import BaseLayout from 'shared/layouts/basic';
import AccountingTools from 'components/accounting/AccountingTools/AccountingTools';
import Balance from 'components/Balance/Balance';
import TransactionHistory from 'components/transactions/TransactionHistory/TransactionHistory';
// styles
import styles from './Accounting.module.scss';

const Accounting: React.FC = () => {
  const { t } = useTranslation('accountingPage');

  return (
    <BaseLayout>
      <h1 className={styles['page-title']}>{t('pageTitle')}</h1>
      <Row className={styles['accounting-header__wrapper']}>
        <Col xs={10} md={6}>
          <Balance className={styles['accounting-balance']} />
        </Col>
        <Col xs={10} md={4}>
          <AccountingTools />
        </Col>
      </Row>
      <TransactionHistory />
    </BaseLayout>
  );
};

export default observer(Accounting);
