// libraries
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
// components
import Routes from 'router/Routes';
// constants
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';
import mainRoutesConfig from 'router/configs/main';
// helpers
import useSubscribe from 'shared/hooks/useSubscribe';
import useDisableScroll from 'shared/hooks/useDisableScroll';
import { useWebsocketContext } from 'shared/websocket/websocketContext';
import { useStore } from 'shared/stores/RootStore/useStore';

const App: React.FC = () => {
  const {
    messagePendingStore: {
      pendingData: { messageType, endpoint, payload, sessionId },
      [CLEAR_STORE]: clearStore,
      isPendingMessageExist,
    },
    connectionStore: { isConnectionOpen },
  } = useStore();
  const { sendMessage } = useWebsocketContext();
  useSubscribe();
  useDisableScroll();

  // TODO: temporary measure
  const clearStorage = () => {
    localStorage.removeItem('zuro-auth');
  };

  // TODO: temporary measure
  useEffect(() => {
    window.addEventListener('beforeunload', clearStorage);

    return () => window.removeEventListener('beforeunload', clearStorage);
  }, []);

  useEffect(() => {
    if (isConnectionOpen && isPendingMessageExist) {
      sendMessage(messageType, endpoint, payload, sessionId);
      clearStore();
    }
  }, [messageType, endpoint, payload, sessionId, isConnectionOpen, isPendingMessageExist]);

  return (
    <BrowserRouter getUserConfirmation={() => {}}>
      <Routes routes={mainRoutesConfig} />
    </BrowserRouter>
  );
};

export default observer(App);
