// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
// components
import Modal from 'components/Modal/Modal';
import OwnerForm from 'components/beneficial/OwnerForm/OwnerForm';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './BeneficialOwnerModal.module.scss';

type BeneficiaryOwnerModalProps = {
  modalId: string;
};

const BeneficiaryOwnerModal: React.FC<BeneficiaryOwnerModalProps> = ({ modalId }: BeneficiaryOwnerModalProps) => {
  const {
    beneficialOwnersStore: { clearCurrentOwner },
    modalStore: { closeModal },
  } = useStore();

  const handleCloseModal = () => {
    clearCurrentOwner();
    closeModal(modalId);
  };

  return (
    <Modal id={modalId} handleCloseModal={handleCloseModal} closeButtonClassName={styles['close-button']}>
      <OwnerForm isModalComponent fieldWrapperClassName={styles['field-wrapper']} />
    </Modal>
  );
};

export default observer(BeneficiaryOwnerModal);
