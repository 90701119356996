// libraries
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// components
import DropdownButton from 'components/Button/DropdownButton/DropdownButton';
import DropdownButtonDivider from 'components/Button/DropdownButton/DropdownButtonDivider';
import ItemActionMenu from './ItemActionMenu';
// helpers
import { AccountType, Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
// assets
import { ReactComponent as DotsIcon } from 'assets/icons/action-dots-icon.svg';
import TrashIcon from 'assets/icons/trash-icon.svg';
import FundingIcon from 'assets/icons/right-up-arrow.svg';
import DepositIcon from 'assets/icons/left-down-arrow.svg';
// styles
import './PaymentActionMenu.scss';

type TPaymentActionMenu = {
  account: Account;
  deleteAccountCallback: (id: number) => void;
  setFundingCallback: (id: number) => Promise<void>;
  setDepositCallback: (id: number) => Promise<void>;
};

const Dots: React.FC = () => (
  <div className="payment-methods__actions-btn">
    <DotsIcon />
  </div>
);

const PaymentActionMenu: React.FC<TPaymentActionMenu> = ({
  account,
  deleteAccountCallback,
  setFundingCallback,
  setDepositCallback,
}: TPaymentActionMenu) => {
  const { t } = useTranslation('paymentMethodsSettings');

  const isDisabledDelete = useMemo(() => account.isFundingAccount || account.isDepositAccount, [account]);
  const isDisabledDepositButton = useMemo(() => account.isDepositAccount || account.type !== AccountType.BANK, [
    account.isDepositAccount,
    account.type,
  ]);

  const handleDeleteAccount = useCallback(() => {
    deleteAccountCallback(account.id);
  }, [account, deleteAccountCallback]);

  const handleFundingAccount = useCallback(() => {
    setFundingCallback(account.id);
  }, [account, deleteAccountCallback]);

  const handleDepositAccount = useCallback(() => {
    setDepositCallback(account.id);
  }, [account, deleteAccountCallback]);

  return (
    <DropdownButton toggle={<Dots />} menuPosition="up" className="payment-methods__actions-wrapper">
      <ItemActionMenu
        disabled={account.isFundingAccount}
        onClick={handleFundingAccount}
        logo={FundingIcon}
        text={t('actionMenu.funding')}
      />
      <ItemActionMenu
        disabled={isDisabledDepositButton}
        onClick={handleDepositAccount}
        logo={DepositIcon}
        text={t('actionMenu.deposit')}
      />
      <DropdownButtonDivider />
      <ItemActionMenu
        disabled={isDisabledDelete}
        onClick={handleDeleteAccount}
        logo={TrashIcon}
        text={t('actionMenu.delete')}
      />
    </DropdownButton>
  );
};

export default PaymentActionMenu;
