import { v4 as uuidv4 } from 'uuid';

const mockUsers = [
  {
    userId: '1',
    email: 'testuser1@email.com',
    password: 'Password1!',
    noOfAttemptsRemaining: 5,
    mfaEnabled: false,
    firstName: 'Elon',
    lastName: 'Musk',
  },
  {
    userId: '2',
    email: 'testuser2@email.com',
    password: 'Password2!',
    noOfAttemptsRemaining: 5,
    mfaEnabled: false,
    firstName: 'Edward',
    lastName: 'Snowden',
  },
  {
    userId: '3',
    email: 'testuser3@email.com',
    password: 'Password3!',
    noOfAttemptsRemaining: 5,
    mfaEnabled: true,
    firstName: 'Bill',
    lastName: 'Gates',
  },
  {
    userId: '4',
    email: 'testuser4@email.com',
    password: 'Password4!',
    noOfAttemptsRemaining: 5,
    mfaEnabled: false,
    firstName: 'Mark',
    lastName: 'Zuckerberg',
  },
  {
    userId: '5',
    email: 'testuser5@email.com',
    password: 'Password5!',
    noOfAttemptsRemaining: 5,
    mfaEnabled: false,
    firstName: 'Harry',
    lastName: 'Potter',
  },
];

export default mockUsers;

export const checkUser = ({ email, password }) => {
  const isUserFound = mockUsers.find(user => user.email === email);
  const isPasswordMatch = isUserFound?.password === password;
  let attempts = 5;

  const failureReason = () => {
    if (!isUserFound) {
      return 'User not found';
    }

    if (isUserFound && !isPasswordMatch) {
      mockUsers.forEach(user => {
        if (user.email === email) {
          if (user.noOfAttemptsRemaining === 0) {
            attempts = user.noOfAttemptsRemaining;
            return;
          }

          // eslint-disable-next-line no-param-reassign
          user.noOfAttemptsRemaining -= 1;
          attempts = user.noOfAttemptsRemaining;
        }
      });

      return 'The email and password do not match please try again';
    }

    if (isUserFound && isPasswordMatch && isUserFound.noOfAttemptsRemaining === 0) {
      attempts = isUserFound.noOfAttemptsRemaining;
      return null;
    }

    mockUsers.forEach(user => {
      if (user.email === email) {
        // eslint-disable-next-line no-param-reassign
        user.noOfAttemptsRemaining = 5;
        attempts = user.noOfAttemptsRemaining;
      }
    });

    return null;
  };

  return {
    failureReason: failureReason(),
    sessionId: isUserFound && isPasswordMatch && attempts !== 0 ? uuidv4() : null,
    isFetching: false,
    noOfAttemptsRemaining: attempts,
    mfaEnabled: isUserFound ? isUserFound.mfaEnabled : null,
    userId: isUserFound ? isUserFound.userId : null,
  };
};

export const unlockAccount = email => {
  mockUsers.forEach(user => {
    if (user.email === email) {
      // eslint-disable-next-line no-param-reassign
      user.noOfAttemptsRemaining = 5;
    }
  });
};

export const getMockUserProfile = id => {
  const { firstName, lastName, email } = mockUsers.find(user => user.userId === id);

  return {
    firstName,
    lastName,
    email,
  };
};

export const updateMockUserProfile = (id, data) => {
  const { firstName, lastName, email } = data;
  const updateData = { firstName, lastName, email };
  const isUserFoundIndex = mockUsers.findIndex(user => user.userId === id);
  const updateUserData = { ...mockUsers[isUserFoundIndex], ...updateData };
  mockUsers[isUserFoundIndex] = updateUserData;
};
