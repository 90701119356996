import Crud, { EntityId } from '../../../shared/api/crud/Crud';
import { Notification } from '../../../shared/stores/NotificationsStore/NotificationsStore';
import { HttpMethod } from '../../../shared/api/AbstractRequest';
import { ResponsePromise } from '../../../shared/api/AbstractRequestManager';

export type NotificationResponse = {
  total_items: number;
  rows: Notification[];
};

export enum NotificationTypes {
  popup = 'POPUP',
  banner = 'BANNER',
  inapp = 'INAPP',
}

class NotificationApi extends Crud {
  constructor() {
    super('/notifications');
  }

  updateNotificationDate = (id: EntityId): ResponsePromise => {
    const resourceUri = `${this.resourceUrl}/${id}`;
    const currentDate = new Date();

    const params = { body: { showedAt: currentDate.toISOString() }, method: HttpMethod.PUT };

    return this.getRequest(resourceUri, params).send();
  };
}

export default new NotificationApi();
