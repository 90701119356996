// libraries
import { useEffect, useState } from 'react';
import { sortBy, clone, reverse, get, isString } from 'lodash';
// helpers
import { TListData, TListItem, IListParams } from 'components/list/ListContext';
import { DESC } from 'components/list/listHelper';

interface IListCrudParams {
  onRemove?: (params: TListItem) => void;
  params: IListParams;
  initialData: TListData;
  idSource?: string;
  searchSources?: string[];
}

const useListCrud = ({
  onRemove = () => {},
  initialData,
  params,
  idSource = 'id',
  searchSources = [],
}: IListCrudParams) => {
  const [data, setData] = useState<TListData>(initialData || []);
  const [dataWithoutSearch, setDataWithoutSearch] = useState<TListData>(initialData || []);

  const updateListData = (listData?: TListData) => {
    const hasSearch = params.search && searchSources.length;
    let result = clone(listData || dataWithoutSearch);

    if (hasSearch) {
      result =
        result.filter(itemData =>
          searchSources.some(searchSource =>
            get(itemData, searchSource, '').toLowerCase().includes(params.search?.toLowerCase()),
          ),
        ) || [];
    }

    let sortedDataItems = sortBy(result, item =>
      isString(get(item, params.sort?.field || ''))
        ? get(item, params.sort?.field || '').toLowerCase()
        : get(item, params.sort?.field || ''),
    );

    if (params.sort?.order === DESC) {
      sortedDataItems = reverse(clone(sortedDataItems));
    }

    setData(sortedDataItems || []);
  };

  useEffect(() => {
    updateListData();
    // Suppressed warnings because we should update data only if params were updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!params.search) {
      setDataWithoutSearch(data);
    }
  }, [data, params.search]);

  useEffect(() => {
    if (initialData) {
      setDataWithoutSearch(initialData);
      updateListData(initialData);
    }
    // Suppressed warnings because we should update data only if initial data were updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const deleteFromList = (id: string) => {
    setData(prev => prev.filter(item => item[idSource] !== id));

    onRemove(id);
  };

  const addToList = (entity: TListItem) => {
    setData(prev => [entity, ...prev]);
  };

  const updateInList = (entityId: string, entity: TListItem) => {
    setData(prev => prev.map(item => (item[idSource] === entityId ? entity : item)));
  };

  const updateAllListData = (listData: TListData) => {
    setDataWithoutSearch(listData);
    updateListData(listData);
  };

  return {
    data,
    dataWithoutSearch,
    updateAllListData,
    deleteFromList,
    addToList,
    updateInList,
  };
};

export default useListCrud;
