// libraries
import { useCallback, useEffect, useState } from 'react';
import { get, set, isEmpty, isEqual, cloneDeep } from 'lodash';
import yup from 'yup';
import { useTranslation } from 'react-i18next';
// context
import { Form, FormErrors, FormContextData, FormFieldValue, FormData } from './FormContext';
// helpers
import { addErrorToast } from '../Toast/toastHelper';
import { FileItem } from 'components/Form/inputs/FileUpload/useFileUpload';
import { uploadFiles } from 'components/Form/inputs/FileUpload/fileUploadHelpers';
// RootStore
import { useSelectFromStoreByEndpoint, useStore } from '../../shared/stores/RootStore/useStore';

const useForm = (contextData: FormContextData): Form => {
  const {
    data: initialData = {},
    errors: formErrors = {},
    submitForm,
    validationSchema,
    canBeEmpty = false,
    formType,
  } = contextData;

  const [data, setData] = useState(cloneDeep(initialData || {}));
  const [errors, setErrors] = useState(cloneDeep(formErrors || {}));
  const [isDirty, setDirty] = useState<boolean>(false);
  const [isSubmitEnabled, setSubmitEnabled] = useState<boolean>(true);
  const [isFormValidated, setIsFormValidated] = useState<boolean>(false);

  const {
    formStore: { setIsFormDirty, setIsFormSubmitting, setIsFilesLoading },
  } = useStore();

  // @ts-ignore
  const { isFetching } = useSelectFromStoreByEndpoint(formType) || {};

  useEffect(() => {
    if (!isFetching) {
      setSubmitEnabled(true);
      setIsFormSubmitting(false);
      setIsFilesLoading(false);
    }
  }, [isFetching]);

  const { t } = useTranslation('errors');

  const onUnload = (e: BeforeUnloadEvent) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  });

  useEffect(() => {
    if (!isEmpty(formErrors) && isSubmitEnabled && !isDirty) {
      setErrors({ ...formErrors });
    }
  }, [formErrors, isSubmitEnabled, isDirty]);

  const validateField = useCallback(
    async (fieldName: string, values?: FormData) => {
      let errs: string[] = [];

      if (!validationSchema) {
        return errs;
      }

      try {
        await validationSchema.validate(values || data || {}, { abortEarly: false });
      } catch (e) {
        const [validationError] = e.inner.filter(({ path }: FormData) => path === fieldName);
        errs = get(validationError, 'errors');
      }

      setErrors(prevErrors => ({ ...prevErrors, [fieldName]: errs?.map(elem => t(elem)) }));

      return errs;
    },
    [data, validationSchema, t],
  );

  const validateForm = useCallback(
    async (isDirtyForm?: boolean) => {
      const errs: FormErrors = {};

      if (!validationSchema || (!isDirtyForm && !isDirty)) {
        return errs;
      }

      try {
        await validationSchema.validate(data || {}, { abortEarly: false });
      } catch (e) {
        e.inner.forEach((errorDetails: yup.ValidationError) => {
          const { path, errors: fieldErrors } = errorDetails;

          set(
            errs,
            path,
            fieldErrors.map(errPath => t(errPath)),
          );
        });
      }

      setErrors(errs);

      return errs;
    },
    [data, t, validationSchema, isDirty],
  );

  const onChangeCallback = (path: string, values: FormFieldValue) => {
    setDirty(true);

    setData(prevData => {
      return set({ ...prevData }, path, values);
    });
  };

  const isValidForm = (currentErrors?: FormErrors) => {
    if (canBeEmpty) {
      return isEmpty(currentErrors || errors);
    }

    return !isEmpty(data) && isEmpty(currentErrors || errors);
  };

  const onSubmitForm = async () => {
    const currentErrors = await validateForm(true);

    setIsFormValidated(true);

    if (!isValidForm(currentErrors) || !submitForm || !isSubmitEnabled) {
      return;
    }

    setSubmitEnabled(false);
    setDirty(false);
    setIsFormDirty(false);
    setIsFormSubmitting(true);

    try {
      const { validationProps, fileupload, ...formData } = data || {};
      let filesData = {};

      if (fileupload) {
        setIsFilesLoading(true);
        filesData = await Promise.all(
          Object.entries(fileupload).map(async ([key, files]) => {
            formData[key] = await uploadFiles(files as FileItem[]);
          }),
        );
      }

      await submitForm({ ...formData, ...filesData });
    } catch ({ message, errors: err }) {
      setDirty(true);

      if (err && !isEmpty(err)) {
        setErrors(err);
      } else {
        addErrorToast(message);
      }
    } finally {
      setSubmitEnabled(true);
    }
  };

  const getFieldValue = useCallback(
    (fieldName: string) => {
      return get(data, fieldName) || null;
    },
    [data],
  );

  const getErrorValue = (fieldName: string) => {
    const fieldValue = getFieldValue(fieldName);

    if (!fieldValue && !isFormValidated) {
      return [];
    }

    return get(errors, fieldName);
  };

  const setFieldErrors = useCallback((fieldName: string, value: string[]) => {
    setErrors(prevErrors => set({ ...prevErrors }, fieldName, value || []));
  }, []);

  const setFieldValues = useCallback(
    (fieldName, value, shouldUpdateFormState = true) => {
      const initialValue = get(initialData, fieldName);

      setData(prevData => {
        const prevValue = get(prevData, fieldName);

        if (isEqual(prevValue, value)) {
          return prevData;
        }

        return set({ ...prevData }, fieldName, value);
      });

      if (initialValue !== value && shouldUpdateFormState && value) {
        setDirty(true);
      }
    },
    [initialData],
  );

  const resetForm = () => {
    setData(cloneDeep(initialData || {}));
    setErrors(cloneDeep(formErrors || {}));
    setIsFormDirty(false);
    setDirty(false);
  };

  useEffect(() => {
    if (!isDirty) {
      return;
    }

    setIsFormDirty(isDirty);
  }, [isDirty, setIsFormDirty]);

  return {
    data,
    errors,
    isFormValidated,
    onChangeCallback,
    onSubmitForm,
    resetForm,
    validationSchema,
    isSubmitEnabled,
    isDirty,
    getErrorValue,
    getFieldValue,
    validateForm,
    setFieldErrors,
    setFieldValues,
    isValidForm,
    setIsFormValidated,
    validateField,
  };
};

export default useForm;
