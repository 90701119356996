// libraries
import has from 'lodash/has';
// constants
import MessageTypes from '../constants/messageTypes';
import Endpoints from '../constants/endpoints';
import EndpointsToStores from 'shared/constants/EndpointsToStores';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';
// helpers
import { useStore } from '../stores/RootStore/useStore';
import { useWebsocketContext } from '../websocket/websocketContext';

type LogoutData = {
  logout: () => void;
};

const useLogout = (): LogoutData => {
  const store = useStore();
  const {
    authStore: { sessionId, removeSessionId },
  } = store;
  const { sendMessage } = useWebsocketContext();

  const clearStore = () => {
    removeSessionId();
    localStorage.clear();
    Object.values(EndpointsToStores).forEach(EndpointToStore => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (has(store[EndpointToStore], CLEAR_STORE)) store[EndpointToStore][CLEAR_STORE]();
    });
  };

  const logout = () => {
    sendMessage(MessageTypes.REQUEST, Endpoints.LOGOUT, {}, sessionId);
    clearStore();
  };

  return {
    logout,
  };
};

export default useLogout;
