// libraries
import React from 'react';
import classNames from 'classnames';
// styles
import styles from './InvoicePage.module.scss';

type TInvoicePageProps = {
  children: React.ReactNode;
  title: string;
  titleClassName?: string;
  subtitle?: string;
  subtitleClassName?: string;
};

const InvoicePage: React.FC<TInvoicePageProps> = ({
  children,
  title,
  titleClassName = '',
  subtitle = '',
  subtitleClassName = '',
}: TInvoicePageProps) => {
  return (
    <div className={styles.page}>
      <div className={styles.page__wrapper}>
        <h1 className={classNames(styles.page__title, titleClassName)}>{title}</h1>
        {subtitle && <p className={classNames(styles.page__subtitle, subtitleClassName)}>{subtitle}</p>}
        {children}
      </div>
    </div>
  );
};

export default InvoicePage;
