// libraries
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import EntityLogo from '../../ShortEntityCard/EntityLogo';
import ShortEntityCard from '../../ShortEntityCard/ShortEntityCard';
// styles
import styles from './InvoiceOwnerInfo.module.scss';

type TInvoiceOwnerInfoProps = {
  logoSrc?: string;
  companyName?: string;
  companyEmail?: string;
  invoiceNumber?: string | number;
  withNewVendorBadge?: boolean;
};

const InvoiceOwnerInfo: React.FC<TInvoiceOwnerInfoProps> = ({
  logoSrc = '',
  companyName = '',
  companyEmail = '',
  invoiceNumber = '',
  withNewVendorBadge = false,
}: TInvoiceOwnerInfoProps) => {
  const { t } = useTranslation('invoice');

  return (
    <ShortEntityCard
      className={classNames(styles['invoice-owner'])}
      message={
        <div className={styles['invoice-owner__vendor-name']}>
          <span className={styles['invoice-owner__name']}>{companyName}</span>
          {withNewVendorBadge && <span className={styles['invoice-owner__new-vendor']}>{t('newVendor')}</span>}
        </div>
      }
      logo={<EntityLogo logoSrc={logoSrc} className={styles['invoice-owner__logo']} />}
      helpText={companyEmail}
      helpTextClassName={styles['invoice-owner__email']}
      additionalInfo={
        invoiceNumber && (
          <div className={styles['invoicing-number']}>
            {t('invoiceNumber')} <br /> #{invoiceNumber}
          </div>
        )
      }
    />
  );
};

export default InvoiceOwnerInfo;
