// libraries
import React, { useMemo } from 'react';
// components
import EditReceivableInvoice from './EditReceivableInvoice';
import DownloadInvoice from 'components/transactions/TransactionDetails/shared/DownloadInvoice';
import CancelReceivableInvoice from './CancelReceivableInvoice';
import TransactionCardFooterActions from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooterActions/TransactionCardFooterActions';
import DropdownButtonDivider from 'components/Button/DropdownButton/DropdownButtonDivider';
// helpers
import { Invoice } from 'components/invoices/types';
import { completedStates } from 'components/transactions/TransactionHistory/transactionHistoryHelpers';

interface IReceivableInvoiceFooterActions {
  invoiceDetails: Invoice;
}

const ReceivableInvoiceFooterActions: React.FC<IReceivableInvoiceFooterActions> = ({
  invoiceDetails,
}: IReceivableInvoiceFooterActions) => {
  const { isReceived, invoiceState } = invoiceDetails;
  const isCompleted = useMemo(() => completedStates.includes(invoiceState), [invoiceState]);

  // TODO need hide edit if mark as received?
  return (
    <TransactionCardFooterActions>
      {!isReceived && <EditReceivableInvoice invoiceDetails={invoiceDetails} />}
      <DownloadInvoice invoiceId={invoiceDetails.invoiceId} />
      {!isCompleted && (
        <>
          <DropdownButtonDivider />
          <CancelReceivableInvoice invoiceId={invoiceDetails.invoiceId} />
        </>
      )}
    </TransactionCardFooterActions>
  );
};

export default ReceivableInvoiceFooterActions;
