// libraries
import * as Yup from 'yup';
import { formatISO } from 'date-fns';
import fieldSizes from 'shared/constants/fieldSizes';

export default Yup.object().shape({
  amount: Yup.number()
    .required('forms.amount.required')
    .moreThan(0, 'forms.amount.enterValidAmount')
    .lessThan(fieldSizes.MONEY_LIMIT, 'forms.amount.enterValidAmount'),
  dueDate: Yup.date()
    .typeError('forms.dueDate.required')
    .min(formatISO(new Date(), { representation: 'date' }), 'forms.dueDate.minDate')
    .required('forms.dueDate.required'),
  description: Yup.string(),
});
