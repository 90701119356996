// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// styles
import 'react-datepicker/dist/react-datepicker.css';

interface ICalendarHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}

const CalendarHeader: React.FC<ICalendarHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
}: ICalendarHeaderProps) => {
  const { t } = useTranslation('externalPaymentPage');

  return (
    <>
      <button
        aria-label="Previous Month"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        onClick={decreaseMonth}
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">{'<'}</span>
      </button>
      <span className="react-datepicker__current-month">
        {date.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })}
      </span>
      <button
        aria-label="Next Month"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        onClick={increaseMonth}
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">{'>'}</span>
      </button>

      <div className="react-datepicker__help-text">{t('form.DueDate')}</div>
    </>
  );
};

export default CalendarHeader;
