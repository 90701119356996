import { formatISO } from 'date-fns';

const tryFormatToIso = (value: number | Date): string => {
  try {
    return formatISO(value, { representation: 'date' });
  } catch {
    return '';
  }
};

// eslint-disable-next-line import/prefer-default-export
export { tryFormatToIso };
