// libraries
import React from 'react';
import classnames from 'classnames';
// helpers
import { useListContext, TListItem } from 'components/list/ListContext';
import FieldData from 'components/list/field/FieldData';
import { FieldContextProvider, TFieldDataInstance } from 'components/list/field/FieldContext';
// components
import Scrollbar, { ScrollBarRef } from 'components/Scrollbar/Scrollbar';

export interface ITableBodyProps {
  children: React.ReactElement | React.ReactElement[];
  gridTemplateColumns?: string;
  button?: React.ReactElement | React.ReactElement[];
  rowKeySource?: string;
  rowProps?: (instance: TFieldDataInstance) => Dictionary<any>;
  tableMaxHeight?: number;
  scrollbarRef?: ScrollBarRef;
}

const renderCell = (data: TListItem, element: TFieldDataInstance, className: string) => {
  const { wrapperClassName } = element.props;

  return <div className={classnames(className, wrapperClassName)}>{React.cloneElement(element)}</div>;
};

const DEFAULT_TABLE_MAX_HEIGHT = 600;

const TableBody = ({
  children,
  button = [],
  rowKeySource = 'id',
  gridTemplateColumns,
  rowProps,
  tableMaxHeight = DEFAULT_TABLE_MAX_HEIGHT,
  scrollbarRef,
}: ITableBodyProps) => {
  const { data = [] } = useListContext();

  return (
    <Scrollbar style={{ maxHeight: tableMaxHeight, paddingRight: 15 }} scrollbarRef={scrollbarRef}>
      {data.map((item: TListItem) => {
        const dataInstance = new FieldData(item);
        const props = rowProps ? rowProps(dataInstance) : {};

        return (
          <div
            {...props}
            key={dataInstance.getValueBySource(rowKeySource)}
            className={classnames('data-grid-table__row', props.className)}
            style={{ gridTemplateColumns }}
          >
            <FieldContextProvider
              value={{
                data: dataInstance,
              }}
            >
              {React.Children.map(children, child => {
                return renderCell(dataInstance, child, '');
              })}
              {React.Children.map(button, btn => {
                return renderCell(dataInstance, btn, 'list-btn-container');
              })}
            </FieldContextProvider>
          </div>
        );
      })}
    </Scrollbar>
  );
};

export default TableBody;
