// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// components
import TransferDateField from 'components/transactions/TransactionCard/TransferDateField';
import ShortEntityCard from 'components/ShortEntityCard/ShortEntityCard';
// helpers
import { formatAmountToLocal } from 'shared/helpers/format';
import { FundsTransferTypes, Invoice } from 'components/invoices/types';
import { getInvoiceCompanyLogo } from 'components/invoices/invoiceHelpers';
// styles
import styles from './TransactionCard.module.scss';

interface ITransferFundsCard {
  data: Invoice;
  children?: React.ReactNode;
}

const TransferFundsCard: React.FC<ITransferFundsCard> = ({ data, children }: ITransferFundsCard) => {
  const { t } = useTranslation('transactionHistory');
  const { totalAmount, counterpartyDetails, payDate, transferType } = data || {};
  const isPayableInvoice = useMemo(() => transferType === FundsTransferTypes.transfer, [transferType]);

  return (
    <>
      <ShortEntityCard
        className="invoice-details-card"
        helpText={counterpartyDetails.cardNumber || ''}
        message={
          <div className={styles['transaction-card__entity-info']}>
            <span className={styles['transaction-card__entity-subname']}>{isPayableInvoice ? t('to') : t('from')}</span>
            <span className={styles['transaction-card__entity-name']}>{counterpartyDetails.bankName}</span>
          </div>
        }
        logo={getInvoiceCompanyLogo(isPayableInvoice, counterpartyDetails.logoImage, false, 'transaction-card__logo')}
        additionalInfo={
          <div>
            <div className={styles['transaction-card-header__amount']}>
              <div>{isPayableInvoice ? '-' : '+'}</div>
              <div>{formatAmountToLocal(totalAmount)}</div>
            </div>
          </div>
        }
      />
      {payDate && <TransferDateField value={payDate} />}
      {children}
    </>
  );
};

export default TransferFundsCard;
