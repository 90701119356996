// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import isString from 'lodash/isString';
// components
import AppLoading from 'components/AppLoading/AppLoading';
import Button from 'components/Button';
import Modal, { ModalType } from '../Modal';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import useConfirmationModal from 'components/Modal/ConfirmationModal/useConfirmationModal';
// styles
import styles from './ConfirmationModal.module.scss';

type ConfirmationModalProps = {
  customId?: string;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ customId }: ConfirmationModalProps) => {
  const { t } = useTranslation('confirmationModal');
  const { isLoading, modalData, handleCancelAction, handleConfirmAction } = useConfirmationModal(customId);

  const icon = useMemo(() => {
    if (isString(modalData?.icon)) {
      return <img src={modalData?.icon} alt="img" />;
    }

    return modalData?.icon;
  }, [modalData?.icon]);

  return (
    <Modal
      id={customId || ModalIds.confirmationModal}
      type={ModalType.confirmation}
      handleCloseModal={handleCancelAction}
    >
      <AppLoading isActive={isLoading}>
        <div className={styles['confirmation-modal']}>
          {icon}
          <div className={classNames(styles['confirmation-modal__title'], modalData?.classNameTitle)}>
            {modalData?.title || t('title')}
          </div>
          {modalData?.content && (
            <p className={classNames(styles['confirmation-modal__text'], modalData?.classNameContent)}>
              {modalData?.content}
            </p>
          )}
          <div className={styles['confirmation-modal__controls']}>
            <Button outline handleClick={handleCancelAction}>
              {modalData?.cancelButtonText || t('cancelButton')}
            </Button>
            <Button {...modalData?.confirmButtonProps} handleClick={handleConfirmAction}>
              {modalData?.confirmButtonText || t('confirmButton')}
            </Button>
          </div>
        </div>
      </AppLoading>
    </Modal>
  );
};

export default observer(ConfirmationModal);
