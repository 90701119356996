// libraries
import React from 'react';
// components
import Email from 'router/views/SignUp/RegistrationFlow/Email/Email';
import Register from 'router/views/SignUp/RegistrationFlow/Register/Register';
import Verification from 'router/views/SignUp/RegistrationFlow/Verification/Verification';
import EmailChange from 'router/views/SignUp/RegistrationFlow/EmailChange/EmailChange';
// helpers
import useSignUpForm, { SignUpFormStep } from 'components/signUpForms/useSignUpForm';

const RegistrationFlow: React.FC = () => {
  const {
    currentStep,
    handleEmailChange,
    email,
    submitRegisterForm,
    submitEmailForm,
    submitVerificationForm,
    submitEmailChangeForm,
  } = useSignUpForm();

  if (currentStep === SignUpFormStep.EMAIL_CHANGE) {
    return <EmailChange submitEmailChangeForm={submitEmailChangeForm} />;
  }

  if (currentStep === SignUpFormStep.VERIFICATION) {
    return (
      <Verification
        email={email}
        handleEmailChange={handleEmailChange}
        submitVerificationForm={submitVerificationForm}
      />
    );
  }

  if (currentStep === SignUpFormStep.REGISTER) {
    return <Register submitRegisterForm={submitRegisterForm} />;
  }

  return <Email submitEmailForm={submitEmailForm} />;
};

export default RegistrationFlow;
