// libraries
import React from 'react';
import classnames from 'classnames';
// styles
import styles from './TransactionCardFooter.module.scss';

interface ITransactionCardFooter {
  helpText?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

const TransactionCardFooter: React.FC<ITransactionCardFooter> = ({
  helpText,
  children,
  className,
}: ITransactionCardFooter) => {
  return (
    <div className={classnames(styles['transaction-card-footer__wrapper'], className)}>
      <p className={styles['transaction-card-footer__help-text']}>{helpText}</p>
      {children && <div className={styles['transaction-card-footer__inner']}>{children}</div>}
    </div>
  );
};

export default TransactionCardFooter;
