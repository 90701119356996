export default [
  '/',
  '/settings/payment-methods',
  '/settings/integrations',
  '/settings/documents',
  '/settings',
  '/verification',
  '/verification/business',
  '/verification/business/industry',
  '/verification/business/address',
  '/verification/business/employee-id-number',
  '/verification/business/owners',
  '/verification/personal',
  '/verification/personal/job-title',
  '/verification/personal/date-of-birth',
  '/verification/personal/home-address',
  '/verification/personal/phone-number',
  '/verification/personal/ssn',
  '/verification/bank-accounts',
  '/verification/completed',
];
export const DISABLED_ROUTES = [];
