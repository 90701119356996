// libraries
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
// components
import AppLoading from 'components/AppLoading/AppLoading';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import CreditCard from 'components/paymentMethods/PaymentMethod/CreditCard/CreditCard';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// context
import { MultiStepProvider } from 'components/MultiStep/MultiStepContext';
// helpers
import usePaymentMethod, { PaymentMethodStep } from './usePaymentMethod';
import { usePlaces } from 'shared/helpers/placesHelpers';
import useMultiStep from 'components/MultiStep/useMultiStep';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './PaymentMethod.module.scss';

type PaymentMethodProps = {
  modalId?: ModalIds;
};

const PaymentMethod: React.FC<PaymentMethodProps> = ({ modalId = ModalIds.paymentMethod }) => {
  const contextValue = useMultiStep();
  const { currentStep } = contextValue;
  const { isPlacesLoaded } = usePlaces();
  const {
    paymentMethods: { setModalId, isLoading },
  } = useStore();

  const { handleCloseModal, addStripeCard } = usePaymentMethod();

  useEffect(() => {
    setModalId(modalId);
  }, [modalId, setModalId]);

  const containerClassNames = classNames(styles['payment-method'], {
    [styles.form__wrapper]: currentStep !== PaymentMethodStep.OPTIONS,
  });

  return (
    <MultiStepProvider value={contextValue}>
      <AppLoading isActive={!isPlacesLoaded || isLoading} hideContent>
        <div className={containerClassNames}>
          {currentStep === PaymentMethodStep.OPTIONS && <PaymentOptions handleCloseModal={handleCloseModal} />}
          {currentStep === PaymentMethodStep.CREDIT_CARD && <CreditCard addStripeCard={addStripeCard} />}
        </div>
      </AppLoading>
    </MultiStepProvider>
  );
};

export default observer(PaymentMethod);
