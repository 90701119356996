// libraries
import React from 'react';
import classnames from 'classnames';
// assets
import { ReactComponent as VerifyIcon } from 'assets/icons/verified-star.svg';
// styles
import styles from './VerificationStar.module.scss';

const DEFAULT_STAR_SIZE = 26;

type VerificationStarProps = {
  isVerified?: boolean;
  size?: number;
  className?: string;
};

const VerificationStar: React.FC<VerificationStarProps> = ({
  isVerified,
  size = DEFAULT_STAR_SIZE,
  className,
}: VerificationStarProps) => {
  return (
    <VerifyIcon
      className={classnames(styles['verify-star'], { [styles.active]: isVerified }, className)}
      width={size}
      height={size}
    />
  );
};

export default VerificationStar;
