import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import { ShortListType } from 'shared/stores/ShortListsStore/ShortListsStore';
import { useStore } from 'shared/stores/RootStore/useStore';
// components
import Button from 'components/Button';
// styles
import styles from './ShortListEmptyText.module.scss';

type TText = {
  additionalText: string;
  isButton?: boolean;
  handleClick?: () => void;
};

const Text: React.FC<TText> = ({ additionalText, isButton = false, handleClick }: TText) => {
  const { t } = useTranslation('shortLists');

  return (
    <div>
      {isButton && (
        <Button handleClick={handleClick} className={styles['btn-link']} btnLink>
          {t('sendInvoice')}&nbsp;
        </Button>
      )}
      <span className={styles['additional-text']}>{additionalText}</span>
    </div>
  );
};

type TShortListEmptyText = {
  shortListType: ShortListType;
};

const ShortListEmptyText: React.FC<TShortListEmptyText> = ({ shortListType }: TShortListEmptyText) => {
  const { t } = useTranslation('shortLists');
  const {
    modalStore: { closeAllModals },
  } = useStore();

  const handleClick = useCallback(() => {
    closeAllModals();
  }, [closeAllModals]);

  const helpText = useMemo(() => {
    if (shortListType === ShortListType.topClients) {
      return <Text handleClick={handleClick} additionalText={t('helpTexts.topCustomers')} isButton />;
    }

    if (shortListType === ShortListType.recentPayments) {
      return <Text handleClick={handleClick} additionalText={t('helpTexts.recentPayments')} isButton />;
    }
    return <Text additionalText={t('helpTexts.shortlist')} />;
  }, [handleClick, shortListType]);

  return <div>{helpText}</div>;
};

export default ShortListEmptyText;
