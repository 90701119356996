// libraries
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { get } from 'lodash';
// components
import { InputElements } from '../Input';
// context
import { useFormContext } from 'components/Form/FormContext';
// helpers
import { addErrorToast } from 'components/Toast/toastHelper';
import { COUNTRIES, USA_COUNTRY } from 'shared/helpers/countries';

type AutocompleteHelper = {
  handleSelect: (option: string) => Promise<void>;
  handleChangeValue: (value: string) => void;
  handleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  inputValue: string;
  isVisibleResults: boolean;
  handleCloseResults: () => void;
  handleFocus: () => void;
  setIsVisibleResults: Dispatch<SetStateAction<boolean>>;
  setInputValue: Dispatch<SetStateAction<string>>;
};

const useAutocompleteInput = (
  onBlur: (event?: FormEvent<InputElements>) => void,
  name: string,
  namePrefix?: string,
  additionalOnChangeCallBack?: Dispatch<SetStateAction<string>>,
): AutocompleteHelper => {
  const [isVisibleResults, setIsVisibleResults] = useState(false);
  const [isAddressChanged, setIsAddressChanged] = useState(false);
  const {
    setFieldValues = () => {},
    data,
    isDirty,
    setFieldErrors = () => {},
    validateForm = () => {},
    validateField = () => {},
  } = useFormContext();

  const initialState = get(data, name, '') as string;

  const [inputValue, setInputValue] = useState(initialState);

  const prefix = useMemo(() => (namePrefix ? `${namePrefix}.` : ''), [namePrefix]);

  const handleChangeValue = (value: string) => {
    setIsVisibleResults(true);
    setInputValue(value);
    setFieldValues(name, value);

    if (additionalOnChangeCallBack) {
      additionalOnChangeCallBack(value);
    }
  };

  useEffect(() => {
    if (isAddressChanged) {
      validateForm();
    }
  }, [validateForm, isAddressChanged, inputValue]);

  useEffect(() => {
    if (!isDirty) {
      setInputValue(initialState);
    }
  }, [data, isDirty, initialState]);

  const handleBlur = useCallback(
    event => {
      const { value } = event.target;
      setFieldValues(name, value);
      setIsAddressChanged(true);

      if (value) {
        validateField(name, { [name]: value });
      } else {
        validateField(name, {});
      }

      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur, setFieldValues, name, validateField],
  );

  const handleFocus = useCallback(() => {
    setFieldErrors(name, []);
  }, [name, setFieldErrors]);

  const handleSelect = async (option: string, placeId?: string) => {
    if (!placeId) {
      return;
    }

    setIsVisibleResults(false);
    setIsAddressChanged(true);
    let streetNumber = '';
    let route = '';
    let locality = '';
    let countryShortName = '';
    let postalCode = '';
    let administrativeArea = {
      shortName: '',
      longName: '',
    };

    try {
      const results = await geocodeByAddress(option);

      results[0].address_components.forEach(el => {
        if (el.types.includes('street_number')) {
          streetNumber = el.long_name;
          return;
        }

        if (el.types.includes('route')) {
          route = el.long_name;
          return;
        }

        if (el.types.includes('locality')) {
          locality = el.long_name;
          return;
        }

        if (el.types.includes('country')) {
          countryShortName = el.short_name;
          return;
        }

        if (el.types.includes('postal_code')) {
          postalCode = el.short_name;
          return;
        }

        if (el.types.includes('administrative_area_level_1')) {
          administrativeArea = {
            longName: el.long_name,
            shortName: el.short_name,
          };
        }
      });
    } catch (error) {
      const { message } = error;
      // addErrorToast(message);
    }

    const countryFromSelect = COUNTRIES.find(country => (country.params === countryShortName ? country.value : ''));
    const resultCountry = countryFromSelect?.value || '';
    const resultAdministrativeArea =
      resultCountry === USA_COUNTRY ? administrativeArea?.shortName : administrativeArea.longName;

    setInputValue(`${option}, ${postalCode}`);

    setFieldValues(name, option);
    setFieldValues(`${prefix}line1`, `${streetNumber} ${route}`);
    setFieldValues(`${prefix}city`, locality);
    setFieldValues(`${prefix}country`, resultCountry);

    if (resultCountry === USA_COUNTRY) {
      setFieldValues(`${prefix}state`, resultAdministrativeArea);
      setFieldValues(`${prefix}zip`, postalCode);
      setFieldValues(`${prefix}region`, '');
      setFieldValues(`${prefix}postalCode`, '');
      return;
    }

    setFieldValues(`${prefix}region`, resultAdministrativeArea);
    setFieldValues(`${prefix}postalCode`, postalCode);
    setFieldValues(`${prefix}state`, '');
    setFieldValues(`${prefix}zip`, '');
  };

  const handleCloseResults = useCallback(() => {
    setIsVisibleResults(false);
  }, []);

  return {
    handleSelect,
    handleChangeValue,
    handleBlur,
    inputValue,
    isVisibleResults,
    handleCloseResults,
    handleFocus,
    setIsVisibleResults,
    setInputValue,
  };
};

export default useAutocompleteInput;
