// libraries
import { useCallback, useEffect, useState } from 'react';
// api
import InviteBusinessApi from './api/InviteBusinessApi';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// context
import { FormData } from 'components/Form/FormContext';
// helpers
import { addErrorToast } from 'components/Toast/toastHelper';
import { convertDollarsToCents } from 'shared/helpers/format';
import { errorMessageHasButtons } from './inviteBusinessHelper';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';

type SendInvoiceData = {
  handleBackButtonClick: () => void;
  handleCloseModal: () => void;
  isFormSubmitted: boolean;
  sendInvoice: (data: FormData) => Promise<void>;
  forceFormSubmission: () => void;
  submitInviteBusinessForm: (data: FormData) => void;
  displayedModalContent: ModalContent;
  mainFormData: FormData;
  errorMessage: Nullable<string>;
};

export enum InviteReasons {
  invoice = 'send-invoice',
  checkout = 'checkout',
}

export enum WarningType {
  inviteCheckoutCheckEmail = 'inviteCheckoutCheckEmail',
  sendInviteCheckEmail = 'sendInviteCheckEmail',
  sendInviteCheckLegalName = 'sendInviteCheckLegalName',
}

export enum ModalContent {
  mainForm = 'mainForm',
  invoiceDetails = 'invoiceDetails',
}

const initialFormData = { reason: InviteReasons.invoice };

const useInviteBusiness = (): SendInvoiceData => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const {
    modalStore: { openModal, modalIds, closeAllModals },
    formStore: { setIsFormDirty },
    invoiceStore: { resetData },
  } = useStore();
  const [mainFormData, setMainFormData] = useState<FormData>(initialFormData);
  const [formData, setFormData] = useState<FormData>({});
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [displayedModalContent, setDisplayedModalContent] = useState<ModalContent>(ModalContent.mainForm);
  const [warningType, setWarningType] = useState<Nullable<WarningType>>(null);

  const showError = useCallback(
    async (e, warnType) => {
      const { message = '' } = await e;
      if (errorMessageHasButtons(message)) {
        setWarningType(warnType);
        setErrorMessage(message);
        openModal(ModalIds.inviteWarningPopup);
      }

      // addErrorToast(message);
    },
    [openModal],
  );

  const clearData = useCallback(() => {
    setDisplayedModalContent(ModalContent.mainForm);
    setIsFormSubmitted(true);
    setIsFormDirty(false);
  }, [setIsFormDirty]);

  const sendInvoice = useCallback(
    async (data?: FormData) => {
      setIsFormSubmitted(false);
      setErrorMessage(null);

      const requestData = data || formData;

      try {
        await InviteBusinessApi.sendInvoice({
          ...requestData,
          ...mainFormData,
          amount: +convertDollarsToCents(requestData.amount as string),
          isForced: !data,
        });

        clearData();
      } catch (e) {
        setFormData(requestData);
        showError(e, WarningType.sendInviteCheckLegalName);
      }
    },
    [showError, formData, mainFormData, clearData],
  );

  const submitCheckout = useCallback(
    async (data?: FormData) => {
      setIsFormSubmitted(false);
      setErrorMessage(null);

      const requestData = data || formData;

      try {
        await InviteBusinessApi.sendCheckout({
          email: requestData.email,
          isForced: !data,
        });

        clearData();
      } catch (e) {
        setFormData(requestData);
        showError(e, WarningType.inviteCheckoutCheckEmail);
      }
    },
    [showError, formData, clearData],
  );

  const checkEmail = useCallback(
    async (email: string) => {
      setIsFormSubmitted(false);
      setErrorMessage(null);

      try {
        await InviteBusinessApi.checkEmail(email as string);
        return true;
      } catch (e) {
        showError(e, WarningType.sendInviteCheckEmail);
        return false;
      }
    },
    [showError],
  );

  const goToInvite = () => {
    setErrorMessage(null);
    setDisplayedModalContent(ModalContent.invoiceDetails);
  };

  const submitInviteBusinessForm = useCallback(
    async (data: FormData) => {
      if (data.reason === InviteReasons.checkout) {
        submitCheckout(data);
        return;
      }

      const isEmailAvailable = await checkEmail(data.email as string);

      if (isEmailAvailable) {
        goToInvite();
      }

      setMainFormData(data);
    },
    [submitCheckout, checkEmail],
  );

  const handleBackButtonClick = useCallback(() => {
    resetData();
    setDisplayedModalContent(ModalContent.mainForm);
  }, [resetData]);

  const handleCloseModal = useCallback(() => {
    resetData();
    closeAllModals();
  }, [resetData, closeAllModals]);

  const forceFormSubmission = useCallback(() => {
    if (warningType === WarningType.inviteCheckoutCheckEmail) {
      submitCheckout();
      return;
    }

    if (warningType === WarningType.sendInviteCheckLegalName) {
      sendInvoice();
      return;
    }

    if (warningType === WarningType.sendInviteCheckEmail) {
      goToInvite();
    }

    setWarningType(null);
  }, [sendInvoice, submitCheckout, warningType]);

  useEffect(() => {
    if (modalIds.length) {
      return;
    }

    setMainFormData(initialFormData);
    setDisplayedModalContent(ModalContent.mainForm);
    setErrorMessage(null);
    setIsFormSubmitted(false);
  }, [modalIds.length]);

  return {
    handleBackButtonClick,
    handleCloseModal,
    isFormSubmitted,
    sendInvoice,
    displayedModalContent,
    mainFormData,
    submitInviteBusinessForm,
    errorMessage,
    forceFormSubmission,
  };
};

export default useInviteBusiness;
