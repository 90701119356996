// libraries
import { computed, makeAutoObservable } from 'mobx';
import { persist } from 'mobx-persist';
// components
import RootStore from 'shared/stores/RootStore/RootStore';
// constants
import UserRoles from 'shared/constants/userRoles';
import VerificationStatus from 'shared/constants/verificationStatus';

export type UserStoreData = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: UserRoles[];
  companyId: number;
  companyName: string;
  phoneVerified: boolean;
  companyVerificationStatus: VerificationStatus;
  identityVerificationStatus: VerificationStatus;
  beneficialVerificationStatus: VerificationStatus;
  nextVerificationStep: string;
};

class UserStore {
  @persist lastVisitedPage: Nullable<string> = null;

  @persist id: Nullable<number> = null;

  @persist firstName: Nullable<string> = null;

  @persist lastName: Nullable<string> = null;

  @persist email: Nullable<string> = null;

  @persist phone: Nullable<string> = null;

  @persist('list') role: string[] = [];

  @persist companyId: Nullable<number> = null;

  @persist companyName: Nullable<string> = null;

  @persist phoneVerified: Nullable<boolean> = false;

  @persist companyVerificationStatus: Nullable<VerificationStatus> = null;

  @persist identityVerificationStatus: Nullable<VerificationStatus> = null;

  @persist nextVerificationStep: Nullable<string> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setUser = ({
    id,
    firstName,
    lastName,
    email,
    phone,
    role,
    companyId,
    companyName,
    phoneVerified,
    companyVerificationStatus,
    identityVerificationStatus,
    nextVerificationStep,
  }: UserStoreData): void => {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.role = role;
    this.companyId = companyId;
    this.companyName = companyName;
    this.phoneVerified = phoneVerified;
    this.companyVerificationStatus = companyVerificationStatus;
    this.identityVerificationStatus = identityVerificationStatus;
    this.nextVerificationStep = nextVerificationStep;
  };

  removeUser = (): void => {
    this.id = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    this.phone = null;
    this.role = [];
    this.companyId = null;
    this.companyName = null;
    this.phoneVerified = null;
    this.companyVerificationStatus = null;
    this.identityVerificationStatus = null;
  };

  setLastVisitedPage = (pathname: Nullable<string>): void => {
    this.lastVisitedPage = pathname;
  };

  setIsPhoneVerified = (value = true): void => {
    this.phoneVerified = value;
  };

  setUserEmail = (email = null): void => {
    this.email = email;
  };

  setCompanyVerificationStatus = (status: VerificationStatus): void => {
    this.companyVerificationStatus = status;
  };

  setIdentityVerificationStatus = (status: VerificationStatus): void => {
    this.identityVerificationStatus = status;
  };

  setNextVerificationStep = (status: Nullable<string>): void => {
    this.nextVerificationStep = status;
  };

  setCompanyName = (companyName: string): void => {
    this.companyName = companyName;
  };

  setUserName = (firstName: string, lastName: string): void => {
    this.firstName = firstName;
    this.lastName = lastName;
  };

  @computed get isVerifiedCompany(): boolean {
    const verificationStatus = VerificationStatus.VERIFIED.toUpperCase();

    return (
      this.companyVerificationStatus?.toUpperCase() === verificationStatus &&
      this.identityVerificationStatus?.toUpperCase() === verificationStatus &&
      !this.nextVerificationStep
    );
  }
}

export default UserStore;
