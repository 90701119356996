// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// components
import TransactionCardFooterActionItem from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooterActions/TransactionCardFooterActionItem';
// assets
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import { ModalIds } from 'shared/constants/modalIds';
import { ButtonColor } from 'components/Button';
import { useListContext } from 'components/list/ListContext';
import stall from 'shared/helpers/simulateAsyncCall';
import useInvoiceItems from 'components/invoices/useInvoiceItems';

interface IRejectPayableInvoice {
  invoiceId: string;
}

const RejectPayableInvoice: React.FC<IRejectPayableInvoice> = ({ invoiceId }: IRejectPayableInvoice) => {
  const {
    modalStore: { openModal },
  } = useStore();
  const { deleteFromList = () => {} } = useListContext();
  const { deleteInvoiceItem } = useInvoiceItems();
  const { t } = useTranslation('transactionHistory');

  const rejectInvoice = useCallback(async () => {
    // TODO: add request to BE
    await stall();
    deleteInvoiceItem(invoiceId);
    deleteFromList(invoiceId);
  }, [invoiceId, deleteFromList, deleteInvoiceItem]);

  const showConfirmationModal = () => {
    openModal(ModalIds.confirmationModal, {
      title: t('invoiceActions.rejectConfirmationTitle'),
      cbConfirmActionFunction: rejectInvoice,
      cancelButtonText: t('invoiceActions.rejectCancelBtn'),
      confirmButtonText: t('invoiceActions.rejectConfirmBtn'),
      confirmButtonProps: { color: ButtonColor.danger },
    });
  };

  return (
    <TransactionCardFooterActionItem onClick={showConfirmationModal}>
      <TrashIcon width={10} height={10} className="trash-icon" />
      {t('invoiceActions.reject')}
    </TransactionCardFooterActionItem>
  );
};

export default observer(RejectPayableInvoice);
