// libraries
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import classnames from 'classnames';
// components
import Input from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextInput, { TextInputProps } from '../TextInput/TextInput';
import { InputCommonProps } from '../Input';
// styles
import styles from './ExpandableNumberInput.module.scss';
import { formatCurrency } from 'shared/helpers/format';

interface IExpandableNumberInput extends TextInputProps {
  classNamePrefix: string;
  classNameWrapper: string;
  inputClassName: string;
  spanHelperClassName: string;
  guide?: boolean;
  prefix?: string;
  allowDecimal?: boolean;
  integerLimit?: number;
}

/**
 * This component aligns to the middle of the input block and makes it dynamically expandable based on the input values.
 */
const ExpandableNumberInput: React.FC<IExpandableNumberInput> = (props: IExpandableNumberInput) => {
  const {
    value = '',
    prefix = '',
    allowDecimal = true,
    integerLimit,
    classNameWrapper,
    classNamePrefix,
    inputClassName,
    spanHelperClassName,
    guide = false,
    placeholder = '0.00',
  } = props;
  const helperSpan = React.createRef<HTMLSpanElement>();
  const [width, setWidth] = useState<string>('78px');
  const [inputValue, setInputValue] = useState(value);

  const numberMask = useMemo(
    () =>
      createNumberMask({
        prefix: '',
        allowDecimal,
        integerLimit,
      }),
    [allowDecimal, integerLimit],
  );

  useEffect(() => {
    if (helperSpan) {
      const helperWidth = helperSpan.current?.offsetWidth || 78;
      setWidth(`${Math.max(12, helperWidth + 1)}px`);
    }
  }, [value, helperSpan]);

  const InputElement = ({ onChange, value: defaultValue = '', ...inputCommonProps }: InputCommonProps) => {
    const onChangeCallback = useCallback(
      event => {
        let newValue = event.target.value;

        if (+(newValue as string).replace(/[^\d.]/g, '') === 0) {
          newValue = '';
        }

        if (!inputValue) {
          newValue = formatCurrency(+(newValue as string).replace(/[^\d.]/g, ''), false).slice(1);
        }

        setInputValue(newValue);

        if (onChange) {
          onChange({
            ...event,
            target: {
              ...event.target,
              value: +(newValue as string).replace(/[^\d.]/g, ''),
            },
          });
        }
      },
      [onChange],
    );

    return (
      <Input
        {...inputCommonProps}
        mask={numberMask}
        guide={guide}
        value={inputValue || defaultValue}
        onChange={onChangeCallback}
      />
    );
  };

  return (
    <div className={classnames(styles['number-input-center__wrapper'], classNameWrapper)}>
      {prefix && <span className={classNamePrefix}>{prefix}</span>}

      <TextInput
        {...props}
        value={inputValue}
        inputElement={InputElement}
        className={inputClassName}
        placeholder={placeholder}
        style={{ width }}
      />

      <span ref={helperSpan} className={classnames(styles['helper-span'], spanHelperClassName)}>
        {inputValue}
      </span>
    </div>
  );
};

export default ExpandableNumberInput;
