import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
// components
import ShortListsModalHeader from './ShortListsModalHeader/ShortListsModalHeader';
import ShortListEmptyText from './ShortListEmptyText/ShortListEmptyText';
import ShortEntityCard from '../ShortEntityCard/ShortEntityCard';
import EntityLogo from '../ShortEntityCard/EntityLogo';
import Button from '../Button';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import { getMockCompanies } from '../company/CompanySearch/mockData';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
// styles
import styles from './ShortList.module.scss';

const ShortList: React.FC = () => {
  const { t } = useTranslation('shortLists');

  const {
    // TODO: Use to get the list you want
    shortListsStore: { shortListType },
    modalStore: { closeAllModals },
  } = useStore();
  const history = useHistory();

  // TODO: need update after integration with BE
  const clients = getMockCompanies().slice(0, 10) as Partial<IBusinessSnapshot>[];

  const isEmptyClients = useMemo(() => !!clients.length, [clients]);

  return (
    <div className={styles['short-list__wrapper']}>
      <ShortListsModalHeader />
      <div className={styles['short-list-companies__wrapper']}>
        {!isEmptyClients && <ShortListEmptyText shortListType={shortListType} />}
        {isEmptyClients &&
          clients.map(({ dbaName = '', industry = '', locationCity, logoImage = '', id }) => (
            <ShortEntityCard
              className={styles['short-list__item']}
              key={dbaName}
              message={dbaName}
              helpText={`${industry} ${locationCity ? `${industry ? ' • ' : ''} ${locationCity}` : ''}`}
              logo={<EntityLogo logoSrc={logoImage} />}
              additionalInfo={
                <Button
                  btnLink
                  className={styles['short-list-link-company']}
                  handleClick={() => {
                    closeAllModals();
                    history.push(`/companies/${id}`);
                  }}
                >
                  {t('visitProfile')}
                </Button>
              }
            />
          ))}
      </div>
    </div>
  );
};

export default observer(ShortList);
