// libraries
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import classnames from 'classnames';
// types
import { UsePopperOptions } from 'react-overlays/usePopper';
// assets
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down-icon.svg';
// styles
import './DropdownButton.scss';

interface DropdownButtonProps {
  hasArrow?: boolean;
  toggle?: React.ReactNode;
  children: React.ReactNode;
  menuPosition?: 'up' | 'left' | 'right' | 'down';
  className?: string;
  popperConfig?: Omit<UsePopperOptions, 'enabled' | 'placement'>;
  alignRight?: boolean;
  toggleButtonRef?: React.MutableRefObject<Nullable<HTMLDivElement>>;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  hasArrow,
  toggle,
  children,
  menuPosition,
  className,
  popperConfig = {},
  alignRight = false,
  toggleButtonRef,
}: DropdownButtonProps) => {
  const CustomToggle = React.forwardRef(
    ({ onClick }: { onClick: () => void }, ref: React.ForwardedRef<HTMLDivElement>) => (
      <div
        ref={ref}
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
        className="d-flex align-items-center dropdown-button__wrapper"
      >
        {toggle}
        {hasArrow && <ArrowIcon width={14} className="dropdown-button__arrow-icon" />}
      </div>
    ),
  );

  return (
    <div className={classnames('dropdown-button', className)}>
      <Dropdown alignRight={alignRight} drop={menuPosition}>
        <Dropdown.Toggle ref={toggleButtonRef} as={CustomToggle} />
        <Dropdown.Menu popperConfig={popperConfig} className="dropdown-button__menu">
          <div className="dropdown-button__menu-wrapper">{children}</div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownButton;
