// api
import FileUploadApi, { uploadFileRequest } from 'components/Form/inputs/FileUpload/api/FileUploadApi';
// constants
import { FileName, FilePath } from 'shared/constants/fileupload';
// helpers
import { getUrlPathname } from 'shared/helpers/api';
import { FileItem } from 'components/Form/inputs/FileUpload/useFileUpload';

export const uploadFile = async (file: File, path: FilePath, name: FileName) => {
  try {
    const fileData = file.name.split('.');
    const fileDescription = fileData[0] || '';
    const fileExtension = fileData[1] || '';

    const { url } = (await FileUploadApi.getUploadLinkRequest({
      type: file.type,
      name: `${name}.${fileExtension}`,
      path,
    })) as Dictionary<string>;

    await uploadFileRequest(url, file, file.type);

    return {
      path: getUrlPathname(url).replace('/', ''),
      description: fileDescription,
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const uploadFiles = async (files: FileItem[]) => {
  let filesData: Dictionary<string>[] = [];

  try {
    filesData = await Promise.all(files.map(({ file, path, name }) => uploadFile(file, path, name)));
    return filesData;
  } catch (e) {
    return Promise.reject(e);
  }
};
