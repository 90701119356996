import { DateFormats, MINUTES_IN_HOUR, MINUTES_IN_DAY, MINUTES_IN_WEEK } from 'shared/helpers/format';
import { format, differenceInWeeks, differenceInMinutes, differenceInMonths } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export const getTimeSince = (date: number): string => {
  const now = new Date();
  const timeDifference = differenceInMinutes(now, date);

  if (timeDifference < 1) {
    return 'Just Now';
  }

  if (timeDifference < MINUTES_IN_HOUR) {
    return `${timeDifference} minutes ago`;
  }

  if (timeDifference < MINUTES_IN_DAY) {
    const minuteDifference = Math.round(timeDifference / MINUTES_IN_HOUR);

    return `${minuteDifference} hour${minuteDifference === 1 ? '' : 's'} ago`;
  }

  if (timeDifference < MINUTES_IN_WEEK) {
    const dayDifference = Math.round(timeDifference / MINUTES_IN_DAY);

    return `${dayDifference} day${dayDifference === 1 ? '' : 's'} ago`;
  }

  const weekDifference = differenceInWeeks(now, date);

  if (weekDifference <= 4) {
    return `${weekDifference} week${weekDifference === 1 ? '' : 's'} ago`;
  }

  const monthDifference = differenceInMonths(now, date);

  if (monthDifference === 1) {
    return `${monthDifference} month ago`;
  }

  return format(date, DateFormats.fullDateTime);
};
