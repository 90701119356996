// libraries
import React, { PropsWithChildren } from 'react';
// styles
import styles from './SettingsLayout.module.scss';

type SettingsLayoutProps = PropsWithChildren<{
  titleBlock: string | JSX.Element;
}>;

const SettingsLayout: React.FC<SettingsLayoutProps> = ({ children = null, titleBlock = '' }) => {
  return (
    <div className={styles['settings-layout']}>
      <div className={styles['settings-layout__title-block']}>{titleBlock}</div>
      <div className={styles['settings-layout__content']}>{children}</div>
    </div>
  );
};

export default SettingsLayout;
