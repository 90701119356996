// libraries
import React from 'react';
import { NavLink, Route } from 'react-router-dom';
// components
import Nav, { NavItem } from 'components/Nav/Nav';
// styles
import styles from 'components/SideNav/index.module.scss';

interface SideNavProps {
  menuMap: NavItem[];
}

const SideNav: React.FC<SideNavProps> = ({ menuMap }: SideNavProps) => {
  return (
    <Nav
      menuData={menuMap}
      mapItemFunc={({ name, path, exact, icon, activeIcon }) => {
        return (
          <Route key={name} path={path} exact={exact}>
            {({ match }) => (
              <div>
                <NavLink
                  key={name}
                  className={styles['side-nav__link']}
                  activeClassName={styles['side-nav__link--active']}
                  to={path}
                  exact={exact}
                >
                  <span className={styles['side-nav__icon']}>{match ? activeIcon : icon}</span>
                  {name}
                </NavLink>
              </div>
            )}
          </Route>
        );
      }}
      style={styles['side-nav']}
    />
  );
};

export default SideNav;
