// libraries
import React, { MouseEvent } from 'react';
import classNames from 'classnames';
// assets
import { ReactComponent as Calendar } from 'assets/icons/datepicker.svg';
// styles
import styles from './DateInput.module.scss';

type TCustomInputProps = {
  value?: string;
  onClick?: (event: MouseEvent) => void;
};

const CustomInput: React.FC<TCustomInputProps> = ({ value, onClick, ...defaultProps }: TCustomInputProps) => (
  <div
    className={classNames(styles['date-input'], {
      [styles['datepicker--empty']]: !value,
    })}
  >
    <input value={value} {...defaultProps} />
    <Calendar className={styles['date-input__icon']} onClick={onClick} />
  </div>
);

export default CustomInput;
