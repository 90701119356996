import React, { useContext } from 'react';
import yup from 'yup';

export interface Form {
  data?: Nullable<FormData>;
  errors?: FormErrors;
  isFormValidated?: boolean;
  onChangeCallback?: (path: string, values: FormFieldValue) => void;
  onSubmitForm?: () => void;
  resetForm?: () => void;
  validationSchema?: yup.ObjectSchema;
  isSubmitEnabled?: boolean;
  isDirty?: boolean;
  getErrorValue?: (fieldName: string) => string[];
  getFieldValue?: (fieldName: string) => Nullable<FormFieldValue>;
  validateForm?: (isDirtyForm?: boolean) => Promise<FormErrors>;
  validateField?: (fieldName: string, values?: FormData) => Promise<string[]>;
  setFieldErrors?: (fieldName: string, value: string[]) => void;
  setFieldValues?: (fieldName: string, value: Nullable<FormFieldValue>, shouldUpdateFormState?: boolean) => void;
  isValidForm?: (errors?: FormErrors) => boolean;
  className?: string;
  canBeEmpty?: boolean;
  autoComplete?: string;
  setIsFormValidated?: (value: boolean) => void;
}

export interface FormContextData extends Form {
  formType?: string;
  submitForm?: (data: FormData) => void;
  setData?: (data: FormData) => void;
  setErrors?: (errors: FormErrors) => void;
  children?: React.ReactNode;
}

export type FormFieldValue = any;
export type FormData = Dictionary<FormFieldValue>;
export type FormErrors = Dictionary<string[]>;

export const FormContext = React.createContext<Partial<FormContextData>>({});

export const FormProvider = FormContext.Provider;

export const useFormContext: () => Partial<FormContextData> = () => useContext<Partial<FormContextData>>(FormContext);
