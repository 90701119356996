// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import EntryTitle from 'components/EntryTitle/EntryTitle';
import EmailForm from 'components/signUpForms/EmailForm/EmailForm';
// constants
import { EmailFormData } from 'components/signUpForms/useSignUpForm';

interface IEmailProps {
  submitEmailForm: (data: EmailFormData) => void;
}

const Email: React.FC<IEmailProps> = ({ submitEmailForm }: IEmailProps) => {
  const { t } = useTranslation('signUpPage');

  return (
    <>
      <EntryTitle
        link="/login"
        linkText={t('emailForm.linkText')}
        subtitle={t('emailForm.subtitle')}
        title={t('emailForm.title')}
      />
      <EmailForm submitEmailForm={submitEmailForm} />
    </>
  );
};

export default Email;
