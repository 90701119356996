import React, { useState, useCallback } from 'react';
import Input from 'react-text-mask';
import TextInput, { TextInputProps } from './TextInput/TextInput';
import { InputCommonProps } from './Input';
import { PHONE_CODE_POSITION, USA_PHONE_CODE, PHONE_MASK } from 'shared/constants';

const defaultFormatChars: { [index: string]: RegExp } = {
  '9': /[0-9]/,
  a: /[A-Za-z]/,
  '*': /[A-Za-z0-9]/,
};

type MaskedInputProps = TextInputProps & {
  mask: string;
  isMaskedValueSaved?: boolean;
  guide?: boolean;
};

const MaskedInput: React.FC<MaskedInputProps> = (props: MaskedInputProps) => {
  const [maskPrefix, setMaskPrefix] = useState('');
  const { mask = '', isMaskedValueSaved = false, guide = false, value = '' } = props;
  let maskConstant = '';

  const valueChars: Nullable<string>[] = [];
  const maskArray = mask.split('').map((char: string, index: number) => {
    const charFormat = defaultFormatChars[char];

    if (mask === PHONE_MASK && char === USA_PHONE_CODE && index === PHONE_CODE_POSITION) {
      maskConstant += char;
      valueChars.push(char);
      return char;
    }

    if (!charFormat && maskConstant.length === index) {
      maskConstant += char;
    }

    if (charFormat) {
      valueChars.push(char);
      return charFormat;
    }

    valueChars.push(null);
    return defaultFormatChars['*'].test(char) ? new RegExp(char) : char;
  });

  const [inputValue, setInputValue] = useState(value);

  const InputElement = ({ onChange, onBlur, onFocus, value: defaultValue, ...inputCommonProps }: InputCommonProps) => {
    const onFocusCallback = useCallback(
      e => {
        setMaskPrefix(!e.target.value ? maskConstant : '');
        if (!inputValue) {
          setInputValue(maskPrefix);
        }

        if (onFocus) {
          onFocus(e);
        }
      },
      [onFocus],
    );

    const onBlurCallback = useCallback(
      event => {
        setMaskPrefix('');

        if (inputValue === maskConstant) {
          setInputValue('');
        }

        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur],
    );

    const onChangeCallback = useCallback(
      event => {
        let newValue = event.target.value;

        if (newValue && newValue !== '' && maskConstant.length <= newValue.length) {
          setMaskPrefix('');
          setInputValue(newValue);
        } else {
          setInputValue(maskConstant);
        }

        if (!isMaskedValueSaved) {
          newValue = newValue
            .split('')
            .filter((char: string, index: number) => valueChars[index])
            .join('');
        }

        if (onChange) {
          onChange({
            ...event,
            target: {
              ...event.target,
              // undefined is needed for validation 'not required' string values with fixed length
              value: newValue || undefined,
            },
          });
        }
      },
      [onChange],
    );

    return (
      <Input
        {...inputCommonProps}
        mask={maskArray}
        guide={guide}
        value={maskPrefix || (inputValue as string) || defaultValue}
        onBlur={onBlurCallback}
        onFocus={onFocusCallback}
        onChange={onChangeCallback}
      />
    );
  };

  return <TextInput {...props} inputElement={InputElement} />;
};

export default MaskedInput;
