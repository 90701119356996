// libraries
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
// constants
import { PayableInvoiceSteps } from 'components/transactions/TransactionDetails/PayableInvoiceDetails/constants';
// components
import PayableInvoiceFormContent from './PayableInvoiceFormContent';
import Form from 'components/Form/index';
// helpers
import { Invoice, InvoiceStates } from 'components/invoices/types';
import { useListContext } from 'components/list/ListContext';
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
import PayInvoiceValidationSchema from './validation-rules';
import stall from 'shared/helpers/simulateAsyncCall';
import useInvoiceItems from 'components/invoices/useInvoiceItems';
import { IConnectedAccountsData } from 'components/transactions/TransactionDetails';
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './PayableInvoiceForm.module.scss';

interface IPayableInvoiceForm {
  invoiceDetails: Invoice;
  connectedAccountsData?: IConnectedAccountsData;
}

const PayableInvoiceForm: React.FC<IPayableInvoiceForm> = ({
  invoiceDetails,
  connectedAccountsData,
}: IPayableInvoiceForm) => {
  const [forceSubmit, setForceSubmit] = useState(false);
  const { updateInList = () => {} } = useListContext();
  const { currentStep, goToNextStep, goToStep } = useMultiStepContext();
  const { updateInvoiceItem } = useInvoiceItems();
  const { accounts = [], isPlaidAvailable = false, openPlaidModal = () => {} } = connectedAccountsData || {};
  const {
    myBusinessSnapshotStore: { isVerifiedCompany },
  } = useStore();

  useEffect(() => {
    setForceSubmit(false);
  }, [invoiceDetails]);

  const submitForm = useCallback(
    async data => {
      if (!data.payDate && !forceSubmit) {
        setForceSubmit(true);
        return;
      }

      const payDate = data.payDate ? new Date(data.payDate) : Date.now();

      if (currentStep === PayableInvoiceSteps.FORM_STEP && goToNextStep) {
        goToNextStep(currentStep, {});
        return;
      }

      // TODO: make request to BE
      await stall();

      updateInList(invoiceDetails?.invoiceId, {
        ...invoiceDetails,
        invoiceState: InvoiceStates.scheduled,
        payDate,
      });
      updateInvoiceItem(invoiceDetails?.invoiceId, {
        invoiceState: InvoiceStates.scheduled,
        payDate,
      });

      if (goToStep) {
        goToStep(0);
      }
    },

    [currentStep, forceSubmit, invoiceDetails, updateInList],
  );

  const handleSubmit = useCallback(
    data => {
      if (accounts.length || isVerifiedCompany) {
        return submitForm(data);
      }

      if (isPlaidAvailable) {
        return openPlaidModal();
      }

      return null;
    },
    [openPlaidModal, accounts.length, isPlaidAvailable, isVerifiedCompany, submitForm],
  );

  return (
    <Form
      className={styles['payable-form']}
      canBeEmpty
      submitForm={handleSubmit}
      validationSchema={PayInvoiceValidationSchema}
    >
      <PayableInvoiceFormContent
        invoiceDetails={invoiceDetails}
        forceSubmit={forceSubmit}
        setForceSubmit={setForceSubmit}
        hasConnectedAccounts={!!accounts.length}
        isDisabled={!isVerifiedCompany && !accounts.length && !isPlaidAvailable}
        isVerifiedCompany={isVerifiedCompany}
      />
    </Form>
  );
};

export default observer(PayableInvoiceForm);
