// libraries
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// store
import { useStore } from 'shared/stores/RootStore/useStore';
// helpers
import { ModalIds } from 'shared/constants/modalIds';
// styles
import styles from './PasswordSuccess.module.scss';
// assets
import { ReactComponent as SuccessIcon } from 'assets/icons/verification-success.svg';

const INTERVAL_BEFORE_REDIRECT = 3000;

const PasswordSuccess = () => {
  const { t } = useTranslation('passwordReset');
  const history = useHistory();
  const {
    modalStore: { closeModal, isOpen },
  } = useStore();

  const isModalOpened = isOpen(ModalIds.successPasswordChange);

  useEffect(() => {
    if (!isModalOpened) return;

    setTimeout(() => {
      closeModal(ModalIds.successPasswordChange);
      history.push('/login');
    }, INTERVAL_BEFORE_REDIRECT);
  }, [isModalOpened]);

  if (!isModalOpened) {
    return null;
  }

  return (
    <div className={styles['content-block']}>
      <SuccessIcon />
      <div className={styles.title}>{t('successModal.title')}</div>
      <div className={styles.farewell}>{t('successModal.subtitle')}</div>
    </div>
  );
};

export default observer(PasswordSuccess);
