// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { MenuPlacement } from 'react-select';
import classNames from 'classnames';
// components
import { TextInput, MaskedInput } from 'components/Form/inputs';
import AutocompleteInput from 'components/Form/inputs/AutocompleteInput/AutocompleteInput';
import Select from 'components/Form/inputs/Select/Select';
// helpers
import { STATES } from 'shared/constants/states';
import { COUNTRIES, USA_COUNTRY } from 'shared/helpers/countries';
import { useFormContext } from 'components/Form/FormContext';
import { PHONE_MASK } from 'shared/constants';
// styles
import styles from './AddressField.module.scss';

export enum AddressFieldType {
  DEFAULT = 'default',
  EXTENDED = 'extended',
}

export enum AddressFieldFormatTheme {
  DEFAULT = 'default',
  VERIFICATION = 'verification',
}

type AddressFieldProps = {
  isUsaOnly?: boolean;
  menuPlacement?: MenuPlacement;
  header?: string;
  name?: string;
  fieldType?: AddressFieldType;
  title?: string;
  formatTheme?: AddressFieldFormatTheme;
  headerClassName?: string;
};

const AddressField: React.FC<AddressFieldProps> = ({
  isUsaOnly = false,
  menuPlacement,
  header,
  name,
  fieldType = AddressFieldType.DEFAULT,
  title,
  formatTheme = AddressFieldFormatTheme.DEFAULT,
  headerClassName,
}: AddressFieldProps) => {
  const { t } = useTranslation('forms');
  const { data, setFieldValues } = useFormContext();

  const namePrefix = name ? `${name}.` : '';

  const isUSA = get(data, `${namePrefix}country`) === USA_COUNTRY;

  const titleStyles = classNames(styles.field__title, {
    [styles['field__title--big']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
  });

  if (isUSA && setFieldValues) {
    setFieldValues(`${namePrefix}region`, '');
    setFieldValues(`${namePrefix}postalCode`, '');
  }

  if (!isUSA && setFieldValues) {
    setFieldValues(`${namePrefix}state`, '');
    setFieldValues(`${namePrefix}zip`, '');
  }

  return (
    <>
      <div
        className={classNames(styles.field__row, {
          [styles['field__row--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
        })}
      >
        <div className={headerClassName}>
          {header ? (
            <div className={styles['field__content-header']}>{header}</div>
          ) : (
            <div>
              <div className={titleStyles}>{title || t('address.businessAddress')}</div>
              <div
                className={classNames(styles.field__subtitle, {
                  [styles['field__subtitle--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
                })}
              >
                {t('address.businessAddressHint')}
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames({
            [styles.autocomplete__field]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
          })}
        >
          <AutocompleteInput
            name={`${namePrefix}address`}
            namePrefix={name}
            errorLabel={header || title || t('address.businessAddress')}
          />
        </div>
      </div>

      <div
        className={classNames(styles.field__row, {
          [styles['field__row--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
        })}
      >
        <div>{t('address.line1')}</div>
        <TextInput name={`${namePrefix}line1`} />
      </div>

      {fieldType === AddressFieldType.EXTENDED && (
        <div className={styles.field__row}>
          <div>{t('address.line2')}</div>
          <TextInput name={`${namePrefix}line2`} />
        </div>
      )}

      <div
        className={classNames(styles.field__row, {
          [styles['field__row--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
        })}
      >
        <div>{t('address.city')}</div>
        <TextInput name={`${namePrefix}city`} />
      </div>

      {isUSA ? (
        <div
          className={classNames(styles.field__row, {
            [styles['field__row--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
          })}
        >
          <div>{t('address.state')}</div>
          <Select name={`${namePrefix}state`} options={STATES} menuPlacement={menuPlacement} />
        </div>
      ) : (
        <div
          className={classNames(styles.field__row, {
            [styles['field__row--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
          })}
        >
          <div>{t('address.region')}</div>
          <TextInput name={`${namePrefix}region`} />
        </div>
      )}

      {!isUsaOnly && (
        <div
          className={classNames(styles.field__row, {
            [styles['field__row--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
          })}
        >
          <div>{t('address.country')}</div>
          <Select
            name={`${namePrefix}country`}
            disabled={isUsaOnly}
            options={COUNTRIES}
            menuPlacement={menuPlacement}
          />
        </div>
      )}

      {isUSA ? (
        <div
          className={classNames(styles.field__row, {
            [styles['field__row--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
          })}
        >
          <div>{t('address.zip')}</div>
          <TextInput name={`${namePrefix}zip`} />
        </div>
      ) : (
        <div
          className={classNames(styles.field__row, {
            [styles['field__row--verification']]: formatTheme === AddressFieldFormatTheme.VERIFICATION,
          })}
        >
          <div>{t('address.postalCode')}</div>
          <TextInput name={`${namePrefix}postalCode`} />
        </div>
      )}

      {fieldType === AddressFieldType.EXTENDED && (
        <div className={styles.field__row}>
          <div>{t('address.phone')}</div>
          <MaskedInput name={`${namePrefix}phone`} mask={PHONE_MASK} />
        </div>
      )}
    </>
  );
};

export default AddressField;
