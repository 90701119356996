// libraries
import { useCallback, useState } from 'react';
// api
import { AccountType } from 'components/paymentMethods/api/PaymentMethodApi';
// context
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
import { FormData } from 'components/Form/FormContext';
// helpers
import { PaymentMethodStep } from 'components/paymentMethods/PaymentMethod/usePaymentMethod';
import usePlaid from 'components/paymentMethods/PaymentMethod/PaymentOptions/usePlaid';

type PaymentOptionsParams = {
  isPlaidAvailable: boolean;
  formData: FormData;
  submitForm: (data: FormData) => void;
};

const usePaymentOptions = (): PaymentOptionsParams => {
  const { openPlaidModal, isPlaidAvailable } = usePlaid();
  const { goToNextStep } = useMultiStepContext();
  const [formData, setFormData] = useState<FormData>({ type: AccountType.BANK });

  const submitForm = useCallback(
    data => {
      setFormData({ ...data });

      if (data.type === AccountType.CREDIT_CARD && goToNextStep) {
        goToNextStep(PaymentMethodStep.OPTIONS, {});
        return;
      }

      openPlaidModal();
    },
    [openPlaidModal, goToNextStep],
  );

  return {
    isPlaidAvailable,
    formData,
    submitForm,
  };
};

export default usePaymentOptions;
