import psl, { ParsedDomain } from 'psl';
import { useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

interface SearchParamsInterface {
  getSearchParameter: (key: string) => string;
}

// eslint-disable-next-line
export const getCurrentDomain = (): Nullable<string> => {
  const currentHostname = window.location.hostname;

  const parsed = psl.parse(currentHostname) as ParsedDomain;

  if (parsed?.tld === 'cloudfront.net') return parsed?.tld;

  return parsed?.domain || null;
};

export const useSearchParams = (): SearchParamsInterface => {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const getSearchParameter = useCallback((key: string) => searchParams.get(key) as string, [searchParams]);

  return {
    getSearchParameter,
  };
};
