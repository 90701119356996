import { useCallback, useEffect } from 'react';
import { IBusinessSnapshot } from 'shared/stores/BusinessSnapshotStore/types';
import { getHooliMockProfile } from 'components/company/CompanyDetails/mockData';
import { useStore } from 'shared/stores/RootStore/useStore';

export interface UserDetailsData {
  myBusinessProfile: Nullable<IBusinessSnapshot>;
  updateData: (data: Partial<IBusinessSnapshot>) => void;
  reloadData: () => void;
}

const useMyBusinessProfile = (): UserDetailsData => {
  const {
    authStore: { sessionId },
    myBusinessSnapshotStore: { myBusinessProfile, setDataFromMessage },
    myBusinessProfilesStore: { myBusinessId },
  } = useStore();

  const loadData = useCallback(
    (isForced = false) => {
      // TODO: need remove after integration
      if (!myBusinessProfile || isForced) setDataFromMessage(getHooliMockProfile() as IBusinessSnapshot);

      // TODO: temporary commented because this integration must be checked on BE
      // if ((!myBusinessProfile || isForced) && myBusinessId && sessionId) {
      // sendMessage(
      //   MessageTypes.REQUEST,
      //   Endpoints.GET_BUSINESS_SNAPSHOT,
      //   {
      //     bizId: myBusinessId,
      //   },
      //   sessionId,
      // );
      // }
    },
    [myBusinessProfile],
  );

  useEffect(() => {
    loadData();

    // Suppressed warnings because we should send message to BE only if updated businessId or sessionId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBusinessId, sessionId]);

  const updateData = useCallback(
    (data: Partial<IBusinessSnapshot> = {}) => {
      setDataFromMessage({ ...myBusinessProfile, ...data } as IBusinessSnapshot);
    },
    [setDataFromMessage, myBusinessProfile],
  );

  const reloadData = useCallback(() => {
    loadData(true);
  }, [loadData]);

  return {
    myBusinessProfile,
    updateData,
    reloadData,
  };
};

export default useMyBusinessProfile;
