import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Form from 'components/Form';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import Button from 'components/Button';
import OneTimeCodeInput from 'components/Form/inputs/OneTimeCodeInput/OneTimeCodeInput';
// helpers
import useTwoFactorAuthForm from './twoFactoAuthFormHelper';
import TFA_FORM_SCHEMA from './validation-rules';
// styles
import styles from './TwoFactorAuthForm.module.scss';

type TfaFormProps = {
  isTitleExist?: boolean;
  isPhoneVerification?: boolean;
};

const TwoFactorAuthForm: React.FC<TfaFormProps> = ({ isPhoneVerification = false }) => {
  const { t } = useTranslation('twoFactorAuth');
  const { verifyTfa, email, changeTokenCallback, isDisabledBtn, sendNewCode } = useTwoFactorAuthForm();

  return (
    <Form className={styles['form-tfa']} submitForm={verifyTfa} validationSchema={TFA_FORM_SCHEMA}>
      <div className={styles['form-column']}>
        <h3>{t('title')}</h3>
        <p className={styles.subtitle}>{t('subtitle')}</p>
        <p className={styles.email}>{email}</p>

        <OneTimeCodeInput
          fieldHeight={75}
          fieldWidth={69}
          label={t('formFields.code')}
          name="token"
          onChangeCallback={changeTokenCallback}
          fieldLabelClassName={styles['code-field__label']}
          fieldWrapperClassName={styles['code-field__wrapper']}
        />

        <SubmitButton disabled={isDisabledBtn} className={styles['button-send-code']}>
          {t('submitButton')}
        </SubmitButton>

        <div className={styles['resend-wrapper']}>
          {t('resend.text')}
          <Button btnLink className={styles['button-resend-code']} handleClick={sendNewCode}>
            {t('resend.resendBtn')}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default TwoFactorAuthForm;
