// libraries
import React from 'react';
// components
import DropdownButton from 'components/Button/DropdownButton/DropdownButton';
// assets
import { ReactComponent as DotsIcon } from 'assets/icons/action-dots-icon.svg';
// styles
import './TransactionCardFooterActions.scss';

interface ITransactionCardFooterActions {
  children?: React.ReactNode;
}

const TransactionCardFooterActions: React.FC<ITransactionCardFooterActions> = ({
  children,
}: ITransactionCardFooterActions) => {
  const toggle = (
    <div className="transaction-card-footer__actions-btn">
      <DotsIcon />
    </div>
  );

  return (
    <DropdownButton toggle={toggle} menuPosition="up" className="transaction-card-footer__actions-wrapper">
      {children}
    </DropdownButton>
  );
};

export default TransactionCardFooterActions;
