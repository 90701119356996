import Home from 'router/views/Home/Home';
import authRoutes from './auth';
import serviceRoutes from './service-routes';
import firstLevelRoutes from './first-level-routes';
import externalPayments from './external-payments';
import proxyRoutes from './proxy-routes';

export default [
  {
    path: '/',
    exact: true,
    component: Home,
    name: 'Home',
  },
  ...authRoutes,
  ...firstLevelRoutes,
  ...externalPayments,
  ...proxyRoutes,
  ...serviceRoutes,
];
