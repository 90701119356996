// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Collapse from 'components/Collapse/Collapse';
import InAppNotification from 'components/notifications/InApp/InAppNotification/InAppNotification';
// helpers
import useInAppNotifications from 'components/notifications/InApp/useInAppNotifications';
// styles
import './InAppNotifications.scss';

const InAppNotifications: React.FC = () => {
  const { t } = useTranslation('notifications');
  const { notifications } = useInAppNotifications();

  return (
    <Collapse
      idSource="notificationId"
      className="in-app-notifications"
      title={t('inApp.title')}
      linkForViewAllBtn="/notifications"
      data={notifications}
      maxItemNumber={7}
      mapItemFunc={notificationProps => <InAppNotification {...notificationProps} />}
    />
  );
};

export default InAppNotifications;
