// libraries
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Location, State } from 'history';
// helpers
import { IDefaultListParams, IListParams, SortOrder, SortParam } from 'components/list/ListContext';
import { ASC, DESC, SORT_ORDERS, DEFAULT_SORT_ORDER } from 'components/list/listHelper';
import { buildQueryString } from 'shared/helpers/api';

const getUrlStorageParams = (location: Location<State>): IListParams => {
  const currentSearchParams = new URLSearchParams(location.search) || {};
  let sortOrder = '';
  let sortField;

  const allParamsInUrl: IListParams = {};

  currentSearchParams.forEach(function (value, key) {
    if (key === 'sortOrder') {
      sortOrder = SORT_ORDERS.includes(value) ? value : '';
    } else if (key === 'sortBy') {
      sortField = value;
    } else {
      allParamsInUrl[key] = value;
    }
  });

  return {
    ...allParamsInUrl,
    sort: {
      field: sortField,
      order: sortOrder,
    },
  };
};

const getInitialParams = (
  location: Location<State>,
  { defaultSortField, defaultSortOrder }: IDefaultListParams,
): IListParams => {
  const currentSearchParams = getUrlStorageParams(location);

  return {
    ...currentSearchParams,
    sort: {
      field: currentSearchParams.sort?.field || defaultSortField,
      order: currentSearchParams.sort?.order || defaultSortOrder || DEFAULT_SORT_ORDER,
    },
  };
};

export interface IListParamsProps {
  defaultSortField?: string;
  defaultSortOrder?: SortOrder;
  shouldResetParams?: boolean;
}

const useListParams = ({ defaultSortField = '', defaultSortOrder = '', shouldResetParams }: IListParamsProps) => {
  const { location, ...history } = useHistory();

  const [params, setParams] = useState(
    getInitialParams(location, {
      defaultSortField,
      defaultSortOrder,
    }),
  );

  const updateParamsInUrl = ({ sort = {}, search }: IListParams) => {
    const { sort: oldSortValue, ...urlStorageParams } = getUrlStorageParams(location);
    const searchString = buildQueryString({ ...urlStorageParams, search, sortBy: sort.field, sortOrder: sort.order });

    if (location.search !== searchString) {
      history.replace({
        search: searchString,
      });
    }
  };

  const updateParams = (newParams: IListParams) => {
    setParams((prev: IListParams) => ({
      ...prev,
      ...newParams,
    }));
  };

  const searchBy = (query: string) => {
    updateParams({
      search: query,
    });
  };

  const sortBy = (key?: string, order?: SortOrder) => {
    const result: SortParam = {
      field: key,
    };
    const { sort = {} } = params;

    if (!order) {
      if (key === sort.field) {
        result.order = sort.order === ASC ? DESC : ASC;
      } else {
        result.order = ASC;
      }
    } else {
      result.order = order;
    }

    updateParams({
      sort: result,
    });
  };

  const resetParamsToDefault = () => {
    setParams({
      ...getInitialParams(location, {
        defaultSortField,
        defaultSortOrder,
      }),
      search: '',
      sort: {
        order: defaultSortOrder,
        field: defaultSortField,
      },
    });
  };

  useEffect(() => {
    if (shouldResetParams) {
      resetParamsToDefault();
    }
  }, [shouldResetParams]);

  useEffect(() => {
    updateParamsInUrl(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return {
    params,
    sortBy,
    searchBy,
  };
};

export default useListParams;
