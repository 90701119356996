// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/Button';
import EntityLogo from 'components/ShortEntityCard/EntityLogo';
// assets
import { ReactComponent as CheckMark } from 'assets/icons/check-mark.svg';
// constants
import AccountIntegrationTypes from 'shared/constants/AccountIntegrationTypes';
import { TAccount } from 'shared/stores/AccountIntegrationsStore/AccountIntegrationsStore';
// styles
import styles from './AccountIntegrationCard.module.scss';

type TAccountIntegrationCard = {
  img: string;
  account: TAccount;
  connectCallback: (type: AccountIntegrationTypes) => void;
  disconnectCallback: (type: AccountIntegrationTypes) => void;
};

const AccountIntegrationCard: React.FC<TAccountIntegrationCard> = ({
  img,
  account,
  connectCallback,
  disconnectCallback,
}: TAccountIntegrationCard) => {
  const { t } = useTranslation('settingAccountIntegrations');

  const connectHandler = useCallback(() => {
    connectCallback(account.type);
  }, [account.type, connectCallback]);

  const disconnectHandler = useCallback(() => {
    disconnectCallback(account.type);
  }, [account.type, disconnectCallback]);

  const IconElement = <CheckMark />;

  return (
    <div className={styles['account-integration__wrapper']}>
      <div className={styles['account-integration-info']}>
        <EntityLogo logoSrc={img} icon={account.isConnected ? IconElement : null} className={styles['company-logo']} />

        <div className={styles['details-account__wrapper']}>
          <b className={styles['account-name']}>{account.title}</b>

          <p className={styles['account-subtitle']}>{account.subtitle}</p>
        </div>
      </div>

      {account.isConnected ? (
        <Button className={styles['account-integration-btn']} btnLink btnDangerLink handleClick={disconnectHandler}>
          {t('disconnectBtn')}
        </Button>
      ) : (
        <Button className={styles['account-integration-btn']} btnLink handleClick={connectHandler}>
          {t('connectBtn')}
        </Button>
      )}
    </div>
  );
};

export default AccountIntegrationCard;
