// libraries
import React from 'react';
// styles
import styles from './ReviewField.module.scss';

type DataContainerProps = {
  title: string;
  value?: string;
};

const DataContainer: React.FC<DataContainerProps> = ({ value = '', title }: DataContainerProps) => {
  return (
    <div className={styles.data__container}>
      <h5 className={styles['data-title']}>{title}</h5>
      <span className={styles['data-value']}>{value}</span>
    </div>
  );
};

export default DataContainer;
