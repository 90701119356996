// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
// components
import TransactionCardField from './TransactionCardField';
// helpers
import { DateFormats } from 'shared/helpers/format';
// styles
import styles from './TransactionCardField.module.scss';

interface ITransferDateField {
  value: number | Date;
}

const TransferDateField: React.FC<ITransferDateField> = ({ value }: ITransferDateField) => {
  const { t } = useTranslation('transactionHistory');
  const date = useMemo(() => format(value, DateFormats.fullInvoiceDate), [value]);

  return (
    <TransactionCardField
      label={t('invoiceDetails.transferDate')}
      value={date}
      className={styles['transfer-date__wrapper']}
    />
  );
};

export default TransferDateField;
