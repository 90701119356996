// libraries
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// components
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// hooks
import { StepsNumber } from '../useTransferFunds';
import { useStore } from 'shared/stores/RootStore/useStore';
// styles
import styles from './Buttons.module.scss';

type TButtonsProps = {
  isAddFundsPopup: boolean;
  isDisabledSubmit: boolean;
  isLoading: boolean;
  isAmountError: boolean;
};

const TransferButtons: React.FC<TButtonsProps> = ({
  isLoading,
  isAddFundsPopup,
  isAmountError,
  isDisabledSubmit,
}: TButtonsProps) => {
  const { t } = useTranslation('accountingPage');
  const {
    transferFundsStore: { transferType },
    modalStore: { closeModal },
  } = useStore();

  const { currentStep, goToNextStep } = useMultiStepContext();

  const submitButtonText = useMemo(() => {
    if (currentStep === StepsNumber.FOUND) {
      return isAddFundsPopup ? `${transferType}` : transferType;
    }

    return t('transferFunds.confirm');
  }, [currentStep, t, isAddFundsPopup, transferType]);

  const handleCancelClick = useCallback(() => {
    closeModal(ModalIds.transferFunds);
  }, [closeModal]);

  const handleNextStepClick = useCallback(() => {
    if (goToNextStep) {
      goToNextStep(StepsNumber.FOUND, {});
    }
  }, [goToNextStep]);

  return (
    <div className={styles['button__wrapper-line']}>
      <div className={styles.button__wrapper}>
        {isAmountError && (
          <div className={styles['text-error__wrapper']}>
            <span className={classnames(styles['error-text-c'], styles['error-text-span'])}>{t('errorText')}</span>
          </div>
        )}

        <div className={styles.buttons__wrapper}>
          <Button outline className={styles.button__cancel} disabled={isLoading} handleClick={handleCancelClick}>
            {t('transferFunds.cancelButton')}
          </Button>
          {currentStep === StepsNumber.FOUND && (
            <Button className={styles.button__submit} disabled={isDisabledSubmit} handleClick={handleNextStepClick}>
              {submitButtonText}
            </Button>
          )}
          {currentStep === StepsNumber.CONFIRM && (
            <SubmitButton className={styles.button__submit} disabled={isDisabledSubmit}>
              {submitButtonText}
            </SubmitButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransferButtons;
