import { makeAutoObservable } from 'mobx';
import RootStore from 'shared/stores/RootStore/RootStore';

class FormStore {
  isFormDirty = false;

  isSubmitting = false;

  isFilesLoading = false;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setIsFormDirty = (isDirty: boolean): void => {
    this.isFormDirty = isDirty;
  };

  setIsFormSubmitting = (isSubmitting: boolean): void => {
    this.isSubmitting = isSubmitting;
  };

  setIsFilesLoading = (isLoading: boolean): void => {
    this.isFilesLoading = isLoading;
  };
}

export default FormStore;
