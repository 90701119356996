// libraries
import React from 'react';
import { Route, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
// components
import Nav from 'components/Nav/Nav';
import CompanyButton from 'components/MainNav/CompanyButton/CompanyButton';
import NotificationButton from 'components/MainNav/NotificationButton/NotificationButton';
// constants
import menuMap from './menuMap';
// helpers
import useNav from 'components/Nav/useNav';
// styles
import styles from './MainNav.module.scss';

const MainNav: React.FC = () => {
  const { t } = useTranslation('navigation');
  const { handleClick } = useNav();

  return (
    <>
      <Nav
        menuData={menuMap}
        mapItemFunc={({ path, name, activeIcon, icon, exact }) => (
          <Route key={name} path={path} exact={exact}>
            {({ match }) => (
              <NavLink
                onClick={e => handleClick(e, path)}
                key={`main-nav-${name}`}
                className={styles['main-nav__link']}
                activeClassName={styles['main-nav__link--active']}
                to={path}
                exact={exact}
              >
                <span className={styles['main-nav__icon-wrapper']}>{match ? activeIcon : icon}</span>
                {t(`main.${name}`)}
              </NavLink>
            )}
          </Route>
        )}
        style={styles['main-nav']}
      />
      <NotificationButton path="/notifications" exact />
      <CompanyButton />
    </>
  );
};

export default observer(MainNav);
