const scrollIfNeeded = (element?: Nullable<HTMLElement>, container?: Nullable<HTMLElement>): void => {
  if (!container || !element) {
    return;
  }

  if (element.offsetTop < container.scrollTop) {
    container.scrollTo({ top: element.offsetTop });
  } else {
    const offsetBottom = element.offsetTop + element.offsetHeight;
    const scrollBottom = container.scrollTop + container.offsetHeight;

    if (offsetBottom > scrollBottom) {
      container.scrollTo({ top: offsetBottom - container.offsetHeight, behavior: 'smooth' });
    }
  }
};

export default scrollIfNeeded;
