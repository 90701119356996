// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
// components
import BalanceMenu from './BalanceMenu/BalanceMenu';
// styles
import styles from './Balance.module.scss';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import { formatCurrency } from 'shared/helpers/format';

type BalanceProps = {
  className?: string;
};

const Balance: React.FC<BalanceProps> = ({ className }: BalanceProps) => {
  const { t } = useTranslation('balance');

  const {
    eventBalancesStore: { balancesData },
  } = useStore();

  return (
    <div className={classNames(styles.balance, className)}>
      <div className={styles.balance__title}>{t('hopscotchBalance')}</div>
      <div className={styles.balance__amount}>{formatCurrency(balancesData.zuroBalance, false)}</div>
      <hr />
      <BalanceMenu />
    </div>
  );
};

export default observer(Balance);
