const PLACEHOLDER = 'input';

const BLACK = '#000';
const GRAY = '#cfd2db';
const TRANSPARENT = 'transparent';

const OPTIONS = {
  placeholder: PLACEHOLDER,
  style: {
    base: {
      fontWeight: '300',
      height: '33px',
      backgroundColor: TRANSPARENT,
      color: BLACK,
      fontSize: '13px',
      lineHeight: '33px',
      ':focus': {
        color: BLACK,
      },
      '::placeholder': {
        color: GRAY,
      },
    },
    invalid: {
      color: BLACK,
    },
  },
};

export default OPTIONS;
