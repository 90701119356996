// libraries
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import { Account, AccountType } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
import useWindowSize, { DESKTOP_MIN_WIDTH } from 'shared/helpers/useWindowSize';
// styles
import styles from './AccountDetails.module.scss';

type TAccountDetails = {
  account: Account;
};

const AccountDetails: React.FC<TAccountDetails> = ({ account }: TAccountDetails) => {
  const { t } = useTranslation('paymentMethodsSettings');

  const { width } = useWindowSize();
  const isMobile = useMemo(() => !!width && width < DESKTOP_MIN_WIDTH, [width]);

  const accountCardText = useMemo(() => {
    if (account.type === AccountType.CREDIT_CARD) {
      return t('typeCard.creditCard');
    }

    if (account.type === AccountType.DEBIT_CARD) {
      return t('typeCard.debitCard');
    }

    return '';
  }, [account]);

  const StatusBank = useMemo(() => {
    return (
      <div className={styles['status-bank-mobile__wrapper']}>
        {account.isFundingAccount && <span className={styles['bank-status']}>{t('statusAccount.funding')}</span>}
        {account.isDepositAccount && <span className={styles['bank-status']}>{t('statusAccount.deposit')}</span>}
      </div>
    );
  }, [account.isDepositAccount, account.isFundingAccount]);

  return (
    <div>
      {isMobile && StatusBank}

      <div className={styles['payment-method-info__wrapper']}>
        <img height={54} width={54} src={account.logo} alt="Bank logo" />

        <div className={styles['details-bank__wrapper']}>
          <div className={styles['bank-name__wrapper']}>
            <b className={styles['bank-name']}>
              {account.brand} {accountCardText && `(${accountCardText})`}
            </b>
            {!isMobile && StatusBank}
          </div>

          <p className={styles['bank-numbers']}>xxxx{account.lastNumbers}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
