// libraries
import React from 'react';
import { HTMLReactParserOptions } from 'html-react-parser';
import { Link } from 'react-router-dom';

export const replaceTag = (
  tagName: string,
  link?: Nullable<string>,
  onClickCallback?: () => void,
): HTMLReactParserOptions => {
  const options: HTMLReactParserOptions = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    replace: (domNode: any) => {
      if (domNode.attribs && domNode.name === tagName) {
        const linkChildren = domNode.children[0].data;

        return (
          <Link to={link || domNode.attribs.href || ''} onClick={onClickCallback}>
            {linkChildren}
          </Link>
        );
      }

      return domNode;
    },
  };

  return options;
};

export default {};
