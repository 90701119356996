import { makeAutoObservable } from 'mobx';
import RootStore from '../RootStore/RootStore';
import { ILogoutStore } from './types';
import { CLEAR_STORE } from 'shared/constants/funcNamesInStore';

const initialValues = {
  isFetching: false,
  message: '',
  requestSuccessful: false,
};

class LogoutStore {
  logoutData: ILogoutStore = initialValues;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setDataFromMessage = (data: ILogoutStore): void => {
    this.logoutData = data;
  };

  [CLEAR_STORE] = (): void => {
    this.logoutData = initialValues;
  };
}

export default LogoutStore;
