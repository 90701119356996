import { MutableRefObject, useCallback, useEffect } from 'react';

const useCloseByOutsideAction = (
  cbFunction: CallableFunction,
  node?: MutableRefObject<Nullable<HTMLElement>>,
): void => {
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Escape' && e.key !== 'Esc' && e.code !== 'Escape') {
        return;
      }

      cbFunction();
    },
    [cbFunction],
  );

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (!node || !node.current) {
        return;
      }

      if (node.current.contains(e.target as Node)) {
        return;
      }

      cbFunction();
    },
    [cbFunction, node],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    if (node) {
      document.addEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);

      if (node) {
        document.removeEventListener('mousedown', handleClick);
      }
    };
  }, [handleKeyPress, handleClick, node]);
};

export default useCloseByOutsideAction;
