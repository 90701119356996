// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// components
import TransactionCardFooterActionItem from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooterActions/TransactionCardFooterActionItem';
// helpers
import stall from 'shared/helpers/simulateAsyncCall';
import { useAppLoadingContext } from 'components/AppLoading/AppLoadingContext';
// assets
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';

interface IDownloadInvoice {
  invoiceId: string;
}

const DownloadInvoice: React.FC<IDownloadInvoice> = ({ invoiceId }: IDownloadInvoice) => {
  const { t } = useTranslation('transactionHistory');
  const { setIsLoading } = useAppLoadingContext();

  const downloadInvoice = useCallback(async () => {
    setIsLoading(true);
    // TODO: generate and download a PDF version of the invoice
    await stall();
    setIsLoading(false);
  }, [invoiceId, setIsLoading]);

  return (
    <TransactionCardFooterActionItem onClick={downloadInvoice}>
      <DownloadIcon width={9} height={9} />
      {t('invoiceActions.download')}
    </TransactionCardFooterActionItem>
  );
};

export default DownloadInvoice;
