import React, { useMemo } from 'react';
import { useStore } from 'shared/stores/RootStore/useStore';
import { useTranslation } from 'react-i18next';
// assets
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as GroupClientsIcon } from 'assets/icons/clients-group.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
// helpers
import { ShortListType } from 'shared/stores/ShortListsStore/ShortListsStore';
// styles
import styles from './ShortListsModalHeader.module.scss';

const ShortListsModalHeader: React.FC = () => {
  const { t } = useTranslation('shortLists');

  const {
    shortListsStore: { shortListType },
  } = useStore();

  const listTypeInfo = useMemo(
    () => ({
      [ShortListType.topClients]: {
        icon: <SaveIcon width={14} height={14} />,
        title: t('topCustomers'),
      },
      [ShortListType.shortList]: {
        icon: <GroupClientsIcon width={18} height={18} />,
        title: t('shortlist'),
      },
      [ShortListType.recentPayments]: {
        icon: <ClockIcon width={14} height={14} />,
        title: t('recentPayments'),
      },
    }),
    [],
  );

  const headerInfo = useMemo(() => listTypeInfo[shortListType], [listTypeInfo, shortListType]);

  return (
    <div className={styles['short-list-header__wrapper']}>
      {headerInfo.icon}
      <span className={styles.title}>{headerInfo.title}</span>
    </div>
  );
};

export default ShortListsModalHeader;
