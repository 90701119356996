// libraries
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
// styles
import styles from 'components/company/CompanyDetails/CompanyDetails.module.scss';
// components
import ProfileTopSection from 'components/ProfileTopSection/ProfileTopSection';
import CompanyDescription from 'components/company/CompanyDescription/CompanyDescription';
import CompanyMarketingElement from 'components/company/CompanyMarketingElement/CompanyMarketingElement';
// helpers
import useCompanyDetails from 'components/company/CompanyDetails/useCompanyDetails';

const PublicCompanyProfile: React.FC = () => {
  const { t } = useTranslation('company');
  const { id = '' } = useParams<Dictionary<string>>();
  const { companyProfile, error } = useCompanyDetails(id);

  if (error) {
    return <div className={classNames(styles.company__wrapper, styles.company__error)}>{t('companyNotFound')}</div>;
  }

  return (
    <>
      <Row className={styles['company-details-layout__wrapper']}>
        <Col xs={10} md={10}>
          <ProfileTopSection companyProfile={companyProfile} />
        </Col>
      </Row>
      <Row>
        <Col xs={10} md={6}>
          <CompanyDescription companyProfile={companyProfile} />
        </Col>
        <Col xs={10} md={4}>
          <CompanyMarketingElement />
        </Col>
      </Row>
    </>
  );
};

export default observer(PublicCompanyProfile);
