// libraries
import React from 'react';
// styles
import styles from './PaymentReview.module.scss';

interface IPaymentReviewField {
  value: React.ReactNode;
  label: string;
}

const PaymentReviewField: React.FC<IPaymentReviewField> = ({ value, label }: IPaymentReviewField) => {
  if (!value) {
    return null;
  }

  return (
    <div className={styles.column}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default PaymentReviewField;
