// libraries
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// constants
import { ButtonColor } from 'components/Button';
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';
import { useStore } from 'shared/stores/RootStore/useStore';
import stall from 'shared/helpers/simulateAsyncCall';
// assets
import filledTrashIcon from 'assets/icons/filled-trash-icon.svg';
// styles
import styles from './SettingsPaymentMethods.module.scss';

type ConnectedAccounts = {
  isLoading: boolean;
  accounts: Account[];
  paymentMethodDeleteConfirm: (id: number) => void;
  setFundingCallback: (id: number) => Promise<void>;
  setDepositCallback: (id: number) => Promise<void>;
};

const useConnectedAccounts = (): ConnectedAccounts => {
  const { t } = useTranslation('paymentMethodsSettings');
  const [isLoading, setIsLoading] = useState(false);

  const {
    paymentMethods: { accounts, removeAccount, setAccounts },
    modalStore: { openModal },
  } = useStore();

  // TODO: Change after integration with BE
  const deleteAccountCallback = useCallback(
    async (id: number) => {
      await stall(id, 500);
      removeAccount(id);
    },
    [removeAccount],
  );

  const paymentMethodDeleteConfirm = useCallback(
    (id: number) => {
      openModal(ModalIds.confirmDeletePaymentMethod, {
        icon: filledTrashIcon,
        title: t('confirmModal.title'),
        content: '',
        classNameTitle: styles['confirm-delete-title'],
        confirmButtonProps: {
          color: ButtonColor.danger,
        },
        cbConfirmActionFunction: () => {
          deleteAccountCallback(id);
        },
        cancelButtonText: t('confirmModal.cancelButtonText'),
        confirmButtonText: t('confirmModal.confirmButtonText'),
      });
    },
    [deleteAccountCallback],
  );

  // TODO: Change after integration with BE
  const setFundingCallback = useCallback(
    async (id: number) => {
      const updatedAccounts = accounts.map(item => {
        if (item.isFundingAccount) {
          return { ...item, isFundingAccount: !item.isFundingAccount };
        }

        if (item.id === id) {
          return { ...item, isFundingAccount: !item.isFundingAccount };
        }

        return item;
      });

      await stall(updatedAccounts, 500);
      setAccounts(updatedAccounts);
    },
    [accounts],
  );

  // TODO: Change after integration with BE
  const setDepositCallback = useCallback(
    async (id: number) => {
      const updatedAccounts = accounts.map(item => {
        if (item.isDepositAccount) {
          return { ...item, isDepositAccount: !item.isDepositAccount };
        }

        if (item.id === id) {
          return { ...item, isDepositAccount: !item.isDepositAccount };
        }

        return item;
      });

      await stall(updatedAccounts, 500);
      setAccounts(updatedAccounts);
    },
    [accounts],
  );

  return {
    accounts,
    paymentMethodDeleteConfirm,
    setFundingCallback,
    setDepositCallback,
    isLoading,
  };
};

export default useConnectedAccounts;
