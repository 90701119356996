// libraries
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
// helpers
import { Invoice, InvoiceTypes } from 'components/invoices/types';
import { getInvoiceStatus } from 'components/transactions/TransactionCard/transactionCardHelpers';
// styles
import styles from './InvoiceStatus.module.scss';

interface IInvoiceStatus {
  data: Invoice;
}

const InvoiceStatus: React.FC<IInvoiceStatus> = ({ data: { invoiceType, dueDate, invoiceState } }: IInvoiceStatus) => {
  const { t } = useTranslation('transactionHistory');
  const isPayableInvoice = useMemo(() => invoiceType === InvoiceTypes.payable, [invoiceType]);
  const invoiceStatus = getInvoiceStatus(invoiceState, dueDate, isPayableInvoice);

  return invoiceStatus ? (
    <div className={classnames(styles['invoice-status'], styles[invoiceStatus])}>
      {t(`invoiceStatuses.${invoiceStatus}`)}
    </div>
  ) : null;
};

export default InvoiceStatus;
