import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';

export type NavItem = {
  name: string;
  path: string;
  exact: boolean;
  icon?: React.ReactElement;
  activeIcon?: React.ReactElement;
};

export interface NavProps {
  menuData: NavItem[];
  mapItemFunc: (item: NavItem) => React.ReactElement;
  style?: string;
}

const Nav: React.FC<NavProps> = ({ menuData, mapItemFunc, style }) => {
  return <nav className={style}>{menuData.map(mapItemFunc)}</nav>;
};

Nav.propTypes = {
  menuData: arrayOf(
    shape({
      name: string.isRequired,
      path: string.isRequired,
      exact: bool.isRequired,
    }).isRequired,
  ).isRequired,
  mapItemFunc: func.isRequired,
  style: string,
};

export default Nav;
