// libraries
import React from 'react';
// components
import PublicLayout from 'shared/layouts/public';
import TwoBlockLayout from 'shared/layouts/public/TwoBlockLayout/TwoBlockLayout';
import RegistrationFlow from 'router/views/SignUp/RegistrationFlow';
// assets
import layoutImage from 'assets/images/layout-image.jpg';

const SignUp: React.FC = () => (
  <PublicLayout checkToken>
    <TwoBlockLayout imageAlt="Sign-up" imageSrc={layoutImage}>
      <RegistrationFlow />
    </TwoBlockLayout>
  </PublicLayout>
);

export default SignUp;
