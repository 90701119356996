// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import SavedOwners from 'components/beneficial/SavedOwners/SavedOwners';
// styles
import styles from '../../AccountTabForm.module.scss';
import beneficiaryOwnersFieldStyles from './BeneficiaryOwnersField.module.scss';

const BeneficiaryOwnersField = () => {
  const { t } = useTranslation('settingsPage');

  return (
    <div className={styles.field__wrapper}>
      <p className={classNames(styles['field-label'], beneficiaryOwnersFieldStyles['field-label'])}>
        {t('businessInformationBlock.fields.beneficiaryOwners.label')}
      </p>
      <div className={classNames(styles.input__wrapper, beneficiaryOwnersFieldStyles.input__wrapper)}>
        <SavedOwners showContinueButton={false} isRedirectRequired={false} />
      </div>
    </div>
  );
};

export default BeneficiaryOwnersField;
