import Account from 'router/views/Settings/Personal/Account/Account';
import SettingsPaymentMethods from 'router/views/Settings/Company/SettingsPaymentMethods/SettingsPaymentMethods';
import SettingsAccountIntegrations from 'router/views/Settings/Company/SettingsAccountIntegrations/SettingsAccountIntegrations';
import Documents from 'router/views/Settings/Company/Documents/Documents';
import NotFound from 'router/views/NotFound/NotFound';

export enum SettingsPagesName {
  ACCOUNT = 'Account',
  INTEGRATIONS = 'Accounting Integrations',
  CONNECTED_ACCOUNTS = 'Payment Methods',
  DOCUMENTS = 'Documents',
}

export default [
  {
    path: '/settings',
    component: Account,
    name: SettingsPagesName.ACCOUNT,
    exact: true,
  },
  {
    name: SettingsPagesName.CONNECTED_ACCOUNTS,
    path: '/settings/payment-methods',
    component: SettingsPaymentMethods,
    exact: true,
  },
  {
    name: SettingsPagesName.INTEGRATIONS,
    path: '/settings/integrations',
    component: SettingsAccountIntegrations,
    exact: true,
  },
  {
    name: SettingsPagesName.DOCUMENTS,
    path: '/settings/documents',
    component: Documents,
    exact: true,
  },
  {
    path: '*',
    exact: true,
    isProtected: false,
    component: NotFound,
    name: '404',
  },
];
