// libraries
import { useCallback } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { StripeCardElement } from '@stripe/stripe-js';
// api
import PaymentMethodApi, { AccountType, NewCreditCard } from 'components/paymentMethods/api/PaymentMethodApi';
// constants
import { ModalIds } from 'shared/constants/modalIds';
// helpers
import { addErrorToast, addSuccessToast } from 'components/Toast/toastHelper';
import { BillingDetails } from 'components/paymentMethods/StripeForm/stripeFormHelpers';
// RootStore
import { useStore } from 'shared/stores/RootStore/useStore';
import { Account } from 'shared/stores/PaymentMethodStore/PaymentMethodStore';

export type PaymentMethodData = { card: StripeCardElement; billing_details: BillingDetails };

export enum PaymentMethodStep {
  OPTIONS = 0,
  CREDIT_CARD = 1,
}

export type PaymentMethodParams = {
  handleCloseModal: () => void;
  addStripeCard: (paymentMethod: PaymentMethodData) => void;
};

const usePaymentMethod = (): PaymentMethodParams => {
  const { t } = useTranslation('connectedAccountsPage');
  const stripe = useStripe();
  const elements = useElements();
  const {
    paymentMethods: { addAccount, addAccountToFunds, modalId },
    modalStore: { closeModal, isOpen },
  } = useStore();

  const handleCloseModal = useCallback(() => {
    closeModal(modalId);
  }, [closeModal, modalId]);

  const addStripeCard = useCallback(
    async ({ card, billing_details }: PaymentMethodData) => {
      if (!stripe || !elements) {
        return;
      }

      const { error, paymentMethod: newPaymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details,
      });

      if (error?.message) {
        // addErrorToast(error.message);
        return;
      }

      const newCreditCard: NewCreditCard = {
        token: newPaymentMethod?.id || '',
      };

      try {
        const account = await PaymentMethodApi.connectAccount(AccountType.CREDIT_CARD, newCreditCard);

        addSuccessToast(t('successMessage'));
        closeModal(modalId);
        addAccount(account as Account);

        if (isOpen(ModalIds.paymentMethodFromNotification)) {
          addAccountToFunds(account as Account);
        }
      } catch (e) {
        const { message } = await e;
        // addErrorToast(message);
      }
    },
    [addAccount, closeModal, isOpen, t, stripe, elements, addAccountToFunds, modalId],
  );

  return {
    handleCloseModal,
    addStripeCard,
  };
};

export default usePaymentMethod;
