// libraries
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Form from 'components/Form';
import Button from 'components/Button';
import SubmitButton from 'components/Form/buttons/SubmitButton';
import FormFooter from 'components/MultiStep/Form/FormFooter/FormFooter';
import FormFieldsInvoice from './FormFieldsInvoice/FormFieldsInvoice';
// constants
import INVOICE_FORM_SCHEMA from './validation-rules';
// helpers
import { FormData } from 'components/Form/FormContext';
import { Invoice } from '../types';
import { useMultiStepContext } from 'components/MultiStep/MultiStepContext';
// styles
import styles from './InvoiceForm.module.scss';

export type TInvoiceFormProps = {
  handleReturn: () => void;
  currentStep: number;
  invoice?: Partial<Invoice> | null;
};

const InvoiceForm: React.FC<TInvoiceFormProps> = ({ handleReturn, currentStep, invoice }: TInvoiceFormProps) => {
  const { t } = useTranslation(['invoice', 'forms']);
  const { goToNextStep, formData } = useMultiStepContext();

  const handleSubmit = useCallback(
    (data: FormData) => {
      if (goToNextStep) {
        goToNextStep(currentStep, data);
      }
    },
    [goToNextStep, currentStep],
  );

  return (
    <Form submitForm={handleSubmit} validationSchema={INVOICE_FORM_SCHEMA} data={formData}>
      <FormFieldsInvoice invoice={invoice} />
      <FormFooter>
        <Button outline handleClick={handleReturn} className={styles['invoice-form__button']}>
          {t('forms:backButtonDefaultText')}
        </Button>
        <SubmitButton className={styles['invoice-form__button']}>{t('forms:continueButtonDefaultText')}</SubmitButton>
      </FormFooter>
    </Form>
  );
};

export default InvoiceForm;
