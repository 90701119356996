// libraries
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// components
import Routes from 'router/Routes';
import PublicLayout from 'shared/layouts/public';
import Modal from 'components/Modal/Modal';
import PaymentMethod from 'components/paymentMethods/PaymentMethod/PaymentMethod';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import VerificationStatus from 'components/verification/VerificationStatus';
import TwoBlockLayout from 'shared/layouts/public/TwoBlockLayout/TwoBlockLayout';
import Toast from 'components/Toast';
// constants
import { ModalIds } from 'shared/constants/modalIds';
import config from 'config';
import STRIPE_OPTIONS from 'shared/constants/stripe';
// helpers
import verificationRoutes from 'router/configs/verification';
import { usePlaces } from 'shared/helpers/placesHelpers';
// assets
import layoutImage from 'assets/images/layout-image.jpg';

const Verification: React.FC = () => {
  const stripePromise = loadStripe(`${config.apiKeys.stripe}`);
  const { isPlacesLoaded } = usePlaces();
  const [prevPath, setPrevPath] = useState('/');
  const { state: locationState = {} } = useLocation();

  useEffect(() => {
    setPrevPath(locationState.prevPath);
  }, []);

  if (!isPlacesLoaded) {
    return null;
  }

  return (
    <Elements stripe={stripePromise} options={STRIPE_OPTIONS}>
      <PublicLayout>
        <TwoBlockLayout imageAlt="Verification" imageSrc={layoutImage}>
          <>
            <Toast className="public-toasts" />
            <Routes routes={verificationRoutes} />

            <Modal id={ModalIds.paymentMethod}>
              <PaymentMethod />
            </Modal>

            <Modal hasCloseButton={false} id={ModalIds.verificationStatus}>
              <VerificationStatus prevPath={prevPath} />
            </Modal>
            <ConfirmationModal />
          </>
        </TwoBlockLayout>
      </PublicLayout>
    </Elements>
  );
};

export default observer(Verification);
