import React, { FormEvent } from 'react';
import useForm from './formHelper';
import { FormProvider, FormContextData } from './FormContext';

const Form: React.FC<FormContextData> = (props: FormContextData) => {
  const { children, className = '', autoComplete = 'on' } = props;

  const { isDirty, onSubmitForm, ...contextValue } = useForm(props);

  const submitForm = (event: FormEvent) => {
    event.preventDefault();

    if (onSubmitForm) {
      onSubmitForm();
    }
  };

  return (
    <FormProvider
      value={{
        isDirty,
        onSubmitForm,
        ...contextValue,
      }}
    >
      <form onSubmit={submitForm} className={className} autoComplete={autoComplete}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
