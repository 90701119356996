// libraries
import React from 'react';
// components
import TransactionCardFooter from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooter';
import CompletedInvoiceFooterActions from 'components/transactions/TransactionDetails/CompletedInvoiceDetails/CompletedInvoiceFooterActions/CompletedInvoiceFooterActions';
// helpers
import { Invoice } from 'components/invoices/types';

interface ICompletedInvoiceDetails {
  invoiceDetails: Invoice;
}
const CompletedInvoiceDetails: React.FC<ICompletedInvoiceDetails> = ({ invoiceDetails }: ICompletedInvoiceDetails) => {
  return (
    <TransactionCardFooter>
      <CompletedInvoiceFooterActions invoiceDetails={invoiceDetails} />
    </TransactionCardFooter>
  );
};

export default CompletedInvoiceDetails;
