// libraries
import { useCallback, useState } from 'react';
import {
  ConfirmCardPaymentData,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { CardNumberElement, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
// context
import { FormData } from 'components/Form/FormContext';
// helpers
import { prepareCardPaymentData } from 'components/paymentMethods/StripeForm/stripeFormHelpers';

export enum StripeInputId {
  CARD_NUMBER = 'cardNumber',
  CARD_EXPIRY = 'cardExpiry',
  CARD_CVC = 'cardCvc',
}

type StripeElementEvent =
  | StripeCardNumberElementChangeEvent
  | StripeCardCvcElementChangeEvent
  | StripeCardExpiryElementChangeEvent;

type SingleFieldState = {
  hasFocus: boolean;
  isComplete: boolean;
  isEmpty: boolean;
  error: string;
};

type FieldsState = {
  [StripeInputId.CARD_NUMBER]: SingleFieldState;
  [StripeInputId.CARD_EXPIRY]: SingleFieldState;
  [StripeInputId.CARD_CVC]: SingleFieldState;
};

export type CardFormData = {
  firstName: string;
  lastName: string;
  cardData: StripeCardNumberElement;
};

export type AddressFormData = {
  country: string;
  city: string;
  line1: string;
  postalCode?: string;
  zip?: string;
  state?: string;
  region?: string;
};

export type StripeCardData = AddressFormData & CardFormData;

export type StripeFormParams = {
  fieldsState: FieldsState;
  toggleFocus: (id: StripeInputId) => void;
  onChange: (e: StripeElementEvent) => void;
  submitCardForm: (data: FormData) => void;
  cardFormData: Nullable<CardFormData>;
  submitAddressForm: (data: FormData) => ConfirmCardPaymentData;
  addressFormData: Nullable<AddressFormData>;
  isCardFormVisible: boolean;
  backToCardDetails: () => void;
};

const FIELD_INITIAL_STATE = {
  hasFocus: false,
  isComplete: false,
  isEmpty: true,
  error: '',
};

const useStripeForm = (): StripeFormParams => {
  const { t } = useTranslation('errors');
  const elements = useElements();
  const [fieldsState, setFieldsState] = useState<FieldsState>({
    [StripeInputId.CARD_NUMBER]: FIELD_INITIAL_STATE,
    [StripeInputId.CARD_EXPIRY]: FIELD_INITIAL_STATE,
    [StripeInputId.CARD_CVC]: FIELD_INITIAL_STATE,
  });
  const [cardFormData, setCardFormData] = useState<Nullable<CardFormData>>(null);
  const [addressFormData, setAddressFormData] = useState<Nullable<AddressFormData>>(null);
  const [isCardFormVisible, setIsCardFormVisible] = useState(true);

  const toggleFocus = useCallback((id: StripeInputId) => {
    setFieldsState(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        hasFocus: !prevState[id].hasFocus,
      },
    }));
  }, []);

  const onChange = useCallback(
    ({ error, elementType: id, empty, complete }: StripeElementEvent) => {
      if (empty) {
        setFieldsState(prevState => ({
          ...prevState,
          [id]: {
            ...prevState[id],
            error: t(`forms.stripeForm.${id}.required`),
          },
        }));

        return;
      }

      if (!error) {
        setFieldsState(prevState => ({
          ...prevState,
          [id]: {
            ...prevState[id],
            error: '',
            isComplete: complete,
            isEmpty: empty,
          },
        }));

        return;
      }

      setFieldsState(prevState => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          error: error.message,
          isComplete: complete,
          isEmpty: empty,
        },
      }));
    },
    [t],
  );

  const getErrorMessage = useCallback(
    (fieldName: StripeInputId, { error, isEmpty, isComplete }: SingleFieldState) => {
      if (error) {
        return error;
      }

      if (isEmpty) {
        return t(`forms.stripeForm.${fieldName}.required`);
      }

      if (isComplete) {
        return '';
      }

      return t(`forms.stripeForm.${fieldName}.invalid`);
    },
    [t],
  );

  const validateForm = useCallback(() => {
    let newState: FieldsState = { ...fieldsState };

    Object.entries(fieldsState).forEach(([fieldName, fieldState]) => {
      const error = getErrorMessage(fieldName as StripeInputId, fieldState);

      newState = {
        ...newState,
        [fieldName]: {
          ...newState[fieldName as StripeInputId],
          error,
        },
      };
    });

    setFieldsState(newState);

    return Object.values(fieldsState).reduce(
      (accumulator, currentValue) => accumulator && currentValue.isComplete,
      true,
    );
  }, [fieldsState, getErrorMessage]);

  const submitCardForm = useCallback(
    (data: FormData) => {
      if (!elements) {
        return;
      }

      const isValidForm = validateForm();

      if (!isValidForm) {
        return;
      }

      const cardNumberElement = elements.getElement(CardNumberElement);

      setCardFormData({
        ...(data as Omit<CardFormData, 'cardData'>),
        cardData: cardNumberElement as StripeCardNumberElement,
      });
      setIsCardFormVisible(false);
    },
    [elements, validateForm],
  );

  const submitAddressForm = useCallback(
    (data: FormData) => {
      setAddressFormData(data as AddressFormData);

      return prepareCardPaymentData({ ...cardFormData, ...data } as StripeCardData);
    },
    [cardFormData],
  );

  const backToCardDetails = useCallback(() => {
    setIsCardFormVisible(true);
  }, []);

  return {
    toggleFocus,
    onChange,
    fieldsState,
    submitCardForm,
    cardFormData,
    submitAddressForm,
    addressFormData,
    isCardFormVisible,
    backToCardDetails,
  };
};

export default useStripeForm;
