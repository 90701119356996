// libraries
import React, { createContext, useContext } from 'react';
// utils
import { useConnection, useMessage, useDisconnection, useError } from './eventHandlers';
import useSession from './useSession';
import { useStore } from 'shared/stores/RootStore/useStore';
// types
import { IWebsocketContextValue } from './types';
import { Entity } from 'shared/api/crud/Crud';
// constants
import EndpointsToStores from 'shared/constants/EndpointsToStores';
import RootStore from 'shared/stores/RootStore/RootStore';

export const WebsocketContext = createContext<IWebsocketContextValue>({} as IWebsocketContextValue);

export function useWebsocketContext() {
  return useContext(WebsocketContext);
}

export default function WebsocketContextProvider({ children }: { children?: React.ReactNode }) {
  const store = useStore();

  const onOpen = useConnection(store);
  const onMessage = useMessage(store);
  const onClose = useDisconnection(store);
  const onError = useError();

  const [connect, send, close] = useSession(onOpen, onMessage, onClose, onError);

  const setFetching = (endpoint: Nullable<string>, isFetching: boolean) => {
    // @ts-ignore
    store[EndpointsToStores[endpoint] as keyof RootStore]?.setDataFromMessage({ isFetching });
  };

  const handleSendAfterConnect = (
    messageType: Nullable<string>,
    endpoint: Nullable<string>,
    payload: Nullable<Entity>,
    sessionId?: Nullable<string>,
  ) => {
    connect();
    store.messagePendingStore.setDataFromMessage({
      messageType,
      endpoint,
      payload,
      sessionId,
    });
  };

  const sendMessage = (
    messageType: Nullable<string>,
    endpoint: Nullable<string>,
    payload: Nullable<Entity>,
    sessionId?: Nullable<string>,
  ) => {
    setFetching(endpoint, true);

    if (store.connectionStore.isConnectionOpen) {
      send(messageType, endpoint, payload, sessionId);
    } else {
      handleSendAfterConnect(messageType, endpoint, payload, sessionId);
    }
  };

  return (
    <WebsocketContext.Provider
      value={{
        connect,
        sendMessage,
        close,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}
