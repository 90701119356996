// libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
// components
import Button from 'components/Button';
import ServiceMessageLayout from 'shared/layouts/service-message';
// helpers
import { useStore } from 'shared/stores/RootStore/useStore';
import useAuth from 'components/Auth/authHelper';
// styles
import styles from './SessionTimeout.module.scss';

const SessionTimeout: React.FC = () => {
  const { t } = useTranslation('sessionTimeoutPage');
  const {
    authStore: { sessionTimedOut, setSessionTimedOut },
  } = useStore();
  const history = useHistory();
  const { isAdminReferrer } = useAuth();

  const handleLoginButtonClick = () => {
    setSessionTimedOut(false);
    history.push('/login');
  };

  if (!isAdminReferrer() && !sessionTimedOut) {
    return <Redirect to="/login" />;
  }

  return (
    <ServiceMessageLayout checkToken>
      <div className={styles['title-wrapper']}>
        <svg viewBox="0 0 647 426" className={styles.layout__shape}>
          <linearGradient id="shape-fill" x1="9.856%" x2="73.359%" y1="23.681%" y2="78.436%">
            <stop offset="0%" stopColor="#e9ebef" />
            <stop offset="100%" stopColor="#cfd2db" />
          </linearGradient>
          <path d="M313.378 426c122.094 0 205.312-24.547 205.312-146.59C518.69 157.366 731.274 0 609.18 0 487.085 0 0 74.512 0 196.555S191.284 426 313.378 426z" />
        </svg>
        <h1 className={styles['page-title']}>{t('title')}</h1>
      </div>
      <Button className={styles['login-button']} handleClick={handleLoginButtonClick}>
        {t('loginButton')}
      </Button>
    </ServiceMessageLayout>
  );
};

export default SessionTimeout;
