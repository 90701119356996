import React from 'react';
import { Container } from 'react-bootstrap';
// components
import ResetPasswordFlow from 'router/views/ResetPassword/ResetPasswordFlow';
import PublicLayout from 'shared/layouts/public';

const ResetPassword = () => (
  <PublicLayout>
    <Container>
      <ResetPasswordFlow />
    </Container>
  </PublicLayout>
);

export default ResetPassword;
