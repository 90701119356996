// libraries
import { ChangeEvent, useEffect, useState } from 'react';
// components
import { InputElements } from 'components/Form/inputs/Input';

type SearchInputCallback = (query: string, isOpenSearch: boolean) => void;

type SearchInputParams = {
  inputValue: string;
  isOpenSearch: boolean;
  openSearch: () => void;
  closeSearch: () => void;
  handleChangeInput: (event: ChangeEvent<InputElements>) => void;
  clearSearch: () => void;
};

const useSearchInput = (onSearch: SearchInputCallback): SearchInputParams => {
  const [inputValue, setInputValue] = useState('');
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const clearSearch = () => {
    setInputValue('');
  };

  const openSearch = () => {
    setIsOpenSearch(true);
  };

  const closeSearch = () => {
    setIsOpenSearch(false);
  };

  const handleChangeInput = ({ target: { value } }: ChangeEvent<InputElements>) => {
    setInputValue(value);
    openSearch();
  };

  useEffect(() => {
    onSearch(inputValue as string, isOpenSearch);
  }, [inputValue, onSearch, isOpenSearch]);

  return {
    inputValue,
    isOpenSearch,
    handleChangeInput,
    openSearch,
    closeSearch,
    clearSearch,
  };
};

export default useSearchInput;
