import i18n from 'i18next';

export type CustomErrors = Dictionary<string | string[]>;

type RequestErrorData = {
  message?: string;
  errors?: CustomErrors;
};

class RequestError extends Error {
  errors?: CustomErrors;

  status?: number | string;

  constructor(status = '', responseData: RequestErrorData) {
    super();

    const errorData = responseData || {};

    this.message = errorData.message || i18n.t('errors:defaultError');
    this.errors = errorData.errors;
    this.status = status;
    this.name = 'RequestError';
  }
}

export default RequestError;
