import * as Yup from 'yup';

export const getCommonNameSchema = (): Yup.StringSchema => Yup.string().max(100, 'forms.maximumStringLength');

export const getTaxIdNameSchema = (isExcludeEmptyString = false) =>
  Yup.string().matches(/^(EIN|SSN|TIN)$/, { message: 'forms.taxId.invalid', excludeEmptyString: isExcludeEmptyString });

export const getLatinNameSchema = (isExcludeEmptyString = false): Yup.StringSchema =>
  getCommonNameSchema().matches(/^[a-zA-Z\s.,-]+$/i, {
    message: 'forms.latinNameError',
    excludeEmptyString: isExcludeEmptyString,
  });

export const getUsernameSchema = (): Yup.StringSchema =>
  getCommonNameSchema().matches(/^@[a-zA-Z\s.,-]+$/i, { message: 'forms.username.invalid' });

export const getFirstAndLastNameSchema = (): Yup.StringSchema =>
  getCommonNameSchema().matches(/^[a-zA-Z\s.,'-]+$/i, { message: 'forms.latinFirstAndLastName' });

export const getLatinNameSchemaWithNumberAndApostrophe = (isExcludeEmptyString = false): Yup.StringSchema =>
  getCommonNameSchema().matches(/^[a-zA-Z0-9\s.,'-]+$/i, {
    message: 'forms.latinNameWithNumbersAndApostropheError',
    excludeEmptyString: isExcludeEmptyString,
  });

export const getVerificationCodeSchema = (): Yup.StringSchema =>
  Yup.string().length(4, 'forms.verificationCode.format');

export const getPhoneSchema = (): Yup.StringSchema =>
  Yup.string()
    .length(10, 'forms.phone.invalid')
    .matches(/(\d{10})/, { message: 'forms.phone.invalid' });

export const getLatinNameWithNumberSchema = (): Yup.StringSchema =>
  getCommonNameSchema().matches(/^[a-zA-Z0-9\s\-,.!"#$%&'()*+/:;<=>?@[\\\]^_`{|}~ ]+$/i, {
    message: 'forms.latinNameSymbolsNumbers',
  });

export const getIdNumberSchema = (isExcludeEmptyString = false): Yup.StringSchema =>
  Yup.string().matches(/^[0-9]{9}$/, { message: 'forms.idNumber.invalid', excludeEmptyString: isExcludeEmptyString });

export const getZipCodeNameSchema = (): Yup.StringSchema =>
  Yup.string()
    .min(5, 'forms.address.zip.length')
    .max(5, 'forms.address.zip.length')
    .matches(/^[0-9]+$/, 'forms.address.zip.format');

export const getPostalCodeSchema = (): Yup.StringSchema =>
  Yup.string()
    .max(10, 'forms.address.postalCode.maxLength')
    .matches(/^[0-9-\s]+$/i, {
      message: 'forms.address.postalCode.format',
    });

export const getPasswordSchema = (requiredError?: string): Yup.StringSchema =>
  Yup.string()
    .required(requiredError || 'forms.password.required')
    .min(8, 'forms.password.minLength')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_~`!@#$%^&*'()+=\-|\\</>{.}[,\]";:?])/, {
      message: 'forms.password.format',
    })
    .max(64, 'forms.password.maxLength');

export const getSSNchema = (): Yup.StringSchema =>
  Yup.string()
    .length(9, 'forms.ssn.invalid')
    .matches(/(\d{9})/, { message: 'forms.ssn.invalid' });
