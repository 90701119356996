// libraries
import React from 'react';
// assets
import { ReactComponent as DefaultIcon } from 'assets/icons/add-entity.svg';
// styles
import styles from './FormTitle.module.scss';

export type TFormTitleProps = {
  icon?: React.ReactNode;
  title: string;
};

const FormTitle: React.FC<TFormTitleProps> = ({ icon, title }: TFormTitleProps) => (
  <div className={styles.title}>
    <div className={styles.title__icon}>{icon || <DefaultIcon />}</div>
    <div className={styles.title__text}>{title}</div>
  </div>
);

export default FormTitle;
