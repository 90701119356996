export enum PopupButtons {
  sendInvoice = 'send-invoice-invite-btn',
  discardInvoice = 'discard-invoice-invite-btn',
  sendInvite = 'yes-invite-btn',
  cancelInvite = 'no-invite-btn',
}

export const errorMessageHasButtons = (message: string): boolean => {
  const buttons = Object.values(PopupButtons);

  return buttons.some(value => message.includes(value));
};
