import { useCallback, useEffect, useMemo, useState } from 'react';
import intersection from 'lodash/intersection';
// api
import NotificationApi, { NotificationTypes, NotificationResponse } from '../api/NotificationApi';
// helpers
import { addErrorToast } from 'components/Toast/toastHelper';
import { useStore } from 'shared/stores/RootStore/useStore';
import { ModalIds } from 'shared/constants/modalIds';
import NotificationTemplateName from '../notificationsHelper';
import useVerificationNotification from '../VerificationNotification/useVerificationNotification';
import { Notification } from '../../../shared/stores/NotificationsStore/NotificationsStore';
import { ADMIN_ROLES } from 'shared/constants/userRoles';

type PopupNotificationsHelper = {
  showNotifications: (page?: number) => void;
  showVerificationNotification: (page?: number) => void;
  updateNotificationsDate: (id: number) => void;
};

const usePopupNotification = (shouldLoadVerificationPopup?: boolean): PopupNotificationsHelper => {
  const {
    notificationsStore: { updatePopupList, isPopupsLoaded, setPopupsLoaded },
    verificationStore: { setVerificationPopup, verificationPopup },
    modalStore: { openModal },
    userStore: { isVerifiedCompany, role },
    authStore: { sessionId },
  } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openVerificationPopup } = useVerificationNotification();
  const isPlatformAdmin = useMemo(() => !!intersection(role, ADMIN_ROLES).length, [role]);

  const showNotifications = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      try {
        const bannersList = await NotificationApi.getList({
          page,
          'filter[type]': NotificationTypes.popup,
          'filter[showedAt]': 'null',
        });

        const { rows = [] } = bannersList as NotificationResponse;
        let loadedVerificationPopup: Nullable<Notification> = null;
        const notificationsForShowing = rows.filter(popup => {
          const { notificationId, message } = popup;

          if (!isVerifiedCompany && message === NotificationTemplateName.verifyAccount) {
            loadedVerificationPopup = popup;
            return false;
          }

          if (message !== NotificationTemplateName.verifyAccount) {
            openModal(`${ModalIds.notificationPopup}-${notificationId}`);
          }

          return true;
        });

        updatePopupList(notificationsForShowing);
        setVerificationPopup(loadedVerificationPopup);
        openVerificationPopup(true);
      } catch (e) {
        const { message } = await e;
        // addErrorToast(message);
      } finally {
        setIsLoading(false);
        setPopupsLoaded(true);
      }
    },
    [updatePopupList, openModal, setVerificationPopup, isVerifiedCompany, setPopupsLoaded, openVerificationPopup],
  );

  const showVerificationNotification = useCallback(
    async (page = 1) => {
      try {
        const bannersList = await NotificationApi.getList({
          page,
          'filter[type]': NotificationTypes.popup,
        });

        const { rows = [] } = bannersList as NotificationResponse;
        const notificationsForShowing = rows.filter(({ expiryTime, message }) => {
          return !expiryTime && message === NotificationTemplateName.verifyAccount;
        });

        setVerificationPopup(notificationsForShowing[0]);
      } catch (e) {
        const { message } = await e;
        // addErrorToast(message);
      } finally {
        setPopupsLoaded(true);
      }
    },
    [setVerificationPopup, setPopupsLoaded],
  );

  const updateNotificationsDate = async (id: number) => {
    try {
      await NotificationApi.updateNotificationDate(id);
    } catch (e) {
      const { message } = await e;
      // addErrorToast(message);
    }
  };

  useEffect(() => {
    if (
      !shouldLoadVerificationPopup ||
      isPopupsLoaded ||
      isLoading ||
      verificationPopup ||
      isVerifiedCompany ||
      !sessionId ||
      isPlatformAdmin
    ) {
      return;
    }

    showVerificationNotification();
  }, [
    isPopupsLoaded,
    showVerificationNotification,
    isLoading,
    verificationPopup,
    isVerifiedCompany,
    sessionId,
    isPlatformAdmin,
    shouldLoadVerificationPopup,
  ]);

  return {
    showNotifications,
    updateNotificationsDate,
    showVerificationNotification,
  };
};

export default usePopupNotification;
