// libraries
import React from 'react';
import { observer } from 'mobx-react-lite';
// components
import Form from 'components/Form';
import TaxFields from './TaxFields';
import AppLoading from 'components/AppLoading/AppLoading';
// helpers
import BUSINESS_TAX_SCHEMA from './validation-rules';
import useVerification from 'components/verification/useVerification';

const BusinessDetailsForm: React.FC = () => {
  const { stepData, submitTaxInformation, isLoading } = useVerification();

  if (isLoading) {
    return <AppLoading isActive={isLoading} />;
  }

  return (
    <Form
      submitForm={submitTaxInformation}
      validationSchema={BUSINESS_TAX_SCHEMA}
      className="public-form-wrapper"
      autoComplete="off"
      data={stepData}
    >
      <TaxFields />
    </Form>
  );
};

export default observer(BusinessDetailsForm);
