// libraries
import React from 'react';
import classnames from 'classnames';
import { ToastContainer } from 'react-toastify';
// constants
import toastConfig from 'shared/constants/toast';
// styles
import './Toast.scss';

interface IToast {
  className?: string;
}

const Toast: React.FC<IToast> = ({ className }: IToast) => {
  return <ToastContainer limit={toastConfig.MAX_TOAST_COUNT} className={classnames('toast-container', className)} />;
};

export default Toast;
