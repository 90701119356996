import React from 'react';
import Avatar, { AvatarType } from 'components/Avatar/Avatar';
import styles from './ChangeAvatarInput.module.scss';
import ImageUploadButton from 'components/ImageUploadButton/ImageUploadButton';
import { useTranslation } from 'react-i18next';

type TChangeAvatarProps = {
  userAvatar?: string;
};

const ChangeAvatarInput: React.FC<TChangeAvatarProps> = ({ userAvatar = '' }) => {
  const { t } = useTranslation('companyProfileSettings');

  return (
    <div className={styles['change-avatar__wrapper']}>
      <div className={styles.logo__wrapper}>
        <Avatar avatarUrl={userAvatar} type={AvatarType.medium} className={styles.company__logo} />
      </div>
      <ImageUploadButton title={t('changeLogo')} />
      <button className={styles['remove-button']} disabled>
        {t('removeLogo')}
      </button>
    </div>
  );
};

export default ChangeAvatarInput;
