// libraries
import React from 'react';
// components
import DownloadInvoice from 'components/transactions/TransactionDetails/shared/DownloadInvoice';
import TransactionCardFooterActions from 'components/transactions/TransactionCard/TransactionCardFooter/TransactionCardFooterActions/TransactionCardFooterActions';
// helpers
import { Invoice } from 'components/invoices/types';
// styles
import styles from './CompletedInvoiceFooterActions.module.scss';

interface ICompletedInvoiceFooterActions {
  invoiceDetails: Invoice;
}

const CompletedInvoiceFooterActions: React.FC<ICompletedInvoiceFooterActions> = ({
  invoiceDetails,
}: ICompletedInvoiceFooterActions) => {
  return (
    <div className={styles['actions-wrapper']}>
      <TransactionCardFooterActions>
        <DownloadInvoice invoiceId={invoiceDetails.invoiceId} />
      </TransactionCardFooterActions>
    </div>
  );
};

export default CompletedInvoiceFooterActions;
